// Copyright Caproc Oy
// System name: Aqua-Net 
// System module: Report
// Author: TS
//
// 12.6.2023 TS Initial version
// 15.6.2023 TS Added gross_sum to pdf report

import React from "react";
import { Message, Header, Table, Segment, Dimmer, Loader, Image, Grid, Input, Radio, Container, Button, Responsive } from "semantic-ui-react";
import Shell from "./Shell";
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import fiLocale from 'react-semantic-ui-datepickers/dist/locales/fi-FI';
import { authAxios, getISOFormattedDate, exportToCsv, getWidth, exportReportToPdf } from "../../utils";
import { setActivity } from "../../store/actions/auth";
import { withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import { reportPaymentTransactionsURL } from "../../constants";



class ReportPaymentTransactions extends React.Component {
    state = {
        report: [],
        error: null,
        loading: false,
        search_start_date: null,
        search_end_date: null,
        search: "",
        fetch: false,
        gross_sum: null,
        onetime_contracts_invoices_chart_data: {},
        profit_cost_chart_data: {},
        profit_cost_percent_chart_data: {},
        update_totals_and_charts: false,
        screen_size: "normal"
    };

    componentDidMount() {
        this.props.setActivity();
        let init_start_date = new Date();
        init_start_date.setDate(1);
        init_start_date.setMonth(init_start_date.getMonth() - 1);
        this.handleStartDateChange(init_start_date);
        this.handleEndDateChange(new Date());
    }

    handleRadioChange = (e, { name, value }) => {
        this.setState({
            [name]: value
        });
    };

    radioButtons = () => {
        return (
            <React.Fragment>
                <Radio
                    name="screen_size"
                    label={this.props.t("Normal")}
                    onChange={this.handleRadioChange}
                    checked={this.state.screen_size === "normal"}
                    value="normal"
                />
                &nbsp; &nbsp;
                <Radio
                    name="screen_size"
                    label={this.props.t("Wide")}
                    onChange={this.handleRadioChange}
                    checked={this.state.screen_size === "wide"}
                    value="wide"
                />
                &nbsp; &nbsp;
                <Radio
                    name="screen_size"
                    label={this.props.t("Full screen")}
                    onChange={this.handleRadioChange}
                    checked={this.state.screen_size === "full"}
                    value="full"
                />  
            </React.Fragment>
        );
    };

    handleSelectChange = (e, { name, value }) => {
        this.setState({
            [name]: value,
            fetch: true
        });
    };

    handleFetchReport = () => {
        this.setState({
            loading: true
        });
        const { search_start_date, search_end_date } = this.state;
        const { activeCompanyId } = this.props;


        authAxios
            .get(reportPaymentTransactionsURL(activeCompanyId, search_start_date, search_end_date))
            .then(res => {
                this.setState({
                    report: res.data,
                    error: null,
                    loading: false
                });
                this.handleTotals(res.data);
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    error: err
                });
            });
    };


    handleTotals = report => {
        let { gross_sum } = this.state;
        let i = 0;
        gross_sum = 0;

        for (i in report) {
            gross_sum += report[i].amount_total;
        }
        this.setState({
            gross_sum
        });
    };

    handleStartDateChange = value => {
        this.setState({
            search_start_date: getISOFormattedDate(value, "Date"),
            fetch: true
        });

    };

    handleEndDateChange = value => {
        this.setState({
            search_end_date: getISOFormattedDate(value, "Date"),
            fetch: true
        });
    };

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value,
            update_totals_and_charts: e.target.name === "search" ? true : false
        });
    };

    handleExportToCSV = items => {
        const { t } = this.props;
        if (items !== null) {
            const titles = [
                t("Invoice number"),
                t("Amount"),
                t("Payment date"),
                t("Booking date"),
                t("Due date"),
                t("Archive code"),
                t("Name"),
                t("Payment method"),
                t("Customer")
            ];
            let items_to_csv = [];
            let i = 0;
            let period = "";
            for (i in items) {
                items_to_csv.push({
                    invoice_num: items[i].invoice_num,
                    amount: items[i].amount_total.toFixed(2),
                    payment_date: new Date(items[i].payment_date).toLocaleString('fi-FI', { dateStyle: 'short' }),
                    booking_date: new Date(items[i].booking_date).toLocaleString('fi-FI', { dateStyle: 'short' }),
                    due_date: new Date(items[i].due_date).toLocaleString('fi-FI', { dateStyle: 'short' }),
                    archive_code: items[i].archive_code,
                    name: items[i].name,
                    method: t(items[i].payment_method),
                    customer: items[i].customer
                });
            }
            exportToCsv(this.props.activeCompanyId, "export", titles, items_to_csv);
        }
    };

    handleFetchReportPdf = async (items) => {
        if (items === null) {
            return;
        }

        this.setState({
            loading: true
        });

        const { activeCompanyId, t} = this.props;
        const { search_start_date, search_end_date, gross_sum} = this.state;
        const titles = [
            t("Invoice number"),
            t("Amount"),
            t("Payment date"),
            t("Booking date"),
            t("Due date"),
            t("Archive code"),
            t("Name"),
            t("Payment method"),
            t("Customer")
        ];

        const report_name = t("Payment transactions") + ` ${new Date(search_start_date).toLocaleString('fi-FI', { dateStyle: 'short' })}-${new Date(search_end_date).toLocaleString('fi-FI', { dateStyle: 'short' })}`;
        let items_to_pdf = [];
        let i = 0;
        let items_amout_total_sum = 0;
        for (i in items) {
            items_to_pdf.push({
                invoice_num: items[i].invoice_num,
                amount: items[i].amount_total.toFixed(2),
                payment_date: new Date(items[i].payment_date).toLocaleString('fi-FI', { dateStyle: 'short' }),
                booking_date: new Date(items[i].booking_date).toLocaleString('fi-FI', { dateStyle: 'short' }),
                due_date: new Date(items[i].due_date).toLocaleString('fi-FI', { dateStyle: 'short' }),
                archive_code: items[i].archive_code,
                name: items[i].name,
                method: t(items[i].payment_method),
                customer: items[i].customer
            });
        }
        // console.log(gross_sum);
        // Add total sum at the end of table
        if (gross_sum > 0) {
            items_to_pdf.push({
                invoice_num: "",
                amount: gross_sum.toFixed(2),
                payment_date: "",
                booking_date: "",
                due_date: "",
                archive_code: "",
                name: "",
                method: "",
                customer: ""
            });
        }
        const activeCompanyName = localStorage.getItem("activeCompanyName");
        exportReportToPdf(activeCompanyId, t("payment_transactions"), report_name, titles, items_to_pdf, activeCompanyName)
            .then(res => {
                this.setState({
                    error: null,
                    loading: false
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    error: err
                });
            });
    };


    main = () => {
        const { error, loading, report, search, search_end_date, search_start_date, 
            gross_sum, update_totals_and_charts} = this.state;
        const {  t } = this.props;

        const is_desktop =  getWidth() > Responsive.onlyMobile.maxWidth ? true : false;
        let filtered_items = [];

        // Filter data for search
        if (report && report.length > 0) {
            filtered_items = report.filter((data) => {
                return data.name.toString().toLowerCase().indexOf(search.toLocaleLowerCase()) !== -1 ||
                data.invoice_num.toString().toLowerCase().indexOf(search.toLocaleLowerCase()) !== -1 ||
                data.amount_total.toFixed(2).toString().toLowerCase().indexOf(search.toLocaleLowerCase()) !== -1 ||
                data.archive_code.toString().toLowerCase().indexOf(search.toLocaleLowerCase()) !== -1 ||
                t(data.payment_method).toString().toLowerCase().indexOf(search.toLocaleLowerCase()) !== -1 ||
                data.customer.toString().toLowerCase().indexOf(search.toLocaleLowerCase()) !== -1 ||
                new Date(data.payment_date).toLocaleString('fi-FI', { dateStyle: 'short' }).toString().toLowerCase().indexOf(search.toLocaleLowerCase()) !== -1;

            })
        }
        if (update_totals_and_charts) {
            this.handleTotals(filtered_items);
            this.setState({ update_totals_and_charts: false });
        }

        return (
            <React.Fragment>
                {is_desktop && (
                    <Button.Group floated="right" basic size='small'>
                        <Button icon="download" content="PDF" onClick={() => this.handleFetchReportPdf(filtered_items)} />
                        <Button icon="download" content="CSV" onClick={() => this.handleExportToCSV(filtered_items)} />
                    </Button.Group>
                )}
                <Header as="h3">{t("Payment transaction report")}</Header>
                {is_desktop ? (
                    <Grid columns="2">
                        <Grid.Row>
                            <Grid.Column width={9}>
                                <SemanticDatepicker
                                    firstDayOfWeek="1"
                                    size="mini"
                                    disabled={loading}
                                    label={t("Transaction date") + ": "}
                                    locale={fiLocale}
                                    placeholder={search_start_date !== null ? (new Date(search_start_date).toLocaleString('fi-FI', { dateStyle: 'short' })) : ("")}
                                    onDateChange={selected => this.handleStartDateChange(selected)}
                                    format='DD.MM.YYYY' />
                                <SemanticDatepicker
                                    firstDayOfWeek="1"
                                    size="mini"
                                    disabled={loading}
                                    label="->"
                                    locale={fiLocale}
                                    placeholder={search_end_date !== null ? (new Date(search_end_date).toLocaleString('fi-FI', { dateStyle: 'short' })) : ("")}
                                    onDateChange={selected => this.handleEndDateChange(selected)}
                                    format='DD.MM.YYYY' />
                            </Grid.Column>
                            <Grid.Column floated="right" textAlign="right" width={6}>
                                <Input
                                    size="mini"
                                    icon='search'
                                    value={search}
                                    name="search"
                                    onChange={this.handleChange} />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                ) : (
                    <React.Fragment>
                        <SemanticDatepicker
                            firstDayOfWeek="1"
                            disabled={loading}
                            locale={fiLocale}
                            placeholder={search_start_date !== null ? (new Date(search_start_date).toLocaleString('fi-FI', { dateStyle: 'short' })) : ("")}
                            onDateChange={selected => this.handleStartDateChange(selected)}
                            format='DD.MM.YYYY' />
                        <SemanticDatepicker
                            firstDayOfWeek="1"
                            disabled={loading}
                            locale={fiLocale}
                            placeholder={search_end_date !== null ? (new Date(search_end_date).toLocaleString('fi-FI', { dateStyle: 'short' })) : ("")}
                            onDateChange={selected => this.handleEndDateChange(selected)}
                            format='DD.MM.YYYY' />
                        <Input
                            fluid
                            icon='search'
                            value={search}
                            name="search"
                            onChange={this.handleChange} />
                        <br/>
                    </React.Fragment>
                )}
                {error && (
                    <Message
                        error
                        header={t("There was an error")}
                        content={JSON.stringify(error)}
                    />
                )}
                {loading && (
                    <Segment>
                        <Dimmer active inverted>
                            <Loader inverted>Loading</Loader>
                        </Dimmer>
                        <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
                    </Segment>
                )}
                {report && (
                    <React.Fragment>
                        <Table striped selectable celled>
                            {is_desktop && (
                                <Table.Header >
                                    <Table.Row textAlign="right">
                                        <Table.HeaderCell textAlign="left">{t("Invoice number")}</Table.HeaderCell>
                                        <Table.HeaderCell>{t("Amount")}</Table.HeaderCell>
                                        <Table.HeaderCell>{t("Payment date")}</Table.HeaderCell>
                                        <Table.HeaderCell>{t("Booking date")}</Table.HeaderCell>
                                        <Table.HeaderCell>{t("Due date")}</Table.HeaderCell>
                                        <Table.HeaderCell>{t("Archive code")}</Table.HeaderCell>
                                        <Table.HeaderCell>{t("Name")}</Table.HeaderCell>
                                        <Table.HeaderCell>{t("Payment method")}</Table.HeaderCell>
                                        <Table.HeaderCell>{t("Customer")}</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                            )}
                            <Table.Body>
                                {filtered_items.map((data, key) => {
                                    return (
                                        <Table.Row key={key} textAlign="right" >
                                            <Table.Cell textAlign="left">
                                                {!is_desktop && (<React.Fragment><b>{t("Invoice number")}</b><br/></React.Fragment>)}
                                                {data.invoice_num}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {!is_desktop && (<React.Fragment><b>{t("Amount")}</b><br/></React.Fragment>)}
                                                {data.amount_total.toFixed(2)}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {!is_desktop && (<React.Fragment><b>{t("Payment date")}</b><br/></React.Fragment>)}
                                                {new Date(data.payment_date).toLocaleString('fi-FI', { dateStyle: 'short' })}
                                            </Table.Cell>
                                            <Table.Cell>
                                            {!is_desktop && (<React.Fragment><b>{t("Booking date")}</b><br/></React.Fragment>)}
                                                {new Date(data.booking_date).toLocaleString('fi-FI', { dateStyle: 'short' })}
                                            </Table.Cell>
                                            <Table.Cell>
                                            {!is_desktop && (<React.Fragment><b>{t("Due date")}</b><br/></React.Fragment>)}
                                                {new Date(data.due_date).toLocaleString('fi-FI', { dateStyle: 'short' })}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {!is_desktop && (<React.Fragment><b>{t("Archive code")}</b><br/></React.Fragment>)}
                                                {data.archive_code}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {!is_desktop && (<React.Fragment><b>{t("Name")}</b><br/></React.Fragment>)}
                                                {data.name}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {!is_desktop && (<React.Fragment><b>{t("Payment method")}</b><br/></React.Fragment>)}
                                                {t(data.payment_method)}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {!is_desktop && (<React.Fragment><b>{t("Customer")}</b><br/></React.Fragment>)}
                                                {data.customer}
                                            </Table.Cell>
                                        </Table.Row>
                                    );
                                })}
                                {gross_sum && (
                                    <Table.Row textAlign="right" >
                                        <Table.Cell />
                                        
                                        <Table.Cell><Header as="h5">{gross_sum.toFixed(2)}</Header></Table.Cell>
                                        <Table.Cell />
                                        <Table.Cell />
                                        <Table.Cell />
                                        <Table.Cell />
                                        <Table.Cell />
                                        <Table.Cell />
                                        <Table.Cell />
                                    </Table.Row>
                                )}
                            </Table.Body>
                        </Table>
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    };

    render() {
        const {  search_end_date, search_start_date, fetch} = this.state;
        const { isAuthenticated} = this.props;
        if (!isAuthenticated) {
            return <Redirect to="/login" />;
        }
        if (fetch) {
            if (search_start_date !== null && search_end_date !== null) {
                this.handleFetchReport();
            }
            this.setState({ fetch: false });
        }
        // console.log(this.state);
        return (
            <React.Fragment>
                {this.state.screen_size === "normal" && (
                    <Shell>
                        <this.radioButtons/>
                        <this.main />
                    </Shell>
                )}
                {this.state.screen_size === "wide" && (
                    <Container>
                        <Segment vertical>
                            <this.radioButtons/>    
                            <this.main/>
                        </Segment>
                    </Container>
                )}{this.state.screen_size === "full" && (
                    <React.Fragment>
                        <this.radioButtons/>
                        <this.main />
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}
const mapStateToProps = state => {
    return {
        activeCompanyId: state.company.activeCompanyId,
        isAuthenticated: state.auth.token !== null
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setActivity: () => dispatch(setActivity())
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(withTranslation('reportpaymenttransactions')(ReportPaymentTransactions))
);