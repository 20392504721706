// Copyright Caproc Oy
// System name: Aqua-Net 
// System module: Project
// Author: TS
//
// 11.3.2020 TS Initial version 
// 3.5.2020 TS Added "No responsible person" option to Responsible person select.
// 6.6.2020 TS Changed remove icon to trash bin


import React from "react";
import { Form, Input, Message, Header, Button, Select, Grid, Table, Modal, Container, Checkbox, Radio, Loader, Popup, Icon} from "semantic-ui-react";
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import fiLocale from 'react-semantic-ui-datepickers/dist/locales/fi-FI';
import { authAxios, getISOFormattedDate } from "../../utils";
import { setActivity } from "../../store/actions/auth";
import { withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import {
    fetchCustomersURL,
    fetchProductGroupsURL,
    fetchProjectGroupsURL,
    projectDetailsURL,
    updateProjectDetailsURL,
    createProjectURL,
    companyActiveUsersListURL,
    customerInvoicingDetailsURL,
    createProjectMemberURL,
    updateProjectMemberURL,
    deleteProjectMemberURL,
    createProjectProductURL,
    updateProjectProductURL,
    deleteProjectProductURL,
    projectTypeOptionsURL
} from "../../constants";
import Shell from "./Shell";


class ProjectDetails extends React.Component {
    state = {
        project_id: null,
        project_details: {
            id: "",
            customer: "",
            project_group: "",
            name: "",
            number: "",
            comment: "",
            start_date: "",
            end_date: "",
            is_active: true,
            project_type: "C",
            responsible_person: "",
            project_members: "",
            project_products: "",
            payment_term: "",
            customer_reference: "",
            company_reference: "",
            purchase_order: "",
            date_created: "",
            last_modified: "",
            author: "",
            budget: "0"
        },
        active_customer_invoicing_details: {
            invoicing_details_id: "",
            customer_id: "",
            payment_term: "",
            default_customer_reference: "",
            default_company_reference: "",
            no_vat: "",
            discount: "",
            invoicing_method: "",
            invoicing_email: ""
        },
        update_due_date: false,
        project_products_list: [],
        selected_products_list: [],
        project_product_details_modal_open: false,
        active_project_product: [],
        selected_product_for_edit: [],
        project_product_select_modal_open: false,
        project_members_list: [],
        selected_members_list: [],
        project_member_details_modal_open: false,
        active_project_member: [],
        selected_member_for_edit: [],
        project_member_select_modal_open: false,
        customer_options: null,
        product_groups: null,
        company_users: null,
        company_user_objects: null,
        fields_disabled: true,
        periodSelectRadioGroup: "permanent",
        periodLengthOptions: [],
        createBeforeDaysOptions: [],
        project_group_options: [],
        error: null,
        success_mgs: null,
        loading: false,
        create: null,
        leave_page: false,
        leave_page_to: "",
        project_type_options: []

    };

    componentDidMount() {
        this.props.setActivity();
        this.handleFetchCompanyUsers();
        this.handleFetchCustomers();
        this.handleFetchProductGroupList();
        this.handleFetchProjectGroupList();
        this.handleFetchProjectTypeOptions();
        if (this.props.match.params.project_id === "create") {
            // Set initial date to start date picker
            this.handleStartDateChange(new Date());
            this.setState({
                create: true
            })
        } else {
            this.handleDetails();
        }
    }

    handleFetchCompanyUsers() {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;

        authAxios
            .get(companyActiveUsersListURL(activeCompanyId))
            .then(res => {
                this.setState({
                    company_users: this.handleFormatUserOptions(res.data),
                    company_user_objects: res.data,
                    error: null,
                    success_mgs: null,
                    loading: false,
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    success_mgs: null,
                    error: err
                });
            });
    };

    handleFormatUserOptions = options => {
        const { t } = this.props;
        let i = 0;
        let options_list = [];
        options_list.push({
            key: 0,
            text: t("No responsible person"),
            value: null
        })
        for (i in options) {
            options_list.push({
                key: options[i].id,
                text: (options[i].first_name + " " + options[i].last_name),
                value: options[i].id
            })
        }
        return options_list;
    };

    handleFetchProjectTypeOptions() {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;

        authAxios
            .get(projectTypeOptionsURL(activeCompanyId))
            .then(res => {
                this.setState({
                    project_type_options: this.handleFormatTypeOptions(res.data),
                    error: null,
                    success_mgs: null,
                    loading: false,
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    success_mgs: null,
                    error: err
                });
            });

    }

    handleFormatTypeOptions = options => {
        const { t } = this.props;
        const keys = Object.keys(options);
        return keys.map(k => {
            return {
                key: options[k].value,
                text: t(options[k].name),
                value: options[k].value
            };
        });
    };

    handleFetchProjectGroupList() {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;

        authAxios
            .get(fetchProjectGroupsURL(activeCompanyId))
            .then(res => {
                this.setState({
                    project_group_options: this.handleFormatGroupOptions(res.data),
                    error: null,
                    success_mgs: null,
                    loading: false,
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    success_mgs: null,
                    error: err
                });
            });
    };

    handleFormatGroupOptions = options => {
        let i = 0;
        let options_list = [];
        for (i in options) {
            options_list.push({
                key: options[i].id,
                text: options[i].name,
                value: options[i].id
            })
        }
        return options_list;
    };



    handleFetchCustomers() {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;

        authAxios
            .get(fetchCustomersURL(activeCompanyId))
            .then(res => {
                this.setState({
                    customer_options: this.handleFormatOptions(res.data),
                    error: null,
                    success_mgs: null,
                    loading: false,
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    success_mgs: null,
                    error: err
                });
            });
    };

    handleFormatOptions = options => {
        let i = 0;
        let options_list = [];
        for (i in options) {
            // Get only active ones..
            if (options[i].is_active) {
                options_list.push({
                    key: options[i].id,
                    text: options[i].name,
                    value: options[i].id
                })
            }
        }
        return options_list;
    };

    handleFetchProductGroupList = () => {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;
        authAxios
            .get(fetchProductGroupsURL(activeCompanyId))
            .then(res => {
                this.setState({
                    product_groups: res.data,
                    error: null,
                    loading: false
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    error: err
                });
            });
    };

    // Fetch invoicing details for selected customer
    handleCustomerInvoicingDetails = (customer_id) => {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;
        authAxios
            .get(customerInvoicingDetailsURL(activeCompanyId, customer_id))
            .then(res => {
                this.setState({
                    active_customer_invoicing_details: res.data,
                    error: null,
                    success_mgs: null,
                    loading: false
                });
                if (this.state.create) {
                    // Store default payment term from customer invoicing details to invoicing details.
                    const { project_details } = this.state;
                    const updatedDetails = {
                        ...project_details,
                        payment_term: res.data.payment_term,
                        company_reference: res.data.default_company_reference,
                        customer_reference: res.data.default_customer_reference
                    };
                    this.setState({
                        project_details: updatedDetails,
                        // update_due_date: true,
                        success_mgs: null,
                        error: null
                    });
                }

            })
            .catch(err => {
                this.setState({
                    loading: false,
                    success_mgs: null,
                    error: err
                });
            });
    };

    handleDetails = () => {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;
        const { project_id } = this.props.match.params;
        authAxios
            .get(projectDetailsURL(activeCompanyId, project_id))
            .then(res => {
                this.setState({
                    project_details: res.data,
                    project_products_list: res.data.projectproducts,
                    project_members_list: res.data.projectmembers,
                    fields_disabled: false,
                    periodSelectRadioGroup: res.data.end_date === null ? "permanent" : "fixed",
                    error: null,
                    success_mgs: null,
                    loading: false
                });
                this.handleCustomerInvoicingDetails(res.data.customer.id);
            })
            .catch(err => {
                this.setState({
                    project_details: null,
                    loading: false,
                    success_mgs: null,
                    error: err
                });
            });

    };

    handleProjectProductDetailsChange = e => {
        const { active_project_product } = this.state;
        const updatedDetails = {
            ...active_project_product,
            [e.target.name]: e.target.value,
        };
        this.setState({
            active_project_product: updatedDetails,
            success_mgs: null,
            error: null
        });
    };

    handleProjectProductCheckboxChange = (e, { name, value }) => {
        const { active_project_product } = this.state;
        const updatedDetails = {
            ...active_project_product,
            [name]: !value
        };
        this.setState({
            active_project_product: updatedDetails
        });
    };

    handleProjectMemberDetailsChange = e => {
        const { active_project_member } = this.state;
        const updatedDetails = {
            ...active_project_member,
            [e.target.name]: e.target.value,
        };
        this.setState({
            active_project_member: updatedDetails,
            success_mgs: null,
            error: null
        });
    };

    handleProjectMemberCheckboxChange = (e, { name, value }) => {
        const { active_project_member } = this.state;
        const updatedDetails = {
            ...active_project_member,
            [name]: !value
        };
        this.setState({
            active_project_member: updatedDetails
        });
    };


    handleChange = e => {
        const { project_details } = this.state;
        const updatedDetails = {
            ...project_details,
            [e.target.name]: e.target.value,
        };
        this.setState({
            project_details: updatedDetails,
            success_mgs: null,
            error: null,
        });
    };

    handleRadioChange = (e, { name, value }) => {
        if (value === "permanent") {
            const { project_details } = this.state;
            const updatedDetails = {
                ...project_details,
                end_date: null
            };
            this.setState({
                project_details: updatedDetails,
            });
        }
        this.setState({
            [name]: value,
        });
    };

    handleSelectChange = (e, { name, value }) => {
        if (name === 'customer') {
            // Fetch customer invoicing details for selected customer. Value = customer id.
            this.handleCustomerInvoicingDetails(value);
            this.setState({ fields_disabled: false })
        }
        const { project_details } = this.state;
        const updatedDetails = {
            ...project_details,
            [name]: value
        };
        this.setState({
            project_details: updatedDetails
        });
    };

    handleStartDateChange = value => {
        const { project_details } = this.state;
        const updatedDetails = {
            ...project_details,
            start_date: getISOFormattedDate(value, "Date")
        };
        this.setState({
            project_details: updatedDetails
        });
    };

    handleEndDateChange = value => {
        const { project_details } = this.state;
        const updatedDetails = {
            ...project_details,
            end_date: getISOFormattedDate(value, "Date")
        };
        this.setState({
            project_details: updatedDetails
        });
    };

    handleCheckboxChange = (e, { name, value }) => {
        const { project_details } = this.state;
        const updatedDetails = {
            ...project_details,
            [name]: !value
        };
        this.setState({
            project_details: updatedDetails
        });
    };

    handleSubmit = e => {
        e.preventDefault();
        this.setState({
            loading: true,
            error: null
        });
        const { create } = this.state;
        let { project_details } = this.state;
        const { activeCompanyId, t } = this.props;
        if (project_details.name === "") {
            this.setState({
                error: t("Please fill in project name"),
                success_mgs: null,
                loading: false
            });
        } else if (project_details.name.length > 100) {
            this.setState({
                error: t("Max amount of characters is 100 in Name field"),
                success_mgs: null,
                loading: false
            });
        } else if (project_details.comment.length > 200) {
            this.setState({
                error: t("Max amount of characters is 200 in Comment field"),
                success_mgs: null,
                loading: false
            });
        } else if (project_details.customer_reference.length > 50) {
            this.setState({
                error: t("Max amount of characters is 50 in Customer reference field"),
                success_mgs: null,
                loading: false
            });
        } else if (project_details.company_reference.length > 50) {
            this.setState({
                error: t("Max amount of characters is 50 in Company reference field"),
                success_mgs: null,
                loading: false
            });
        } else if (project_details.purchase_order.length > 70) {
            this.setState({
                error: t("Max amount of characters is 70 in Purchase order field"),
                success_mgs: null,
                loading: false
            });
        } else if (project_details.start_date === "") {
            this.setState({
                error: t("Please fill in Start date"),
                success_mgs: null,
                loading: false
            });
        } else if (project_details.project_group === "") {
            this.setState({
                error: t("Please select Project Group"),
                success_mgs: null,
                loading: false
            });
        } else if (project_details.payment_term === "") {
            this.setState({
                error: t("Please fill in Payment Term"),
                success_mgs: null,
                loading: false
            });
        } else if (project_details.budget === "") {
            this.setState({
                error: t("Please fill in Budget"),
                success_mgs: null,
                loading: false
            });
        } else {
            if (create) {
                const updated_details = {
                    ...project_details,
                    author: localStorage.getItem("UserID")
                }
                authAxios
                    .post(createProjectURL(activeCompanyId), {
                        ...updated_details
                    })
                    .then(res => {
                        project_details.id = res.data.project_id;
                        // Create project products
                        let { project_products_list } = this.state;
                        let i = 0;
                        for (i in project_products_list) {
                            // Add project id from just created project and add to all project products.
                            project_products_list[i]['project'] = res.data.project_id;
                            project_products_list[i]['author'] = localStorage.getItem("UserID");
                            authAxios
                                .post(createProjectProductURL(activeCompanyId, res.data.project_id), {
                                    ...project_products_list[i]
                                })
                                .then(res => {
                                })
                                .catch(err => {
                                    this.setState({
                                        loading: false,
                                        success_mgs: null,
                                        error: err
                                    });
                                    return;
                                });
                        }
                        // // Create project members
                        let { project_members_list } = this.state;
                        i = 0;
                        for (i in project_members_list) {
                            // Add project id from just created project and add to all project products.
                            project_members_list[i]['project'] = res.data.project_id;
                            authAxios
                                .post(createProjectMemberURL(activeCompanyId, res.data.project_id), {
                                    ...project_members_list[i]
                                })
                                .then(res => {
                                })
                                .catch(err => {
                                    this.setState({
                                        loading: false,
                                        success_mgs: null,
                                        error: err
                                    });
                                    return;
                                });
                        }
                        
                        this.setState({
                            leave_page: true
                        });
                    })
                    .catch(err => {
                        this.setState({
                            loading: false,
                            success_mgs: null,
                            error: err
                        });
                    });

            } else {
                authAxios
                    .put(updateProjectDetailsURL(activeCompanyId, project_details.id), {
                        ...project_details
                    })
                    .then(res => {
                        // Update also project products
                        let { project_products_list } = this.state;
                        let i = 0;
                        for (i in project_products_list) {
                            // id is empty if this is new product added to the list
                            if (!project_products_list[i].id) {
                                project_products_list[i]['project'] = project_details.id;
                                authAxios
                                    .post(createProjectProductURL(activeCompanyId, project_details.id), {
                                        ...project_products_list[i]
                                    })
                                    .then(res => {
                                    })
                                    .catch(err => {
                                        this.setState({
                                            loading: false,
                                            success_mgs: null,
                                            error: err
                                        });
                                        return;
                                    });

                            } else {
                                // Update if existing project product
                                authAxios
                                    .put(updateProjectProductURL(activeCompanyId, project_details.id, project_products_list[i].id), {
                                        ...project_products_list[i]
                                    })
                                    .then(res => {
                                    })
                                    .catch(err => {
                                        this.setState({
                                            loading: false,
                                            success_mgs: null,
                                            error: err
                                        });
                                        return;
                                    });
                            }
                        }
                        // Update also project members
                        let { project_members_list } = this.state;
                        i = 0;
                        for (i in project_members_list) {
                            // id is empty if this is new project member added to the list
                            if (!project_members_list[i].id) {
                                project_members_list[i]['project'] = project_details.id;
                                authAxios
                                    .post(createProjectMemberURL(activeCompanyId, project_details.id), {
                                        ...project_members_list[i]
                                    })
                                    .then(res => {
                                    })
                                    .catch(err => {
                                        this.setState({
                                            loading: false,
                                            success_mgs: null,
                                            error: err
                                        });
                                        return;
                                    });

                            } else {
                                // Update if existing project member
                                authAxios
                                    .put(updateProjectMemberURL(activeCompanyId, project_details.id, project_members_list[i].id), {
                                        ...project_members_list[i]
                                    })
                                    .then(res => {
                                    })
                                    .catch(err => {
                                        this.setState({
                                            loading: false,
                                            success_mgs: null,
                                            error: err
                                        });
                                        return;
                                    });
                            }
                        }

                        this.setState({
                            leave_page: true
                        });
                    })
                    .catch(err => {
                        this.setState({
                            loading: false,
                            success_mgs: null,
                            error: err
                        });
                    });
            }
        }
    };

    openProjectProductDetailsModal = item => {
        this.setState({
            project_product_details_modal_open: true,
            active_project_product: item,
            selected_product_for_edit: item
        })
    };

    closeProjectProductDetailsModal = () => {
        this.setState({
            project_product_details_modal_open: false,
            active_project_product: [],
            selected_product_for_edit: []
        })
    };

    handleProjectProductDetailsSubmit = () => {
        const { active_project_product, selected_product_for_edit } = this.state;
        this.handleProjectProductsListChange(selected_product_for_edit, active_project_product, "update");
        this.closeProjectProductDetailsModal();
    }

    openProjectProductsAddModal = () => {
        this.setState({
            project_product_select_modal_open: true
        })
    };

    closeProjectProductsAddModal = () => {
        this.setState({
            project_product_select_modal_open: false,
            selected_products_list: [],
        })
    };

    handleProjectProductsListChange = (item, updated_item, action) => {
        let { project_products_list } = this.state;
        const { activeCompanyId } = this.props;
        if (action === "add") {
            if (!project_products_list.includes(item)) {
                project_products_list.push(item);
            }
        } else if (action === "remove") {
            if (project_products_list.includes(item)) {
                project_products_list.splice(project_products_list.indexOf(item), 1);
                // If this project product is already saved in the db (has id defined), Delete it.
                if (item.id) {
                    authAxios
                        .delete(deleteProjectProductURL(activeCompanyId, item.project, item.id), {
                        })
                        .then(res => {
                        })
                        .catch(err => {
                            this.setState({
                                loading: false,
                                success_mgs: null,
                                error: err
                            });
                            return;
                        });
                }
            }
        } else if (action === "update") {
            if (project_products_list.includes(item)) {
                project_products_list.splice(project_products_list.indexOf(item), 1, updated_item);
            }
        }
        this.setState({ project_products_list });
    };

    handleAddProjectProductsCheckboxChange = (e, { value, checked }) => {
        let { selected_products_list } = this.state;
        if (checked) {
            if (!selected_products_list.includes(value)) {
                selected_products_list.push(value);
            }
        } else {
            if (selected_products_list.includes(value)) {
                selected_products_list.splice(selected_products_list.indexOf(value), 1);
            }
        }
        this.setState({ selected_products_list });
    };

    handleAddProjectProductsToProject = () => {
        const { selected_products_list, active_customer_invoicing_details } = this.state;
        const { activeCompanyId } = this.props;
        let i = 0;
        for (i in selected_products_list) {
            let new_item = {};
            new_item['company'] = activeCompanyId;
            // Pick project id and author in handleSubmit after the invoice has been created.

            new_item['name'] = selected_products_list[i].name;
            new_item['product'] = selected_products_list[i].id;
            new_item['code'] = selected_products_list[i].code;
            new_item['net_price'] = selected_products_list[i].net_price;
            new_item['measure_unit'] = selected_products_list[i].measure_unit;
            new_item['vat_value'] = selected_products_list[i].vat_value;
            new_item['minimum_invoice_amount'] = 0; 
            new_item['invoice_amount_period'] = 0;
            if (active_customer_invoicing_details) {
                new_item['customer'] = active_customer_invoicing_details.customer_id;
                new_item['discount'] = active_customer_invoicing_details.discount;
            }
            this.handleProjectProductsListChange(new_item, "", "add");
        }
        this.closeProjectProductsAddModal();
    };

    openProjectMemberDetailsModal = item => {
        this.setState({
            project_member_details_modal_open: true,
            active_project_member: item, 
            selected_member_for_edit: item
        })
    };

    closeProjectMemberDetailsModal = () => {
        this.setState({
            project_member_details_modal_open: false,
            active_project_member: [],
            selected_member_for_edit: []
        })
    };

    handleProjectMemberDetailsSubmit = () => {
        const { active_project_member, selected_member_for_edit } = this.state;
        this.handleProjectMembersListChange(selected_member_for_edit, active_project_member, "update");
        this.closeProjectMemberDetailsModal();
    }

    openProjectMembersAddModal = () => {
        this.setState({
            project_member_select_modal_open: true
        })
    };

    closeProjectMembersAddModal = () => {
        this.setState({
            project_member_select_modal_open: false,
            selected_members_list: []
        })
    };

    handleProjectMembersListChange = (item, updated_item, action) => {
        let { project_members_list } = this.state;
        const { activeCompanyId } = this.props;
        if (action === "add") {
            if (!project_members_list.includes(item)) {
                project_members_list.push(item);
            }
        } else if (action === "remove") {
            if (project_members_list.includes(item)) {
                project_members_list.splice(project_members_list.indexOf(item), 1);
                // If this project product is already saved in the db (has id defined), Delete it.
                if (item.id) {
                    authAxios
                        .delete(deleteProjectMemberURL(activeCompanyId, item.project, item.id), {
                        })
                        .then(res => {
                        })
                        .catch(err => {
                            this.setState({
                                loading: false,
                                success_mgs: null,
                                error: err
                            });
                            return;
                        });
                }
            }
        } else if (action === "update") {
            if (project_members_list.includes(item)) {
                project_members_list.splice(project_members_list.indexOf(item), 1, updated_item);
            }
        }
        this.setState({ project_members_list });
    };

    handleAddProjectMembersCheckboxChange = (e, { value, checked }) => {
        let { selected_members_list } = this.state;
        if (checked) {
            if (!selected_members_list.includes(value)) {
                selected_members_list.push(value);
            }
        } else {
            if (selected_members_list.includes(value)) {
                selected_members_list.splice(selected_members_list.indexOf(value), 1);
            }
        }
        this.setState({ selected_members_list });
    };

    handleAddProjectMembersToProject = () => {
        const { selected_members_list } = this.state;
        const { activeCompanyId } = this.props;
        let i = 0;
        for (i in selected_members_list) {
            let new_item = {};
            new_item['company'] = activeCompanyId;
            // Pick project id in handleSubmit after the invoice has been created.

            new_item['user'] = selected_members_list[i].id;
            new_item['first_name'] = selected_members_list[i].first_name;
            new_item['last_name'] = selected_members_list[i].last_name;
            new_item['role'] = "";
            new_item['is_active'] = true;
            this.handleProjectMembersListChange(new_item, "", "add");
        }
        this.closeProjectMembersAddModal();
    };


    render() {
        const {
            project_details,
            project_products_list,
            project_members_list,
            customer_options,
            company_users,
            company_user_objects,
            product_groups,
            project_product_select_modal_open,
            project_member_select_modal_open,
            fields_disabled,
            active_project_product,
            active_project_member,
            project_product_details_modal_open,
            project_member_details_modal_open,
            project_group_options,
            error,
            success_mgs,
            loading,
            create,
            project_type_options
        } = this.state;
        const { isAuthenticated, t } = this.props;
        if (!isAuthenticated) {
            return <Redirect to="/login" />;
        }
        if (this.state.leave_page && error === null) {
            this.props.history.push(`/projects/`);
        }
        // console.log(this.state);
        return (
            <Shell>
                {(create || project_details.id !== "") && (
                    <div>
                        {loading && (
                            <Loader inverted>Loading</Loader>
                        )}
                        <Header as="h3">{create ? (t("New project")) : (project_details.name)}</Header>
                        <Form error={error !== null}>
                            <Container>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column width={8}>
                                        {customer_options && (
                                            <Form.Field required>
                                                <label>{t("Customer")}</label>
                                                <Select
                                                    required
                                                    disabled={loading || !create}
                                                    fluid
                                                    search
                                                    name='customer'
                                                    options={customer_options}
                                                    placeholder={create ? (t("Select customer")) : (project_details.customer.id.toString())}
                                                    onChange={this.handleSelectChange}
                                                    value={project_details.customer.id}
                                                />
                                            </Form.Field>
                                        )}
                                        <Form.Field required>
                                            <label>{t("Project type")}</label>
                                            <Select
                                                required
                                                disabled={loading || fields_disabled}
                                                fluid
                                                search
                                                name='project_type'
                                                options={project_type_options}
                                                placeholder={create ? (t("Select project type")) : (project_details.project_type.toString())}
                                                onChange={this.handleSelectChange}
                                                value={project_details.project_type}
                                            />
                                        </Form.Field>

                                    </Grid.Column>
                                    <Grid.Column width={8}>
                                        <Form.Field required>
                                            <label>{t("Project group")}</label>
                                            <Select
                                                required
                                                disabled={loading || fields_disabled}
                                                fluid
                                                search
                                                name='project_group'
                                                options={project_group_options}
                                                placeholder={create ? (t("Select project group")) : (project_details.project_group.toString())}
                                                onChange={this.handleSelectChange}
                                                value={project_details.project_group}
                                            />
                                        </Form.Field>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                            </Container>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column width={8}>
                                        <Header as="h4">{t("Basic details")}</Header>
                                        <Form.Field required>
                                            <label>{t("Project name")}</label>
                                            <Input
                                                required
                                                disabled={loading || fields_disabled}
                                                value={project_details.name}
                                                name="name"
                                                onChange={this.handleChange} />
                                        </Form.Field>
                                        {company_users && (
                                            <Form.Field>
                                                <label>{t("Select responsible person")}</label>
                                                <Select
                                                    disabled={loading || fields_disabled}
                                                    fluid
                                                    search
                                                    name='responsible_person'
                                                    options={company_users}
                                                    placeholder={create ? (t("Responsible person")) : (project_details.responsible_person && (project_details.responsible_person.toString()))}
                                                    onChange={this.handleSelectChange}
                                                    value={project_details.responsible_person && (project_details.responsible_person)}
                                                />
                                            </Form.Field>
                                        )}
                                        <Grid>
                                            <Grid.Row >
                                                <Grid.Column width={7} >
                                                    <Form.Field>
                                                        <label>{t("Permanent")}</label>
                                                        <Radio
                                                            disabled={loading || fields_disabled}
                                                            name="periodSelectRadioGroup"
                                                            value="permanent"
                                                            checked={this.state.periodSelectRadioGroup === "permanent"}
                                                            onChange={this.handleRadioChange} />
                                                    </Form.Field>
                                                </Grid.Column>
                                                <Grid.Column width={7}>
                                                    <Form.Field>
                                                        <label>{t("Fixed term")}</label>
                                                        <Radio
                                                            disabled={loading || fields_disabled}
                                                            name="periodSelectRadioGroup"
                                                            value="fixed"
                                                            checked={this.state.periodSelectRadioGroup === "fixed"}
                                                            onChange={this.handleRadioChange} />
                                                    </Form.Field>
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>

                                        <Grid>
                                            <Grid.Row >
                                                <Grid.Column width={8} >
                                                    <Form.Field>
                                                        <label>{t("Start date")}</label>
                                                        <SemanticDatepicker
                                                            firstDayOfWeek={1}
                                                            disabled={loading || fields_disabled}
                                                            locale={fiLocale}
                                                            name="start_date"
                                                            pointing="top left"
                                                            size="small"
                                                            placeholder={project_details.start_date !== null ? (new Date(project_details.start_date).toLocaleString('fi-FI', { dateStyle: 'short' })) : ("")}
                                                            onDateChange={selected => this.handleStartDateChange(selected)}
                                                            format='DD.MM.YYYY'
                                                        />
                                                    </Form.Field>
                                                    <Form.Field>
                                                    <label>{t("Project budget")}</label>
                                                    <Input
                                                        disabled={loading || fields_disabled}
                                                        value={project_details.budget}
                                                        name="budget"
                                                        onChange={this.handleChange} />

                                                    </Form.Field>
                                                </Grid.Column>
                                                <Grid.Column width={8}>
                                                    {this.state.periodSelectRadioGroup === "fixed" && (
                                                        <Form.Field>
                                                            <label>{t("End date")}</label>
                                                            <SemanticDatepicker
                                                                firstDayOfWeek={1}
                                                                disabled={loading || fields_disabled}
                                                                locale={fiLocale}
                                                                name="end_date"
                                                                pointing="top left"
                                                                size="small"
                                                                placeholder={project_details.end_date !== null ? (new Date(project_details.end_date).toLocaleString('fi-FI', { dateStyle: 'short' })) : ("")}
                                                                onDateChange={selected => this.handleEndDateChange(selected)}
                                                                format='DD.MM.YYYY' />
                                                        </Form.Field>
                                                    )}
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                    </Grid.Column>
                                    <Grid.Column width={8}>
                                        <Header as="h4">{t("Invoicing details")}</Header>
                                        <Form.Field>
                                            <label>{t("Project comment")}</label>
                                            <Input
                                                disabled={loading || fields_disabled}
                                                value={project_details.comment}
                                                name="comment"
                                                onChange={this.handleChange} />
                                        </Form.Field>
                                        <Form.Field>
                                            <label>{t("Payment term")}</label>
                                            <Input
                                                disabled={loading || fields_disabled}
                                                type='number'
                                                value={project_details.payment_term}
                                                name="payment_term"
                                                onChange={this.handleChange} />
                                        </Form.Field>
                                        <Form.Field>
                                            <label>{t("Company reference")}</label>
                                            <Input
                                                disabled={loading || fields_disabled}
                                                value={project_details.company_reference}
                                                name="company_reference"
                                                onChange={this.handleChange} />
                                        </Form.Field>
                                        <Form.Field>
                                            <label>{t("Customer reference")}</label>
                                            <Input
                                                disabled={loading || fields_disabled}
                                                value={project_details.customer_reference}
                                                name="customer_reference"
                                                onChange={this.handleChange} />
                                        </Form.Field>
                                        <Form.Field>
                                            <label>{t("Purchase order")}</label>
                                            <Input
                                                disabled={loading || fields_disabled}
                                                value={project_details.purchase_order}
                                                name="purchase_order"
                                                onChange={this.handleChange} />
                                        </Form.Field>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                            <Header as="h3">{t("Project members")}</Header>
                            <Button type="button" disabled={loading || fields_disabled} floated="right" color="green" content={t("+ Project member")} onClick={() => this.openProjectMembersAddModal()} />

                            <Table celled striped selectable unstackable sortable>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>{t("First name")}</Table.HeaderCell>
                                        <Table.HeaderCell>{t("Last name")}</Table.HeaderCell>
                                        <Table.HeaderCell>{t("Role")}</Table.HeaderCell>
                                        <Table.HeaderCell />
                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                    {project_members_list.map((item, i) => {
                                        return (
                                            <Table.Row key={i + 1}>
                                                <Table.Cell collapsing onClick={() => this.openProjectMemberDetailsModal(item)}>{item.first_name}</Table.Cell>
                                                <Table.Cell collapsing onClick={() => this.openProjectMemberDetailsModal(item)}>{item.last_name}</Table.Cell>
                                                <Table.Cell collapsing onClick={() => this.openProjectMemberDetailsModal(item)}>{item.role}</Table.Cell>
                                                <Table.Cell collapsing textAlign='center'>
                                                    <Popup content={t("Deletes item straight away without needing to Save the project")} trigger={
                                                        <Icon link color="red" name='trash alternate outline' onClick={() => this.handleProjectMembersListChange(item, "", "remove")}/>
                                                        //<Button type="button" negative size='tiny' icon='remove' onClick={() => this.handleProjectMembersListChange(item, "", "remove")} />
                                                    } />
                                                </Table.Cell>
                                            </Table.Row>
                                        );
                                    })}
                                </Table.Body>
                            </Table>


                            <Header as="h3">{t("Project products")}</Header>
                            <Button type="button" disabled={loading || fields_disabled} floated="right" color="green" content={t("+ Project product")} onClick={() => this.openProjectProductsAddModal()} />

                            <Table celled striped selectable unstackable sortable>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>{t("Name")}</Table.HeaderCell>
                                        <Table.HeaderCell>{t("Code")}</Table.HeaderCell>
                                        <Table.HeaderCell>{t("Net Price")}</Table.HeaderCell>
                                        <Table.HeaderCell>{t("Vat")}</Table.HeaderCell>
                                        <Table.HeaderCell>{t("Discount")}</Table.HeaderCell>
                                        <Table.HeaderCell>{t("Min invoice amount (min)")}</Table.HeaderCell>
                                        <Table.HeaderCell>{t("Invoice amount period (min)")}</Table.HeaderCell>
                                        <Table.HeaderCell />
                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                    {project_products_list.map((item, i) => {
                                        return (
                                            <Table.Row key={i + 1}>
                                                <Table.Cell collapsing onClick={() => this.openProjectProductDetailsModal(item)}>{item.name}</Table.Cell>
                                                <Table.Cell collapsing onClick={() => this.openProjectProductDetailsModal(item)}>{item.code}</Table.Cell>
                                                <Table.Cell collapsing onClick={() => this.openProjectProductDetailsModal(item)}>{item.net_price}</Table.Cell>
                                                <Table.Cell collapsing onClick={() => this.openProjectProductDetailsModal(item)}>{item.vat_value}</Table.Cell>
                                                <Table.Cell collapsing onClick={() => this.openProjectProductDetailsModal(item)}>{item.discount}</Table.Cell>
                                                <Table.Cell collapsing onClick={() => this.openProjectProductDetailsModal(item)}>{item.minimum_invoice_amount}</Table.Cell>
                                                <Table.Cell collapsing onClick={() => this.openProjectProductDetailsModal(item)}>{item.invoice_amount_period}</Table.Cell>
                                                <Table.Cell collapsing textAlign='center'>
                                                    <Popup content={t("Deletes item straight away without needing to Save the project")} trigger={
                                                        <Icon link color="red" name='trash alternate outline' onClick={() => this.handleProjectProductsListChange(item, "", "remove")}/>
                                                        //<Button type="button" negative size='tiny' icon='remove' onClick={() => this.handleProjectProductsListChange(item, "", "remove")} />
                                                    } />
                                                </Table.Cell>
                                            </Table.Row>
                                        );
                                    })}
                                </Table.Body>
                            </Table>
                            {error && <Message error header={t("Error")} content={error} />}
                            {success_mgs && <Message positive header={success_mgs} />}
                            <Button primary type="button" onClick={(e) => this.handleSubmit(e)} loading={loading} disabled={loading || fields_disabled}>
                                {create ? (t("Create")) : (t("Save"))}
                            </Button>
                        </Form>
                    </div>
                )}
                {/* Project members select modal. Lists available company users and adds selected ones to the project members list */}
                <Modal size='tiny' open={project_member_select_modal_open} dimmer="inverted" scrolling="true">
                    <Modal.Header>
                        <Header as="h3">{t("Pick users to project")}</Header>
                        <Button type="button" positive icon='add circle' content={t("Add users to project")} onClick={() => this.handleAddProjectMembersToProject()} />
                        <Button type="button" floated='right' negative onClick={() => this.closeProjectMembersAddModal()} >{t("Close")}</Button>
                    </Modal.Header>
                    <Modal.Content>
                        <Container>
                            {company_user_objects && (
                                <React.Fragment>
                                    <Header as="h3" color="blue">{t("Users")}</Header>
                                    {company_user_objects.length > 0 && (
                                        <Table striped selectable unstackable size='small'>
                                            <Table.Header>
                                                <Table.Row>
                                                    <Table.HeaderCell/>
                                                    <Table.HeaderCell content={t("Name")} />
                                                </Table.Row>
                                            </Table.Header>
                                            <Table.Body>
                                                {company_user_objects.map((user, key) => {
                                                    return (
                                                        <React.Fragment key={key}>
                                                            {user.is_active && (
                                                                <Table.Row key={key} >
                                                                    <Table.Cell collapsing>
                                                                        <Checkbox
                                                                            value={user}
                                                                            onChange={this.handleAddProjectMembersCheckboxChange} />
                                                                    </Table.Cell>
                                                                    <Table.Cell>
                                                                        {user.first_name} {user.last_name}
                                                                    </Table.Cell>
                                                                </Table.Row>
                                                            )}
                                                        </React.Fragment>
                                                    );
                                                })}
                                            </Table.Body>
                                        </Table>
                                    )}
                                </React.Fragment>
                            )}
                        </Container>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button type="button" negative onClick={() => this.closeProjectMembersAddModal()} >{t("Close")}</Button>
                    </Modal.Actions>
                </Modal>

                {/* Project members update modal. */}
                <Modal size='small' open={project_member_details_modal_open} dimmer="inverted" scrolling="true">
                    <Modal.Header>
                        <Header as="h3">{t("Edit project member")}</Header>
                    </Modal.Header>
                    <Modal.Content>
                        <Container>
                            {active_project_member && (
                                <Form error={error !== null}>
                                    <Form.Field>
                                        <label>{t("First name")}</label>
                                        <Input
                                            value={active_project_member.first_name}
                                            name="first_name"
                                            onChange={this.handleProjectMemberDetailsChange} />
                                    </Form.Field>
                                    <Form.Field>
                                        <label>{t("Last name")}</label>
                                        <Input
                                            value={active_project_member.last_name}
                                            name="last_name"
                                            onChange={this.handleProjectMemberDetailsChange} />
                                    </Form.Field>
                                    <Form.Field>
                                        <label>{t("Role")}</label>
                                        <Input
                                            value={active_project_member.role}
                                            name="role"
                                            onChange={this.handleProjectMemberDetailsChange} />
                                    </Form.Field>

                                    {error && <Message error header={t("Error")} content={error} />}
                                    {success_mgs && <Message positive header={success_mgs} />}
                                    <Button primary type="button" onClick={(e) => this.handleProjectMemberDetailsSubmit(e)} loading={loading} disabled={loading}>
                                        {t("Save")}
                                    </Button>
                                </Form>
                            )}
                        </Container>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button type="button" negative onClick={() => this.closeProjectMemberDetailsModal()} >{t("Close")}</Button>
                    </Modal.Actions>
                </Modal>


                {/* Project products select modal. Lists available products and adds selected ones to the project products list */}
                <Modal size='small' open={project_product_select_modal_open} dimmer="inverted" scrolling="true">
                    <Modal.Header>
                        <Header as="h3">{t("Pick products and services")}</Header>
                        <Button type="button" positive icon='add circle' content={t("Add products to project")} onClick={() => this.handleAddProjectProductsToProject()} />
                        <Button type="button" floated='right' negative onClick={() => this.closeProjectProductsAddModal()} >{t("Close")}</Button>
                    </Modal.Header>
                    <Modal.Content>
                        <Container>
                            {product_groups && (
                                <React.Fragment>
                                    <Header as="h3" color="blue">{t("Products")}</Header>
                                    {product_groups.map(group => {
                                        return (
                                            <React.Fragment key={group.id}>
                                                {group.products.length > 0 && (
                                                    <Table striped selectable unstackable size='small'>
                                                        <Table.Header>
                                                            <Table.Row>
                                                                <Table.HeaderCell colSpan='2'>{group.name}</Table.HeaderCell>
                                                                <Table.HeaderCell content={t("Net price")} />
                                                            </Table.Row>
                                                        </Table.Header>
                                                        <Table.Body>
                                                            {group.products.map((product, key) => {
                                                                return (
                                                                    <React.Fragment key={key}>
                                                                        {product.is_active && (
                                                                            <Table.Row key={key} >
                                                                                <Table.Cell collapsing>
                                                                                    <Checkbox
                                                                                        value={product}
                                                                                        onChange={this.handleAddProjectProductsCheckboxChange} />
                                                                                </Table.Cell>
                                                                                <Table.Cell>
                                                                                    <b>{product.name}</b> <Header size='small' floated="right">({product.code})</Header>
                                                                                </Table.Cell>
                                                                                <Table.Cell collapsing textAlign='right'>
                                                                                    <Header as="h4">{product.net_price} €</Header>
                                                                                </Table.Cell>
                                                                            </Table.Row>
                                                                        )}
                                                                    </React.Fragment>
                                                                );
                                                            })}
                                                        </Table.Body>
                                                    </Table>
                                                )}
                                            </React.Fragment>
                                        );
                                    })}
                                </React.Fragment>
                            )}
                        </Container>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button type="button" negative onClick={() => this.closeProjectProductsAddModal()} >{t("Close")}</Button>
                    </Modal.Actions>
                </Modal>

                {/* Project products update modal. */}
                <Modal size='small' open={project_product_details_modal_open} dimmer="inverted" scrolling="true">
                    <Modal.Header>
                        <Header as="h3">{t("Edit project product")}</Header>
                    </Modal.Header>
                    <Modal.Content>
                        <Container>
                            {active_project_product && (
                                <Form error={error !== null}>
                                    <Form.Field>
                                        <label>{t("Name")}</label>
                                        <Input
                                            value={active_project_product.name}
                                            name="name"
                                            onChange={this.handleProjectProductDetailsChange} />
                                    </Form.Field>
                                    <Form.Field>
                                        <label>{t("Code")}</label>
                                        <Input
                                            value={active_project_product.code}
                                            name="code"
                                            onChange={this.handleProjectProductDetailsChange} />
                                    </Form.Field>
                                    <Form.Field>
                                        <label>{t("Net price")}</label>
                                        <Input
                                            value={active_project_product.net_price}
                                            name="net_price"
                                            onChange={this.handleProjectProductDetailsChange} />
                                    </Form.Field>
                                    <Form.Field>
                                        <label>{t("Discount %")}</label>
                                        <Input
                                            value={active_project_product.discount}
                                            name="discount"
                                            onChange={this.handleProjectProductDetailsChange} />
                                    </Form.Field>
                                    <Form.Field>
                                        <label>{t("Vat %")}</label>
                                        <Input
                                            value={active_project_product.vat_value}
                                            name="vat_value"
                                            onChange={this.handleProjectProductDetailsChange} />
                                    </Form.Field>
                                    <Form.Field>
                                        <label>{t("Min invoice amount (min)")}</label>
                                        <Input
                                            value={active_project_product.minimum_invoice_amount}
                                            name="minimum_invoice_amount"
                                            onChange={this.handleProjectProductDetailsChange} />
                                    </Form.Field>
                                    <Form.Field>
                                        <label>{t("Invoice amount period (min)")}</label>
                                        <Input
                                            value={active_project_product.invoice_amount_period}
                                            name="invoice_amount_period"
                                            onChange={this.handleProjectProductDetailsChange} />
                                    </Form.Field>


                                    {error && <Message error header={t("Error")} content={error} />}
                                    {success_mgs && <Message positive header={success_mgs} />}
                                    <Button primary type="button" onClick={(e) => this.handleProjectProductDetailsSubmit(e)} loading={loading} disabled={loading}>
                                        {t("Save")}
                                    </Button>
                                </Form>
                            )}
                        </Container>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button type="button" negative onClick={() => this.closeProjectProductDetailsModal()} >{t("Close")}</Button>
                    </Modal.Actions>
                </Modal>

            </Shell>
        );
    }
}
const mapStateToProps = state => {
    return {
        activeCompanyId: state.company.activeCompanyId,
        isAuthenticated: state.auth.token !== null
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setActivity: () => dispatch(setActivity())
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(withTranslation('projectdetails')(ProjectDetails))
);