// Copyright Caproc Oy
// System name: Aqua-Net 
// System module: Company
// Author: TS
//
// 1.2.2020 TS Initial version
// 20.6.2020 TS Added export to CSV

import React from "react";
import { Message, Header, Button, Table, Segment, Dimmer, Loader, Image, Menu, Responsive } from "semantic-ui-react";
import Shell from "./Shell";
import { authAxios, exportToCsv, getWidth } from "../../utils";
import { setActivity } from "../../store/actions/auth";
import { withRouter, Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import { companyUsersListURL, companyUserRoleOptionsURL } from "../../constants";


class CompanyUsers extends React.Component {
    state = {
        users: null,
        role_options: null,
        activeMenuItem: this.props.t("Active"),
        error: null,
        loading: false
    };

    componentDidMount() {
        this.props.setActivity();
        this.fetctRoleOptions();
        this.handleFetchUserList();
    }

    fetctRoleOptions = () => {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;

        authAxios
            .get(companyUserRoleOptionsURL(activeCompanyId))
            .then(res => {
                this.setState({
                    role_options: res.data,
                    error: null,
                    loading: false,
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    error: err
                });
            });

    };

    handleFetchUserList = () => {
        this.setState({
            loading: true,
        });
        const { activeCompanyId } = this.props;
        authAxios
            .get(companyUsersListURL(activeCompanyId))
            .then(res => {
                this.setState({
                    users: res.data,
                    error: null,
                    loading: false
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    error: err
                });
            });
    };

    userDetails = (id) => {
        this.props.history.push(`/company/user-details/${id}/`)
    };

    handleMenuItemClick = (e, { name }) => this.setState({ activeMenuItem: name });

    handleExportToCSV = items => {
        const { t } = this.props;
        if (items !== null) {
            const titles = [
                t("First name"),
                t("Last name"),
                t("Title"),
                t("Email"),
                t("Phone"),
                t("Role")
            ];
            let items_to_csv = [];
            let i = 0;
            let roles = "";
            let x = 0;
            for (i in items) {
                roles = "";
                for (x in items[i].userroles) {
                    if (x > 0) {
                        roles += "," + t(items[i].userroles[x].role_name);
                    } else {
                        roles = t(items[i].userroles[x].role_name);
                    }
                }
                items_to_csv.push({
                    first_name: items[i].first_name,
                    last_name: items[i].last_name,
                    title: items[i].title,
                    email: items[i].email,
                    phone: items[i].phone_number,
                    roles: roles
                });
            }
            exportToCsv(this.props.activeCompanyId, "export", titles, items_to_csv);
        }
    };

    render() {
        const { error, loading, users, activeMenuItem } = this.state;
        const { isAuthenticated, activeCompanyId, t } = this.props;
        if (!isAuthenticated) {
            return <Redirect to="/login" />;
        }
        // console.log(this.state);
        const is_desktop =  getWidth() > Responsive.onlyMobile.maxWidth ? true : false;
        return (
            <Shell>
                {is_desktop && (
                    <Button.Group floated="right" basic size='small'>
                        <Button icon="download" content="CSV" onClick={() => this.handleExportToCSV(users)} />
                    </Button.Group>
                )}

                <Header as="h3">{t("Company users")}</Header>
                <Link to={`/company/user-details/create/`}>
                    <Button type="button" floated="right" color="green">
                        {t("+ User")}
                    </Button>
                </Link>

                {error && (
                    <Message
                        error
                        header={t("There was an error")}
                        content={JSON.stringify(error)}
                    />
                )}
                {loading && (
                    <Segment>
                        <Dimmer active inverted>
                            <Loader inverted>Loading</Loader>
                        </Dimmer>
                        <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
                    </Segment>
                )}
                <Menu attached="top" tabular>
                    <Menu.Item
                        name={t("Active")}
                        active={activeMenuItem === t("Active")}
                        onClick={this.handleMenuItemClick} />
                    <Menu.Item
                        name={t("Archive")}
                        active={activeMenuItem === t("Archive")}
                        onClick={this.handleMenuItemClick} />
                </Menu>
                {users && (
                    <Table striped selectable celled>
                        {is_desktop && (
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>{t("First name")}</Table.HeaderCell>
                                    <Table.HeaderCell>{t("Last name")}</Table.HeaderCell>
                                    <Table.HeaderCell>{t("Title")}</Table.HeaderCell>
                                    <Table.HeaderCell>{t("Email")}</Table.HeaderCell>
                                    <Table.HeaderCell>{t("Phone")}</Table.HeaderCell>
                                    <Table.HeaderCell>{t("Role")}</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                        )}
                        <Table.Body>
                            {users.map((user) => {
                                if ((activeMenuItem === t("Active") && user.is_active) || (activeMenuItem === t("Archive") && !user.is_active)) {
                                    return (
                                        <Table.Row key={user.id} onClick={() => this.userDetails(user.id)}>
                                            <Table.Cell>
                                                {!is_desktop && (<React.Fragment><b>{t("First name")}</b><br/></React.Fragment>)}
                                                {user.first_name}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {!is_desktop && (<React.Fragment><b>{t("Last name")}</b><br/></React.Fragment>)}
                                                {user.last_name}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {!is_desktop && (<React.Fragment><b>{t("Title")}</b><br/></React.Fragment>)}
                                                {user.title}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {!is_desktop && (<React.Fragment><b>{t("Email")}</b><br/></React.Fragment>)}
                                                {user.email}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {!is_desktop && (<React.Fragment><b>{t("Phone")}</b><br/></React.Fragment>)}
                                                {user.phone_number}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {!is_desktop && (<React.Fragment><b>{t("Role")}</b><br/></React.Fragment>)}
                                                {user.userroles.map(role => {
                                                    if (parseInt(role.company) === parseInt(activeCompanyId))
                                                        return (
                                                            <React.Fragment>
                                                                {role.role === "C" ? (
                                                                    <>
                                                                        {`${t(role.role_name)} / ${role.customer.name}`} <br/>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        {t(role.role_name)} <br/>
                                                                    </>
                                                                )}
                                                            </React.Fragment>
                                                        );
                                                })}
                                            </Table.Cell>
                                        </Table.Row>
                                    );
                                }
                            })}
                        </Table.Body>
                    </Table>
                )}
            </Shell>
        );
    }
}
const mapStateToProps = state => {
    return {
        activeCompanyId: state.company.activeCompanyId,
        isAuthenticated: state.auth.token !== null
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setActivity: () => dispatch(setActivity())
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(withTranslation('companyusers')(CompanyUsers))
);