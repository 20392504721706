// Copyright Caproc Oy
// System name: Aqua-Net 
// System module: Company
// Author: TS
//
// 1.2.2020 TS Initial version
// 21.6.2021 TS Added use_default_sensor_last_estimate
// 24.6.2021 TS Added timestamps to last_value, last_invoiced_value and last_estimate titles.

import React from "react";
import { Form, Input, Message, Header, Button, Select, Checkbox, Table, Accordion, Modal, Icon, Container, Popup } from "semantic-ui-react";
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import fiLocale from 'react-semantic-ui-datepickers/dist/locales/fi-FI';
import Shell from "./Shell";
import { authAxios, getISOFormattedDate } from "../../utils";
import { setActivity } from "../../store/actions/auth";
import { withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import { customerDataCollectionDetailsURL, updateCustomerDataCollectionURL, createMultipleCustomerDataCollectionURL, customerDetailsURL, fetchProductsURL, fetchActiveSensorsURL, fetchCustomersURL } from "../../constants";


class CustomerDataCollectionDetails extends React.Component {
    state = {
        dc_details: {
            id: "",
            company: "",
            customer: "",
            product: "",
            sensor: "",
            sensor_status_variable: "",
            name: "",
            description: "",
            source_id: "",
            location_id: "",
            start_time: "",
            is_scheduled: false,
            scheduled_frequency: "",
            next_scheduled_event: "",
            start_value: 0,
            last_value: 0,
            last_invoiced_value: 0,
            last_estimate: 0,
            api_address: "",
            api_user: "",
            api_key: "",
            api_data: "",
            is_active: true,
            author: "",
            use_default_sensor: false,
            use_default_location: false,
            use_default_sensor_last_value: false,
            use_default_sensor_last_estimate: false
        },
        start_time_date: null,
        start_time_timestamp: null,
        product_options: null,
        sensor_options: null,
        sensor_status_variable_options: null,
        customer_id: null,
        customer_name: null,
        error: null,
        success_mgs: null,
        loading: false,
        create: false,
        activeIndex: 0,
        frequencyOptions: [
            { key: '15mm', value: '15mm', text: '15 min' },
            { key: '30mm', value: '30mm', text: '30 min' },
            { key: '1HH', value: '1HH', text: '1 hour' },
            { key: '1DD', value: '1DD', text: '1 day' },
            { key: '1W', value: '1W', text: '1 week' },
            { key: '1MM', value: '1MM', text: '1 month' },
            { key: '1YYYY', value: '1YYYY', text: '1 year' },
        ],
        customers: null,
        customers_select_modal_open: false,
        selected_customers_list: [],
        search: "",
        customers_list: [],
        all_customers_selected: false
    };

    componentDidMount() {
        this.props.setActivity();
        
        this.fetchProducts();
        this.fetchSensors();

        if (this.props.match.params.customer_data_collection_id === "create") {
            this.setState({
                create: true
            })
            this.handleFetchCustomers();
        } else {
            this.fetchCustomerDetails();
            this.handleDetails();
        }
    }

    handleFetchCustomers() {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;

        authAxios
            .get(fetchCustomersURL(activeCompanyId))
            .then(res => {
                this.setState({
                    customers: res.data,
                    error: null,
                    success_mgs: null,
                    loading: false,
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    success_mgs: null,
                    error: err
                });
            });
    };

    fetchProducts() {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;

        authAxios
            .get(fetchProductsURL(activeCompanyId))
            .then(res => {
                this.setState({
                    product_options: this.handleFormatOptions(res.data),
                    error: null,
                    success_mgs: null,
                    loading: false,
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    success_mgs: null,
                    error: err
                });
            });

    }

    handleFormatOptions = options => {
        const keys = Object.keys(options);
        return keys.map(k => {
            return {
                key: options[k].id,
                text: options[k].name,
                value: options[k]
            };
        });
    };

    fetchSensors() {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;

        authAxios
            .get(fetchActiveSensorsURL(activeCompanyId))
            .then(res => {
                this.setState({
                    sensor_options: this.handleFormatSensorOptions(res.data),
                    error: null,
                    success_mgs: null,
                    loading: false,
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    success_mgs: null,
                    error: err
                });
            });

    }

    handleFormatSensorOptions = options => {
        const keys = Object.keys(options);
        return keys.map(k => {
            return {
                key: options[k].id,
                text: `${options[k].local_name} ${options[k].device_eui}`,
                value: options[k]
            };
        });
    };

    handleFormatSensorStatusVariableOptions = (sensor, options) => {
        const keys = Object.keys(options);
        return keys.map(k => {
            return {
                key: options[k].id,
                text: `${sensor.local_name} ${options[k].group} ${options[k].display_name}`,
                value: options[k]
            };
        });
    };


    fetchCustomerDetails() {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;
        const { customer_id } = this.props.match.params;

        authAxios
            .get(customerDetailsURL(activeCompanyId, customer_id))
            .then(res => {
                this.setState({
                    customer_name: res.data.name,
                    customer_id: res.data.id,
                    error: null,
                    success_mgs: null,
                    loading: false,
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    success_mgs: null,
                    error: err
                });
            });
    }

    handleDetails = () => {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;
        const { customer_id, customer_data_collection_id } = this.props.match.params;
        authAxios
            .get(customerDataCollectionDetailsURL(activeCompanyId, customer_id, customer_data_collection_id))
            .then(res => {
                this.setState({
                    dc_details: res.data,
                    sensor_status_variable_options: res.data.sensor.device_eui ? (this.handleFormatSensorStatusVariableOptions(res.data.sensor, res.data.sensor.sensorstatusvariables)) : (null),
                    error: null,
                    success_mgs: null,
                    loading: false
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    success_mgs: null,
                    error: err
                });
            });
    };

    handleLastInvoicedValueDateChange = value => {
        const { dc_details } = this.state;
        const updatedDataCollectionDetails = {
            ...dc_details,
            last_invoiced_value_time: getISOFormattedDate(value, "Date") + "T00:00:00",
        };
        this.setState({
            dc_details: updatedDataCollectionDetails
        });
    };

    handleLastValueDateChange = value => {
        const { dc_details } = this.state;
        const updatedDataCollectionDetails = {
            ...dc_details,
            last_value_time: getISOFormattedDate(value, "Date") + "T00:00:00",
        };
        this.setState({
            dc_details: updatedDataCollectionDetails
        });
    };

    handleLastEstimateDateChange = value => {
        const { dc_details } = this.state;
        const updatedDataCollectionDetails = {
            ...dc_details,
            last_estimate_time: getISOFormattedDate(value, "Date") + "T00:00:00",
        };
        this.setState({
            dc_details: updatedDataCollectionDetails
        });
    };

    handleChange = e => {
        const { dc_details } = this.state;
        const updatedDataCollectionDetails = {
            ...dc_details,
            [e.target.name]: e.target.value,
        };
        this.setState({
            dc_details: updatedDataCollectionDetails,
            success_mgs: null,
            error: null
        });
    };

    handleSelectChange = (e, { name, value }) => {
        const { dc_details } = this.state;
        // Update next_scheduled event if "start_time" or "scheduled_frequency" is changed
        if ((name === "start_time" && dc_details.scheduled_frequency !== "") || name === "scheduled_frequency") {
            const { t } = this.props;
            if (dc_details.start_time !== "") {
                let tmp_value = "";
                let tmp_scheduled_event = "";
                // Switch values depending on which detail is changed
                if (name === "start_time") {
                    tmp_value = dc_details.scheduled_frequency;
                    tmp_scheduled_event = new Date(value);
                } else {
                    tmp_value = value;
                    tmp_scheduled_event = new Date(dc_details.start_time);
                }

                // Add get selected event frequency and add relevant time to get timestamp for next scheduled event
                // Minutes
                if (tmp_value.includes("mm")) {
                    tmp_scheduled_event.setMinutes(tmp_scheduled_event.getMinutes() + parseInt(tmp_value.replace("mm", "")));
                    // Hours
                } else if (tmp_value.includes("HH")) {
                    tmp_scheduled_event.setHours(tmp_scheduled_event.getHours() + parseInt(tmp_value.replace("HH", "")));
                    // Days
                } else if (tmp_value.includes("DD")) {
                    tmp_scheduled_event.setDate(tmp_scheduled_event.getDate() + parseInt(tmp_value.replace("DD", "")));
                    // Weeks
                } else if (tmp_value.includes("W")) {
                    tmp_scheduled_event.setDate(tmp_scheduled_event.getDate() + (parseInt(tmp_value.replace("DD", "")) * 7));
                    // Months
                } else if (tmp_value.includes("MM")) {
                    tmp_scheduled_event.setMonth(tmp_scheduled_event.getMonth() + parseInt(tmp_value.replace("MM", "")));
                    // Years
                } else if (tmp_value.includes("YYYY")) {
                    tmp_scheduled_event.setFullYear(tmp_scheduled_event.getFullYear() + parseInt(tmp_value.replace("YYYY", "")));
                }

                const updatedDataCollectionDetails = {
                    ...dc_details,
                    next_scheduled_event: getISOFormattedDate(tmp_scheduled_event, "DateTime"),
                    [name]: value
                };
                this.setState({
                    dc_details: updatedDataCollectionDetails,
                    success_mgs: null,
                    error: null
                });
            } else {
                this.setState({
                    success_mgs: null,
                    error: t("Please add Start Time first")
                });
            }
        } else {
            let updatedDataCollectionDetails = {};
            if (name === "product") {
                updatedDataCollectionDetails = {
                    ...dc_details,
                    [name]: value,
                    name: value.name
                };
            } else if (name === "sensor") {
                updatedDataCollectionDetails = {
                    ...dc_details,
                    [name]: value,
                    source_id: value.device_eui                
                };
                this.setState({
                    sensor_status_variable_options: this.handleFormatSensorStatusVariableOptions(value, value.sensorstatusvariables)
                });
            } else {
                updatedDataCollectionDetails = {
                    ...dc_details,
                    [name]: value
                };
            }

            this.setState({
                dc_details: updatedDataCollectionDetails,
                success_mgs: null,
                error: null
            });
        }
    };

    handleCheckboxChange = (e, { name, value }) => {
        const { dc_details } = this.state;
        const updatedDataCollectionDetails = {
            ...dc_details,
            [name]: !value
        };
        this.setState({
            dc_details: updatedDataCollectionDetails
        });
    };

    handleClick = (e, titleProps) => {
        const { index } = titleProps
        const { activeIndex } = this.state
        const newIndex = activeIndex === index ? -1 : index

        this.setState({ activeIndex: newIndex })
    };

    handleSubmit = e => {
        e.preventDefault();
        this.setState({
            loading: true
        });
        const { create, dc_details, customers_list } = this.state;
        const { activeCompanyId, t } = this.props;
        const { customer_id } = this.props.match.params;
        if (dc_details.product === "") {
            this.setState({
                error: t("Please select Product"),
                success_mgs: null,
                loading: false
            });
        } else if (!create && dc_details.source_id === "") {
            this.setState({
                error: t("Please add Source Id"),
                success_mgs: null,
                loading: false
            });
        } else if (create && customers_list.length === 0) {
            this.setState({
                error: t("Please select customers"),
                success_mgs: null,
                loading: false
            });
        } else if (dc_details.description.length > 100) {
            this.setState({
                error: t("Max amount of characters is 100 for Description"),
                success_mgs: null,
                loading: false
            });
        } else if (dc_details.source_id.length > 50) {
            this.setState({
                error: t("Max amount of characters is 50 for Source Id"),
                success_mgs: null,
                loading: false
            });
        } else if (dc_details.location_id.length > 50) {
            this.setState({
                error: t("Max amount of characters is 50 for Location Id"),
                success_mgs: null,
                loading: false
            });
        } else if (dc_details.sensor.id !== "" && dc_details.sensor_status_variable.id === "") {
            this.setState({
                error: t("Please select Sensor status variable"),
                success_mgs: null,
                loading: false
            });
        } else if (dc_details.sensor.id !== undefined && dc_details.sensor_status_variable.id === undefined) {
            this.setState({
                error: t("Please select Status variable"),
                success_mgs: null,
                loading: false
            });
        } else if (dc_details.sensor.id !== undefined && dc_details.sensor_status_variable.id !== undefined && dc_details.sensor.id !== dc_details.sensor_status_variable.sensor) {
            this.setState({
                error: t("Please select Status variable"),
                success_mgs: null,
                loading: false
            });

        } else {
            if (create) {
                const updated_dc_details = {
                    ...dc_details,
                    company: activeCompanyId,
                    product: dc_details.product.id,
                    sensor: dc_details.sensor.id !== undefined ? (dc_details.sensor.id) : (""),
                    sensor_status_variable: dc_details.sensor_status_variable.id !== undefined ? (dc_details.sensor_status_variable.id) : (""),
                    author: localStorage.getItem("UserID")
                }
                authAxios
                    .post(createMultipleCustomerDataCollectionURL(activeCompanyId), {
                        data_collection_detail: updated_dc_details,
                        customers: customers_list
                    })
                    .then(res => {
                        this.props.history.push(`/company/customer-data-collections/`);
                    })
                    .catch(err => {
                        this.setState({
                            loading: false,
                            success_mgs: null,
                            error: err
                        });
                    });

            } else {
                const updated_dc_details = {
                    ...dc_details,
                    product: dc_details.product.id,
                    sensor: dc_details.sensor.id,
                    sensor_status_variable: dc_details.sensor_status_variable.id
                }

                authAxios
                    .put(updateCustomerDataCollectionURL(activeCompanyId, customer_id, dc_details.id), {
                        ...updated_dc_details
                    })
                    .then(res => {
                        this.props.history.push(`/company/customer-data-collections/`);
                    })
                    .catch(err => {
                        this.setState({
                            loading: false,
                            success_mgs: null,
                            error: err
                        });
                    });
            }
        }
    };

    openCustomersAddModal = () => {
        const { customers_list } = this.state;
        let i = 0;
        let existing_list = [];
        for (i in customers_list) {
            existing_list.push(customers_list[i]);
        }
        this.setState({
            customers_select_modal_open: true,
            selected_customers_list: existing_list,
            error: null
        })
    };

    closeCustomersAddModal = () => {
        this.setState({
            customers_select_modal_open: false,
            selected_customers_list: [],
        })
    };

    handleSearchChange = e => {
        this.setState({
            [e.target.name]: e.target.value,
            error: null
        });
    };

    handleCheckIfSelected = item => {
        const { selected_customers_list } = this.state;
        let i = 0;
        let return_value = false;
        for (i in selected_customers_list) {
            if (item === selected_customers_list[i]) {
                return_value = true;
            }
        }
        return return_value;
    };

    handleAddCustomersCheckboxChange = (e, { value, checked }) => {
        const { selected_customers_list } = this.state;
        let updatedList = selected_customers_list;
        if (checked) {
            if (!updatedList.includes(value)) {
                updatedList.push(value);
            }
        } else {
            if (updatedList.includes(value)) {
                updatedList.splice(updatedList.indexOf(value), 1);
            }
        }
        this.setState({ selected_customers_list: updatedList });
    };

    handleAddCustomers = () => {
        this.setState({
            customers_list: this.state.selected_customers_list
        });
        this.closeCustomersAddModal();
    };

    handleRemoveCustomerFromList = customer => {
        const { customers_list } = this.state;
        let updatedList = customers_list;
        if (updatedList.includes(customer)) {
            updatedList.splice(updatedList.indexOf(customer), 1);
        }
        this.setState({ customers_list: updatedList });
    };

    selectAllCustomers = customers => {
        if (this.state.all_customers_selected) {
            this.setState({
                selected_customers_list: [],
                all_customers_selected: false
            });
        } else {
            this.setState({
                selected_customers_list: customers,
                all_customers_selected: true
            });
        }
    };

    render() {
        const {
            dc_details,
            product_options,
            sensor_options,
            sensor_status_variable_options,
            customer_name,
            error,
            success_mgs,
            loading,
            create,
            customers,
            search,
            customers_list,
            customers_select_modal_open,
            activeIndex
        } = this.state;
        const { isAuthenticated, t } = this.props;
        if (!isAuthenticated) {
            return <Redirect to="/login" />;
        }
        let filtered_list = [];
        if (customers) {
            filtered_list = customers.filter((customer) => {
                return customer.name.toLowerCase().indexOf(search.toLocaleLowerCase()) !== -1 ||
                    customer.vat.toLowerCase().indexOf(search.toLocaleLowerCase()) !== -1 ||
                    customer.address.toLowerCase().indexOf(search.toLocaleLowerCase()) !== -1 ||
                    customer.postal_code.toLowerCase().indexOf(search.toLocaleLowerCase()) !== -1 ||
                    customer.city.toLowerCase().indexOf(search.toLocaleLowerCase()) !== -1 ||
                    customer.customer_number.toString().toLowerCase().indexOf(search.toLocaleLowerCase()) !== -1;
                    
            })
        }
        // console.log(this.state);
        return (
            <React.Fragment>
                {(create || dc_details.id !== "") && (
                    <Shell >
                        {!create ? (
                            <Header as="h4">{customer_name} {t("data collection details")}</Header>
                        ) : (
                            <React.Fragment>
                                <Header as="h4">{t("Data collection details")}</Header>
                                <Button type="button" color="green" content={t("+ Customers")} onClick={() => this.openCustomersAddModal()} />   
                                <br/>
                                {customers_list.length > 0 && (
                                    <Accordion>
                                        <Accordion.Title
                                            active={activeIndex === 1}
                                            index={1}
                                            onClick={this.handleClick}>
                                            <Icon name='dropdown' />
                                            <b>{t("Selected customers")}</b>
                                        </Accordion.Title>
                                        <Accordion.Content active={activeIndex === 1}>
                                            <Table striped selectable size='small'>
                                                <Table.Header>
                                                    <Table.Row>
                                                        <Table.HeaderCell>{t("Name")}</Table.HeaderCell>
                                                        <Table.HeaderCell>{t("Address")}</Table.HeaderCell>
                                                        <Table.HeaderCell>{t("VAT")}</Table.HeaderCell>
                                                        <Table.HeaderCell/>
                                                    </Table.Row>
                                                </Table.Header>
                                                <Table.Body>
                                                    {customers_list.map((customer) => {
                                                        if (customer.is_active) {
                                                            return (
                                                                <Table.Row key={customer.id}>
                                                                    <Table.Cell >
                                                                        {customer.name}
                                                                    </Table.Cell>
                                                                    <Table.Cell >{customer.address}, {customer.postal_code} {customer.city}</Table.Cell>
                                                                    <Table.Cell >{customer.vat}</Table.Cell>
                                                                    <Table.Cell><Icon link name="trash alternate outline" onClick={() => this.handleRemoveCustomerFromList(customer)}/></Table.Cell>
                                                                </Table.Row>
                                                            );
                                                        }
                                                    })}
                                                </Table.Body>
                                            </Table>
                                        </Accordion.Content>
                                    </Accordion>
                                )}   
                                <br/>
                            </React.Fragment>   
                        )}
                        <Form error={error !== null}>
                            {product_options && (
                                <Form.Field>
                                    <label>{t("Product")}</label>
                                    <Select
                                        search
                                        fluid
                                        name='product'
                                        options={product_options}
                                        placeholder={create ? (t("Select product")) : (dc_details.product.name)}
                                        onChange={this.handleSelectChange}
                                        value={dc_details.product}
                                    />
                                </Form.Field>
                            )}
                            <Form.Field>
                                <label>{t("Description")}</label>
                                <Input
                                    value={dc_details.description}
                                    name="description"
                                    onChange={this.handleChange} />
                            </Form.Field>
                            <React.Fragment>
                                <Form.Field>
                                    <label>{t("Location id")}</label>
                                    <Input
                                        disabled={dc_details.use_default_location}
                                        value={dc_details.location_id}
                                        name="location_id"
                                        onChange={this.handleChange} />
                                </Form.Field>
                                {create && (
                                    <Form.Field>
                                        <Checkbox
                                            label={<label>{t("Use default location number from customer invoicing details")}</label>}
                                            name="use_default_location"
                                            onChange={this.handleCheckboxChange}
                                            checked={dc_details.use_default_location}
                                            value={dc_details.use_default_location}
                                        />
                                    </Form.Field>
                                )}
                                {sensor_options && (
                                    <Form.Field>
                                        <label>{t("Sensor")}</label>
                                        <Select
                                            search
                                            fluid
                                            name='sensor'
                                            options={sensor_options}
                                            placeholder={create ? (t("Select sensor")) : (`${dc_details.sensor.local_name} ${dc_details.device_eui}`)}
                                            onChange={this.handleSelectChange}
                                            value={dc_details.sensor}
                                        />
                                    </Form.Field>
                                )}
                                {sensor_status_variable_options && (
                                    <Form.Field>
                                        <label>{t("Status variable")}</label>
                                        <Select
                                            search
                                            fluid
                                            name='sensor_status_variable'
                                            options={sensor_status_variable_options}
                                            placeholder={create ? (t("Select status variable")) : (`${dc_details.sensor_status_variable.group} ${dc_details.sensor_status_variable.display_name}`)}
                                            onChange={this.handleSelectChange}
                                            value={dc_details.sensor_status_variable}
                                        />
                                    </Form.Field>
                                )}
                                <Form.Field >
                                    <label>{t("Source id")}</label>
                                    <Input
                                        disabled={dc_details.use_default_sensor}
                                        value={dc_details.source_id}
                                        name="source_id"
                                        onChange={this.handleChange} />
                                </Form.Field>
                                {create && (
                                    <Form.Field>
                                        <Checkbox
                                            label={<label>{t("Use default sensor number from customer invoicing details")}</label>}
                                            name="use_default_sensor"
                                            onChange={this.handleCheckboxChange}
                                            checked={dc_details.use_default_sensor}
                                            value={dc_details.use_default_sensor}
                                        />
                                    </Form.Field>
                                )}
                                <Form.Field>
                                    <label>{t("Start value")}</label>
                                    <Input
                                        disabled={dc_details.use_default_sensor_last_value}
                                        value={dc_details.start_value}
                                        name="start_value"
                                        onChange={this.handleChange} />
                                </Form.Field>
                                <Form.Field>
                                    <label>{t("Last value")} ({new Date(dc_details.last_value_time).toLocaleString('fi-FI')})</label>
                                    <Input
                                        disabled={dc_details.use_default_sensor_last_value}
                                        value={dc_details.last_value}
                                        name="last_value"
                                        onChange={this.handleChange} />
                                </Form.Field>
                                {create && (
                                    <Form.Field>
                                        <Checkbox
                                            label={<label>{t("Use default sensor initial value from customer invoicing details")}</label>}
                                            name="use_default_sensor_last_value"
                                            onChange={this.handleCheckboxChange}
                                            checked={dc_details.use_default_sensor_last_value}
                                            value={dc_details.use_default_sensor_last_value}
                                        />
                                    </Form.Field>
                                )}
                                {!create && (
                                    <Form.Field>
                                        <SemanticDatepicker
                                            disabled={loading}
                                            label={t("Last value date")}
                                            locale={fiLocale}
                                            placeholder={new Date(dc_details.last_value_time).toLocaleString('fi-FI', { dateStyle: 'short' })}
                                            onDateChange={selected => this.handleLastValueDateChange(selected)}
                                            format='DD.MM.YYYY' />
                                    </Form.Field>
                                )}
                                <Form.Field>
                                    <label>{t("Last invoiced value")} ({new Date(dc_details.last_invoiced_value_time).toLocaleString('fi-FI')})</label>
                                    <Input
                                        disabled={dc_details.use_default_sensor_last_invoiced_value}
                                        value={dc_details.last_invoiced_value}
                                        name="last_invoiced_value"
                                        onChange={this.handleChange} />
                                </Form.Field>
                                {create && (
                                    <Form.Field>
                                        <Checkbox
                                            label={<label>{t("Use default sensor last invoiced value from customer invoicing details")}</label>}
                                            name="use_default_sensor_last_invoiced_value"
                                            onChange={this.handleCheckboxChange}
                                            checked={dc_details.use_default_sensor_last_invoiced_value}
                                            value={dc_details.use_default_sensor_last_invoiced_value}
                                        />
                                    </Form.Field>
                                )}
                                {!create && (
                                    <Form.Field>
                                        <SemanticDatepicker
                                            disabled={loading}
                                            label={t("Last invoiced value date")}
                                            locale={fiLocale}
                                            placeholder={new Date(dc_details.last_invoiced_value_time).toLocaleString('fi-FI', { dateStyle: 'short' })}
                                            onDateChange={selected => this.handleLastInvoicedValueDateChange(selected)}
                                            format='DD.MM.YYYY' />
                                    </Form.Field>
                                )}
                                <Form.Field>
                                    <label>{t("Last estimate")} ({new Date(dc_details.last_estimate_time).toLocaleString('fi-FI')})</label>
                                    <Input
                                        disabled={dc_details.use_default_sensor_last_estimate}
                                        value={dc_details.last_estimate}
                                        name="last_estimate"
                                        onChange={this.handleChange} />
                                </Form.Field>
                                {create && (
                                    <Form.Field>
                                        <Checkbox
                                            label={<label>{t("Use default sensor last estimate value from customer invoicing details")}</label>}
                                            name="use_default_sensor_last_estimate"
                                            onChange={this.handleCheckboxChange}
                                            checked={dc_details.use_default_sensor_last_estimate}
                                            value={dc_details.use_default_sensor_last_estimate}
                                        />
                                    </Form.Field>
                                )}
                                {!create && (
                                    <Form.Field>
                                        <SemanticDatepicker
                                            disabled={loading}
                                            label={t("Last estimate date")}
                                            locale={fiLocale}
                                            placeholder={new Date(dc_details.last_estimate_time).toLocaleString('fi-FI', { dateStyle: 'short' })}
                                            onDateChange={selected => this.handleLastEstimateDateChange(selected)}
                                            format='DD.MM.YYYY' />
                                    </Form.Field>
                                )}

                            </React.Fragment>
                            <br />
                            <Form.Field>
                                <Checkbox
                                    label={<label>{t("Is active")}</label>}
                                    name="is_active"
                                    onChange={this.handleCheckboxChange}
                                    checked={dc_details.is_active}
                                    value={dc_details.is_active}
                                />
                            </Form.Field>

                            {error && <Message error header={t("Error")} content={error} />}
                            {success_mgs && <Message positive header={success_mgs} />}
                            <Button primary type="button" onClick={(e) => this.handleSubmit(e)} loading={loading} disabled={loading}>
                                {create ? (t("Create")) : (t("Save"))}
                            </Button>
                        </Form>
                    </Shell>
                )}
                <Modal size='small' open={customers_select_modal_open} dimmer="inverted" scrolling="true">
                    <Modal.Header>
                        <Header as="h3">{t("Select customers")}</Header>
                        <Button type="button" positive icon='add circle' content={t("Add customers")} onClick={() => this.handleAddCustomers()} />
                        <Input
                            size="mini"
                            icon='search'
                            value={search}
                            name="search"
                            onChange={this.handleSearchChange} />
                        <Button type="button" floated='right' negative onClick={() => this.closeCustomersAddModal()} >{t("Close")}</Button>
                    </Modal.Header>
                    <Modal.Content>
                        <Container>
                            {customers && (
                                <React.Fragment>
                                    <Header as="h3" color="blue">{t("Customers")}</Header>
                                        {filtered_list.length > 0 && (
                                            <Table striped selectable size='small'>
                                                <Table.Header>
                                                    <Table.Row>
                                                        <Table.HeaderCell>
                                                            <Popup content={t("Select all visible customers")} trigger={
                                                                <Icon link name="check circle outline" onClick={() => this.selectAllCustomers(filtered_list)} />
                                                            } />
                                                        </Table.HeaderCell>
                                                        <Table.HeaderCell>{t("Name")}</Table.HeaderCell>
                                                        <Table.HeaderCell>{t("Address")}</Table.HeaderCell>
                                                        <Table.HeaderCell>{t("VAT")}</Table.HeaderCell>
                                                    </Table.Row>
                                                </Table.Header>
                                                <Table.Body>
                                                    {filtered_list.map((customer) => {
                                                        if (customer.is_active) {
                                                            return (
                                                                <Table.Row key={customer.id}>
                                                                    <Table.Cell >
                                                                        <Checkbox
                                                                            checked={this.handleCheckIfSelected(customer) === true}
                                                                            value={customer}
                                                                            onChange={this.handleAddCustomersCheckboxChange} />
                                                                        </Table.Cell>
                                                                    <Table.Cell >
                                                                        {customer.name}
                                                                    </Table.Cell>
                                                                    <Table.Cell >{customer.address}, {customer.postal_code} {customer.city}</Table.Cell>
                                                                    <Table.Cell >{customer.vat}</Table.Cell>
                                                                </Table.Row>
                                                            );
                                                        }
                                                    })}
                                                </Table.Body>
                                            </Table>
                                        )}
                                </React.Fragment>
                            )}
                        </Container>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button type="button" negative onClick={() => this.closeCustomersAddModal()} >{t("Close")}</Button>
                    </Modal.Actions>
                </Modal>
            </React.Fragment>
        );

    }
}
const mapStateToProps = state => {
    return {
        activeCompanyId: state.company.activeCompanyId,
        isAuthenticated: state.auth.token !== null
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setActivity: () => dispatch(setActivity())
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(withTranslation('companydatacollectiondetails')(CustomerDataCollectionDetails))
);