// Copyright Caproc Oy
// System name: Aqua-Net 
// System module: Contract
// Author: TS
//
// 1.2.2020 TS Initial version
// 1.4.2020 TS Added missing recurring variable in to ContractItem
// 3.5.2020 TS Added "No sales person" option to Responsible person select.
// 5.5.2020 TS Added check for at least one contract item. Changed all button type from default "submit" to "button"
// 11.5.2020 TS Added check for no tax and tax items in the same contract. Cannot have both in the same contract.
// 11.5.2020 TS VAT options are now in select list instead of free input in item edit modal.
// 12.5.2020 TS Added Vat 0% checkbox and Vat comment field. Also new checks in handleSubmit for Vat 0% handling
// 13.5.2020 TS Changed item net_price and amount to fixed 2 decimals before sending to backend.
// 25.5.2020 TS Changed locations of next_invoice_date and next_invoice_create_date settings in the form layout
// 25.5.2020 TS Disabled check for zero tax or tax invoice in the handleSubmit. Invoice can have zero tax items and tax items as e.g. notification fee for late payment is tax free and other items are from the original invoice.
// 28.5.2020 TS Added contract_number_on_invoice and default invoice comment functionalities
// 4.6.2020 TS Added sequence_on_invoice functionality. Sequence of items list can now be changed.
// 6.6.2020 TS Changed remove icon to trash bin
// 8.6.2020 TS Added character counters
// 21.6.2020 TS Added mobile version


import React from "react";
import { Form, Input, Message, Header, Button, Select, Grid, Table, Modal, Container, Checkbox, Radio, Loader, Icon, Popup, Responsive } from "semantic-ui-react";
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import fiLocale from 'react-semantic-ui-datepickers/dist/locales/fi-FI';
import { authAxios, getItemGrossPrice, getItemNetPrice, getTotalGrossPrice, getTotalNetPrice, getISOFormattedDate, getWidth } from "../../utils";
import { setActivity } from "../../store/actions/auth";
import { withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import {
    fetchCustomersURL,
    // fetchProductGroupsURL,
    fetchContractGroupsURL,
    contractDetailsURL,
    updateContractDetailsURL,
    createContractURL,
    companyActiveUsersListURL,
    customerInvoicingDetailsURL,
    createContractItemURL,
    updateContractItemURL,
    deleteContractItemURL,
    customerDataCollectionsURL,
    automaticSendingModeOptionsURL,
    vatValueOptionsURL,
    fetchProductsURL
} from "../../constants";
import Shell from "./Shell";


class ContractDetails extends React.Component {
    state = {
        contract_id: null,
        contract_details: {
            id: "",
            customer: "",
            contract_group: "",
            name: "",
            number: "",
            contract_number_on_invoice: "",
            comment: "",
            no_vat_comment: "",
            start_date: "",
            end_date: "",
            is_active: true,
            is_automatic_sending: false,
            automatic_sending_mode: "A",
            invoicing_period_length: "1",
            invoicing_type: "B",
            advance_create_days: "0",
            invoice_date_transfer: "0",
            sales_person: "",
            contractitems: "",
            payment_term: "",
            customer_reference: "",
            company_reference: "",
            purchase_order: "",
            date_created: "",
            last_modified: "",
            next_invoice_date: "",
            next_invoice_create_date: "",
            invoice_period_start_date: null,
            invoice_period_end_date: null,
            author: ""
        },
        active_customer_invoicing_details: {
            invoicing_details_id: "",
            customer_id: "",
            payment_term: "",
            default_customer_reference: "",
            default_company_reference: "",
            default_contract_number: "",
            default_invoice_comment:  "",
            no_vat: false,
            no_vat_comment: "",
            discount: "",
            invoicing_method: "",
            invoicing_email: ""
        },
        preview_periods: [],
        update_due_date: false,
        active_customer_data_collections: null,
        contract_items_list: [],
        selected_products_list: [],
        contract_item_details_modal_open: false,
        active_contract_item: [],
        selected_item_for_edit: [],
        contract_items_select_modal_open: false,
        customer_options: null,
        // product_groups: null,
        company_users: null,
        fields_disabled: true,
        periodSelectRadioGroup: "permanent",
        periodLengthOptions: [],
        createBeforeDaysOptions: [],
        contract_group_options: [],
        period_preview_modal_open: false,
        error: null,
        success_mgs: null,
        loading: false,
        create: null,
        leave_page: false,
        leave_page_to: "",
        automatic_sending_mode_options: [],
        vat_value_options: [],
        no_vat_contract: false,
        search: "",
        products: null
    };

    componentDidMount() {
        this.props.setActivity();
        this.handleFetchCompanyUsers();
        this.handleFetchCustomers();
        // this.handleFetchProductGroupList();
        this.handleFetchProductList();
        this.handleFetchContractGroupList();
        this.createNumberOptions();
        this.handleFetchAutomaticSendingModeOptions();
        this.handleFetchVatValueOptions();
        if (this.props.match.params.contract_id === "create") {
            // Set initial date to start date picker
            this.handleStartDateChange(new Date());
            this.setState({
                create: true
            })
        } else {
            this.handleDetails();
        }
    }
    handleFetchAutomaticSendingModeOptions() {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;

        authAxios
            .get(automaticSendingModeOptionsURL(activeCompanyId))
            .then(res => {
                this.setState({
                    automatic_sending_mode_options: this.handleFormatAMSOptions(res.data),
                    error: null,
                    success_mgs: null,
                    loading: false,
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    success_mgs: null,
                    error: err
                });
            });
    };

    handleFormatAMSOptions = options => {
        const { t } = this.props;
        const keys = Object.keys(options);
        return keys.map(k => {
            return {
                key: options[k].value,
                text: t(options[k].name),
                value: options[k].value
            };
        });
    };

    handleFetchVatValueOptions() {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;

        authAxios
            .get(vatValueOptionsURL(activeCompanyId))
            .then(res => {
                this.setState({
                    vat_value_options: this.handleFormatVatValueOptions(res.data),
                    error: null,
                    success_mgs: null,
                    loading: false,
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    success_mgs: null,
                    error: err
                });
            });
    };

    handleFormatVatValueOptions = options => {
        const keys = Object.keys(options);
        return keys.map(k => {
            return {
                key: options[k].value,
                text: options[k].name,
                value: parseFloat(options[k].value)
            };
        });
    };

    handleFetchCompanyUsers() {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;

        authAxios
            .get(companyActiveUsersListURL(activeCompanyId))
            .then(res => {
                this.setState({
                    company_users: this.handleFormatUserOptions(res.data),
                    error: null,
                    success_mgs: null,
                    loading: false,
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    success_mgs: null,
                    error: err
                });
            });
    };

    handleFormatUserOptions = options => {
        const { t } = this.props;
        let i = 0;
        let options_list = [];
        options_list.push({
            key: 0,
            text: t("No sales person"),
            value: null
        })
        for (i in options) {
            options_list.push({
                key: options[i].id,
                text: (options[i].first_name + " " + options[i].last_name),
                value: options[i].id
            })
        }
        return options_list;
    };

    handleFetchContractGroupList() {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;

        authAxios
            .get(fetchContractGroupsURL(activeCompanyId))
            .then(res => {
                this.setState({
                    contract_group_options: this.handleFormatGroupOptions(res.data),
                    error: null,
                    success_mgs: null,
                    loading: false,
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    success_mgs: null,
                    error: err
                });
            });
    };

    handleFormatGroupOptions = options => {
        const { t } = this.props;
        let i = 0;
        let options_list = [];
        for (i in options) {
            options_list.push({
                key: options[i].id,
                text: options[i].name === "Default group" ? t("Default group") : options[i].name,
                value: options[i].id
            })
        }
        return options_list;
    };



    handleFetchCustomers() {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;

        authAxios
            .get(fetchCustomersURL(activeCompanyId))
            .then(res => {
                this.setState({
                    customer_options: this.handleFormatOptions(res.data),
                    error: null,
                    success_mgs: null,
                    loading: false,
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    success_mgs: null,
                    error: err
                });
            });
    };

    handleFormatOptions = options => {
        let i = 0;
        let options_list = [];
        for (i in options) {
            // Get only active ones..
            if (options[i].is_active) {
                options_list.push({
                    key: options[i].id,
                    text: options[i].name,
                    value: options[i].id
                })
            }
        }
        return options_list;
    };

    // handleFetchProductGroupList = () => {
    //     this.setState({
    //         loading: true
    //     });
    //     const { activeCompanyId } = this.props;
    //     authAxios
    //         .get(fetchProductGroupsURL(activeCompanyId))
    //         .then(res => {
    //             this.setState({
    //                 product_groups: res.data,
    //                 error: null,
    //                 loading: false
    //             });
    //         })
    //         .catch(err => {
    //             this.setState({
    //                 loading: false,
    //                 error: err
    //             });
    //         });
    // };

    handleFetchProductList = () => {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;
        authAxios
            .get(fetchProductsURL(activeCompanyId))
            .then(res => {
                this.setState({
                    products: res.data,
                    error: null,
                    loading: false
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    error: err
                });
            });
    };

    // Fetch invoicing details for selected customer
    handleCustomerInvoicingDetails = (customer_id) => {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;
        authAxios
            .get(customerInvoicingDetailsURL(activeCompanyId, customer_id))
            .then(res => {
                this.setState({
                    active_customer_invoicing_details: res.data,
                    error: null,
                    success_mgs: null,
                    loading: false
                });
                if (this.state.create) {
                    // Store default payment term from customer invoicing details to invoicing details.
                    const { contract_details } = this.state;
                    const updatedDetails = {
                        ...contract_details,
                        payment_term: res.data.payment_term,
                        company_reference: res.data.default_company_reference,
                        customer_reference: res.data.default_customer_reference,
                        no_vat_comment: res.data.no_vat_comment,
                        comment: res.data.default_invoice_comment,
                        contract_number_on_invoice: res.data.default_contract_number
                    };
                    this.setState({
                        contract_details: updatedDetails,
                        no_vat_contract: res.data.no_vat,
                        // update_due_date: true,
                        success_mgs: null,
                        error: null
                    });
                }

            })
            .catch(err => {
                this.setState({
                    loading: false,
                    success_mgs: null,
                    error: err
                });
            });
    };

    handleGetCustomerDataCollections = customer_id => {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;
        authAxios
            .get(customerDataCollectionsURL(activeCompanyId, customer_id))
            .then(res => {
                this.setState({
                    active_customer_data_collections: res.data,
                    error: null,
                    success_mgs: null,
                    loading: false
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    success_mgs: null,
                    error: err
                });
            });
    };


    handleDetails = () => {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;
        const { contract_id } = this.props.match.params;
        authAxios
            .get(contractDetailsURL(activeCompanyId, contract_id))
            .then(res => {
                this.setState({
                    contract_details: res.data,
                    contract_items_list: this.handleSeqAndSort(res.data.contractitems),
                    no_vat_contract: this.checkIfNoVatContract(res.data.contractitems),
                    fields_disabled: false,
                    periodSelectRadioGroup: res.data.end_date === null ? "permanent" : "fixed",
                    error: null,
                    success_mgs: null,
                    loading: false
                });
                this.handleCustomerInvoicingDetails(res.data.customer.id);
                // Fetch data collections list for items modal
                this.handleGetCustomerDataCollections(res.data.customer.id);
            })
            .catch(err => {
                this.setState({
                    contract_details: null,
                    loading: false,
                    success_mgs: null,
                    error: err
                });
            });
    };

    handleSeqAndSort = items => {
        // Assign sequence numbers just in case items have been deleted from the list and invoice has not been saved.
        let i = 0;
        for (i in items) {
            items[i].sequence_on_invoice = parseInt(i) + 1;
        }
        return items.sort(this.compareValuesForSort('sequence_on_invoice'));
    };


    checkIfNoVatContract = items_list => {
        let no_vat = true;
        let i = 0;
        for (i in items_list) {
            if (items_list[i].vat_value > 0) {
                no_vat = false;
            }
        }
        return no_vat;
    };

    handleItemDetailsChange = e => {
        const { t } = this.props;
        if (e.target.name === "amount" && isNaN(e.target.value)) {
            this.setState({
                error: t("Amount value is not number")
            });
        } else if (e.target.name === "net_price" && isNaN(e.target.value)) {
            this.setState({
                error: t("Net price is not number")
            });
        } else if (e.target.name === "discount" && isNaN(e.target.value)) {
            this.setState({
                error: t("Discount is not number")
            });
        } else if (e.target.name === "vat_value" && isNaN(e.target.value)) {
            this.setState({
                error: t("Vat value is not number")
            });
        } else {
            this.setState({
                error: null
            });
        }
        const { active_contract_item } = this.state;
        const updatedItemDetails = {
            ...active_contract_item,
            [e.target.name]: e.target.value,
        };
        this.setState({
            active_contract_item: updatedItemDetails,
            success_mgs: null
        });
    };

    handleItemDetailsSelectChange = (e, { name, value }) => {
        const { active_contract_item } = this.state;
        const updatedItemDetails = {
            ...active_contract_item,
            [name]: value
        };
        this.setState({
            active_contract_item: updatedItemDetails,
            error: null
        });
    };


    handleItemCheckboxChange = (e, { name, value }) => {
        const { active_contract_item } = this.state;
        const updatedItemDetails = {
            ...active_contract_item,
            [name]: !value
        };
        this.setState({
            active_contract_item: updatedItemDetails,
            error: null
        });
    };

    handleChange = e => {
        const { contract_details } = this.state;
        const updatedDetails = {
            ...contract_details,
            [e.target.name]: e.target.value,
        };
        this.setState({
            contract_details: updatedDetails,
            success_mgs: null,
            error: null,
        });
    };

    handleItemSearchChange = e => {
        this.setState({
            [e.target.name]: e.target.value,
            error: null
        });
    };

    handleRadioChange = (e, { name, value }) => {
        if (value === "permanent") {
            const { contract_details } = this.state;
            const updatedDetails = {
                ...contract_details,
                end_date: null
            };
            this.setState({
                contract_details: updatedDetails,
            });
        }
        this.setState({
            [name]: value,
        });
    };

    handleSelectChange = (e, { name, value }) => {
        if (name === 'customer') {
            // Fetch customer invoicing details for selected customer. Value = customer id.
            this.handleCustomerInvoicingDetails(value);
            // Fetch data collections list for items modal
            this.handleGetCustomerDataCollections(value);
            this.setState({ fields_disabled: false })
        }
        const { contract_details } = this.state;
        const updatedDetails = {
            ...contract_details,
            [name]: value
        };
        this.setState({
            contract_details: this.handleNextInvoiceDates(updatedDetails),
            error: null
        });
    };

    handleStartDateChange = value => {
        const { contract_details } = this.state;
        const updatedDetails = {
            ...contract_details,
            start_date: getISOFormattedDate(value, "Date")
        };
        this.setState({
            contract_details: this.handleNextInvoiceDates(updatedDetails),
            error: null
        });
    };

    handleEndDateChange = value => {
        const { contract_details } = this.state;
        const updatedDetails = {
            ...contract_details,
            end_date: getISOFormattedDate(value, "Date")
        };
        this.setState({
            contract_details: this.handleNextInvoiceDates(updatedDetails),
            error: null
        });
    };

    handleNextInvoiceDates = updatedDetails => {
        // Get start date and add invoicing period length (months) to get next invoice date.
        let next_invoice_date = new Date(updatedDetails.start_date);
        // If invoicing type = "B" (beforehand), the next invoice date is the first day of the period-> contract start date. If invoicing type is "A" (Afterwards), the invoicing date is the last 
        // day of the period.
        if (updatedDetails.invoicing_type === "A") {
            // Go to the end of the period
            next_invoice_date.setMonth(next_invoice_date.getMonth() + parseInt(updatedDetails.invoicing_period_length));
            // Minus one day to get the last day of period
            next_invoice_date.setDate(next_invoice_date.getDate() - 1);
        }
        // Invoice date transfer can be used to adjust the actual invoice date regardless of the period. Transfer can be +/-.
        next_invoice_date.setDate(next_invoice_date.getDate() + parseInt(updatedDetails.invoice_date_transfer));
        let next_invoice_create_date = new Date(next_invoice_date);
        next_invoice_create_date.setDate(next_invoice_create_date.getDate() - parseInt(updatedDetails.advance_create_days));
        // Save invoicing period start and end dates. These dates are printed on invoices
        const invoice_period_start_date = updatedDetails.start_date;
        let invoice_period_end_date = new Date(updatedDetails.start_date);
        // Go to the end of invoicing period 
        invoice_period_end_date.setMonth(invoice_period_end_date.getMonth() + parseInt(updatedDetails.invoicing_period_length));
        // Minus one day to get the last day of period
        invoice_period_end_date.setDate(invoice_period_end_date.getDate() - 1);
        const returnUpdatedDetails = {
            ...updatedDetails,
            next_invoice_date: getISOFormattedDate(next_invoice_date, "DateTime"),
            next_invoice_create_date: getISOFormattedDate(next_invoice_create_date, "DateTime"),
            invoice_period_start_date: invoice_period_start_date,
            invoice_period_end_date: getISOFormattedDate(invoice_period_end_date, "Date")
        }
        return returnUpdatedDetails;
    };

    handleCheckboxChange = (e, { name, value }) => {
        const { contract_details } = this.state;
        const updatedDetails = {
            ...contract_details,
            [name]: !value
        };
        this.setState({
            contract_details: updatedDetails,
            error: null
        });
    };

    handleNoVatCheckboxChange = (e, { name, value }) => {
        this.setState({
            [name]: !value,
            error: null
        });
    };

    handleSubmit = e => {
        e.preventDefault();
        this.setState({
            loading: true,
            error: null
        });
        const { create } = this.state;
        let { contract_details } = this.state;
        const { activeCompanyId, t } = this.props;
        if (contract_details.name === "") {
            this.setState({
                error: t("Please fill in contract name"),
                success_mgs: null,
                loading: false
            });
        } else if (contract_details.name.length > 35) {
            this.setState({
                error: t("Max amount of characters is 100 in Name field"),
                success_mgs: null,
                loading: false
            });
        } else if (contract_details.comment.length > 100) {
            this.setState({
                error: t("Max amount of characters is 200 in Comment field"),
                success_mgs: null,
                loading: false
            });
        } else if (contract_details.customer_reference.length > 50) {
            this.setState({
                error: t("Max amount of characters is 50 in Customer reference field"),
                success_mgs: null,
                loading: false
            });
        } else if (contract_details.company_reference.length > 50) {
            this.setState({
                error: t("Max amount of characters is 50 in Company reference field"),
                success_mgs: null,
                loading: false
            });
        } else if (contract_details.purchase_order.length > 70) {
            this.setState({
                error: t("Max amount of characters is 70 in Purchase order field"),
                success_mgs: null,
                loading: false
            });
        } else if (contract_details.start_date === "") {
            this.setState({
                error: t("Please fill in Start date"),
                success_mgs: null,
                loading: false
            });
        } else if (contract_details.contract_group === "") {
            this.setState({
                error: t("Please select Contract Group"),
                success_mgs: null,
                loading: false
            });
        } else if (contract_details.payment_term === "") {
            this.setState({
                error: t("Please fill in Payment Term"),
                success_mgs: null,
                loading: false
            });
        } else if (this.state.contract_items_list.length === 0) {
            this.setState({
                error: t("Contract has to have at least one product / invoice item."),
                success_mgs: null,
                loading: false
            });
        } else if (this.state.no_vat_contract && !this.checkIfNoVatContract(this.state.contract_items_list)) {
            this.setState({
                error: t("Contract marked as VAT 0%/Reverse VAT, but some items have VAT other than zero. Please check that the whole contract is zero VAT."),
                success_mgs: null,
                loading: false
            });
        } else if (this.state.no_vat_contract && contract_details.no_vat_comment === "") {
            this.setState({
                error: t("Please fill in No VAT comment for VAT 0% contract"),
                success_mgs: null,
                loading: false
            });
        // } else if (!this.validateItemListTaxes(this.state.contract_items_list)) {
        //     this.setState({
        //         error: t("Contract cannot have no tax and tax items. The whole contract has to be either 0% tax or with tax."),
        //         success_mgs: null,
        //         loading: false
        //     });
        } else {
            if (create) {
                const updated_details = {
                    ...contract_details,
                    author: localStorage.getItem("UserID")
                }
                authAxios
                    .post(createContractURL(activeCompanyId), {
                        ...updated_details
                    })
                    .then(res => {
                        contract_details.id = res.data.contract_id;
                        // Create invoice items
                        let { contract_items_list } = this.state;
                        let i = 0;
                        for (i in contract_items_list) {
                            // Add invoice id from just created invoice and add to all items.
                            contract_items_list[i]['contract'] = res.data.contract_id;
                            contract_items_list[i]['author'] = localStorage.getItem("UserID");
                            authAxios
                                .post(createContractItemURL(activeCompanyId, res.data.contract_id), {
                                    ...contract_items_list[i],
                                    net_price: parseFloat(contract_items_list[i]['net_price']).toFixed(2),
                                    amount: parseFloat(contract_items_list[i]['amount']).toFixed(2)
                                })
                                .then(res => {
                                })
                                .catch(err => {
                                    this.setState({
                                        loading: false,
                                        success_mgs: null,
                                        error: err
                                    });
                                    return;
                                });
                        }
                        this.setState({
                            leave_page: true
                        });
                    })
                    .catch(err => {
                        this.setState({
                            loading: false,
                            success_mgs: null,
                            error: err
                        });
                    });

            } else {
                authAxios
                    .put(updateContractDetailsURL(activeCompanyId, contract_details.id), {
                        ...contract_details,
                        contract_number_on_invoice: contract_details.contract_number_on_invoice === null ? "" : contract_details.contract_number_on_invoice
                    })
                    .then(res => {
                        // Update also invoice items
                        let { contract_items_list } = this.state;
                        let i = 0;
                        for (i in contract_items_list) {
                            // id is empty if this is new item added to the list
                            if (!contract_items_list[i].id) {
                                contract_items_list[i]['contract'] = contract_details.id;
                                authAxios
                                    .post(createContractItemURL(activeCompanyId, contract_details.id), {
                                        ...contract_items_list[i],
                                        net_price: parseFloat(contract_items_list[i]['net_price']).toFixed(2),
                                        amount: parseFloat(contract_items_list[i]['amount']).toFixed(2)
                                    })
                                    .then(res => {
                                    })
                                    .catch(err => {
                                        this.setState({
                                            loading: false,
                                            success_mgs: null,
                                            error: err
                                        });
                                        return;
                                    });

                            } else {
                                // Update if existing item
                                authAxios
                                    .put(updateContractItemURL(activeCompanyId, contract_details.id, contract_items_list[i].id), {
                                        ...contract_items_list[i],
                                        net_price: parseFloat(contract_items_list[i]['net_price']).toFixed(2),
                                        amount: parseFloat(contract_items_list[i]['amount']).toFixed(2)
                                    })
                                    .then(res => {
                                    })
                                    .catch(err => {
                                        this.setState({
                                            loading: false,
                                            success_mgs: null,
                                            error: err
                                        });
                                        return;
                                    });
                            }
                        }
                        this.setState({
                            leave_page: true
                        });
                    })
                    .catch(err => {
                        this.setState({
                            loading: false,
                            success_mgs: null,
                            error: err
                        });
                    });
            }
        }
    };

    openItemDetailsModal = item => {
        this.setState({
            contract_item_details_modal_open: true,
            active_contract_item: item,
            selected_item_for_edit: item,
            error: null
        })
    };

    closeItemDetailsModal = () => {
        this.setState({
            contract_item_details_modal_open: false,
            active_contract_item: [],
            selected_item_for_edit: []
        })
    };

    handleItemDetailsSubmit = () => {
        const { t } = this.props;
        const { active_contract_item, selected_item_for_edit, error } = this.state;
        if (active_contract_item.name === "") {
            this.setState({
                error: t("Please fill in item Name"),
                success_mgs: null,
                loading: false
            });
        } else if (active_contract_item.name.length > 100) {
            this.setState({
                error: t("Max amount of characters is 100 in item Name field"),
                success_mgs: null,
                loading: false
            });
        } else if (active_contract_item.amount === "") {
            this.setState({
                error: t("Please fill in item Amount"),
                success_mgs: null,
                loading: false
            });
        } else if (active_contract_item.net_price === "") {
            this.setState({
                error: t("Please fill in item Net price"),
                success_mgs: null,
                loading: false
            });
        } else if (active_contract_item.discount === "") {
            this.setState({
                error: t("Please fill in item Discount"),
                success_mgs: null,
                loading: false
            });
        } else if (active_contract_item.vat_value === "") {
            this.setState({
                error: t("Please fill in item Vat value"),
                success_mgs: null,
                loading: false
            });
        } else {
            if (!error) {
                // if (active_contract_item.vat_value === parseFloat(0)) {
                //     this.setState({
                //         no_vat_contract: true
                //     });
                // }
                this.handleItemsListChange(selected_item_for_edit, active_contract_item, "update");
                this.closeItemDetailsModal();
            }
        }
    }

    openItemsAddModal = () => {
        this.setState({
            contract_items_select_modal_open: true,
            error: null
        })
    };

    closeItemsAddModal = () => {
        this.setState({
            contract_items_select_modal_open: false,
            selected_products_list: [],
        })
    };

    openPeriodPreviewModal = () => {
        this.setState({
            period_preview_modal_open: true,
            preview_periods: this.getPreviewPeriods()
        });
    };

    closePeriodPreviewModal = () => {
        this.setState({
            period_preview_modal_open: false,
            preview_periods: []
        });
    };

    getPreviewPeriods = () => {
        const { contract_details } = this.state;
        let preview_periods = [];
        let i = 0;

        let period_start_date = new Date(contract_details.start_date);
        let period_end_date = new Date(period_start_date);
        // Move period end date x months forward. x defined in invoicing_period_length
        period_end_date.setMonth(period_end_date.getMonth() + parseInt(contract_details.invoicing_period_length));
        // Last day of period is minus one from the start of new period
        period_end_date.setDate(period_end_date.getDate() - 1);

        let next_invoice_date = new Date(contract_details.next_invoice_date);
        let next_invoice_create_date = new Date(contract_details.next_invoice_create_date);
        for (i = 0; i < 5; i++) {
            preview_periods[i] = {
                period_start: getISOFormattedDate(period_start_date, "Date"),
                period_end: getISOFormattedDate(period_end_date, "Date"),
                invoice_date: getISOFormattedDate(next_invoice_date, "DateTime"),
                invoice_create_date: getISOFormattedDate(next_invoice_create_date, "DateTime")
            }
            period_start_date.setMonth(period_start_date.getMonth() + parseInt(contract_details.invoicing_period_length));
            period_end_date = new Date(period_start_date);
            period_end_date.setMonth(period_end_date.getMonth() + parseInt(contract_details.invoicing_period_length));
            period_end_date.setDate(period_end_date.getDate() - 1);

            next_invoice_date = new Date(period_start_date);

            if (contract_details.invoicing_type === "A") {
                // Go to the end of the period
                next_invoice_date.setMonth(next_invoice_date.getMonth() + parseInt(contract_details.invoicing_period_length));
                // Minus one day to get the last day of period
                next_invoice_date.setDate(next_invoice_date.getDate() - 1);
            }
            // Invoice date transfer can be used to adjust the actual invoice date regardless of the period. Transfer can be +/-.
            next_invoice_date.setDate(next_invoice_date.getDate() + parseInt(contract_details.invoice_date_transfer));
            next_invoice_create_date = new Date(next_invoice_date);
            next_invoice_create_date.setDate(next_invoice_create_date.getDate() - parseInt(contract_details.advance_create_days));
        }
        return preview_periods;
    };

    handleItemsListChange = (item, updated_item, action) => {
        let { contract_items_list } = this.state;
        const { activeCompanyId } = this.props;
        if (action === "add") {
            if (!contract_items_list.includes(item)) {
                contract_items_list.push(item);
            }
        } else if (action === "remove") {
            if (contract_items_list.includes(item)) {
                contract_items_list.splice(contract_items_list.indexOf(item), 1);
                // If this item is already saved in the db (has id defined), Delete it.
                if (item.id) {
                    authAxios
                        .delete(deleteContractItemURL(activeCompanyId, item.contract, item.id), {
                        })
                        .then(res => {
                        })
                        .catch(err => {
                            this.setState({
                                loading: false,
                                success_mgs: null,
                                error: err
                            });
                            return;
                        });
                }
                // Rearrange sequence_on_invoice numbers
                contract_items_list = this.handleSeqAndSort(contract_items_list);
            }
        } else if (action === "update") {
            if (contract_items_list.includes(item)) {
                contract_items_list.splice(contract_items_list.indexOf(item), 1, updated_item);
            }
        }
        this.setState({ contract_items_list });
    };

    handleAddItemsCheckboxChange = (e, { value, checked }) => {
        let { selected_products_list } = this.state;
        if (checked) {
            if (!selected_products_list.includes(value)) {
                selected_products_list.push(value);
            }
        } else {
            if (selected_products_list.includes(value)) {
                selected_products_list.splice(selected_products_list.indexOf(value), 1);
            }
        }
        this.setState({ selected_products_list });
    };

    handleAddItemsToContract = () => {
        const { selected_products_list, active_customer_invoicing_details, no_vat_contract } = this.state;
        const { activeCompanyId } = this.props;
        const last_sequence_number = this.state.contract_items_list ? this.state.contract_items_list.length : 0;
        let i = 0;
        for (i in selected_products_list) {
            let new_item = {};
            new_item['company'] = activeCompanyId;
            // Pick contract id in handleSubmit after the invoice has been created.

            // If item in a list has source_id -> data collection item. Else normal item
            if (selected_products_list[i].source_id !== undefined) {
                new_item['customer_data_collection_detail'] = selected_products_list[i].id;
                new_item['name'] = selected_products_list[i].product.name;
                new_item['product'] = selected_products_list[i].product.id;
                new_item['description'] = selected_products_list[i].product.description;
                new_item['code'] = selected_products_list[i].product.code;
                new_item['net_price'] = selected_products_list[i].product.net_price;
                new_item['measure_unit'] = selected_products_list[i].product.measure_unit;
                if (no_vat_contract) {
                    new_item['vat_value'] = 0;
                } else {
                    new_item['vat_value'] = selected_products_list[i].product.vat_value;
                }
                
                new_item['item_type'] = "D"; // Data collection product type
                new_item['amount'] = 1; // Amount will be replaced with data collection value when invoice is created
                new_item['update_dc_value_on_invoice_date'] = true;
                new_item['recurring'] = selected_products_list[i].product.recurring;
            } else {
                new_item['name'] = selected_products_list[i].name;
                new_item['product'] = selected_products_list[i].id;
                new_item['description'] = selected_products_list[i].description;
                new_item['code'] = selected_products_list[i].code;
                new_item['net_price'] = selected_products_list[i].net_price;
                new_item['measure_unit'] = selected_products_list[i].measure_unit;
                if (no_vat_contract) {
                    new_item['vat_value'] = 0;
                } else {
                    new_item['vat_value'] = selected_products_list[i].vat_value;
                }
               
                new_item['item_type'] = "N"; // Normal product type
                new_item['amount'] = 1;
                new_item['recurring'] = selected_products_list[i].recurring;
            }
            if (active_customer_invoicing_details) {
                new_item['customer'] = active_customer_invoicing_details.customer_id;
                new_item['discount'] = active_customer_invoicing_details.discount;
            }
            new_item['sequence_on_invoice'] = parseInt(last_sequence_number) + parseInt(i) + 1;
            this.handleItemsListChange(new_item, "", "add");
        }
        this.closeItemsAddModal();
    };

    createNumberOptions = () => {
        let options = [];
        for (let i = 1; i < 13; i++) {
            options.push({
                key: i.toString(),
                text: i.toString(),
                value: i.toString()
            })
        }
        let options2 = [];
        for (let i = 0; i < 366; i++) {
            options2.push({
                key: i.toString(),
                text: i.toString(),
                value: i.toString()
            })
        }
        let options3 = [];
        for (let i = -30; i < 31; i++) {
            options3.push({
                key: i.toString(),
                text: i.toString(),
                value: i.toString()
            })
        }
        this.setState({
            periodLengthOptions: options,
            createBeforeDaysOptions: options2,
            transferDaysOptions: options3
        });
    };

    validateItemListTaxes = items_list => {
        let i = 0;
        let tax_items = false;
        let no_tax_items = false;
        for (i in items_list) {
            if (items_list[i].vat_value > 0) {
                tax_items = true;
            } else {
                no_tax_items = true;
            }
        }
        if (tax_items && no_tax_items) {
            return false;
        } else {
            return true;
        }
    };

    handleCheckIfSelectedProduct = product => {
        const { selected_products_list } = this.state;
        let i = 0;
        let return_value = false;
        for (i in selected_products_list) {
            if (product === selected_products_list[i]) {
                return_value = true;
            }
        }
        return return_value;
    };

    compareValuesForSort = (key, order = 'asc') => {
        return (a, b) => {
            if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
                // property doesn't exist on either object
                return 0;
              }
          
              const varA = (typeof a[key] === 'string')
                ? a[key].toUpperCase() : a[key];
              const varB = (typeof b[key] === 'string')
                ? b[key].toUpperCase() : b[key];
          
              let comparison = 0;
              if (varA > varB) {
                comparison = 1;
              } else if (varA < varB) {
                comparison = -1;
              }
              return (
                (order === 'desc') ? (comparison * -1) : comparison
              );  
        };
    };

    handleChangeItemSequence = (item, direction) => {
        const { contract_items_list } = this.state;
        let new_seq = 0;
        if (direction === "up") {
            new_seq = parseInt(item.sequence_on_invoice) - 1;
        } else if (direction === "down") {
            new_seq = parseInt(item.sequence_on_invoice) + 1;
        }
        if (new_seq !== 0) {
            // Update first item above/below
            let i = 0;
            for (i in contract_items_list) {
                if (contract_items_list[i].sequence_on_invoice === new_seq) {
                    let updatedItem = contract_items_list[i];
                    // The item above/below will get current items seq number
                    updatedItem.sequence_on_invoice = item.sequence_on_invoice;
                    this.handleItemsListChange(contract_items_list[i], updatedItem, "update");
                    // Now update current item with new seq.
                    updatedItem = item;
                    updatedItem.sequence_on_invoice = new_seq;
                    this.handleItemsListChange(item, updatedItem, "update");
                    break;
                }
            }
        }
    };

    render() {
        const {
            contract_details,
            contract_items_list,
            customer_options,
            company_users,
            // product_groups,
            products,
            contract_items_select_modal_open,
            period_preview_modal_open,
            fields_disabled,
            active_contract_item,
            contract_item_details_modal_open,
            active_customer_data_collections,
            contract_group_options,
            preview_periods,
            error,
            success_mgs,
            loading,
            create,
            search
        } = this.state;
        const { isAuthenticated, t } = this.props;
        if (!isAuthenticated) {
            return <Redirect to="/login" />;
        }
        if (this.state.leave_page && error === null) {
            this.props.history.push(`/contracts/`);
        }
        let filtered_dc_products = []
        let filtered_products = []
        // Filter data for product search
        if (active_customer_data_collections !== null) {
            filtered_dc_products = active_customer_data_collections.filter((item) => {
                return item.product.name.toLowerCase().indexOf(search.toLocaleLowerCase()) !== -1 ||
                    item.product.code.toString().toLowerCase().indexOf(search.toLocaleLowerCase()) !== -1;
            })
        }

        // Filter data for product search
        if (products !== null) {
                filtered_products = products.filter((item) => {
                    return item.name.toLowerCase().indexOf(search.toLocaleLowerCase()) !== -1 ||
                        item.code.toString().toLowerCase().indexOf(search.toLocaleLowerCase()) !== -1;
                })
        }

        contract_items_list.sort(this.compareValuesForSort('sequence_on_invoice'));
        
        const is_desktop =  getWidth() > Responsive.onlyMobile.maxWidth ? true : false;
        // console.log(this.state);
        return (
            <Shell>
                {(create || contract_details.id !== "") && (
                    <div>
                        {loading && (
                            <Loader inverted>Loading</Loader>
                        )}
                        <Header as="h3">{create ? (t("New contract")) : (contract_details.name)}</Header>
                        <Form error={error !== null}>
                            {is_desktop ? (
                                <React.Fragment>
                                    <Grid>
                                        <Grid.Row>
                                            <Grid.Column width={8}>
                                                {customer_options && (
                                                    <Form.Field required>
                                                        <label>{t("Select customer")}</label>
                                                        <Select
                                                            required
                                                            disabled={loading || !create}
                                                            fluid
                                                            search
                                                            name='customer'
                                                            options={customer_options}
                                                            placeholder={create ? (t("Customer")) : (contract_details.customer.id.toString())}
                                                            onChange={this.handleSelectChange}
                                                            value={contract_details.customer.id}
                                                        />
                                                    </Form.Field>
                                                )}

                                            </Grid.Column>
                                            <Grid.Column width={8}>
                                                <Form.Field required>
                                                    <label>{t("Select contract group")}</label>
                                                    <Select
                                                        required
                                                        disabled={loading || fields_disabled}
                                                        fluid
                                                        search
                                                        name='contract_group'
                                                        options={contract_group_options}
                                                        placeholder={create ? (t("Contract group")) : (contract_details.contract_group.toString())}
                                                        onChange={this.handleSelectChange}
                                                        value={contract_details.contract_group}
                                                    />
                                                </Form.Field>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                    <Grid>
                                        <Grid.Row>
                                            <Grid.Column width={8}>
                                                <Header as="h4">{t("Basic details")}</Header>
                                                <Form.Field required>
                                                    <label>{t("Contract name")} {contract_details.name ? (`${contract_details.name.length}/35`) : ("0/35")}</label>
                                                    <Input
                                                        required
                                                        disabled={loading || fields_disabled}
                                                        value={contract_details.name}
                                                        name="name"
                                                        onChange={this.handleChange} />
                                                </Form.Field>
                                                {company_users && (
                                                    <Form.Field>
                                                        <label>{t("Select sales person")}</label>
                                                        <Select
                                                            disabled={loading || fields_disabled}
                                                            fluid
                                                            search
                                                            name='sales_person'
                                                            options={company_users}
                                                            placeholder={create ? (t("Sales person")) : (contract_details.sales_person && (contract_details.sales_person.toString()))}
                                                            onChange={this.handleSelectChange}
                                                            value={contract_details.sales_person && (contract_details.sales_person)}
                                                        />
                                                    </Form.Field>
                                                )}
                                                <Grid>
                                                    <Grid.Row >
                                                        <Grid.Column width={7} >
                                                            <Form.Field>
                                                                <label>{t("Permanent")}</label>
                                                                <Radio
                                                                    disabled={loading || fields_disabled}
                                                                    name="periodSelectRadioGroup"
                                                                    value="permanent"
                                                                    checked={this.state.periodSelectRadioGroup === "permanent"}
                                                                    onChange={this.handleRadioChange} />
                                                            </Form.Field>
                                                        </Grid.Column>
                                                        <Grid.Column width={7}>
                                                            <Form.Field>
                                                                <label>{t("Fixed term")}</label>
                                                                <Radio
                                                                    disabled={loading || fields_disabled}
                                                                    name="periodSelectRadioGroup"
                                                                    value="fixed"
                                                                    checked={this.state.periodSelectRadioGroup === "fixed"}
                                                                    onChange={this.handleRadioChange} />
                                                            </Form.Field>
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                </Grid>

                                                <Grid>
                                                    <Grid.Row >
                                                        <Grid.Column width={8} >
                                                            <Form.Field>
                                                                <label>{t("Start date")}</label>
                                                                <SemanticDatepicker
                                                                    firstDayOfWeek={1}
                                                                    disabled={loading || fields_disabled}
                                                                    locale={fiLocale}
                                                                    name="start_date"
                                                                    pointing="top left"
                                                                    size="small"
                                                                    placeholder={contract_details.start_date !== null ? (new Date(contract_details.start_date).toLocaleString('fi-FI', { dateStyle: 'short' })) : ("")}
                                                                    onDateChange={selected => this.handleStartDateChange(selected)}
                                                                    format='DD.MM.YYYY'
                                                                />
                                                            </Form.Field>
                                                        </Grid.Column>
                                                        <Grid.Column width={8}>
                                                            {this.state.periodSelectRadioGroup === "fixed" && (
                                                                <Form.Field>
                                                                    <label>{t("End date")}</label>
                                                                    <SemanticDatepicker
                                                                        firstDayOfWeek={1}
                                                                        disabled={loading || fields_disabled}
                                                                        locale={fiLocale}
                                                                        name="end_date"
                                                                        pointing="top left"
                                                                        size="small"
                                                                        placeholder={contract_details.end_date !== null ? (new Date(contract_details.end_date).toLocaleString('fi-FI', { dateStyle: 'short' })) : ("")}
                                                                        onDateChange={selected => this.handleEndDateChange(selected)}
                                                                        format='DD.MM.YYYY' />
                                                                </Form.Field>
                                                            )}
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                </Grid>
                                                <Grid>
                                                    <Grid.Row>
                                                        <Grid.Column width={8}>
                                                            <Form.Field>
                                                                <label>{t("Invoicing period (months)")}</label>
                                                                <Select
                                                                    disabled={loading || fields_disabled}
                                                                    fluid
                                                                    search
                                                                    options={this.state.periodLengthOptions}
                                                                    value={contract_details.invoicing_period_length.toString()}
                                                                    placeholder={contract_details.invoicing_period_length.toString()}
                                                                    name="invoicing_period_length"
                                                                    onChange={this.handleSelectChange} />
                                                            </Form.Field>
                                                        </Grid.Column>
                                                        <Grid.Column width={8}>
                                                            <Form.Field>
                                                                <label>{t("Invoicing type")}</label>
                                                                <Select
                                                                    disabled={loading || fields_disabled}
                                                                    fluid
                                                                    search
                                                                    options={[{ key: "B", text: t("Begining of period"), value: "B" }, { key: "A", text: t("After the period"), value: "A" }]}
                                                                    value={contract_details.invoicing_type}
                                                                    placeholder={contract_details.invoicing_type}
                                                                    name="invoicing_type"
                                                                    onChange={this.handleSelectChange} />
                                                            </Form.Field>
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                    <Grid.Row>
                                                        <Grid.Column width={8}>
                                                            <Form.Field>
                                                                <label>{t("Next invoice date")}</label>
                                                                <Input
                                                                    disabled={loading || fields_disabled}
                                                                    value={new Date(contract_details.next_invoice_date).toLocaleString('fi-FI', { dateStyle: 'short' })}
                                                                />
                                                            </Form.Field>
                                                            <Button type="button" size='tiny' disabled={loading || fields_disabled} primary content={t("Preview invoicing periods")} onClick={() => this.openPeriodPreviewModal()} />
                                                        </Grid.Column>
                                                        <Grid.Column width={8}>
                                                            <Form.Field>
                                                                <label>{t("Next invoice create date")}</label>
                                                                <Input
                                                                    disabled={loading || fields_disabled}
                                                                    value={new Date(contract_details.next_invoice_create_date).toLocaleString('fi-FI', { dateStyle: 'short' })}
                                                                />
                                                            </Form.Field>
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                    <Grid.Row>
                                                        <Grid.Column>
                                                        <Form.Field>
                                                            <Checkbox
                                                                disabled={loading || fields_disabled}
                                                                name="no_vat_contract"
                                                                label={t("VAT 0%/Reverse VAT contract")}
                                                                onChange={this.handleNoVatCheckboxChange}
                                                                checked={this.state.no_vat_contract}
                                                                value={this.state.no_vat_contract}
                                                            />
                                                        </Form.Field>
                                                        {this.state.no_vat_contract && (
                                                            <Form.Field>
                                                                <label>{t("No VAT comment")} {contract_details.no_vat_comment ? (`${contract_details.no_vat_comment.length}/70`) : ("0/70")}</label>
                                                                <Input
                                                                    disabled={loading || fields_disabled}
                                                                    value={contract_details.no_vat_comment}
                                                                    name="no_vat_comment"
                                                                    onChange={this.handleChange} />
                                                            </Form.Field>
                                                        )}
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                </Grid>
                                            </Grid.Column>
                                            <Grid.Column width={8}>
                                                <Header as="h4">{t("Invoicing details")}</Header>
                                                <Form.Field>
                                                    <Popup content={t("Contract number on invoice. If empty the system contract number will be used on invoice when creating new contract. If existing contract is modified the value in the field will be used (can be empty as well)")}
                                                                    trigger={<label>{t("Contract number (on invoice)")} {contract_details.contract_number_on_invoice ? (`${contract_details.contract_number_on_invoice.length}/35`) : ("0/35")}</label>} />
                                                    <Input
                                                        disabled={loading || fields_disabled}
                                                        value={contract_details.contract_number_on_invoice}
                                                        name="contract_number_on_invoice"
                                                        onChange={this.handleChange} />
                                                </Form.Field>
                                                <Form.Field>
                                                    <label>{t("Invoice comment")} {contract_details.comment ? (`${contract_details.comment.length}/100`) : ("0/100")}</label>
                                                    <Input
                                                        disabled={loading || fields_disabled}
                                                        value={contract_details.comment}
                                                        name="comment"
                                                        onChange={this.handleChange} />
                                                </Form.Field>
                                                <Form.Field>
                                                    <label>{t("Payment term")}</label>
                                                    <Input
                                                        disabled={loading || fields_disabled}
                                                        type='number'
                                                        value={contract_details.payment_term}
                                                        name="payment_term"
                                                        onChange={this.handleChange} />
                                                </Form.Field>
                                                <Form.Field>
                                                    <label>{t("Company reference")} {contract_details.company_reference ? (`${contract_details.company_reference.length}/50`) : ("0/50")}</label>
                                                    <Input
                                                        disabled={loading || fields_disabled}
                                                        value={contract_details.company_reference}
                                                        name="company_reference"
                                                        onChange={this.handleChange} />
                                                </Form.Field>
                                                <Form.Field>
                                                    <label>{t("Customer reference")} {contract_details.customer_reference ? (`${contract_details.customer_reference.length}/50`) : ("0/50")}</label>
                                                    <Input
                                                        disabled={loading || fields_disabled}
                                                        value={contract_details.customer_reference}
                                                        name="customer_reference"
                                                        onChange={this.handleChange} />
                                                </Form.Field>
                                                <Form.Field>
                                                    <label>{t("Purchase order")} {contract_details.purchase_order ? (`${contract_details.purchase_order.length}/70`) : ("0/70")}</label>
                                                    <Input
                                                        disabled={loading || fields_disabled}
                                                        value={contract_details.purchase_order}
                                                        name="purchase_order"
                                                        onChange={this.handleChange} />
                                                </Form.Field>
                                                <Grid>
                                                    <Grid.Row>
                                                        <Grid.Column width={8}>
                                                            <Form.Field>
                                                                <Popup content={t("Invoice date can be moved in relation with start or end date of the period")}
                                                                    trigger={<label>{t("Invoice date transfer (days)")}</label>} />
                                                                <Select
                                                                    disabled={loading || fields_disabled}
                                                                    fluid
                                                                    search
                                                                    options={this.state.transferDaysOptions}
                                                                    value={contract_details.invoice_date_transfer.toString()}
                                                                    placeholder={contract_details.invoice_date_transfer.toString()}
                                                                    name="invoice_date_transfer"
                                                                    onChange={this.handleSelectChange} />
                                                            </Form.Field>
                                                        </Grid.Column>
                                                        <Grid.Column width={8}>
                                                            <Form.Field>
                                                                <Popup content={t("Invoice can be created in advance. Invoice date remains the same")}
                                                                    trigger={<label>{t("Create (days) before invoice date")}</label>} />
                                                                <Select
                                                                    disabled={loading || fields_disabled}
                                                                    fluid
                                                                    search
                                                                    options={this.state.createBeforeDaysOptions}
                                                                    value={contract_details.advance_create_days.toString()}
                                                                    placeholder={contract_details.advance_create_days.toString()}
                                                                    name="advance_create_days"
                                                                    onChange={this.handleSelectChange} />
                                                            </Form.Field>
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                    <Grid.Row>
                                                        <Grid.Column>
                                                            <Form.Field>
                                                                <Checkbox
                                                                    name="is_automatic_sending"
                                                                    label={t("Send automatically when created")}
                                                                    onChange={this.handleCheckboxChange}
                                                                    checked={contract_details.is_automatic_sending}
                                                                    value={contract_details.is_automatic_sending}
                                                                />
                                                            </Form.Field>
                                                            
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                    <Grid.Row>
                                                        <Grid.Column width={8}>
                                                            {contract_details.is_automatic_sending && (
                                                                <Form.Field>
                                                                    <Popup content={t("Send invoices automatically if they are in selected status")}
                                                                        trigger={<label>{t("Automatic sending status")}</label>} />
                                                                    <Select
                                                                        disabled={loading || fields_disabled}
                                                                        fluid
                                                                        search
                                                                        options={this.state.automatic_sending_mode_options}
                                                                        value={contract_details.automatic_sending_mode}
                                                                        placeholder={contract_details.automatic_sending_mode}
                                                                        name="automatic_sending_mode"
                                                                        onChange={this.handleSelectChange} />
                                                                </Form.Field>
                                                            )}
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                </Grid>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    {customer_options && (
                                        <Form.Field required>
                                            <label>{t("Select customer")}</label>
                                            <Select
                                                required
                                                disabled={loading || !create}
                                                fluid
                                                search
                                                name='customer'
                                                options={customer_options}
                                                placeholder={create ? (t("Customer")) : (contract_details.customer.id.toString())}
                                                onChange={this.handleSelectChange}
                                                value={contract_details.customer.id}
                                            />
                                        </Form.Field>
                                    )}
                                    <Form.Field required>
                                        <label>{t("Select contract group")}</label>
                                        <Select
                                            required
                                            disabled={loading || fields_disabled}
                                            fluid
                                            search
                                            name='contract_group'
                                            options={contract_group_options}
                                            placeholder={create ? (t("Contract group")) : (contract_details.contract_group.toString())}
                                            onChange={this.handleSelectChange}
                                            value={contract_details.contract_group}
                                        />
                                    </Form.Field>
                                    <hr/>
                                    <Header as="h4">{t("Basic details")}</Header>
                                    <Form.Field required>
                                        <label>{t("Contract name")} {contract_details.name ? (`${contract_details.name.length}/35`) : ("0/35")}</label>
                                        <Input
                                            required
                                            disabled={loading || fields_disabled}
                                            value={contract_details.name}
                                            name="name"
                                            onChange={this.handleChange} />
                                    </Form.Field>
                                    {company_users && (
                                        <Form.Field>
                                            <label>{t("Select sales person")}</label>
                                            <Select
                                                disabled={loading || fields_disabled}
                                                fluid
                                                search
                                                name='sales_person'
                                                options={company_users}
                                                placeholder={create ? (t("Sales person")) : (contract_details.sales_person && (contract_details.sales_person.toString()))}
                                                onChange={this.handleSelectChange}
                                                value={contract_details.sales_person && (contract_details.sales_person)}
                                            />
                                        </Form.Field>
                                    )}
                                    <Form.Field>
                                        <label>{t("Permanent")}</label>
                                        <Radio
                                            disabled={loading || fields_disabled}
                                            name="periodSelectRadioGroup"
                                            value="permanent"
                                            checked={this.state.periodSelectRadioGroup === "permanent"}
                                            onChange={this.handleRadioChange} />
                                    </Form.Field>
                                    <Form.Field>
                                        <label>{t("Fixed term")}</label>
                                        <Radio
                                            disabled={loading || fields_disabled}
                                            name="periodSelectRadioGroup"
                                            value="fixed"
                                            checked={this.state.periodSelectRadioGroup === "fixed"}
                                            onChange={this.handleRadioChange} />
                                    </Form.Field>
                                    <Form.Field>
                                        <label>{t("Start date")}</label>
                                        <SemanticDatepicker
                                            firstDayOfWeek={1}
                                            disabled={loading || fields_disabled}
                                            locale={fiLocale}
                                            name="start_date"
                                            pointing="top left"
                                            size="small"
                                            placeholder={contract_details.start_date !== null ? (new Date(contract_details.start_date).toLocaleString('fi-FI', { dateStyle: 'short' })) : ("")}
                                            onDateChange={selected => this.handleStartDateChange(selected)}
                                            format='DD.MM.YYYY'
                                        />
                                    </Form.Field>
                                    {this.state.periodSelectRadioGroup === "fixed" && (
                                        <Form.Field>
                                            <label>{t("End date")}</label>
                                            <SemanticDatepicker
                                                firstDayOfWeek={1}
                                                disabled={loading || fields_disabled}
                                                locale={fiLocale}
                                                name="end_date"
                                                pointing="top left"
                                                size="small"
                                                placeholder={contract_details.end_date !== null ? (new Date(contract_details.end_date).toLocaleString('fi-FI', { dateStyle: 'short' })) : ("")}
                                                onDateChange={selected => this.handleEndDateChange(selected)}
                                                format='DD.MM.YYYY' />
                                        </Form.Field>
                                    )}
                                    <Form.Field>
                                        <label>{t("Invoicing period (months)")}</label>
                                        <Select
                                            disabled={loading || fields_disabled}
                                            fluid
                                            search
                                            options={this.state.periodLengthOptions}
                                            value={contract_details.invoicing_period_length.toString()}
                                            placeholder={contract_details.invoicing_period_length.toString()}
                                            name="invoicing_period_length"
                                            onChange={this.handleSelectChange} />
                                    </Form.Field>
                                    <Form.Field>
                                        <label>{t("Invoicing type")}</label>
                                        <Select
                                            disabled={loading || fields_disabled}
                                            fluid
                                            search
                                            options={[{ key: "B", text: t("Begining of period"), value: "B" }, { key: "A", text: t("After the period"), value: "A" }]}
                                            value={contract_details.invoicing_type}
                                            placeholder={contract_details.invoicing_type}
                                            name="invoicing_type"
                                            onChange={this.handleSelectChange} />
                                    </Form.Field>
                                    <Form.Field>
                                        <label>{t("Next invoice date")}</label>
                                        <Input
                                            disabled={loading || fields_disabled}
                                            value={new Date(contract_details.next_invoice_date).toLocaleString('fi-FI', { dateStyle: 'short' })}
                                        />
                                    </Form.Field>
                                    <Form.Field>
                                        <label>{t("Next invoice create date")}</label>
                                        <Input
                                            disabled={loading || fields_disabled}
                                            value={new Date(contract_details.next_invoice_create_date).toLocaleString('fi-FI', { dateStyle: 'short' })}
                                        />
                                    </Form.Field>
                                    <Form.Field>
                                        <label>{t("Invoice date transfer (days)")}</label>
                                        <Select
                                            disabled={loading || fields_disabled}
                                            fluid
                                            search
                                            options={this.state.transferDaysOptions}
                                            value={contract_details.invoice_date_transfer.toString()}
                                            placeholder={contract_details.invoice_date_transfer.toString()}
                                            name="invoice_date_transfer"
                                            onChange={this.handleSelectChange} />
                                    </Form.Field>
                                    <Form.Field>
                                        <label>{t("Create (days) before invoice date")}</label>
                                        <Select
                                            disabled={loading || fields_disabled}
                                            fluid
                                            search
                                            options={this.state.createBeforeDaysOptions}
                                            value={contract_details.advance_create_days.toString()}
                                            placeholder={contract_details.advance_create_days.toString()}
                                            name="advance_create_days"
                                            onChange={this.handleSelectChange} />
                                    </Form.Field>
                                    <Button type="button" fluid disabled={loading || fields_disabled} primary content={t("Preview invoicing periods")} onClick={() => this.openPeriodPreviewModal()} />
                                    <br/>
                                    <Form.Field>
                                        <Checkbox
                                            disabled={loading || fields_disabled}
                                            name="no_vat_contract"
                                            label={t("VAT 0%/Reverse VAT contract")}
                                            onChange={this.handleNoVatCheckboxChange}
                                            checked={this.state.no_vat_contract}
                                            value={this.state.no_vat_contract}
                                        />
                                    </Form.Field>
                                    {this.state.no_vat_contract && (
                                        <Form.Field>
                                            <label>{t("No VAT comment")} {contract_details.no_vat_comment ? (`${contract_details.no_vat_comment.length}/70`) : ("0/70")}</label>
                                            <Input
                                                disabled={loading || fields_disabled}
                                                value={contract_details.no_vat_comment}
                                                name="no_vat_comment"
                                                onChange={this.handleChange} />
                                        </Form.Field>
                                    )}
                                    <hr/>
                                    <Header as="h4">{t("Invoicing details")}</Header>
                                    <Form.Field>
                                        <Popup content={t("Contract number on invoice. If empty the system contract number will be used on invoice when creating new contract. If existing contract is modified the value in the field will be used (can be empty as well)")}
                                                        trigger={<label>{t("Contract number (on invoice)")} {contract_details.contract_number_on_invoice ? (`${contract_details.contract_number_on_invoice.length}/35`) : ("0/35")}</label>} />
                                        <Input
                                            disabled={loading || fields_disabled}
                                            value={contract_details.contract_number_on_invoice}
                                            name="contract_number_on_invoice"
                                            onChange={this.handleChange} />
                                    </Form.Field>
                                    <Form.Field>
                                        <label>{t("Invoice comment")} {contract_details.comment ? (`${contract_details.comment.length}/100`) : ("0/100")}</label>
                                        <Input
                                            disabled={loading || fields_disabled}
                                            value={contract_details.comment}
                                            name="comment"
                                            onChange={this.handleChange} />
                                    </Form.Field>
                                    <Form.Field>
                                        <label>{t("Payment term")}</label>
                                        <Input
                                            disabled={loading || fields_disabled}
                                            type='number'
                                            value={contract_details.payment_term}
                                            name="payment_term"
                                            onChange={this.handleChange} />
                                    </Form.Field>
                                    <Form.Field>
                                        <label>{t("Company reference")} {contract_details.company_reference ? (`${contract_details.company_reference.length}/50`) : ("0/50")}</label>
                                        <Input
                                            disabled={loading || fields_disabled}
                                            value={contract_details.company_reference}
                                            name="company_reference"
                                            onChange={this.handleChange} />
                                    </Form.Field>
                                    <Form.Field>
                                        <label>{t("Customer reference")} {contract_details.customer_reference ? (`${contract_details.customer_reference.length}/50`) : ("0/50")}</label>
                                        <Input
                                            disabled={loading || fields_disabled}
                                            value={contract_details.customer_reference}
                                            name="customer_reference"
                                            onChange={this.handleChange} />
                                    </Form.Field>
                                    <Form.Field>
                                        <label>{t("Purchase order")} {contract_details.purchase_order ? (`${contract_details.purchase_order.length}/70`) : ("0/70")}</label>
                                        <Input
                                            disabled={loading || fields_disabled}
                                            value={contract_details.purchase_order}
                                            name="purchase_order"
                                            onChange={this.handleChange} />
                                    </Form.Field>
                                    <Form.Field>
                                        <Checkbox
                                            name="is_automatic_sending"
                                            label={t("Send automatically when created")}
                                            onChange={this.handleCheckboxChange}
                                            checked={contract_details.is_automatic_sending}
                                            value={contract_details.is_automatic_sending}
                                        />
                                    </Form.Field>
                                    {contract_details.is_automatic_sending && (
                                        <Form.Field>
                                            <label>{t("Automatic sending status")}</label>
                                            <Select
                                                disabled={loading || fields_disabled}
                                                fluid
                                                search
                                                options={this.state.automatic_sending_mode_options}
                                                value={contract_details.automatic_sending_mode}
                                                placeholder={contract_details.automatic_sending_mode}
                                                name="automatic_sending_mode"
                                                onChange={this.handleSelectChange} />
                                        </Form.Field>
                                    )}
                                </React.Fragment>
                            )}
                            <br />

                            <Header as="h3">{t("Invoice Items")}</Header>
                            {is_desktop ? (
                                <Button type="button" disabled={loading || fields_disabled} floated="right" color="green" content={t("+ Item")} onClick={() => this.openItemsAddModal()} />
                            ) : (
                                <Button type="button" disabled={loading || fields_disabled} fluid color="green" content={t("+ Item")} onClick={() => this.openItemsAddModal()} />
                            )}    
                            <Table celled striped selectable sortable>
                                {is_desktop && (
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell collapsing />
                                            <Table.HeaderCell>{t("Item")}</Table.HeaderCell>
                                            <Table.HeaderCell>{t("Amount")}</Table.HeaderCell>
                                            <Table.HeaderCell>{t("Net value")}</Table.HeaderCell>
                                            <Table.HeaderCell>{t("Discount %")}</Table.HeaderCell>
                                            <Table.HeaderCell>{t("Vat %")}</Table.HeaderCell>
                                            <Table.HeaderCell>{t("Total")}</Table.HeaderCell>
                                            <Table.HeaderCell />
                                        </Table.Row>
                                    </Table.Header>
                                )}
                                <Table.Body>
                                    {contract_items_list.map((item, i) => {
                                        return (
                                            <Table.Row key={i + 1}>
                                                <Table.Cell collapsing>
                                                    {item.sequence_on_invoice !== 1 && (
                                                        <Popup content={t("Move up in the list")} trigger={
                                                            <Icon link name="arrow up" onClick={() => this.handleChangeItemSequence(item, "up")} /> } />
                                                    )}
                                                    {item.sequence_on_invoice !== contract_items_list.length && (
                                                        <Popup content={t("Move down in the list")} trigger={
                                                            <Icon link name="arrow down" onClick={() => this.handleChangeItemSequence(item, "down")} /> } />
                                                    )}
                                                </Table.Cell>
                                                <Table.Cell collapsing onClick={() => this.openItemDetailsModal(item)}>
                                                    {item.item_type === "D" && (<Popup content={t("Data collection product")} trigger={<Icon fitted name="cloud download" />} />)}
                                                    {item.recurring
                                                        ? (<Popup content={t("Recurring product/invoiced periodically")} trigger={<Icon fitted name="sync" />} />)
                                                        : (<Popup content={t("Product invoiced once")} trigger={<Icon fitted name="file alternate outline" />} />)}
                                                    &nbsp; <b>{item.name}</b><br />{item.description}
                                                </Table.Cell>
                                                <Table.Cell collapsing onClick={() => this.openItemDetailsModal(item)}>
                                                    {!is_desktop && (<React.Fragment>{t("Amount")}<br/></React.Fragment>)}
                                                    {item.amount} {item.measure_unit}
                                                </Table.Cell>
                                                <Table.Cell collapsing onClick={() => this.openItemDetailsModal(item)}>
                                                    {!is_desktop && (<React.Fragment>{t("Net value €")}<br/></React.Fragment>)}
                                                    {item.net_price}
                                                </Table.Cell>
                                                <Table.Cell collapsing onClick={() => this.openItemDetailsModal(item)}>
                                                    {!is_desktop && (<React.Fragment>{t("Discount %")}<br/></React.Fragment>)}
                                                    {item.discount}
                                                </Table.Cell>
                                                <Table.Cell collapsing onClick={() => this.openItemDetailsModal(item)}>
                                                    {!is_desktop && (<React.Fragment>{t("Vat %")}<br/></React.Fragment>)}
                                                    {item.vat_value}
                                                </Table.Cell>
                                                <Table.Cell collapsing onClick={() => this.openItemDetailsModal(item)}>
                                                    {!is_desktop && (<React.Fragment>{t("Total €")}<br/></React.Fragment>)}
                                                    <Header as="h5">{getItemNetPrice(item).toFixed(2)} €</Header>({getItemGrossPrice(item).toFixed(2)} €)</Table.Cell>
                                                <Table.Cell collapsing textAlign='center'>
                                                    <Popup content={t("Deletes item straight away without needing to Save the contract")} trigger={
                                                            <Icon link color="red" name='trash alternate outline' onClick={() => this.handleItemsListChange(item, "", "remove")} />
                                                            // <Button type="button" negative size='tiny' icon='trash alternate outline' onClick={() => this.handleItemsListChange(item, "", "remove")} />
                                                        } />
                                                </Table.Cell>
                                            </Table.Row>
                                        );
                                    })}
                                    <Table.Row>
                                        <Table.Cell />
                                        <Table.Cell />
                                        <Table.Cell />
                                        <Table.Cell />
                                        <Table.Cell />
                                        <Table.Cell >
                                            <Header as="h5">{t("Total")}</Header>
                                        </Table.Cell>
                                        <Table.Cell collapsing>
                                            <Header as="h5">{getTotalNetPrice(contract_items_list).toFixed(2)} €</Header>({getTotalGrossPrice(contract_items_list).toFixed(2)} €)
                                        </Table.Cell>
                                        <Table.Cell />
                                    </Table.Row>
                                </Table.Body>
                            </Table>
                            {error && <Message error header={t("Error")} content={error} />}
                            {success_mgs && <Message positive header={success_mgs} />}
                            <Button type="button" primary onClick={(e) => this.handleSubmit(e)} loading={loading} disabled={loading || fields_disabled}>
                                {create ? (t("Create")) : (t("Save"))}
                            </Button>
                        </Form>
                    </div>
                )}

                {/* Invoice items select modal. Lists available products and adds selected ones to the invoice items list */}
                <Modal size='small' open={contract_items_select_modal_open} dimmer="inverted" scrolling="true">
                    <Modal.Header>
                        <Header as="h3">{t("Pick products and services")}</Header>
                        <Button type="button" positive icon='add circle' content={t("Add products to contract")} onClick={() => this.handleAddItemsToContract()} />
                        <Input
                            size="mini"
                            icon='search'
                            value={search}
                            name="search"
                            onChange={this.handleItemSearchChange} />
                        <Button type="button" floated='right' negative onClick={() => this.closeItemsAddModal()} >{t("Close")}</Button>
                    </Modal.Header>
                    <Modal.Content>
                        <Container>
                            {products && (
                                <React.Fragment>
                                    {/* Table for data collection items */}
                                    {filtered_dc_products && (
                                        <React.Fragment>
                                            {filtered_dc_products.length > 0 && (
                                                <React.Fragment>
                                                    <Header as="h3" color="blue">{t("Data collection items")}</Header>
                                                    <Table striped selectable unstackable size='small'>
                                                        <Table.Header>
                                                            <Table.Row>
                                                                <Table.HeaderCell />
                                                                <Table.HeaderCell content={t("Product")} />
                                                                <Table.HeaderCell content={t("Amount for invoice")} />
                                                                <Table.HeaderCell content={t("Net price")} />
                                                            </Table.Row>
                                                        </Table.Header>
                                                        <Table.Body>
                                                            {filtered_dc_products.map(dc_item => {
                                                                return (
                                                                    <React.Fragment key={dc_item.id}>
                                                                        {dc_item.is_active && (
                                                                            <Table.Row key={dc_item.id} >
                                                                                <Table.Cell collapsing>
                                                                                    <Checkbox
                                                                                        checked={this.handleCheckIfSelectedProduct(dc_item) === true}
                                                                                        value={dc_item}
                                                                                        onChange={this.handleAddItemsCheckboxChange} />
                                                                                </Table.Cell>
                                                                                <Table.Cell >
                                                                                    <Popup content={t("Data collection product")} trigger={<Icon fitted name="cloud download" />} />
                                                                                    {dc_item.product.recurring
                                                                                        ? (<Popup content={t("Recurring product/invoiced periodically")} trigger={<Icon fitted name="sync" />} />)
                                                                                        : (<Popup content={t("Product invoiced once")} trigger={<Icon fitted name="file alternate outline" />} />)}
                                                                                    &nbsp; <b>{dc_item.product.name}</b> <Header size='small' floated="right">({dc_item.product.code})</Header>

                                                                                    {/* <Header size='tiny'>({dc_item.product.code})</Header> */}
                                                                                </Table.Cell>
                                                                                <Table.Cell collapsing>
                                                                                    <Header as="h4">{parseFloat(dc_item.last_value) - parseFloat(dc_item.last_invoiced_value)}</Header>
                                                                                </Table.Cell>
                                                                                <Table.Cell singleLine collapsing textAlign='right'>
                                                                                    <Header as="h4">{dc_item.product.net_price} €</Header>
                                                                                </Table.Cell>
                                                                            </Table.Row>
                                                                        )}
                                                                    </React.Fragment>
                                                                );
                                                            })}
                                                        </Table.Body>
                                                    </Table>
                                                </React.Fragment>
                                            )}
                                        </React.Fragment>
                                    )}
                                    <hr />
                                    <Header as="h3" color="blue">{t("Normal products")}</Header>
                                        {filtered_products.length > 0 && (
                                            <Table striped selectable unstackable size='small'>
                                                <Table.Header>
                                                    <Table.Row>
                                                        <Table.HeaderCell colSpan='2'>{t("Name")}</Table.HeaderCell>
                                                        <Table.HeaderCell content={t("Net price")} />
                                                    </Table.Row>
                                                </Table.Header>
                                                <Table.Body>
                                                    {filtered_products.map((product, key) => {
                                                        return (
                                                            <React.Fragment key={key}>
                                                                {product.is_active && (
                                                                    <Table.Row key={key} >
                                                                        <Table.Cell collapsing>
                                                                            <Checkbox
                                                                                checked={this.handleCheckIfSelectedProduct(product) === true}
                                                                                value={product}
                                                                                onChange={this.handleAddItemsCheckboxChange} />
                                                                        </Table.Cell>
                                                                        <Table.Cell>
                                                                            {product.recurring
                                                                                ? (<Popup content={t("Recurring product/invoiced periodically")} trigger={<Icon fitted name="sync" />} />)
                                                                                : (<Popup content={t("Product invoiced once")} trigger={<Icon fitted name="file alternate outline" />} />)}
                                                                            &nbsp; <b>{product.name}</b> <Header size='small' floated="right">({product.code})</Header>
                                                                        </Table.Cell>
                                                                        <Table.Cell collapsing textAlign='right'>
                                                                            <Header as="h4">{product.net_price} €</Header>
                                                                        </Table.Cell>
                                                                    </Table.Row>
                                                                )}
                                                            </React.Fragment>
                                                        );
                                                    })}
                                                </Table.Body>
                                            </Table>
                                        )}
                                </React.Fragment>
                            )}
                        </Container>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button type="button" negative onClick={() => this.closeItemsAddModal()} >{t("Close")}</Button>
                    </Modal.Actions>
                </Modal>

                {/* Contract items update modal. */}
                <Modal size='small' open={contract_item_details_modal_open} dimmer="inverted" scrolling="true">
                    <Modal.Header>
                        <Header as="h3">{t("Edit contract item")}</Header>
                    </Modal.Header>
                    <Modal.Content>
                        <Container>
                            {active_contract_item && (
                                <Form error={error !== null}>
                                    <Form.Field required>
                                        <label>{t("Name")} {active_contract_item.name ? (`${active_contract_item.name.length}/100`) : ("0/100")}</label>
                                        <Input
                                            required
                                            value={active_contract_item.name}
                                            name="name"
                                            onChange={this.handleItemDetailsChange} />
                                    </Form.Field>
                                    <Form.Field>
                                        <label>{t("Description")} {active_contract_item.description ? (`${active_contract_item.description.length}/100`) : ("0/100")}</label>
                                        <Input
                                            value={active_contract_item.description}
                                            name="description"
                                            onChange={this.handleItemDetailsChange} />
                                    </Form.Field>
                                    <Form.Field required>
                                        <label>{t("Amount")}</label>
                                        <Input
                                            required
                                            value={active_contract_item.amount}
                                            name="amount"
                                            onChange={this.handleItemDetailsChange} />
                                    </Form.Field>
                                    {active_contract_item.item_type === "D" && (
                                        <Form.Field>
                                            <Checkbox
                                                name="update_dc_value_on_invoice_date"
                                                label={t("Update amount on invoice date")}
                                                onChange={this.handleItemCheckboxChange}
                                                checked={active_contract_item.update_dc_value_on_invoice_date}
                                                value={active_contract_item.update_dc_value_on_invoice_date}
                                            />
                                        </Form.Field>
                                    )}
                                    <Form.Field required>
                                        <label>{t("Net value")}</label>
                                        <Input
                                            required
                                            value={active_contract_item.net_price}
                                            name="net_price"
                                            onChange={this.handleItemDetailsChange} />
                                    </Form.Field>
                                    <Form.Field required>
                                        <label>{t("Discount %")}</label>
                                        <Input
                                            required
                                            value={active_contract_item.discount}
                                            name="discount"
                                            onChange={this.handleItemDetailsChange} />
                                    </Form.Field>
                                    <Form.Field required>
                                        <label>{t("Vat %")}</label>
                                        <Select
                                            required
                                            fluid
                                            search
                                            options={this.state.vat_value_options}
                                            value={active_contract_item.vat_value}
                                            placeholder={active_contract_item.vat_value}
                                            name="vat_value"
                                            onChange={this.handleItemDetailsSelectChange} />
                                    </Form.Field>


                                    {error && <Message error header={t("Error")} content={error} />}
                                    {success_mgs && <Message positive header={success_mgs} />}
                                    <Button primary type="button" loading={loading} disabled={loading} onClick={(e) => this.handleItemDetailsSubmit(e)}>
                                        {t("Save")}
                                    </Button>
                                </Form>
                            )}
                        </Container>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button type="button" negative onClick={() => this.closeItemDetailsModal()} >{t("Close")}</Button>
                    </Modal.Actions>
                </Modal>

                {/* Period preview modal. */}
                <Modal size='small' open={period_preview_modal_open} dimmer="inverted" centered={false} >
                    <Modal.Header>
                        <Header as="h3">{t("Invoicing period preview")}</Header>
                    </Modal.Header>
                    <Modal.Content>
                        <Container>
                            <Table striped selectable unstackable size='small'>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell collapsing>{t("Period")}</Table.HeaderCell>
                                        <Table.HeaderCell collapsing>{t("Invoice date")}</Table.HeaderCell>
                                        <Table.HeaderCell collapsing>{t("Invoice create date")}</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {preview_periods.map((period, key) => {
                                        return (
                                            <Table.Row key={key}>
                                                <Table.Cell>
                                                    {new Date(period.period_start).toLocaleString('fi-FI', { dateStyle: 'short' })} - {new Date(period.period_end).toLocaleString('fi-FI', { dateStyle: 'short' })}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {new Date(period.invoice_date).toLocaleString('fi-FI', { dateStyle: 'short' })}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {new Date(period.invoice_create_date).toLocaleString('fi-FI', { dateStyle: 'short' })}
                                                </Table.Cell>
                                            </Table.Row>
                                        );
                                    })}
                                </Table.Body>
                            </Table>
                        </Container>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button type="button" negative onClick={() => this.closePeriodPreviewModal()} >{t("Close")}</Button>
                    </Modal.Actions>
                </Modal>
            </Shell>
        );
    }
}
const mapStateToProps = state => {
    return {
        activeCompanyId: state.company.activeCompanyId,
        isAuthenticated: state.auth.token !== null
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setActivity: () => dispatch(setActivity())
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(withTranslation('contractdetails')(ContractDetails))
);