// Copyright Caproc Oy
// System name: Aqua-Net 
// System module: Contract
// Author: TS
//
// 28.3.2020 TS Initial version
// 29.3.2020 TS Added Status field
// 31.3.2020 TS Added Price Change events
// 6.6.2020 TS Changed remove icon to trash bin
// 20.6.2020 TS Added export to CSV


import React from "react";
import { Message, Header, Button, Table, Segment, Dimmer, Loader, Image, Confirm, Modal, Grid, GridColumn, Card, Container, Icon, Responsive } from "semantic-ui-react";
import Shell from "./Shell";
import { authAxios, exportToCsv, getWidth } from "../../utils";
import { setActivity } from "../../store/actions/auth";
import { withRouter, Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import { fetchContractPriceChangesURL, deleteContractPriceChangeURL } from "../../constants";


class ContractPriceChanges extends React.Component {
    state = {
        contract_pcs: null,
        delete_confirm_open: false,
        delete_confirm_text: "",
        delete_item: "",
        active_contract_pc: null,
        error: null,
        loading: false
    };

    componentDidMount() {
        this.props.setActivity();
        this.handleFetchList();
    }

    handleFetchList = () => {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;
        authAxios
            .get(fetchContractPriceChangesURL(activeCompanyId))
            .then(res => {
                this.setState({
                    contract_pcs: res.data,
                    error: null,
                    loading: false
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    error: err
                });
            });
    };

    handleDeleteContractPriceChange = contract_pc_id => {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;
        authAxios
            .delete(deleteContractPriceChangeURL(activeCompanyId, contract_pc_id))
            .then(res => {
                this.setState({
                    error: null,
                    loading: false
                });
                this.handleFetchList();
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    error: err
                });
            });
        this.setState({
            delete_confirm_open: false,
            delete_confirm_text: "",
            delete_item: ""
        });
    };

    contractPriceChangeDetails = id => {
        this.props.history.push(`/contract-price-change-details/${id}/`)
    };

    handleMenuItemClick = (e, { name }) => this.setState({ activeMenuItem: name });

    openDeleteConfirm = (text, delete_item) => {
        this.setState({
            delete_confirm_open: true,
            delete_confirm_text: text,
            delete_item: delete_item
        });
    };

    closeDeleteConfirm = () => {
        this.setState({
            delete_confirm_open: false,
            delete_confirm_text: "",
            delete_item: ""
        });
    };

    openContractPriceChangeDetails = contract_pc => {
        this.setState({
            modal_open: true,
            active_contract_pc: contract_pc
        });
    };

    closeModal() {
        this.setState({
            modal_open: false,
            active_contract_pc: null
        });
    };

    handleCheckIfInTheFuture = contract_pc => {
        let current_date = new Date();
        if (new Date(contract_pc.change_date) < current_date) {
            return false;
        } else {
            return true;
        }
    };

    handleExportToCSV = items => {
        const { t } = this.props;
        if (items !== null) {
            const titles = [
                t("Name"),
                t("Description"),
                t("Change"),
                t("Change date"),
                t("Author"),
                t("Status"),
                t("Time")
            ];
            let items_to_csv = [];
            let i = 0;
            for (i in items) {
                items_to_csv.push({
                    name: items[i].name,
                    description: items[i].description,
                    change: `${items[i].change_amount.toFixed(2)} ${items[i].change_type}`,
                    change_date: new Date(items[i].change_date).toLocaleString('fi-FI', { dateStyle: 'short' }),
                    author: `${items[i].author.first_name} ${items[i].author.last_name}`,
                    status: items[i].is_completed ? (t("Completed")) : (t("Scheduled")),
                    completed_date: new Date(items[i].date_completed).toLocaleString('fi-FI')
                });
            }
            exportToCsv(this.props.activeCompanyId, "export", titles, items_to_csv);
        }
    };

    render() {
        const { error, loading, contract_pcs, active_contract_pc, delete_confirm_open, delete_confirm_text, delete_item, modal_open } = this.state;
        const { isAuthenticated, t } = this.props;
        if (!isAuthenticated) {
            return <Redirect to="/login" />;
        }
        // console.log(this.state);
        const is_desktop =  getWidth() > Responsive.onlyMobile.maxWidth ? true : false;

        return (
            <Shell>
                {is_desktop && (
                    <Button.Group floated="right" basic size='small'>
                        <Button icon="download" content="CSV" onClick={() => this.handleExportToCSV(contract_pcs)} />
                    </Button.Group>
                )}

                <Header as="h3">{t("Contract price changes")}</Header>
                <Link to="/contract-price-change-details/create/">
                    <Button type="button" floated="right" color="green">
                        {t("+ Price Change")}
                    </Button>
                </Link>
                <br/>
                <br/>
                {error && (
                    <Message
                        error
                        header={t("There was an error")}
                        content={JSON.stringify(error)}
                    />
                )}
                {loading && (
                    <Segment>
                        <Dimmer active inverted>
                            <Loader inverted>Loading</Loader>
                        </Dimmer>
                        <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
                    </Segment>
                )}

                {contract_pcs && (
                    <React.Fragment>
                        <Table striped selectable celled>
                            {is_desktop && (
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell collapsing>{t("Name")}</Table.HeaderCell>
                                        <Table.HeaderCell collapsing>{t("Description")}</Table.HeaderCell>
                                        <Table.HeaderCell collapsing>{t("Change")}</Table.HeaderCell>
                                        <Table.HeaderCell collapsing>{t("Change date")}</Table.HeaderCell>
                                        <Table.HeaderCell collapsing>{t("Author")}</Table.HeaderCell>
                                        <Table.HeaderCell collapsing>{t("Status")}</Table.HeaderCell>
                                        <Table.HeaderCell collapsing />
                                    </Table.Row>
                                </Table.Header>
                            )}
                            <Table.Body>
                                {contract_pcs.map((contract_pc) => {
                                    return (
                                        <Table.Row key={contract_pc.id}>
                                            <Table.Cell collapsing onClick={() => this.openContractPriceChangeDetails(contract_pc)}>
                                                {!is_desktop && (<React.Fragment><b>{t("Name")}</b><br/></React.Fragment>)}
                                                {contract_pc.name
                                            }</Table.Cell>
                                            <Table.Cell collapsing onClick={() => this.openContractPriceChangeDetails(contract_pc)}>
                                                {!is_desktop && (<React.Fragment><b>{t("Description")}</b><br/></React.Fragment>)}
                                                {contract_pc.description}
                                            </Table.Cell>
                                            <Table.Cell collapsing onClick={() => this.openContractPriceChangeDetails(contract_pc)}>
                                                {!is_desktop && (<React.Fragment><b>{t("Change")}</b><br/></React.Fragment>)}
                                                {contract_pc.change_amount.toFixed(2)} {contract_pc.change_type}
                                            </Table.Cell>
                                            <Table.Cell collapsing onClick={() => this.openContractPriceChangeDetails(contract_pc)}>
                                                {!is_desktop && (<React.Fragment><b>{t("Change date")}</b><br/></React.Fragment>)}
                                                {new Date(contract_pc.change_date).toLocaleString('fi-FI', { dateStyle: 'short' })}
                                            </Table.Cell>
                                            <Table.Cell collapsing onClick={() => this.openContractPriceChangeDetails(contract_pc)}>
                                                {!is_desktop && (<React.Fragment><b>{t("Author")}</b><br/></React.Fragment>)}
                                                {contract_pc.author.first_name} {contract_pc.author.last_name}
                                            </Table.Cell>
                                            <Table.Cell collapsing onClick={() => this.openContractPriceChangeDetails(contract_pc)}>
                                                {!is_desktop && (<React.Fragment><b>{t("Status")}</b><br/></React.Fragment>)}
                                                {contract_pc.is_completed ? (t("Completed")) : (t("Scheduled"))}
                                            </Table.Cell>
                                            {/* Allow delete only for changes which are not done yet. Leave passed ones for information */}
                                            {(this.handleCheckIfInTheFuture(contract_pc) && !contract_pc.is_completed) && (
                                                <Table.Cell collapsing textAlign='center'>
                                                    <Icon link color="red" name='trash alternate outline' onClick={() => this.openDeleteConfirm(t("Are you sure your want to delete", { contract_pc_name: contract_pc.name }), contract_pc.id)} />
                                                    {/* <Button type="button" compact negative size='mini' icon='remove' onClick={() => this.openDeleteConfirm(t("Are you sure your want to delete", { contract_pc_name: contract_pc.name }), contract_pc.id)} /> */}
                                                </Table.Cell>
                                            )}
                                        </Table.Row>
                                    );
                                })}
                            </Table.Body>
                        </Table>
                        <Confirm
                            content={delete_confirm_text}
                            open={delete_confirm_open}
                            onCancel={() => this.closeDeleteConfirm()}
                            onConfirm={() => this.handleDeleteContractPriceChange(delete_item)}
                        />

                    </React.Fragment>
                )}
                {active_contract_pc && (
                    < Modal  open={modal_open} dimmer="inverted" scrolling="true">
                        <Modal.Header>
                            <Header as="h3">{t("Contract price change")}: {active_contract_pc.name}</Header>
                            {!active_contract_pc.is_completed && (
                                <Button type="button" positive icon='edit' content={t("Edit")} loading={loading} onClick={() => this.contractPriceChangeDetails(active_contract_pc.id)} />
                            )}
                            <Button type="button" negative floated="right" onClick={() => this.closeModal()} >{t("Close")}</Button>
                        </Modal.Header>
                        <Modal.Content>
                            <Container>
                                <Grid >
                                    <Grid.Row>
                                        <Grid.Column >
                                            <Header as="h4">{t("Basic details")}</Header>
                                            <Card fluid>
                                                <Card.Content>
                                                    <Card.Description>
                                                        <Grid>
                                                            <Grid.Row>
                                                                <GridColumn>
                                                                    <Table  basic="very" size="small">
                                                                        <Table.Body>
                                                                            <Table.Row>
                                                                                <Table.Cell><Header as="h5">{t("Name")}</Header></Table.Cell>
                                                                                <Table.Cell>{active_contract_pc.name} </Table.Cell>
                                                                            </Table.Row>
                                                                            <Table.Row>
                                                                                <Table.Cell><Header as="h5">{t("Description")}</Header></Table.Cell>
                                                                                <Table.Cell>{active_contract_pc.description} </Table.Cell>
                                                                            </Table.Row>
                                                                            <Table.Row>
                                                                                <Table.Cell><Header as="h5">{t("Change")}</Header></Table.Cell>
                                                                                <Table.Cell>{active_contract_pc.change_amount.toFixed(2)} {active_contract_pc.change_type}</Table.Cell>
                                                                            </Table.Row>
                                                                            <Table.Row>
                                                                                <Table.Cell><Header as="h5">{t("Change date")}</Header></Table.Cell>
                                                                                <Table.Cell>{new Date(active_contract_pc.change_date).toLocaleString('fi-FI', { dateStyle: 'short' })} </Table.Cell>
                                                                            </Table.Row>
                                                                            <Table.Row>
                                                                                <Table.Cell><Header as="h5">{t("Author")}</Header></Table.Cell>
                                                                                <Table.Cell>{active_contract_pc.author.first_name} {active_contract_pc.author.last_name} </Table.Cell>
                                                                            </Table.Row>
                                                                            <Table.Row>
                                                                                <Table.Cell><Header as="h5">{t("Status")}</Header></Table.Cell>
                                                                                <Table.Cell>{active_contract_pc.is_completed ? (t("Completed")) : (t("Scheduled"))} </Table.Cell>
                                                                            </Table.Row>
                                                                            <Table.Row>
                                                                                <Table.Cell><Header as="h5">{t("Completed date")}</Header></Table.Cell>
                                                                                <Table.Cell>{active_contract_pc.date_completed ? (new Date(active_contract_pc.date_completed).toLocaleString('fi-FI')) : ("")} </Table.Cell>
                                                                            </Table.Row>
                                                                        </Table.Body>
                                                                    </Table>
                                                                </GridColumn>
                                                                {/* <Grid.Column width={5}>{t("Name")}</Grid.Column>
                                                                <GridColumn width={8}><Header as="h5">{active_contract_pc.name} </Header></GridColumn>
                                                                <Grid.Column width={5}>{t("Description")}</Grid.Column>
                                                                <GridColumn width={8}><Header as="h5">{active_contract_pc.description} </Header></GridColumn>
                                                                <Grid.Column width={5}>{t("Change")}</Grid.Column>
                                                                <GridColumn width={8}><Header as="h5">{active_contract_pc.change_amount.toFixed(2)} {active_contract_pc.change_type}</Header></GridColumn>
                                                                <Grid.Column width={5}>{t("Change date")}</Grid.Column>
                                                                <GridColumn width={8}><Header as="h5">{new Date(active_contract_pc.change_date).toLocaleString('fi-FI', { dateStyle: 'short' })} </Header></GridColumn> */}
                                                            </Grid.Row>
                                                        </Grid>
                                                    </Card.Description>
                                                </Card.Content>
                                            </Card>
                                        </Grid.Column>

                                    </Grid.Row>
                                </Grid>
                                <br />
                            </Container>
                            <Container>
                                <Header as="h4">{t("Products")}</Header>
                                <Table striped selectable celled>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>{t("Name")}</Table.HeaderCell>
                                            <Table.HeaderCell>{t("Description")}</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>

                                    {active_contract_pc.contractpricechangeproducts.length > 0 && (
                                        <Table.Body>
                                            {active_contract_pc.contractpricechangeproducts.map(item => {
                                                return (
                                                    <Table.Row key={item.id} >
                                                        <Table.Cell collapsing><b>{item.product.name}</b><Header sub floated="right">({item.product.code})</Header></Table.Cell>
                                                        <Table.Cell collapsing>{item.product.description}</Table.Cell>
                                                    </Table.Row>
                                                );
                                            })}
                                        </Table.Body>
                                    )}
                                </Table>
                            </Container>
                            <React.Fragment>
                                <br />
                                <hr />
                                <Container>
                                    <Header as="h4">{t("Contracts")}</Header>
                                    <Table striped selectable sortable celled >
                                        <Table.Header>
                                            <Table.Row>
                                                <Table.HeaderCell>{t("Contract")}</Table.HeaderCell>
                                                <Table.HeaderCell>{t("Customer")}</Table.HeaderCell>
                                                <Table.HeaderCell>{t("Description")}</Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>
                                        {active_contract_pc.contractpricechangecontracts.length > 0 && (
                                            <Table.Body>
                                                {active_contract_pc.contractpricechangecontracts.map(item => {
                                                    return (
                                                        <Table.Row key={item.id} >
                                                            <Table.Cell collapsing><b>{item.contract.name}</b><Header sub floated="right">({item.contract.number})</Header></Table.Cell>
                                                            <Table.Cell collapsing><b>{item.contract.customer.name}</b><Header sub floated="right">({item.contract.customer.customer_number})</Header></Table.Cell>
                                                            <Table.Cell collapsing>{item.contract.comment}</Table.Cell>
                                                        </Table.Row>
                                                    );
                                                })}
                                            </Table.Body>
                                        )}
                                    </Table>
                                </Container>
                            </React.Fragment>
                            <React.Fragment>
                                <br />
                                <hr />
                                <Container>
                                    <Header as="h4">{t("Price change events")}</Header>
                                    <Table striped selectable sortable celled >
                                        <Table.Header>
                                            <Table.Row>
                                                {/* <Table.HeaderCell collapsing>{t("Contract")}</Table.HeaderCell>
                                                <Table.HeaderCell collapsing>{t("Customer")}</Table.HeaderCell> */}
                                                <Table.HeaderCell collapsing>{t("Contract item")}</Table.HeaderCell>
                                                <Table.HeaderCell collapsing>{t("Old value")}</Table.HeaderCell>
                                                <Table.HeaderCell collapsing>{t("New value")}</Table.HeaderCell>
                                                <Table.HeaderCell collapsing>{t("Author")}</Table.HeaderCell>
                                                <Table.HeaderCell collapsing>{t("Time")}</Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>
                                        {active_contract_pc.contractpricechangeevents.length > 0 && (
                                            <Table.Body>
                                                {active_contract_pc.contractpricechangeevents.map(item => {
                                                    return (
                                                        <Table.Row key={item.id} >
                                                            <Table.Cell collapsing>{item.contract_price_change_contract.contract.customer.name}/{item.contract_price_change_contract.contract.name}/{item.contract_item.name} ({item.contract_item.code})</Table.Cell>
                                                            <Table.Cell collapsing >{item.old_value} €</Table.Cell>
                                                            <Table.Cell collapsing >{item.new_value} €</Table.Cell>
                                                            <Table.Cell collapsing >{item.author.first_name} {item.author.last_name}</Table.Cell>
                                                            <Table.Cell collapsing >{new Date(item.date_created).toLocaleString('fi-FI')}</Table.Cell>
                                                        </Table.Row>
                                                    );
                                                })}
                                            </Table.Body>
                                        )}
                                    </Table>
                                </Container>
                            </React.Fragment>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button type="button" negative onClick={() => this.closeModal()} >{t("Close")}</Button>
                        </Modal.Actions>
                    </Modal >
                )}
            </Shell>
        );
    }
}
const mapStateToProps = state => {
    return {
        activeCompanyId: state.company.activeCompanyId,
        isAuthenticated: state.auth.token !== null
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setActivity: () => dispatch(setActivity())
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(withTranslation('contractpricechanges')(ContractPriceChanges))
);