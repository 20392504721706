// Copyright Caproc Oy
// System name: Aqua-Net 
// System module: Invoices
// Author: TS
//
// 1.2.2020 TS Initial version
// 26.3.2020 TS Added Abnormal payments menu 
// 11.7.2020 TS Added Company Selection banner
// 3.9.2020 TS Added use of disabled props

import React from "react";
import { Grid, Container, Segment, Header, Menu, Statistic } from "semantic-ui-react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import { CompanySelection } from "../../utils";

const Shell = props => (
    <>
    <Container><CompanySelection/></Container>
    < Segment vertical>
        <Container fluid>
            <Grid container stackable verticalAlign="top" divided columns={2} >
                <Grid.Row>
                    <Grid.Column width={3} textAlign="center">
                        <Header as="h3">{props.t("Invoices")}</Header>
                        <Menu vertical compact>
                            <Menu.Item
                                disabled = {props.loading}
                                active={props.location.pathname === "/invoice/invoices/C/"} // C = 'Created' in Invoice model allowed statuses
                                onClick={() => props.history.push("/invoice/invoices/C/")}
                                name="invoices-created"
                            >
                                <Statistic size='mini' >
                                    <Statistic.Label>{props.t("Created")}</Statistic.Label>
                                    <Statistic.Value>{props.statistics && (`${props.statistics.C.amount}`)}</Statistic.Value>
                                </Statistic>
                                {/* {props.t("Created")} {props.statistics && (`(${props.statistics.C.amount})`)} */}
                            </Menu.Item>
                            <Menu.Item
                                disabled = {props.loading}
                                active={props.location.pathname === "/invoice/invoices/A/"} // A = 'Approved' in Invoice model allowed statuses
                                onClick={() => props.history.push("/invoice/invoices/A/")}
                                name="invoices-approved"
                            >
                                <Statistic size='mini' >
                                    <Statistic.Label>{props.t("Approved")}</Statistic.Label>
                                    <Statistic.Value>{props.statistics && (`${props.statistics.A.amount}`)}</Statistic.Value>
                                </Statistic>
                                {/* {props.t("Approved")} {props.statistics && (`(${props.statistics.A.amount})`)} */}
                            </Menu.Item>
                            <Menu.Item
                                disabled = {props.loading}
                                active={props.location.pathname === "/invoice/invoices/Q/"} // Q = 'Sending' in Invoice model allowed statuses
                                onClick={() => props.history.push("/invoice/invoices/Q/")}
                                name="invoices-sending"
                            >
                                <Statistic size='mini' >
                                    <Statistic.Label>{props.t("Sending")}</Statistic.Label>
                                    <Statistic.Value>{props.statistics && (`${props.statistics.Q.amount}`)}</Statistic.Value>
                                </Statistic>
                            </Menu.Item>
                            <Menu.Item
                                disabled = {props.loading}
                                active={props.location.pathname === "/invoice/invoices/S/"} // S = 'Sent' in Invoice model allowed statuses
                                onClick={() => props.history.push("/invoice/invoices/S/")}
                                name="invoices-sent"
                            >
                                <Statistic size='mini' >
                                    <Statistic.Label>{props.t("Sent")}</Statistic.Label>
                                    <Statistic.Value>{props.statistics && (`${props.statistics.S.amount}`)}</Statistic.Value>
                                </Statistic>
                                {/* {props.t("Sent")} {props.statistics && (`(${props.statistics.S.amount})`)} */}
                            </Menu.Item>
                            <Menu.Item
                                disabled = {props.loading}
                                active={props.location.pathname === "/invoice/invoices/O/"} // O = 'Overdue' in Invoice model allowed statuses
                                onClick={() => props.history.push("/invoice/invoices/O/")}
                                name="invoices-overdue"
                            >
                                <Statistic size='mini' >
                                    <Statistic.Label>{props.t("Overdue")}</Statistic.Label>
                                    <Statistic.Value>{props.statistics && (`${props.statistics.O.amount}`)}</Statistic.Value>
                                </Statistic>
                                {/* {props.t("Overdue")} {props.statistics && (`(${props.statistics.O.amount})`)} */}
                            </Menu.Item>
                            <Menu.Item
                                disabled = {props.loading}
                                active={props.location.pathname === "/invoice/invoices/B/"} // B = 'Abnormal' in Invoice model allowed statuses
                                onClick={() => props.history.push("/invoice/invoices/B/")}
                                name="invoices-abnormal"
                            >
                                <Statistic size='mini' >
                                    <Statistic.Label>{props.t("Abnormal")}</Statistic.Label>
                                    <Statistic.Value>{props.statistics && (`${props.statistics.B.amount}`)}</Statistic.Value>
                                </Statistic>
                                {/* {props.t("Abnormal")} {props.statistics && (`(${props.statistics.B.amount})`)} */}
                            </Menu.Item>
                            <Menu.Item
                                disabled = {props.loading}
                                active={props.location.pathname === "/invoice/invoices/P/"} // P = 'Paid' in Invoice model allowed statuses
                                onClick={() => props.history.push("/invoice/invoices/P/")}
                                name="invoices-paid"
                            >
                                <Statistic size='mini' >
                                    <Statistic.Label>{props.t("Paid")}</Statistic.Label>
                                </Statistic>
                                {/* {props.t("Paid")} */}
                            </Menu.Item>
                            <Menu.Item
                                disabled = {props.loading}
                                active={props.location.pathname === "/invoice/invoices/all/"} // P = 'Paid' in Invoice model allowed statuses
                                onClick={() => props.history.push("/invoice/invoices/all/")}
                                name="invoices-all"
                            >
                                <Statistic size='mini' >
                                    <Statistic.Label>{props.t("All")}</Statistic.Label>
                                </Statistic>
                                {/* {props.t("All")} */}
                            </Menu.Item>
                        </Menu>
                    </Grid.Column>
                    <Grid.Column width={13}>{props.children}</Grid.Column>
                </Grid.Row>
            </Grid>
        </Container>
    </Segment >
    </>
);

const mapStateToProps = state => {
    return {
        activeCompanyId: state.company.activeCompanyId
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        null
    )(withTranslation('invoiceshell')(Shell))
);