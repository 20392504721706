// Copyright Caproc Oy
// System name: Aqua-Net 
// System module: Company
// Author: TS
//
// 1.2.2020 TS Initial version
// 4.5.2020 TS Added "required"/* for items required
// 11.1.2022 TS Added cleaning of spaces for bank account and BIC.

import React from "react";
import { Form, Input, Message, Header, Button, Select } from "semantic-ui-react";
import Shell from "./Shell";
import { authAxios } from "../../utils";
import { setActivity } from "../../store/actions/auth";
import { withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import { companyDetailsURL, countriesURL, updateCompanyDetailsURL } from "../../constants";


class CompanyDetails extends React.Component {
    state = {
        company_id: "",
        vat: "",
        name: "",
        description: "",
        address: "",
        postal_code: "",
        country: "",
        phone: "",
        email: "",
        homepage: "",
        bank_account: "",
        bank_name: "",
        bank_BIC: "",
        last_modified: "",
        author: "",
        city: "",
        country_options: null,
        error: null,
        success_mgs: null,
        loading: false
    };

    componentDidMount() {
        this.props.setActivity();
        this.handleCompanyDetails();
        this.fetchCountryOptions();
    }

    fetchCountryOptions = () => {
        this.setState({
            loading: true,
        });
        authAxios
            .get(countriesURL)
            .then(res => {
                this.setState({
                    country_options: this.handleFormatCountries(res.data),
                    loading: false
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    success_mgs: null,
                    error: err
                });
            });
    };

    handleFormatCountries = countries => {
        const keys = Object.keys(countries);
        return keys.map(k => {
            return {
                key: k,
                text: countries[k],
                value: k
            };
        });
    };

    handleSelectChange = (e, { name, value }) => {
        this.setState({
            [name]: value
        });
    };

    handleCompanyDetails = () => {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;
        authAxios
            .get(companyDetailsURL(activeCompanyId, activeCompanyId))
            .then(res => {
                this.setState({
                    company_id: res.data.id,
                    vat: res.data.vat,
                    name: res.data.name,
                    description: res.data.description,
                    address: res.data.address,
                    postal_code: res.data.postal_code,
                    country: res.data.country,
                    phone: res.data.phone,
                    email: res.data.email,
                    homepage: res.data.homepage,
                    bank_account: res.data.bank_account,
                    bank_name: res.data.bank_name,
                    bank_BIC: res.data.bank_BIC,
                    last_modified: res.data.last_modified,
                    author: res.data.author,
                    city: res.data.city,
                    error: null,
                    success_mgs: null,
                    loading: false
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    success_mgs: null,
                    error: err
                });
            });
    };

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value,
            success_mgs: null,
            error: null
        });
    };

    handleSubmit = e => {
        e.preventDefault();
        this.setState({
            loading: true
        });
        const {
            author,
            vat,
            name,
            description,
            address,
            postal_code,
            country,
            phone,
            email,
            homepage,
            bank_account,
            bank_name,
            bank_BIC,
            city
        } = this.state;
        const { activeCompanyId, t } = this.props;
        if (name === "") {
            this.setState({
                error: t("Please fill in name"),
                success_mgs: null,
                loading: false
            });
        } else if (address === "") {
            this.setState({
                error: t("Please fill in address"),
                success_mgs: null,
                loading: false
            });
        } else if (postal_code === "") {
            this.setState({
                error: t("Please fill in postal code"),
                success_mgs: null,
                loading: false
            });
        } else if (city === "") {
            this.setState({
                error: t("Please fill in city"),
                success_mgs: null,
                loading: false
            });
        } else if (vat.length > 35) {
            this.setState({
                error: t("Max amount of characters is 50 for Vat"),
                success_mgs: null,
                loading: false
            });
        } else if (name.length > 70) {
            this.setState({
                error: t("Max amount of characters is 200 for Name"),
                success_mgs: null,
                loading: false
            });
        } else if (address.length > 105) {
            this.setState({
                error: t("Max amount of characters is 150 for Address"),
                success_mgs: null,
                loading: false
            });
        } else if (postal_code.length > 35) {
            this.setState({
                error: t("Max amount of characters is 50 for Postal code"),
                success_mgs: null,
                loading: false
            });
        } else if (city.length > 35) {
            this.setState({
                error: t("Max amount of characters is 50 for City"),
                success_mgs: null,
                loading: false
            });
        } else if (phone.length > 35) {
            this.setState({
                error: t("Max amount of characters is 20 for Phone"),
                success_mgs: null,
                loading: false
            });
        } else if (homepage.length > 70) {
            this.setState({
                error: t("Max amount of characters is 100 for Homepage"),
                success_mgs: null,
                loading: false
            });
        } else if (bank_account.length > 34) {
            this.setState({
                error: t("Max amount of characters is 50 for Bank account"),
                success_mgs: null,
                loading: false
            });
        } else if (bank_name.length > 35) {
            this.setState({
                error: t("Max amount of characters is 50 for Bank name"),
                success_mgs: null,
                loading: false
            });
        } else if (bank_BIC.length > 11) {
            this.setState({
                error: t("Max amount of characters is 15 for Bank BIC"),
                success_mgs: null,
                loading: false
            });
        } else {
            let bank_account_cleaned = bank_account.replace(/ /g, "");
            let bank_BIC_cleaned = bank_BIC.replace(/ /g, "");
            authAxios
                .put(updateCompanyDetailsURL(activeCompanyId, activeCompanyId), {
                    vat,
                    author,
                    name,
                    description,
                    address,
                    postal_code,
                    country,
                    phone,
                    email,
                    homepage,
                    bank_account_cleaned,
                    bank_name,
                    bank_BIC_cleaned,
                    city
                })
                .then(res => {
                    this.setState({
                        loading: false,
                        success_mgs: t("Company updated successfully"),
                        error: null
                    });
                })
                .catch(err => {
                    this.setState({
                        loading: false,
                        success_mgs: null,
                        error: err
                    });
                });
        }
    };

    render() {
        const {
            vat,
            name,
            description,
            address,
            postal_code,
            country,
            phone,
            email,
            homepage,
            bank_account,
            bank_name,
            bank_BIC,
            last_modified,
            city,
            country_options,
            error,
            success_mgs,
            loading } = this.state;
        const { isAuthenticated, t } = this.props;
        if (!isAuthenticated) {
            return <Redirect to="/login" />;
        }
        return (
            <Shell>
                <Header as="h4">{t("Company details")}</Header>
                <Form error={error !== null}>
                    <Form.Field required>
                        <label>{t("Vat")} {vat ? (`${vat.length}/35`) : ("0/35")}</label>
                        <Input
                            required
                            value={vat}
                            name="vat"
                            onChange={this.handleChange} />
                    </Form.Field>
                    <Form.Field required>
                        <label>{t("Name")} {name ? (`${name.length}/70`) : ("0/70")}</label>
                        <Input
                            required
                            value={name}
                            name="name"
                            onChange={this.handleChange} />
                    </Form.Field>
                    <Form.Field>
                        <label>{t("Description")} {description ? (`${description.length}/100`) : ("0/100")}</label>
                        <Input
                            value={description}
                            name="description"
                            onChange={this.handleChange} />
                    </Form.Field>
                    <Form.Field required>
                        <label>{t("Address")} {address ? (`${address.length}/105`) : ("0/105")}</label>
                        <Input
                            required
                            value={address}
                            name="address"
                            onChange={this.handleChange} />
                    </Form.Field>
                    <Form.Field required>
                        <label>{t("Postal code")} {postal_code ? (`${postal_code.length}/35`) : ("0/35")}</label>
                        <Input
                            required
                            value={postal_code}
                            name="postal_code"
                            onChange={this.handleChange} />
                    </Form.Field>
                    <Form.Field required>
                        <label>{t("City")} {city ? (`${city.length}/35`) : ("0/35")}</label>
                        <Input
                            required
                            value={city}
                            name="city"
                            onChange={this.handleChange} />
                    </Form.Field>
                    {country_options && (
                        <Form.Field required>
                            <label>{t("Country")}</label>
                            <Select
                                required
                                search
                                fluid
                                name='country'
                                options={country_options}
                                placeholder={country}
                                onChange={this.handleSelectChange}
                                value={country}
                            />
                        </Form.Field>
                    )}
                    <Form.Field>
                        <label>{t("Phone")} {phone ? (`${phone.length}/35`) : ("0/35")}</label>
                        <Input
                            value={phone}
                            name="phone"
                            onChange={this.handleChange} />
                    </Form.Field>
                    <Form.Field required>
                        <label>{t("Email")} {email ? (`${email.length}/70`) : ("0/70")}</label>
                        <Input
                            required
                            value={email}
                            name="email"
                            onChange={this.handleChange} />
                    </Form.Field>
                    <Form.Field>
                        <label>{t("Homepage")} {homepage ? (`${homepage.length}/70`) : ("0/70")}</label>
                        <Input
                            value={homepage}
                            name="homepage"
                            onChange={this.handleChange} />
                    </Form.Field>
                    <Form.Field required>
                        <label>{t("Bank account")} {bank_account ? (`${bank_account.length}/34`) : ("0/34")}</label>
                        <Input
                            required
                            value={bank_account}
                            name="bank_account"
                            onChange={this.handleChange} />
                    </Form.Field>
                    <Form.Field required>
                        <label>{t("Bank name")} {bank_name ? (`${bank_name.length}/35`) : ("0/35")}</label>
                        <Input
                            required
                            value={bank_name}
                            name="bank_name"
                            onChange={this.handleChange} />
                    </Form.Field>
                    <Form.Field required>
                        <label>{t("Bank BIC")} {bank_BIC ? (`${bank_BIC.length}/11`) : ("0/11")}</label>
                        <Input
                            required
                            value={bank_BIC}
                            name="bank_BIC"
                            onChange={this.handleChange} />
                    </Form.Field>
                    <Form.Field>
                        <label>{t("Last modified")}</label>
                        <Input
                            value={new Date(last_modified).toLocaleString('fi-FI')}
                            name="last_modified"
                            onChange={this.handleChange} disabled />
                    </Form.Field>

                    {error && <Message error header={t("Error")} content={error} />}
                    {success_mgs && <Message positive header={success_mgs} />}
                    <Button primary type="button" loading={loading} disabled={loading} onClick={(e) => this.handleSubmit(e)}>
                        {t("Save")}
                    </Button>
                </Form>
            </Shell>
        );
    }
}
const mapStateToProps = state => {
    return {
        activeCompanyId: state.company.activeCompanyId,
        isAuthenticated: state.auth.token !== null
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setActivity: () => dispatch(setActivity())
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(withTranslation('companydetails')(CompanyDetails))
);