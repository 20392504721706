// Copyright Caproc Oy
// System name: Aqua-Net 
// System module: Company
// Author: TS
//
// 1.2.2020 TS Initial version
// 6.6.2020 TS Changed remove icon to trash bin
// 20.6.2020 TS Added export to CSV

import React from "react";
import { Message, Header, Button, Table, Segment, Dimmer, Loader, Image, Menu, Confirm, Popup, Icon, Input, Responsive } from "semantic-ui-react";
import Shell from "./Shell";
import { authAxios, exportToCsv, getWidth } from "../../utils";
import { setActivity } from "../../store/actions/auth";
import { withRouter, Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import { fetchProductGroupsURL, deleteProductDetailsURL } from "../../constants";


class Products extends React.Component {
    state = {
        product_groups: null,
        activeMenuItem: this.props.t("Active"),
        delete_confirm_open: false,
        delete_confirm_text: "",
        delete_item: "",
        error: null,
        loading: false,
        search: ""
    };

    componentDidMount() {
        this.props.setActivity();
        this.handleFetchProductGroupList();
    }

    handleFetchProductGroupList = () => {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;
        authAxios
            .get(fetchProductGroupsURL(activeCompanyId))
            .then(res => {
                this.setState({
                    product_groups: res.data,
                    error: null,
                    loading: false
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    error: err
                });
            });
    };

    handleDeleteProduct = product_id => {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;
        authAxios
            .delete(deleteProductDetailsURL(activeCompanyId, product_id))
            .then(res => {
                this.setState({
                    error: null,
                    loading: false
                });
                this.handleFetchProductGroupList();
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    error: err
                });
            });
        this.setState({
            delete_confirm_open: false,
            delete_confirm_text: "",
            delete_item: ""
        });
    }

    productDetails = id => {
        this.props.history.push(`/product/product-details/${id}/`)
    };

    handleMenuItemClick = (e, { name }) => this.setState({ activeMenuItem: name });

    openDeleteConfirm = (text, delete_item) => {
        this.setState({
            delete_confirm_open: true,
            delete_confirm_text: text,
            delete_item: delete_item
        });
    };

    closeDeleteConfirm = () => {
        this.setState({
            delete_confirm_open: false,
            delete_confirm_text: "",
            delete_item: ""
        });
    };

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    handleGetFilterList = items => {
        const { search } = this.state;
        let filtered_list = [];
        filtered_list = items.filter((item) => {
            return item.name.toLowerCase().indexOf(search.toLocaleLowerCase()) !== -1 ||
                item.description.toLowerCase().indexOf(search.toLocaleLowerCase()) !== -1 ||
                item.code.toLowerCase().indexOf(search.toLocaleLowerCase()) !== -1 ||
                item.net_price.toString().toLowerCase().indexOf(search.toLocaleLowerCase()) !== -1 ||
                item.net_price.toString().toLowerCase().indexOf(search.toLocaleLowerCase()) !== -1 ||
                item.vat_value.toString().toLowerCase().indexOf(search.toLocaleLowerCase()) !== -1 ||
                item.measure_unit.toString().toLowerCase().indexOf(search.toLocaleLowerCase()) !== -1 ||
                item.net_cost.toString().toLowerCase().indexOf(search.toLocaleLowerCase()) !== -1;
                
        })
        return filtered_list;
    };

    handleExportToCSV = items => {
        const { t } = this.props;
        if (items !== null) {
            const titles = [
                t("Product name"),
                t("Report name"),
                t("Product code"),
                t("Description"),
                t("Measure unit"),
                t("Net price"),
                t("Net cost"),
                t("Total price"),
                t("VAT%"),
                t("Recurring"),
                t("Is active")
            ];
            let items_to_csv = [];
            let i = 0;
            let gross = 0;
            for (i in items) {
                gross = (((items[i].vat_value / 100) + 1) * items[i].net_price).toFixed(2)
                items_to_csv.push({
                    name: items[i].name,
                    report_name: items[i].report_name,
                    code: items[i].code,
                    description: items[i].description,
                    measure_unit: items[i].measure_unit,
                    net_price: items[i].net_price.toString().replace(".", ","),
                    net_cost: items[i].net_cost.toString().replace(".", ","),
                    gross: gross.toString().replace(".", ","),
                    vat_value: items[i].vat_value,
                    recurring: items[i].recurring ? t("Yes") : t("No"),
                    is_active: items[i].is_active ? t("Yes") : t("No")
                });
            }
            exportToCsv(this.props.activeCompanyId, "export", titles, items_to_csv);
        }
    };

    handleGetFilterListToExport = groups => {
        let i = 0;
        let x = 0;
        let items = [];
        for (i in groups) {
            for (x in groups[i].products) {
                items.push(groups[i].products[x]);
            }
        }
        return this.handleGetFilterList(items);
    };

    render() {
        const { error, loading, product_groups, activeMenuItem, delete_confirm_open, delete_confirm_text, delete_item, search } = this.state;
        const { isAuthenticated, t } = this.props;
        if (!isAuthenticated) {
            return <Redirect to="/login" />;
        }
        // console.log(this.state);
        const is_desktop =  getWidth() > Responsive.onlyMobile.maxWidth ? true : false;
        return (
            <Shell>
                {is_desktop && (
                    <Button.Group floated="right" basic size='small'>
                        <Button icon="download" content="CSV" onClick={() => this.handleExportToCSV(this.handleGetFilterListToExport(product_groups))} />
                    </Button.Group>
                )}
                <Header as="h3">{t("Products")}</Header>
                <Link to="/product/product-details/create/">
                    <Button type="button" floated="right" color="green">
                        {t("+ Product")}
                    </Button>
                </Link>
                <Input
                    floated="right"
                    size="mini"
                    icon='search'
                    value={search}
                    name="search"
                    onChange={this.handleChange} />
                {error && (
                    <Message
                        error
                        header={t("There was an error")}
                        content={JSON.stringify(error)}
                    />
                )}
                {loading && (
                    <Segment>
                        <Dimmer active inverted>
                            <Loader inverted>Loading</Loader>
                        </Dimmer>
                        <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
                    </Segment>
                )}

                {product_groups && (
                    <React.Fragment>
                        <Menu attached="top" tabular>
                            <Menu.Item
                                name={t("Active")}
                                active={activeMenuItem === t("Active")}
                                onClick={this.handleMenuItemClick} />
                            <Menu.Item
                                name={t("Archive")}
                                active={activeMenuItem === t("Archive")}
                                onClick={this.handleMenuItemClick} />
                        </Menu>
                        {product_groups.map((product_group, key) => {
                            return (
                                <React.Fragment key={key}>
                                    <div>
                                        {product_group.products.length > 0 && (
                                            <React.Fragment>
                                                <br></br>
                                                <Link to={`/product/product-group-details/${product_group.id}/`}>
                                                    <Header as="h4" color="blue">{product_group.name} </Header>
                                                </Link>


                                                <Table striped selectable celled>
                                                    {is_desktop && (
                                                        <Table.Header>
                                                            <Table.Row>
                                                                <Table.HeaderCell />
                                                                <Table.HeaderCell>{t("Product name")}</Table.HeaderCell>
                                                                <Table.HeaderCell>{t("Product code")}</Table.HeaderCell>
                                                                <Table.HeaderCell>{t("Net price")}</Table.HeaderCell>
                                                                <Table.HeaderCell>{t("Total price")}</Table.HeaderCell>
                                                                <Table.HeaderCell>{t("VAT%")}</Table.HeaderCell>
                                                                {activeMenuItem === t("Archive") && (
                                                                    <Table.HeaderCell />
                                                                )}
                                                            </Table.Row>
                                                        </Table.Header>
                                                    )}
                                                    <Table.Body>
                                                        {this.handleGetFilterList(product_group.products).map((product) => {
                                                            if ((activeMenuItem === t("Active") && product.is_active) || (activeMenuItem === t("Archive") && !product.is_active)) {
                                                                return (
                                                                    <Table.Row key={product.id} >
                                                                        <Table.Cell onClick={() => this.productDetails(product.id)}>
                                                                            <Popup content={product.recurring ? (t("Recurring product")) : (t("One time product"))} trigger={
                                                                                product.recurring ? (<Icon fitted name="sync" />) : (<Icon fitted name="file alternate outline"  />)
                                                                            } />
                                                                        </Table.Cell>
                                                                        <Table.Cell onClick={() => this.productDetails(product.id)}>
                                                                            {!is_desktop && (<React.Fragment><b>{t("Product name")}</b><br/></React.Fragment>)}
                                                                            {product.name}
                                                                        </Table.Cell>
                                                                        <Table.Cell onClick={() => this.productDetails(product.id)}>
                                                                            {!is_desktop && (<React.Fragment><b>{t("Product code")}</b><br/></React.Fragment>)}
                                                                            {product.code}
                                                                        </Table.Cell>
                                                                        <Table.Cell onClick={() => this.productDetails(product.id)}>
                                                                            {!is_desktop && (<React.Fragment><b>{t("Net price")}</b><br/></React.Fragment>)}
                                                                            {product.net_price} €
                                                                        </Table.Cell>
                                                                        <Table.Cell onClick={() => this.productDetails(product.id)}>
                                                                            {!is_desktop && (<React.Fragment><b>{t("Total price")}</b><br/></React.Fragment>)}
                                                                            {(((product.vat_value / 100) + 1) * product.net_price).toFixed(2)}€
                                                                        </Table.Cell>
                                                                        <Table.Cell>
                                                                            {!is_desktop && (<React.Fragment><b>{t("VAT%")}</b><br/></React.Fragment>)}
                                                                            {product.vat_value}
                                                                        </Table.Cell>
                                                                        {activeMenuItem === t("Archive") && (
                                                                            <Table.Cell collapsing textAlign='center'><Icon link color="red" name="trash alternate outline"
                                                                                onClick={() => this.openDeleteConfirm(t("Are you sure you want to delete?", { product_name: product.name }), product.id)} /></Table.Cell>
                                                                        )}
                                                                    </Table.Row>
                                                                );
                                                            }
                                                        })}
                                                    </Table.Body>
                                                </Table>
                                            </React.Fragment>
                                        )}
                                    </div>
                                </React.Fragment>
                            );
                        })}
                        <Confirm
                            content={delete_confirm_text}
                            open={delete_confirm_open}
                            onCancel={() => this.closeDeleteConfirm()}
                            onConfirm={() => this.handleDeleteProduct(delete_item)}
                        />
                    </React.Fragment>
                )
                }
            </Shell>
        );
    }
}
const mapStateToProps = state => {
    return {
        activeCompanyId: state.company.activeCompanyId,
        isAuthenticated: state.auth.token !== null
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setActivity: () => dispatch(setActivity())
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(withTranslation('products')(Products))
);