// Copyright Caproc Oy
// System name: Aqua-Net 
// System module: store
// Author: TS
//
// 1.2.2020 TS Initial version
// 30.3.2020 TS Added companySetActiveRoles
// 8.7.2020 TS Added companySetActiveRole


import * as actionTypes from "./actionTypes";
import { authAxios } from "../../utils";
import { companyListURL } from "../../constants";


export const companyFetchUserCompaniesStart = () => {
    return {
        type: actionTypes.COMPANY_FETCHUSERCOMPANIES_START
    };
};

export const companyFetchUserCompaniesSuccess = userCompanies => {
    return {
        type: actionTypes.COMPANY_FETCHUSERCOMPANIES_SUCCESS,
        userCompanies
    };
};

export const companyFetchUserCompaniesFail = error => {
    return {
        type: actionTypes.COMPANY_FETCHUSERCOMPANIES_FAIL,
        error,
        userCompanies: null
    };
};

export const companySetActiveCompany = activeCompanyId => {
    return {
        type: actionTypes.COMPANY_SET_ACTIVE_COMPANY,
        activeCompanyId
    };
};

export const companySetActiveRoles = activeRoles => {
    return {
        type: actionTypes.COMPANY_SET_ACTIVE_ROLES,
        activeRoles
    };
};

export const companySetActiveRole = activeRole => {
    return {
        type: actionTypes.COMPANY_SET_ACTIVE_ROLE,
        activeRole
    };
};


export const companyResetCompanies = () => {
    return {
        type: actionTypes.COMPANY_RESET_COMPANIES,
        activeCompanyId: null,
        activeRoles: null,
        userCompanies: null,
        activeRole: null
    };
};

export const setActiveCompany = (id, name, roles) => {
    return dispatch => {
        localStorage.setItem("activeCompanyId", id);
        localStorage.setItem("activeCompanyName", name);
        localStorage.setItem("userCompanyRoles", roles);
        dispatch(companySetActiveCompany(id));
        dispatch(companySetActiveRoles(roles));
        // Set role only if one role assigned to the user. Otherwise user need to choose the role on login
        if (roles && roles.length === 1) {
            dispatch(companySetActiveRole(roles[0]));
            localStorage.setItem("activeRole", roles[0]);
        } else {
            localStorage.removeItem("activeRole");
            dispatch(companySetActiveRole(null));
        }
    }
}

export const fetchUserCompanies = () => {
    return dispatch => {
        dispatch(companyFetchUserCompaniesStart());
        authAxios
            .get(companyListURL)
            .then(res => {
                const data = res.data;
                const companiesData = handleFormatCompaniesList(data);
                localStorage.setItem("userCompanies", companiesData);
                dispatch(companyFetchUserCompaniesSuccess(companiesData));

                let current_active_company_id = localStorage.getItem("activeCompanyId");
                let current_active_company_name = localStorage.getItem("activeCompanyName");
                let current_active_roles = localStorage.getItem("userCompanyRoles");
                if (current_active_company_id === undefined || !handleCheckActiveCompanyAccess(current_active_company_id, companiesData)) {
                    current_active_company_id = data[0].id;
                    localStorage.setItem("activeCompanyId", current_active_company_id);
                    current_active_company_name = data[0].name;
                    localStorage.setItem("activeCompanyName", current_active_company_name);
                    current_active_roles = handleFormatRoles(data[0].roles);
                    localStorage.setItem("userCompanyRoles", current_active_roles);
                    dispatch(companySetActiveCompany(current_active_company_id));
                    
                } else {
                    localStorage.setItem("activeCompanyId", current_active_company_id);
                    localStorage.setItem("activeCompanyName", current_active_company_name);
                    localStorage.setItem("userCompanyRoles", current_active_roles);
                    dispatch(companySetActiveCompany(current_active_company_id));
                }
                // Set role only if one role assigned to the user. Otherwise user need to choose the role on login
                if (current_active_roles && current_active_roles.length === 1) {
                    dispatch(companySetActiveRole(current_active_roles[0]));
                    localStorage.setItem("activeRole", current_active_roles[0]);
                } else {
                    localStorage.removeItem("activeRole");
                    dispatch(companySetActiveRole(null));
                }
                dispatch(companySetActiveRoles(current_active_roles));
            })
            .catch(err => {
                dispatch(companyFetchUserCompaniesFail(err));
                // console.log(err);
            });
    }
}

export const setActiveRole = role => {
    return dispatch => {
        dispatch(companySetActiveRole(role));
        localStorage.setItem("activeRole", role);
    }
};

const handleCheckActiveCompanyAccess = (activeCompany, companies) => {
    let i = 0;
    let result = false;
    for (i in companies) {
        if (activeCompany == companies[i].id) {
            result = true;
        }
    }
    return result;
}

const handleFormatRoles = data => {
    const keys = Object.keys(data);
    return keys.map(k => {
        return {
            id: data[k].id,
            role: data[k].role,
            role_name: data[k].role_name,
            customer: data[k].customer,
            // default: data[k].default
        };
    });
};


const handleFormatCompaniesList = data => {
    const keys = Object.keys(data);
    return keys.map(k => {
        return {
            id: data[k].id,
            name: data[k].name,
            roles: data[k].roles
        };
    });
};

export const resetUserCompanies = () => {
    return dispatch => {
        localStorage.removeItem("userCompanies");
        localStorage.removeItem("activeCompanyId");
        localStorage.removeItem("activeCompanyName");
        localStorage.removeItem("userCompanyRoles");
        localStorage.removeItem("activeRole");
        dispatch(companyResetCompanies());
    }
}
