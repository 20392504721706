// Copyright Caproc Oy
// System name: Aqua-Net 
// System module: CustomerLandingPage
// Author: TS
//
// 9.7.2020 TS Initial version
// 11.7.2020 TS Added Company Selection banner

import React from "react";
// import { Bar, Line, Pie, Doughnut } from 'react-chartjs-2';
import {
    Container,
    Grid,
    Header,
    Segment,
    Statistic,
    Card,
    Icon,
    Responsive,
    List,
    Table
} from "semantic-ui-react";
import { connect } from "react-redux";
import { Link, withRouter, Redirect } from "react-router-dom";
import { withTranslation } from 'react-i18next';

import { customerFullDetailsURL, fetchGdprDefinitionsURL, customerDataCollectionsURL, fetchInvoicePdfURL, reportDataCollectionURL } from '../constants';
import { authAxios, getWidth, getTotalGrossPrice, getISOFormattedDate, CompanySelection } from "../utils";


class CustomerLandingPage extends React.Component {

    state = {
        dc_reports: null,
        sensors: null,
        customer: null,
        invoices_short_list: null,
        activeRole_received: false,
        loading: false,
        error: null
    };

    handleFetchCustomer = () => {
        this.setState({
            loading: true
        });
        const { activeCompanyId, activeRole } = this.props;
        authAxios
            .get(customerFullDetailsURL(activeCompanyId, activeRole.customer.id))
            .then(res => {
                this.setState({
                    customer: res.data,
                    invoices_short_list: this.handleInvoicesShortList(res.data.invoices),
                    error: null,
                    loading: false
                });
            })
            .catch(err => {
                this.setState({
                    fina_stats: null,
                    loading: false,
                    error: err
                });
            });
        
    };

    handleInvoicesShortList = data => {
        let new_list = [];
        let i = 0;
        let break_index = 3;
        for (i in data) {
            // Status Created, Approved or Deleted
            if (data[i].status === "C" || data[i].status === "A" || data[i].status === "D") {
                break_index += 1;
                continue;
            }
            if (i > break_index) {
                break;
            }
            new_list.push(data[i]);
        }
        new_list.sort(function(a, b){return b.due_date - a.due_date});
        return new_list;
    };

    handleGetDataCollectionReports = data_collection => {
        this.setState({
            loading: true
        });
        const days = 30; // Past 30 days
        const { activeCompanyId, activeRole } = this.props;
        let start_date = new Date();
        start_date.setDate(start_date.getDate() - days); 
        const end_date = new Date();
        authAxios
            .get(reportDataCollectionURL(activeCompanyId, "data_collection_daily", getISOFormattedDate(start_date, "Date"), getISOFormattedDate(end_date, "Date"), data_collection.product.id, activeRole.customer.id))
            .then(res => {
                const res_report = res.data;
                let x = 0;
                let total = 0;
                for (x in res_report) {
                    total += res_report[x].total_amount;
                }
                const report = {
                    product: data_collection.product.report_name,
                    daily_avg: total / days,
                    daily_avg_price: (total / days) * (data_collection.product.net_price * (data_collection.product.vat_value / 100 + 1)),
                    weekly_avg: total / 7,
                    weekly_avg_price: (total / 7) * (data_collection.product.net_price * (data_collection.product.vat_value / 100 + 1)),
                    total: total,
                    total_price: total * (data_collection.product.net_price * (data_collection.product.vat_value / 100 + 1)),
                    measure_unit: res_report[0].measure_unit
                };
                let updatedReports = this.state.dc_reports;
                if (updatedReports === null) {
                    updatedReports = [report];
                } else {
                    updatedReports.push(report);
                }
                
                this.setState({
                    dc_reports: updatedReports,
                    loading: false
                });        
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    error: err
                });
            });
        
    };

    handleGetReport = data_collections => {
        this.setState({
            loading: true
        });
        let i = 0;
        let reports = [];
        let res = null;
        for (i in data_collections) {            
            this.handleGetDataCollectionReports(data_collections[i]);
        }

    }

    handleFetchCustomerDataCollections = () => {
        this.setState({
            loading: true
        });
        const { activeCompanyId, activeRole } = this.props;
        authAxios
            .get(customerDataCollectionsURL(activeCompanyId, activeRole.customer.id))
            .then(res => {
                const _data_collections = this.handleFormatDataCollections(res.data);
                
                this.setState({
                    sensors: this.handleFormatSensors(res.data),
                    error: null,
                    loading: false
                });
                this.handleGetReport(_data_collections);
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    error: err
                });
            });
        
    };

    handleFormatDataCollections = data => {
        let new_list = [];
        let i = 0;
        for (i in data) {
            if (data[i].is_active) {
                new_list.push(data[i]);
            }
        }
        return new_list;
    };

    handleFormatSensors = data => {
        let new_list = [];
        let added_sensors = [];
        let i = 0;
        for (i in data) {
            if (data[i].is_active) {
                if (!added_sensors.includes(data[i].source_id)) {
                    new_list.push(data[i]);
                    added_sensors.push(data[i].source_id);
                }
            }
        }
        return new_list;
    };

    fetchGdprDefinitions = () => {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;
        authAxios
            .get(fetchGdprDefinitionsURL(activeCompanyId), { responseType: 'blob' })
            .then(res => {
                //Create a Blob from the PDF Stream
                const file = new Blob(
                    [res.data],
                    { type: 'application/pdf' });
                //Build a URL from the file
                const fileURL = URL.createObjectURL(file);
                // // //Open the URL on new Window
                window.open(fileURL);

                // const link = document.createElement('a');
                // link.href = fileURL;
                // link.setAttribute('download', 'lasku.pdf'); //or any other extension
                // document.body.appendChild(link);
                // link.click();
                this.setState({
                    error: null,
                    loading: false
                });
                this.closeModal();
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    error: err
                });
            });
    };

    handleFetchInvoicePdf = (invoice_id) => {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;
        authAxios
            .get(fetchInvoicePdfURL(activeCompanyId, invoice_id), { responseType: 'blob' })
            .then(res => {
                //Create a Blob from the PDF Stream
                const file = new Blob(
                    [res.data],
                    { type: 'application/pdf' });
                //Build a URL from the file
                const fileURL = URL.createObjectURL(file);
                // // //Open the URL on new Window
                // window.open(fileURL);

                const link = document.createElement('a');
                link.href = fileURL;
                link.setAttribute('download', 'lasku.pdf'); //or any other extension
                document.body.appendChild(link);
                link.click();

                this.setState({
                    error: null,
                    loading: false
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    error: err
                });
            });
    };


    render() {
        const { isAuthenticated, t, activeRoles, activeRole } = this.props;
        const {  activeRole_received, dc_reports, invoices_short_list, sensors } = this.state;
        const is_desktop =  getWidth() > Responsive.onlyMobile.maxWidth ? true : false;

        if (isAuthenticated && activeRole && !activeRole_received) {
            this.handleFetchCustomerDataCollections();
            this.handleFetchCustomer();
            this.setState({ activeRole_received: true });
        }

        //console.log(this.state);
        return (
            <React.Fragment>
                {!isAuthenticated && (
                    <Redirect to="/login" />
                )}
                {(isAuthenticated && activeRoles !== null && activeRole === null) && (
                    <Redirect to="/account/role-select" />
                )}
                {(isAuthenticated && activeRole !== null && activeRole.role !== "C") && (
                    <Redirect to="/" />
                )}
                
                {isAuthenticated && (
                    <React.Fragment>
                        <Container><CompanySelection/></Container>
                        <Segment style={{ padding: "5em 0em" }} vertical>
                            {/* <Header as="h2" style={{ fontSize: "2em" }} textAlign="center">{t('welcometext')}</Header> */}
                            <br />
                            <Grid container stackable verticalAlign="middle" centered>
                                {activeRole && (
                                    <>
                                    <Grid.Row columns="1">
                                        <Grid.Column width={13}>
                                            <Card.Group centered>
                                                <Card fluid>
                                                    <Card.Content textAlign="center">
                                                        <Link to={`/customer-portal/customer-details/${activeRole.customer.id}`}>
                                                            <Header>{t("Customer details")}</Header>
                                                        </Link>
                                                    </Card.Content>
                                                    <Card.Content textAlign="center" >
                                                        <b style={{ fontSize: "1.15em", color: "black" }}>{activeRole.customer.name}</b><br/>
                                                        <b style={{ fontSize: "1.15em", color: "black" }}>{activeRole.customer.address}</b>,&nbsp;
                                                        <b style={{ fontSize: "1.15em", color: "black" }}>{activeRole.customer.postal_code},&nbsp; {activeRole.customer.city}</b><br/>

                                                    </Card.Content>
                                                </Card>
                                                
                                            </Card.Group>
                                        </Grid.Column>
                                    </Grid.Row>
                                    {invoices_short_list && (
                                        <Grid.Row columns="1">
                                            <Grid.Column width={13}>
                                                <Card.Group centered >
                                                    <Card fluid >
                                                        <Card.Content textAlign="center">
                                                            <Link to={`/customer-portal/invoices/${activeRole.customer.id}/`}>
                                                                <Header>{t("Last invoices")}</Header>
                                                            </Link>
                                                        </Card.Content>
                                                        <Card.Content>
                                                            <Table basic="very" unstackable textAlign="center">
                                                                <Table.Header>
                                                                    <Table.Row>
                                                                    <Table.HeaderCell>{t("Invoice number")}</Table.HeaderCell>
                                                                    <Table.HeaderCell>{t("Due date")}</Table.HeaderCell>
                                                                    <Table.HeaderCell>{t("Total")}</Table.HeaderCell>
                                                                    <Table.HeaderCell/>
                                                                    </Table.Row>
                                                                </Table.Header>
                                                                <Table.Body>
                                                                    {invoices_short_list.map((invoice, key) => {
                                                                        return (
                                                                            <Table.Row key={key} >
                                                                                <Table.Cell>{invoice.number}</Table.Cell>
                                                                                <Table.Cell>{new Date(invoice.due_date).toLocaleString('fi-FI', { dateStyle: 'short' })}</Table.Cell>
                                                                                <Table.Cell><strong>{getTotalGrossPrice(invoice.invoiceitems).toFixed(2)} €</strong></Table.Cell>
                                                                                <Table.Cell><Link onClick={() => this.handleFetchInvoicePdf(invoice.id)} ><Icon link name="file pdf outline" size="large" /> {t("Open file")}</Link></Table.Cell>
                                                                            </Table.Row>
                                                                        );
                                                                    })}
                                                                </Table.Body>
                                                            </Table>
                                                            <div style={{float:"right"}}><Link to={`/customer-portal/invoices/${activeRole.customer.id}/`}>{t("see more..")}</Link></div>
                                                        </Card.Content>
                                                    </Card>
                                                </Card.Group>
                                            </Grid.Column>
                                        </Grid.Row>
                                    )}
                                    <Grid.Row columns="1">
                                        <Grid.Column >
                                            <Card.Group centered>
                                                {sensors && (
                                                    <>
                                                        {sensors.map((data, key) => {
                                                            return (
                                                                <Card key={key} >
                                                                    <Card.Content textAlign="center">
                                                                        <Link to={`/customer-portal/${activeRole.customer.id}/customer-data-collection/report/`}>
                                                                        <Header>{t("Sensor")}</Header>
                                                                        </Link>
                                                                    </Card.Content>
                                                                    <Card.Content>
                                                                        <List size="large">
                                                                            <List.Item >
                                                                                <List.Content >
                                                                                    {t("Sensor number")}
                                                                                    <List.Header>
                                                                                        {data.source_id}
                                                                                    </List.Header>
                                                                                </List.Content>
                                                                            </List.Item>
                                                                            <List.Item >
                                                                                <List.Content >
                                                                                    {t("Location number")}
                                                                                    <List.Header>
                                                                                        {data.location_id}
                                                                                    </List.Header>
                                                                                </List.Content>
                                                                            </List.Item>
                                                                            <List.Item >
                                                                                <List.Content >
                                                                                    {t("Last value")}
                                                                                    <List.Header>
                                                                                        {data.last_value} {data.product.measure_unit}
                                                                                    </List.Header>
                                                                                </List.Content>
                                                                            </List.Item>
                                                                            <List.Item >
                                                                                <List.Content >
                                                                                    {t("Last value date")}
                                                                                    <List.Header>
                                                                                        {data.last_value_time ? new Date(data.last_value_time).toLocaleString('fi-FI') : ("")}
                                                                                    </List.Header>
                                                                                </List.Content>
                                                                            </List.Item>
                                                                        </List>
                                                                    </Card.Content>
                                                                </Card>
                                                            );
                                                        })}
                                                    </>
                                                )}
                                                {dc_reports && (
                                                    <>
                                                        {dc_reports.map((data, key) => {
                                                            return (
                                                                <Card key={key}>
                                                                    <Card.Content textAlign="center">
                                                                        <Link to={`/customer-portal/${activeRole.customer.id}/customer-data-collection/report/`}>
                                                                            <Header>{data.product}</Header>
                                                                        </Link>
                                                                        <Card.Meta>{t("average consumption")}</Card.Meta>
                                                                        <Card.Meta>{t("past 30 days")}</Card.Meta>
                                                                    </Card.Content>
                                                                    <Card.Content textAlign="center">
                                                                        <Card.Meta>{t("day")}</Card.Meta>
                                                                        <Statistic.Group size='tiny' widths="2"> 
                                                                            <Statistic >
                                                                                <Statistic.Value>{data.daily_avg && data.daily_avg.toFixed(1)}</Statistic.Value>
                                                                                <Statistic.Label>{data.measure_unit}</Statistic.Label>
                                                                            </Statistic>
                                                                            <Statistic  >
                                                                                <Statistic.Value>{data.daily_avg_price && data.daily_avg_price.toFixed(1)}</Statistic.Value>
                                                                                <Statistic.Label>€</Statistic.Label>
                                                                            </Statistic>
                                                                        </Statistic.Group>
                                                                    </Card.Content>
                                                                    <Card.Content textAlign="center">
                                                                        <Card.Meta>{t("week")}</Card.Meta>
                                                                        <Statistic.Group size='tiny' widths="2"> 
                                                                            <Statistic >
                                                                                <Statistic.Value>{data.weekly_avg && data.weekly_avg.toFixed(1)}</Statistic.Value>
                                                                                <Statistic.Label>{data.measure_unit}</Statistic.Label>
                                                                            </Statistic>
                                                                            <Statistic  >
                                                                                <Statistic.Value>{data.weekly_avg_price && data.weekly_avg_price.toFixed(1)}</Statistic.Value>
                                                                                <Statistic.Label>€</Statistic.Label>
                                                                            </Statistic>
                                                                        </Statistic.Group>
                                                                    </Card.Content>
                                                                    <Card.Content textAlign="center">
                                                                        <Card.Meta>{t("month")}</Card.Meta>
                                                                        <Statistic.Group size='tiny' widths="2"> 
                                                                            <Statistic >
                                                                                <Statistic.Value>{data.total && data.total.toFixed(1)}</Statistic.Value>
                                                                                <Statistic.Label>{data.measure_unit}</Statistic.Label>
                                                                            </Statistic>
                                                                            <Statistic  >
                                                                            <Statistic.Value>{data.total_price && data.total_price.toFixed(1)}</Statistic.Value>
                                                                                <Statistic.Label>€</Statistic.Label>
                                                                            </Statistic>
                                                                        </Statistic.Group>
                                                                    </Card.Content>
                                                                </Card> 
                                                            );
                                                        })}
                                                    </>
                                                )}
                                            </Card.Group>
                                        </Grid.Column>
                                    </Grid.Row>
                                    </>
                                )}
                            </Grid>
                            {/* <Header as="h2" style={{ fontSize: "2em" }} textAlign="center">Veden kulutus</Header>
                            <br />
                            <Grid container stackable verticalAlign="middle">
                                <Grid.Row centered>
                                    <Grid.Column width={12}>


                                        <div>

                                            <Bar data={data} options={options} />

                                        </div>
                                    </Grid.Column>
                                </Grid.Row>

                            </Grid>
                            <Header style={{ fontSize: "2em" }} textAlign="center">2019</Header> */}
                        </Segment>
                    </React.Fragment>
                )}
                <Segment inverted vertical style={{ padding: "5em 0em" }}>
                    <Container>
                        <Grid divided inverted stackable centered>
                            <Grid.Row centered>
                                <Grid.Column width={7} textAlign="center">
                                    {is_desktop ? (
                                        <React.Fragment>
                                            © 2020 Caproc Oy | <a href='mailto:info@caproc.fi'>info(a)caproc.fi</a> | +358 (40) 556 2176 <br/>
                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment>
                                            © 2020 Caproc Oy <br/>
                                            <a href='mailto:info@caproc.fi'>info(a)caproc.fi</a> <br/> 
                                            +358 (40) 556 2176 <br/>
                                        </React.Fragment>
                                    )}
                                    {isAuthenticated && (
                                        <Link onClick={this.fetchGdprDefinitions}>{t("GDPR definitions")}</Link>
                                    )}
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Container>
                </Segment>
            </React.Fragment>
        );
    }
}
const mapStateToProps = state => {
    return {
        activeCompanyId: state.company.activeCompanyId,
        isAuthenticated: state.auth.token !== null,
        activeRoles: state.company.activeRoles ,
        activeRole: state.company.activeRole     
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        null
    )(withTranslation('customerlandingpage')(CustomerLandingPage))
);
