// Copyright Caproc Oy
// System name: Aqua-Net 
// System module: Project
// Author: TS
//
// 14.3.2020 TS Initial version
// 31.3.2020 TS Changed details section in modal to table for better responsive action
// 6.5.2020 TS Changed search input and Task date filtering to use filter_items instead of tasks. 
// 17.5.2020 TS Added status filter in to "all" status menu

import React from "react";
import { Message, Header, Button, Table, Segment, Dimmer, Loader, Image, Confirm, Grid, GridColumn, Input, Popup, Icon, Modal, Container, Card, List, ListHeader, ListDescription, Checkbox, Select } from "semantic-ui-react";
import Shell from "./Shell";
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import fiLocale from 'react-semantic-ui-datepickers/dist/locales/fi-FI';
import { authAxios, getISOFormattedDate } from "../../utils";
import { setActivity } from "../../store/actions/auth";
import { withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import { fetchProjectTasksURL, deleteProjectTaskURL, updateProjectTaskURL, projectTaskStatistics, fetchProjectTasksWithDatesURL, customerFileDetailsUpdateURL } from "../../constants";


class ProjectTasks extends React.Component {
    state = {
        tasks: null,
        error: null,
        loading: false,
        confirm_open: false,
        confirm_text: "",
        confirm_item: "",
        confirm_action: "",
        search_start_date: null,
        search_end_date: null,
        search: "",
        modal_open: false,
        active_task: null,
        project_groups: null,
        task_status: null,
        statistics: null,
        select_all: false,
        selected: [],
        search_status_options: [],
        search_status: ""
    };

    componentDidMount() {
        this.props.setActivity();
        if (this.props.match.params.status === "all") {
            let init_start_date = new Date();
            init_start_date = init_start_date.setMonth(init_start_date.getMonth() - 1); 
            this.handleStartDateChange(new Date(init_start_date));
            this.handleEndDateChange(new Date());
        }
        this.handleFetchList(this.props.match.params.status);
    }

    handleFetchList = status => {
        this.setState({
            loading: true,
            task_status: status
        });
        const { activeCompanyId } = this.props;
        const { search_start_date, search_end_date } = this.state;
        if (this.props.match.params.status === "all") {
            if (search_start_date !== null && search_end_date !== null) {
                authAxios
                    .get(fetchProjectTasksWithDatesURL(activeCompanyId, status, search_start_date, search_end_date))
                    .then(res => {
                        this.setState({
                            tasks: res.data,
                            error: null,
                            loading: false
                        });
                    })
                    .catch(err => {
                        this.setState({
                            loading: false,
                            error: err
                        });
                    });
            }
        } else {
            authAxios
                .get(fetchProjectTasksURL(activeCompanyId, status))
                .then(res => {
                    this.setState({
                        tasks: res.data,
                        error: null,
                        loading: false
                    });
                })
                .catch(err => {
                    this.setState({
                        loading: false,
                        error: err
                    });
                });
        }
        this.handleFetchProjectTaskStatistics();
    };

    handleFetchProjectTaskStatistics = () => {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;
        authAxios
            .get(projectTaskStatistics(activeCompanyId))
            .then(res => {
                this.setState({
                    statistics: res.data,
                    error: null,
                    loading: false
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    error: err
                });
            });
    };

    taskDetails = id => {
        this.props.history.push(`/project/task-details/${id}/`)
    };

    updateTask = (task, fetchTasks) => {
        this.setState({
            loading: true,
        });
        const { activeCompanyId } = this.props;
        if (task.status === "Delete") {
            authAxios
                .delete(deleteProjectTaskURL(activeCompanyId, task.id), {
                })
                .then(res => {
                    this.setState({
                        loading: false,
                        error: null
                    });
                    // Fetch updated tasks only for single updates or after the last update in batch
                    if (fetchTasks) {
                        this.handleFetchList(this.props.match.params.status);
                        // Empty selected
                        this.setState({ selected: [], select_all: false });
                    }
                })
                .catch(err => {
                    this.setState({
                        loading: false,
                        success_mgs: null,
                        error: err
                    });
                });
        } else {
            const updatedTask = {
                ...task,
                customer: task.customer.id,
                project: task.project.id,
                project_product: task.project_product.id,
                author: task.author.id
            }
            authAxios
                .put(updateProjectTaskURL(activeCompanyId, task.id), {
                    ...updatedTask
                })
                .then(res => {
                    this.setState({
                        loading: false,
                        error: null
                    });
                    // Fetch updated tasks only for single updates or after the last update in batch
                    if (fetchTasks) {
                        this.handleFetchList(this.props.match.params.status);
                        // Empty selected
                        this.setState({ selected: [], select_all: false });
                    }
                })
                .catch(err => {
                    this.setState({
                        loading: false,
                        success_mgs: null,
                        error: err
                    });
                });
        }
    }

    updateTaskStatus = (task, newStatus) => {
        // If multiple tasks 
        if (task === "All" || task === "Selected") {
            let list = null;
            if (task === "All") {
                list = this.state.tasks;
            } else {
                list = this.state.selected;
            }
            let i = 0;
            for (i in list) {
                list[i].status = newStatus;
                // Fetch updated tasks only after last update in batch
                if (i < list.length - 1) {
                    this.updateTask(list[i], false);
                } else {
                    this.updateTask(list[i], true);
                }
            }
        } else { // If only one task
            task.status = newStatus;
            this.updateTask(task, true);
        }
    }


    handleConfirm = (action, param) => {
        if (action === "Delete") {
            this.updateTaskStatus(param, action);
        } 
        this.closeConfirm();
    };

    openConfirm = (action, param, text) => {
        if (this.state.selected.length > 0) {
            this.setState({
                confirm_open: true,
                confirm_action: action,
                confirm_param: param,
                confirm_text: text
            });
        }
    };

    closeConfirm = () => {
        this.setState({
            confirm_open: false,
            confirm_action: "",
            confirm_param: "",
            confirm_text: ""
        });
    };

    handleStartDateChange = value => {
        this.setState({
            search_start_date: getISOFormattedDate(value, "Date"),
            selected: [],
            select_all: false,
            refresh: this.props.match.params.status === "all" ? true : false
        });
    };

    handleEndDateChange = value => {
        this.setState({
            search_end_date: getISOFormattedDate(value, "Date"),
            selected: [],
            select_all: false,
            refresh: this.props.match.params.status === "all" ? true : false
        });
    };

    handleChange = e => {
        if (e.target.name === "search") {
            this.setState({
                [e.target.name]: e.target.value,
                selected: [],
                select_all: false
            });
        } else {
            this.setState({
                [e.target.name]: e.target.value
            });
        }
    };

    getTaskNetPrice = task => {
        let discount_multiplier = 1;
        // e.g. 10% discount => 0.90 as multiplier
        if (task.discount > 0) {
            discount_multiplier = (100 - task.discount) / 100;
        }
        return (task.net_price * task.amount * discount_multiplier);
    };

    taskDetails = id => {
        this.props.history.push(`/project/task-details/${id}/`)
    };

    openDetailsModal = task => {
        this.setState({
            modal_open: true,
            active_task: task
        });
    };

    closeModal() {
        this.setState({
            modal_open: false,
            active_task: null
        });
    };

    getCommentPopupText = task => {
        const { t } = this.props;
        return (
            <React.Fragment>
                <b>{t("Invoice comment")}:</b><br/><text>{task.invoice_comment}</text><br/>
                <b>{t("Internal comment")}:</b><br/><text>{task.internal_comment}</text>
            </React.Fragment>
        );
    };

    handleSelectAll = filtered_items => {
        if (!this.state.select_all) {
            let new_list = [];
            let i = 0;
            for (i in filtered_items) {
                new_list.push(filtered_items[i]);
            }
            this.setState({
                select_all: true,
                selected: new_list
            })
        }
        else this.setState({ select_all: false, selected: [] });
    };

    handleCheckboxChange = (e, { value, checked }) => {
        let { selected } = this.state;
        if (checked) {
            if (!selected.includes(value)) {
                selected.push(value);
            }
        } else {
            if (selected.includes(value)) {
                selected.splice(selected.indexOf(value), 1);
            }
        }
        this.setState({ selected });
    };

    handleSearchStatusOptions = () => {
        const { t } = this.props;
        let options = [];
        options[0] = { key: "C", text: t("Created status"), value: "C"};
        options[1] = { key: "V", text: t("Verified status"), value: "V"};
        options[2] = { key: "A", text: t("Approved status"), value: "A"};
        options[3] = { key: "I", text: t("Invoiced status"), value: "I"};
        options[4] = { key: "R", text: t("Archived status"), value: "R"};
        this.setState({
            search_status_options: options
        });
    };

    handleSelectChange = (e, { name, value }) => {
        this.setState({
            [name]: value
        });
    };


    render() {
        const { error, loading, tasks, confirm_open, confirm_text, confirm_action, confirm_param, search, search_end_date, search_start_date, search_status_options, search_status, active_task, modal_open, task_status, statistics, selected} = this.state;
        const { isAuthenticated, t } = this.props;
        if (!isAuthenticated) {
            return <Redirect to="/login" />;
        }
        if (task_status !== this.props.match.params.status) {
            if (this.props.match.params.status === "all") {
                let init_start_date = new Date();
                init_start_date = init_start_date.setMonth(init_start_date.getMonth() - 1); 
                this.handleStartDateChange(new Date(init_start_date));
                this.handleEndDateChange(new Date());
                this.handleSearchStatusOptions();
            } else {
                this.setState({
                    search_start_date: null,
                    search_end_date: null
                });
                this.handleFetchList(this.props.match.params.status);
            }
        }
        if (this.state.refresh) {
            this.handleFetchList(this.props.match.params.status);
            this.setState({
                refresh: false
            });
        }

        // console.log(this.state);
        let filtered_items = [];

        // Filter data for search
        if (tasks !== null) {
            filtered_items = tasks.filter((task) => {
                return task.customer.name.toLowerCase().indexOf(search.toLocaleLowerCase()) !== -1 ||
                    task.project.name.toString().toLowerCase().indexOf(search.toLocaleLowerCase()) !== -1 ||
                    task.name.toString().toLowerCase().indexOf(search.toLocaleLowerCase()) !== -1 ||
                    task.internal_comment.toString().toLowerCase().indexOf(search.toLocaleLowerCase()) !== -1 ||
                    task.invoice_comment.toString().toLowerCase().indexOf(search.toLocaleLowerCase()) !== -1 ||
                    task.author.first_name.toString().toLowerCase().indexOf(search.toLocaleLowerCase()) !== -1 ||
                    task.author.last_name.toString().toLowerCase().indexOf(search.toLocaleLowerCase()) !== -1 ||
                    task.task_date.toString().toLowerCase().indexOf(search.toLocaleLowerCase()) !== -1 ||
                    task.status.toString().toLowerCase().indexOf(search.toLocaleLowerCase()) !== -1;
            })
            if (search_start_date !== null) {
                filtered_items = filtered_items.filter((task) => {
                    return (new Date(task.task_date) >= new Date(search_start_date))
                })
            }
            if (search_end_date !== null) {
                filtered_items = filtered_items.filter((task) => {
                    return (new Date(task.task_date) <= new Date(search_end_date).setDate(new Date(search_end_date).getDate()))
                })
            }
            if (search_status !== null) {
                if (search_status.length > 0) {
                    filtered_items = filtered_items.filter((task) => {
                        let i = 0;
                        let return_value = false;
                        for (i in search_status) {
                            if (task.status === search_status[i]) {
                                return_value = true;
                            }
                        }
                        return return_value;
                    })  
                }
            }
        }

        // let active_tasks_list = [];
        // if (task_status === "all") {
        //     active_tasks_list = filtered_items;
        // } else {
        //     active_tasks_list = tasks;
        // }
        
        return (
            <Shell statistics={statistics} loading={loading}>
                <Header as="h3">{t("Project tasks")}</Header>
                <Grid columns="2">
                    <Grid.Row>
                        <Grid.Column width={9}>
                            {task_status === "C" && (
                                <React.Fragment>
                                    <Popup content={t("Verify selected tasks")} trigger={
                                        <Button type="button" size='tiny' positive icon='thumbs up outline' content={t("Verify selected")} onClick={() => this.updateTaskStatus("Selected", "V")} />
                                    } />
                                    <Popup textAlign='center' content={t("Delete selected tasks")} trigger={
                                        <Button type="button" size='tiny' negative icon='delete' content={t("Delete selected")} onClick={() => this.openConfirm("Delete", "Selected", t("Are you sure to delete selected tasks?"))} />
                                    } />
                                </React.Fragment>
                            )}
                            {task_status === "V" && (
                                <React.Fragment>
                                    <Popup content={t("Approve selected tasks")} trigger={
                                        <Button type="button" size='tiny' positive icon='thumbs up outline' content={t("Approve selected")} onClick={() => this.updateTaskStatus("Selected", "A")} />
                                    } />
                                    <Popup content={t("Return selected to Created status")} trigger={
                                        <Button type="button" size='tiny' color='blue' icon='arrow left' content={t("Return selected")} onClick={() => this.updateTaskStatus("Selected", "C")} />
                                    } />
                                </React.Fragment>
                            )}
                            {task_status === "A" && (
                                <React.Fragment>
                                    <Popup content={t("Archive selected tasks")} trigger={
                                        <Button type="button" size='tiny' positive icon='archive' content={t("Archive selected")} onClick={() => this.updateTaskStatus("Selected", "R")} />
                                    } />
                                    <Popup content={t("Return selected to Created status")} trigger={
                                        <Button type="button" size='tiny' color='blue' icon='arrow left' content={t("Return selected")} onClick={() => this.updateTaskStatus("Selected", "C")} />
                                    } />
                                </React.Fragment>
                            )}
                            {task_status === "I" && (
                                <React.Fragment>
                                    <Popup content={t("Return selected to Created status")} trigger={
                                        <Button type="button" size='tiny' color='blue' icon='arrow left' content={t("Return selected")} onClick={() => this.updateTaskStatus("Selected", "C")} />
                                    } />
                                </React.Fragment>
                            )}
                        </Grid.Column>
                        <Grid.Column floated="right" width={6}>
                            <Input
                                size="mini"
                                icon='search'
                                value={search}
                                name="search"
                                onChange={this.handleChange} />
                            <Button type="button" positive size='tiny' floated="right" content={t("+ Project Task")} onClick={() => this.props.history.push(`/project/task-details/create/`)} />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <Grid columns="2">
                    <Grid.Row >
                        <Grid.Column width={9}>
                            <SemanticDatepicker
                                firstDayOfWeek="1"
                                size="mini"
                                label={t("Task date") + ": "}
                                disabled={loading}
                                locale={fiLocale}
                                placeholder={search_start_date !== null ? (new Date(search_start_date).toLocaleString('fi-FI', { dateStyle: 'short' })) : ("")}
                                onDateChange={selected => this.handleStartDateChange(selected)}
                                format='DD.MM.YYYY' />
                            <SemanticDatepicker
                                firstDayOfWeek="1"
                                size="mini"
                                disabled={loading}
                                label="->"
                                locale={fiLocale}
                                placeholder={search_end_date !== null ? (new Date(search_end_date).toLocaleString('fi-FI', { dateStyle: 'short' })) : ("")}
                                onDateChange={selected => this.handleEndDateChange(selected)}
                                format='DD.MM.YYYY' />
                        </Grid.Column>
                        {task_status === "all" && (
                            <Grid.Column floated="right" textAlign="right" width={7}>
                                <label>{t("Status") + ": "}</label>
                                <Select
                                    disabled={loading}
                                    search
                                    multiple
                                    name='search_status'
                                    options={search_status_options}
                                    placeholder={t("Select status")}
                                    onChange={this.handleSelectChange}
                                    value={search_status}
                                />
                            </Grid.Column>
                        )}
                    </Grid.Row>   
                </Grid> 
                {error && (
                    <Message
                        error
                        header={t("There was an error")}
                        content={JSON.stringify(error)}
                    />
                )}
                {loading && (
                    <Segment>
                        <Dimmer active inverted>
                            <Loader inverted>Loading</Loader>
                        </Dimmer>
                        <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
                    </Segment>
                )}
                {filtered_items && (
                    <Table striped celled   >
                        <Table.Header>
                            <Table.Row>
                                {this.props.match.params.status !== "all" && (
                                    <Table.HeaderCell icon='check circle outline' collapsing onClick={() => this.handleSelectAll(filtered_items)} />
                                )}
                                <Table.HeaderCell width='1'></Table.HeaderCell>
                                {/* <Table.HeaderCell>{t("Customer")}</Table.HeaderCell>
                                <Table.HeaderCell>{t("Project")}</Table.HeaderCell> */}
                                <Table.HeaderCell collapsing>{t("Name")}</Table.HeaderCell>
                                <Table.HeaderCell collapsing>{t("Author")}</Table.HeaderCell>
                                <Table.HeaderCell collapsing>{t("Task date")}</Table.HeaderCell>
                                <Table.HeaderCell collapsing>{t("Amount")}</Table.HeaderCell>
                                <Table.HeaderCell collapsing>{t("Net €")}</Table.HeaderCell>
                                {this.props.match.params.status === "all" && (
                                    <Table.HeaderCell collapsing>{t("Status")}</Table.HeaderCell>
                                )}
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {filtered_items.map((task, key) => {
                                return (
                                    <React.Fragment key={key}>
                                    <Table.Row key={key} >
                                        {this.props.match.params.status !== "all" && (
                                            <Table.Cell collapsing>
                                                <Checkbox
                                                    value={task}
                                                    checked={selected.includes(task) === true}
                                                    onChange={this.handleCheckboxChange} />
                                            </Table.Cell>
                                        )}
                                        <Table.Cell collapsing textAlign="center" onClick={() => this.openDetailsModal(task)}>
                                            <Popup content={task.task_type === "C" ? (t("Contract task")) : (t("Invoice task"))} trigger={
                                                task.task_type === "C" ? (<Icon name='handshake outline'/>) : (<Icon name='euro'  />)
                                            } />
                                        </Table.Cell>
                                        {/* <Table.Cell onClick={() => this.openDetailsModal(task)} title={task.customer.name} >{task.customer.name}</Table.Cell>
                                        <Table.Cell onClick={() => this.openDetailsModal(task)} title={task.project.name} >{task.project.name}</Table.Cell> */}
                                        <Table.Cell onClick={() => this.openDetailsModal(task)} collapsing >
                                            <List>
                                                <ListHeader>
                                                    <strong>{task.customer.name}/{task.project.name}</strong>
                                                    {(task.invoice_comment !== "" || task.internal_comment !== "") && (<Popup content={this.getCommentPopupText(task)} trigger={<Header as="h6" floated="right" icon="comments outline" />} />)}
                                                </ListHeader>
                                                <ListDescription>
                                                    {task.name}
                                                </ListDescription>
                                            </List>
                                        </Table.Cell>
                                        <Table.Cell onClick={() => this.openDetailsModal(task)} title={`${task.author.first_name} ${task.author.last_name}`} collapsing>{task.author.first_name} {task.author.last_name}</Table.Cell>
                                        <Table.Cell onClick={() => this.openDetailsModal(task)} collapsing>{new Date(task.task_date).toLocaleString('fi-FI', { dateStyle: 'short' })}</Table.Cell>
                                        <Table.Cell onClick={() => this.openDetailsModal(task)} collapsing>{task.measure_unit === "h" ? (`${task.hours < 10 ? ("0" + task.hours.toString()) : (task.hours)}:${task.minutes < 10 ? ("0" + task.minutes.toString()) : (task.minutes)}`) : (`${task.amount.toFixed(2)} ${task.measure_unit}`)} </Table.Cell>
                                        <Table.Cell onClick={() => this.openDetailsModal(task)} title={this.getTaskNetPrice(task).toFixed(2)} collapsing> {this.getTaskNetPrice(task).toFixed(2)} €</Table.Cell>
                                        {this.props.match.params.status === "all" && (
                                            <Table.Cell onClick={() => this.openDetailsModal(task)} collapsing>
                                                <Header as="h5">
                                                    {task.status === "C" && (t("Created status"))}
                                                    {task.status === "A" && (t("Approved status"))}
                                                    {task.status === "V" && (t("Verified status"))}
                                                    {task.status === "I" && (t("Invoiced status"))}
                                                    {task.status === "R" && (t("Archived status"))}
                                                </Header>
                                            </Table.Cell> 
                                        )}
                                    </Table.Row>
                                    </React.Fragment>
                                );
                            })}
                        </Table.Body>
                    </Table>
                )}
                <Confirm
                    cancelButton={t("Cancel")}
                    content={confirm_text}
                    open={confirm_open}
                    onCancel={() => this.closeConfirm()}
                    onConfirm={() => this.handleConfirm(confirm_action, confirm_param)}
                />
                {active_task && (
                    <Modal size='large' open={modal_open} dimmer="inverted" scrolling="true">
                    <Modal.Header>
                        <Header as="h3">{t("Task")}: {active_task.name}</Header>
                        <Button type="button" positive icon='edit' content={t("Edit")} loading={loading} onClick={() => this.taskDetails(active_task.id)} />
                        <Button type="button" negative floated="right" onClick={() => this.closeModal()} >{t("Close")}</Button>
                    </Modal.Header>
                    <Modal.Content>
                        <Container>
                            <Grid columns="2">
                                <Grid.Row>
                                    <Grid.Column>
                                        <Header as="h4">{t("Basic details")}</Header>
                                        <Card fluid>
                                            <Card.Content>
                                                <Card.Description>
                                                    <Grid>
                                                        <GridColumn>
                                                            <Table  basic="very" size="small">
                                                                <Table.Body>
                                                                    <Table.Row>
                                                                        <Table.Cell><Header as="h5">{t("Number")}</Header></Table.Cell>
                                                                        <Table.Cell>{active_task.id}</Table.Cell>
                                                                    </Table.Row>
                                                                    <Table.Row>
                                                                        <Table.Cell><Header as="h5">{t("Name")}</Header></Table.Cell>
                                                                        <Table.Cell>{active_task.name}</Table.Cell>
                                                                    </Table.Row>
                                                                    <Table.Row>
                                                                        <Table.Cell><Header as="h5">{t("Customer")}</Header></Table.Cell>
                                                                        <Table.Cell>{active_task.customer.name}</Table.Cell>
                                                                    </Table.Row>
                                                                    <Table.Row>
                                                                        <Table.Cell><Header as="h5">{t("Project")}</Header></Table.Cell>
                                                                        <Table.Cell>{active_task.project.name}</Table.Cell>
                                                                    </Table.Row>
                                                                    <Table.Row>
                                                                        <Table.Cell><Header as="h5">{t("Author")}</Header></Table.Cell>
                                                                        <Table.Cell>{active_task.author.first_name} {active_task.author.last_name}</Table.Cell>
                                                                    </Table.Row>
                                                                    <Table.Row>
                                                                        <Table.Cell><Header as="h5">{t("Created")}</Header></Table.Cell>
                                                                        <Table.Cell>{new Date(active_task.date_created).toLocaleString('fi-FI')}</Table.Cell>
                                                                    </Table.Row>
                                                                    <Table.Row>
                                                                        <Table.Cell><Header as="h5">{t("Last modified")}</Header></Table.Cell>
                                                                        <Table.Cell>{new Date(active_task.last_modified).toLocaleString('fi-FI')}</Table.Cell>
                                                                    </Table.Row>
                                                                    <Table.Row>
                                                                        <Table.Cell><Header as="h5">{t("Status")}</Header></Table.Cell>
                                                                        <Table.Cell>
                                                                            {active_task.status === "C" && (t("Created status"))}
                                                                            {active_task.status === "A" && (t("Approved status"))}
                                                                            {active_task.status === "V" && (t("Verified status"))}
                                                                            {active_task.status === "I" && (t("Invoiced status"))}
                                                                            {active_task.status === "R" && (t("Archived status"))}
                                                                        </Table.Cell>
                                                                    </Table.Row>
                                                                    <Table.Row>
                                                                        <Table.Cell><Header as="h5">{t("Internal comment")}</Header></Table.Cell>
                                                                        <Table.Cell>{active_task.internal_comment}</Table.Cell>
                                                                    </Table.Row>
                                                                </Table.Body>
                                                            </Table>
                                                        </GridColumn>

                                                    </Grid>
                                                    <br/>
                                                    <br/>
                                                </Card.Description>
                                            </Card.Content>
                                        </Card>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Header as="h4">{t("Invoicing details")}</Header>
                                        <Card fluid>
                                            <Card.Content>
                                                <Card.Description>
                                                    <Grid>
                                                        <GridColumn>
                                                            <Table  basic="very" size="small" >
                                                                <Table.Body>
                                                                    <Table.Row>
                                                                        <Table.Cell><Header as="h5">{t("Task date")}</Header></Table.Cell>
                                                                        <Table.Cell>{new Date(active_task.task_date).toLocaleString('fi-FI', { dateStyle: 'short' })}</Table.Cell>
                                                                    </Table.Row>
                                                                    <Table.Row>
                                                                        <Table.Cell><Header as="h5">{t("Task type")}</Header></Table.Cell>
                                                                        <Table.Cell>{active_task.task_type === "C" ? (t("Contract task")) : (t("Invoice task"))}</Table.Cell>
                                                                    </Table.Row>
                                                                    <Table.Row>
                                                                        <Table.Cell><Header as="h5">{t("Code")}</Header></Table.Cell>
                                                                        <Table.Cell>{active_task.code}</Table.Cell>
                                                                    </Table.Row>
                                                                    <Table.Row>
                                                                        <Table.Cell><Header as="h5">{t("Discount")}</Header></Table.Cell>
                                                                        <Table.Cell>{active_task.discount} %</Table.Cell>
                                                                    </Table.Row>
                                                                    <Table.Row>
                                                                        <Table.Cell><Header as="h5">{t("Vat%")}</Header></Table.Cell>
                                                                        <Table.Cell>{active_task.vat_value} %</Table.Cell>
                                                                    </Table.Row>
                                                                    <Table.Row>
                                                                        <Table.Cell><Header as="h5">{t("Net price")}</Header></Table.Cell>
                                                                        <Table.Cell>{active_task.net_price} €</Table.Cell>
                                                                    </Table.Row>
                                                                    <Table.Row>
                                                                        <Table.Cell><Header as="h5">{t("Hours")}</Header></Table.Cell>
                                                                        <Table.Cell>{active_task.hours < 10 ? ("0" + active_task.hours.toString()) : (active_task.hours)}:{active_task.minutes < 10 ? ("0" + active_task.minutes.toString()) : (active_task.minutes)}</Table.Cell>
                                                                    </Table.Row>
                                                                    <Table.Row>
                                                                        <Table.Cell><Header as="h5">{t("Amount")}</Header></Table.Cell>
                                                                        <Table.Cell>{active_task.amount} {active_task.measure_unit}</Table.Cell>
                                                                    </Table.Row>
                                                                    <Table.Row>
                                                                        <Table.Cell><Header as="h5">{t("Net total")}</Header></Table.Cell>
                                                                        <Table.Cell>{this.getTaskNetPrice(active_task).toFixed(2)} €</Table.Cell>
                                                                    </Table.Row>
                                                                    <Table.Row>
                                                                        <Table.Cell><Header as="h5">{t("Invoice comment")}</Header></Table.Cell>
                                                                        <Table.Cell>{active_task.invoice_comment}</Table.Cell>
                                                                    </Table.Row>
                                                                </Table.Body>
                                                            </Table>
                                                        </GridColumn>
                                                    </Grid>
                                                </Card.Description>
                                            </Card.Content>
                                        </Card>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                            <br />
                        </Container>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button type="button" negative onClick={() => this.closeModal()} >{t("Close")}</Button>
                    </Modal.Actions>
                </Modal >
                )}
            </Shell>
        );
    }
}
const mapStateToProps = state => {
    return {
        activeCompanyId: state.company.activeCompanyId,
        isAuthenticated: state.auth.token !== null
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setActivity: () => dispatch(setActivity())
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(withTranslation('projecttasks')(ProjectTasks))
);