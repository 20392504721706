// Copyright Caproc Oy
// System name: Aqua-Net 
// System module: Customer
// Author: TS
//
// 1.2.2020 TS Initial version
// 6.6.2020 TS Changed remove icon to trash bin
// 20.6.2020 TS Added export to CSV
// 8.7.2020 TS Bug fix. customerContactDetails was companyContactDetails


import React from "react";
import { Message, Header, Button, Table, Segment, Dimmer, Loader, Image, Confirm, Icon, Responsive } from "semantic-ui-react";
import Shell from "./Shell";
import { authAxios, exportToCsv, getWidth } from "../../utils";
import { setActivity } from "../../store/actions/auth";
import { withRouter, Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import { customerContactsURL, customerDetailsURL, customerContactRoleOptionsURL, deleteCustomerContactURL } from "../../constants";


class CustomerContacts extends React.Component {
    state = {
        customer_contacts: null,
        customer_name: null,
        customer_id: null,
        role_options: null,
        delete_confirm_open: false,
        delete_confirm_text: "",
        delete_item: "",
        error: null,
        loading: false
    };

    componentDidMount() {
        this.props.setActivity();
        this.fetchCustomerDetails();
        this.fetctRoleOptions();
        this.handleFetchCustomerContactList();
    }

    fetctRoleOptions = () => {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;
        const { customer_id } = this.props.match.params;

        authAxios
            .get(customerContactRoleOptionsURL(activeCompanyId, customer_id))
            .then(res => {
                this.setState({
                    role_options: res.data,
                    error: null,
                    success_mgs: null,
                    loading: false,
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    success_mgs: null,
                    error: err
                });
            });

    };

    fetchCustomerDetails = () => {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;
        const { customer_id } = this.props.match.params;

        authAxios
            .get(customerDetailsURL(activeCompanyId, customer_id))
            .then(res => {
                this.setState({
                    customer_name: res.data.name,
                    error: null,
                    success_mgs: null,
                    loading: false,
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    success_mgs: null,
                    error: err
                });
            });
    };

    handleFetchCustomerContactList = () => {
        this.setState({
            loading: true,
        });
        const { activeCompanyId } = this.props;
        const { customer_id } = this.props.match.params;
        authAxios
            .get(customerContactsURL(activeCompanyId, customer_id))
            .then(res => {
                this.setState({
                    customer_contacts: res.data,
                    error: null,
                    loading: false
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    error: err
                });
            });
    };

    customerContactDetails = (customer_id, id) => {
        this.props.history.push(`/customer/${customer_id}/contact-details/${id}/`)
    };

    getRoleName = (options, value) => {
        const { t } = this.props;
        var name;
        if (options) {
            var i;
            for (i in options) {
                if (options[i].value === value) name = t(options[i].name);
            }
        }
        return name;
    };

    openDeleteConfirm = (text, delete_item) => {
        this.setState({
            delete_confirm_open: true,
            delete_confirm_text: text,
            delete_item: delete_item
        });
    };

    closeDeleteConfirm = () => {
        this.setState({
            delete_confirm_open: false,
            delete_confirm_text: "",
            delete_item: ""
        });
    };

    handleDelete = id => {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;
        const { customer_id } = this.props.match.params;
        authAxios
            .delete(deleteCustomerContactURL(activeCompanyId, customer_id, id))
            .then(res => {
                this.setState({
                    error: null,
                    loading: false
                });
                this.handleFetchCustomerContactList();
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    error: err
                });
            });
        this.setState({
            delete_confirm_open: false,
            delete_confirm_text: "",
            delete_item: ""
        });
    };

    handleExportToCSV = items => {
        const { t } = this.props;
        if (items !== null) {
            const titles = [
                t("First name"),
                t("Last name"),
                t("Title"),
                t("Email"),
                t("Phone"),
                t("Role")
            ];
            let items_to_csv = [];
            let i = 0;
            for (i in items) {
                items_to_csv.push({
                    first_name: items[i].first_name,
                    last_name: items[i].last_name,
                    title: items[i].title,
                    email: items[i].email,
                    phone: items[i].phone,
                    role: t(items[i].role_display)
                });
            }
            exportToCsv(this.props.activeCompanyId, "export", titles, items_to_csv);
        }
    };

    render() {
        const { error, loading, customer_contacts, customer_name, role_options, delete_confirm_open, delete_confirm_text, delete_item } = this.state;
        const { customer_id } = this.props.match.params;
        const { isAuthenticated, t } = this.props;
        if (!isAuthenticated) {
            return <Redirect to="/login" />;
        }
        // console.log(this.state);
        const is_desktop =  getWidth() > Responsive.onlyMobile.maxWidth ? true : false;
        return (
            <Shell active_customer_id={customer_id} active_customer_name={customer_name}>
                {is_desktop && (
                    <Button.Group floated="right" basic size='small'>
                        <Button icon="download" content="CSV" onClick={() => this.handleExportToCSV(customer_contacts)} />
                    </Button.Group>
                )}
                <Header as="h3">{customer_name} {t("Contacts")}</Header>
                <Link to={`/customer/${customer_id}/contact-details/create/`}>
                    <Button type="button" floated="right" color="green">
                        {t("+ Contact")}
                    </Button>
                </Link>

                {error && (
                    <Message
                        error
                        header={t("There was an error")}
                        content={JSON.stringify(error)}
                    />
                )}
                {loading && (
                    <Segment>
                        <Dimmer active inverted>
                            <Loader inverted>Loading</Loader>
                        </Dimmer>
                        <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
                    </Segment>
                )}

                {customer_contacts && (
                    <Table striped selectable celled>
                        {is_desktop && (
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>{t("First name")}</Table.HeaderCell>
                                    <Table.HeaderCell>{t("Last name")}</Table.HeaderCell>
                                    <Table.HeaderCell>{t("Title")}</Table.HeaderCell>
                                    <Table.HeaderCell>{t("Email")}</Table.HeaderCell>
                                    <Table.HeaderCell>{t("Phone")}</Table.HeaderCell>
                                    <Table.HeaderCell>{t("Role")}</Table.HeaderCell>
                                    <Table.HeaderCell />
                                </Table.Row>
                            </Table.Header>
                        )}
                        <Table.Body>
                            {customer_contacts.map((contact) => {
                                return (
                                    <Table.Row key={contact.id} >
                                        <Table.Cell onClick={() => this.customerContactDetails(contact.customer, contact.id)}>
                                            {!is_desktop && (<React.Fragment><b>{t("First name")}</b><br/></React.Fragment>)}
                                            {contact.first_name}
                                        </Table.Cell>
                                        <Table.Cell onClick={() => this.customerContactDetails(contact.customer, contact.id)}>
                                            {!is_desktop && (<React.Fragment><b>{t("Last name")}</b><br/></React.Fragment>)}
                                            {contact.last_name}
                                        </Table.Cell>
                                        <Table.Cell onClick={() => this.customerContactDetails(contact.customer, contact.id)}>
                                            {!is_desktop && (<React.Fragment><b>{t("Title")}</b><br/></React.Fragment>)}
                                            {contact.title}
                                        </Table.Cell>
                                        <Table.Cell onClick={() => this.customerContactDetails(contact.customer, contact.id)}>
                                            {!is_desktop && (<React.Fragment><b>{t("Email")}</b><br/></React.Fragment>)}
                                            {contact.email}
                                        </Table.Cell>
                                        <Table.Cell onClick={() => this.customerContactDetails(contact.customer, contact.id)}>
                                            {!is_desktop && (<React.Fragment><b>{t("Phone")}</b><br/></React.Fragment>)}
                                            {contact.phone}
                                        </Table.Cell>
                                        <Table.Cell onClick={() => this.customerContactDetails(contact.customer, contact.id)}>
                                            {!is_desktop && (<React.Fragment><b>{t("Role")}</b><br/></React.Fragment>)}
                                            {this.getRoleName(role_options, contact.role)}
                                        </Table.Cell>
                                        <Table.Cell collapsing textAlign='center'>
                                            <Icon link color="red" name='trash alternate outline' onClick={() => this.openDeleteConfirm(t("Are you sure your want to delete", { name: (contact.first_name + " " + contact.last_name).toString() }), contact.id)}/>
                                            {/* <Button type="button" compact negative size='mini' icon='remove' onClick={() => this.openDeleteConfirm(t("Are you sure your want to delete", { name: (contact.first_name + " " + contact.last_name).toString() }), contact.id)} /> */}
                                        </Table.Cell>
                                    </Table.Row>
                                );
                            })}
                        </Table.Body>
                    </Table>
                )}
                <Confirm
                    content={delete_confirm_text}
                    open={delete_confirm_open}
                    onCancel={() => this.closeDeleteConfirm()}
                    onConfirm={() => this.handleDelete(delete_item)}
                />
            </Shell>
        );
    }
}
const mapStateToProps = state => {
    return {
        activeCompanyId: state.company.activeCompanyId,
        isAuthenticated: state.auth.token !== null
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setActivity: () => dispatch(setActivity())
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(withTranslation('customercontacts')(CustomerContacts))
);