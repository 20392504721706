// Copyright Caproc Oy
// System name: Aqua-Net 
// System module: store
// Author: TS
//
// 1.2.2020 TS Initial version
// 30.3.2020 TS Added companySetActiveRoles

import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";

const initialState = {
    error: null,
    loading: false,
    activeCompanyId: null,
    userCompanies: null,
    activeRoles: null,
    activeRole: null
};

const companyFetchUserCompaniesStart = (state, action) => {
    return updateObject(state, {
        error: null,
        userCompanies: null,
        loading: true
    });
};

const companyFetchUserCompaniesSuccess = (state, action) => {
    return updateObject(state, {
        userCompanies: action.userCompanies,
        error: null,
        loading: false
    });
};

const companyFetchUserCompaniesFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false,
        userCompanies: null
    });
};

const companySetActiveCompany = (state, action) => {
    return updateObject(state, {
        activeCompanyId: action.activeCompanyId,
        loading: false
    });
};

const companySetActiveRoles = (state, action) => {
    return updateObject(state, {
        activeRoles: action.activeRoles,
        loading: false
    });
};

const companySetActiveRole = (state, action) => {
    return updateObject(state, {
        activeRole: action.activeRole,
        loading: false
    });
};


const companyResetCompanies = (state, action) => {
    return updateObject(state, {
        activeCompanyId: null,
        userCompanies: null,
        activeRoles: null
    })
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.COMPANY_FETCHUSERCOMPANIES_START:
            return companyFetchUserCompaniesStart(state, action);
        case actionTypes.COMPANY_FETCHUSERCOMPANIES_SUCCESS:
            return companyFetchUserCompaniesSuccess(state, action);
        case actionTypes.COMPANY_FETCHUSERCOMPANIES_FAIL:
            return companyFetchUserCompaniesFail(state, action);
        case actionTypes.COMPANY_SET_ACTIVE_COMPANY:
            return companySetActiveCompany(state, action);
        case actionTypes.COMPANY_SET_ACTIVE_ROLES:
            return companySetActiveRoles(state, action);    
        case actionTypes.COMPANY_RESET_COMPANIES:
            return companyResetCompanies(state, action);
        case actionTypes.COMPANY_SET_ACTIVE_ROLE:
            return companySetActiveRole(state, action);
        default:
            return state;
    }
};

export default reducer;
