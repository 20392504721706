// Copyright Caproc Oy
// System name: Aqua-Net 
// System module: Report
// Author: TS
//
// 1.2.2020 TS Initial version
// 11.7.2020 TS Added Company Selection banner
// 25.6.2021 TS Data collection reports disabled.
// 12.6.2023 TS Added Payment transactions

import React from "react";
import { Grid, Container, Segment, Header, Menu, Icon } from "semantic-ui-react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import { CompanySelection } from "../../utils";

const Shell = props => (
    <>
    <Container><CompanySelection/></Container>
    < Segment vertical>
        <Container>
            <Grid container stackable verticalAlign="top" divided columns={2}>
                <Grid.Row>
                    <Grid.Column width={3}>
                        <Header as="h3">{props.t("Reports")}</Header>
                        <Menu vertical fluid icon="labeled" compact>
                            <Menu.Item>
                                <Header as="h4">{props.t("Invoice reports")}</Header>
                            </Menu.Item>
                            <Menu.Item
                                active={props.location.pathname === "/reports/invoices/invoices/"}
                                onClick={() => props.history.push("/reports/invoices/invoices/")}
                                name="reports-invoices-invoices"
                            >
                                <Icon name="money bill alternate"/> {props.t("Invoices")}
                            </Menu.Item>
                            <Menu.Item
                                active={props.location.pathname === "/reports/invoices/customers/"}
                                onClick={() => props.history.push("/reports/invoices/customers/")}
                                name="reports-invoices-customers"
                            >
                                <Icon name="users"/> {props.t("Customers")}
                            </Menu.Item>
                            <Menu.Item
                                active={props.location.pathname === "/reports/invoices/products/"}
                                onClick={() => props.history.push("/reports/invoices/products/")}
                                name="reports-invoices-products"
                            >
                                <Icon name="shopping cart"/> {props.t("Products")}
                            </Menu.Item>
                            <Menu.Item
                                active={props.location.pathname === "/reports/invoices/trade-receivables/"}
                                onClick={() => props.history.push("/reports/invoices/trade-receivables/")}
                                name="reports-invoices-trade-receivables"
                            >
                                <Icon name="money bill alternate"/> {props.t("Trade receivables")}
                            </Menu.Item>
                            <Menu.Item
                                active={props.location.pathname === "/reports/invoices/payment-transactions/"}
                                onClick={() => props.history.push("/reports/invoices/payment-transactions/")}
                                name="reports-invoices-payment-transactions"
                            >
                                <Icon name="money bill alternate"/> {props.t("Payment transactions")}
                            </Menu.Item>
                        </Menu>
                    </Grid.Column>
                    <Grid.Column width={13}>{props.children}</Grid.Column>
                </Grid.Row>
            </Grid>
        </Container>
    </Segment >
    </>
);

const mapDispatchToProps = dispatch => {
    return {
        // logout: () => dispatch(logout())
    };
};

export default withRouter(
    connect(
        null,
        mapDispatchToProps
    )(withTranslation('reportshell')(Shell))
);