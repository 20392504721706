// Copyright Caproc Oy
// System name: Aqua-Net 
// System module: CustomerPortal
// Author: TS
//
// 14.7.2020 TS Initial version


import React from "react";
import { Form, Input, Message, Header, Button, Select } from "semantic-ui-react";
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import fiLocale from 'react-semantic-ui-datepickers/dist/locales/fi-FI';
import Shell from "./Shell";
import { authAxios, getISOFormattedDate } from "../../utils";
import { setActivity } from "../../store/actions/auth";
import { withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import { customerDataCollectionsURL, createSensorReadingURL } from "../../constants";


class CustomerPortalDataCollectionAddItem extends React.Component {
    state = {
        dc_item: {
            company: "",
            customer: "",
            customer_data_collection_detail: "",
            name: "",
            source_id: "",
            location_id: "",
            data_value: "",
            source_type: "M",
            author: localStorage.getItem("userFullName"),
            value_date: getISOFormattedDate(new Date(), "Date"),
            received_message: ""
        },
        sensors: null,
        fields_disabled: true,
        error: null,
        success_mgs: null,
        loading: false,
        customer_name: null
    };

    componentDidMount() {
        this.props.setActivity();
        this.handleFetchCustomerDataCollections();
    }


    handleFetchCustomerDataCollections = () => {
        this.setState({
            loading: true
        });
        const { dc_item } = this.state;
        const { activeCompanyId } = this.props;
        const { customer_id } = this.props.match.params;
        authAxios
            .get(customerDataCollectionsURL(activeCompanyId, customer_id))
            .then(res => {
                const _sensors = this.handleFormatSensors(res.data);
                let updatedDetails = null;
                if (_sensors.length === 1) {
                    updatedDetails = {
                        ...dc_item,
                        // customer_data_collection_detail: res.data,
                        // name: res.data.product.name,
                        source_id: _sensors[0].value.source_id,
                        location_id: _sensors[0].value.location_id
                    };
                }
                this.setState({
                    sensors: _sensors,
                    error: null,
                    loading: false,
                    dc_item: updatedDetails !== null ? updatedDetails : dc_item,
                    fields_disabled: updatedDetails !== null ? false : true,
                });

            })
            .catch(err => {
                this.setState({
                    loading: false,
                    error: err
                });
            });
        
    };

    handleFormatSensors = data => {
        let new_list = [];
        let added_sensors = [];
        let i = 0;
        for (i in data) {
            if (data[i].is_active) {
                if (!added_sensors.includes(data[i].source_id)) {
                    new_list.push({
                        key: i,
                        text: `${this.props.t("Meter")} ${data[i].source_id} ${data[i].product.report_name}`,
                        value: data[i]
                    });
                    added_sensors.push(data[i].source_id);
                }
            }
        }
        return new_list;
    };


    handleSelectChange = (e, { name, value }) => {
        const { dc_item } = this.state;
        let updatedItemDetails = {};
        if (name === "customer_data_collection_detail") {
            updatedItemDetails = {
                ...dc_item,
                [name]: value,
                source_id: value.source_id,
                location_id: value.location_id
            };
        } else {
            updatedItemDetails = {
                ...dc_item,
                [name]: value
            };
        }
        this.setState({
            fields_disabled: false,
            dc_item: updatedItemDetails,
            success_mgs: null,
            error: null
        });
    };


    handleChange = e => {
        const { dc_item } = this.state;
        const { t } = this.props;
        //console.log(e.target.name, isNaN(e.target.value)); 
        if (e.target.name === "data_value" && isNaN(e.target.value)) {
            if (e.target.value.includes(",")) {
                //console.log("comma");
                this.setState({
                    error: t("Data value is not number. Please check that decimal separator is dot instead of comma.")
                });
            } else {
                //console.log("e");
                this.setState({
                    error: t("Data value is not number")
                });
            }
        } else {
            this.setState({
                error: null
            });
        }
        const updatedItemDetails = {
            ...dc_item,
            [e.target.name]: e.target.value,
        };
        this.setState({
            dc_item: updatedItemDetails,
            success_mgs: null
        });
    };

    handleSubmit = e => {
        e.preventDefault();
        this.setState({
            loading: true
        });
        const { dc_item } = this.state;
        const { activeCompanyId, t } = this.props;
        const { customer_id } = this.props.match.params;
        if (dc_item.data_value === "") {
            this.setState({
                error: t("Please fill in Data value"),
                success_mgs: null,
                loading: false
            });
        } else if (dc_item.author.length > 100) {
            this.setState({
                error: t("Max amount of characters is 100 for Author"),
                success_mgs: null,
                loading: false
            });
        } else if (dc_item.data_value.length > 50) {
            this.setState({
                error: t("Max amount of characters is 50 for Value"),
                success_mgs: null,
                loading: false
            });
        } else {
            let author = dc_item.author;
            if (author === "") {
                author = localStorage.getItem("userFullName");
            }
            const updated_dc_item = {
                ...dc_item,
                company: activeCompanyId,
                customer: customer_id,
                customer_data_collection_detail: dc_item.customer_data_collection_detail.id,
                author,
                added_by: localStorage.getItem("UserID")
            }
            authAxios
                .post(createSensorReadingURL(activeCompanyId), {
                    ...updated_dc_item
                })
                .then(res => {
                    this.props.history.push(`/customer-portal/${customer_id}/customer-data-collection/items/`)
                })
                .catch(err => {
                    this.setState({
                        loading: false,
                        success_mgs: null,
                        error: err
                    });
                });
        }
    };

    handleDateChange = value => {
        const { dc_item } = this.state;
        const updatedItemDetails = {
            ...dc_item,
            value_date: getISOFormattedDate(value, "Date"),
        };
        this.setState({
            dc_item: updatedItemDetails
        });
    };

    render() {
        const {
            dc_item,
            sensors,
            fields_disabled,
            error,
            success_mgs,
            loading,
            customer_name
        } = this.state;
        const { customer_id } = this.props.match.params;
        const { isAuthenticated, t, activeRole } = this.props;
        if (!isAuthenticated) {
            return <Redirect to="/login" />;
        }
        // console.log(this.state);
        return (
            <Shell active_customer_id={customer_id} active_customer_name={activeRole.customer && activeRole.customer.name}>
                <Header as="h4">{t("Add meter reading")}</Header>
                <Form error={error !== null}>
                    {(sensors && sensors.length > 1) && (
                        <Form.Field width="6">
                            <label>{t("Meters")}</label>
                            <Select
                                search
                                disabled={loading}
                                name='customer_data_collection_detail'
                                options={sensors}
                                placeholder={t("Meter")}
                                onChange={this.handleSelectChange}
                                value={dc_item.customer_data_collection_detail}
                            />
                        </Form.Field>
                    )}
                    {!fields_disabled && (
                        <React.Fragment>
                            <label>{t("Source id")}</label>
                            <Header as="h4">{dc_item.source_id}</Header>
                            <label>{t("Location id")}</label>
                            <Header as="h4">{dc_item.location_id}</Header>
                        </React.Fragment>
                    )}
                    <Form.Field>
                        <SemanticDatepicker
                            disabled={loading || fields_disabled}
                            label={t("Data read date")}
                            locale={fiLocale}
                            placeholder={new Date(dc_item.value_date).toLocaleString('fi-FI', { dateStyle: 'short' })}
                            onDateChange={selected => this.handleDateChange(selected)}
                            format='DD.MM.YYYY' />
                    </Form.Field>
                    <Form.Field width="4">
                        <label>{t("Value author")}</label>
                        <Input
                            disabled={loading || fields_disabled}
                            value={dc_item.author}
                            name="author"
                            onChange={this.handleChange} />
                    </Form.Field>
                    <Form.Field width="4">
                        <label>{t("Value")}</label>
                        <Input
                            disabled={loading || fields_disabled}
                            value={dc_item.data_value}
                            name="data_value"
                            onChange={this.handleChange} />
                    </Form.Field>

                    {error && <Message error header={t("Error")} content={error} />}
                    {success_mgs && <Message positive header={success_mgs} />}
                    <Button primary type="button" onClick={(e) => this.handleSubmit(e)} loading={loading} disabled={loading}>
                        {t("Save")}
                    </Button>
                </Form>
            </Shell>
        );
    }
}
const mapStateToProps = state => {
    return {
        activeCompanyId: state.company.activeCompanyId,
        isAuthenticated: state.auth.token !== null,
        activeRole: state.company.activeRole
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setActivity: () => dispatch(setActivity())
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(withTranslation('customerportaldatacollectionadditem')(CustomerPortalDataCollectionAddItem))
);