// Copyright Caproc Oy
// System name: Aqua-Net 
// System module: Layout
// Author: TS
//
// 1.2.2020 TS Initial version
// 30.3.2020 TS Changed use or roles. Projects module only for Admins for now
// 11.5.2020 TS Projects now available for all
// 11.7.2020 TS Added Icons to menu and moved company selection to banner

import PropTypes from "prop-types";
import React, { Component } from "react";
import {
    Button,
    Container,
    Dropdown,
    Menu,
    Responsive,
    Segment,
    Visibility,
    Flag,
    DropdownMenu,
    Image,
    Icon
} from "semantic-ui-react";
import { connect } from "react-redux";
import { logout } from "../../store/actions/auth";
import { setActiveCompany } from "../../store/actions/company";
import { withRouter } from "react-router-dom";
import { getWidth } from "../../utils";
import { withTranslation } from 'react-i18next';
import Logo from "../../assets/images/Caproc-logo-small-70.png";


class DesktopContainer extends Component {
    state = {
        company_name: null,
        fixed: false
    };

    hideFixedMenu = () => this.setState({ fixed: false });
    showFixedMenu = () => this.setState({ fixed: true });

    setActiveCompanyId = (company_id, company_name, roles) => {
        this.props.setCompany(company_id, company_name, roles);
        this.setState({ company_name });
        this.props.history.push("/")
    };

    handleLogout = () => {
        this.setState({ company_name: null });
        this.props.logout();
    };


    render() {
        const { children, isAuthenticated, userCompanies, activeCompanyId, activeRole, t, i18n } = this.props;
        const { fixed, company_name } = this.state;
        return (
            <Responsive getWidth={getWidth} minWidth={Responsive.onlyTablet.minWidth}>
                <Visibility
                    once={false}
                    onBottomPassed={this.showFixedMenu}
                    onBottomPassedReverse={this.hideFixedMenu}
                >
                    <Segment
                        inverted
                        textAlign="center"
                        style={{ padding: "1em 0em" }}
                        vertical
                    >
                        <Menu
                            fixed={fixed ? "top" : null}
                            inverted={!fixed}
                            // pointing={!fixed}
                            // secondary={!fixed}
                            size="large"
                            icon="labeled"
                            compact
                        >
                            <Container>
                                <Menu.Item
                                    active={this.props.location.pathname === "/"}
                                    onClick={() => this.props.history.push("/")}
                                    content={<Image src={Logo} size='small' verticalAlign="bottom" />}
                                    fitted="horizontally"
                                    position="left"

                                >

                                    {/* <Image src='Caproc logo.png' size='small' /> */}
                                    {/* {t('Home')} */}
                                </Menu.Item>

                                {(userCompanies && activeCompanyId) && (
                                    <React.Fragment>
                                        {activeRole && (
                                            <React.Fragment>
                                                {activeRole.role !== "C" ? (
                                                    <React.Fragment>
                                                        <Menu.Item
                                                            active={this.props.location.pathname === "/customers/"}
                                                            onClick={() => this.props.history.push("/customers/")}
                                                        >
                                                            <Icon name="group"/> {t('Customers')}
                                                        </Menu.Item>
                                                        <Menu.Item
                                                            active={this.props.location.pathname === "/contracts/"}
                                                            onClick={() => this.props.history.push("/contracts/")}
                                                        >
                                                            <Icon name="handshake"/> {t('Contracts')}
                                                        </Menu.Item>
                                                        {/* {(activeRole && activeRole.role === "A") && ( */}
                                                        <Menu.Item
                                                            active={this.props.location.pathname === "/projects/"}
                                                            onClick={() => this.props.history.push("/projects/")}
                                                        >
                                                            <Icon name="suitcase"/> {t('Projects')}
                                                        </Menu.Item>
                                                        {/* )} */}
                                                        <Menu.Item
                                                            active={this.props.location.pathname === "/invoice/invoices/C/"}
                                                            onClick={() => this.props.history.push("/invoice/invoices/C/")}
                                                        >
                                                            <Icon name="list alternate"/> {t('Invoices')}
                                                        </Menu.Item>
                                                        <Menu.Item
                                                            active={this.props.location.pathname === "/messages/"}
                                                            onClick={() => this.props.history.push("/messages/")}
                                                        >
                                                            <Icon name="envelope"/> {t('Messages')}
                                                        </Menu.Item>
                                                        <Menu.Item
                                                            active={this.props.location.pathname === "/reports/invoices/invoices/"}
                                                            onClick={() => this.props.history.push("/reports/invoices/invoices/")}
                                                        >
                                                            <Icon name="chart bar"/> {t('Reports')}
                                                        </Menu.Item>
                                                    </React.Fragment>
                                                ) : (
                                                    <React.Fragment>
                                                        <Menu.Item
                                                            active={this.props.location.pathname === `/customer-portal/customer-details/${activeRole.customer.id}/`}
                                                            onClick={() => this.props.history.push(`/customer-portal/customer-details/${activeRole.customer.id}/`)}
                                                        >
                                                            <Icon name="home"/> {t('Customer details')}
                                                        </Menu.Item>
                                                    </React.Fragment>
                                                )}
                                            </React.Fragment>
                                        )}
                                    </React.Fragment>
                                )}

                                <Menu.Menu position="right" icon="labeled">
                                    {isAuthenticated ? (
                                        <React.Fragment>
                                            {/* {(userCompanies && userCompanies.length > 0) &&
                                                <Dropdown item trigger={<Icon name="building"/>} text={company_name ? (company_name) : (localStorage.getItem("activeCompanyName"))}>
                                                    <Dropdown.Menu>
                                                        {userCompanies.map((item, key) =>
                                                            <Dropdown.Item
                                                                key={key}
                                                                text={item.name}
                                                                onClick={() =>
                                                                    this.setActiveCompanyId(item.id, item.name, item.roles)
                                                                }
                                                            />
                                                        )}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            }  */}
                                            <Dropdown item trigger={<><Icon name="user"/> {localStorage.getItem("userFullName") !== "" ? (localStorage.getItem("userFullName")) : (localStorage.getItem("userName"))}</>}>
                                                <Dropdown.Menu>
                                                    {activeRole && (
                                                        <Dropdown.Item
                                                            text={t('Account')}
                                                            onClick={() =>
                                                                this.props.history.push("/account/account-details")
                                                            }
                                                            icon="user"
                                                        />
                                                    )}
                                                    {(userCompanies && activeRole && activeRole.role !== "C") && (
                                                        <Dropdown.Item
                                                            text={t('Company')}
                                                            onClick={() =>
                                                                this.props.history.push("/company/company-details")
                                                            }
                                                            icon="building"
                                                        />
                                                    )}
                                                    <Dropdown.Item
                                                        text={t('Logout')}
                                                        onClick={() =>
                                                            this.handleLogout()
                                                        }
                                                        icon="power"
                                                    />
                                                </Dropdown.Menu>
                                            </Dropdown>
                                            <Dropdown item icon={null} trigger={<div style={{paddingTop: "1.5em"}}><Flag name={i18n.language === "en" ? "gb" : i18n.language} /></div>} >
                                                <DropdownMenu>
                                                    <Dropdown.Item flag="fi" onClick={() => i18n.changeLanguage('fi')} />
                                                    <Dropdown.Item flag="gb" onClick={() => i18n.changeLanguage('en')} />
                                                </DropdownMenu>
                                            </Dropdown>

                                        </React.Fragment>
                                    ) : (
                                            <React.Fragment>
                                                <Button
                                                    type="button"
                                                    inverted={!fixed}
                                                    onClick={() => this.props.history.push("/login")}
                                                >
                                                    {t('Login')}
                                                </Button>
                                                {/* <Button
                                                    inverted={!fixed}
                                                    primary={fixed}
                                                    style={{ marginLeft: "0.5em" }}
                                                    onClick={() => this.props.history.push("/signup")}
                                                >
                                                    {t('Signup')}
                                                </Button> */}
                                                <Dropdown item trigger={<Flag name={i18n.language === "en" ? "gb" : i18n.language} />} >
                                                    <DropdownMenu>
                                                        <Dropdown.Item flag="fi" onClick={() => i18n.changeLanguage('fi')} />
                                                        <Dropdown.Item flag="gb" onClick={() => i18n.changeLanguage('en')} />
                                                    </DropdownMenu>
                                                </Dropdown>

                                            </React.Fragment>
                                        )}
                                </Menu.Menu>
                            </Container>
                        </Menu>
                    </Segment>
                </Visibility>

                {children}
            </Responsive>
        );
    }
}

DesktopContainer.propTypes = {
    children: PropTypes.node
};

const mapStateToProps = state => {
    return {
        isAuthenticated: state.auth.token !== null,
        activeCompanyId: state.company.activeCompanyId,
        userCompanies: state.company.userCompanies,
        activeRole: state.company.activeRole
    };
};

const mapDispatchToProps = dispatch => {
    return {
        logout: () => dispatch(logout()),
        setCompany: (id, name, roles) => dispatch(setActiveCompany(id, name, roles))
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(withTranslation('desktopcontainer')(DesktopContainer))
);