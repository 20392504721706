// Copyright Caproc Oy
// System name: Aqua-Net 
// System module: Project
// Author: TS
//
// 8.3.2020 TS Initial version
// 13.2.2020 TS Updated activity change
// 16.3.2020 TS Cleaned up project task adding ideas. All project task changes are now done in ProjectTaskDetails.js
// 31.3.2020 TS Changed details section in modal to table for better responsive action
// 8.4.2020 TS Added Project invoicing
// 12.5.2020 TS Changed button types to "button"
// 5.8.2021 TS Fixed projects amount counter

import React from "react";
import { Message, Header, Button, Table, Segment, Dimmer, Loader, Image, Menu, Confirm, Modal, Grid, GridColumn, Card, Container, Icon, Popup, Accordion, List, ListHeader, ListDescription, Radio, Form, Input, Select, Checkbox } from "semantic-ui-react";
import Shell from "./Shell";
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import fiLocale from 'react-semantic-ui-datepickers/dist/locales/fi-FI';
import { authAxios, getISOFormattedDate } from "../../utils";
import { setActivity } from "../../store/actions/auth";
import { withRouter, Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import { fetchProjectGroupsURL, deleteProjectDetailsURL, updateProjectActivityURL, companyActiveUsersListURL, fetchCustomerInvoicesByStatusURL, createInvoiceURL, createInvoiceItemFromProjectTaskURL, updateProjectTaskURL } from "../../constants";
  
const empty_invoice_details = {
    id: "",
    company: "",
    customer: "",
    name: "",
    status: "",
    date: "",
    due_date: "",
    payment_term: 0,
    sales_person: "",
    number: "",
    comment: "",
    reference_number: "",
    customer_reference: "",
    company_reference: "",
    invoiceitems: "",
    invoicing_method: "",
    author: "",
    date_created: "",
    last_modified: "",
    date_sent: "",
    is_automatic_sending: false,
    automatic_sending_mode: "A",
    contract: null,
    project: null,
    purchase_order: ""
};

class Projects extends React.Component {
    state = {
        project_groups: null,
        activeMenuItem: this.props.t("Active"),
        delete_confirm_open: false,
        delete_confirm_text: "",
        delete_item: "",
        active_project: null,
        modal_open: false,
        error: null,
        loading: false,
        success_mgs: null,
        activeIndex: 0,
        activeIndex2: 0,
        invoicing_modal_open: false,
        contractTaskSelectRadioGroup: "archive",
        invoicingMethodSelectRadioGroup: "new_invoice",
        invoice_details: {
            id: "",
            company: "",
            customer: "",
            name: "",
            status: "",
            date: "",
            due_date: "",
            payment_term: 0,
            sales_person: "",
            number: "",
            comment: "",
            no_vat_comment: "",
            reference_number: "",
            customer_reference: "",
            company_reference: "",
            invoiceitems: "",
            invoicing_method: "",
            author: "",
            date_created: "",
            last_modified: "",
            date_sent: "",
            is_automatic_sending: false,
            automatic_sending_mode: "A",
            contract: null,
            project: null,
            purchase_order: "",
            contract_number_on_invoice: ""
        },
        company_users: null,
        approved_tasks: null,
        select_all_approved_tasks: false,
        selected_approved_tasks: [],
        active_project_open_invoices: null,
        selected_open_invoice: null,
        invoice_create_error: null,
        refresh_projects: false
    };

    componentDidMount() {
        this.props.setActivity();
        this.handleFetchGroupList();
        this.handleFetchCompanyUsers();
    }

    handleFetchCompanyUsers() {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;

        authAxios
            .get(companyActiveUsersListURL(activeCompanyId))
            .then(res => {
                this.setState({
                    company_users: this.handleFormatUserOptions(res.data),
                    error: null,
                    success_mgs: null,
                    loading: false,
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    success_mgs: null,
                    error: err
                });
            });
    };

    handleFormatUserOptions = options => {
        const keys = Object.keys(options);
        return keys.map(k => {
            return {
                key: options[k].id,
                text: (options[k].first_name + " " + options[k].last_name),
                value: options[k].id
            };
        });
    };

    handleFetchCustomerInvoicesByStatus = (customer_id, status) => {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;

        authAxios
            .get(fetchCustomerInvoicesByStatusURL(activeCompanyId, customer_id, status))
            .then(res => {
                this.setState({
                    active_project_open_invoices: this.handleFormatCustomerOrderOptions(res.data),
                    error: null,
                    success_mgs: null,
                    loading: false,
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    success_mgs: null,
                    error: err
                });
            });
    };

    handleFormatCustomerOrderOptions = options => {
        const keys = Object.keys(options);
        return keys.map(k => {
            return {
                key: options[k].id,
                text: (options[k].customer.name + " " + options[k].name + " (" + options[k].number + ") " + new Date(options[k].date).toLocaleString('fi-FI', { dateStyle: 'short' })),
                value: options[k]
            };
        });
    };


    handleFetchGroupList = () => {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;
        authAxios
            .get(fetchProjectGroupsURL(activeCompanyId))
            .then(res => {
                this.setState({
                    project_groups: res.data,
                    error: null,
                    loading: false
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    error: err
                });
            });
    };

    handleDeleteProject = project_id => {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;
        authAxios
            .delete(deleteProjectDetailsURL(activeCompanyId, project_id))
            .then(res => {
                this.setState({
                    error: null,
                    loading: false,
                    refresh_projects: true
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    error: err
                });
            });
        this.setState({
            delete_confirm_open: false,
            delete_confirm_text: "",
            delete_item: ""
        });
    };

    projectDetails = id => {
        this.props.history.push(`/project/project-details/${id}/`)
    };

    handleMenuItemClick = (e, { name }) => this.setState({ activeMenuItem: name });

    openDeleteConfirm = (text, delete_item) => {
        this.setState({
            delete_confirm_open: true,
            delete_confirm_text: text,
            delete_item: delete_item
        });
    };

    closeDeleteConfirm = () => {
        this.setState({
            delete_confirm_open: false,
            delete_confirm_text: "",
            delete_item: ""
        });
    };

    openProjectDetails = project => {
        let approved_tasks = project.projecttasks.filter(task => {
            return task.status === "A"
        });
        this.setState({
            modal_open: true,
            active_project: project,
            approved_tasks
        });
    };

    closeModal() {
        this.setState({
            modal_open: false,
            active_project: null,
            approved_tasks: null
        });
    };

    checkIfValid = date => {
        const currentTime = new Date();
        // If end_date is not defined, the project is permanent -> valid
        if (date !== null) {
            // Project is valid if end_date is in the future.
            // The end_date is in format YYYY-MM-DD 00:00:00, so we need to reduce one day from current time to ensure the project is valid
            // to the end of selected date. e.g. if end_date is 24.12.2019 it needs to be valid until the date turns in to 25.12.2019. 
            if (new Date(date) > currentTime.setDate(currentTime.getDate() - 1)) return true;
            else return false;
        } else return true;
    };

    taskDetails = id => {
        this.props.history.push(`/project/task-details/${id}/`)
    };

    getProjectHours = (author, tasks) => {
        let i = 0;
        let hours = 0;
        for (i in tasks) {
            // Get hours only from tasks with measure unit "h"=Hours
            if (tasks[i].measure_unit === "h") {
                if (author === "all") {
                    hours += tasks[i].amount 
                } else if (author.id === tasks[i].author.id) {
                    hours += tasks[i].amount 
                }
            }
        }
        let return_hours = Math.trunc(hours);
        let return_decimals = hours - return_hours;
        let return_minutes = 0;
        if (return_decimals > 0) {
            return_minutes = return_decimals * 60;
        }
        return `${return_hours < 10 ? ("0" + return_hours.toString()) : (return_hours)}:${return_minutes.toFixed(0) < 10 ? ("0" + return_minutes.toFixed(0).toString()) : (return_minutes.toFixed(0))}`;
    };

    getProjectInvoicedHours = (author, tasks) => {
        let i = 0;
        let hours = 0;
        for (i in tasks) {
            // Get hours only from tasks with measure unit "h"=Hours. Status "I"=Invoiced. Task type "I"=Invoice task
            if (tasks[i].measure_unit === "h" && tasks[i].status === "I" && tasks[i].task_type === "I") {
                if (author === "all") {
                    hours += tasks[i].amount 
                } else if (author.id === tasks[i].author.id) {
                    hours += tasks[i].amount 
                }
            }
        }
        let return_hours = Math.trunc(hours);
        let return_decimals = hours - return_hours;
        let return_minutes = 0;
        if (return_decimals > 0) {
            return_minutes = return_decimals * 60;
        }
        return `${return_hours < 10 ? ("0" + return_hours.toString()) : (return_hours)}:${return_minutes.toFixed(0) < 10 ? ("0" + return_minutes.toFixed(0).toString()) : (return_minutes.toFixed(0))}`;
    };

    getProjectToBeInvoicedHours = (author, tasks) => { // Open tasks. Not invoiced yet.
        let i = 0;
        let hours = 0;
        for (i in tasks) {
            // Get hours only from tasks with measure unit "h"=Hours. Status "A"=Approved. Task type "I"=Invoice task
            if (tasks[i].measure_unit === "h" && tasks[i].status === "A" && tasks[i].task_type === "I") {
                if (author === "all") {
                    hours += tasks[i].amount 
                } else if (author.id === tasks[i].author.id) {
                    hours += tasks[i].amount 
                }
            }
        }
        let return_hours = Math.trunc(hours);
        let return_decimals = hours - return_hours;
        let return_minutes = 0;
        if (return_decimals > 0) {
            return_minutes = return_decimals * 60;
        }
        return `${return_hours < 10 ? ("0" + return_hours.toString()) : (return_hours)}:${return_minutes.toFixed(0) < 10 ? ("0" + return_minutes.toFixed(0).toString()) : (return_minutes.toFixed(0))}`;
    };

    getProjectOpenTasksHours = (author, tasks) => { // Open tasks. Not approved or invoiced yet.
        let i = 0;
        let hours = 0;
        for (i in tasks) {
            // Get hours only from tasks with measure unit "h"=Hours. Status "C"=Created, "V"=Verified. Task type "I"=Invoice task
            if (tasks[i].measure_unit === "h" && (tasks[i].status === "C" || tasks[i].status === "V") && tasks[i].task_type === "I") {
                if (author === "all") {
                    hours += tasks[i].amount 
                } else if (author.id === tasks[i].author.id) {
                    hours += tasks[i].amount 
                }
            }
        }
        let return_hours = Math.trunc(hours);
        let return_decimals = hours - return_hours;
        let return_minutes = 0;
        if (return_decimals > 0) {
            return_minutes = return_decimals * 60;
        }
        return `${return_hours < 10 ? ("0" + return_hours.toString()) : (return_hours)}:${return_minutes.toFixed(0) < 10 ? ("0" + return_minutes.toFixed(0).toString()) : (return_minutes.toFixed(0))}`;
    };

    getProjectValue = (author, tasks) => {
        let i = 0;
        let net = 0;
        for (i in tasks) {
            if (author === "all") {
                net += this.getTaskNetPrice(tasks[i]); 
            } else if (author.id === tasks[i].author.id) {
                net += this.getTaskNetPrice(tasks[i]);  
            }
        }
        return net;
    };

    getProjectInvoicedValue = (author, tasks) => {
        let i = 0;
        let net = 0;
        for (i in tasks) {
            // Status "I" = Invoiced
            if (tasks[i].status === "I") {
                if (author === "all") {
                    net += this.getTaskNetPrice(tasks[i]); 
                } else if (author.id === tasks[i].author.id) {
                    net += this.getTaskNetPrice(tasks[i]);  
                }
            }
        }
        return net;
    };

    getProjectToBeInvoicedValue = (author, tasks) => { // Open tasks. Not invoiced yet.
        let i = 0;
        let net = 0;
        for (i in tasks) {
            // Status "A"=Approved
            if (tasks[i].status === "A") {
                if (author === "all") {
                    net += this.getTaskNetPrice(tasks[i]); 
                } else if (author.id === tasks[i].author.id) {
                    net += this.getTaskNetPrice(tasks[i]);  
                }
            }
        }
        return net;
    };

    getProjectOpenTasksValue = (author, tasks) => { // Open tasks. Not approved or invoiced yet.
        let i = 0;
        let net = 0;
        for (i in tasks) {
            // Status "C"=Created, "V"=Verified.
            if (tasks[i].status === "C" || tasks[i].status === "V") {
                if (author === "all") {
                    net += this.getTaskNetPrice(tasks[i]); 
                } else if (author.id === tasks[i].author.id) {
                    net += this.getTaskNetPrice(tasks[i]);  
                }
            }
        }
        return net;
    };

    getTaskNetPrice = task => {
        let discount_multiplier = 1;
        // e.g. 10% discount => 0.90 as multiplier
        if (task.discount > 0) {
            discount_multiplier = (100 - task.discount) / 100;
        }
        return (task.net_price * task.amount * discount_multiplier);
    };

    handleClick = (e, titleProps) => {
        const { index } = titleProps
        const { activeIndex } = this.state
        const newIndex = activeIndex === index ? -1 : index

        this.setState({ activeIndex: newIndex })
    };

    handleClick2 = (e, titleProps) => {
        const { index } = titleProps
        const { activeIndex2 } = this.state
        const newIndex = activeIndex2 === index ? -1 : index

        this.setState({ activeIndex2: newIndex })
    };

    changeProjectActiveStatus = project => {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;
        const updatedProject = {
            ...project,
            is_active: !project.is_active
        }
        authAxios
            .put(updateProjectActivityURL(activeCompanyId, project.id), {
                ...updatedProject
            })
            .then(res => {
                this.setState({
                    error: null,
                    loading: false,
                    refresh_projects: true
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    error: err
                });
            });
    };

    openInvoicingModal = () => {
        const { active_project, invoice_details } = this.state;
        // Initial due date is current day + payment term in project settings
        let due_date = new Date();
        due_date.setDate(due_date.getDate() + parseInt(active_project.payment_term));
        const updatedInvoice = {
            ...invoice_details,
            company: active_project.company,
            customer: active_project.customer.id,
            project: active_project.id,
            payment_term: active_project.payment_term,
            customer_reference: active_project.customer_reference,
            company_reference: active_project.company_reference,
            purchase_order: active_project.purchase_order,
            date: getISOFormattedDate(new Date(), "Date"),
            due_date: getISOFormattedDate(due_date, "Date")
        }
        this.handleFetchCustomerInvoicesByStatus(active_project.customer.id, "C");
        this.setState({
            invoicing_modal_open: true,
            invoice_details: updatedInvoice
        });
    };

    closeInvoicingModal = () => {
        
        this.setState({
            invoicing_modal_open: false,
            invoice_details: empty_invoice_details,
            active_project_open_invoices: null,
            select_all_approved_tasks: false,
            selected_approved_tasks: [],
            invoice_create_error: null
        });
    };

    handleRadioChange = (e, { name, value }) => {
        
        this.setState({
            [name]: value,
            invoice_create_error: null,
            selected_open_invoice: name === "invoicingMethodSelectRadioGroup" ? null : this.state.selected_open_invoice
        });
    };

    handleDateChange = value => {
        const { invoice_details } = this.state;
        let tmp_due_date = new Date(value);
        tmp_due_date.setDate(tmp_due_date.getDate() + parseInt(invoice_details.payment_term));
        const new_due_date = tmp_due_date.getFullYear() + "-" + (tmp_due_date.getMonth() + 1) + "-" + tmp_due_date.getDate();
        let tmp_date = new Date(value);
        const new_date = tmp_date.getFullYear() + "-" + (tmp_date.getMonth() + 1) + "-" + tmp_date.getDate();
        const updatedInvoiceDetails = {
            ...invoice_details,
            date: new_date,
            due_date: new_due_date
        };
        this.setState({
            invoice_details: updatedInvoiceDetails,
            update_due_date: false,
            invoice_create_error: null
        });
    };

    handleChange = e => {
        const { invoice_details } = this.state;
        const updatedInvoiceDetails = {
            ...invoice_details,
            [e.target.name]: e.target.value,
        };
        this.setState({
            invoice_details: updatedInvoiceDetails,
            success_mgs: null,
            invoice_create_error: null,
            update_due_date: (e.target.name === "payment_term" ? true : false)
        });
    };

    handleSelectChange = (e, { name, value }) => {
        const { invoice_details } = this.state;
        if (name === "selected_open_invoice") {
            this.setState({
                selected_open_invoice: value
            });   
        } else {
            const updatedInvoiceDetails = {
                ...invoice_details,
                [name]: value
            };    
            this.setState({
                invoice_details: updatedInvoiceDetails,
                invoice_create_error: null
            });    
        }
    };

    getCommentPopupText = task => {
        const { t } = this.props;
        return (
            <React.Fragment>
                <b>{t("Invoice comment")}:</b><br/><text>{task.invoice_comment}</text><br/>
                <b>{t("Internal comment")}:</b><br/><text>{task.internal_comment}</text>
            </React.Fragment>
        );
    };

    handleSelectAll = () => {
        if (!this.state.select_all_approved_tasks) {
            let new_list = [];
            let i = 0;
            for (i in this.state.approved_tasks) {
                new_list.push(this.state.approved_tasks[i]);
            }
            this.setState({
                select_all_approved_tasks: true,
                selected_approved_tasks: new_list
            })
        }
        else this.setState({ select_all_approved_tasks: false, selected_approved_tasks: [] });
    };

    handleCheckboxChange = (e, { value, checked }) => {
        let { selected_approved_tasks } = this.state;
        if (checked) {
            if (!selected_approved_tasks.includes(value)) {
                selected_approved_tasks.push(value);
            }
        } else {
            if (selected_approved_tasks.includes(value)) {
                selected_approved_tasks.splice(selected_approved_tasks.indexOf(value), 1);
            }
        }
        this.setState({ 
            selected_approved_tasks,
            invoice_create_error: null
        });
    };

    handleSubmit = e => {
        e.preventDefault();
        this.setState({
            loading: true
        });
        const {
            invoicingMethodSelectRadioGroup,
            contractTaskSelectRadioGroup,
            active_project_open_invoices,
            active_project,
            selected_open_invoice 
        } = this.state;
        let { invoice_details, selected_approved_tasks} = this.state;
        const { activeCompanyId, t } = this.props;

        if (!selected_approved_tasks || selected_approved_tasks.length === 0) {
            this.setState({
                invoice_create_error: t("Please select project tasks to invoice"),
                success_mgs: null,
                loading: false
            });
        } else {
            if (invoicingMethodSelectRadioGroup === "new_invoice") {
                if (invoice_details.name === "") {
                    this.setState({
                        invoice_create_error: t("Please fill in invoice name"),
                        success_mgs: null,
                        loading: false
                    });
                } else if (invoice_details.name.length > 35) {
                    this.setState({
                        invoice_create_error: t("Max amount of characters is 50 in Name field"),
                        success_mgs: null,
                        loading: false
                    });
                } else if (invoice_details.comment.length > 100) {
                    this.setState({
                        invoice_create_error: t("Max amount of characters is 200 in Comment field"),
                        success_mgs: null,
                        loading: false
                    });
                } else if (invoice_details.customer_reference.length > 50) {
                    this.setState({
                        invoice_create_error: t("Max amount of characters is 30 in Customer reference field"),
                        success_mgs: null,
                        loading: false
                    });
                } else if (invoice_details.company_reference.length > 50) {
                    this.setState({
                        invoice_create_error: t("Max amount of characters is 30 in Company reference field"),
                        success_mgs: null,
                        loading: false
                    });
                } else if (invoice_details.purchase_order.length > 70) {
                    this.setState({
                        invoice_create_error: t("Max amount of characters is 70 in Purchase order field"),
                        success_mgs: null,
                        loading: false
                    });
                } else if (invoice_details.date === "") {
                    this.setState({
                        invoice_create_error: t("Please fill in date"),
                        success_mgs: null,
                        loading: false
                    });
                } else if (invoice_details.due_date === "") {
                    this.setState({
                        invoice_create_error: t("Please fill in due date"),
                        success_mgs: null,
                        loading: false
                    });
                } else if (invoice_details.payment_term === "") {
                    this.setState({
                        invoice_create_error: t("Please fill in payment term"),
                        success_mgs: null,
                        loading: false
                    });
                } else {
                    const updated_invoice_details = {
                        ...invoice_details,
                        author: localStorage.getItem("UserID")
                    }
                    authAxios
                        .post(createInvoiceURL(activeCompanyId), {
                            ...updated_invoice_details
                        })
                        .then(res => {
                            // Create invoice items
                            let i = 0;
                            for (i in selected_approved_tasks) {
                                // Check if projec task type is Contract and if it should be arhived or added into the invoice with zero cost.
                                if (selected_approved_tasks[i].task_type === "C" && contractTaskSelectRadioGroup === "archive") {
                                    // Update status to "R"=Archived
                                    selected_approved_tasks[i]['status'] = "R";
                                    selected_approved_tasks[i]['author'] = selected_approved_tasks[i].author.id;
                                    authAxios
                                        .put(updateProjectTaskURL(activeCompanyId, selected_approved_tasks[i].id), {
                                            ...selected_approved_tasks[i]
                                        })
                                        .then(res => {
                                        })
                                        .catch(err => {
                                            this.setState({
                                                loading: false,
                                                success_mgs: null,
                                                invoice_create_error: err
                                            });
                                            return;
                                        });
                                } else {
                                    // Add invoice id from just created invoice and add to all items.
                                    selected_approved_tasks[i]['invoice'] = res.data.invoice_id;
                                    selected_approved_tasks[i]['sequence_on_invoice'] = parseInt(i) + 1;
                                    authAxios
                                        .post(createInvoiceItemFromProjectTaskURL(activeCompanyId, res.data.invoice_id), {
                                            ...selected_approved_tasks[i]
                                        })
                                        .then(res => {
                                        })
                                        .catch(err => {
                                            this.setState({
                                                loading: false,
                                                success_mgs: null,
                                                invoice_create_error: err
                                            });
                                            return;
                                        });
                                }
                            }
                            this.setState({
                                refresh_projects: true
                            });
                        })
                        .catch(err => {
                            this.setState({
                                loading: false,
                                success_mgs: null,
                                invoice_create_error: err
                            });
                        });
                }   
            } else {
                if (!active_project_open_invoices || active_project_open_invoices.length === 0) {
                    this.setState({
                        invoice_create_error: t("No open invoices available for customer") + ": " + active_project.customer.name,
                        success_mgs: null,
                        loading: false
                    });
                } else if (!selected_open_invoice) {
                    this.setState({
                        invoice_create_error: t("Please select open invoice"),
                        success_mgs: null,
                        loading: false
                    });
                } else {

                    // Create invoice items
                    let i = 0;
                    for (i in selected_approved_tasks) {
                        // Check if projec task type is Contract and if it should be arhived or added into the invoice with zero cost.
                        if (selected_approved_tasks[i].task_type === "C" && contractTaskSelectRadioGroup === "archive") {
                            // Update status to "R"=Archived
                            selected_approved_tasks[i]['status'] = "R";
                            selected_approved_tasks[i]['author'] = selected_approved_tasks[i].author.id;
                            authAxios
                                .put(updateProjectTaskURL(activeCompanyId, selected_approved_tasks[i].id), {
                                    ...selected_approved_tasks[i]
                                })
                                .then(res => {
                                })
                                .catch(err => {
                                    this.setState({
                                        loading: false,
                                        success_mgs: null,
                                        invoice_create_error: err
                                    });
                                    return;
                                });
                        } else {
                            // Add invoice id from just created invoice and add to all items.
                            selected_approved_tasks[i]['invoice'] = selected_open_invoice.id;
                            selected_approved_tasks[i]['sequence_on_invoice'] = parseInt(i) + 1;
                            authAxios
                                .post(createInvoiceItemFromProjectTaskURL(activeCompanyId, selected_open_invoice.id), {
                                    ...selected_approved_tasks[i]
                                })
                                .then(res => {
                                })
                                .catch(err => {
                                    this.setState({
                                        loading: false,
                                        success_mgs: null,
                                        invoice_create_error: err
                                    });
                                    return;
                                });
                        }
                    }
                    this.setState({
                        refresh_projects: true
                    });
                }
            }
        }
    };

    handleAmountOfProjects = status => {
        const { project_groups } = this.state;
        let amount = 0;
        let i = 0;
        for (i in project_groups) {
            let x = 0;
            for (x in project_groups[i].projects) {
                if (project_groups[i].projects[x].is_active && status === "active") {
                    amount += 1;
                } else if (!project_groups[i].projects[x].is_active && status === "archive") {
                    amount += 1;
                }    
            }
        }
        return amount;
    };

    render() {
        const { error, invoice_create_error, loading, project_groups, active_project, activeMenuItem, delete_confirm_open, delete_confirm_text, delete_item, modal_open, activeIndex, activeIndex2, invoicing_modal_open, invoice_details, company_users, approved_tasks, selected_approved_tasks, active_project_open_invoices, selected_open_invoice } = this.state;
        const { isAuthenticated, t } = this.props;
        if (!isAuthenticated) {
            return <Redirect to="/login" />;
        }
        if (this.state.update_due_date) {
            this.handleDateChange(invoice_details.date);
        }
        if (this.state.refresh_projects) {
            this.setState({
                refresh_projects: false,
                project_groups: null
            });
            this.handleFetchGroupList();
            this.closeInvoicingModal();
            this.closeModal();
        }
        
        //console.log(this.state);
        return (
            <Shell>
                <Header as="h3">{t("Projects")}</Header>
                <Link to="/project/project-details/create/">
                    <Button type="button" floated="right" color="green">
                        {t("+ Project")}
                    </Button>
                </Link>

                {error && (
                    <Message
                        error
                        header={t("There was an error")}
                        content={JSON.stringify(error)}
                    />
                )}
                {loading && (
                    <Segment>
                        <Dimmer active inverted>
                            <Loader inverted>Loading</Loader>
                        </Dimmer>
                        <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
                    </Segment>
                )}

                {project_groups && (
                    <React.Fragment>
                        <Menu attached="top" tabular>
                            <Menu.Item
                                name={`${t("Active")} ${this.handleAmountOfProjects("active")}`}
                                active={activeMenuItem.startsWith(t("Active"))}
                                onClick={this.handleMenuItemClick} />
                            <Menu.Item
                                name={`${t("Archive")} ${this.handleAmountOfProjects("archive")}`}
                                active={activeMenuItem.startsWith(t("Archive"))}
                                onClick={this.handleMenuItemClick} />
                        </Menu>

                        {project_groups.map((project_group) => {
                            return (
                                <React.Fragment key={project_group.id}>
                                    <div>

                                        {project_group.projects.length > 0 && (
                                            <React.Fragment>
                                                <br></br>
                                                <Link to={`/project/project-group-details/${project_group.id}/`}>
                                                    <Header as="h4" color="blue">
                                                        {project_group.name}
                                                    </Header>
                                                </Link>


                                                <Table striped selectable celled>
                                                    <Table.Header>
                                                        <Table.Row>
                                                            <Table.HeaderCell collapsing></Table.HeaderCell>
                                                            <Table.HeaderCell >{t("Name")}</Table.HeaderCell>
                                                            <Table.HeaderCell collapsing>{t("Started")}</Table.HeaderCell>
                                                            <Table.HeaderCell collapsing>{t("Ending")}</Table.HeaderCell>
                                                            <Table.HeaderCell collapsing>{t("Open")}</Table.HeaderCell>
                                                            <Table.HeaderCell collapsing>{t("Approved")}</Table.HeaderCell>
                                                            <Table.HeaderCell collapsing>{t("Invoiced")}</Table.HeaderCell>
                                                            <Table.HeaderCell collapsing>{t("Total")}</Table.HeaderCell>
                                                            <Table.HeaderCell collapsing>{t("Budget")}</Table.HeaderCell>
                                                            {/* {activeMenuItem === t("Archive") && (
                                                                <Table.HeaderCell />
                                                            )} */}
                                                        </Table.Row>
                                                    </Table.Header>

                                                    <Table.Body>
                                                        {project_group.projects.map((project) => {
                                                            if ((activeMenuItem.startsWith(t("Active")) && project.is_active) || (activeMenuItem.startsWith(t("Archive")) && !project.is_active)) {
                                                                return (
                                                                    <Table.Row key={project.id}>
                                                                        <Table.Cell collapsing onClick={() => this.openProjectDetails(project)}>
                                                                            <Popup content={project.project_type === "C" ? (t("Customer project")) : (t("Internal project"))} trigger={
                                                                                project.project_type === "C" ? (<Icon name='euro'/>) : (<Icon name='chart line' />)
                                                                            } />
                                                                        </Table.Cell>
                                                                        <Table.Cell  onClick={() => this.openProjectDetails(project)}>
                                                                            <List>
                                                                                <ListHeader>
                                                                                    <strong>{project.customer.name}</strong>
                                                                                    <Header sub floated="right">({project.number})</Header>
                                                                                </ListHeader>
                                                                                <ListDescription>
                                                                                    {project.name}
                                                                                </ListDescription>
                                                                            </List>
                                                                        </Table.Cell>
                                                                        <Table.Cell collapsing onClick={() => this.openProjectDetails(project)}>{new Date(project.start_date).toLocaleString('fi-FI', { dateStyle: 'short' })}</Table.Cell>
                                                                        <Table.Cell collapsing onClick={() => this.openProjectDetails(project)}>{project.end_date === null ? "" : new Date(project.end_date).toLocaleString('fi-FI', { dateStyle: 'short' })}</Table.Cell>
                                                                        <Table.Cell collapsing onClick={() => this.openProjectDetails(project)}>{this.getProjectOpenTasksHours("all", project.projecttasks)}/{this.getProjectOpenTasksValue("all", project.projecttasks).toFixed(2)} €</Table.Cell>
                                                                        <Table.Cell collapsing onClick={() => this.openProjectDetails(project)}>{this.getProjectToBeInvoicedHours("all", project.projecttasks)}/{this.getProjectToBeInvoicedValue("all", project.projecttasks).toFixed(2)} €</Table.Cell>
                                                                        <Table.Cell collapsing onClick={() => this.openProjectDetails(project)}>{this.getProjectInvoicedHours("all", project.projecttasks)}/{this.getProjectInvoicedValue("all", project.projecttasks).toFixed(2)} €</Table.Cell>
                                                                        <Table.Cell collapsing onClick={() => this.openProjectDetails(project)}>{this.getProjectHours("all", project.projecttasks)}/{this.getProjectValue("all", project.projecttasks).toFixed(2)} €</Table.Cell>
                                                                        <Table.Cell collapsing onClick={() => this.openProjectDetails(project)}>{project.budget} €</Table.Cell>
                                                                        {/* {activeMenuItem === t("Archive") && (
                                                                            <Table.Cell collapsing textAlign='center'><Button type="button" compact negative size='mini' icon='remove'
                                                                                onClick={() => this.openDeleteConfirm(t("Are you sure your want to delete", { project_name: project.name }), project.id)} /></Table.Cell>
                                                                        )} */}
                                                                    </Table.Row>
                                                                );
                                                            }
                                                        })}
                                                    </Table.Body>
                                                </Table>
                                            </React.Fragment>
                                        )}

                                    </div>
                                </React.Fragment>
                            );
                        })}
                        <Confirm
                            content={delete_confirm_text}
                            open={delete_confirm_open}
                            onCancel={() => this.closeDeleteConfirm()}
                            onConfirm={() => this.handleDeleteProject(delete_item)}
                        />
                    </React.Fragment>
                )}
                {active_project && (
                    <React.Fragment>
                    < Modal size='large' open={modal_open} dimmer="inverted" scrolling="true">
                        <Modal.Header>
                            <Header as="h3">{t("Project")}: {active_project.name}</Header>
                            <Button type="button" positive icon='edit' content={t("Edit")} loading={loading} onClick={() => this.projectDetails(active_project.id)} />
                            {active_project.is_active ? (
                                <Popup content={t("Deactive project and move in to archive.")} trigger={
                                    <Button type="button" negative icon='pause circle outline' content={t("Deactivate")} onClick={() => this.changeProjectActiveStatus(active_project)} />
                                } />
                            ) : (
                                <React.Fragment>
                                    <Popup content={t("Activate project.")} trigger={
                                        <Button type="button" positive icon='play circle outline' content={t("Activate")} onClick={() => this.changeProjectActiveStatus(active_project)} />
                                    } />
                                    <Popup content={t("Deletes project and project tasks related to the project.")} trigger={
                                        <Button type="button" negative icon='remove circle' content={t("Delete")} onClick={() => this.openDeleteConfirm(t("Are you sure you want to delete", { project_name: active_project.name }), active_project.id)} />
                                    } />
                                </React.Fragment>
                            )}
                            <Button type="button" negative floated="right" onClick={() => this.closeModal()} >{t("Close")}</Button>
                        </Modal.Header>
                        <Modal.Content>
                            <Container>
                                <Grid columns="2">
                                    <Grid.Row>
                                        <Grid.Column >
                                            <Header as="h4">{t("Basic details")}</Header>
                                            <Card fluid>
                                                <Card.Content>
                                                    <Card.Description>
                                                        <Grid>
                                                            <GridColumn>
                                                                <Table  basic="very" size="small">
                                                                    <Table.Body>
                                                                        <Table.Row>
                                                                            <Table.Cell><Header as="h5">{t("Number")}</Header></Table.Cell>
                                                                            <Table.Cell>{active_project.number}</Table.Cell>
                                                                        </Table.Row>
                                                                        <Table.Row>
                                                                            <Table.Cell><Header as="h5">{t("Name")}</Header></Table.Cell>
                                                                            <Table.Cell>{active_project.name}</Table.Cell>
                                                                        </Table.Row>
                                                                        <Table.Row>
                                                                            <Table.Cell><Header as="h5">{t("Customer")}</Header></Table.Cell>
                                                                            <Table.Cell>{active_project.customer.name}</Table.Cell>
                                                                        </Table.Row>
                                                                        <Table.Row>
                                                                            <Table.Cell><Header as="h5">{t("Comment")}</Header></Table.Cell>
                                                                            <Table.Cell>{active_project.comment}</Table.Cell>
                                                                        </Table.Row>
                                                                        <Table.Row>
                                                                            <Table.Cell><Header as="h5">{t("Started")}</Header></Table.Cell>
                                                                            <Table.Cell>{new Date(active_project.start_date).toLocaleString('fi-FI', { dateStyle: 'short' })}</Table.Cell>
                                                                        </Table.Row>
                                                                        <Table.Row>
                                                                            <Table.Cell><Header as="h5">{t("Ending")}</Header></Table.Cell>
                                                                            <Table.Cell>{active_project.end_date === null ? ("") : (new Date(active_project.end_date).toLocaleString('fi-FI', { dateStyle: 'short' }))}</Table.Cell>
                                                                        </Table.Row>
                                                                        <Table.Row>
                                                                            <Table.Cell><Header as="h5">{t("Created")}</Header></Table.Cell>
                                                                            <Table.Cell>{new Date(active_project.date_created).toLocaleString('fi-FI')}</Table.Cell>
                                                                        </Table.Row>
                                                                        <Table.Row>
                                                                            <Table.Cell><Header as="h5">{t("Last modified")}</Header></Table.Cell>
                                                                            <Table.Cell>{new Date(active_project.last_modified).toLocaleString('fi-FI')}</Table.Cell>
                                                                        </Table.Row>
                                                                        <Table.Row>
                                                                            <Table.Cell><Header as="h5">{t("Is active")}</Header></Table.Cell>
                                                                            <Table.Cell>{active_project.is_active ? (t("Yes")) : (t("No"))}</Table.Cell>
                                                                        </Table.Row>
                                                                    </Table.Body>
                                                                </Table>
                                                            </GridColumn>
                                                        </Grid>
                                                    </Card.Description>
                                                </Card.Content>
                                                <br/>
                                                <br/>
                                                <br/>
                                                <br/>
                                            </Card>
                                        </Grid.Column>
                                        <Grid.Column >
                                            <Header as="h4">{t("Other details")}</Header>
                                            <Card fluid>
                                                <Card.Content>
                                                    <Card.Description>
                                                        <Grid>
                                                            <GridColumn>
                                                                <Table  basic="very" size="small">
                                                                    <Table.Body>
                                                                        <Table.Row>
                                                                            <Table.Cell><Header as="h5">{t("Project type")}</Header></Table.Cell>
                                                                            <Table.Cell>{active_project.project_type === "C" ? (t("Customer project")) : (t("Internal project"))}</Table.Cell>
                                                                        </Table.Row>
                                                                        <Table.Row>
                                                                            <Table.Cell><Header as="h5">{t("Rensponsible person")}</Header></Table.Cell>
                                                                            <Table.Cell>{active_project.responsible_person.first_name} {active_project.responsible_person.last_name}</Table.Cell>
                                                                        </Table.Row>
                                                                        <Table.Row>
                                                                            <Table.Cell><Header as="h5">{t("Payment term")}</Header></Table.Cell>
                                                                            <Table.Cell>{active_project.payment_term}</Table.Cell>
                                                                        </Table.Row>
                                                                        <Table.Row>
                                                                            <Table.Cell><Header as="h5">{t("Company reference")}</Header></Table.Cell>
                                                                            <Table.Cell>{active_project.company_reference}</Table.Cell>
                                                                        </Table.Row>
                                                                        <Table.Row>
                                                                            <Table.Cell><Header as="h5">{t("Customer reference")}</Header></Table.Cell>
                                                                            <Table.Cell>{active_project.customer_reference}</Table.Cell>
                                                                        </Table.Row>
                                                                        <Table.Row>
                                                                            <Table.Cell><Header as="h5">{t("Purchase order")}</Header></Table.Cell>
                                                                            <Table.Cell>{active_project.purchase_order}</Table.Cell>
                                                                        </Table.Row>
                                                                        <Table.Row>
                                                                            <Table.Cell><Header as="h5">{t("Project budget")}</Header></Table.Cell>
                                                                            <Table.Cell>{active_project.budget.toFixed(2)} €</Table.Cell>
                                                                        </Table.Row>
                                                                        <Table.Row>
                                                                            <Table.Cell>
                                                                                <Popup content={t("All project hours including Contract and Invoice hours")} trigger={
                                                                                    <Header as="h5">{t("Project hours")}</Header>
                                                                                } />
                                                                            </Table.Cell>
                                                                            <Table.Cell>{this.getProjectHours("all", active_project.projecttasks)}</Table.Cell>
                                                                        </Table.Row>
                                                                        <Table.Row>
                                                                            <Table.Cell>
                                                                                <Popup content={t("Created and Verified hours/value")} trigger={
                                                                                    <Header as="h5">{t("Open hours/Value")}</Header>
                                                                                } />
                                                                            </Table.Cell>
                                                                            <Table.Cell>{this.getProjectOpenTasksHours("all", active_project.projecttasks)}/{this.getProjectOpenTasksValue("all", active_project.projecttasks)} €</Table.Cell>
                                                                        </Table.Row>
                                                                        <Table.Row>
                                                                            <Table.Cell>
                                                                                <Popup content={t("Approved hours/value")} trigger={
                                                                                    <Header as="h5">{t("To be invoiced hours/Value")}</Header>
                                                                                } />
                                                                            </Table.Cell>
                                                                            <Table.Cell>{this.getProjectToBeInvoicedHours("all", active_project.projecttasks)}/{this.getProjectToBeInvoicedValue("all", active_project.projecttasks).toFixed(2)} €</Table.Cell>
                                                                        </Table.Row>
                                                                        <Table.Row>
                                                                            <Table.Cell><Header as="h5">{t("Invoiced hours/value")}</Header></Table.Cell>
                                                                            <Table.Cell>{this.getProjectInvoicedHours("all", active_project.projecttasks)}/{this.getProjectInvoicedValue("all", active_project.projecttasks).toFixed(2)} €</Table.Cell>
                                                                        </Table.Row>
                                                                    </Table.Body>
                                                                </Table>
                                                            </GridColumn>
                                                        </Grid>
                                                    </Card.Description>
                                                </Card.Content>
                                            </Card>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Container>
                            <br/>
                            <Container>
                            <Accordion>
                                <Accordion.Title
                                    active={activeIndex === 1}
                                    index={1}
                                    onClick={this.handleClick}>
                                        <Icon name='dropdown' />
                                        <b>{t("Project members")}</b>
                                </Accordion.Title>
                                <Accordion.Content active={activeIndex === 1}>
                                    <Table striped selectable celled>
                                        <Table.Header>
                                            <Table.Row>
                                                <Table.HeaderCell>{t("Name")}</Table.HeaderCell>
                                                <Table.HeaderCell>{t("Role")}</Table.HeaderCell>
                                                <Table.HeaderCell>{t("Hours")}</Table.HeaderCell>
                                                <Table.HeaderCell>{t("Invoicing")}</Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>

                                        {active_project.projectmembers.length > 0 && (
                                            <Table.Body>
                                                {active_project.projectmembers.map(member => {
                                                    return (
                                                        <Table.Row key={member.id} >
                                                            <Table.Cell collapsing>{member.user.first_name} {member.user.last_name}</Table.Cell>
                                                            <Table.Cell collapsing>{member.role}</Table.Cell>
                                                            <Table.Cell collapsing >{this.getProjectHours(member, active_project.projecttasks)}</Table.Cell>
                                                            <Table.Cell collapsing >{this.getProjectValue(member, active_project.projecttasks).toFixed(2)} €</Table.Cell>
                                                        </Table.Row>
                                                    );
                                                })}
                                            </Table.Body>
                                        )}
                                    </Table>
                                </Accordion.Content>
                            </Accordion>
                            </Container>
                            <br/>
                            <Container>
                            <Accordion>
                                <Accordion.Title
                                    active={activeIndex2 === 1}
                                    index={1}
                                    onClick={this.handleClick2}>
                                        <Icon name='dropdown' />
                                        <b>{t("Project products")}</b>
                                </Accordion.Title>
                                <Accordion.Content active={activeIndex2 === 1}>
                                    <Table striped selectable celled>
                                        <Table.Header>
                                            <Table.Row>
                                                <Table.HeaderCell>{t("Name")}</Table.HeaderCell>
                                                <Table.HeaderCell>{t("Code")}</Table.HeaderCell>
                                                <Table.HeaderCell>{t("Net Price")}</Table.HeaderCell>
                                                <Table.HeaderCell>{t("Vat")}</Table.HeaderCell>
                                                <Table.HeaderCell>{t("Discount")}</Table.HeaderCell>
                                                <Table.HeaderCell>{t("Min invoice amount (min)")}</Table.HeaderCell>
                                                <Table.HeaderCell>{t("Invoice amount period (min)")}</Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>

                                        {active_project.projectproducts.length > 0 && (
                                            <Table.Body>
                                                {active_project.projectproducts.map(product => {
                                                    return (
                                                        <Table.Row key={product.id} >
                                                            <Table.Cell collapsing>{product.name}</Table.Cell>
                                                            <Table.Cell collapsing>{product.code}</Table.Cell>
                                                            <Table.Cell collapsing>{product.net_price} €</Table.Cell>
                                                            <Table.Cell collapsing >{product.vat_value} %</Table.Cell>
                                                            <Table.Cell collapsing >{product.discount} %</Table.Cell>
                                                            <Table.Cell collapsing >{product.minimum_invoice_amount}</Table.Cell>
                                                            <Table.Cell collapsing >{product.invoice_amount_period}</Table.Cell>
                                                        </Table.Row>
                                                    );
                                                })}
                                            </Table.Body>
                                        )}
                                    </Table>
                                </Accordion.Content>
                            </Accordion>    
                            </Container>
                            
                            {active_project.projecttasks.length > 0 && (
                                <React.Fragment>
                                    <br />
                                    <Container>
                                        {(approved_tasks && approved_tasks.length > 0 && active_project.project_type === "C") ? (
                                            <Button type="button" positive floated="right" content={t("Invoice approved tasks")} onClick={() => this.openInvoicingModal()} />
                                        ) : (
                                            <Popup content={t("Project does not have tasks to be invoices")} trigger={
                                                <Button disabled type="button" positive floated="right" content={t("Invoice approved tasks")} onClick={() => this.openInvoicingModal()} />
                                            } />
                                        )}
                                        <b>{t("Project tasks")}</b><br/>
                                        <br/>
                                        <Table striped selectable sortable celled fixed singleLine>
                                            <Table.Header>
                                                <Table.Row>
                                                    <Table.HeaderCell width='1'></Table.HeaderCell>
                                                    <Table.HeaderCell>{t("Name")}</Table.HeaderCell>
                                                    <Table.HeaderCell>{t("Author")}</Table.HeaderCell>
                                                    <Table.HeaderCell>{t("Task date")}</Table.HeaderCell>
                                                    <Table.HeaderCell width="2">{t("Invoice comment")}</Table.HeaderCell>
                                                    <Table.HeaderCell width="2">{t("Internal comment")}</Table.HeaderCell>
                                                    <Table.HeaderCell>{t("Hours")}</Table.HeaderCell>
                                                    <Table.HeaderCell>{t("Invoice amount")}</Table.HeaderCell>
                                                    <Table.HeaderCell>{t("Net")}</Table.HeaderCell>
                                                    <Table.HeaderCell>{t("Status")}</Table.HeaderCell>
                                                </Table.Row>
                                            </Table.Header>

                                            <Table.Body>
                                                {active_project.projecttasks.map(task => {
                                                    return (
                                                        <Table.Row key={task.id} onClick={() => this.taskDetails(task.id)}>
                                                            <Table.Cell collapsing textAlign="center">
                                                                <Popup content={task.task_type === "C" ? (t("Contract task")) : (t("Invoice task"))} trigger={
                                                                    task.task_type === "C" ? (<Icon name='handshake outline'/>) : (<Icon name='euro'  />)
                                                                } />
                                                            </Table.Cell>
                                                            <Table.Cell title={task.name} >{task.name}</Table.Cell>
                                                            <Table.Cell title={`${task.author.first_name} ${task.author.last_name}`} >{task.author.first_name} {task.author.last_name}</Table.Cell>
                                                            <Table.Cell >{new Date(task.task_date).toLocaleString('fi-FI', { dateStyle: 'short' })}</Table.Cell>
                                                            <Table.Cell title={task.invoice_comment}>{task.invoice_comment}</Table.Cell>
                                                            <Table.Cell title={task.internal_comment}>{task.internal_comment}</Table.Cell>
                                                            <Table.Cell  >{task.hours < 10 ? ("0" + task.hours.toString()) : (task.hours)}:{task.minutes < 10 ? ("0" + task.minutes.toString()) : (task.minutes)}</Table.Cell>
                                                            <Table.Cell  >{task.amount.toFixed(2)} {task.measure_unit}</Table.Cell>
                                                            <Table.Cell  title={this.getTaskNetPrice(task).toFixed(2)}>{this.getTaskNetPrice(task).toFixed(2)} €</Table.Cell>
                                                            <Table.Cell >
                                                                <Header as="h5">
                                                                    {task.status === "C" && (t("Created status"))}
                                                                    {task.status === "A" && (t("Approved status"))}
                                                                    {task.status === "V" && (t("Verified status"))}
                                                                    {task.status === "I" && (t("Invoiced status"))}
                                                                    {task.status === "R" && (t("Archived status"))}
                                                                </Header>
                                                            </Table.Cell>
                                                        </Table.Row>
                                                    );
                                                })}
                                                {/* <Table.Row>
                                                    <Table.Cell />
                                                    <Table.Cell />
                                                    <Table.Cell />
                                                    <Table.Cell collapsing>
                                                        <Header as="h5">{this.getTotalNetPriceAllInvoices(active_contract.invoices).toFixed(2)} €</Header>
                                                    </Table.Cell>
                                                    <Table.Cell collapsing>
                                                        <Header as="h5">{this.getTotalGrossPriceAllInvoices(active_contract.invoices).toFixed(2)} €</Header>
                                                    </Table.Cell>
                                                    <Table.Cell />
                                                </Table.Row> */}
                                            </Table.Body>
                                        </Table>
                                    </Container>
                                </React.Fragment>
                            )}
                        </Modal.Content>
                        <Modal.Actions>
                            <Button type="button" negative onClick={() => this.closeModal()} >{t("Close")}</Button>
                        </Modal.Actions>
                    </Modal >
                    <Modal  open={invoicing_modal_open} dimmer="inverted" scrolling="true">
                        <Modal.Header>
                            <strong>{t("Project invoicing")}: {active_project.name}</strong>
                            <Button type="button" negative floated="right" onClick={() => this.closeInvoicingModal()} >{t("Close")}</Button>
                        </Modal.Header>
                        <Modal.Content>
                            <br/>
                            <Grid>
                                <Header as="h5">1. {t("Contract tasks")}</Header>
                                <Grid.Row >
                                    <Grid.Column width={7} >
                                        <Radio
                                            label={t("Archive tasks")}
                                            name="contractTaskSelectRadioGroup"
                                            value="archive"
                                            checked={this.state.contractTaskSelectRadioGroup === "archive"}
                                            onChange={this.handleRadioChange} />
                                    </Grid.Column>
                                    <Grid.Column width={7}>
                                            <Radio
                                                label={t("Add to invoice with zero cost")}
                                                name="contractTaskSelectRadioGroup"
                                                value="invoice"
                                                checked={this.state.contractTaskSelectRadioGroup === "invoice"}
                                                onChange={this.handleRadioChange} />
                                    </Grid.Column>
                                </Grid.Row>
                                <Header as="h5">2. {t("Select invoicing method")}</Header>
                                <Grid.Row >
                                    <Grid.Column width={7} >
                                        <Radio
                                            label={t("New invoice")}
                                            name="invoicingMethodSelectRadioGroup"
                                            value="new_invoice"
                                            checked={this.state.invoicingMethodSelectRadioGroup === "new_invoice"}
                                            onChange={this.handleRadioChange} />
                                    </Grid.Column>
                                    <Grid.Column width={7}>
                                            <Radio
                                                label={t("Add to existing open invoice")}
                                                name="invoicingMethodSelectRadioGroup"
                                                value="open_invoice"
                                                checked={this.state.invoicingMethodSelectRadioGroup === "open_invoice"}
                                                onChange={this.handleRadioChange} />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
        
                            <Form error={invoice_create_error !== null}>
                                <Grid>
                                    {this.state.invoicingMethodSelectRadioGroup === "new_invoice" ? (
                                        <React.Fragment>
                                            <br/>
                                            <Header as="h5">3. {t("Add invoice details")}</Header>
                                            <Grid.Row>
                                                <Grid.Column width={8}>
                                                    <Header as="h4">{t("Basic details")}</Header>
                                                    <Form.Field required>
                                                        <label>{t("Invoice name")} {invoice_details.name ? (`${invoice_details.name.length}/35`) : ("0/35")}</label>
                                                        <Input
                                                            required
                                                            value={invoice_details.name}
                                                            name="name"
                                                            onChange={this.handleChange} />
                                                    </Form.Field>
                                                    {company_users && (
                                                        <Form.Field>
                                                            <label>{t("Select sales person")}</label>
                                                            <Select
                                                                fluid
                                                                search
                                                                name='sales_person'
                                                                options={company_users}
                                                                placeholder={t("Sales person")}
                                                                onChange={this.handleSelectChange}
                                                                value={invoice_details.sales_person && (invoice_details.sales_person)}
                                                            />
                                                        </Form.Field>
                                                    )}
                                                    <Grid columns="3">
                                                        <Grid.Row >
                                                            <Grid.Column>
                                                                <Form.Field required>
                                                                    <label>{t("Payment term")}</label>
                                                                    <Input
                                                                        required
                                                                        type='number'
                                                                        value={invoice_details.payment_term}
                                                                        name="payment_term"
                                                                        onChange={this.handleChange} />
                                                                </Form.Field>
                                                            </Grid.Column>
                                                            <Grid.Column  >
                                                                <Form.Field required>
                                                                    <label>{t("Invoice date")}</label>
                                                                    <SemanticDatepicker
                                                                        firstDayOfWeek="1"
                                                                        locale={fiLocale}
                                                                        placeholder={invoice_details.date !== null ? (new Date(invoice_details.date).toLocaleString('fi-FI', { dateStyle: 'short' })) : ("")}
                                                                        onDateChange={selected => this.handleDateChange(selected)}
                                                                        format='DD.MM.YYYY' />
                                                                </Form.Field>
                                                            </Grid.Column>
                                                            <Grid.Column >
                                                                <Form.Field required>
                                                                    <label>{t("Due date")}</label>
                                                                    <Input
                                                                        value={new Date(invoice_details.due_date).toLocaleString('fi-FI', { dateStyle: 'short' })}
                                                                        name="due_date"
                                                                        />
                                                                </Form.Field>
                                                            </Grid.Column>
                                                        </Grid.Row>
                                                    </Grid>
                                                    <br />
                                                    
                                                </Grid.Column>
                                                <Grid.Column width={8}>
                                                    <Header as="h4">{t("Invoicing details")}</Header>
                                                    <Form.Field>
                                                        <label>{t("Invoice comment")} {invoice_details.comment ? (`${invoice_details.comment.length}/100`) : ("0/100")}</label>
                                                        <Input
                                                            
                                                            value={invoice_details.comment}
                                                            name="comment"
                                                            onChange={this.handleChange} />
                                                    </Form.Field>
                                                    <Form.Field>
                                                        <label>{t("Company reference")} {invoice_details.company_reference ? (`${invoice_details.company_reference.length}/50`) : ("0/50")}</label>
                                                        <Input
                                                            
                                                            value={invoice_details.company_reference}
                                                            name="company_reference"
                                                            onChange={this.handleChange} />
                                                    </Form.Field>
                                                    <Form.Field>
                                                        <label>{t("Customer reference")} {invoice_details.customer_reference ? (`${invoice_details.customer_reference.length}/50`) : ("0/50")}</label>
                                                        <Input
                                                            
                                                            value={invoice_details.customer_reference}
                                                            name="customer_reference"
                                                            onChange={this.handleChange} />
                                                    </Form.Field>
                                                    <Form.Field>
                                                        <label>{t("Purchase order")} {invoice_details.purchase_order ? (`${invoice_details.purchase_order.length}/70`) : ("0/70")}</label>
                                                        <Input
                                                            
                                                            value={invoice_details.purchase_order}
                                                            name="purchase_order"
                                                            onChange={this.handleChange} />
                                                    </Form.Field>

                                                </Grid.Column>
                                            </Grid.Row>
                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment>
                                            {(active_project_open_invoices && active_project_open_invoices.length > 0) ? (
                                                <Grid>
                                                    <br/>
                                                    <Header as="h5">3. {t("Select open invoice")}</Header>
                                                    <Grid.Row>
                                                        <Grid.Column>
                                                            <Form.Field>
                                                                <Select
                                                                    fluid
                                                                    search
                                                                    name='selected_open_invoice'
                                                                    options={active_project_open_invoices}
                                                                    placeholder={t("Select invoice")}
                                                                    onChange={this.handleSelectChange}
                                                                    value={selected_open_invoice}
                                                                />
                                                            </Form.Field>
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                </Grid>
                                            ) : (
                                                <Grid.Row>
                                                    <Grid.Column>
                                                        <Header as="h5">3. {t("Select open invoice")}</Header>
                                                        <Header as="h5"><i>{t("No open invoices for customer")}:</i> {active_project.customer.name}</Header>
                                                    </Grid.Column>
                                                </Grid.Row>
                                            )}
                                            
                                        </React.Fragment>
                                    )}
                                    {approved_tasks && (
                                        <Grid.Row>
                                            <GridColumn>
                                            <Header as="h5">4. {t("Select tasks to the invoice")}</Header>
                                            <Table striped celled>
                                                <Table.Header>
                                                    <Table.Row>
                                                        <Table.HeaderCell icon='check circle outline' collapsing onClick={this.handleSelectAll} />
                                                        <Table.HeaderCell width='1'></Table.HeaderCell>
                                                        <Table.HeaderCell>{t("Name")}</Table.HeaderCell>
                                                        <Table.HeaderCell>{t("Author")}</Table.HeaderCell>
                                                        <Table.HeaderCell>{t("Task date")}</Table.HeaderCell>
                                                        <Table.HeaderCell>{t("Hours")}</Table.HeaderCell>
                                                        <Table.HeaderCell>{t("Invoice amount")}</Table.HeaderCell>
                                                        <Table.HeaderCell>{t("Net")}</Table.HeaderCell>
                                                    </Table.Row>
                                                </Table.Header>
                                                <Table.Body>
                                                    {approved_tasks.map(task => {
                                                        return (
                                                            <Table.Row key={task.id} >
                                                                <Table.Cell collapsing>
                                                                    <Checkbox
                                                                        value={task}
                                                                        checked={selected_approved_tasks.includes(task) === true}
                                                                        onChange={this.handleCheckboxChange} />
                                                                </Table.Cell>
                                                                <Table.Cell collapsing textAlign="center" onClick={() => this.taskDetails(task.id)}>
                                                                    <Popup content={task.task_type === "C" ? (t("Contract task")) : (t("Invoice task"))} trigger={
                                                                        task.task_type === "C" ? (<Icon name='handshake outline'/>) : (<Icon name='euro'  />)
                                                                    } />
                                                                </Table.Cell>
                                                                <Table.Cell title={task.name} onClick={() => this.taskDetails(task.id)}>
                                                                    {task.name}
                                                                    {(task.invoice_comment !== "" || task.internal_comment !== "") && (<Popup content={this.getCommentPopupText(task)} trigger={<Header as="h6" floated="right" icon="comments outline" />} />)}
                                                                </Table.Cell>
                                                                <Table.Cell title={`${task.author.first_name} ${task.author.last_name}`} onClick={() => this.taskDetails(task.id)}>{task.author.first_name} {task.author.last_name}</Table.Cell>
                                                                <Table.Cell onClick={() => this.taskDetails(task.id)}>{new Date(task.task_date).toLocaleString('fi-FI', { dateStyle: 'short' })}</Table.Cell>
                                                                <Table.Cell  onClick={() => this.taskDetails(task.id)}>{task.hours < 10 ? ("0" + task.hours.toString()) : (task.hours)}:{task.minutes < 10 ? ("0" + task.minutes.toString()) : (task.minutes)}</Table.Cell>
                                                                <Table.Cell  onClick={() => this.taskDetails(task.id)}>{task.amount.toFixed(2)} {task.measure_unit}</Table.Cell>
                                                                <Table.Cell  title={this.getTaskNetPrice(task).toFixed(2)} onClick={() => this.taskDetails(task.id)}>{this.getTaskNetPrice(task).toFixed(2)} €</Table.Cell>
                                                            </Table.Row>
                                                        );
                                                    })}
                                                </Table.Body>
                                            </Table>
                                            </GridColumn>
                                        </Grid.Row>
                                    )}
                                    
                                </Grid>
                                <br/>
                                {invoice_create_error && <Message error header={t("Error")} content={invoice_create_error} />}
                                <Button primary type="button" onClick={(e) => this.handleSubmit(e)} loading={loading}>
                                    {t("Create")}
                                </Button>
                            </Form>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button type="button" negative onClick={() => this.closeInvoicingModal()} >{t("Close")}</Button>
                        </Modal.Actions>          
                    </Modal>                     
                    </React.Fragment>
                )}          
            </Shell>
        );
    }
}
const mapStateToProps = state => {
    return {
        activeCompanyId: state.company.activeCompanyId,
        isAuthenticated: state.auth.token !== null
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setActivity: () => dispatch(setActivity())
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(withTranslation('projects')(Projects))
);