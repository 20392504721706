// Copyright Caproc Oy
// System name: Aqua-Net 
// System module: Project
// Author: TS
//
// 16.3.2020 TS Initial version
// 13.5.2020 TS Changed amount and net_price decimals to fixed 2.
// 19.5.2020 TS Fixed amount fixed decimals error in handleSubmit

import React from "react";
import { Form, Input, Message, Header, Button, Select } from "semantic-ui-react";
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import fiLocale from 'react-semantic-ui-datepickers/dist/locales/fi-FI';
import Shell from "./Shell";
import { authAxios, getISOFormattedDate } from "../../utils";
import { setActivity } from "../../store/actions/auth";
import { withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import { fetchProjectListURL, updateProjectTaskURL, createProjectTaskURL, projectTaskDetailsURL, projectTaskTypeOptionsURL } from "../../constants";


class ProjectTaskDetails extends React.Component {
    state = {
        project_task: {
            id: "",
            company: "",
            customer: null,
            project: null,
            project_product: null,
            task_type: "",
            author: "",
            status: "",
            name: "",
            task_date: "",
            invoice_comment: "",
            internal_comment: "",
            code: "",
            net_price: "0",
            vat_value: "24",
            discount: "0",
            measure_unit: "",
            hours: 0,
            minutes: 0,
            amount: 0
        },
        project_member_options: null,
        project_product_options: null,
        error: null,
        success_mgs: null,
        loading: false,
        create: null,
        projects: null,
        task_type_options: null,
        hours_options: null,
        minutes_options: null,
        minutes_disabled: false
    };

    componentDidMount() {
        this.props.setActivity();
        this.handleFetchProjectTaskTypeOptions();
        this.handleFetchProjects();
        this.createNumberOptions();

        if (this.props.match.params.project_task_id === "create") {
            this.setState({
                create: true
            })
            this.handleTaskDateChange(new Date());
        } else {
            this.handleDetails();
        }
    }

    handleFetchProjectTaskTypeOptions() {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;

        authAxios
            .get(projectTaskTypeOptionsURL(activeCompanyId))
            .then(res => {
                this.setState({
                    task_type_options: this.handleFormatTypeOptions(res.data),
                    error: null,
                    success_mgs: null,
                    loading: false,
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    success_mgs: null,
                    error: err
                });
            });

    }

    handleFormatTypeOptions = options => {
        const { t } = this.props;
        const keys = Object.keys(options);
        return keys.map(k => {
            return {
                key: options[k].value,
                text: t(options[k].name),
                value: options[k].value
            };
        });
    };

    handleFetchProjects() {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;

        authAxios
            .get(fetchProjectListURL(activeCompanyId))
            .then(res => {
                this.setState({
                    projects: this.handleFormatProjectsOptions(res.data),
                    error: null,
                    success_mgs: null,
                    loading: false,
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    success_mgs: null,
                    error: err
                });
            });

    }

    handleFormatProjectsOptions = options => {
        let i = 0;
        let options_list = [];
        for (i in options) {
            // Get only active ones..
            if (options[i].is_active) {
                options_list.push({
                    key: options[i].id,
                    text: `${options[i].customer.name}/${options[i].name} (${options[i].number})`,
                    value: options[i]
                })
            }
        }
        return options_list;
    };

    handleDetails = () => {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;
        const { project_task_id} = this.props.match.params;
        authAxios
            .get(projectTaskDetailsURL(activeCompanyId, project_task_id))
            .then(res => {
                this.setState({
                    project_task: res.data,
                    project_member_options: this.handleFormatMembersOptions(res.data.project.projectmembers),
                    project_product_options: this.handleFormatProductsOptions(res.data.project.projectproducts),    
                    error: null,
                    success_mgs: null,
                    loading: false
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    success_mgs: null,
                    error: err
                });
            });
    };

    handleChange = e => {
        const { t } = this.props;
        if (e.target.name === "amount" && isNaN(e.target.value)) {
            this.setState({
                error: t("Amount value is not number")
            });
        } else {
            this.setState({
                error: null
            });
        }
        const { project_task } = this.state;
        const updatedDetails = {
            ...project_task,
            [e.target.name]: e.target.value,
        };
        this.setState({
            project_task: updatedDetails,
            success_mgs: null
        });
    };

    handleSelectChange = (e, { name, value }) => {
        const { project_task } = this.state;
        let updatedDetails = {
            ...project_task,
            [name]: value
        };
        if (name === 'project') {
            updatedDetails = {
                ...project_task,
                company: value.company,
                customer: value.customer,
                project: value,
            };
            this.setState({
                project_task: updatedDetails,
                project_member_options: this.handleFormatMembersOptions(value.projectmembers),
                project_product_options: this.handleFormatProductsOptions(value.projectproducts)
            });
        } else if (name === 'project_product') {
            updatedDetails = {
                ...project_task,
                project_product: value,
                name: value.name,
                code: value.code,
                vat_value: value.vat_value,
                measure_unit: value.measure_unit,
                discount: value.discount,
                net_price: value.net_price
            };
            this.setState({
                project_task: updatedDetails
            });
        } else if (name === 'hours') {
            if (value === "24") {
                updatedDetails = {
                    ...project_task,
                    hours: value,
                    amount: this.handleCalcAmountFromHoursAndMinutes(value, "0"),
                    minutes: "0"
                };
                this.setState({
                    project_task: updatedDetails,
                    minutes_disabled: true
                });    
            } else {
                updatedDetails = {
                    ...project_task,
                    hours: value,
                    amount: this.handleCalcAmountFromHoursAndMinutes(value, project_task.minutes)
                };
                this.setState({
                    project_task: updatedDetails,
                    minutes_disabled: false
                });    
            }
        } else if (name === 'minutes') {
            updatedDetails = {
                ...project_task,
                minutes: value,
                amount: this.handleCalcAmountFromHoursAndMinutes(project_task.hours, value)
            };
            this.setState({
                project_task: updatedDetails
            });    
        } else {
            this.setState({
                project_task: updatedDetails
            });
        }
    };

    handleCalcAmountFromHoursAndMinutes = (hours, minutes) => {
        let return_hours = parseFloat(hours);
        if (parseInt(minutes) > 0) {
            return_hours += parseFloat(minutes) / 60;
        }
        return return_hours;
    };

    handleFormatMembersOptions = options => {
        const keys = Object.keys(options);
        return keys.map(k => {
            return {
                key: options[k].id,
                text: `${options[k].first_name} ${options[k].last_name}`,
                value: options[k]
            };
        });
    };

    handleFormatProductsOptions = options => {
        const keys = Object.keys(options);
        return keys.map(k => {
            return {
                key: options[k].id,
                text: `${options[k].name} ${options[k].net_price} €/${options[k].measure_unit}`,
                value: options[k]
            };
        });
    };

    handleSubmit = e => {
        e.preventDefault();
        this.setState({
            loading: true
        });
        const {
            project_task,
            create
        } = this.state;
        const { activeCompanyId, t } = this.props;
        if (project_task.project === null) {
            this.setState({
                error: t("Please select Project"),
                success_mgs: null,
                loading: false
            });
        } else if (project_task.author === null) {
            this.setState({
                error: t("Please select Author"),
                success_mgs: null,
                loading: false
            });
        } else if (project_task.project_product === null) {
            this.setState({
                error: t("Please select Project Product"),
                success_mgs: null,
                loading: false
            });
        } else if (project_task.name === "") {
            this.setState({
                error: t("Please fill in Name"),
                success_mgs: null,
                loading: false
            });
        } else if (project_task.name > 100) {
            this.setState({
                error: t("Max amount of characters is 100 for Name"),
                success_mgs: null,
                loading: false
            });
        } else if (project_task.task_type === "") {
            this.setState({
                error: t("Please select Task type"),
                success_mgs: null,
                loading: false
            });
        } else if (project_task.amount.toString().includes(",")) {
            this.setState({
                error: t("Please use dot as decimal separator in Amount instead of comma"),
                success_mgs: null,
                loading: false
            });
        } else {
            const updatedProjectTaskDetails = {
                ...project_task,
                customer: project_task.customer.id,
                project: project_task.project.id,
                project_product: project_task.project_product.id,
                author: project_task.author.id,
                hours: project_task.measure_unit !== "h" ? ("0") : (project_task.hours),
                minutes: project_task.measure_unit !== "h" ? ("0") : (project_task.minutes),
                net_price: project_task.task_type === "I" ? (project_task.net_price.toFixed(2)) : (0),
                amount: parseFloat(project_task.amount).toFixed(2)
            };

            if (create) {
                authAxios
                    .post(createProjectTaskURL(activeCompanyId), {
                        ...updatedProjectTaskDetails,
                        status: "C"
                    })
                    .then(res => {
                        this.props.history.push(`/project/tasks/C/`);
                    })
                    .catch(err => {
                        this.setState({
                            loading: false,
                            success_mgs: null,
                            error: err
                        });
                    });

            } else {
                authAxios
                    .put(updateProjectTaskURL(activeCompanyId, project_task.id), {
                        ...updatedProjectTaskDetails
                    })
                    .then(res => {
                        this.props.history.goBack();
                    })
                    .catch(err => {
                        this.setState({
                            loading: false,
                            success_mgs: null,
                            error: err
                        });
                    });
            }
        }
    };

    handleTaskDateChange = value => {
        const { project_task } = this.state;
        const updatedDetails = {
            ...project_task,
            task_date: getISOFormattedDate(value, "Date")
        };
        this.setState({
            project_task: updatedDetails
        });
    };

    createNumberOptions = () => {
        let options = [];
        for (let i = 0; i < 25; i++) {
            options.push({
                key: i.toString(),
                text: i.toString(),
                value: i.toString()
            })
        }
        let options2 = [];
        for (let i = 0; i < 60; i++) {
            options2.push({
                key: i.toString(),
                text: i.toString(),
                value: i.toString()
            })
        }
        this.setState({
            hours_options: options,
            minutes_options: options2
        });
    };

    render() {
        const {
            project_task,
            task_type_options,
            project_member_options,
            project_product_options,
            projects,
            error,
            success_mgs,
            loading,
            create,
            hours_options,
            minutes_options
        } = this.state;
        const { isAuthenticated, t } = this.props;
        if (!isAuthenticated) {
            return <Redirect to="/login" />;
        }
        // console.log(this.state);
        return (
            <Shell>
                {(create || project_task.id !== "") && (
                    <React.Fragment>
                        <Header as="h4"> {t("Project task")}</Header>
                        <Form error={error !== null}>
                            {projects && (
                                <Form.Field required>
                                    <label>{t("Project")}</label>
                                    <Select
                                        required
                                        search
                                        fluid
                                        name='project'
                                        options={projects}
                                        placeholder={create ? (t("Select project")) : (project_task.project.toString())}
                                        onChange={this.handleSelectChange}
                                        value={project_task.project}
                                    />
                                </Form.Field>
                            )}
                            {project_member_options && (
                                <Form.Field required>
                                    <label>{t("Author")}</label>
                                    <Select
                                        required
                                        fluid
                                        search
                                        name='author'
                                        options={project_member_options}
                                        placeholder={create ? (t("Select author")) : (project_task.author.toString())}
                                        onChange={this.handleSelectChange}
                                        value={project_task.author}
                                    />
                                </Form.Field>
                            )}
                            {project_product_options && (
                                <Form.Field required>
                                    <label>{t("Project product")}</label>
                                    <Select
                                        required
                                        fluid
                                        search
                                        name='project_product'
                                        options={project_product_options}
                                        placeholder={create ? (t("Select project product")) : (project_task.project_product.toString())}
                                        onChange={this.handleSelectChange}
                                        value={project_task.project_product}
                                    />
                                </Form.Field>
                            )}
                            <Form.Field required>
                                <label>{t("Name")}</label>
                                <Input
                                    required
                                    value={project_task.name}
                                    name="name"
                                    onChange={this.handleChange} />
                            </Form.Field>
                            {task_type_options && (
                                <Form.Field required>
                                    <label>{t("Task type")}</label>
                                    <Select
                                        required
                                        fluid
                                        search
                                        name='task_type'
                                        options={task_type_options}
                                        placeholder={create ? (t("Select task type")) : (project_task.task_type.toString())}
                                        onChange={this.handleSelectChange}
                                        value={project_task.task_type}
                                    />
                                </Form.Field>
                            )}
                            <Form.Field required>
                                <label>{t("Task date")}</label>
                                <SemanticDatepicker
                                    firstDayOfWeek={1}
                                    locale={fiLocale}
                                    name="task_date"
                                    pointing="top left"
                                    size="small"
                                    placeholder={project_task.task_date !== null ? (new Date(project_task.task_date).toLocaleString('fi-FI', { dateStyle: 'short' })) : (new Date().toLocaleString('fi-FI', { dateStyle: 'short' }))}
                                    onDateChange={selected => this.handleTaskDateChange(selected)}
                                    format='DD.MM.YYYY'
                                />
                            </Form.Field>
                            <Form.Field>
                                <label>{t("Internal comment")}</label>
                                <Input
                                    value={project_task.internal_comment}
                                    name="internal_comment"
                                    onChange={this.handleChange} />
                            </Form.Field>
                            <Form.Field>
                                <label>{t("Invoice comment")}</label>
                                <Input
                                    value={project_task.invoice_comment}
                                    name="invoice_comment"
                                    onChange={this.handleChange} />
                            </Form.Field>
                            {project_task.measure_unit === "h" && (
                                <React.Fragment>
                                    {hours_options && (
                                        <Form.Field required>
                                            <label>{t("Hours")}</label>
                                            <Select
                                                required
                                                fluid
                                                search
                                                name='hours'
                                                options={hours_options}
                                                placeholder={project_task.hours.toString()}
                                                onChange={this.handleSelectChange}
                                                value={project_task.hours.toString()}
                                            />
                                        </Form.Field>
                                    )}
                                    {minutes_options && (
                                        <Form.Field required>
                                            <label>{t("Minutes")}</label>
                                            <Select
                                                disabled={this.state.minutes_disabled}
                                                required
                                                fluid
                                                search
                                                name='minutes'
                                                options={minutes_options}
                                                placeholder={project_task.minutes.toString()}
                                                onChange={this.handleSelectChange}
                                                value={project_task.minutes.toString()}
                                            />
                                        </Form.Field>
                                    )}
                                </React.Fragment>
                            )}
                            {(project_task.measure_unit !== "" && project_task.measure_unit !== "h") && (
                                <Form.Field required>
                                    <label>{t("Amount")}</label>
                                    <Input
                                        required
                                        value={project_task.amount}
                                        name="amount"
                                        onChange={this.handleChange} />
                                </Form.Field>
                            )}
                            {error && <Message error header={t("Error")} content={error} />}
                            {success_mgs && <Message positive header={success_mgs} />}
                            <Button primary type="button" onClick={(e) => this.handleSubmit(e)} loading={loading} disabled={loading}>
                                {create ? (t("Create")) : (t("Save"))}
                            </Button>
                        </Form>
                    </React.Fragment>
                )}
            </Shell>
        );
    }
}
const mapStateToProps = state => {
    return {
        activeCompanyId: state.company.activeCompanyId,
        isAuthenticated: state.auth.token !== null
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setActivity: () => dispatch(setActivity())
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(withTranslation('projecttaskdetails')(ProjectTaskDetails))
);