// Copyright Caproc Oy
// System name: Aqua-Net 
// System module: Company
// Author: TS
//
// 1.2.2020 TS Initial version

import React from "react";
import { Form, Input, Message, Header, Button } from "semantic-ui-react";
import Shell from "./Shell";
import { restAuthAxios, validatePasswords } from "../../utils";
import { setActivity } from "../../store/actions/auth";
import { changePasswordURL } from "../../constants";
import { withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';


class ChangePassword extends React.Component {
    state = {
        password: "",
        confirmPassword: "",
        error: null,
        succes_msg: null,
        loading: false
    };

    componentDidMount() {
        this.props.setActivity();
    }

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value,
            error: null,
            succes_msg: null
        });
    };

    handleSubmit = e => {
        e.preventDefault();
        this.setState({
            loading: true
        });
        const { password, confirmPassword } = this.state;
        const { t } = this.props;
        if (password === "" || confirmPassword === "") {
            this.setState({
                error: t("Please fill in all the fields"),
                loading: false
            });
        } else {
            const validate_response = validatePasswords(password, confirmPassword);
            if (validate_response === "ok") {
                restAuthAxios
                    .post(changePasswordURL, {
                        new_password1: password,
                        new_password2: confirmPassword
                    })
                    .then(res => {
                        this.setState({
                            loading: false,
                            password: "",
                            confirmPassword: "",
                            error: null,
                            succes_msg: t("Password changed successfully")
                        });
                    })
                    .catch(err => {
                        this.setState({
                            loading: false,
                            error: err,
                            succes_msg: null

                        });
                    });
            } else {
                this.setState({
                    loading: false,
                    error: t(validate_response)
                });
            }
        }
    };

    render() {
        const {
            password,
            confirmPassword,
            error,
            loading,
            succes_msg
        } = this.state;
        const { isAuthenticated, t } = this.props;
        if (!isAuthenticated) {
            return <Redirect to="/login" />;
        }
        return (
            <Shell>
                <Header as="h4">{t("Change password")}</Header>
                <Form error={error !== null}>
                    <Form.Field required>
                        <label>{t("New password")}</label>
                        <Input
                            value={password}
                            placeholder={t("New password")}
                            type="password"
                            name="password"
                            onChange={this.handleChange}
                        />
                    </Form.Field>
                    <Form.Field required>
                        <label>{t("Confirm password")}</label>
                        <Input
                            value={confirmPassword}
                            placeholder={t("Confirm password")}
                            type="password"
                            name="confirmPassword"
                            onChange={this.handleChange}
                        />
                    </Form.Field>
                    {error && <Message error header={t("Error")} content={error} />}
                    {succes_msg && <Message positive header={succes_msg} />}
                    <Button primary type="button" onClick={(e) => this.handleSubmit(e)} loading={loading} disabled={loading}>
                        {t("Submit")}
                    </Button>
                </Form>
            </Shell>
        );
    }
}
const mapStateToProps = state => {
    return {
        isAuthenticated: state.auth.token !== null
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setActivity: () => dispatch(setActivity())
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(withTranslation('changepassword')(ChangePassword))
);
