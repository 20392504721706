// Copyright Caproc Oy
// System name: Aqua-Net 
// System module: Customers
// Author: TS
//
// 1.2.2020 TS Initial version
// 8.5.2020 TS Added Net invoicing functionality
// 10.5.2020 TS Added no_vat_comment
// 26.5.2020 TS Added add_address_to_invoice 
// 8.6.2020 TS Added character amount counters
// 17.8.2020 TS Added check for mandatory no vat comment if no vat is activated. Also check that discount input is numerical.

import React from "react";
import { Form, Input, Message, Header, Button, Checkbox, Select, Modal, Table, Container, Popup } from "semantic-ui-react";
import Shell from "./Shell";
import { authAxios } from "../../utils";
import { setActivity } from "../../store/actions/auth";
import { withRouter, Redirect, Link } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import { customerInvoicingDetailsURL, updateCustomerInvoicingDetailsURL, customerDetailsURL, customerInvoicingMethodOptionsURL, searchNetInvoicingDetailsURL, searchNetInvoiceDeliveryMethodURL } from "../../constants";


class CustomerInvoicingDetails extends React.Component {
    state = {
        customer_id: null,
        invoicing_details_id: null,
        payment_term: "",
        default_customer_reference: "",
        default_company_reference: "",
        default_contract_number: "",
        default_invoice_comment: "",
        default_sensor_number: "",
        default_location_number: "",
        no_vat: false,
        no_vat_comment: "",
        discount: "",
        invoicing_method: "",
        invoicing_email: "",
        invoicing_method_options: null,
        net_invoice_indentifier: null,
        net_invoice_intermediator: null,
        add_address_to_invoice: false,
        customer_name: "",
        customer_vat: "",
        error: null,
        success_mgs: null,
        loading: false,
        net_invoice_detail_search_result: null,
        net_invoice_detail_search_modal_open: false,
        net_invoice_delivery_method_result: null,
        net_invoice_delivery_method_modal_open: false
    };

    componentDidMount() {
        this.props.setActivity();
        this.fetchInvoicingMethodOptions();
        this.handleInvoicingDetails();
        this.fetchCustomerDetails();
    }

    fetchCustomerDetails() {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;
        const { customer_id } = this.props.match.params;

        authAxios
            .get(customerDetailsURL(activeCompanyId, customer_id))
            .then(res => {
                this.setState({
                    customer_name: res.data.name,
                    customer_vat: res.data.vat,
                    error: null,
                    success_mgs: null,
                    loading: false,
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    success_mgs: null,
                    error: err
                });
            });
    }

    handleInvoicingDetails = () => {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;
        const { customer_id } = this.props.match.params;
        authAxios
            .get(customerInvoicingDetailsURL(activeCompanyId, customer_id))
            .then(res => {
                this.setState({
                    invoicing_details_id: res.data.id,
                    customer_id: res.data.customer_id,
                    payment_term: res.data.payment_term,
                    default_customer_reference: res.data.default_customer_reference,
                    default_company_reference: res.data.default_company_reference,
                    default_contract_number: res.data.default_contract_number,
                    default_invoice_comment: res.data.default_invoice_comment,
                    default_sensor_number: res.data.default_sensor_number,
                    default_location_number: res.data.default_location_number,
                    no_vat: res.data.no_vat,
                    no_vat_comment: res.data.no_vat_comment,
                    add_address_to_invoice: res.data.add_address_to_invoice,
                    discount: res.data.discount,
                    invoicing_method: res.data.invoicing_method,
                    invoicing_email: res.data.invoicing_email,
                    net_invoice_indentifier: res.data.net_invoice_indentifier,
                    net_invoice_intermediator: res.data.net_invoice_intermediator,
                    error: null,
                    success_mgs: null,
                    loading: false
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    success_mgs: null,
                    error: err
                });
            });
    };


    fetchInvoicingMethodOptions() {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;
        const { customer_id } = this.props.match.params;

        authAxios
            .get(customerInvoicingMethodOptionsURL(activeCompanyId, customer_id))
            .then(res => {
                this.setState({
                    invoicing_method_options: this.handleFormatOptions(res.data),
                    error: null,
                    success_mgs: null,
                    loading: false,
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    success_mgs: null,
                    error: err
                });
            });

    }

    handleFormatOptions = options => {
        const { t } = this.props;
        const keys = Object.keys(options);
        return keys.map(k => {
            return {
                key: options[k].value,
                text: t(options[k].name),
                value: options[k].value
            };
        });
    };


    handleSubmit = e => {
        e.preventDefault();
        this.setState({
            loading: true
        });
        const {
            customer_id,
            invoicing_details_id,
            payment_term,
            default_customer_reference,
            default_company_reference,
            default_contract_number,
            default_invoice_comment,
            default_sensor_number,
            default_location_number,
            no_vat,
            no_vat_comment,
            add_address_to_invoice,
            discount,
            invoicing_method,
            invoicing_email,
            net_invoice_indentifier,
            net_invoice_intermediator
        } = this.state;
        const { activeCompanyId, t } = this.props;

        if (discount.toString().includes(",")) {
            this.setState({
                error: t("Please use dot as decimal separator in Discount instead of comma"),
                success_mgs: null,
                loading: false
            });
        } else if (invoicing_method === "E" && invoicing_email === "") {
            this.setState({
                error: t("Please add Invoicing email if invoicing method is email"),
                success_mgs: null,
                loading: false
            });
        } else if (no_vat && no_vat_comment === "") {
            this.setState({
                error: t("Please add VAT% 0 default comment"),
                success_mgs: null,
                loading: false
            });
        } else if (default_customer_reference.length > 50) {
            this.setState({
                error: t("Max amount of characters is 50 for Default customer reference"),
                success_mgs: null,
                loading: false
            });
        } else if (default_company_reference.length > 50) {
            this.setState({
                error: t("Max amount of characters is 50 for Default company reference"),
                success_mgs: null,
                loading: false
            });
        } else {
            authAxios
                .put(updateCustomerInvoicingDetailsURL(activeCompanyId, customer_id, invoicing_details_id), {
                    customer: customer_id,
                    payment_term,
                    default_customer_reference,
                    default_company_reference,
                    default_contract_number,
                    default_invoice_comment,
                    default_sensor_number,
                    default_location_number,
                    no_vat,
                    no_vat_comment,
                    add_address_to_invoice,
                    discount,
                    invoicing_method,
                    invoicing_email,
                    net_invoice_indentifier,
                    net_invoice_intermediator
                })
                .then(res => {
                    this.setState({
                        loading: false,
                        success_mgs: t("Customer invoicing details updated successfully"),
                        error: null
                    });
                })
                .catch(err => {
                    this.setState({
                        loading: false,
                        success_mgs: null,
                        error: err
                    });
                });
        }

    };

    handleCheckboxChange = (e, { name, checked }) => {
        this.setState({
            [name]: checked
        });
    };

    handleChange = e => {
        const { t } = this.props;
        if (e.target.name === "discount" && isNaN(e.target.value)) {
            this.setState({
                error: t("Discount value is not number")
            });
        } else {
            this.setState({
                error: null
            });
        }
        this.setState({
            [e.target.name]: e.target.value,
            success_mgs: null
        });
    };

    handleSelectChange = (e, { name, value }) => {
        this.setState({
            [name]: value
        });
    };

    handleSearchNetInvoiceDetails = (e) => {
        e.preventDefault();
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;
        const { customer_vat, customer_name } = this.state;

        authAxios
            .post(searchNetInvoicingDetailsURL(activeCompanyId), {
                customer_vat: customer_vat,
                customer_name: customer_name
            })
            .then(res => {
                this.setState({
                    net_invoice_detail_search_result: res.data,
                    net_invoice_detail_search_modal_open: true,
                    error: null,
                    success_mgs: null,
                    loading: false,
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    success_mgs: null,
                    error: err
                });
            });
    
    };

    handleCheckDeliveryMethod = (e) => {
        e.preventDefault();
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;
        const { customer_id } = this.props.match.params;

        authAxios
            .get(searchNetInvoiceDeliveryMethodURL(activeCompanyId, customer_id))
            .then(res => {
                this.setState({
                    net_invoice_delivery_method_result: res.data,
                    net_invoice_delivery_method_modal_open: true,
                    error: null,
                    success_mgs: null,
                    loading: false,
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    success_mgs: null,
                    error: err
                });
            });
    };

    closeNetInvoiceSearchModal = () => {
        this.setState({
            net_invoice_detail_search_modal_open: false,
            net_invoice_detail_search_result: null
        });
    };

    closeNetInvoiceDeliveryModal = () => {
        this.setState({
            net_invoice_delivery_method_modal_open: false,
            net_invoice_delivery_method_result: null
        });
    };

    handleSelectNetInvoiceDetail = net_address => {
        this.setState({
            net_invoice_indentifier: net_address.ReceivereInvoiceAddress,
            net_invoice_intermediator: net_address.ReceiverOperatorId,
        });
        this.closeNetInvoiceSearchModal();
    };

    render() {
        const {
            customer_name,
            customer_vat,
            customer_id,
            payment_term,
            default_customer_reference,
            default_company_reference,
            default_contract_number,
            default_invoice_comment,
            default_sensor_number,
            default_location_number,
            no_vat,
            no_vat_comment,
            add_address_to_invoice,
            discount,
            invoicing_method,
            invoicing_email,
            invoicing_method_options,
            net_invoice_indentifier,
            net_invoice_intermediator,
            error,
            success_mgs,
            net_invoice_detail_search_modal_open,
            net_invoice_detail_search_result,
            net_invoice_delivery_method_modal_open,
            net_invoice_delivery_method_result,
            loading } = this.state;
        const { isAuthenticated, t } = this.props;
        if (!isAuthenticated) {
            return <Redirect to="/login" />;
        }
        // console.log(this.state);
        return (
            <Shell active_customer_id={customer_id} active_customer_name={customer_name}>
                <Header as="h4">{customer_name}</Header>
                <Form error={error !== null}>
                    <Form.Field>
                        <label>{t("Payment term")}</label>
                        <Input
                            value={payment_term}
                            name="payment_term"
                            onChange={this.handleChange} />
                    </Form.Field>
                    <Form.Field>
                        <label>{t("Default customer reference")} {default_customer_reference ? (`${default_customer_reference.length}/50`) : ("0/50")}</label>
                        <Input
                            value={default_customer_reference}
                            name="default_customer_reference"
                            onChange={this.handleChange} />
                    </Form.Field>
                    <Form.Field>
                        <label>{t("Default company reference")} {default_company_reference ? (`${default_company_reference.length}/50`) : ("0/50")}</label>
                        <Input
                            value={default_company_reference}
                            name="default_company_reference"
                            onChange={this.handleChange} />
                    </Form.Field>
                    <Form.Field>
                        <label>{t("Default contract number")} {default_contract_number ? (`${default_contract_number.length}/35`) : ("0/35")}</label>
                        <Input
                            value={default_contract_number}
                            name="default_contract_number"
                            onChange={this.handleChange} />
                    </Form.Field>
                    <Form.Field>
                        <label>{t("Default invoice comment")} {default_invoice_comment ? (`${default_invoice_comment.length}/100`) : ("0/100")}</label>
                        <Input
                            value={default_invoice_comment}
                            name="default_invoice_comment"
                            onChange={this.handleChange} />
                    </Form.Field>
                    <Form.Field>
                        <label>{t("Default sensor number")} {default_sensor_number ? (`${default_sensor_number.length}/35`) : ("0/35")}</label>
                        <Input
                            value={default_sensor_number}
                            name="default_sensor_number"
                            onChange={this.handleChange} />
                    </Form.Field>
                    <Form.Field>
                        <label>{t("Default location number")} {default_location_number ? (`${default_location_number.length}/35`) : ("0/35")}</label>
                        <Input
                            value={default_location_number}
                            name="default_location_number"
                            onChange={this.handleChange} />
                    </Form.Field>
                    <Form.Field>
                        <Checkbox
                            label={<label>{t("Add customer delivery address to invoice")}</label>}
                            name="add_address_to_invoice"
                            onChange={this.handleCheckboxChange}
                            checked={add_address_to_invoice}
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>{t("Default discount")}</label>
                        <Input
                            value={discount}
                            name="discount"
                            onChange={this.handleChange} />
                    </Form.Field>
                    <Form.Field>
                        <Checkbox
                            label={<label>{t("VAT% 0")}</label>}
                            name="no_vat"
                            onChange={this.handleCheckboxChange}
                            checked={no_vat}
                        />
                    </Form.Field>
                    {no_vat && (
                        <Form.Field>
                            <Popup content={t("Default reason text for VAT% 0 such as 'according to AVL 8 c § buyer will pay for value added tax'")} 
                                trigger={<label>{t("VAT% 0 default comment")} {no_vat_comment ? (`${no_vat_comment.length}/70`) : ("0/70")}</label>}/>
                            <Input
                                value={no_vat_comment}
                                name="no_vat_comment"
                                onChange={this.handleChange} />
                        </Form.Field>
                    )}
                    {invoicing_method_options && (
                        <Form.Field>
                            <label>{t("Invoicing method")}</label>
                            <Select
                                search
                                fluid
                                name='invoicing_method'
                                options={invoicing_method_options}
                                placeholder={invoicing_method}
                                onChange={this.handleSelectChange}
                                value={invoicing_method}
                            />
                        </Form.Field>
                    )}
                    <Form.Field>
                        <label>{t("Invoicing email")}</label>
                        <Input
                            value={invoicing_email}
                            name="invoicing_email"
                            onChange={this.handleChange} />
                    </Form.Field>
                    {invoicing_method === "N" && (
                        <React.Fragment>
                            <hr/>
                            <React.Fragment>
                            <Link>
                                <Button floated="right" size="tiny" type="button" primary loading={loading} disabled={loading} onClick={(e) => this.handleCheckDeliveryMethod(e)}>{t("Check delivery method")}</Button>
                            </Link>
                            <Link>
                                <Button floated="right" size="tiny" type="button" primary loading={loading} disabled={customer_vat === "" ? true : false} onClick={(e) => this.handleSearchNetInvoiceDetails(e)}>{t("Search net invoice details")}</Button>
                            </Link>
                            </React.Fragment>
                            <Form.Field>
                                <label>{t("Net invoice identifier")}</label>
                                <Input
                                    value={net_invoice_indentifier}
                                    name="net_invoice_indentifier"
                                    onChange={this.handleChange} />
                            </Form.Field>
                            <Form.Field>
                                <label>{t("Net invoice intermediator")}</label>
                                <Input
                                    value={net_invoice_intermediator}
                                    name="net_invoice_intermediator"
                                    onChange={this.handleChange} />
                            </Form.Field>
                        </React.Fragment>
                    )}
                    <hr/>
                    {error && <Message error header={t("Error")} content={error} />}
                    {success_mgs && <Message positive header={success_mgs} />}
                    <Form.Button type="button" primary loading={loading} disabled={loading} onClick={(e) => this.handleSubmit(e)}>
                        {t("Save")}
                    </Form.Button>
                </Form>
                <Modal size='tiny' open={net_invoice_detail_search_modal_open} dimmer="inverted" scrolling="true">
                    <Modal.Header>
                        {t("Search results")}: {customer_name} {customer_vat}
                    </Modal.Header>
                    <Modal.Content>
                        <Container>
                            {net_invoice_detail_search_result && (
                                <React.Fragment>
                                    {net_invoice_detail_search_result[0]['Status'] === "OK" ? (
                                        <React.Fragment>
                                            {net_invoice_detail_search_result[0]['ReceiverAddresses'][0]['ReceiverOperator'] ? (
                                                <React.Fragment>
                                                    <Table  basic="very" size="small">
                                                        <Table.Header>
                                                            <Table.Row>
                                                                <Table.HeaderCell>{t("Receiver")}</Table.HeaderCell>
                                                                <Table.HeaderCell>{t("Net invoice identifier")}</Table.HeaderCell>
                                                                <Table.HeaderCell>{t("Net invoice intermediator")}</Table.HeaderCell>
                                                                <Table.HeaderCell/>
                                                            </Table.Row>
                                                        </Table.Header>
                                                        <Table.Body>
                                                            {net_invoice_detail_search_result[0]['ReceiverAddresses'].map(net_address => {
                                                                return (
                                                                    <Table.Row>
                                                                        <Table.Cell>{net_address.ReceiverName}</Table.Cell>
                                                                        <Table.Cell>{net_address.ReceivereInvoiceAddress}</Table.Cell>
                                                                        <Table.Cell>{net_address.ReceiverOperatorId}</Table.Cell>
                                                                        <Table.Cell collapsing textAlign='center'>
                                                                            <Button type="button" primary size='tiny' content={t("Select")} onClick={() => this.handleSelectNetInvoiceDetail(net_address)} />
                                                                        </Table.Cell>
                                                                    </Table.Row>
                                                                );
                                                            })}
                                                        </Table.Body>
                                                    </Table>
                                                </React.Fragment>
                                            ) : (
                                                t("No net invoice details available")
                                            )}
                                        </React.Fragment>
                                    ) : (
                                        t("ERROR: Search failed.. For more information please contact info@caproc.fi.")
                                    )}
                                </React.Fragment>
                            )}
                        </Container>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button type="button" negative onClick={() => this.closeNetInvoiceSearchModal()} >{t("Close")}</Button>
                    </Modal.Actions>
                </Modal>
                <Modal size='tiny' open={net_invoice_delivery_method_modal_open} dimmer="inverted" scrolling="true">
                    <Modal.Header>
                        {t("Delivery method")}: {customer_name} {customer_vat}
                    </Modal.Header>
                    <Modal.Content>
                        <Container>
                            {net_invoice_delivery_method_result && (
                                <React.Fragment>
                                    {net_invoice_delivery_method_result[0]['Status'] === "OK" ? (
                                        <React.Fragment>
                                            {net_invoice_delivery_method_result[0]['Documents'] ? (
                                                <React.Fragment>
                                                    <Table  basic="very" size="small">
                                                        <Table.Header>
                                                            <Table.Row>
                                                                <Table.HeaderCell>{t("Receiver")}</Table.HeaderCell>
                                                                <Table.HeaderCell>{t("Delivery method")}</Table.HeaderCell>
                                                            </Table.Row>
                                                        </Table.Header>
                                                        <Table.Body>
                                                            {net_invoice_delivery_method_result[0]['Documents'].map(method => {
                                                                return (
                                                                    <Table.Row>
                                                                        <Table.Cell>{method.ReceiverName}</Table.Cell>
                                                                        <Table.Cell>{t(method.ChannelName)}</Table.Cell>
                                                                    </Table.Row>
                                                                );
                                                            })}
                                                        </Table.Body>
                                                    </Table>
                                                </React.Fragment>
                                            ) : (
                                                t("No net invoice delivery method details available")
                                            )}
                                        </React.Fragment>
                                    ) : (
                                        t("ERROR: Delivery method check failed.. For more information please contact info@caproc.fi.")
                                    )}
                                </React.Fragment>
                            )}
                        </Container>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button type="button" negative onClick={() => this.closeNetInvoiceDeliveryModal()} >{t("Close")}</Button>
                    </Modal.Actions>
                </Modal>
            </Shell>
        );
    }
}
const mapStateToProps = state => {
    return {
        activeCompanyId: state.company.activeCompanyId,
        isAuthenticated: state.auth.token !== null
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setActivity: () => dispatch(setActivity())
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(withTranslation('customerinvoicingdetails')(CustomerInvoicingDetails))
);