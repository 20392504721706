import React from "react";
import {
  Button,
  Form,
  Grid,
  Header,
  Message,
  Segment
} from "semantic-ui-react";
import { connect } from "react-redux";
import { NavLink, Redirect } from "react-router-dom";
import { authLogin } from "../store/actions/auth";
import { withTranslation } from 'react-i18next';


class LoginForm extends React.Component {
  state = {
    username: "",
    password: ""
  };

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { username, password } = this.state;
    this.props.login(username, password);
  };

  render() {
    const { error, loading, token, t } = this.props;
    const { username, password } = this.state;
    if (token) {
      return <Redirect to="/" />;
    }
    return (
      <Grid
        textAlign="center"
        style={{ height: "100vh" }}
        verticalAlign="middle"
      >
        <Grid.Column style={{ maxWidth: 450 }}>
          <Header as="h2" textAlign="center">
            {t("Log-in to your account")}
          </Header>
          {error && (error.message === "Network Error" ? (<Header as="h5" color="red">{t("Network error. Please try again later. If problem still exists, please contact system support")}</Header>)
            : (<Header as="h5" color="red">{t("Login failed. Please check email and password")}</Header>))}

          <React.Fragment>
            <Form size="large" >
              <Segment stacked>
                <Form.Input
                  onChange={this.handleChange}
                  value={username}
                  name="username"
                  fluid
                  icon="mail"
                  iconPosition="left"
                  placeholder={t("Email")}
                />
                <Form.Input
                  onChange={this.handleChange}
                  fluid
                  value={password}
                  name="password"
                  icon="lock"
                  iconPosition="left"
                  placeholder={t("Password")}
                  type="password"
                />

                <Button
                  primary
                  type="submit"
                  onClick={(e) => this.handleSubmit(e)}
                  fluid
                  size="large"
                  loading={loading}
                  disabled={loading}
                >
                  {t("Login")}
                </Button>
              </Segment>
            </Form>
            <Message>
              {/* New to us? <NavLink to="/signup">Sign Up</NavLink> <br></br> */}
              {t("Forgot password?")} <NavLink to="/password-reset-email/">{t("Reset password")}</NavLink>
            </Message>
          </React.Fragment>
        </Grid.Column>
      </Grid>
    );
  }
}

const mapStateToProps = state => {
  return {
    loading: state.auth.loading,
    error: state.auth.error,
    token: state.auth.token
  };
};

const mapDispatchToProps = dispatch => {
  return {
    login: (username, password) => dispatch(authLogin(username, password))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('login')(LoginForm));
