// Copyright Caproc Oy
// System name: Aqua-Net 
// System module: Company
// Author: TS
//
// 6.9.2021 TS Initial version


import React from "react";
import { Message, Header, Button, Table, Segment, Dimmer, Loader, Image, Modal, Grid, Input, Responsive, Container, Form, Confirm } from "semantic-ui-react";
import Shell from "./Shell";
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import fiLocale from 'react-semantic-ui-datepickers/dist/locales/fi-FI';
import { authAxios, authFileAxios, getISOFormattedDate, getWidth, exportToCsv } from "../../utils";
import { setActivity } from "../../store/actions/auth";
import { withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import { companyBankTransactionsURL, companyBankTransactionsImportUploadURL, deleteBankTransactionURL } from "../../constants";


class CompanyBankTransactions extends React.Component {
    state = {
        bank_transactions: null,
        customer_name: null,
        customer_id: null,
        message_modal_open: false,
        message_modal_text: "",
        error: null,
        import_error: null,
        loading: false,
        search_start_date: null,
        search_end_date: null,
        search: "",
        refresh: false,
        file: {
            file: "",
            first_row_header: false,
            selected_file: null
        },
        activeIndex: 0,
        import_file_rows_total: 0,
        missing_import_information: null,
        response_obj: null,
        import_disabled: false,
        delete_confirm_open: false,
        delete_confirm_text: "",
        delete_item: ""        

    };

    componentDidMount() {
        this.props.setActivity();
        let init_start_date = new Date();
        init_start_date.setDate(init_start_date.getDate() - 90);
        this.handleStartDateChange(init_start_date);
        this.handleEndDateChange(new Date());
        // this.handleFetchDataCollectionItemsList();
    }

    handleClick = (e, titleProps) => {
        const { index } = titleProps
        const { activeIndex } = this.state
        const newIndex = activeIndex === index ? -1 : index

        this.setState({ activeIndex: newIndex })
    };

    handleFetchBankTransactionsList = () => {
        this.setState({
            loading: true,
        });
        const { activeCompanyId } = this.props;
        const { search_start_date, search_end_date } = this.state;

        if (search_start_date !== null && search_end_date !== null) {

            authAxios
                .get(companyBankTransactionsURL(activeCompanyId, search_start_date, search_end_date))
                .then(res => {
                    this.setState({
                        bank_transactions: res.data,
                        error: null,
                        loading: false
                    });
                })
                .catch(err => {
                    this.setState({
                        loading: false,
                        error: err
                    });
                });
        }
    };

    openMessageModal = message => {
        this.setState({
            message_modal_open: true,
            message_modal_text: message
        });
    };

    closeModal = () => {
        this.setState({
            message_modal_open: false,
            message_modal_text: ""
        });
    };

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    handleStartDateChange = value => {
        this.setState({
            search_start_date: getISOFormattedDate(value, "Date"),
            refresh: true
        });
    };

    handleEndDateChange = value => {
        this.setState({
            search_end_date: getISOFormattedDate(value, "Date"),
            refresh: true
        });
    };

    handleExportToCSV = items => {
        const { t } = this.props;
        if (items !== null) {
            const titles = [
                t("Value date"),
                t("Payer"),
                t("Invoice reference"),
                t("Amount"),
                t("Related invoice"),
                t("Import time"),
                t("Status")
            ];
            let items_to_csv = [];
            let i = 0;
            for (i in items) {
                items_to_csv.push({
                    value_date: new Date(items[i].value_date).toLocaleString('fi-FI', { dateStyle: 'short' }),
                    transaction_party: items[i].transaction_party,
                    invoice_reference: items[i].invoice_reference,
                    amount: items[i].amount,
                    related_invoice: items[i].related_invoice.number,
                    date_created: new Date(items[i].date_created).toLocaleString('fi-FI'),
                    status: items[i].status
                });
            }
            exportToCsv(this.props.activeCompanyId, "export", titles, items_to_csv);
        }
    };

    handleFileCheckboxChange = (e, { name, value }) => {
        const { file } = this.state;
        const updatedFile = {
            ...file,
            [name]: !value
        }
        this.setState({
            file: updatedFile,
            success_mgs: null,
            import_error: null
        });
    };

    handleFileSelect = (e) => {
        // e.preventDefault();
        // this.fileSelector.click();
        const { file } = this.state;
        const updatedFile = {
            ...file,
            selected_file: e.target.files[0]
        }
        this.setState({
            file: updatedFile,
            success_mgs: null,
            import_error: null
        });
    };

    handleUploadFile = e => {
        e.preventDefault();
        this.setState({
            loading: true
        });
        const { file } = this.state;
        const { activeCompanyId, t } = this.props;
        if (file.selected_file === null) {
            this.setState({
                import_error: t("Please select file to upload"),
                success_mgs: null,
                loading: false
            });
        } else {
            let form_data = new FormData();
            form_data.append('file', file.selected_file, file.selected_file.name);
            // form_data.append('first_row_header', file.first_row_header);

            authFileAxios
                .post(companyBankTransactionsImportUploadURL(activeCompanyId), form_data)
                .then(res => {
                    this.setState({
                        response_obj: res.data,
                        // missing_import_information: res.data.missing_rows_list,
                        // import_file_rows_total: res.data.rows_in_file,
                        loading: false,
                        success_mgs: null,
                        import_error: null,
                        import_disabled: true
                    });
                    // this.handleCloseModal();
                })
                .catch(err => {
                    this.setState({
                        loading: false,
                        success_mgs: null,
                        import_error: err.response.data
                    });
                });
        }
    };

    handleImportItems = () => {
        this.setState({
            import_csv_modal_open: true
        });
    };

    handleCloseModal = () => {
        const updatedFile = {
            ...this.state.file,
            first_row_header: false
        }
        this.setState({
            import_csv_modal_open: false,
            received_items_list: null,
            missing_import_information: null,
            import_file_rows_total: 0,
            import_disabled: false,
            file: updatedFile,
            import_error: null,
            loading: false,
            refresh: true
        });
    };


    handleDelete = item => {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;
        authAxios
            .delete(deleteBankTransactionURL(activeCompanyId, item.id))
            .then(res => {
                this.setState({
                    refresh: true,
                    error: null,
                    loading: false
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    error: err
                });
            });
        this.setState({
            delete_confirm_open: false,
            delete_confirm_text: "",
            delete_item: ""
        });
    };

    openDeleteConfirm = (text, delete_item) => {
        this.setState({
            delete_confirm_open: true,
            delete_confirm_text: text,
            delete_item: delete_item
        });
    };

    closeDeleteConfirm = () => {
        this.setState({
            delete_confirm_open: false,
            delete_confirm_text: "",
            delete_item: ""
        });
    };


    render() {
        const { error, loading, bank_transactions, customer_name, search, search_end_date, search_start_date, refresh, import_csv_modal_open, response_obj, missing_import_information, import_file_rows_total, file, import_error, activeIndex, import_disabled, delete_confirm_open, delete_confirm_text, delete_item } = this.state;
        const { customer_id } = this.props.match.params;
        const { isAuthenticated, t } = this.props;
        if (!isAuthenticated) {
            return <Redirect to="/login" />;
        }
        if (refresh) {
            this.handleFetchBankTransactionsList();
            this.setState({ refresh: false });
        }
        const is_desktop =  getWidth() > Responsive.onlyMobile.maxWidth ? true : false;
        // console.log(this.state);
        let filtered_dc = [];

        // Filter data for search
        if (bank_transactions !== null) {
            filtered_dc = bank_transactions.filter((item) => {
                return item.transaction_party.toLowerCase().indexOf(search.toLocaleLowerCase()) !== -1 ||
                    item.amount.toString().toLowerCase().indexOf(search.toLocaleLowerCase()) !== -1 ||
                    item.invoice_reference.toString().toLowerCase().indexOf(search.toLocaleLowerCase()) !== -1 ||
                    item.archive_code.toString().toLowerCase().indexOf(search.toLocaleLowerCase()) !== -1;
            })
            if (search_start_date !== null) {
                filtered_dc = filtered_dc.filter((item) => {
                    return (new Date(item.value_date) >= new Date(search_start_date))
                })
            }
            if (search_end_date !== null) {
                filtered_dc = filtered_dc.filter((item) => {
                    return (new Date(item.value_date) <= new Date(search_end_date).setDate(new Date(search_end_date).getDate() + 1))
                })
            }
        }

        return (
            <Shell active_customer_id={customer_id} active_customer_name={customer_name}>
                {is_desktop && (
                    <Button.Group floated="right" basic size='small'>
                        <Button icon="download" content="CSV" onClick={() => this.handleExportToCSV(filtered_dc)} />
                        <Button icon="upload" content={t("Import bank transactions")} onClick={() => this.handleImportItems()}/>
                    </Button.Group>
                )}
                {/* <Header as="h4" floated="right" color="blue"><Icon link name="download">export CSV</Icon></Header>
                <div style={{float:'right'}}><Icon link color="blue" name="download"></Icon><b><font color="blue">export CSV</font></b></div> */}
                <Header as="h3">{customer_name} {t("Bank transactions")}</Header>
                {is_desktop ? (
                    <Grid columns="2">
                        <Grid.Column width={9}>
                            <SemanticDatepicker
                                firstDayOfWeek="1"
                                size="mini"
                                disabled={loading}
                                label={t("Value date") + ": "}
                                locale={fiLocale}
                                placeholder={search_start_date !== null ? (new Date(search_start_date).toLocaleString('fi-FI', { dateStyle: 'short' })) : ("")}
                                onDateChange={selected => this.handleStartDateChange(selected)}
                                format='DD.MM.YYYY' />
                            <SemanticDatepicker
                                firstDayOfWeek="1"
                                size="mini"
                                disabled={loading}
                                label="->"
                                locale={fiLocale}
                                placeholder={search_end_date !== null ? (new Date(search_end_date).toLocaleString('fi-FI', { dateStyle: 'short' })) : ("")}
                                onDateChange={selected => this.handleEndDateChange(selected)}
                                format='DD.MM.YYYY' />
                        </Grid.Column>
                        <Grid.Column floated="right" width={6}>
                            <Input
                                size="mini"
                                icon='search'
                                value={search}
                                name="search"
                                onChange={this.handleChange} />
                            {/* <Button type="button" positive size='tiny' floated="right" content={t("+ Add item")} onClick={() => this.props.history.push(`/company/customer-data-collection/item/create/`)} /> */}


                            {/* <Link to={`/customer/${customer_id}/customer-data-collection/item/create/`}>
                                <Button type="button" floated="right" color="green">
                                    {t("+ Add item")}
                                </Button>
                            </Link> */}
                        </Grid.Column>
                    </Grid>
                ) : (
                    <React.Fragment>
                        {/* <Button type="button" positive fluid content={t("+ Add item")} onClick={() => this.props.history.push(`/customer/${customer_id}/customer-data-collection/item/create/`)} /> */}
                        <hr/>
                        <label>{t("Value date")}: </label><br/>
                        <SemanticDatepicker
                            firstDayOfWeek="1"
                            disabled={loading}
                            locale={fiLocale}
                            placeholder={search_start_date !== null ? (new Date(search_start_date).toLocaleString('fi-FI', { dateStyle: 'short' })) : ("")}
                            onDateChange={selected => this.handleStartDateChange(selected)}
                            format='DD.MM.YYYY' />
                        <SemanticDatepicker
                            firstDayOfWeek="1"
                            disabled={loading}
                            locale={fiLocale}
                            placeholder={search_end_date !== null ? (new Date(search_end_date).toLocaleString('fi-FI', { dateStyle: 'short' })) : ("")}
                            onDateChange={selected => this.handleEndDateChange(selected)}
                            format='DD.MM.YYYY' />
                            <Input
                                fluid
                                icon='search'
                                value={search}
                                name="search"
                                onChange={this.handleChange} />
                    </React.Fragment>
                )}
                {error && (
                    <Message
                        error
                        header={t("There was an error")}
                        content={JSON.stringify(error)}
                    />
                )}
                {loading && (
                    <Segment>
                        <Dimmer active inverted>
                            <Loader inverted>Loading</Loader>
                        </Dimmer>
                        <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
                    </Segment>
                )}
                {bank_transactions && (
                    <React.Fragment>
                        <Table striped selectable celled >
                            {is_desktop && (
                                <Table.Header>
                                    <Table.Row>                                    
                                        <Table.HeaderCell>{t("Archive code")}</Table.HeaderCell>    
                                        <Table.HeaderCell>{t("Value date")}</Table.HeaderCell>
                                        <Table.HeaderCell>{t("Payer")}</Table.HeaderCell>
                                        <Table.HeaderCell>{t("Invoice reference")}</Table.HeaderCell>
                                        <Table.HeaderCell>{t("Amount")}</Table.HeaderCell>
                                        <Table.HeaderCell>{t("Related invoice")}</Table.HeaderCell>
                                        <Table.HeaderCell>{t("Import time")}</Table.HeaderCell>
                                        <Table.HeaderCell>{t("Status")}</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                            )}
                            <Table.Body>
                                {filtered_dc.map((item) => {
                                    return (
                                        <Table.Row key={item.id} >
                                            <Table.Cell collapsing>
                                                {!is_desktop && (<React.Fragment><b>{t("Archive code")}</b><br/></React.Fragment>)}
                                                {item.archive_code}
                                            </Table.Cell>
                                            <Table.Cell collapsing>
                                                {!is_desktop && (<React.Fragment><b>{t("Value date")}</b><br/></React.Fragment>)}
                                                {new Date(item.value_date).toLocaleString('fi-FI')}
                                            </Table.Cell>
                                            <Table.Cell collapsing>
                                                {!is_desktop && (<React.Fragment><b>{t("Payer")}</b><br/></React.Fragment>)}
                                                {item.transaction_party}
                                            </Table.Cell>
                                            <Table.Cell collapsing>
                                                {!is_desktop && (<React.Fragment><b>{t("Invoice reference")}</b><br/></React.Fragment>)}
                                                {item.invoice_reference}
                                            </Table.Cell>
                                            <Table.Cell collapsing>
                                                {!is_desktop && (<React.Fragment><b>{t("Amount")}</b><br/></React.Fragment>)}
                                                {item.amount}
                                            </Table.Cell>
                                            <Table.Cell collapsing>
                                                {!is_desktop && (<React.Fragment><b>{t("Related invoice")}</b><br/></React.Fragment>)}
                                                {item.related_invoice.number}
                                            </Table.Cell>
                                            <Table.Cell collapsing>
                                                {!is_desktop && (<React.Fragment><b>{t("Import time")}</b><br/></React.Fragment>)}
                                                {new Date(item.date_created).toLocaleString('fi-FI')}
                                            </Table.Cell>                  
                                            <Table.Cell collapsing>
                                                {!is_desktop && (<React.Fragment><b>{t("Status")}</b><br/></React.Fragment>)}
                                                {item.status}
                                            </Table.Cell>                                        
                      
                                        </Table.Row>
                                    );
                                })}
                            </Table.Body>
                        </Table>
                        <Modal open={this.state.message_modal_open}>
                            <Modal.Header>
                                <Button type="button" negative onClick={() => this.closeModal()} >{t("Close")}</Button>
                            </Modal.Header>
                            <Modal.Content>
                                {this.state.message_modal_text}
                            </Modal.Content>
                            <Modal.Actions>
                                <Button type="button" negative onClick={() => this.closeModal()} >{t("Close")}</Button>
                            </Modal.Actions>
                        </Modal>
                    </React.Fragment>
                )
                }
                <Modal size='large' open={import_csv_modal_open} dimmer="inverted" >
                    <Modal.Header>
                        <Header as="h3">{t("Import bank transactions")}</Header>
                    </Modal.Header>
                    <Modal.Content scrolling>
                        <Container>
                            <Header as="h4">{t("Select file to import")}</Header>
                            <Form error={import_error !== null}>
                                <Form.Field>
                                    <Input disabled={import_disabled} type="file" id="file" accept=".xlsx,.csv,.txt,.xml" onChange={this.handleFileSelect} />
                                </Form.Field>
                                {/* <br/>
                                <i>{t("First column (Source Id) and the second column (Sensor value) are mandatory. Third (Value date/dd.mm.yyyy) and fourth (Author) are optional columns")}</i>&nbsp;
                                <Link onClick={this.handleGetExampleFile}>{t("Example file")}</Link><br/>
                                <i>{t("If file has header row, please select 'First row is header row' option below")}</i><br/>
                                <i><b>{t("Please note")}</b>: {t("Column separator has to be ';', if the file format is CSV (.csv)")}</i>
                                <Form.Field>
                                    <br/>
                                    <Checkbox
                                        disabled={import_disabled}
                                        label={<label>{t("First row is header row")}</label>}
                                        name="first_row_header"
                                        onChange={this.handleFileCheckboxChange}
                                        checked={file.first_row_header}
                                        value={file.first_row_header}
                                    />
                                </Form.Field> */}
                                {import_error && <Message error header={t("Error")} content={import_error} />}
                                <Button primary type="button" content={t("Upload")} onClick={(e) => this.handleUploadFile(e)} loading={loading} disabled={loading || import_disabled}/>
                            </Form>
                            {response_obj && (
                                <React.Fragment>
                                    <Header as="h4">{t("Rows in import file")}: {response_obj.transactions_imported}</Header>
                                    <Header as="h4">{t("Invoices updated")}: {response_obj.invoices_updated}</Header>
                                    <Header as="h4">{t("Rows rejected")}: {response_obj.transactions_rejected}</Header>
                                </React.Fragment>
                            )} 
                        </Container>
                    </Modal.Content>
                    <Modal.Actions>
                        {/* {received_items_list && (
                            <Button type="button" positive floated="left" onClick={() => this.handleSaveImportItems()} >{t("Save new items")}</Button>
                        )} */}
                        <Button type="button" negative onClick={() => this.handleCloseModal()} >{t("Close")}</Button>
                    </Modal.Actions>
                </Modal>
                <Confirm
                    content={delete_confirm_text}
                    open={delete_confirm_open}
                    onCancel={() => this.closeDeleteConfirm()}
                    onConfirm={() => this.handleDelete(delete_item)}
                />

            </Shell >
        );
    }
}
const mapStateToProps = state => {
    return {
        activeCompanyId: state.company.activeCompanyId,
        isAuthenticated: state.auth.token !== null,
        activeRole: state.company.activeRole
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setActivity: () => dispatch(setActivity())
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(withTranslation('companybanktransactions')(CompanyBankTransactions))
);