// Copyright Caproc Oy
// System name: Aqua-Net 
// System module: Company
// Author: TS
//
// 10.7.2020 TS Initial version

import React from "react";
import {
  Button,
  Form,
  Grid,
  Header,
  Segment,
  Select,
  Container
} from "semantic-ui-react";
import { withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import { setActiveRole } from "../../store/actions/company";
import { CompanySelection } from "../../utils";

class RoleSelect extends React.Component {
  state = {
    role: null,
    role_options: [],
    error: null,
    loading: false
  };

  componentDidMount() {
    this.handleOptions();
  }

  handleOptions = () => {
    const { t, activeRoles } = this.props;
    let options = [];
    let i = 0;
    
    for (i in activeRoles) {
      options.push({
        key: activeRoles[i].id,
        text: t(activeRoles[i].role_name),
        value: activeRoles[i]
      })
    }

    this.setState({
      role_options: options
     });
  };

  handleSubmit = () => {
    const { role } = this.state;
    this.props.setActiveRole(role);
    this.props.history.push('/');

  };

  handleSelectChange = (e, { name, value }) => {
    this.setState({
        [name]: value
    });
  };

  render() {
    const { role, role_options, error, loading } = this.state;
    const { isAuthenticated, t } = this.props;
    if (!isAuthenticated) {
        return <Redirect to="/login" />;
    }
    // console.log(this.state);
    return (
      <>
      <Container><CompanySelection/></Container>
      <Grid
        textAlign="center"
        style={{ height: "100vh" }}
        verticalAlign="middle"
      >
        <Grid.Column style={{ maxWidth: 450 }}>
          <Header as="h2" textAlign="center">
            {t("Role select")}
          </Header>
          {error && <p>{error.message}</p>}
          <React.Fragment>
            <Form size="large">
              <Segment stacked>
              <Form.Field required>
                  <Select
                      fluid
                      search
                      name='role'
                      options={role_options}
                      placeholder={this.props.activeRole !== null ? t(this.props.activeRole.role_name) : t("Select role")}
                      onChange={this.handleSelectChange}
                      value={role}
                  />
              </Form.Field>

                <Button
                  primary
                  type="submit" 
                  onClick={() => this.handleSubmit()}
                  fluid
                  size="large"
                  loading={loading}
                  disabled={loading}
                >
                  {t("Save")}
                </Button>
              </Segment>
            </Form>

          </React.Fragment>
        </Grid.Column>
      </Grid>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
      isAuthenticated: state.auth.token !== null,
      activeRoles: state.company.activeRoles,
      activeRole: state.company.activeRole
  };
};

const mapDispatchToProps = dispatch => {
  return {
      setActiveRole: (role) => dispatch(setActiveRole(role))
  };
};

export default withRouter(
  connect(
      mapStateToProps,
      mapDispatchToProps
  )(withTranslation('roleselect')(RoleSelect))
);
