// Copyright Caproc Oy
// System name: Aqua-Net 
// System module: Customer
// Author: TS
//
// 1.2.2020 TS Initial version
// 6.6.2020 TS Changed remove icon to trash bin
// 20.6.2020 TS Added export to CSV


import React from "react";
import { Message, Header, Button, Table, Segment, Dimmer, Loader, Image, Confirm, Grid, Input, Icon, Responsive } from "semantic-ui-react";
import Shell from "./Shell";
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import fiLocale from 'react-semantic-ui-datepickers/dist/locales/fi-FI';
import { authAxios, getISOFormattedDate, exportToCsv, getWidth } from "../../utils";
import { setActivity } from "../../store/actions/auth";
import { withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import { customerTaskListURL, customerTaskDeleteURL, customerDetailsURL } from "../../constants";


class CustomerTasks extends React.Component {
    state = {
        tasks: null,
        customer_name: "",
        error: null,
        loading: false,
        delete_confirm_open: false,
        delete_confirm_text: "",
        delete_item: "",
        search_start_date: null,
        search_end_date: null,
        search: ""
    };

    componentDidMount() {
        this.props.setActivity();
        this.fetchCustomerDetails();
        this.handleFetchList();
    }

    fetchCustomerDetails = () => {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;
        const { customer_id } = this.props.match.params;

        authAxios
            .get(customerDetailsURL(activeCompanyId, customer_id))
            .then(res => {
                this.setState({
                    customer_name: res.data.name,
                    error: null,
                    success_mgs: null,
                    loading: false,
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    success_mgs: null,
                    error: err
                });
            });
    };

    handleFetchList = () => {
        this.setState({
            loading: true,
        });
        const { activeCompanyId } = this.props;
        const { customer_id } = this.props.match.params;
        authAxios
            .get(customerTaskListURL(activeCompanyId, customer_id))
            .then(res => {
                this.setState({
                    tasks: res.data,
                    error: null,
                    loading: false
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    error: err
                });
            });
    };

    taskDetails = (id) => {
        const { customer_id } = this.props.match.params;
        this.props.history.push(`/customer/${customer_id}/task-details/${id}/`)
    };

    openDeleteConfirm = (text, delete_item) => {
        this.setState({
            delete_confirm_open: true,
            delete_confirm_text: text,
            delete_item: delete_item
        });
    };

    closeDeleteConfirm = () => {
        this.setState({
            delete_confirm_open: false,
            delete_confirm_text: "",
            delete_item: ""
        });
    };

    handleDelete = id => {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;
        const { customer_id } = this.props.match.params;
        authAxios
            .delete(customerTaskDeleteURL(activeCompanyId, customer_id, id))
            .then(res => {
                this.setState({
                    error: null,
                    loading: false
                });
                this.handleFetchList();
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    error: err
                });
            });
        this.setState({
            delete_confirm_open: false,
            delete_confirm_text: "",
            delete_item: ""
        });
    };

    handleStartDateChange = value => {
        this.setState({
            search_start_date: getISOFormattedDate(value, "Date")
        });
    };

    handleEndDateChange = value => {
        this.setState({
            search_end_date: getISOFormattedDate(value, "Date")
        });
    };

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    handleExportToCSV = items => {
        const { t } = this.props;
        if (items !== null) {
            const titles = [
                t("Location"),
                t("Category"),
                t("Target"),
                t("Task name"),
                t("Description"),
                t("Operator"),
                t("Date")
            ];
            let items_to_csv = [];
            let i = 0;
            for (i in items) {
                items_to_csv.push({
                    location: items[i].location,
                    category: items[i].category,
                    target: items[i].target,
                    name: items[i].name,
                    description: items[i].description,
                    operator: items[i].operator,
                    task_date: new Date(items[i].task_date).toLocaleString('fi-FI', { dateStyle: 'short' })
                });
            }
            exportToCsv(this.props.activeCompanyId, "export", titles, items_to_csv);
        }
    };

    render() {
        const { error, loading, customer_name, tasks, delete_confirm_open, delete_confirm_text, delete_item, search, search_end_date, search_start_date } = this.state;
        const { isAuthenticated, t } = this.props;
        const { customer_id } = this.props.match.params;
        if (!isAuthenticated) {
            return <Redirect to="/login" />;
        }
        // console.log(this.state);
        const is_desktop =  getWidth() > Responsive.onlyMobile.maxWidth ? true : false;
        let filtered_items = [];

        // Filter data for search
        if (tasks !== null) {
            filtered_items = tasks.filter((task) => {
                return task.location.toLowerCase().indexOf(search.toLocaleLowerCase()) !== -1 ||
                    task.category.toString().toLowerCase().indexOf(search.toLocaleLowerCase()) !== -1 ||
                    task.target.toString().toLowerCase().indexOf(search.toLocaleLowerCase()) !== -1 ||
                    task.name.toString().toLowerCase().indexOf(search.toLocaleLowerCase()) !== -1 ||
                    task.description.toString().toLowerCase().indexOf(search.toLocaleLowerCase()) !== -1 ||
                    task.operator.toString().toLowerCase().indexOf(search.toLocaleLowerCase()) !== -1 ||
                    task.task_date.toString().toLowerCase().indexOf(search.toLocaleLowerCase()) !== -1;
            })
            if (search_start_date !== null) {
                filtered_items = filtered_items.filter((task) => {
                    return (new Date(task.task_date) >= new Date(search_start_date))
                })
            }
            if (search_end_date !== null) {
                filtered_items = filtered_items.filter((task) => {
                    return (new Date(task.task_date) <= new Date(search_end_date).setDate(new Date(search_end_date).getDate()))
                })
            }
        }
        return (
            <Shell active_customer_id={customer_id} active_customer_name={customer_name}>
                {is_desktop && (
                    <Button.Group floated="right" basic size='small'>
                        <Button icon="download" content="CSV" onClick={() => this.handleExportToCSV(filtered_items)} />
                    </Button.Group>
                )}
                <Header as="h3">{customer_name} {t("tasks")}</Header>
                {is_desktop ? (
                    <Grid columns="2">
                        <Grid.Column width={9}>
                            <SemanticDatepicker
                                firstDayOfWeek="1"
                                size="mini"
                                disabled={loading}
                                locale={fiLocale}
                                placeholder={search_start_date !== null ? (new Date(search_start_date).toLocaleString('fi-FI', { dateStyle: 'short' })) : ("")}
                                onDateChange={selected => this.handleStartDateChange(selected)}
                                format='DD.MM.YYYY' />
                            <SemanticDatepicker
                                firstDayOfWeek="1"
                                size="mini"
                                disabled={loading}
                                label="->"
                                locale={fiLocale}
                                placeholder={search_end_date !== null ? (new Date(search_end_date).toLocaleString('fi-FI', { dateStyle: 'short' })) : ("")}
                                onDateChange={selected => this.handleEndDateChange(selected)}
                                format='DD.MM.YYYY' />
                        </Grid.Column>
                        <Grid.Column floated="right" width={6} textAlign="right">
                            <Input
                                size="mini"
                                icon='search'
                                value={search}
                                name="search"
                                onChange={this.handleChange} />
                            <Button type="button" positive size='tiny' content={t("+ Task")} onClick={() => this.props.history.push(`/customer/${customer_id}/task-details/create/`)} />
                        </Grid.Column>
                    </Grid>
                ) : (
                    <React.Fragment>
                        <Button type="button" positive fluid content={t("+ Task")} onClick={() => this.props.history.push(`/customer/${customer_id}/task-details/create/`)} />
                        <SemanticDatepicker
                                firstDayOfWeek="1"
                                fluid
                                disabled={loading}
                                locale={fiLocale}
                                placeholder={search_start_date !== null ? (new Date(search_start_date).toLocaleString('fi-FI', { dateStyle: 'short' })) : ("")}
                                onDateChange={selected => this.handleStartDateChange(selected)}
                                format='DD.MM.YYYY' />
                        <SemanticDatepicker
                                firstDayOfWeek="1"
                                fluid
                                disabled={loading}
                                locale={fiLocale}
                                placeholder={search_end_date !== null ? (new Date(search_end_date).toLocaleString('fi-FI', { dateStyle: 'short' })) : ("")}
                                onDateChange={selected => this.handleEndDateChange(selected)}
                                format='DD.MM.YYYY' />
                        <Input
                                fluid
                                icon='search'
                                value={search}
                                name="search"
                                onChange={this.handleChange} />
                    </React.Fragment>
                )}
                {error && (
                    <Message
                        error
                        header={t("There was an error")}
                        content={JSON.stringify(error)}
                    />
                )}
                {loading && (
                    <Segment>
                        <Dimmer active inverted>
                            <Loader inverted>Loading</Loader>
                        </Dimmer>
                        <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
                    </Segment>
                )}
                {tasks && (
                    <Table striped celled  >
                        {is_desktop && (
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell >{t("Location")}</Table.HeaderCell>
                                    <Table.HeaderCell width="4">{t("Category")}</Table.HeaderCell>
                                    <Table.HeaderCell width="4">{t("Target")}</Table.HeaderCell>
                                    <Table.HeaderCell width="4">{t("Task name")}</Table.HeaderCell>
                                    <Table.HeaderCell width="5">{t("Description")}</Table.HeaderCell>
                                    <Table.HeaderCell width="4">{t("Operator")}</Table.HeaderCell>
                                    <Table.HeaderCell width="4">{t("Date")}</Table.HeaderCell>
                                    <Table.HeaderCell width="2" />
                                </Table.Row>
                            </Table.Header>
                        )}
                        <Table.Body>
                            {filtered_items.map((task, key) => {
                                return (
                                    <Table.Row key={key} >
                                        <Table.Cell onClick={() => this.taskDetails(task.id)}>
                                            {!is_desktop && (<React.Fragment><b>{t("Location")}</b><br/></React.Fragment>)}
                                            {task.location}
                                        </Table.Cell>
                                        <Table.Cell onClick={() => this.taskDetails(task.id)}>
                                            {!is_desktop && (<React.Fragment><b>{t("Category")}</b><br/></React.Fragment>)}
                                            {task.category}
                                        </Table.Cell>
                                        <Table.Cell onClick={() => this.taskDetails(task.id)}>
                                            {!is_desktop && (<React.Fragment><b>{t("Target")}</b><br/></React.Fragment>)}
                                            {task.target}
                                        </Table.Cell>
                                        <Table.Cell onClick={() => this.taskDetails(task.id)}>
                                            {!is_desktop && (<React.Fragment><b>{t("Task name")}</b><br/></React.Fragment>)}
                                            {task.name}
                                            {task.task_files.length > 0 && (<Header as="h6" floated="right" icon="attach" />)}
                                        </Table.Cell>
                                        <Table.Cell onClick={() => this.taskDetails(task.id)}>
                                            {!is_desktop && (<React.Fragment><b>{t("Description")}</b><br/></React.Fragment>)}
                                            {task.description}
                                        </Table.Cell>
                                        <Table.Cell onClick={() => this.taskDetails(task.id)}>
                                            {!is_desktop && (<React.Fragment><b>{t("Operator")}</b><br/></React.Fragment>)}
                                            {task.operator}
                                        </Table.Cell>
                                        <Table.Cell onClick={() => this.taskDetails(task.id)}>
                                            {!is_desktop && (<React.Fragment><b>{t("Date")}</b><br/></React.Fragment>)}
                                            {new Date(task.task_date).toLocaleString('fi-FI', { dateStyle: 'short' })}
                                        </Table.Cell>
                                        <Table.Cell textAlign='center'>
                                            <Icon link color="red" name='trash alternate outline' onClick={() => this.openDeleteConfirm(t("Are you sure your want to delete", { name: task.name }), task.id)}/>
                                            {/* <Button type="button" compact negative size='mini' icon='remove' onClick={() => this.openDeleteConfirm(t("Are you sure your want to delete", { name: task.name }), task.id)} /> */}
                                        </Table.Cell>
                                    </Table.Row>
                                );
                            })}
                        </Table.Body>
                    </Table>
                )}
                <Confirm
                    content={delete_confirm_text}
                    open={delete_confirm_open}
                    onCancel={() => this.closeDeleteConfirm()}
                    onConfirm={() => this.handleDelete(delete_item)}
                />
            </Shell>
        );
    }
}
const mapStateToProps = state => {
    return {
        activeCompanyId: state.company.activeCompanyId,
        isAuthenticated: state.auth.token !== null
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setActivity: () => dispatch(setActivity())
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(withTranslation('customertasks')(CustomerTasks))
);