// Copyright Caproc Oy
// System name: Aqua-Net 
// System module: Invoice
// Author: TS
//
// 23.6.2021 TS Initial version for multiple customers
// 11.4.2022 TS Changed due date from disabled input field to date picker. Added booking date field.

import React from "react";
import { Form, Input, Message, Header, Button, Select, Grid, Table, Modal, Container, Checkbox, Icon, Popup, Responsive, Accordion, Radio } from "semantic-ui-react";
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import fiLocale from 'react-semantic-ui-datepickers/dist/locales/fi-FI';
import { authAxios, getItemGrossPrice, getItemNetPrice, getTotalGrossPrice, getTotalNetPrice, getISOFormattedDate, getWidth, roundFloat } from "../../utils";
import { setActivity } from "../../store/actions/auth";
import { withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import {
    fetchCustomersURL,
    invoicingDetailsURL,
    invoiceDetailsURL,
    modifyInvoiceDetailsURL,
    companyActiveUsersListURL,
    customerInvoicingDetailsURL,
    createInvoiceItemURL,
    updateInvoiceItemURL,
    deleteInvoiceItemURL,
    customerDataCollectionsURL,
    automaticSendingModeOptionsURL,
    customerActiveContractsURL,
    vatValueOptionsURL,
    fetchProductsURL,
    createInvoiceForMultipleCustomersURL,
    customerInvoicingMethodOptionsURL
} from "../../constants";
import Shell from "./Shell";


class InvoiceDetailsMultipleCustomers extends React.Component {
    state = {
        invoice_id: null,
        invoice_details: {
            id: "",
            customer: "",
            name: "",
            status: "",
            date: "",
            due_date: "",
            payment_term: 14,
            sales_person: "",
            number: "",
            comment: "",
            no_vat_comment: "",
            reference_number: "",
            customer_reference: "",
            company_reference: "",
            invoiceitems: "",
            invoicing_method: "P",
            author: "",
            date_created: "",
            last_modified: "",
            date_sent: "",
            date_paid: "",
            is_automatic_sending: false,
            automatic_sending_mode: "A",
            contract: "",
            project: null,
            purchase_order: "",
            contract_number_on_invoice: "",
            invoice_type: "N",
            use_customer_payment_term: false,
            use_customer_contract_number: false,
            use_customer_invoice_comment: false,
            use_customer_company_reference: false,
            use_customer_customer_reference: false,
            use_customer_no_vat: false,
            use_customer_no_vat_comment: false,
            invoice_period_start_date: null,
            invoice_period_end_date: null,
            add_invoicing_period: false,
            use_customer_invoicing_method: false,
            booking_date: null,
            value_date: null
        },
        active_customer_invoicing_details: {
            invoicing_details_id: "",
            customer_id: "",
            payment_term: "",
            default_customer_reference: "",
            default_company_reference: "",
            default_contract_number: "",
            default_invoice_comment: "",
            no_vat: false,
            no_vat_comment: "",
            discount: "",
            invoicing_method: "",
            invoicing_email: ""
        },
        update_due_date: false,
        active_customer_data_collections: null,
        invoice_items_list: [],
        selected_products_list: [],
        invoice_item_details_modal_open: false,
        active_invoice_item: [],
        active_invoice_item_name: "",
        selected_item_for_edit: [],
        invoice_items_select_modal_open: false,
        customer_options: null,
        // product_groups: null,
        products: null,
        company_users: null,
        fields_disabled: true,
        customer_select_disabled: true,
        error: null,
        success_mgs: null,
        loading: false,
        create: null,
        copy_invoice: null,
        leave_page: false,
        leave_page_to: "",
        automatic_sending_mode_options: [],
        customer_contract_options: null,
        vat_value_options: [],
        no_vat_invoice: false,
        search: "",
        // For multiple customers
        customers_select_modal_open: false,
        selected_customers_list: [],
        customers_list: [],
        all_customers_selected: false,
        activeIndex: 0,
        customer_search: "",
        itemTypeSelectRadioGroup: "N",
        invoicing_method_options: []

    };

    componentDidMount() {
        this.props.setActivity();
        this.handleFetchCompanyUsers();
        this.handleFetchCustomers();
        // this.handleFetchProductGroupList();
        this.handleFetchProductList();
        this.handleFetchAutomaticSendingModeOptions();
        this.handleFetchVatValueOptions();
        this.handleCompanyInvoicingDetails();
        this.fetchInvoicingMethodOptions();
        if (this.props.match.params.action_type === "create") {
            this.handleDateChange(new Date());
            this.setState({
                create: true,
                customer_select_disabled: false
            })
        } else if (this.props.match.params.action_type === "copy") {
            this.handleDateChange(new Date());
            this.setState({
                copy_invoice: true,
                customer_select_disabled: false
            })
            this.handleDetails();
        } else {
            this.handleDetails();
        }
    }

    handleFetchAutomaticSendingModeOptions() {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;

        authAxios
            .get(automaticSendingModeOptionsURL(activeCompanyId))
            .then(res => {
                this.setState({
                    automatic_sending_mode_options: this.handleFormatAMSOptions(res.data),
                    error: null,
                    success_mgs: null,
                    loading: false,
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    success_mgs: null,
                    error: err
                });
            });
    };

    handleFormatAMSOptions = options => {
        const { t } = this.props;
        const keys = Object.keys(options);
        return keys.map(k => {
            return {
                key: options[k].value,
                text: t(options[k].name),
                value: options[k].value
            };
        });
    };


    handleFetchVatValueOptions() {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;

        authAxios
            .get(vatValueOptionsURL(activeCompanyId))
            .then(res => {
                this.setState({
                    vat_value_options: this.handleFormatVatValueOptions(res.data),
                    error: null,
                    success_mgs: null,
                    loading: false,
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    success_mgs: null,
                    error: err
                });
            });
    };

    handleFormatVatValueOptions = options => {
        const keys = Object.keys(options);
        return keys.map(k => {
            return {
                key: options[k].value,
                text: options[k].name,
                value: parseFloat(options[k].value)
            };
        });
    };

    handleFetchCompanyUsers() {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;

        authAxios
            .get(companyActiveUsersListURL(activeCompanyId))
            .then(res => {
                this.setState({
                    company_users: this.handleFormatUserOptions(res.data),
                    error: null,
                    success_mgs: null,
                    loading: false,
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    success_mgs: null,
                    error: err
                });
            });
    };

    handleFormatUserOptions = options => {
        const { t } = this.props;
        let i = 0;
        let options_list = [];
        options_list.push({
            key: 0,
            text: t("No sales person"),
            value: null
        })
        for (i in options) {
            options_list.push({
                key: options[i].id,
                text: (options[i].first_name + " " + options[i].last_name),
                value: options[i].id
            })
        }
        return options_list;
    };

    handleFetchCustomerContracts(customer_id) {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;

        authAxios
            .get(customerActiveContractsURL(activeCompanyId, customer_id))
            .then(res => {
                this.setState({
                    customer_contract_options: this.handleFormatContractOptions(res.data),
                    error: null,
                    success_mgs: null,
                    loading: false,
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    success_mgs: null,
                    error: err
                });
            });
    };

    handleFormatContractOptions = options => {
        const { t } = this.props;
        let i = 0;
        let options_list = [];
        options_list.push({
            key: 0,
            text: t("No contract"),
            value: null
        })
        for (i in options) {
            // Get only active ones..
            if (options[i].is_active) {
                options_list.push({
                    key: options[i].id,
                    text: options[i].name,
                    value: options[i]
                })
            }
        }
        return options_list;
    };

    handleFetchCustomers() {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;

        authAxios
            .get(fetchCustomersURL(activeCompanyId))
            .then(res => {
                this.setState({
                    // customer_options: this.handleFormatOptions(res.data),
                    customer_options: this.handleKeepOnlyActiveCustomers(res.data),
                    error: null,
                    success_mgs: null,
                    loading: false,
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    success_mgs: null,
                    error: err
                });
            });
    };

    handleKeepOnlyActiveCustomers = customers => {
        let i = 0;
        let active_list = [];
        for (i in customers) {
            // Get only active ones..
            if (customers[i].is_active) {
                active_list.push(customers[i]);
            }
        }
        return active_list;
    };

    handleFormatOptions = options => {
        let i = 0;
        let options_list = [];
        for (i in options) {
            // Get only active ones..
            if (options[i].is_active) {
                options_list.push({
                    key: options[i].id,
                    text: options[i].name,
                    value: options[i].id
                })
            }
        }
        return options_list;
    };

    handleFetchProductList = () => {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;
        authAxios
            .get(fetchProductsURL(activeCompanyId))
            .then(res => {
                this.setState({
                    products: res.data,
                    error: null,
                    loading: false
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    error: err
                });
            });
    };

    // Fetch company invoicing details for initial payment term
    handleCompanyInvoicingDetails = () => {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;
        authAxios
            .get(invoicingDetailsURL(activeCompanyId))
            .then(res => {
                if (this.state.create) {
                    const { invoice_details } = this.state;
                    const updatedInvoiceDetails = {
                        ...invoice_details,
                        payment_term: res.data.payment_term,
                    };
                    this.setState({
                        invoice_details: updatedInvoiceDetails,
                        success_mgs: null,
                        error: null
                    });
                }
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    success_mgs: null,
                    error: err
                });
            });
    };


    // Fetch invoicing details for selected customer
    handleCustomerInvoicingDetails = (customer_id) => {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;
        authAxios
            .get(customerInvoicingDetailsURL(activeCompanyId, customer_id))
            .then(res => {
                this.setState({
                    active_customer_invoicing_details: res.data,
                    error: null,
                    success_mgs: null,
                    loading: false
                });
                if (this.state.create) {
                    // Store default payment term from customer invoicing details to invoicing details.
                    const { invoice_details } = this.state;
                    const updatedInvoiceDetails = {
                        ...invoice_details,
                        payment_term: res.data.payment_term,
                        company_reference: res.data.default_company_reference,
                        customer_reference: res.data.default_customer_reference,
                        no_vat_comment: res.data.no_vat_comment,
                        comment: res.data.default_invoice_comment,
                        contract_number_on_invoice: res.data.default_contract_number
                    };
                    this.setState({
                        invoice_details: updatedInvoiceDetails,
                        no_vat_invoice: res.data.no_vat,
                        update_due_date: true,
                        success_mgs: null,
                        error: null
                    });
                }
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    success_mgs: null,
                    error: err
                });
            });
    };

    handleGetCustomerDataCollections = customer_id => {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;
        authAxios
            .get(customerDataCollectionsURL(activeCompanyId, customer_id))
            .then(res => {
                this.setState({
                    active_customer_data_collections: res.data,
                    error: null,
                    success_mgs: null,
                    loading: false
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    success_mgs: null,
                    error: err
                });
            });
    };


    handleDetails = () => {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;
        const { invoice_id } = this.props.match.params;
        authAxios
            .get(invoiceDetailsURL(activeCompanyId, invoice_id))
            .then(res => {
                this.setState({
                    invoice_details: res.data,
                    invoice_items_list: this.handleSeqAndSort(res.data.invoiceitems),
                    no_vat_invoice: this.checkIfNoVatInvoice(res.data.invoiceitems),
                    fields_disabled: false,
                    error: null,
                    success_mgs: null,
                    loading: false
                });
                this.handleCustomerInvoicingDetails(res.data.customer.id);
                // Fetch data collections list for items modal
                this.handleGetCustomerDataCollections(res.data.customer.id);
                this.handleFetchCustomerContracts(res.data.customer.id);
                if (this.state.copy_invoice) {
                    this.handleClearIdsForInvoiceCopy();
                }
            })
            .catch(err => {
                this.setState({
                    invoice_details: null,
                    loading: false,
                    success_mgs: null,
                    error: err
                });
            });
    };

    handleSeqAndSort = items => {
        // Assign sequence numbers just in case items have been deleted from the list and invoice has not been saved.
        let i = 0;
        for (i in items) {
            items[i].sequence_on_invoice = parseInt(i) + 1;
        }
        return items.sort(this.compareValuesForSort('sequence_on_invoice'));
    };

    handleClearIdsForInvoiceCopy = () => {
        const updatedInvoice_details = {
            ...this.state.invoice_details,
            id: ""
        }
        let updatedItems = this.state.invoice_items_list;
        let i = 0;
        for (i in updatedItems) {
            updatedItems[i].id = "";
            updatedItems[i].invoice = "";
        }
        this.setState({
            invoice_details: updatedInvoice_details,
            invoice_items_list: updatedItems
        });
    };

    checkIfNoVatInvoice = invoice_items_list => {
        let no_vat = true;
        let i = 0;
        for (i in invoice_items_list) {
            if (invoice_items_list[i].vat_value > 0) {
                no_vat = false;
            }
        }
        return no_vat;
    };

    handleItemDetailsChange = e => {
        const { t } = this.props;
        if (e.target.name === "amount" && isNaN(e.target.value)) {
            this.setState({
                error: t("Amount value is not number")
            });
        } else if (e.target.name === "net_price" && isNaN(e.target.value)) {
            this.setState({
                error: t("Net price is not number")
            });
        } else if (e.target.name === "discount" && isNaN(e.target.value)) {
            this.setState({
                error: t("Discount is not number")
            });
        } else if (e.target.name === "vat_value" && isNaN(e.target.value)) {
            this.setState({
                error: t("Vat value is not number")
            });
        } else {
            this.setState({
                error: null
            });
        }
        const { active_invoice_item, no_vat_invoice } = this.state;
        const updatedItemDetails = {
            ...active_invoice_item,
            [e.target.name]: e.target.value,
        };
        this.setState({
            active_invoice_item: updatedItemDetails,
            success_mgs: null
        });
    };

    handleItemDetailsSelectChange = (e, { name, value }) => {
        const { active_invoice_item } = this.state;
        const updatedItemDetails = {
            ...active_invoice_item,
            [name]: value
        };
        this.setState({
            active_invoice_item: updatedItemDetails,
            error: null
        });
    };

    handleItemDetailsRadioChange = (e, { name, value }) => {
        const { active_invoice_item, active_invoice_item_name } = this.state;
        const { t } = this.props;
        let item_name_value = active_invoice_item_name;
        if (value === "D")
        {
            item_name_value = item_name_value + " " + t("REAL CONSUMPTION");
        }
        else if (value === "E")
        {
            item_name_value = item_name_value + " " + t("ESTIMATE");
        }
        else if (value === "B")
        {
            item_name_value = item_name_value + " " + t("INVOICED ESTIMATE");
        }

        const updatedItemDetails = {
            ...active_invoice_item,
            [name]: value,
            name: item_name_value
        };
        this.setState({
            active_invoice_item: updatedItemDetails,
            itemTypeSelectRadioGroup: value,
            error: null
        });
    };

    handleItemDetailsCheckboxChange = (e, { name, value }) => {
        const { active_invoice_item } = this.state;
        const updatedItemDetails = {
            ...active_invoice_item,
            [name]: !value
        };
        this.setState({
            active_invoice_item: updatedItemDetails,
            error: null
        });
    };

    handleInvoiceItemPeriodStartDateChange = value => {
        const { active_invoice_item } = this.state;
        const updatedItemDetails = {
            ...active_invoice_item,
            invoice_period_start_date: value === null ? null : getISOFormattedDate(new Date(value), "Date"),
        };
        this.setState({
            active_invoice_item: updatedItemDetails,
            error: null
        });
    };

    handleInvoiceItemPeriodEndDateChange = value => {
        const { active_invoice_item } = this.state;
        const updatedItemDetails = {
            ...active_invoice_item,
            invoice_period_end_date: value === null ? null : getISOFormattedDate(new Date(value), "Date"),
        };
        this.setState({
            active_invoice_item: updatedItemDetails,
            error: null
        });
    };

    handleChange = e => {
        const { invoice_details } = this.state;
        const updatedInvoiceDetails = {
            ...invoice_details,
            [e.target.name]: e.target.value,
        };
        this.setState({
            invoice_details: updatedInvoiceDetails,
            success_mgs: null,
            error: null,
            update_due_date: (e.target.name === "payment_term" ? true : false)
        });
    };

    handleItemSearchChange = e => {
        this.setState({
            [e.target.name]: e.target.value,
            error: null
        });
    };

    handleSelectChange = (e, { name, value }) => {

        if (name === 'customer') {
            // Fetch customer invoicing details for selected customer. Value = customer id.
            this.handleCustomerInvoicingDetails(value);
            // Fetch data collections list for items modal
            this.handleGetCustomerDataCollections(value);
            this.handleFetchCustomerContracts(value);
            this.setState({ fields_disabled: false })
        }

        const { invoice_details } = this.state;
        const updatedInvoiceDetails = {
            ...invoice_details,
            [name]: value,
            contract_number_on_invoice: (name === "contract" && value !== null) ? (value.contract_number_on_invoice !== null ? value.contract_number_on_invoice : "") : invoice_details.contract_number_on_invoice
        };

        this.setState({
            invoice_details: updatedInvoiceDetails,
            error: null
        });
    };

    handleSetContractNumberOnInvoice = contract => {
        const { invoice_details } = this.state;
        const updatedInvoiceDetails = {
            ...invoice_details,
            contract_number_on_invoice: contract.contract_number_on_invoice
        };
        this.setState({
            invoice_details: updatedInvoiceDetails
        });
    }

    handleDateChange = value => {
        const { invoice_details } = this.state;
        let tmp_due_date = new Date(value);
        tmp_due_date.setDate(tmp_due_date.getDate() + parseInt(invoice_details.payment_term));
        const new_due_date = tmp_due_date.getFullYear() + "-" + (tmp_due_date.getMonth() + 1) + "-" + tmp_due_date.getDate();
        let tmp_date = new Date(value);
        const new_date = tmp_date.getFullYear() + "-" + (tmp_date.getMonth() + 1) + "-" + tmp_date.getDate();
        const updatedInvoiceDetails = {
            ...invoice_details,
            date: new_date,
            due_date: new_due_date
        };
        this.setState({
            invoice_details: updatedInvoiceDetails,
            update_due_date: false,
            error: null
        });
    };

    handleDueDateChange = value => {
        const { invoice_details } = this.state;
        const updatedInvoiceDetails = {
            ...invoice_details,
            due_date: getISOFormattedDate(new Date(value), "Date"),
        };
        this.setState({
            invoice_details: updatedInvoiceDetails,
            error: null
        });
    };

    handleBookingDateChange = value => {
        const { invoice_details } = this.state;
        const updatedInvoiceDetails = {
            ...invoice_details,
            booking_date: getISOFormattedDate(new Date(value), "Date"),
        };
        this.setState({
            invoice_details: updatedInvoiceDetails,
            error: null
        });
    };

    handlePaidDateChange = value => {
        const { invoice_details } = this.state;
        const updatedInvoiceDetails = {
            ...invoice_details,
            date_paid: getISOFormattedDate(new Date(value), "DateTime"),
        };
        this.setState({
            invoice_details: updatedInvoiceDetails,
            error: null
        });
    };

    handleSentDateChange = value => {
        const { invoice_details } = this.state;
        const updatedInvoiceDetails = {
            ...invoice_details,
            date_sent: getISOFormattedDate(new Date(value), "DateTime"),
        };
        this.setState({
            invoice_details: updatedInvoiceDetails,
            error: null
        });
    };

    handleInvoicePeriodStartDateChange = value => {
        const { invoice_details } = this.state;
        const updatedInvoiceDetails = {
            ...invoice_details,
            invoice_period_start_date: value === null ? null : getISOFormattedDate(new Date(value), "Date"),
        };
        this.setState({
            invoice_details: updatedInvoiceDetails,
            error: null
        });
    };

    handleInvoicePeriodEndDateChange = value => {
        const { invoice_details } = this.state;
        const updatedInvoiceDetails = {
            ...invoice_details,
            invoice_period_end_date: value === null ? null : getISOFormattedDate(new Date(value), "Date"),
        };
        this.setState({
            invoice_details: updatedInvoiceDetails,
            error: null
        });
    };

    handleCheckboxChange = (e, { name, value }) => {
        const { invoice_details } = this.state;
        const updatedDetails = {
            ...invoice_details,
            [name]: !value
        };
        this.setState({
            invoice_details: updatedDetails,
            error: null
        });
    };

    handleNoVatCheckboxChange = (e, { name, value }) => {
        this.setState({
            [name]: !value,
            error: null
        });
    };

    handleSubmit = e => {
        e.preventDefault();
        this.setState({
            loading: true
        });
        const { create, copy_invoice, customers_list, invoice_items_list } = this.state;
        let { invoice_details, no_vat_invoice } = this.state;
        const { activeCompanyId, t } = this.props;
        if (invoice_details.name === "") {
            this.setState({
                error: t("Please fill in invoice name"),
                success_mgs: null,
                loading: false
            });
        } else if (invoice_details.name.length > 35) {
            this.setState({
                error: t("Max amount of characters is 100 in Name field"),
                success_mgs: null,
                loading: false
            });
        } else if (invoice_details.comment.length > 100) {
            this.setState({
                error: t("Max amount of characters is 200 in Comment field"),
                success_mgs: null,
                loading: false
            });
        } else if (invoice_details.customer_reference.length > 50) {
            this.setState({
                error: t("Max amount of characters is 50 in Customer reference field"),
                success_mgs: null,
                loading: false
            });
        } else if (invoice_details.company_reference.length > 50) {
            this.setState({
                error: t("Max amount of characters is 50 in Company reference field"),
                success_mgs: null,
                loading: false
            });
        } else if (invoice_details.purchase_order.length > 70) {
            this.setState({
                error: t("Max amount of characters is 70 in Purchase order field"),
                success_mgs: null,
                loading: false
            });
        } else if (invoice_details.date === "") {
            this.setState({
                error: t("Please fill in date"),
                success_mgs: null,
                loading: false
            });
        } else if (invoice_details.due_date === "") {
            this.setState({
                error: t("Please fill in due date"),
                success_mgs: null,
                loading: false
            });
        } else if (invoice_details.add_invoicing_period && (invoice_details.invoice_period_start_date === "" || invoice_details.invoice_period_start_date === null)) {
            this.setState({
                error: t("Please fill in invoicing period start date"),
                success_mgs: null,
                loading: false
            });
        } else if (invoice_details.add_invoicing_period && (invoice_details.invoice_period_end_date === "" || invoice_details.invoice_period_end_date === null)) {
            this.setState({
                error: t("Please fill in invoicing period end date"),
                success_mgs: null,
                loading: false
            });
        } else if (invoice_details.payment_term === "") {
            this.setState({
                error: t("Please fill in payment term"),
                success_mgs: null,
                loading: false
            });
        } else if (this.state.invoice_items_list.length === 0) {
            this.setState({
                error: t("Invoice has to have at least one product / invoice item."),
                success_mgs: null,
                loading: false
            });
        } else if (no_vat_invoice && !this.checkIfNoVatInvoice(this.state.invoice_items_list)) {
            this.setState({
                error: t("Invoice marked as VAT 0%/Reverse VAT, but some items have VAT other than zero. Please check that the whole invoice is zero VAT."),
                success_mgs: null,
                loading: false
            });
        } else if (no_vat_invoice && invoice_details.no_vat_comment === "") {
            this.setState({
                error: t("Please fill in No VAT comment for VAT 0% invoice"),
                success_mgs: null,
                loading: false
            });
            // } else if (!this.validateInvoiceItemListTaxes(this.state.invoice_items_list)) {
            //     this.setState({
            //         error: t("Invoice cannot have no tax and tax items. The whole invoice has to be either 0% tax or with tax."),
            //         success_mgs: null,
            //         loading: false
            //     });
        } else {
            if (create || copy_invoice) {
                const updated_invoice_details = {
                    ...invoice_details,
                    author: localStorage.getItem("UserID"),
                    invoice_period_start_date: invoice_details.add_invoicing_period ? invoice_details.invoice_period_start_date : null,
                    invoice_period_end_date: invoice_details.add_invoicing_period ? invoice_details.invoice_period_end_date : null
                    // customer: invoice_details.customer.id === undefined ? invoice_details.customer : invoice_details.customer.id,
                    // contract: (copy_invoice && (invoice_details.contract === null || invoice_details.contract.id === undefined)) ? null : (create ? invoice_details.contract : ({ id: invoice_details.contract.id }))
                }

                authAxios
                    .post(createInvoiceForMultipleCustomersURL(activeCompanyId), {
                        invoice_details: updated_invoice_details,
                        customers: customers_list,
                        invoice_items: invoice_items_list
                    })
                    .then(res => {
                        this.setState({
                            loading: false,
                            leave_page: true,
                            leave_page_to: "C"
                        });
                    })
                    .catch(err => {
                        this.setState({
                            loading: false,
                            success_mgs: null,
                            error: err
                        });
                    });


                // authAxios
                //     .post(createInvoiceURL(activeCompanyId), {
                //         ...updated_invoice_details
                //     })
                //     .then(res => {
                //         invoice_details.id = res.data.invoice_id;
                //         // Create invoice items
                //         let { invoice_items_list } = this.state;
                //         let i = 0;
                //         for (i in invoice_items_list) {
                //             // Add invoice id from just created invoice and add to all items.
                //             invoice_items_list[i]['invoice'] = res.data.invoice_id;
                //             invoice_items_list[i]['author'] = localStorage.getItem("UserID");
                //             authAxios
                //                 .post(createInvoiceItemURL(activeCompanyId, res.data.invoice_id), {
                //                     ...invoice_items_list[i],
                //                     net_price: parseFloat(invoice_items_list[i]['net_price']).toFixed(2),
                //                     amount: parseFloat(invoice_items_list[i]['amount']).toFixed(2)
                //                 })
                //                 .then(res => {
                //                 })
                //                 .catch(err => {
                //                     this.setState({
                //                         loading: false,
                //                         success_mgs: null,
                //                         error: err
                //                     });
                //                     return;
                //                 });
                //         }
                //         this.setState({
                //             leave_page: true,
                //             leave_page_to: "C"
                //         });
                //     })
                //     .catch(err => {
                //         this.setState({
                //             loading: false,
                //             success_mgs: null,
                //             error: err
                //         });
                //     });

            } else {
                authAxios
                    .put(modifyInvoiceDetailsURL(activeCompanyId, invoice_details.id), {
                        ...invoice_details,
                        customer: invoice_details.customer.id,
                        contract: (invoice_details.contract === null || invoice_details.contract.id === undefined) ? null : invoice_details.contract.id,
                        contract_number_on_invoice: invoice_details.contract_number_on_invoice === null ? "" : invoice_details.contract_number_on_invoice,
                        invoice_period_start_date: invoice_details.add_invoicing_period ? invoice_details.invoice_period_start_date : null,
                        invoice_period_end_date: invoice_details.add_invoicing_period ? invoice_details.invoice_period_end_date : null

                    })
                    .then(res => {
                        // Update also invoice items
                        let { invoice_items_list } = this.state;
                        let i = 0;
                        for (i in invoice_items_list) {
                            // id is empty if this is new item added to the list
                            if (!invoice_items_list[i].id) {
                                invoice_items_list[i]['invoice'] = invoice_details.id;
                                authAxios
                                    .post(createInvoiceItemURL(activeCompanyId, invoice_details.id), {
                                        ...invoice_items_list[i],
                                        net_price: parseFloat(invoice_items_list[i]['net_price']).toFixed(2),
                                        amount: parseFloat(invoice_items_list[i]['amount']).toFixed(2)
                                    })
                                    .then(res => {
                                    })
                                    .catch(err => {
                                        this.setState({
                                            loading: false,
                                            success_mgs: null,
                                            error: err
                                        });
                                        return;
                                    });

                            } else {
                                // Update if existing item
                                authAxios
                                    .put(updateInvoiceItemURL(activeCompanyId, invoice_details.id, invoice_items_list[i].id), {
                                        ...invoice_items_list[i],
                                        net_price: parseFloat(invoice_items_list[i]['net_price']).toFixed(2),
                                        amount: parseFloat(invoice_items_list[i]['amount']).toFixed(2)
                                    })
                                    .then(res => {
                                    })
                                    .catch(err => {
                                        this.setState({
                                            loading: false,
                                            success_mgs: null,
                                            error: err
                                        });
                                        return;
                                    });
                            }
                        }
                        this.setState({
                            leave_page: true,
                            leave_page_to: invoice_details.status
                        });
                    })
                    .catch(err => {
                        this.setState({
                            loading: false,
                            success_mgs: null,
                            error: err
                        });
                    });
            }
        }
    };

    openItemDetailsModal = item => {
        this.setState({
            invoice_item_details_modal_open: true,
            active_invoice_item: item,
            active_invoice_item_name: item.name,
            selected_item_for_edit: item,
            itemTypeSelectRadioGroup: item.item_type,
            error: null
        })
    };

    closeItemDetailsModal = () => {
        this.setState({
            invoice_item_details_modal_open: false,
            active_invoice_item: [],
            active_invoice_item_name: "",
            selected_item_for_edit: []
        })
    };

    handleItemDetailsSubmit = () => {
        const { t } = this.props;
        const { active_invoice_item, selected_item_for_edit, error } = this.state;
        if (active_invoice_item.name === "") {
            this.setState({
                error: t("Please fill in item Name"),
                success_mgs: null,
                loading: false
            });
        } else if (active_invoice_item.name.length > 100) {
            this.setState({
                error: t("Max amount of characters is 100 in item Name field"),
                success_mgs: null,
                loading: false
            });
        } else if (active_invoice_item.amount === "") {
            this.setState({
                error: t("Please fill in item Amount"),
                success_mgs: null,
                loading: false
            });
        } else if (active_invoice_item.net_price === "") {
            this.setState({
                error: t("Please fill in item Net price"),
                success_mgs: null,
                loading: false
            });
        } else if (active_invoice_item.discount === "") {
            this.setState({
                error: t("Please fill in item Discount"),
                success_mgs: null,
                loading: false
            });
        } else if (active_invoice_item.vat_value === "") {
            this.setState({
                error: t("Please fill in item Vat value"),
                success_mgs: null,
                loading: false
            });
        } else if ((active_invoice_item.item_type === "E" || active_invoice_item.item_type === "B") && (active_invoice_item.invoice_period_start_date === "" || active_invoice_item.invoice_period_start_date === null)) {
            this.setState({
                error: t("Please fill in invoice period start date"),
                success_mgs: null,
                loading: false
            });
        } else if ((active_invoice_item.item_type === "E" || active_invoice_item.item_type === "B") && (active_invoice_item.invoice_period_end_date === "" || active_invoice_item.invoice_period_end_date === null)) {
            this.setState({
                error: t("Please fill in invoice period end date"),
                success_mgs: null,
                loading: false
            });
        } else {
            if (!error) {
                // if (active_invoice_item.vat_value === parseFloat(0)) {
                //     this.setState({
                //         no_vat_invoice: true
                //     });
                // }
                this.handleItemsListChange(selected_item_for_edit, active_invoice_item, "update");
                this.closeItemDetailsModal();
            }
        }
    }

    openItemsAddModal = () => {
        this.setState({
            invoice_items_select_modal_open: true,
            error: null
        })
    };

    closeItemsAddModal = () => {
        this.setState({
            invoice_items_select_modal_open: false,
            selected_products_list: [],
        })
    };

    handleItemsListChange = (item, updated_item, action) => {
        let { invoice_items_list } = this.state;
        const { activeCompanyId } = this.props;
        if (action === "add") {
            if (!invoice_items_list.includes(item)) {
                invoice_items_list.push(item);
            }
        } else if (action === "remove") {
            if (invoice_items_list.includes(item)) {
                invoice_items_list.splice(invoice_items_list.indexOf(item), 1);
                // If this item is already saved in the db (has id defined), Delete it.
                if (item.id) {
                    authAxios
                        .delete(deleteInvoiceItemURL(activeCompanyId, item.invoice, item.id), {
                        })
                        .then(res => {
                        })
                        .catch(err => {
                            this.setState({
                                loading: false,
                                success_mgs: null,
                                error: err
                            });
                            return;
                        });
                }
                // Rearrange sequence_on_invoice numbers
                invoice_items_list = this.handleSeqAndSort(invoice_items_list);

            }
        } else if (action === "update") {
            if (invoice_items_list.includes(item)) {
                invoice_items_list.splice(invoice_items_list.indexOf(item), 1, updated_item);
            }
        }
        this.setState({ invoice_items_list });
    };

    handleAddItemsCheckboxChange = (e, { value, checked }) => {
        let { selected_products_list } = this.state;
        if (checked) {
            if (!selected_products_list.includes(value)) {
                selected_products_list.push(value);
            }
        } else {
            if (selected_products_list.includes(value)) {
                selected_products_list.splice(selected_products_list.indexOf(value), 1);
            }
        }
        this.setState({ selected_products_list });
    };

    handleAddItemsToInvoice = () => {
        const { selected_products_list, active_customer_invoicing_details, no_vat_invoice, invoice_details } = this.state;
        const { activeCompanyId } = this.props;
        const last_sequence_number = this.state.invoice_items_list ? this.state.invoice_items_list.length : 0;
        let i = 0;
        for (i in selected_products_list) {
            let new_invoice_item = {};
            new_invoice_item['company'] = activeCompanyId;
            // Pick invoice id in handleSubmit after the invoice has been created.

            // If item in a list has source_id -> data collection item. Else normal item
            if (selected_products_list[i].source_id !== undefined) {
                new_invoice_item['customer_data_collection_detail'] = selected_products_list[i].id;
                new_invoice_item['name'] = selected_products_list[i].product.name;
                new_invoice_item['product'] = selected_products_list[i].product.id;
                new_invoice_item['description'] = selected_products_list[i].product.description;
                new_invoice_item['code'] = selected_products_list[i].product.code;
                new_invoice_item['net_price'] = selected_products_list[i].product.net_price;
                new_invoice_item['measure_unit'] = selected_products_list[i].product.measure_unit;
                if (no_vat_invoice) {
                    new_invoice_item['vat_value'] = 0;
                } else {
                    new_invoice_item['vat_value'] = selected_products_list[i].product.vat_value;
                }

                new_invoice_item['item_type'] = "D"; // Data collection product type
                // Calculate initial amount base on lastest collected value and last invoiced value
                new_invoice_item['amount'] = parseFloat(selected_products_list[i].last_value) - parseFloat(selected_products_list[i].last_invoiced_value);
                new_invoice_item['recurring'] = selected_products_list[i].product.recurring;
            } else {
                new_invoice_item['name'] = selected_products_list[i].name;
                new_invoice_item['product'] = selected_products_list[i].id;
                new_invoice_item['description'] = selected_products_list[i].description;
                new_invoice_item['code'] = selected_products_list[i].code;
                new_invoice_item['net_price'] = selected_products_list[i].net_price;
                new_invoice_item['measure_unit'] = selected_products_list[i].measure_unit;
                if (no_vat_invoice) {
                    new_invoice_item['vat_value'] = 0;
                } else {
                    new_invoice_item['vat_value'] = selected_products_list[i].vat_value;
                }

                new_invoice_item['item_type'] = "N"; // Normal product type
                new_invoice_item['amount'] = 1;
                new_invoice_item['recurring'] = selected_products_list[i].recurring;
            }
            new_invoice_item['discount'] = 0;
            if (active_customer_invoicing_details.customer_id !== "") {
                new_invoice_item['customer'] = active_customer_invoicing_details.customer_id;
                new_invoice_item['discount'] = active_customer_invoicing_details.discount;
            }
            new_invoice_item['sequence_on_invoice'] = parseInt(last_sequence_number) + parseInt(i) + 1;
            new_invoice_item['update_value_from_customer_dc_details'] = false;
            new_invoice_item['update_value_from_customer_estimate_details'] = false;
            new_invoice_item['add_sensor_number_to_item_comment'] = false;
            new_invoice_item['add_invoicing_period_to_item_comment'] = false;
            new_invoice_item['add_total_estimate_to_item_comment'] = false;
            new_invoice_item['invoice_period_start_date'] = invoice_details.invoice_period_start_date;
            new_invoice_item['invoice_period_end_date'] = invoice_details.invoice_period_end_date;
            new_invoice_item['update_year_estimate'] = false;

            this.handleItemsListChange(new_invoice_item, "", "add");
        }
        this.closeItemsAddModal();
    };

    validateInvoiceItemListTaxes = items_list => {
        let i = 0;
        let tax_items = false;
        let no_tax_items = false;
        for (i in items_list) {
            if (items_list[i].vat_value > 0) {
                tax_items = true;
            } else {
                no_tax_items = true;
            }
        }
        if (tax_items && no_tax_items) {
            return false;
        } else {
            return true;
        }
    };

    handleCheckIfSelectedProduct = product => {
        const { selected_products_list } = this.state;
        let i = 0;
        let return_value = false;
        for (i in selected_products_list) {
            if (product === selected_products_list[i]) {
                return_value = true;
            }
        }
        return return_value;
    };

    compareValuesForSort = (key, order = 'asc') => {
        return (a, b) => {
            if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
                // property doesn't exist on either object
                return 0;
            }

            const varA = (typeof a[key] === 'string')
                ? a[key].toUpperCase() : a[key];
            const varB = (typeof b[key] === 'string')
                ? b[key].toUpperCase() : b[key];

            let comparison = 0;
            if (varA > varB) {
                comparison = 1;
            } else if (varA < varB) {
                comparison = -1;
            }
            return (
                (order === 'desc') ? (comparison * -1) : comparison
            );
        };
    };

    handleChangeItemSequence = (item, direction) => {
        const { invoice_items_list } = this.state;
        let new_seq = 0;
        if (direction === "up") {
            new_seq = parseInt(item.sequence_on_invoice) - 1;
        } else if (direction === "down") {
            new_seq = parseInt(item.sequence_on_invoice) + 1;
        }
        if (new_seq !== 0) {
            // Update first item above/below
            let i = 0;
            for (i in invoice_items_list) {
                if (invoice_items_list[i].sequence_on_invoice === new_seq) {
                    let updatedItem = invoice_items_list[i];
                    // The item above/below will get current items seq number
                    updatedItem.sequence_on_invoice = item.sequence_on_invoice;
                    this.handleItemsListChange(invoice_items_list[i], updatedItem, "update");
                    // Now update current item with new seq.
                    updatedItem = item;
                    updatedItem.sequence_on_invoice = new_seq;
                    this.handleItemsListChange(item, updatedItem, "update");
                    break;
                }
            }
        }
    };

    openCustomersAddModal = () => {
        const { customers_list } = this.state;
        let i = 0;
        let existing_list = [];
        for (i in customers_list) {
            existing_list.push(customers_list[i]);
        }
        this.setState({
            customers_select_modal_open: true,
            selected_customers_list: existing_list,
            error: null
        })
    };

    closeCustomersAddModal = () => {
        this.setState({
            customers_select_modal_open: false,
            selected_customers_list: [],
        })
    };

    handleSearchChange = e => {
        this.setState({
            [e.target.name]: e.target.value,
            error: null
        });
    };

    handleCheckIfSelected = item => {
        const { selected_customers_list } = this.state;
        let i = 0;
        let return_value = false;
        for (i in selected_customers_list) {
            if (item === selected_customers_list[i]) {
                return_value = true;
            }
        }
        return return_value;
    };

    handleAddCustomersCheckboxChange = (e, { value, checked }) => {
        const { selected_customers_list } = this.state;
        let updatedList = selected_customers_list;
        if (checked) {
            if (!updatedList.includes(value)) {
                updatedList.push(value);
            }
        } else {
            if (updatedList.includes(value)) {
                updatedList.splice(updatedList.indexOf(value), 1);
            }
        }
        this.setState({ selected_customers_list: updatedList });
    };

    handleAddCustomers = () => {
        this.setState({
            customers_list: this.state.selected_customers_list,
            fields_disabled: this.state.selected_customers_list.length == 0 ? true : false
        });
        this.closeCustomersAddModal();
    };

    handleRemoveCustomerFromList = customer => {
        const { customers_list } = this.state;
        let updatedList = customers_list;
        if (updatedList.includes(customer)) {
            updatedList.splice(updatedList.indexOf(customer), 1);
        }
        this.setState({ customers_list: updatedList });
    };

    selectAllCustomers = customers => {
        if (this.state.all_customers_selected) {
            this.setState({
                selected_customers_list: [],
                all_customers_selected: false
            });
        } else {
            this.setState({
                selected_customers_list: customers,
                all_customers_selected: true
            });
        }
    };

    handleClick = (e, titleProps) => {
        const { index } = titleProps
        const { activeIndex } = this.state
        const newIndex = activeIndex === index ? -1 : index

        this.setState({ activeIndex: newIndex })
    };

    fetchInvoicingMethodOptions() {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;

        authAxios
            .get(customerInvoicingMethodOptionsURL(activeCompanyId, 0))
            .then(res => {
                this.setState({
                    invoicing_method_options: this.handleFormatInvoicingMethodOptions(res.data),
                    error: null,
                    success_mgs: null,
                    loading: false,
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    success_mgs: null,
                    error: err
                });
            });

    }

    handleFormatInvoicingMethodOptions = options => {
        const { t } = this.props;
        const keys = Object.keys(options);
        return keys.map(k => {
            return {
                key: options[k].value,
                text: t(options[k].name),
                value: options[k].value
            };
        });
    };

    render() {
        const {
            invoice_details,
            invoice_items_list,
            customer_options,
            customer_contract_options,
            company_users,
            // product_groups,
            products,
            invoice_items_select_modal_open,
            fields_disabled,
            active_invoice_item,
            invoice_item_details_modal_open,
            active_customer_data_collections,
            update_due_date,
            error,
            success_mgs,
            loading,
            create,
            copy_invoice,
            customer_select_disabled,
            selected_products_list,
            search,
            customer_search,
            customers_list,
            activeIndex,
            customers_select_modal_open,
            invoicing_method_options
        } = this.state;
        const { isAuthenticated, t } = this.props;
        if (!isAuthenticated) {
            return <Redirect to="/login" />;
        }
        if (update_due_date) {
            this.handleDateChange(invoice_details.date);
        }
        if (this.state.leave_page && error === null) {
            this.props.history.push(`/invoice/invoices/${this.state.leave_page_to}/`);
        }
        let filtered_dc_products = []
        let filtered_products = []
        // Filter data for product search
        if (active_customer_data_collections !== null) {
            filtered_dc_products = active_customer_data_collections.filter((item) => {
                return item.product.name.toLowerCase().indexOf(search.toLocaleLowerCase()) !== -1 ||
                    item.product.code.toString().toLowerCase().indexOf(search.toLocaleLowerCase()) !== -1;
            })
        }

        // Filter data for product search
        if (products !== null) {
            filtered_products = products.filter((item) => {
                return item.name.toLowerCase().indexOf(search.toLocaleLowerCase()) !== -1 ||
                    item.code.toString().toLowerCase().indexOf(search.toLocaleLowerCase()) !== -1;
            })
        }

        invoice_items_list.sort(this.compareValuesForSort('sequence_on_invoice'));

        const is_desktop = getWidth() > Responsive.onlyMobile.maxWidth ? true : false;
        // console.log(this.state);

        let edit_disabled = true;
        // Allow edit only for new invoices and existing ones with status "C"
        if (create || copy_invoice || invoice_details.status === "C") {
            edit_disabled = false;
        }

        // Multiple customers search
        let filtered_customers_list = [];
        if (customer_options && customer_options.length > 0) {
            filtered_customers_list = customer_options.filter((customer) => {
                return customer.name.toLowerCase().indexOf(customer_search.toLocaleLowerCase()) !== -1 ||
                    customer.vat.toLowerCase().indexOf(customer_search.toLocaleLowerCase()) !== -1 ||
                    customer.address.toLowerCase().indexOf(customer_search.toLocaleLowerCase()) !== -1 ||
                    customer.postal_code.toLowerCase().indexOf(customer_search.toLocaleLowerCase()) !== -1 ||
                    customer.city.toLowerCase().indexOf(customer_search.toLocaleLowerCase()) !== -1 ||
                    customer.customer_number.toString().toLowerCase().indexOf(customer_search.toLocaleLowerCase()) !== -1;

            })
        }

        return (
            <Shell>
                {(create || invoice_details.id !== "" || (copy_invoice && invoice_details.name !== "")) && (
                    <div>
                        <React.Fragment>
                            <Header as="h3">{create ? (t("New invoice")) : (`${invoice_details.name} (${invoice_details.number})`)}</Header>
                            <Button type="button" color="green" content={t("+ Customers")} onClick={() => this.openCustomersAddModal()} />
                            <br />
                            {customers_list.length > 0 && (
                                <Accordion>
                                    <Accordion.Title
                                        active={activeIndex === 1}
                                        index={1}
                                        onClick={this.handleClick}>
                                        <Icon name='dropdown' />
                                        <b>{`${t("Selected customers")} (${customers_list.length})`}</b>
                                    </Accordion.Title>
                                    <Accordion.Content active={activeIndex === 1}>
                                        <Table striped selectable size='small'>
                                            <Table.Header>
                                                <Table.Row>
                                                    <Table.HeaderCell>{t("Name")}</Table.HeaderCell>
                                                    <Table.HeaderCell>{t("Address")}</Table.HeaderCell>
                                                    <Table.HeaderCell>{t("VAT")}</Table.HeaderCell>
                                                    <Table.HeaderCell />
                                                </Table.Row>
                                            </Table.Header>
                                            <Table.Body>
                                                {customers_list.map((customer) => {
                                                    if (customer.is_active) {
                                                        return (
                                                            <Table.Row key={customer.id}>
                                                                <Table.Cell >
                                                                    {customer.name}
                                                                </Table.Cell>
                                                                <Table.Cell >{customer.address}, {customer.postal_code} {customer.city}</Table.Cell>
                                                                <Table.Cell >{customer.vat}</Table.Cell>
                                                                <Table.Cell><Icon link name="trash alternate outline" onClick={() => this.handleRemoveCustomerFromList(customer)} /></Table.Cell>
                                                            </Table.Row>
                                                        );
                                                    }
                                                })}
                                            </Table.Body>
                                        </Table>
                                    </Accordion.Content>
                                </Accordion>
                            )}
                            <br />
                        </React.Fragment>

                        <Form error={error !== null}>
                            {is_desktop ? (
                                <React.Fragment>
                                    {/* <Grid>
                                        <Grid.Row>
                                            <Grid.Column width={8}>
                                                {customer_options && (
                                                    <Form.Field required>
                                                        <label>{t("Select customer")}</label>
                                                        <Select
                                                            disabled={loading || customer_select_disabled}
                                                            fluid
                                                            search
                                                            name='customer'
                                                            options={customer_options}
                                                            placeholder={(create ? (t("Customer")) : (invoice_details.customer.id === undefined ? invoice_details.customer : invoice_details.customer.id.toString()))}
                                                            onChange={this.handleSelectChange}
                                                            value={invoice_details.customer.id === undefined ? invoice_details.customer : invoice_details.customer.id}
                                                        />
                                                    </Form.Field>
                                                )}
                                            </Grid.Column>
                                            <Grid.Column width={8}>
                                                <Form.Field >
                                                    <label>{t("Select contract")}</label>
                                                    <Select
                                                        disabled={loading || fields_disabled}
                                                        fluid
                                                        search
                                                        name='contract'
                                                        options={customer_contract_options}
                                                        placeholder={create ? (t("Select contract")) : (invoice_details.contract && (invoice_details.contract))}
                                                        onChange={this.handleSelectChange}
                                                        value={invoice_details.contract && (invoice_details.contract)}
                                                    />
                                                </Form.Field>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid> */}
                                    <Grid>
                                        <Grid.Row>
                                            <Grid.Column width={8}>
                                                <Header as="h4">{t("Basic details")}</Header>
                                                <Form.Field required>
                                                    <label>{t("Invoice name")} {invoice_details.name ? (`${invoice_details.name.length}/35`) : ("0/35")}</label>
                                                    <Input
                                                        required
                                                        disabled={loading || fields_disabled}
                                                        value={invoice_details.name}
                                                        name="name"
                                                        onChange={this.handleChange} />
                                                </Form.Field>
                                                {company_users && (
                                                    <Form.Field>
                                                        <label>{t("Select sales person")}</label>
                                                        <Select
                                                            disabled={loading || fields_disabled}
                                                            fluid
                                                            search
                                                            name='sales_person'
                                                            options={company_users}
                                                            placeholder={create ? (t("Sales person")) : (invoice_details.sales_person && (invoice_details.sales_person.toString()))}
                                                            onChange={this.handleSelectChange}
                                                            value={invoice_details.sales_person && (invoice_details.sales_person)}
                                                        />
                                                    </Form.Field>
                                                )}
                                                <Grid>
                                                    <Grid.Row columns="2">
                                                        <Grid.Column >
                                                            <Form.Field required>
                                                                <label>{t("Payment term")}</label>
                                                                <Input
                                                                    required
                                                                    disabled={loading || fields_disabled || invoice_details.use_customer_payment_term}
                                                                    type='number'
                                                                    value={invoice_details.payment_term}
                                                                    name="payment_term"
                                                                    onChange={this.handleChange} />
                                                            </Form.Field>
                                                            {create && (
                                                                <Form.Field>
                                                                    <Checkbox
                                                                        disabled={loading || fields_disabled}
                                                                        label={<label>{t("Use default payment term from customer invoicing details")}</label>}
                                                                        name="use_customer_payment_term"
                                                                        onChange={this.handleCheckboxChange}
                                                                        checked={invoice_details.use_customer_payment_term}
                                                                        value={invoice_details.use_customer_payment_term}
                                                                    />
                                                                </Form.Field>
                                                            )}
                                                        </Grid.Column>
                                                        <Grid.Column  >
                                                            <Form.Field required>
                                                                <label>{t("Booking date")}</label>
                                                                <SemanticDatepicker
                                                                    firstDayOfWeek="1"
                                                                    disabled={loading || fields_disabled}
                                                                    locale={fiLocale}
                                                                    placeholder={invoice_details.booking_date !== null ? (new Date(invoice_details.booking_date).toLocaleString('fi-FI', { dateStyle: 'short' })) : (new Date(invoice_details.date).toLocaleString('fi-FI', { dateStyle: 'short' }))}
                                                                    onDateChange={selected => this.handleBookingDateChange(selected)}
                                                                    format='DD.MM.YYYY' />
                                                            </Form.Field>
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                    <Grid.Row columns="2">
                                                        <Grid.Column  >
                                                            <Form.Field required>
                                                                <label>{t("Invoice date")}</label>
                                                                <SemanticDatepicker
                                                                    firstDayOfWeek="1"
                                                                    disabled={loading || fields_disabled}
                                                                    // label={t("Invoice date")}
                                                                    locale={fiLocale}
                                                                    placeholder={invoice_details.date !== null ? (new Date(invoice_details.date).toLocaleString('fi-FI', { dateStyle: 'short' })) : ("")}
                                                                    onDateChange={selected => this.handleDateChange(selected)}
                                                                    format='DD.MM.YYYY' />
                                                            </Form.Field>
                                                        </Grid.Column>
                                                        <Grid.Column  >
                                                            <Form.Field required>
                                                                <label>{t("Due date")}</label>
                                                                <SemanticDatepicker
                                                                    firstDayOfWeek="1"
                                                                    disabled={loading || fields_disabled}
                                                                    locale={fiLocale}
                                                                    placeholder={new Date(invoice_details.due_date).toLocaleString('fi-FI', { dateStyle: 'short' })}
                                                                    onDateChange={selected => this.handleDueDateChange(selected)}
                                                                    format='DD.MM.YYYY' />
                                                            </Form.Field>
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                    {create && (
                                                        <>
                                                            <Grid.Row columns="2">
                                                                <Grid.Column>
                                                                    <Form.Field>
                                                                        <label>{t("Select invoicing method")}</label>
                                                                        <Select
                                                                            disabled={loading || fields_disabled || invoice_details.use_customer_invoicing_method}
                                                                            fluid
                                                                            search
                                                                            name='invoicing_method'
                                                                            options={invoicing_method_options}
                                                                            placeholder={invoice_details.invoicing_method}
                                                                            onChange={this.handleSelectChange}
                                                                            value={invoice_details.invoicing_method && (invoice_details.invoicing_method)}
                                                                        />
                                                                    </Form.Field>
                                                                </Grid.Column>
                                                            </Grid.Row>
                                                            <Grid.Row>
                                                                <Grid.Column>
                                                                    <Form.Field>
                                                                        <Checkbox
                                                                            disabled={loading || fields_disabled}
                                                                            label={<label>{t("Use default invoicing method from customer invoicing details")}</label>}
                                                                            name="use_customer_invoicing_method"
                                                                            onChange={this.handleCheckboxChange}
                                                                            checked={invoice_details.use_customer_invoicing_method}
                                                                            value={invoice_details.use_customer_invoicing_method}
                                                                        />
                                                                    </Form.Field>
                                                                </Grid.Column>
                                                            </Grid.Row>
                                                        </>
                                                    )}
                                                    <Grid.Row>
                                                        <Grid.Column>
                                                            <Form.Field>
                                                                <Checkbox
                                                                    disabled={loading || fields_disabled}
                                                                    name="is_automatic_sending"
                                                                    label={t("Send automatically when created")}
                                                                    onChange={this.handleCheckboxChange}
                                                                    checked={invoice_details.is_automatic_sending}
                                                                    value={invoice_details.is_automatic_sending}
                                                                />
                                                            </Form.Field>
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                    <Grid.Row>
                                                        <Grid.Column width={8}>
                                                            {invoice_details.is_automatic_sending && (
                                                                <Form.Field>
                                                                    <Popup content={t("Send invoices automatically if they are in selected status")}
                                                                        trigger={<label>{t("Automatic sending status")}</label>} />
                                                                    <Select
                                                                        disabled={loading || fields_disabled}
                                                                        fluid
                                                                        search
                                                                        options={this.state.automatic_sending_mode_options}
                                                                        value={invoice_details.automatic_sending_mode}
                                                                        placeholder={invoice_details.automatic_sending_mode}
                                                                        name="automatic_sending_mode"
                                                                        onChange={this.handleSelectChange} />
                                                                </Form.Field>
                                                            )}
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                </Grid>
                                                <br />

                                            </Grid.Column>
                                            <Grid.Column width={8}>
                                                <Header as="h4">{t("Invoicing details")}</Header>
                                                <Form.Field>
                                                    <Popup content={t("Will be printed on invoice if not empty")}
                                                        trigger={<label>{t("Contract number (on invoice)")} {invoice_details.contract_number_on_invoice ? (`${invoice_details.contract_number_on_invoice.length}/35`) : ("0/35")}</label>} />
                                                    <Input
                                                        disabled={loading || fields_disabled || invoice_details.use_customer_contract_number}
                                                        value={invoice_details.contract_number_on_invoice}
                                                        name="contract_number_on_invoice"
                                                        onChange={this.handleChange} />
                                                </Form.Field>
                                                {create && (
                                                    <Form.Field>
                                                        <Checkbox
                                                            disabled={loading || fields_disabled}
                                                            label={<label>{t("Use default contract number from customer invoicing details")}</label>}
                                                            name="use_customer_contract_number"
                                                            onChange={this.handleCheckboxChange}
                                                            checked={invoice_details.use_customer_contract_number}
                                                            value={invoice_details.use_customer_contract_number}
                                                        />
                                                    </Form.Field>
                                                )}
                                                <Form.Field>
                                                    <label>{t("Invoice comment")} {invoice_details.comment ? (`${invoice_details.comment.length}/100`) : ("0/100")}</label>
                                                    <Input
                                                        disabled={loading || fields_disabled || invoice_details.use_customer_invoice_comment}
                                                        value={invoice_details.comment}
                                                        name="comment"
                                                        onChange={this.handleChange} />
                                                </Form.Field>
                                                {create && (
                                                    <Form.Field>
                                                        <Checkbox
                                                            disabled={loading || fields_disabled}
                                                            label={<label>{t("Use default invoice comment from customer invoicing details")}</label>}
                                                            name="use_customer_invoice_comment"
                                                            onChange={this.handleCheckboxChange}
                                                            checked={invoice_details.use_customer_invoice_comment}
                                                            value={invoice_details.use_customer_invoice_comment}
                                                        />
                                                    </Form.Field>
                                                )}
                                                <Form.Field>
                                                    <label>{t("Company reference")} {invoice_details.company_reference ? (`${invoice_details.company_reference.length}/50`) : ("0/50")}</label>
                                                    <Input
                                                        disabled={loading || fields_disabled || invoice_details.use_customer_company_reference}
                                                        value={invoice_details.company_reference}
                                                        name="company_reference"
                                                        onChange={this.handleChange} />
                                                </Form.Field>
                                                {create && (
                                                    <Form.Field>
                                                        <Checkbox
                                                            disabled={loading || fields_disabled}
                                                            label={<label>{t("Use default company reference from customer invoicing details")}</label>}
                                                            name="use_customer_company_reference"
                                                            onChange={this.handleCheckboxChange}
                                                            checked={invoice_details.use_customer_company_reference}
                                                            value={invoice_details.use_customer_company_reference}
                                                        />
                                                    </Form.Field>
                                                )}
                                                <Form.Field>
                                                    <label>{t("Customer reference")} {invoice_details.customer_reference ? (`${invoice_details.customer_reference.length}/50`) : ("0/50")}</label>
                                                    <Input
                                                        disabled={loading || fields_disabled || invoice_details.use_customer_customer_reference}
                                                        value={invoice_details.customer_reference}
                                                        name="customer_reference"
                                                        onChange={this.handleChange} />
                                                </Form.Field>
                                                {create && (
                                                    <Form.Field>
                                                        <Checkbox
                                                            disabled={loading || fields_disabled}
                                                            label={<label>{t("Use default customer reference from customer invoicing details")}</label>}
                                                            name="use_customer_customer_reference"
                                                            onChange={this.handleCheckboxChange}
                                                            checked={invoice_details.use_customer_customer_reference}
                                                            value={invoice_details.use_customer_customer_reference}
                                                        />
                                                    </Form.Field>
                                                )}
                                                <Form.Field>
                                                    <label>{t("Purchase order")} {invoice_details.purchase_order ? (`${invoice_details.purchase_order.length}/70`) : ("0/70")}</label>
                                                    <Input
                                                        disabled={loading || fields_disabled}
                                                        value={invoice_details.purchase_order}
                                                        name="purchase_order"
                                                        onChange={this.handleChange} />
                                                </Form.Field>
                                                <Form.Field>
                                                    <Checkbox
                                                        disabled={loading || fields_disabled || invoice_details.use_customer_no_vat}
                                                        name="no_vat_invoice"
                                                        label={t("VAT 0%/Reverse VAT invoice")}
                                                        onChange={this.handleNoVatCheckboxChange}
                                                        checked={this.state.no_vat_invoice}
                                                        value={this.state.no_vat_invoice}
                                                    />
                                                </Form.Field>
                                                {this.state.no_vat_invoice && (
                                                    <Form.Field>
                                                        <label>{t("No VAT comment")} {invoice_details.no_vat_comment ? (`${invoice_details.no_vat_comment.length}/70`) : ("0/70")}</label>
                                                        <Input
                                                            disabled={loading || fields_disabled || invoice_details.use_customer_no_vat}
                                                            value={invoice_details.no_vat_comment}
                                                            name="no_vat_comment"
                                                            onChange={this.handleChange} />
                                                    </Form.Field>
                                                )}
                                                {create && (
                                                    <Form.Field>
                                                        <Checkbox
                                                            disabled={loading || fields_disabled}
                                                            label={<label>{t("Use default VAT 0% details from customer invoicing details")}</label>}
                                                            name="use_customer_no_vat"
                                                            onChange={this.handleCheckboxChange}
                                                            checked={invoice_details.use_customer_no_vat}
                                                            value={invoice_details.use_customer_no_vat}
                                                        />
                                                    </Form.Field>
                                                )}
                                                {create && (
                                                    <Form.Field>
                                                        <Checkbox
                                                            disabled={loading || fields_disabled}
                                                            label={<label>{t("Add invoicing period")}</label>}
                                                            name="add_invoicing_period"
                                                            onChange={this.handleCheckboxChange}
                                                            checked={invoice_details.add_invoicing_period}
                                                            value={invoice_details.add_invoicing_period}
                                                        />
                                                    </Form.Field>
                                                )}
                                                {invoice_details.add_invoicing_period && (
                                                    <Form.Field >
                                                        <label>{t("Invoicing period start date")}</label>
                                                        <SemanticDatepicker
                                                            firstDayOfWeek="1"
                                                            disabled={loading || fields_disabled}
                                                            locale={fiLocale}
                                                            placeholder={invoice_details.invoice_period_start_date !== null ? (new Date(invoice_details.invoice_period_start_date).toLocaleString('fi-FI', { dateStyle: 'short' })) : (null)}
                                                            onDateChange={(value) => this.handleInvoicePeriodStartDateChange(value)}
                                                            format='DD.MM.YYYY' />
                                                    </Form.Field>
                                                )}
                                                {invoice_details.add_invoicing_period && (
                                                    <Form.Field >
                                                        <label>{t("Invoicing period end date")}</label>
                                                        <SemanticDatepicker
                                                            firstDayOfWeek="1"
                                                            disabled={loading || fields_disabled}
                                                            locale={fiLocale}
                                                            placeholder={invoice_details.invoice_period_end_date !== null ? (new Date(invoice_details.invoice_period_end_date).toLocaleString('fi-FI', { dateStyle: 'short' })) : (null)}
                                                            onDateChange={(value) => this.handleInvoicePeriodEndDateChange(value)}
                                                            format='DD.MM.YYYY' />
                                                    </Form.Field>
                                                )}
                                                {(invoice_details.date_sent !== null && invoice_details.date_sent !== "") && (
                                                    <Form.Field >
                                                        <label>{t("Invoice sent date")}</label>
                                                        <SemanticDatepicker
                                                            firstDayOfWeek="1"
                                                            disabled={loading || fields_disabled}
                                                            locale={fiLocale}
                                                            placeholder={invoice_details.date_sent !== "" ? (new Date(invoice_details.date_sent).toLocaleString('fi-FI', { dateStyle: 'short' })) : ("")}
                                                            onDateChange={(value) => this.handleSentDateChange(value)}
                                                            format='DD.MM.YYYY' />
                                                    </Form.Field>
                                                )}
                                                {(invoice_details.date_paid !== null && invoice_details.date_paid !== "") && (
                                                    <Form.Field >
                                                        <label>{t("Invoice paid date")}</label>
                                                        <SemanticDatepicker
                                                            firstDayOfWeek="1"
                                                            disabled={loading || fields_disabled}
                                                            locale={fiLocale}
                                                            placeholder={invoice_details.date_paid !== "" ? (new Date(invoice_details.date_paid).toLocaleString('fi-FI', { dateStyle: 'short' })) : ("")}
                                                            onDateChange={(value) => this.handlePaidDateChange(value)}
                                                            format='DD.MM.YYYY' />
                                                    </Form.Field>
                                                )}
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    {customer_options && (
                                        <Form.Field required>
                                            <label>{t("Select customer")}</label>
                                            <Select
                                                disabled={loading || customer_select_disabled}
                                                fluid
                                                search
                                                name='customer'
                                                options={customer_options}
                                                placeholder={(create ? (t("Customer")) : (invoice_details.customer.id === undefined ? invoice_details.customer : invoice_details.customer.id.toString()))}
                                                onChange={this.handleSelectChange}
                                                value={invoice_details.customer.id === undefined ? invoice_details.customer : invoice_details.customer.id}
                                            />
                                        </Form.Field>
                                    )}
                                    <Form.Field >
                                        <label>{t("Select contract")}</label>
                                        <Select
                                            disabled={loading || fields_disabled}
                                            fluid
                                            search
                                            name='contract'
                                            options={customer_contract_options}
                                            placeholder={create ? (t("Select contract")) : (invoice_details.contract && (invoice_details.contract))}
                                            onChange={this.handleSelectChange}
                                            value={invoice_details.contract && (invoice_details.contract)}
                                        />
                                    </Form.Field>
                                    <hr />
                                    <Header as="h4">{t("Basic details")}</Header>
                                    <Form.Field required>
                                        <label>{t("Invoice name")} {invoice_details.name ? (`${invoice_details.name.length}/35`) : ("0/35")}</label>
                                        <Input
                                            required
                                            disabled={loading || fields_disabled}
                                            value={invoice_details.name}
                                            name="name"
                                            onChange={this.handleChange} />
                                    </Form.Field>
                                    {company_users && (
                                        <Form.Field>
                                            <label>{t("Select sales person")}</label>
                                            <Select
                                                disabled={loading || fields_disabled}
                                                fluid
                                                search
                                                name='sales_person'
                                                options={company_users}
                                                placeholder={create ? (t("Sales person")) : (invoice_details.sales_person && (invoice_details.sales_person.toString()))}
                                                onChange={this.handleSelectChange}
                                                value={invoice_details.sales_person && (invoice_details.sales_person)}
                                            />
                                        </Form.Field>
                                    )}
                                    <Form.Field required>
                                        <label>{t("Payment term")}</label>
                                        <Input
                                            required
                                            disabled={loading || fields_disabled}
                                            type='number'
                                            value={invoice_details.payment_term}
                                            name="payment_term"
                                            onChange={this.handleChange} />
                                    </Form.Field>
                                    <Form.Field required>
                                        <label>{t("Invoice date")}</label>
                                        <SemanticDatepicker
                                            firstDayOfWeek="1"
                                            disabled={loading || fields_disabled}
                                            // label={t("Invoice date")}
                                            locale={fiLocale}
                                            placeholder={invoice_details.date !== null ? (new Date(invoice_details.date).toLocaleString('fi-FI', { dateStyle: 'short' })) : ("")}
                                            onDateChange={selected => this.handleDateChange(selected)}
                                            format='DD.MM.YYYY' />
                                    </Form.Field>
                                    <Form.Field required>
                                        <label>{t("Due date")}</label>
                                        <Input
                                            disabled={loading || fields_disabled}
                                            value={new Date(invoice_details.due_date).toLocaleString('fi-FI', { dateStyle: 'short' })}
                                            name="due_date"
                                            onChange={this.handleChange} />
                                    </Form.Field>
                                    <Form.Field>
                                        <Checkbox
                                            disabled={loading || fields_disabled}
                                            name="is_automatic_sending"
                                            label={t("Send automatically when created")}
                                            onChange={this.handleCheckboxChange}
                                            checked={invoice_details.is_automatic_sending}
                                            value={invoice_details.is_automatic_sending}
                                        />
                                    </Form.Field>
                                    {invoice_details.is_automatic_sending && (
                                        <Form.Field>
                                            <Popup content={t("Send invoices automatically if they are in selected status")}
                                                trigger={<label>{t("Automatic sending status")}</label>} />
                                            <Select
                                                disabled={loading || fields_disabled}
                                                fluid
                                                search
                                                options={this.state.automatic_sending_mode_options}
                                                value={invoice_details.automatic_sending_mode}
                                                placeholder={invoice_details.automatic_sending_mode}
                                                name="automatic_sending_mode"
                                                onChange={this.handleSelectChange} />
                                        </Form.Field>
                                    )}
                                    <hr />
                                    <Header as="h4">{t("Invoicing details")}</Header>
                                    <Form.Field>
                                        <Popup content={t("Will be printed on invoice if not empty")}
                                            trigger={<label>{t("Contract number (on invoice)")} {invoice_details.contract_number_on_invoice ? (`${invoice_details.contract_number_on_invoice.length}/35`) : ("0/35")}</label>} />
                                        <Input
                                            disabled={loading || fields_disabled}
                                            value={invoice_details.contract_number_on_invoice}
                                            name="contract_number_on_invoice"
                                            onChange={this.handleChange} />
                                    </Form.Field>
                                    <Form.Field>
                                        <label>{t("Invoice comment")} {invoice_details.comment ? (`${invoice_details.comment.length}/100`) : ("0/100")}</label>
                                        <Input
                                            disabled={loading || fields_disabled}
                                            value={invoice_details.comment}
                                            name="comment"
                                            onChange={this.handleChange} />
                                    </Form.Field>
                                    <Form.Field>
                                        <label>{t("Company reference")} {invoice_details.company_reference ? (`${invoice_details.company_reference.length}/50`) : ("0/50")}</label>
                                        <Input
                                            disabled={loading || fields_disabled}
                                            value={invoice_details.company_reference}
                                            name="company_reference"
                                            onChange={this.handleChange} />
                                    </Form.Field>
                                    <Form.Field>
                                        <label>{t("Customer reference")} {invoice_details.customer_reference ? (`${invoice_details.customer_reference.length}/50`) : ("0/50")}</label>
                                        <Input
                                            disabled={loading || fields_disabled}
                                            value={invoice_details.customer_reference}
                                            name="customer_reference"
                                            onChange={this.handleChange} />
                                    </Form.Field>
                                    <Form.Field>
                                        <label>{t("Purchase order")} {invoice_details.purchase_order ? (`${invoice_details.purchase_order.length}/70`) : ("0/70")}</label>
                                        <Input
                                            disabled={loading || fields_disabled}
                                            value={invoice_details.purchase_order}
                                            name="purchase_order"
                                            onChange={this.handleChange} />
                                    </Form.Field>
                                    <Form.Field>
                                        <Checkbox
                                            disabled={loading || fields_disabled}
                                            name="no_vat_invoice"
                                            label={t("VAT 0%/Reverse VAT invoice")}
                                            onChange={this.handleNoVatCheckboxChange}
                                            checked={this.state.no_vat_invoice}
                                            value={this.state.no_vat_invoice}
                                        />
                                    </Form.Field>
                                    {this.state.no_vat_invoice && (
                                        <Form.Field>
                                            <label>{t("No VAT comment")} {invoice_details.no_vat_comment ? (`${invoice_details.no_vat_comment.length}/70`) : ("0/70")}</label>
                                            <Input
                                                disabled={loading || fields_disabled}
                                                value={invoice_details.no_vat_comment}
                                                name="no_vat_comment"
                                                onChange={this.handleChange} />
                                        </Form.Field>
                                    )}
                                    {(invoice_details.date_sent !== null && invoice_details.date_sent !== "") && (
                                        <Form.Field >
                                            <label>{t("Invoice sent date")}</label>
                                            <SemanticDatepicker
                                                firstDayOfWeek="1"
                                                disabled={loading || fields_disabled}
                                                locale={fiLocale}
                                                placeholder={invoice_details.date_sent !== "" ? (new Date(invoice_details.date_sent).toLocaleString('fi-FI', { dateStyle: 'short' })) : ("")}
                                                onDateChange={(value) => this.handleSentDateChange(value)}
                                                format='DD.MM.YYYY' />
                                        </Form.Field>
                                    )}
                                    {(invoice_details.date_paid !== null && invoice_details.date_paid !== "") && (
                                        <Form.Field >
                                            <label>{t("Invoice paid date")}</label>
                                            <SemanticDatepicker
                                                firstDayOfWeek="1"
                                                disabled={loading || fields_disabled}
                                                locale={fiLocale}
                                                placeholder={invoice_details.date_paid !== "" ? (new Date(invoice_details.date_paid).toLocaleString('fi-FI', { dateStyle: 'short' })) : ("")}
                                                onDateChange={(value) => this.handlePaidDateChange(value)}
                                                format='DD.MM.YYYY' />
                                        </Form.Field>
                                    )}
                                </React.Fragment>
                            )}
                            <br />

                            <Header as="h3">{t("Invoice Items")}</Header>
                            {is_desktop ? (
                                <Button type="button" disabled={loading || fields_disabled || edit_disabled} floated="right" color="green" content={t("+ Item")} onClick={() => this.openItemsAddModal()} />
                            ) : (
                                <>
                                    <hr />
                                    <Button type="button" disabled={loading || fields_disabled || edit_disabled} fluid color="green" content={t("+ Item")} onClick={() => this.openItemsAddModal()} />
                                    <hr />
                                </>
                            )}

                            <Table celled striped selectable sortable >
                                {is_desktop && (
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell />
                                            <Table.HeaderCell>{t("Item")}</Table.HeaderCell>
                                            <Table.HeaderCell>{t("Amount")}</Table.HeaderCell>
                                            <Table.HeaderCell>{t("Net value €")}</Table.HeaderCell>
                                            <Table.HeaderCell>{t("Discount %")}</Table.HeaderCell>
                                            <Table.HeaderCell>{t("Vat %")}</Table.HeaderCell>
                                            <Table.HeaderCell>{t("Total €")}</Table.HeaderCell>
                                            <Table.HeaderCell />
                                        </Table.Row>
                                    </Table.Header>
                                )}
                                <Table.Body>
                                    {invoice_items_list.map((item, i) => {
                                        return (
                                            <Table.Row key={i + 1}>
                                                <Table.Cell >
                                                    {item.sequence_on_invoice !== 1 && (
                                                        <Popup content={t("Move up in the list")} trigger={
                                                            <Icon link name="arrow up" onClick={() => this.handleChangeItemSequence(item, "up")} />} />
                                                    )}
                                                    {item.sequence_on_invoice !== invoice_items_list.length && (
                                                        <Popup content={t("Move down in the list")} trigger={
                                                            <Icon link name="arrow down" onClick={() => this.handleChangeItemSequence(item, "down")} />} />
                                                    )}
                                                </Table.Cell>
                                                <Table.Cell onClick={() => this.openItemDetailsModal(item)}>
                                                    {item.item_type === "D" && (<Popup content={t("Data collection product")} trigger={<Icon fitted name="cloud download" />} />)}
                                                    {item.recurring
                                                        ? (<Popup content={t("Recurring product/invoiced periodically")} trigger={<Icon fitted name="sync" />} />)
                                                        : (<Popup content={t("Product invoiced once")} trigger={<Icon fitted name="file alternate outline" />} />)}
                                                    &nbsp; <b>{item.name}</b><br />{item.description}
                                                </Table.Cell>
                                                <Table.Cell onClick={() => this.openItemDetailsModal(item)}>
                                                    {!is_desktop && (<React.Fragment>{t("Amount")}<br /></React.Fragment>)}
                                                    {item.amount} {item.measure_unit}
                                                </Table.Cell>
                                                <Table.Cell onClick={() => this.openItemDetailsModal(item)}>
                                                    {!is_desktop && (<React.Fragment>{t("Net value €")}<br /></React.Fragment>)}
                                                    {item.net_price}
                                                </Table.Cell>
                                                <Table.Cell onClick={() => this.openItemDetailsModal(item)}>
                                                    {!is_desktop && (<React.Fragment>{t("Discount %")}<br /></React.Fragment>)}
                                                    {item.discount}
                                                </Table.Cell>
                                                <Table.Cell onClick={() => this.openItemDetailsModal(item)}>
                                                    {!is_desktop && (<React.Fragment>{t("Vat %")}<br /></React.Fragment>)}
                                                    {item.vat_value}
                                                </Table.Cell>
                                                <Table.Cell onClick={() => this.openItemDetailsModal(item)}>
                                                    {!is_desktop && (<React.Fragment>{t("Total €")}<br /></React.Fragment>)}
                                                    <Header as="h5">{getItemNetPrice(item).toFixed(2)} €</Header>({getItemGrossPrice(item).toFixed(2)} €)</Table.Cell>
                                                <Table.Cell textAlign='center'>
                                                    <Popup content={t("Deletes item straight away without needing to Save the invoice")} trigger={
                                                        <Icon link disabled={edit_disabled} color="red" name='trash alternate outline' onClick={() => this.handleItemsListChange(item, "", "remove")} />
                                                        //<Button type="button" negative size='tiny' icon='remove' onClick={() => this.handleItemsListChange(item, "", "remove")} />
                                                    } />
                                                </Table.Cell>
                                            </Table.Row>
                                        );
                                    })}
                                    <Table.Row>
                                        <Table.Cell />
                                        <Table.Cell />
                                        <Table.Cell />
                                        <Table.Cell />
                                        <Table.Cell />
                                        <Table.Cell >
                                            <Header as="h5">{t("Total:")}</Header>
                                        </Table.Cell>
                                        <Table.Cell >
                                            <Header as="h5">{getTotalNetPrice(invoice_items_list).toFixed(2)} €</Header>({getTotalGrossPrice(invoice_items_list).toFixed(2)} €)
                                        </Table.Cell>
                                        <Table.Cell />
                                    </Table.Row>
                                </Table.Body>
                            </Table>
                            {error && <Message error header={t("Error")} content={error} />}
                            {success_mgs && <Message positive header={success_mgs} />}
                            <Button type="button" primary onClick={(e) => this.handleSubmit(e)} loading={loading} disabled={loading || fields_disabled || edit_disabled}>
                                {(create || copy_invoice) ? (t("Create")) : (t("Save"))}
                            </Button>
                        </Form>
                    </div>
                )}

                {/* Invoice items select modal. Lists available products and adds selected ones to the invoice items list */}
                <Modal size='small' open={invoice_items_select_modal_open} dimmer="inverted" scrolling="true">
                    <Modal.Header>
                        <Header as="h3">{t("Pick products and services")}</Header>
                        <Button type="button" positive icon='add circle' content={t("Add products to invoice")} onClick={() => this.handleAddItemsToInvoice()} />
                        <Input
                            size="mini"
                            icon='search'
                            value={search}
                            name="search"
                            onChange={this.handleItemSearchChange} />
                        <Button type="button" floated='right' negative onClick={() => this.closeItemsAddModal()} >{t("Close")}</Button>
                    </Modal.Header>
                    <Modal.Content>
                        <Container>
                            {products && (
                                <React.Fragment>
                                    {/* Table for data collection items */}
                                    {filtered_dc_products && (
                                        <React.Fragment>
                                            {filtered_dc_products.length > 0 && (
                                                <React.Fragment>
                                                    <Header as="h3" color="blue">{t("Data collection items")}</Header>
                                                    <Table striped selectable size='small'>
                                                        <Table.Header>
                                                            <Table.Row>
                                                                <Table.HeaderCell />
                                                                <Table.HeaderCell content={t("Product")} />
                                                                <Table.HeaderCell content={t("Amount for invoice")} />
                                                                <Table.HeaderCell content={t("Estimate")} />
                                                                <Table.HeaderCell content={t("Net price")} />
                                                            </Table.Row>
                                                        </Table.Header>
                                                        <Table.Body>
                                                            {filtered_dc_products.map(dc_item => {
                                                                return (
                                                                    <React.Fragment key={dc_item.id}>
                                                                        {dc_item.is_active && (
                                                                            <Table.Row key={dc_item.id} >
                                                                                <Table.Cell collapsing>
                                                                                    <Checkbox
                                                                                        checked={this.handleCheckIfSelectedProduct(dc_item) === true}
                                                                                        value={dc_item}
                                                                                        onChange={this.handleAddItemsCheckboxChange} />
                                                                                </Table.Cell>
                                                                                <Table.Cell>
                                                                                    <Popup content={t("Data collection product")} trigger={<Icon fitted name="cloud download" />} />
                                                                                    {dc_item.recurring
                                                                                        ? (<Popup content={t("Recurring product/invoiced periodically")} trigger={<Icon fitted name="sync" />} />)
                                                                                        : (<Popup content={t("Product invoiced once")} trigger={<Icon fitted name="file alternate outline" />} />)}
                                                                                    &nbsp; <b>{dc_item.product.name}</b><br />{dc_item.product.code}

                                                                                    {/* <Header size='small'>{dc_item.product.name}</Header>
                                                                                    <Header size='tiny'>({dc_item.product.code})</Header> */}
                                                                                </Table.Cell>
                                                                                <Table.Cell collapsing>
                                                                                    <Header as="h4">{roundFloat(parseFloat(dc_item.last_value) - parseFloat(dc_item.last_invoiced_value), 3)}</Header>
                                                                                </Table.Cell>
                                                                                <Table.Cell collapsing>
                                                                                    <Header as="h4">{dc_item.last_estimate}</Header>
                                                                                </Table.Cell>
                                                                                <Table.Cell collapsing textAlign='right'>
                                                                                    <Header as="h4">{roundFloat(dc_item.product.net_price, 2)} €</Header>
                                                                                </Table.Cell>
                                                                            </Table.Row>
                                                                        )}
                                                                    </React.Fragment>
                                                                );
                                                            })}
                                                        </Table.Body>
                                                    </Table>
                                                </React.Fragment>
                                            )}
                                        </React.Fragment>
                                    )}
                                    <hr />
                                    <Header as="h3" color="blue">{t("Normal products")}</Header>
                                    {filtered_products.length > 0 && (
                                        <Table striped selectable size='small'>
                                            <Table.Header>
                                                <Table.Row>
                                                    <Table.HeaderCell colSpan='2'>{t("Name")}</Table.HeaderCell>
                                                    <Table.HeaderCell content={t("Net price")} />
                                                </Table.Row>
                                            </Table.Header>
                                            <Table.Body>
                                                {filtered_products.map(product => {
                                                    return (
                                                        <React.Fragment key={product.id}>
                                                            {product.is_active && (
                                                                <Table.Row key={product.id} >
                                                                    <Table.Cell collapsing>
                                                                        <Checkbox
                                                                            checked={this.handleCheckIfSelectedProduct(product) === true}
                                                                            value={product}
                                                                            onChange={this.handleAddItemsCheckboxChange} />
                                                                    </Table.Cell>
                                                                    <Table.Cell>
                                                                        {product.recurring
                                                                            ? (<Popup content={t("Recurring product/invoiced periodically")} trigger={<Icon fitted name="sync" />} />)
                                                                            : (<Popup content={t("Product invoiced once")} trigger={<Icon fitted name="file alternate outline" />} />)}
                                                                        &nbsp; <b>{product.name}</b><br />{product.code}

                                                                        {/* <Header size='small'>{product.name}</Header>
                                                                            <Header size='tiny'>({product.code})</Header> */}
                                                                    </Table.Cell>
                                                                    <Table.Cell collapsing textAlign='right'>
                                                                        <Header as="h4">{roundFloat(product.net_price, 2)} €</Header>
                                                                    </Table.Cell>
                                                                </Table.Row>
                                                            )}
                                                        </React.Fragment>
                                                    );
                                                })}
                                            </Table.Body>
                                        </Table>
                                    )}
                                </React.Fragment>
                            )}
                        </Container>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button type="button" negative onClick={() => this.closeItemsAddModal()} >{t("Close")}</Button>
                    </Modal.Actions>
                </Modal>

                {/* Invoice items update modal. */}
                <Modal size='small' open={invoice_item_details_modal_open} dimmer="inverted" scrolling="true">
                    <Modal.Header>
                        <Header as="h3">{t("Edit invoice item")}</Header>
                    </Modal.Header>
                    <Modal.Content>
                        <Container>
                            {active_invoice_item && (
                                <Form error={error !== null}>
                                    <Grid>
                                        <Grid.Row columns="4">
                                            <Grid.Column width={7} >
                                                <Form.Field>
                                                    <label>{t("Normal")}</label>
                                                    <Radio
                                                        disabled={loading || fields_disabled}
                                                        name="item_type"
                                                        value="N"
                                                        checked={this.state.itemTypeSelectRadioGroup === "N"}
                                                        onChange={this.handleItemDetailsRadioChange} />
                                                </Form.Field>
                                            </Grid.Column>
                                            <Grid.Column width={7}>
                                                <Form.Field>
                                                    <label>{t("Data collection")}</label>
                                                    <Radio
                                                        disabled={loading || fields_disabled}
                                                        name="item_type"
                                                        value="D"
                                                        checked={this.state.itemTypeSelectRadioGroup === "D"}
                                                        onChange={this.handleItemDetailsRadioChange} />
                                                </Form.Field>
                                            </Grid.Column>
                                            <Grid.Column width={7}>
                                                <Form.Field>
                                                    <label>{t("Estimate")}</label>
                                                    <Radio
                                                        disabled={loading || fields_disabled}
                                                        name="item_type"
                                                        value="E"
                                                        checked={this.state.itemTypeSelectRadioGroup === "E"}
                                                        onChange={this.handleItemDetailsRadioChange} />
                                                </Form.Field>
                                            </Grid.Column>
                                            <Grid.Column width={7}>
                                                <Form.Field>
                                                    <label>{t("Balance")}</label>
                                                    <Radio
                                                        disabled={loading || fields_disabled}
                                                        name="item_type"
                                                        value="B"
                                                        checked={this.state.itemTypeSelectRadioGroup === "B"}
                                                        onChange={this.handleItemDetailsRadioChange} />
                                                </Form.Field>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                    {(active_invoice_item.item_type === "E" || active_invoice_item.item_type === "B") && (
                                        <Form.Field >
                                            <label>{t("Invoicing period start date")}</label>
                                            <SemanticDatepicker
                                                firstDayOfWeek="1"
                                                disabled={loading || fields_disabled}
                                                locale={fiLocale}
                                                placeholder={active_invoice_item.invoice_period_start_date !== null ? (new Date(active_invoice_item.invoice_period_start_date).toLocaleString('fi-FI', { dateStyle: 'short' })) : (null)}
                                                onDateChange={(value) => this.handleInvoiceItemPeriodStartDateChange(value)}
                                                format='DD.MM.YYYY' />
                                        </Form.Field>
                                    )}
                                    {(active_invoice_item.item_type === "E" || active_invoice_item.item_type === "B") && (
                                        <Form.Field >
                                            <label>{t("Invoicing period end date")}</label>
                                            <SemanticDatepicker
                                                firstDayOfWeek="1"
                                                disabled={loading || fields_disabled}
                                                locale={fiLocale}
                                                placeholder={active_invoice_item.invoice_period_end_date !== null ? (new Date(active_invoice_item.invoice_period_end_date).toLocaleString('fi-FI', { dateStyle: 'short' })) : (null)}
                                                onDateChange={(value) => this.handleInvoiceItemPeriodEndDateChange(value)}
                                                format='DD.MM.YYYY' />
                                        </Form.Field>
                                    )}

                                    <Form.Field required>
                                        <label>{t("Name")} {active_invoice_item.name ? (`${active_invoice_item.name.length}/100`) : ("0/100")}</label>
                                        <Input
                                            required
                                            value={active_invoice_item.name}
                                            name="name"
                                            onChange={this.handleItemDetailsChange} />
                                    </Form.Field>
                                    <Form.Field >
                                        <label>{t("Description")} {active_invoice_item.description ? (`${active_invoice_item.description.length}/100`) : ("0/100")}</label>
                                        <Input
                                            value={active_invoice_item.description}
                                            name="description"
                                            onChange={this.handleItemDetailsChange} />
                                    </Form.Field>
                                    {active_invoice_item.item_type === "D" && (
                                        <>
                                            <Form.Field>
                                                <Checkbox
                                                    name="add_sensor_number_to_item_comment"
                                                    label={t("Add sensor number to description")}
                                                    onChange={this.handleItemDetailsCheckboxChange}
                                                    checked={active_invoice_item.add_sensor_number_to_item_comment}
                                                    value={active_invoice_item.add_sensor_number_to_item_comment}
                                                />
                                            </Form.Field>
                                            <Form.Field>
                                                <Checkbox
                                                    name="add_invoicing_period_to_item_comment"
                                                    label={t("Add invoicing period to description")}
                                                    onChange={this.handleItemDetailsCheckboxChange}
                                                    checked={active_invoice_item.add_invoicing_period_to_item_comment}
                                                    value={active_invoice_item.add_invoicing_period_to_item_comment}
                                                />
                                            </Form.Field>
                                        </>
                                    )}
                                    {active_invoice_item.item_type === "E" && (
                                        <>
                                            <Form.Field>
                                                <Checkbox
                                                    name="add_sensor_number_to_item_comment"
                                                    label={t("Add sensor number to description")}
                                                    onChange={this.handleItemDetailsCheckboxChange}
                                                    checked={active_invoice_item.add_sensor_number_to_item_comment}
                                                    value={active_invoice_item.add_sensor_number_to_item_comment}
                                                />
                                            </Form.Field>
                                            <Form.Field>
                                                <Checkbox
                                                    name="add_invoicing_period_to_item_comment"
                                                    label={t("Add invoicing period to description")}
                                                    onChange={this.handleItemDetailsCheckboxChange}
                                                    checked={active_invoice_item.add_invoicing_period_to_item_comment}
                                                    value={active_invoice_item.add_invoicing_period_to_item_comment}
                                                />
                                            </Form.Field>
                                            <Form.Field>
                                                <Checkbox
                                                    name="add_total_estimate_to_item_comment"
                                                    label={t("Add total estimate to description")}
                                                    onChange={this.handleItemDetailsCheckboxChange}
                                                    checked={active_invoice_item.add_total_estimate_to_item_comment}
                                                    value={active_invoice_item.add_total_estimate_to_item_comment}
                                                />
                                            </Form.Field>
                                        </>
                                    )}
                                    <Form.Field required>
                                        <label>{t("Amount")}</label>
                                        <Input
                                            disabled={active_invoice_item.update_value_from_customer_dc_details || active_invoice_item.update_value_from_customer_estimate_details}
                                            required
                                            value={active_invoice_item.amount}
                                            name="amount"
                                            onChange={this.handleItemDetailsChange} />
                                    </Form.Field>
                                    {active_invoice_item.item_type === "D" && (
                                        <>
                                        <Form.Field>
                                            <Checkbox
                                                name="update_value_from_customer_dc_details"
                                                label={t("Update amount from customer data collection details")}
                                                onChange={this.handleItemDetailsCheckboxChange}
                                                checked={active_invoice_item.update_value_from_customer_dc_details}
                                                value={active_invoice_item.update_value_from_customer_dc_details}
                                            />
                                        </Form.Field>
                                        <Form.Field>
                                            <Checkbox
                                                name="update_year_estimate"
                                                label={t("Update year estimate based on real consumption")}
                                                onChange={this.handleItemDetailsCheckboxChange}
                                                checked={active_invoice_item.update_year_estimate}
                                                value={active_invoice_item.update_year_estimate}
                                            />
                                        </Form.Field>
                                        </>
                                    )}
                                    {active_invoice_item.item_type === "E" && (
                                        <Form.Field>
                                            <Checkbox
                                                name="update_value_from_customer_estimate_details"
                                                label={t("Update amount from customer estimate details")}
                                                onChange={this.handleItemDetailsCheckboxChange}
                                                checked={active_invoice_item.update_value_from_customer_estimate_details}
                                                value={active_invoice_item.update_value_from_customer_estimate_details}
                                            />
                                        </Form.Field>
                                    )}
                                    <Form.Field required>
                                        <label>{t("Net value €")}</label>
                                        <Input
                                            required
                                            value={active_invoice_item.net_price}
                                            name="net_price"
                                            onChange={this.handleItemDetailsChange} />
                                    </Form.Field>
                                    <Form.Field required>
                                        <label>{t("Discount %")}</label>
                                        <Input
                                            required
                                            value={active_invoice_item.discount}
                                            name="discount"
                                            onChange={this.handleItemDetailsChange} />
                                    </Form.Field>
                                    <Form.Field required>
                                        <label>{t("Vat %")}</label>
                                        <Select
                                            required
                                            fluid
                                            search
                                            options={this.state.vat_value_options}
                                            value={active_invoice_item.vat_value}
                                            placeholder={active_invoice_item.vat_value}
                                            name="vat_value"
                                            onChange={this.handleItemDetailsSelectChange} />
                                    </Form.Field>


                                    {error && <Message error header={t("Error")} content={error} />}
                                    {success_mgs && <Message positive header={success_mgs} />}
                                    { }
                                    <Button primary type="button" loading={loading} disabled={loading || edit_disabled} onClick={(e) => this.handleItemDetailsSubmit(e)}>
                                        {t("Save")}
                                    </Button>
                                </Form>
                            )}
                        </Container>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button type="button" negative onClick={() => this.closeItemDetailsModal()} >{t("Close")}</Button>
                    </Modal.Actions>
                </Modal>
                <Modal size='small' open={customers_select_modal_open} dimmer="inverted" scrolling="true">
                    <Modal.Header>
                        <Header as="h3">{t("Select customers")}</Header>
                        <Button type="button" positive icon='add circle' content={t("Add customers")} onClick={() => this.handleAddCustomers()} />
                        <Input
                            size="mini"
                            icon='search'
                            value={customer_search}
                            name="customer_search"
                            onChange={this.handleSearchChange} />
                        <Button type="button" floated='right' negative onClick={() => this.closeCustomersAddModal()} >{t("Close")}</Button>
                    </Modal.Header>
                    <Modal.Content>
                        <Container>
                            {customer_options && (
                                <React.Fragment>
                                    <Header as="h3" color="blue">{t("Customers")}</Header>
                                    {filtered_customers_list.length > 0 && (
                                        <Table striped selectable size='small'>
                                            <Table.Header>
                                                <Table.Row>
                                                    <Table.HeaderCell>
                                                        <Popup content={t("Select all visible customers")} trigger={
                                                            <Icon link name="check circle outline" onClick={() => this.selectAllCustomers(filtered_customers_list)} />
                                                        } />
                                                    </Table.HeaderCell>
                                                    <Table.HeaderCell>{t("Name")}</Table.HeaderCell>
                                                    <Table.HeaderCell>{t("Address")}</Table.HeaderCell>
                                                    <Table.HeaderCell>{t("VAT")}</Table.HeaderCell>
                                                </Table.Row>
                                            </Table.Header>
                                            <Table.Body>
                                                {filtered_customers_list.map((customer) => {
                                                    if (customer.is_active) {
                                                        return (
                                                            <Table.Row key={customer.id}>
                                                                <Table.Cell >
                                                                    <Checkbox
                                                                        checked={this.handleCheckIfSelected(customer) === true}
                                                                        value={customer}
                                                                        onChange={this.handleAddCustomersCheckboxChange} />
                                                                </Table.Cell>
                                                                <Table.Cell >
                                                                    {customer.name}
                                                                </Table.Cell>
                                                                <Table.Cell >{customer.address}, {customer.postal_code} {customer.city}</Table.Cell>
                                                                <Table.Cell >{customer.vat}</Table.Cell>
                                                            </Table.Row>
                                                        );
                                                    }
                                                })}
                                            </Table.Body>
                                        </Table>
                                    )}
                                </React.Fragment>
                            )}
                        </Container>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button type="button" negative onClick={() => this.closeCustomersAddModal()} >{t("Close")}</Button>
                    </Modal.Actions>
                </Modal>


            </Shell>
        );
    }
}
const mapStateToProps = state => {
    return {
        activeCompanyId: state.company.activeCompanyId,
        isAuthenticated: state.auth.token !== null
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setActivity: () => dispatch(setActivity())
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(withTranslation('invoicedetailsmultiplecustomers')(InvoiceDetailsMultipleCustomers))
);