// Copyright Caproc Oy
// System name: Aqua-Net 
// System module: Project
// Author: TS
//
// 1.2.2020 TS Initial version
// 13.5.2020 TS Added fixed decimals 2 to net_price and net_cost to limit amount of decimals
// 15.5.2020 TS Added Select for vat value selection options instead of free input
// 8.8.2020 TS Added character amount counters
// 10.6.2020 TS Added report_name

import React from "react";
import { Form, Input, Message, Header, Button, Select, Checkbox, Popup } from "semantic-ui-react";
import Shell from "./Shell";
import { authAxios } from "../../utils";
import { setActivity } from "../../store/actions/auth";
import { withRouter, Redirect } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { createProductDetailsURL, productDetailsURL, updateProductDetailsURL, fetchProductGroupsURL, fetchMeasureUnitsURL, vatValueOptionsURL } from "../../constants";


class productDetails extends React.Component {
    state = {
        product_id: null,
        company: "",
        name: "",
        report_name: "",
        description: "",
        code: "",
        net_price: "0",
        net_cost: "0",
        measure_unit: "kpl",
        vat_value: "24",
        product_group: "",
        recurring: false,
        is_active: false,
        product_group_options: null,
        measure_unit_options: null,
        error: null,
        success_mgs: null,
        loading: false,
        create: false,
        vat_value_options: [],
    };

    componentDidMount() {
        this.props.setActivity();
        if (this.props.match.params.product_id === "create") {
            this.setState({
                create: true,
                is_active: true
            })
        } else {
            this.handleDetails();
        }
        this.handleProductGroups();
        this.handleMeasureUnits();
        this.handleFetchVatValueOptions();
    }

    handleFetchVatValueOptions() {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;

        authAxios
            .get(vatValueOptionsURL(activeCompanyId))
            .then(res => {
                this.setState({
                    vat_value_options: this.handleFormatVatValueOptions(res.data),
                    error: null,
                    success_mgs: null,
                    loading: false,
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    success_mgs: null,
                    error: err
                });
            });
    };

    handleFormatVatValueOptions = options => {
        const keys = Object.keys(options);
        return keys.map(k => {
            return {
                key: options[k].value,
                text: options[k].name,
                value: parseFloat(options[k].value)
            };
        });
    };

    handleProductGroups = () => {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;
        authAxios
            .get(fetchProductGroupsURL(activeCompanyId))
            .then(res => {
                this.setState({
                    product_group_options: this.handleFormatPGOptions(res.data),
                    error: null,
                    loading: false
                });
            })
            .catch(err => {
                this.setState({
                    product_groups: null,
                    loading: false,
                    error: err
                });
            });
    };

    handleFormatPGOptions = options => {
        const keys = Object.keys(options);
        return keys.map(k => {
            return {
                key: options[k].id,
                text: options[k].name,
                value: options[k].id
            };
        });
    };

    handleMeasureUnits = () => {
        this.setState({
            loading: true
        });
        const { activeCompanyId, i18n } = this.props;
        authAxios
            .get(fetchMeasureUnitsURL(activeCompanyId, i18n.language))
            .then(res => {
                this.setState({
                    measure_unit_options: this.handleFormatOptions(res.data),
                    error: null,
                    loading: false
                });
            })
            .catch(err => {
                this.setState({
                    product_groups: null,
                    loading: false,
                    error: err
                });
            });
    };


    handleFormatOptions = options => {
        const keys = Object.keys(options);
        return keys.map(k => {
            return {
                key: options[k].value,
                text: options[k].name,
                value: options[k].value
            };
        });
    };

    handleDetails = () => {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;
        const { product_id } = this.props.match.params;

        authAxios
            .get(productDetailsURL(activeCompanyId, product_id))
            .then(res => {
                this.setState({
                    product_id: res.data.id,
                    company: res.data.company,
                    name: res.data.name,
                    report_name: res.data.report_name,
                    description: res.data.description,
                    code: res.data.code,
                    net_price: res.data.net_price,
                    net_cost: res.data.net_cost,
                    measure_unit: res.data.measure_unit,
                    vat_value: res.data.vat_value,
                    product_group: res.data.product_group,
                    recurring: res.data.recurring,
                    is_active: res.data.is_active,
                    error: null,
                    success_mgs: null,
                    loading: false
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    success_mgs: null,
                    error: err
                });
            });
    };

    handleSubmit = e => {
        e.preventDefault();
        if (this.state.net_price === "") {
            this.setState({
                net_price: "0"
            });
        }
        if (this.state.vat_value === "") {
            this.setState({
                vat_value: "0"
            });
        }
        this.setState({
            loading: true
        });
        const {
            company,
            name,
            report_name,
            description,
            code,
            net_price,
            net_cost,
            measure_unit,
            vat_value,
            product_group,
            recurring,
            is_active,
            product_id,
            create
        } = this.state;
        const { activeCompanyId, t } = this.props;
        if (name === "") {
            this.setState({
                error: t("Please fill in name"),
                success_mgs: null,
                loading: false
            });
        } else if (report_name === "") {
            this.setState({
                error: t("Please fill in name on Reports"),
                success_mgs: null,
                loading: false
            });
        } else if (net_price.toString().includes(",")) {
            this.setState({
                error: t("Please use dot as decimal separator in Net Price instead of comma"),
                success_mgs: null,
                loading: false
            });
        } else if (vat_value === "") {
            this.setState({
                error: t("Please fill in VAT"),
                success_mgs: null,
                loading: false
            });
        } else if (product_group === "") {
            this.setState({
                error: t("Please select Product group"),
                success_mgs: null,
                loading: false
            });
        } else if (vat_value.toString().includes(",")) {
            this.setState({
                error: t("Please use dot as decimal separator in VAT value instead of comma"),
                success_mgs: null,
                loading: false
            });
        } else if (name.length > 100) {
            this.setState({
                error: t("Max amount of characters is 100 for Name"),
                success_mgs: null,
                loading: false
            });
        } else if (description.length > 100) {
            this.setState({
                error: t("Max amount of characters is 100 for Description"),
                success_mgs: null,
                loading: false
            });
        } else if (code.length > 70) {
            this.setState({
                error: t("Max amount of characters is 50 for Code"),
                success_mgs: null,
                loading: false
            });
        } else {
            if (create) {
                authAxios
                    .post(createProductDetailsURL(activeCompanyId), {
                        company: activeCompanyId,
                        name,
                        report_name,
                        description,
                        code,
                        net_price: parseFloat(net_price).toFixed(2),
                        net_cost: parseFloat(net_cost).toFixed(2),
                        measure_unit,
                        vat_value,
                        product_group,
                        recurring,
                        is_active,
                        author: localStorage.getItem("UserID")
                    })
                    .then(res => {
                        // Goto Products page 
                        this.props.history.push("/product/products/");
                    })
                    .catch(err => {
                        this.setState({
                            loading: false,
                            success_mgs: null,
                            error: err
                        });
                    });

            } else {
                authAxios
                    .put(updateProductDetailsURL(activeCompanyId, product_id), {
                        company,
                        name,
                        report_name,
                        description,
                        code,
                        net_price: parseFloat(net_price).toFixed(2),
                        net_cost: parseFloat(net_cost).toFixed(2),
                        measure_unit,
                        vat_value,
                        product_group,
                        recurring,
                        is_active
                    })
                    .then(res => {
                        // Goto Products page 
                        this.props.history.push("/product/products/");
                    })
                    .catch(err => {
                        this.setState({
                            loading: false,
                            success_mgs: null,
                            error: err
                        });
                    });
            }
        }
    };

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value,
            success_mgs: null,
            error: null
        });
    };

    handleSelectChange = (e, { name, value }) => {
        this.setState({
            [name]: value
        });
    };

    handleCheckboxChange = (e, { name, value }) => {
        this.setState({
            [name]: !value
        });
    };

    render() {
        const {
            name,
            report_name,
            description,
            code,
            net_price,
            net_cost,
            measure_unit,
            measure_unit_options,
            vat_value,
            product_group,
            product_group_options,
            recurring,
            is_active,
            error,
            success_mgs,
            loading,
            create
        } = this.state;
        const { isAuthenticated, t } = this.props;
        if (!isAuthenticated) {
            return <Redirect to="/login" />;
        }
        // console.log(this.state);
        return (
            <Shell>
                <Header as="h4">{t("Product details")}</Header>
                <Form error={error !== null}>
                    <Form.Field required>
                        <label>{t("Name")} {name ? (`${name.length}/100`) : ("0/100")}</label>
                        <Input
                            required
                            value={name}
                            name="name"
                            onChange={this.handleChange} />
                    </Form.Field>
                    <Form.Field required>
                        <Popup content={t("Display name on reports")} trigger={
                            <label>{t("Name on reports")} {report_name ? (`${report_name.length}/100`) : ("0/100")}</label>
                        } />
                        <Input
                            required
                            value={report_name}
                            name="report_name"
                            onChange={this.handleChange} />
                    </Form.Field>
                    <Form.Field>
                        <label>{t("Description")} {description ? (`${description.length}/100`) : ("0/100")}</label>
                        <Input
                            value={description}
                            name="description"
                            onChange={this.handleChange} />
                    </Form.Field>
                    <Form.Field>
                        <label>{t("Code")} {code ? (`${code.length}/70`) : ("0/70")}</label>
                        <Input
                            value={code}
                            name="code"
                            onChange={this.handleChange} />
                    </Form.Field>
                    <Form.Field required>
                        <label>{t("Net price")}</label>
                        <Input
                            required
                            value={net_price}
                            name="net_price"
                            onChange={this.handleChange} />
                    </Form.Field>
                    <Form.Field required>
                        <label>{t("Net cost")}</label>
                        <Input
                            required
                            value={net_cost}
                            name="net_cost"
                            onChange={this.handleChange} />
                    </Form.Field>
                    <Form.Field required>
                        <label>{t("Measure unit")}</label>
                        <Select
                            search
                            fluid
                            name='measure_unit'
                            options={measure_unit_options}
                            placeholder={measure_unit}
                            onChange={this.handleSelectChange}
                            value={measure_unit}
                        />
                    </Form.Field>
                    <Form.Field required>
                        <label>{t("VAT%")}</label>
                        <Select
                            required
                            fluid
                            search
                            options={this.state.vat_value_options}
                            value={vat_value}
                            placeholder={vat_value}
                            name="vat_value"
                            onChange={this.handleSelectChange} />
                    </Form.Field>
                    <Form.Field required>
                        <label>{t("Product group")}</label>
                        <Select
                            required
                            search
                            fluid
                            name='product_group'
                            options={product_group_options}
                            placeholder={product_group}
                            onChange={this.handleSelectChange}
                            value={product_group}
                        />
                    </Form.Field>
                    <Form.Field>
                        <Checkbox
                            label={<label>{t("Recurring")}</label>}
                            name="recurring"
                            onChange={this.handleCheckboxChange}
                            checked={recurring}
                            value={recurring}
                        />
                    </Form.Field>
                    <Form.Field>
                        <Checkbox
                            label={<label>{t("Is active")}</label>}
                            name="is_active"
                            onChange={this.handleCheckboxChange}
                            checked={is_active}
                            value={is_active}

                        />
                    </Form.Field>

                    {error && <Message error header={t("Error")} content={error} />}
                    {success_mgs && <Message positive header={success_mgs} />}
                    <Button primary type="button" onClick={(e) => this.handleSubmit(e)} loading={loading} disabled={loading}>
                        {create ? (t("Create")) : (t("Save"))}
                    </Button>
                </Form>
            </Shell>
        );
    }
}
const mapStateToProps = state => {
    return {
        activeCompanyId: state.company.activeCompanyId,
        isAuthenticated: state.auth.token !== null
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setActivity: () => dispatch(setActivity())
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(withTranslation('productdetails')(productDetails))
);