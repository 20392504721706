// Copyright Caproc Oy
// System name: Aqua-Net 
// System module: LandingPage
// Author: TS
//
// 1.2.2020 TS Initial version
// 9.4.2020 TS Added Projects in to the Dashboard
// 13.5.2020 TS Added GDPR definitions link on footer
// 11.7.2020 TS Added Company Selection banner

import React from "react";
// import { Bar, Line, Pie, Doughnut } from 'react-chartjs-2';
import {
    Container,
    Grid,
    Header,
    Segment,
    Statistic,
    Card,
    Image,
    Divider,
    Responsive,
    Dropdown,
    Icon
} from "semantic-ui-react";
import { connect } from "react-redux";
import { Link, withRouter, Redirect } from "react-router-dom";

import { withTranslation } from 'react-i18next';

import Integration_pic from '../assets/images/integration.jpg';
import Wireless_pic from '../assets/images/wireless-signal.png';
import Dashboard_pic from '../assets/images/dashboard_speed.jpg';
import Cloud_pic from '../assets/images/cloud.png';
import Aqua_net_dashboard_pic from '../assets/images/aqua-net_dashboard.png';
import Customer_portal from '../assets/images/customer_portal.png';
import Invoice from '../assets/images/invoice.jpg';

import { fetchDashboardFinaStatisticsURL, fetchGdprDefinitionsURL } from '../constants';
import { authAxios, getWidth, CompanySelection } from "../utils";


class HomepageLayout extends React.Component {

    state = {
        fina_stats: null,
        current_company_id: null,
        loading: false,
        error: null
    };

    // componentDidMount() {
    //     this.handleFetchFinaStatistics();
    // }

    handleFetchFinaStatistics = () => {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;
        authAxios
            .get(fetchDashboardFinaStatisticsURL(activeCompanyId, new Date().getFullYear(), new Date().getMonth() + 1))
            .then(res => {
                this.setState({
                    fina_stats: res.data,
                    error: null,
                    loading: false
                });
            })
            .catch(err => {
                this.setState({
                    fina_stats: null,
                    loading: false,
                    error: err
                });
            });
        
    };

    fetchGdprDefinitions = () => {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;
        authAxios
            .get(fetchGdprDefinitionsURL(activeCompanyId), { responseType: 'blob' })
            .then(res => {
                //Create a Blob from the PDF Stream
                const file = new Blob(
                    [res.data],
                    { type: 'application/pdf' });
                //Build a URL from the file
                const fileURL = URL.createObjectURL(file);
                // // //Open the URL on new Window
                window.open(fileURL);

                // const link = document.createElement('a');
                // link.href = fileURL;
                // link.setAttribute('download', 'lasku.pdf'); //or any other extension
                // document.body.appendChild(link);
                // link.click();
                this.setState({
                    error: null,
                    loading: false
                });
                this.closeModal();
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    error: err
                });
            });
    };


    render() {
        const { activeCompanyId, isAuthenticated, t, activeRoles, activeRole } = this.props;
        const { fina_stats } = this.state;
        if (activeCompanyId !== null && this.state.current_company_id !== activeCompanyId) {
            if (activeRole && activeRole.role !== "C") {
                this.handleFetchFinaStatistics();
                this.setState({current_company_id: activeCompanyId});
            }
        }
        const is_desktop =  getWidth() > Responsive.onlyMobile.maxWidth ? true : false;
        // console.log(this.state);
        return (
            <React.Fragment>
                {(isAuthenticated && activeRoles !== null && activeRole === null) && (
                    <Redirect to="/account/role-select" />
                )}
                {(isAuthenticated && activeRole !== null && activeRole.role === "C") && (
                    <Redirect to="/customer-portal" />
                )}
                {(isAuthenticated && activeRole !== null) ? (
                    <React.Fragment>
                <Container><CompanySelection/></Container>
                <Segment style={{ padding: "5em 0em" }} vertical>
                    {/* <Header as="h2" style={{ fontSize: "2em" }} textAlign="center">{t('welcometext')}</Header> */}
                    
                    <br />
                    <Grid container stackable verticalAlign="middle">
                        {fina_stats && (
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <Card.Group centered>
                                        <Card>
                                            <Card.Content>
                                                <Link to="/company/company-details/">
                                                    <Header>{fina_stats.current_company}</Header>
                                                </Link>
                                            </Card.Content>
                                            <Card.Content>
                                                <Statistic.Group horizontal size='mini'>
                                                    <Link to="/customers/">
                                                        <Statistic >
                                                            <Statistic.Value>{fina_stats.customers_amount}</Statistic.Value>
                                                        </Statistic>
                                                        &nbsp;
                                                        &nbsp;
                                                        <b style={{ fontSize: "1.15em", color: "black" }}>{t("Customers")}</b>
                                                    </Link>
                                                    <Link to="/contracts/">
                                                        <Statistic>
                                                            <Statistic.Value>{fina_stats.contracts_pcs}</Statistic.Value>
                                                        </Statistic>
                                                        &nbsp;
                                                        &nbsp;
                                                        <b style={{ fontSize: "1.15em", color: "black" }}>{t("Contracts")}</b>
                                                    </Link>
                                                    <Link to="/contracts/">
                                                        <Statistic>
                                                            <Statistic.Value>{fina_stats.contracts_total_per_month.toFixed(2)}€</Statistic.Value>
                                                        </Statistic>
                                                        &nbsp;
                                                        &nbsp;
                                                        <b style={{ fontSize: "1.15em", color: "black" }}>{t("Contracts/month")}</b>
                                                    </Link>
                                                    <Link to="/projects/">
                                                        <Statistic>
                                                            <Statistic.Value>{fina_stats.projects_pcs}</Statistic.Value>
                                                        </Statistic>
                                                        &nbsp;
                                                        &nbsp;
                                                        <b style={{ fontSize: "1.15em", color: "black" }}>{t("Projects")}</b>
                                                    </Link>
                                                    <Link to="/projects/">
                                                        <Statistic>
                                                            <Statistic.Value>{fina_stats.current_month_project_total.toFixed(2)}€</Statistic.Value>
                                                        </Statistic>
                                                        &nbsp;
                                                        &nbsp;
                                                        <b style={{ fontSize: "1.15em", color: "black" }}>{t("Projects")}/{new Intl.DateTimeFormat('fi-FI', { month: 'long' }).format(new Date())}</b>
                                                    </Link>
                                                </Statistic.Group>
                                            </Card.Content>
                                        </Card>
                                        <Card>
                                            <Card.Content>

                                                <Link to="/invoice/invoices/C/">
                                                <Header>{t("Invoices")}</Header>
                                                </Link>
                                                <Card.Meta>{new Intl.DateTimeFormat('fi-FI', { month: 'long' }).format(new Date())} {new Date().getFullYear()}</Card.Meta>
                                            </Card.Content>
                                            <Card.Content>

                                                <br />
                                                &nbsp;
                                                &nbsp;
                                                &nbsp;
                                                &nbsp;
                                                &nbsp;
                                                &nbsp;
                                                &nbsp;
                                                &nbsp;
                                                &nbsp;
                                                &nbsp;
                                                &nbsp;
                                                &nbsp;
                                                &nbsp;
                                                &nbsp;

                                                <Statistic size='tiny'>
                                                    <Statistic.Value>{fina_stats.current_month_total.toFixed(2)} €</Statistic.Value>
                                                    <Statistic.Label>{fina_stats.current_month_pcs} {t("pcs")}</Statistic.Label>
                                                </Statistic>
                                            </Card.Content>
                                            <Card.Content >
                                                {Math.sign(fina_stats.prev_vs_current_percent) == 1 ? (
                                                    <Statistic horizontal color="green" size='mini'>
                                                        <Statistic.Value>{fina_stats.prev_vs_current_percent.toFixed(1)} %</Statistic.Value>
                                                    </Statistic>
                                                ) : (
                                                    <Statistic horizontal color="red" size='mini'>
                                                        <Statistic.Value>{fina_stats.prev_vs_current_percent.toFixed(1)} %</Statistic.Value>
                                                    </Statistic>
                                                )}
                                                &nbsp;
                                                &nbsp;
                                                <b style={{ fontSize: "1em" }}>{t("previous month")}</b>

                                            </Card.Content>
                                        </Card>
                                        <Card>
                                            <Card.Content>
                                                <Link to="/invoice/invoices/C/">
                                                    <Header>{t("Open invoices")}</Header>
                                                </Link>
                                            </Card.Content>
                                            <Card.Content>
                                                <Statistic.Group horizontal size='mini'>
                                                    <Link to="/invoice/invoices/C/">
                                                        <Statistic>
                                                            <Statistic.Value>{fina_stats.created_total.toFixed(2)} €</Statistic.Value>
                                                            {/* <Statistic.Label>Uudet (12)</Statistic.Label> */}
                                                        </Statistic>
                                                        &nbsp;
                                                        &nbsp;
                                                        <b style={{ fontSize: "1.15em", color: "black" }}>{t("Created")} ({fina_stats.created_pcs})</b>
                                                    </Link>
                                                    <Link to="/invoice/invoices/A/">
                                                        <Statistic>
                                                            <Statistic.Value>{fina_stats.approved_total.toFixed(2)} €</Statistic.Value>
                                                            {/* <Statistic.Label>Hyväksytyt (5)</Statistic.Label> */}
                                                        </Statistic>
                                                        &nbsp;
                                                        &nbsp;
                                                        <b style={{ fontSize: "1.15em", color: "black" }}>{t("Approved")} ({fina_stats.approved_pcs})</b>
                                                    </Link>
                                                    <Link to="/invoice/invoices/S/">
                                                        <Statistic>
                                                            <Statistic.Value>{fina_stats.sent_total.toFixed(2)} €</Statistic.Value>
                                                            {/* <Statistic.Label>Lähetetyt (10)</Statistic.Label> */}
                                                        </Statistic>
                                                        &nbsp;
                                                        &nbsp;
                                                        <b style={{ fontSize: "1.15em", color: "black" }}>{t("Sent")} ({fina_stats.sent_pcs})</b>
                                                    </Link>
                                                    <Link to="/invoice/invoices/B/">
                                                        <Statistic color="red">
                                                            <Statistic.Value>{fina_stats.abnormal_total.toFixed(2)} €</Statistic.Value>
                                                            {/* <Statistic.Label>Erääntyneet (3)</Statistic.Label> */}
                                                        </Statistic>
                                                        &nbsp;
                                                        &nbsp;
                                                        <b style={{ fontSize: "1.15em", color: "black" }}>{t("Abnormal")} ({fina_stats.overdue_pcs})</b>
                                                    </Link>
                                                    <Link to="/invoice/invoices/O/">
                                                        <Statistic color="red">
                                                            <Statistic.Value>{fina_stats.overdue_total.toFixed(2)} €</Statistic.Value>
                                                            {/* <Statistic.Label>Erääntyneet (3)</Statistic.Label> */}
                                                        </Statistic>
                                                        &nbsp;
                                                        &nbsp;
                                                        <b style={{ fontSize: "1.15em", color: "black" }}>{t("Overdue")} ({fina_stats.overdue_pcs})</b>
                                                    </Link>
                                                </Statistic.Group>
                                            </Card.Content>
                                            <Card.Content>
                                                <Link to="/invoice/invoices/C/">
                                                    <Statistic horizontal size='mini'>
                                                        <Statistic.Value>{fina_stats.open_total.toFixed(2)} €</Statistic.Value>
                                                        {/* <Statistic.Label>Kaikki (30)</Statistic.Label> */}
                                                    </Statistic>
                                                    &nbsp;
                                                        &nbsp;
                                                        <b style={{ fontSize: "1.15em", color: "black" }}>{t("All")} ({fina_stats.open_pcs})</b>
                                                </Link>
                                            </Card.Content>
                                        </Card>
                                    </Card.Group>
                                </Grid.Column>
                            </Grid.Row>
                        )}
                    </Grid>
                    {/* <Header as="h2" style={{ fontSize: "2em" }} textAlign="center">Veden kulutus</Header>
                    <br />
                    <Grid container stackable verticalAlign="middle">
                        <Grid.Row centered>
                            <Grid.Column width={12}>


                                <div>

                                    <Bar data={data} options={options} />

                                </div>
                            </Grid.Column>
                        </Grid.Row>

                    </Grid>
                    <Header style={{ fontSize: "2em" }} textAlign="center">2019</Header> */}
                </Segment>
                </React.Fragment>
                ) : (
                    <React.Fragment>
                        <Segment inverted vertical  style={{ padding: "5em 0em" }}>
                          <Container text>
                            <Header
                            as='h1'
                            content={t("Main header")}
                            inverted
                            textAlign="center"
                            style={{
                                fontSize:   '4em',
                                fontWeight: 'normal',
                                marginBottom: 0,
                            }}
                            />
                            <Header
                            as='h2'
                            content={t("Main header content")}
                            inverted
                            textAlign="center"
                            style={{
                                fontSize: '1.7em',
                                fontWeight: 'normal',
                                marginTop:  '1.5em',
                            }}
                            />
                            {/* <Button primary size='huge'>
                            Get Started
                            <Icon name='right arrow' />
                            </Button> */}
                        </Container>
                        </Segment>
                      <Segment style={{ padding: '8em 0em' }} vertical>
                    <Grid container stackable verticalAlign='middle'>
                      <Grid.Row>
                        <Grid.Column width={8}>
                          <Header as='h3' style={{ fontSize: '2em' }}>
                            {t("Body 1 header")} 
                          </Header>
                          <p style={{ fontSize: '1.33em' }}>
                            {t("Body 1 content")}  
                          </p>
                          <Header as='h3' style={{ fontSize: '2em' }}>
                            {t("Body 2 header")} 
                          </Header>
                          <p style={{ fontSize: '1.33em' }}>
                            {t("Body 2 content")}  
                          </p>
                          <Header as='h3' style={{ fontSize: '2em' }}>
                            {t("Body 3 header")} 
                          </Header>
                          <p style={{ fontSize: '1.33em' }}>
                            {t("Body 3 content")}  
                          </p>
                        </Grid.Column>
                        <Grid.Column floated='right' width={6}>
                          <Image bordered rounded size='large' src={Aqua_net_dashboard_pic} />
                        </Grid.Column>
                      </Grid.Row>
                      {/* <Grid.Row>
                        <Grid.Column textAlign='center'>
                          <Button size='huge'>Check Them Out</Button>
                        </Grid.Column>
                      </Grid.Row> */}
                    </Grid>
                  </Segment>
              
                  <Segment style={{ padding: '0em' }} vertical>
                    <Grid celled='internally' columns='equal' stackable>
                      <Grid.Row textAlign='center'>
                        <Grid.Column style={{ paddingBottom: '5em', paddingTop: '5em' }}>
                        <Image avatar size="tiny" centered src={Cloud_pic} />
                          <Header as='h3' style={{ fontSize: '2em' }}>
                            {t("Benefit 1 header")}  
                          </Header>
                          <p style={{ fontSize: '1.33em' }}>
                            {t("Benefit 1 content")} 
                          </p>
                        </Grid.Column>
                        <Grid.Column style={{ paddingBottom: '5em', paddingTop: '5em' }}>
                        <Image rounded size="small" centered src={Dashboard_pic} />
                          <Header as='h3' style={{ fontSize: '2em' }}>
                            {t("Benefit 2 header")} 
                          </Header>
                          <p style={{ fontSize: '1.33em' }}>
                            {t("Benefit 2 content")}
                          </p>
                        </Grid.Column>
                        <Grid.Column style={{ paddingBottom: '5em', paddingTop: '5em' }}>
                        <Image size="tiny" centered src={Wireless_pic} />
                          <Header as='h3' style={{ fontSize: '2em' }}>
                            {t("Benefit 3 header")} 
                          </Header>
                          <p style={{ fontSize: '1.33em' }}>
                            {t("Benefit 3 content")}
                          </p>
                        </Grid.Column>
                        <Grid.Column style={{ paddingBottom: '5em', paddingTop: '5em' }}>
                        <Image rounded size="small" centered src={Integration_pic} />
                          <Header as='h3' style={{ fontSize: '2em' }}>
                            {t("Benefit 4 header")} 
                          </Header>
                          <p style={{ fontSize: '1.33em' }}>
                            {t("Benefit 4 content")}
                          </p>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row textAlign='center'>
                        <Grid.Column style={{ paddingBottom: '5em', paddingTop: '5em' }}>
                        <Image  size="small" centered src={Customer_portal} />
                          <Header as='h3' style={{ fontSize: '2em' }}>
                            {t("Benefit 5 header")}  
                          </Header>
                          <p style={{ fontSize: '1.33em' }}>
                            {t("Benefit 5 content")} 
                          </p>
                        </Grid.Column>
                        <Grid.Column style={{ paddingBottom: '5em', paddingTop: '5em' }}>
                        <Image rounded size="small" centered src={Invoice} />
                          <Header as='h3' style={{ fontSize: '2em' }}>
                            {t("Benefit 6 header")} 
                          </Header>
                          <p style={{ fontSize: '1.33em' }}>
                            {t("Benefit 6 content")}
                          </p>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Segment>
              
                  <Segment style={{ padding: '8em 0em' }} vertical>
                    <Container text>
                      <Header textAlign="center" as='h3' style={{ fontSize: '2em' }}>
                        {t("Contact header")} 
                      </Header>
                      <p style={{ fontSize: '1.33em' }}>
                        {t("Contact content part 1")} <br/> <br/>
                        {t("Contact content part 2")}
                        <br/><br/>
                      </p>
                      {/* <Button as='a' size='large'>
                        Read More
                      </Button> */}
              
                      <Divider
                        as='h4'
                        className='header'
                        horizontal
                        style={{ margin: '3em 0em', textTransform: 'uppercase' }}
                      >
                        {/* <a href='#'>Case Studies</a> */}
                        {t("Contact details")}
                      </Divider>
              
                      <Header as='h3' textAlign="center" style={{ fontSize: '2em' }}>
                        <a href='https://www.caproc.fi'>Caproc Oy</a>
                      </Header>
                      <p style={{ fontSize: '1.33em' }}>
                      
                      <center >
                      <a href='mailto:info@caproc.fi'>info(a)caproc.fi</a> <br/>
                        +358 (40) 556 2176
                        </center>

                      </p>
                      {/* <Button as='a' size='large'>
                        I'm Still Quite Interested
                      </Button> */}
                    </Container>
                  </Segment>
                </React.Fragment>
                )}
                
                <Segment inverted vertical style={{ padding: "5em 0em" }}>
                    <Container>
                        <Grid divided inverted stackable centered>
                            <Grid.Row centered>
                                {/* <Grid.Column width={3}>
                                    <Header inverted as="h4" content="About" />
                                    <List link inverted>
                                        <List.Item as="a">Sitemap</List.Item>
                                        <List.Item as="a">Contact Us</List.Item>
                                    </List>
                                </Grid.Column>
                                <Grid.Column width={3}>
                                    <Header inverted as="h4" content="Services" />
                                    <List link inverted>
                                        <List.Item as="a">FAQ</List.Item>
                                        <List.Item as="a">Pricing</List.Item>
                                        <List.Item as="a">API</List.Item>
                                    </List>
                                </Grid.Column> */}
                                <Grid.Column width={7} textAlign="center">
                                    {is_desktop ? (
                                        <React.Fragment>
                                            © 2020 Caproc Oy | <a href='mailto:info@caproc.fi'>info(a)caproc.fi</a> | +358 (40) 556 2176 <br/>
                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment>
                                            © 2020 Caproc Oy <br/>
                                            <a href='mailto:info@caproc.fi'>info(a)caproc.fi</a> <br/> 
                                            +358 (40) 556 2176 <br/>
                                        </React.Fragment>
                                    )}
                                    {isAuthenticated && (
                                        <Link onClick={this.fetchGdprDefinitions}>{t("GDPR definitions")}</Link>
                                    )}
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Container>
                </Segment>
            </React.Fragment>
        );
    }
}
const mapStateToProps = state => {
    return {
        activeCompanyId: state.company.activeCompanyId,
        isAuthenticated: state.auth.token !== null,
        activeRoles: state.company.activeRoles ,
        activeRole: state.company.activeRole     
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        null
    )(withTranslation('landingpage')(HomepageLayout))
);
// export default HomepageLayout;