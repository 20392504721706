// Copyright Caproc Oy
// System name: Aqua-Net 
// System module: Project
// Author: TS
//
// 1.2.2020 TS Initial version
// 13.2.2020 TS Added project routes
// 16.3.2020 TS Added project task routes
// 29.3.2020 TS Added contract price change routes
// 8.5.2020 TS Added company net invoicing routes
// 10.6.2020 TS Added company data collection routes
// 10.7.2020 TS Added CustomerPortal routes

import React from "react";
import { Route, Redirect } from "react-router-dom";
import Hoc from "./hoc/hoc";

import Login from "./containers/Login";
// import Signup from "./containers/Signup";
import PasswordReset from "./containers/PasswordReset";
import LandingPage from "./containers/LandingPage";
import PasswordResetRequest from "./containers/PasswordResetEmail";
import RoleSelect from "./containers/Account/RoleSelect";
import AccountDetails from "./containers/Account/AccountDetails";
import ChangePassword from "./containers/Account/ChangePassword";
import CompanyDetails from "./containers/Company/CompanyDetails";
import InvoicingDetails from "./containers/Company/InvoicingDetails";
import CompanyNetInvoicing from "./containers/Company/CompanyNetInvoicing";
import CompanyUsers from "./containers/Company/CompanyUsers";
import CompanyUserDetails from "./containers/Company/CompanyUserDetails";
import CompanyContacts from "./containers/Company/CompanyContacts";
import CompanyContactDetails from "./containers/Company/CompanyContactDetails";
import CompanyFiles from "./containers/Company/CompanyFiles";
import CompanyFileDetails from "./containers/Company/CompanyFileDetails";
import CompanyTasks from "./containers/Company/CompanyTasks";
import CompanyTaskDetails from "./containers/Company/CompanyTaskDetails";
import CompanyDataCollections from "./containers/Company/CompanyDataCollections";
import CompanyDataCollectionDetails from "./containers/Company/CompanyDataCollectionDetails";
import CompanyDataCollectionItems from "./containers/Company/CompanyDataCollectionItems";
import CompanyDataCollectionAddItem from "./containers/Company/CompanyDataCollectionAddItem";
import ProductDetails from "./containers/Company/ProductDetails";
import ProductGroupDetails from "./containers/Company/ProductGroupDetails";
import Products from "./containers/Company/Products";
import ProductGroups from "./containers/Company/ProductGroups";
import Customers from "./containers/Customer/Customers";
import CustomerDetails from "./containers/Customer/CustomerDetails";
import CustomerGroups from "./containers/Customer/CustomerGroups";
import CustomerGroupDetails from "./containers/Customer/CustomerGroupDetails";
import CustomerInvoicingDetails from "./containers/Customer/CustomerInvoicingDetails";
import CustomerAddresses from "./containers/Customer/CustomerAddresses";
import CustomerContacts from "./containers/Customer/CustomerContacts";
import CustomerContactDetails from "./containers/Customer/CustomerContactDetails";
import CustomerFiles from "./containers/Customer/CustomerFiles";
import CustomerFileDetails from "./containers/Customer/CustomerFileDetails";
import CustomerTasks from "./containers/Customer/CustomerTasks";
import CustomerTaskDetails from "./containers/Customer/CustomerTaskDetails";
import Invoices from "./containers/Invoice/Invoices";
import InvoiceDetails from "./containers/Invoice/InvoiceDetails";
import InvoiceDetailsMultipleCustomers from "./containers/Invoice/InvoiceDetailsMultipleCustomers";
import CustomerDataCollections from "./containers/Customer/CustomerDataCollections";
import CustomerDataCollectionDetails from "./containers/Customer/CustomerDataCollectionDetails";
import CustomerDataCollectionItems from "./containers/Customer/CustomerDataCollectionItems";
import CustomerDataCollectionAddItem from "./containers/Customer/CustomerDataCollectionAddItem";
import CustomerDataCollectionReport from "./containers/Customer/CustomerDataCollectionReport";
import Contracts from "./containers/Contract/Contracts";
import ContractDetails from "./containers/Contract/ContractDetails";
import ContractGroupDetails from "./containers/Contract/ContractGroupDetails";
import ContractGroups from "./containers/Contract/ContractGroups";
import ContractPriceChanges from "./containers/Contract/ContractPriceChanges";
import ContractPriceChangeDetails from "./containers/Contract/ContractPriceChangeDetails";
import Messages from "./containers/Message/Messages";
import MessageCreate from "./containers/Message/MessageCreate";
import ReportInvoices from "./containers/Report/ReportInvoices";
import ReportInvoicesCustomers from "./containers/Report/ReportInvoicesCustomers";
import ReportInvoicesProducts from "./containers/Report/ReportInvoicesProducts";
import ReportTradeReceivables from "./containers/Report/ReportTradeReceivables";
import ReportPaymentTransactions from "./containers/Report/ReportPaymentTransactions";
// import ReportDataCollectionItems from "./containers/Report/ReportDataCollectionItems";
import ReportDataCollection from "./containers/Report/ReportDataCollection";
import Projects from "./containers/Project/Projects";
import ProjectDetails from "./containers/Project/ProjectDetails";
import ProjectGroupDetails from "./containers/Project/ProjectGroupDetails";
import ProjectGroups from "./containers/Project/ProjectGroups";
import ProjectTaskDetails from "./containers/Project/ProjectTaskDetails";
import ProjectTasks from "./containers/Project/ProjectTasks";

import CustomerLandingPage from "./containers/CustomerLandingPage";
import CustomerPortalDetails from "./containers/CustomerPortal/CustomerPortalDetails";
import CustomerPortalContacts from "./containers/CustomerPortal/CustomerPortalContacts";
import CustomerPortalContactDetails from "./containers/CustomerPortal/CustomerPortalContactDetails";
import CustomerPortalInvoices from "./containers/CustomerPortal/CustomerPortalInvoices";
import CustomerPortalFiles from "./containers/CustomerPortal/CustomerPortalFiles";
import CustomerPortalDataCollectionItems from "./containers/CustomerPortal/CustomerPortalDataCollectionItems";
import CustomerPortalDataCollectionReport from "./containers/CustomerPortal/CustomerPortalDataCollectionReport";
import CustomerPortalDataCollectionAddItem from "./containers/CustomerPortal/CustomerPortalDataCollectionAddItem";
import CompanyBankTransactions from "./containers/Company/CompanyBankTransactions";


const PrivateRoute = ({ component: Component, ...rest }) => {
  const authenticated = localStorage.getItem("token") !== null;
  return (
    <Route
      {...rest}
      render={props =>
        authenticated === true ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
};

const BaseRouter = () => (
  <Hoc>
    <Route exact path="/" component={LandingPage} />
    <Route path="/login/" component={Login} />
    {/* <Route path="/signup/" component={Signup} /> */}
    <Route path="/password-reset-email/:email/" component={PasswordResetRequest} />
    <Route path="/password-reset-email/" component={PasswordResetRequest} />
    <Route path="/password-reset-confirm/:uid/:token/" component={PasswordReset} />
    <PrivateRoute path="/account/role-select/" component={RoleSelect} />
    <PrivateRoute path="/account/change-password/" component={ChangePassword} />
    <PrivateRoute path="/account/account-details/" component={AccountDetails} />
    <PrivateRoute path="/company/company-details/" component={CompanyDetails} />
    <PrivateRoute path="/company/invoicing-details/" component={InvoicingDetails} />
    <PrivateRoute path="/company/net-invoicing-details/" component={CompanyNetInvoicing} />
    <PrivateRoute path="/company/users/" component={CompanyUsers} />
    <PrivateRoute path="/company/user-details/:user_id/" component={CompanyUserDetails} />
    <PrivateRoute path="/company/contacts/" component={CompanyContacts} />
    <PrivateRoute path="/company/contact-details/:contact_id/" component={CompanyContactDetails} />
    <PrivateRoute path="/company/files/" component={CompanyFiles} />
    <PrivateRoute path="/company/file-details/:file_id/" component={CompanyFileDetails} />
    <PrivateRoute path="/company/tasks/" component={CompanyTasks} />
    <PrivateRoute path="/company/task-details/:task_id/" component={CompanyTaskDetails} />
    <PrivateRoute path="/company/customer-data-collections/" component={CompanyDataCollections} />
    <PrivateRoute path="/company/customer-data-collection/items/" component={CompanyDataCollectionItems} />
    <PrivateRoute path="/company/customer-data-collection/item/create/" component={CompanyDataCollectionAddItem} />
    <PrivateRoute path="/company/:customer_id/customer-data-collection-details/:customer_data_collection_id/" component={CompanyDataCollectionDetails} />
    <PrivateRoute path="/company/bank-transactions/" component={CompanyBankTransactions} />
    <PrivateRoute path="/product/product-group-details/:product_group_id/" component={ProductGroupDetails} />
    <PrivateRoute path="/product/product-details/:product_id/" component={ProductDetails} />
    <PrivateRoute path="/product/products/" component={Products} />
    <PrivateRoute path="/product/product-groups/" component={ProductGroups} />
    <PrivateRoute path="/customers/" component={Customers} />
    <PrivateRoute path="/customer/customer-details/:customer_id/" component={CustomerDetails} />
    <PrivateRoute path="/customer/customer-groups/" component={CustomerGroups} />
    <PrivateRoute path="/customer/customer-group-details/:customer_group_id/" component={CustomerGroupDetails} />
    <PrivateRoute path="/customer/customer-invoicing-details/:customer_id/" component={CustomerInvoicingDetails} />
    <PrivateRoute path="/customer/:customer_id/addresses/" component={CustomerAddresses} />
    <PrivateRoute path="/customer/:customer_id/contacts/" component={CustomerContacts} />
    <PrivateRoute path="/customer/:customer_id/contact-details/:contact_id/" component={CustomerContactDetails} />
    <PrivateRoute path="/customer/:customer_id/customer-data-collections/" component={CustomerDataCollections} />
    <PrivateRoute path="/customer/:customer_id/customer-data-collection/items/" component={CustomerDataCollectionItems} />
    <PrivateRoute path="/customer/:customer_id/customer-data-collection/item/create/" component={CustomerDataCollectionAddItem} />
    <PrivateRoute path="/customer/:customer_id/customer-data-collection-details/:customer_data_collection_id/" component={CustomerDataCollectionDetails} />
    <PrivateRoute path="/customer/:customer_id/customer-data-collection/report/" component={CustomerDataCollectionReport} />
    <PrivateRoute path="/customer/:customer_id/files/" component={CustomerFiles} />
    <PrivateRoute path="/customer/:customer_id/file-details/:file_id/" component={CustomerFileDetails} />
    <PrivateRoute path="/customer/:customer_id/tasks/" component={CustomerTasks} />
    <PrivateRoute path="/customer/:customer_id/task-details/:task_id/" component={CustomerTaskDetails} />
    <PrivateRoute path="/invoice/invoices/:status/" component={Invoices} />
    <PrivateRoute path="/invoice/invoice-details/:action_type/:invoice_id/" component={InvoiceDetails} />
    <PrivateRoute path="/invoice/invoice-details-multiple-customers/:action_type/:invoice_id/" component={InvoiceDetailsMultipleCustomers} />
    <PrivateRoute path="/contracts/" component={Contracts} />
    <PrivateRoute path="/contract/contract-details/:contract_id/" component={ContractDetails} />
    <PrivateRoute path="/contract/contract-groups/" component={ContractGroups} />
    <PrivateRoute path="/contract/contract-group-details/:contract_group_id/" component={ContractGroupDetails} />
    <PrivateRoute path="/contract-price-changes/" component={ContractPriceChanges} />
    <PrivateRoute path="/contract-price-change-details/:contract_price_change_id/" component={ContractPriceChangeDetails} />
    <PrivateRoute path="/messages/" component={Messages} />
    <PrivateRoute path="/message/create/" component={MessageCreate} />
    <PrivateRoute path="/reports/invoices/invoices/" component={ReportInvoices} />
    <PrivateRoute path="/reports/invoices/customers/" component={ReportInvoicesCustomers} />
    <PrivateRoute path="/reports/invoices/products/" component={ReportInvoicesProducts} />
    <PrivateRoute path="/reports/invoices/trade-receivables/" component={ReportTradeReceivables} />
    <PrivateRoute path="/reports/invoices/payment-transactions/" component={ReportPaymentTransactions} />
    <PrivateRoute path="/reports/datacollection/items/:customer_id/" component={ReportDataCollection} />
    <PrivateRoute path="/projects/" component={Projects} />
    <PrivateRoute path="/project/project-details/:project_id/" component={ProjectDetails} />
    <PrivateRoute path="/project/project-groups/" component={ProjectGroups} />
    <PrivateRoute path="/project/project-group-details/:project_group_id/" component={ProjectGroupDetails} />
    <PrivateRoute path="/project/tasks/:status/" component={ProjectTasks} />
    <PrivateRoute path="/project/task-details/:project_task_id/" component={ProjectTaskDetails} />

    {/* Customer portal routes */}
    <PrivateRoute exact path="/customer-portal/" component={CustomerLandingPage} />
    <PrivateRoute exact path="/customer-portal/customer-details/:customer_id/" component={CustomerPortalDetails} />
    <PrivateRoute exact path="/customer-portal/contacts/:customer_id/" component={CustomerPortalContacts} />
    <PrivateRoute exact path="/customer-portal/:customer_id/contact-details/:contact_id/" component={CustomerPortalContactDetails} />
    <PrivateRoute exact path="/customer-portal/invoices/:customer_id/" component={CustomerPortalInvoices} />
    <PrivateRoute exact path="/customer-portal/:customer_id/files/" component={CustomerPortalFiles} />
    <PrivateRoute exact path="/customer-portal/:customer_id/customer-data-collection/items/" component={CustomerPortalDataCollectionItems} />
    <PrivateRoute exact path="/customer-portal/:customer_id/customer-data-collection/report/" component={CustomerPortalDataCollectionReport} />
    <PrivateRoute exact path="/customer-portal/:customer_id/customer-data-collection/items/add/" component={CustomerPortalDataCollectionAddItem} />

  </Hoc>
);

export default BaseRouter;
