import React from "react";
import { Form, Input, Message, Header, Button, Select, Dimmer, Loader, Segment, Image, Grid } from "semantic-ui-react";
import Shell from "./Shell";
import { authAxios } from "../../utils";
import { setActivity } from "../../store/actions/auth";
import { withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import { customerAddressesURL, updateCustomerAddressURL, customerDetailsURL, countriesURL } from "../../constants";


class CustomerAddresses extends React.Component {
    state = {
        customer_id: null,
        I_address_id: null,
        I_address_type: "",
        I_name: "",
        I_address: "",
        I_postal_code: "",
        I_city: "",
        I_country: "",
        D_address_id: null,
        D_address_type: "",
        D_name: "",
        D_address: "",
        D_postal_code: "",
        D_city: "",
        D_country: "",
        country_options: null,
        customer_name: "",
        error: null,
        success_mgs_I: null,
        success_mgs_D: null,
        loading: false
    };

    componentDidMount() {
        this.props.setActivity();
        this.fetchAddresses();
        this.fetchCountryOptions();
        this.fetchCustomerDetails();
    }

    fetchCustomerDetails() {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;
        const { customer_id } = this.props.match.params;

        authAxios
            .get(customerDetailsURL(activeCompanyId, customer_id))
            .then(res => {
                this.setState({
                    customer_name: res.data.name,
                    error: null,
                    success_mgs: null,
                    loading: false,
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    success_mgs: null,
                    error: err
                });
            });
    }

    fetchCountryOptions = () => {
        this.setState({
            loading: true,
        });
        authAxios
            .get(countriesURL)
            .then(res => {
                this.setState({
                    country_options: this.handleFormatCountries(res.data),
                    loading: false
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    success_mgs_D: null,
                    success_mgs_I: null,
                    error: err
                });
            });
    };

    handleFormatCountries = countries => {
        const keys = Object.keys(countries);
        return keys.map(k => {
            return {
                key: k,
                text: countries[k],
                value: k
            };
        });
    };

    handleFormatAddresses = addresses => {
        var i;
        for (i in addresses) {
            if (addresses[i].address_type === "D") {
                this.setState({
                    customer_id: addresses[i].customer,
                    D_address_id: addresses[i].id,
                    D_address_type: addresses[i].address_type,
                    D_name: addresses[i].name,
                    D_address: addresses[i].address,
                    D_postal_code: addresses[i].postal_code,
                    D_city: addresses[i].city,
                    D_country: addresses[i].country
                });
            } else if (addresses[i].address_type === "I") {
                this.setState({
                    customer_id: addresses[i].customer,
                    I_address_id: addresses[i].id,
                    I_address_type: addresses[i].address_type,
                    I_name: addresses[i].name,
                    I_address: addresses[i].address,
                    I_postal_code: addresses[i].postal_code,
                    I_city: addresses[i].city,
                    I_country: addresses[i].country
                });
            } else {
                this.setState({
                    loading: false,
                    success_mgs_I: null,
                    success_mgs_D: null,
                    error: this.props.t("Unsupported address type")
                });
            }
        }
    }

    fetchAddresses = () => {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;
        const { customer_id } = this.props.match.params;

        authAxios
            .get(customerAddressesURL(activeCompanyId, customer_id))
            .then(res => {
                this.handleFormatAddresses(res.data);
                this.setState({
                    success_mgs_I: null,
                    success_mgs_D: null,
                    loading: false
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    success_mgs_I: null,
                    success_mgs_D: null,
                    error: err
                });
            });
    };

    handleSubmit = e => {
        e.preventDefault();
        this.setState({
            loading: true
        });
        const {
            I_address_id,
            I_address_type,
            I_name,
            I_address,
            I_postal_code,
            I_city,
            I_country,
            D_address_id,
            D_address_type,
            D_name,
            D_address,
            D_postal_code,
            D_city,
            D_country,
            customer_id
        } = this.state;
        const { activeCompanyId, t } = this.props;
        if (I_name === "") {
            this.setState({
                error: t("Please fill in Invoice address company name"),
                success_mgs_I: null,
                success_mgs_D: null,
                loading: false
            });
        } else if (D_name === "") {
            this.setState({
                error: t("Please fill in Delivery address company name"),
                success_mgs_I: null,
                success_mgs_D: null,
                loading: false
            });
        } else if (I_address === "") {
            this.setState({
                error: t("Please fill in Invoice address"),
                success_mgs_I: null,
                success_mgs_D: null,
                loading: false
            });
        } else if (D_address === "") {
            this.setState({
                error: t("Please fill in Delivery address"),
                success_mgs_I: null,
                success_mgs_D: null,
                loading: false
            });
        } else if (I_postal_code === "") {
            this.setState({
                error: t("Please fill in Invoice address postal code"),
                success_mgs_I: null,
                success_mgs_D: null,
                loading: false
            });
        } else if (D_postal_code === "") {
            this.setState({
                error: t("Please fill in Delivery address postal code"),
                success_mgs_I: null,
                success_mgs_D: null,
                loading: false
            });
        } else if (I_city === "") {
            this.setState({
                error: t("Please fill in Invoice address city"),
                success_mgs_I: null,
                success_mgs_D: null,
                loading: false
            });
        } else if (D_city === "") {
            this.setState({
                error: t("Please fill in Delivery address city"),
                success_mgs_I: null,
                success_mgs_D: null,
                loading: false
            });
        } else if (I_name.length > 70) {
            this.setState({
                error: t("Max amount of characters is 100 for Name"),
                success_mgs: null,
                loading: false
            });
        } else if (I_address.length > 105) {
            this.setState({
                error: t("Max amount of characters is 150 for Address"),
                success_mgs: null,
                loading: false
            });
        } else if (I_postal_code.length > 35) {
            this.setState({
                error: t("Max amount of characters is 50 for Postal code"),
                success_mgs: null,
                loading: false
            });
        } else if (I_city.length > 35) {
            this.setState({
                error: t("Max amount of characters is 50 for City"),
                success_mgs: null,
                loading: false
            });
        } else if (D_name.length > 70) {
            this.setState({
                error: t("Max amount of characters is 100 for Name"),
                success_mgs: null,
                loading: false
            });
        } else if (D_address.length > 105) {
            this.setState({
                error: t("Max amount of characters is 150 for Address"),
                success_mgs: null,
                loading: false
            });
        } else if (D_postal_code.length > 35) {
            this.setState({
                error: t("Max amount of characters is 50 for Postal code"),
                success_mgs: null,
                loading: false
            });
        } else if (D_city.length > 35) {
            this.setState({
                error: t("Max amount of characters is 50 for City"),
                success_mgs: null,
                loading: false
            });
        } else {
            authAxios
                .put(updateCustomerAddressURL(activeCompanyId, customer_id, D_address_id), {
                    address_type: D_address_type,
                    customer: customer_id,
                    name: D_name,
                    address: D_address,
                    postal_code: D_postal_code,
                    city: D_city,
                    country: D_country
                })
                .then(res => {
                    this.setState({
                        loading: false,
                        success_mgs_D: t("Delivery address updated successfully"),
                        error: null,
                        create: false
                    });
                })
                .catch(err => {
                    this.setState({
                        loading: false,
                        success_mgs_I: null,
                        success_mgs_D: null,
                        error: err
                    });
                });

            authAxios
                .put(updateCustomerAddressURL(activeCompanyId, customer_id, I_address_id), {
                    address_type: I_address_type,
                    customer: customer_id,
                    name: I_name,
                    address: I_address,
                    postal_code: I_postal_code,
                    city: I_city,
                    country: I_country
                })
                .then(res => {
                    this.setState({
                        loading: false,
                        success_mgs_I: t("Invoicing address updated successfully"),
                        error: null,
                        create: false
                    });
                })
                .catch(err => {
                    this.setState({
                        loading: false,
                        success_mgs_I: null,
                        success_mgs_D: null,
                        error: err
                    });
                });
        }
    };

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value,
            success_mgs_I: null,
            success_mgs_D: null,
            error: null
        });
    };

    handleSelectChange = (e, { name, value }) => {
        this.setState({
            [name]: value
        });
    };

    handleCheckboxChange = (e, { name, value }) => {
        this.setState({
            [name]: !value
        });
    };

    render() {
        const {
            customer_id,
            customer_name,
            country_options,
            I_name,
            I_address,
            I_postal_code,
            I_city,
            I_country,
            D_name,
            D_address,
            D_postal_code,
            D_city,
            D_country,
            error,
            success_mgs_I,
            success_mgs_D,
            loading
        } = this.state;

        const { isAuthenticated, t } = this.props;
        if (!isAuthenticated) {
            return <Redirect to="/login" />;
        }
        return (
            <Shell active_customer_id={customer_id} active_customer_name={customer_name}>
                {error && (
                    <Message
                        error
                        header={t("There was an error")}
                        content={JSON.stringify(error)}
                    />
                )}
                {loading && (
                    <Segment>
                        <Dimmer active inverted>
                            <Loader inverted>Loading</Loader>
                        </Dimmer>
                        <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
                    </Segment>
                )}


                <Header as="h4">{customer_name}</Header>
                <Form error={error !== null}>
                    <Grid>
                        <Grid.Column width={8}>
                            <Header as="h5">{t("Invoicing address")}</Header>
                            <Form.Field required>
                                <label>{t("Name")} {I_name ? (`${I_name.length}/70`) : ("0/70")}</label>
                                <Input
                                    required
                                    value={I_name}
                                    name="I_name"
                                    onChange={this.handleChange} />
                            </Form.Field>
                            <Form.Field required>
                                <label>{t("Address")} {I_address ? (`${I_address.length}/105`) : ("0/105")}</label>
                                <Input
                                    required
                                    value={I_address}
                                    name="I_address"
                                    onChange={this.handleChange} />
                            </Form.Field>
                            <Form.Field required>
                                <label>{t("Postal code")} {I_postal_code ? (`${I_postal_code.length}/35`) : ("0/35")}</label>
                                <Input
                                    required
                                    value={I_postal_code}
                                    name="I_postal_code"
                                    onChange={this.handleChange} />
                            </Form.Field>
                            <Form.Field required>
                                <label>{t("City")} {I_city ? (`${I_city.length}/35`) : ("0/35")}</label>
                                <Input
                                    required
                                    value={I_city}
                                    name="I_city"
                                    onChange={this.handleChange} />
                            </Form.Field>
                            {country_options && (
                                <Form.Field required>
                                    <label>{t("Country")}</label>
                                    <Select
                                        required
                                        search
                                        fluid
                                        name='I_country'
                                        options={country_options}
                                        placeholder={I_country}
                                        onChange={this.handleSelectChange}
                                        value={I_country}
                                    />
                                </Form.Field>
                            )}
                        </Grid.Column>
                        <Grid.Column width={8}>
                            <Header as="h5">{t("Delivery address")}</Header>
                            <Form.Field required>
                                <label>{t("Name")} {D_name ? (`${D_name.length}/70`) : ("0/70")}</label>
                                <Input
                                    required
                                    value={D_name}
                                    name="D_name"
                                    onChange={this.handleChange} />
                            </Form.Field>
                            <Form.Field required>
                                <label>{t("Address")} {D_address ? (`${D_address.length}/105`) : ("0/105")}</label>
                                <Input
                                    required
                                    value={D_address}
                                    name="D_address"
                                    onChange={this.handleChange} />
                            </Form.Field>
                            <Form.Field required>
                                <label>{t("Postal code")} {D_postal_code ? (`${D_postal_code.length}/35`) : ("0/35")}</label>
                                <Input
                                    required
                                    value={D_postal_code}
                                    name="D_postal_code"
                                    onChange={this.handleChange} />
                            </Form.Field>
                            <Form.Field required>
                                <label>{t("City")} {D_city ? (`${D_city.length}/35`) : ("0/35")}</label>
                                <Input
                                    required
                                    value={D_city}
                                    name="D_city"
                                    onChange={this.handleChange} />
                            </Form.Field>
                            {country_options && (
                                <Form.Field required>
                                    <label>{t("Country")}</label>
                                    <Select
                                        required
                                        search
                                        fluid
                                        name='D_country'
                                        options={country_options}
                                        placeholder={D_country}
                                        onChange={this.handleSelectChange}
                                        value={D_country}
                                    />
                                </Form.Field>
                            )}
                        </Grid.Column>
                    </Grid>
                    <br></br>
                    {error && <Message error header={t("Error")} content={error} />}
                    {success_mgs_D && <Message positive header={success_mgs_D} />}
                    {success_mgs_I && <Message positive header={success_mgs_I} />}
                    <Button primary type="button" onClick={(e) => this.handleSubmit(e)} loading={loading} disabled={loading}>
                        {t("Save")}
                    </Button>
                </Form>
            </Shell>
        );
    }
}
const mapStateToProps = state => {
    return {
        activeCompanyId: state.company.activeCompanyId,
        isAuthenticated: state.auth.token !== null
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setActivity: () => dispatch(setActivity())
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(withTranslation('customeraddresses')(CustomerAddresses))
);