// Copyright Caproc Oy
// System name: Aqua-Net 
// System module: Report
// Author: TS
//
// 1.2.2020 TS Initial version
// 20.5.2020 TS Added amount and tax total in to the Table data. Removed one time and contract info from the Table.
// 29.5.2020 TS Added invoice state filter option
// 2.5.2020 TS Added screen size radio buttons
// 20.6.2020 TS Added export to CSV

import React from "react";
import { Message, Header, Table, Segment, Dimmer, Loader, Image, Grid, Input, Menu, Select, Radio, Container, Button, Responsive } from "semantic-ui-react";
import Shell from "./Shell";
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import fiLocale from 'react-semantic-ui-datepickers/dist/locales/fi-FI';
import { authAxios, getISOFormattedDate, exportToCsv, getWidth, exportReportToPdf } from "../../utils";
import { setActivity } from "../../store/actions/auth";
import { withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import { reportProductInvoicesURL } from "../../constants";
import { Bar } from 'react-chartjs-2';



class ReportInvoicesProducts extends React.Component {
    state = {
        report: [],
        activeMenuItem: this.props.t("Table"),
        error: null,
        loading: false,
        search_start_date: null,
        search_end_date: null,
        search: "",
        fetch: false,
        amount_sum: null,
        onetime_sum: null,
        contract_sum: null,
        net_total_sum: null,
        tax_total_sum: null,
        gross_total_sum: null,
        profit_sum: null,
        onetime_contracts_invoices_chart_data: {},
        profit_cost_chart_data: {},
        profit_cost_percent_chart_data: {},
        net_tax_chart_data: {},
        update_totals_and_charts: false,
        search_status_options: null,
        search_status: null,
        screen_size: "normal"
    };

    componentDidMount() {
        this.props.setActivity();
        let init_start_date = new Date();
        init_start_date.setDate(1);
        init_start_date.setMonth(init_start_date.getMonth() - 6);
        this.handleStartDateChange(init_start_date);
        this.handleEndDateChange(new Date());
        this.handleSearchStatusOptions();
    }

    handleRadioChange = (e, { name, value }) => {
        this.setState({
            [name]: value
        });
    };

    radioButtons = () => {
        return (
            <React.Fragment>
                <Radio
                    name="screen_size"
                    label={this.props.t("Normal")}
                    onChange={this.handleRadioChange}
                    checked={this.state.screen_size === "normal"}
                    value="normal"
                />
                &nbsp; &nbsp;
                <Radio
                    name="screen_size"
                    label={this.props.t("Wide")}
                    onChange={this.handleRadioChange}
                    checked={this.state.screen_size === "wide"}
                    value="wide"
                />
                &nbsp; &nbsp;
                <Radio
                    name="screen_size"
                    label={this.props.t("Full screen")}
                    onChange={this.handleRadioChange}
                    checked={this.state.screen_size === "full"}
                    value="full"
                />  
            </React.Fragment>
        );
    };

    handleSelectChange = (e, { name, value }) => {
        this.setState({
            [name]: value,
            fetch: true
        });
    };

    handleSearchStatusOptions = () => {
        const { t } = this.props;
        let options = [];
        options[0] = { key: "C", text: t("Created"), value: "C"};
        options[1] = { key: "A", text: t("Approved"), value: "A"};
        options[2] = { key: "S", text: t("Sent"), value: "S"};
        options[3] = { key: "P", text: t("Paid"), value: "P"};
        options[4] = { key: "O", text: t("Overdue"), value: "O"};
        options[5] = { key: "B", text: t("Abnormal"), value: "B"};
        this.setState({
            search_status_options: options
        });
    }

    handleMenuItemClick = (e, { name }) => this.setState({ activeMenuItem: name });

    handleFetchReport = () => {
        this.setState({
            loading: true
        });
        const { search_start_date, search_end_date, search_status } = this.state;
        const { activeCompanyId } = this.props;

        let search_input_status = search_status;
        if (search_input_status !== null && search_input_status.length === 0) {
            search_input_status = null;
        }

        authAxios
            .get(reportProductInvoicesURL(activeCompanyId, search_start_date, search_end_date, search_input_status))
            .then(res => {
                this.setState({
                    report: res.data,
                    error: null,
                    loading: false
                });
                this.handleTotals(res.data);
                this.handleChartData(res.data);
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    error: err
                });
            });
    };

    handleChartData = report => {
        let {
            onetime_contracts_invoices_chart_data,
            profit_cost_chart_data,
            profit_cost_percent_chart_data,
            net_tax_chart_data
        } = this.state;
        const { t } = this.props;
        const default_dataset1 = {
            label: "",
            backgroundColor: 'rgb(78, 150, 220)',
            borderColor: 'rgb(255, 255, 255)',
            data: []
        };
        const default_dataset2 = {
            label: "",
            backgroundColor: 'rgb(100, 140, 180)',
            borderColor: 'rgb(255, 255, 255)',
            data: []
        };
        onetime_contracts_invoices_chart_data.labels = [];
        onetime_contracts_invoices_chart_data.datasets = [];
        profit_cost_chart_data.labels = [];
        profit_cost_chart_data.datasets = [];
        profit_cost_percent_chart_data.labels = [];
        profit_cost_percent_chart_data.datasets = [];
        net_tax_chart_data.labels = [];
        net_tax_chart_data.datasets = [];

        let onetime_invoices_dataset = {
            ...default_dataset1,
            label: t("Onetime invoices")
        };
        let contracts_invoices_dataset = {
            ...default_dataset2,
            label: t("Contract invoices"),
            data: []
        };

        let profit_dataset = {
            ...default_dataset1,
            label: t("Profit"),
            data: []
        };
        let cost_dataset = {
            ...default_dataset2,
            label: t("Cost"),
            data: []
        };

        let profit_percent_dataset = {
            ...default_dataset1,
            label: t("Profit %"),
            data: []
        };
        let cost_percent_dataset = {
            ...default_dataset2,
            label: t("Cost %"),
            data: []
        };

        let net_dataset = {
            ...default_dataset1,
            label: t("Net total"),
            data: []
        };
        let tax_dataset = {
            ...default_dataset2,
            label: t("Tax total"),
            data: []
        };
        let i = 0;
        for (i in report) {
            onetime_contracts_invoices_chart_data.labels.push(report[i].name);
            profit_cost_chart_data.labels.push(report[i].name);
            profit_cost_percent_chart_data.labels.push(report[i].name);
            net_tax_chart_data.labels.push(report[i].name);

            onetime_invoices_dataset.data.push(report[i].onetime_invoicing.toFixed(2));
            contracts_invoices_dataset.data.push(report[i].contract_invoicing.toFixed(2));

            profit_dataset.data.push(report[i].profit.toFixed(2));
            cost_dataset.data.push((parseFloat(report[i].net_total) - parseFloat(report[i].profit)).toFixed(2));

            profit_percent_dataset.data.push((report[i].profit / report[i].net_total * 100).toFixed(1));
            cost_percent_dataset.data.push((100 - report[i].profit / report[i].net_total * 100).toFixed(1));

            net_dataset.data.push(report[i].net_total.toFixed(2));
            tax_dataset.data.push((parseFloat(report[i].gross_total) - parseFloat(report[i].net_total)).toFixed(2));
        }
        onetime_contracts_invoices_chart_data.datasets.push(onetime_invoices_dataset);
        onetime_contracts_invoices_chart_data.datasets.push(contracts_invoices_dataset);

        profit_cost_chart_data.datasets.push(profit_dataset);
        profit_cost_chart_data.datasets.push(cost_dataset);

        profit_cost_percent_chart_data.datasets.push(profit_percent_dataset);
        profit_cost_percent_chart_data.datasets.push(cost_percent_dataset);

        net_tax_chart_data.datasets.push(net_dataset);
        net_tax_chart_data.datasets.push(tax_dataset);

        this.setState({
            onetime_contracts_invoices_chart_data: onetime_contracts_invoices_chart_data,
            profit_cost_chart_data: profit_cost_chart_data,
            profit_cost_percent_chart_data: profit_cost_percent_chart_data,
            net_tax_chart_data: net_tax_chart_data
        });
    };

    handleTotals = report => {
        let { amount_sum, onetime_sum, contract_sum, net_total_sum, tax_total_sum, gross_total_sum, profit_sum } = this.state;
        let i = 0;
        amount_sum = 0;
        onetime_sum = 0;
        contract_sum = 0;
        net_total_sum = 0;
        tax_total_sum = 0;
        gross_total_sum = 0;
        profit_sum = 0;

        for (i in report) {
            amount_sum += report[i].amout_total;
            onetime_sum += report[i].onetime_invoicing;
            contract_sum += report[i].contract_invoicing;
            net_total_sum += report[i].net_total;
            tax_total_sum += report[i].tax_total;
            gross_total_sum += report[i].gross_total;
            profit_sum += report[i].profit;
        }
        this.setState({
            amount_sum,
            onetime_sum,
            contract_sum,
            net_total_sum,
            tax_total_sum,
            gross_total_sum,
            profit_sum
        });
    };

    handleStartDateChange = value => {
        this.setState({
            search_start_date: getISOFormattedDate(value, "Date"),
            fetch: true
        });

    };

    handleEndDateChange = value => {
        this.setState({
            search_end_date: getISOFormattedDate(value, "Date"),
            fetch: true
        });
    };

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value,
            update_totals_and_charts: e.target.name === "search" ? true : false
        });
    };

    handleExportToCSV = items => {
        const { t } = this.props;
        const { report_type } = this.state;
        if (items !== null) {
            const titles = [
                t("Product"),
                t("Amount"),
                t("Profit"),
                t("Net total"),
                t("Tax total"),
                t("Gross total")
            ];
            let items_to_csv = [];
            let i = 0;
            let period = "";
            for (i in items) {
                items_to_csv.push({
                    product: items[i].name,
                    amount: `${items[i].amount_total.toFixed(2)} ${items[i].measure_unit}`,
                    profit: items[i].profit.toFixed(2).replace(".",","),
                    net_total: items[i].net_total.toFixed(2).replace(".",","),
                    tax_total: items[i].tax_total.toFixed(2).replace(".",","),
                    gross_total: items[i].gross_total.toFixed(2).replace(".",",")
                });
            }
            exportToCsv(this.props.activeCompanyId, "export", titles, items_to_csv);
        }
    };

    handleFetchReportPdf = async (items) => {
        if (items === null) {
            return;
        }

        this.setState({
            loading: true
        });

        const { activeCompanyId, t} = this.props;
        const { search_start_date, search_end_date, profit_sum, net_total_sum, tax_total_sum, gross_total_sum} = this.state;
        const titles = [
            t("Product"),
            t("Amount"),
            t("Profit"),
            t("Net total"),
            t("Tax total"),
            t("Gross total")
    ];

        const report_name = t("Invoice report") + ` ${new Date(search_start_date).toLocaleString('fi-FI', { dateStyle: 'short' })}-${new Date(search_end_date).toLocaleString('fi-FI', { dateStyle: 'short' })}`;
        let items_to_pdf = [];
        let i = 0;
        for (i in items) {
            items_to_pdf.push({
                product: items[i].name,
                amount: `${items[i].amount_total.toFixed(2)} ${items[i].measure_unit}`,
                profit: items[i].profit.toFixed(2).replace(".",","),
                net_total: items[i].net_total.toFixed(2).replace(".",","),
                tax_total: items[i].tax_total.toFixed(2).replace(".",","),
                gross_total: items[i].gross_total.toFixed(2).replace(".",",")
            });
        }
        // Add totals at the end of table
        if (gross_total_sum > 0) {
            items_to_pdf.push({
                product: "",
                amount: "",
                profit: profit_sum.toFixed(2),
                net_total: net_total_sum.toFixed(2),
                tax_total: tax_total_sum.toFixed(2),
                gross_total: gross_total_sum.toFixed(2)
            });
        }
        const activeCompanyName = localStorage.getItem("activeCompanyName");
        exportReportToPdf(activeCompanyId, t("invoices_report"), report_name, titles, items_to_pdf, activeCompanyName)
            .then(res => {
                this.setState({
                    error: null,
                    loading: false
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    error: err
                });
            });
    };

    main = () => {
        const { error, loading, report, search, search_end_date, search_start_date, 
            onetime_sum, net_total_sum, gross_total_sum, profit_sum, tax_total_sum, activeMenuItem, update_totals_and_charts, search_status, search_status_options } = this.state;
        const {  t } = this.props;
        const options = {
            scales: {
                yAxes: [
                    {
                        ticks: {
                            min: 0
                        },
                        stacked: true
                    }
                ],
                xAxes: [
                    {
                        stacked: true
                    }
                ]
            }
        }
        const is_desktop =  getWidth() > Responsive.onlyMobile.maxWidth ? true : false;
        let filtered_items = [];

        // Filter data for search
        if (report && report.length > 0) {
            filtered_items = report.filter((data) => {
                return data.name.toLowerCase().indexOf(search.toLocaleLowerCase()) !== -1 ||
                    data.onetime_invoicing.toString().toLowerCase().indexOf(search.toLocaleLowerCase()) !== -1 ||
                    data.contract_invoicing.toString().toLowerCase().indexOf(search.toLocaleLowerCase()) !== -1 ||
                    data.profit.toString().toLowerCase().indexOf(search.toLocaleLowerCase()) !== -1 ||
                    data.net_total.toString().toLowerCase().indexOf(search.toLocaleLowerCase()) !== -1 ||
                    data.gross_total.toString().toLowerCase().indexOf(search.toLocaleLowerCase()) !== -1;
            })
        }
        if (update_totals_and_charts) {
            this.handleTotals(filtered_items);
            this.handleChartData(filtered_items);
            this.setState({ update_totals_and_charts: false });
        }

        return (
            <React.Fragment>
                {is_desktop && (
                    <Button.Group floated="right" basic size='small'>
                        <Button icon="download" content="PDF" onClick={() => this.handleFetchReportPdf(filtered_items)} />
                        <Button icon="download" content="CSV" onClick={() => this.handleExportToCSV(filtered_items)} />
                    </Button.Group>
                )}
                <Header as="h3">{t("Invoice report")}</Header>
                {is_desktop ? (
                    <Grid columns="2">
                        <Grid.Row>
                            <Grid.Column width={9}>
                                <SemanticDatepicker
                                    firstDayOfWeek="1"
                                    size="mini"
                                    disabled={loading}
                                    label={t("Booking date") + ": "}
                                    locale={fiLocale}
                                    placeholder={search_start_date !== null ? (new Date(search_start_date).toLocaleString('fi-FI', { dateStyle: 'short' })) : ("")}
                                    onDateChange={selected => this.handleStartDateChange(selected)}
                                    format='DD.MM.YYYY' />
                                <SemanticDatepicker
                                    firstDayOfWeek="1"
                                    size="mini"
                                    disabled={loading}
                                    label="->"
                                    locale={fiLocale}
                                    placeholder={search_end_date !== null ? (new Date(search_end_date).toLocaleString('fi-FI', { dateStyle: 'short' })) : ("")}
                                    onDateChange={selected => this.handleEndDateChange(selected)}
                                    format='DD.MM.YYYY' />
                            </Grid.Column>
                            <Grid.Column floated="right" textAlign="right" width={6}>
                                <Input
                                    size="mini"
                                    icon='search'
                                    value={search}
                                    name="search"
                                    onChange={this.handleChange} />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column floated="right" textAlign="right">
                                <label>{t("Status")}: </label>
                                <Select
                                    search
                                    multiple
                                    name='search_status'
                                    options={search_status_options}
                                    placeholder={t("All")}
                                    onChange={this.handleSelectChange}
                                    value={search_status}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                ) : (
                    <React.Fragment>
                        <SemanticDatepicker
                            firstDayOfWeek="1"
                            disabled={loading}
                            locale={fiLocale}
                            placeholder={search_start_date !== null ? (new Date(search_start_date).toLocaleString('fi-FI', { dateStyle: 'short' })) : ("")}
                            onDateChange={selected => this.handleStartDateChange(selected)}
                            format='DD.MM.YYYY' />
                        <SemanticDatepicker
                            firstDayOfWeek="1"
                            disabled={loading}
                            locale={fiLocale}
                            placeholder={search_end_date !== null ? (new Date(search_end_date).toLocaleString('fi-FI', { dateStyle: 'short' })) : ("")}
                            onDateChange={selected => this.handleEndDateChange(selected)}
                            format='DD.MM.YYYY' />
                        <Input
                            fluid
                            icon='search'
                            value={search}
                            name="search"
                            onChange={this.handleChange} />
                        <br/>
                        <label>{t("Status")}: </label><br/>
                        <Select
                            search
                            multiple
                            name='search_status'
                            options={search_status_options}
                            placeholder={t("All")}
                            onChange={this.handleSelectChange}
                            value={search_status}
                        />
                    </React.Fragment>
                )}
                {error && (
                    <Message
                        error
                        header={t("There was an error")}
                        content={JSON.stringify(error)}
                    />
                )}
                {loading && (
                    <Segment>
                        <Dimmer active inverted>
                            <Loader inverted>Loading</Loader>
                        </Dimmer>
                        <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
                    </Segment>
                )}
                {report && (
                    <React.Fragment>
                        <Menu attached="top" tabular>
                            <Menu.Item
                                name={t("Table")}
                                active={activeMenuItem === t("Table")}
                                onClick={this.handleMenuItemClick} />
                            <Menu.Item
                                name={t("Chart")}
                                active={activeMenuItem === t("Chart")}
                                onClick={this.handleMenuItemClick} />
                        </Menu>
                        {(activeMenuItem === t("Table")) ? (
                            <Table striped selectable celled>
                                {is_desktop && (
                                    <Table.Header >
                                        <Table.Row textAlign="right">
                                            <Table.HeaderCell textAlign="left">{t("Product")}</Table.HeaderCell>
                                            <Table.HeaderCell>{t("Amount")}</Table.HeaderCell>
                                            {/* <Table.HeaderCell>{t("One time invoice")}</Table.HeaderCell>
                                            <Table.HeaderCell>{t("Contract invoice")}</Table.HeaderCell> */}
                                            <Table.HeaderCell>{t("Profit")}</Table.HeaderCell>
                                            <Table.HeaderCell>{t("Net total")}</Table.HeaderCell>
                                            <Table.HeaderCell>{t("Tax total")}</Table.HeaderCell>
                                            <Table.HeaderCell>{t("Gross total")}</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                )}
                                <Table.Body>
                                    {filtered_items.map((data, key) => {
                                        return (
                                            <Table.Row key={key} textAlign="right" >
                                                <Table.Cell textAlign="left">
                                                    {!is_desktop && (<React.Fragment><b>{t("Product")}</b><br/></React.Fragment>)}
                                                    {data.name}
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {!is_desktop && (<React.Fragment><b>{t("Amount")}</b><br/></React.Fragment>)}
                                                    {data.amount_total.toFixed(2)} {data.measure_unit}
                                                </Table.Cell>
                                                {/* <Table.Cell>{data.onetime_invoicing.toFixed(2)} €</Table.Cell>
                                                <Table.Cell>{data.contract_invoicing.toFixed(2)} €</Table.Cell> */}
                                                <Table.Cell>
                                                    {!is_desktop && (<React.Fragment><b>{t("Profit")}</b><br/></React.Fragment>)}
                                                    {data.profit.toFixed(2)} €
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {!is_desktop && (<React.Fragment><b>{t("Net total")}</b><br/></React.Fragment>)}
                                                    {data.net_total.toFixed(2)} €
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {!is_desktop && (<React.Fragment><b>{t("Tax total")}</b><br/></React.Fragment>)}
                                                    {data.tax_total.toFixed(2)} €
                                                </Table.Cell>
                                                <Table.Cell><Header as="h5">
                                                    {!is_desktop && (<React.Fragment><b>{t("Gross total")}</b><br/></React.Fragment>)}
                                                    {data.gross_total.toFixed(2)} €
                                                </Header></Table.Cell>
                                            </Table.Row>
                                        );
                                    })}
                                    {onetime_sum && (
                                        <Table.Row textAlign="right" >
                                            <Table.Cell />
                                            <Table.Cell />
                                            {/* <Table.Cell><Header as="h5">{onetime_sum.toFixed(2)} €</Header></Table.Cell>
                                            <Table.Cell><Header as="h5">{contract_sum.toFixed(2)} €</Header></Table.Cell> */}
                                            <Table.Cell><Header as="h5">{profit_sum.toFixed(2)} €</Header></Table.Cell>
                                            <Table.Cell><Header as="h5">{net_total_sum.toFixed(2)} €</Header></Table.Cell>
                                            <Table.Cell><Header as="h5">{tax_total_sum.toFixed(2)} €</Header></Table.Cell>
                                            <Table.Cell><Header as="h5">{gross_total_sum.toFixed(2)} €</Header></Table.Cell>
                                        </Table.Row>
                                    )}
                                </Table.Body>
                            </Table>
                        ) : (
                                <React.Fragment>
                                    <Header textAlign="center" as="h3">{t("One time and contract invoices")}</Header>
                                    <Bar data={this.state.onetime_contracts_invoices_chart_data} options={options} />
                                    <Header textAlign="center" as="h3">{t("Profit and cost")}</Header>
                                    <Bar data={this.state.profit_cost_chart_data} options={options} />
                                    <Header textAlign="center" as="h3">{t("Profit and cost percentage")}</Header>
                                    <Bar data={this.state.profit_cost_percent_chart_data} options={options} />
                                    <Header textAlign="center" as="h3">{t("Net and tax")}</Header>
                                    <Bar data={this.state.net_tax_chart_data} options={options} />
                                </React.Fragment>
                            )}
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    };

    render() {
        const {  search_end_date, search_start_date, fetch} = this.state;
        const { isAuthenticated} = this.props;
        if (!isAuthenticated) {
            return <Redirect to="/login" />;
        }
        if (fetch) {
            if (search_start_date !== null && search_end_date !== null) {
                this.handleFetchReport();
            }
            this.setState({ fetch: false });
        }
        // console.log(this.state);
        return (
            <React.Fragment>
                {this.state.screen_size === "normal" && (
                    <Shell>
                        <this.radioButtons/>
                        <this.main />
                    </Shell>
                )}
                {this.state.screen_size === "wide" && (
                    <Container>
                        <Segment vertical>
                            <this.radioButtons/>    
                            <this.main/>
                        </Segment>
                    </Container>
                )}{this.state.screen_size === "full" && (
                    <React.Fragment>
                        <this.radioButtons/>
                        <this.main />
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}
const mapStateToProps = state => {
    return {
        activeCompanyId: state.company.activeCompanyId,
        isAuthenticated: state.auth.token !== null
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setActivity: () => dispatch(setActivity())
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(withTranslation('reportinvoicesproducts')(ReportInvoicesProducts))
);