// Copyright Caproc Oy
// System name: Aqua-Net 
// System module: Contract
// Author: TS
//
// 1.2.2020 TS Initial version
// 28.3.2020 TS Added Price Changes menu
// 11.7.2020 TS Added Company Selection banner

import React from "react";
import { Grid, Container, Segment, Header, Menu, Icon } from "semantic-ui-react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import { CompanySelection } from "../../utils";

const Shell = props => (
    <>
    <Container><CompanySelection/></Container>
    < Segment vertical>
        <Container>
            <Grid container stackable verticalAlign="top" divided columns={2}>
                <Grid.Row>
                    <Grid.Column width={3}>
                        <Header as="h3">{props.t("Contracts")}</Header>
                        <Menu vertical fluid icon="labeled" compact>
                            <Menu.Item>
                                <Header as="h4">{props.t("Contracts")}</Header>
                            </Menu.Item>
                            <Menu.Item
                                active={props.location.pathname === "/contracts/"}
                                onClick={() => props.history.push("/contracts/")}
                                name="contracts"
                            >
                                <Icon name="handshake"/> {props.t("Contracts")}
                            </Menu.Item>
                            <Menu.Item
                                active={props.location.pathname === "/contract/contract-groups/"}
                                onClick={() => props.history.push("/contract/contract-groups/")}
                                name="contract-groups"
                            >
                                <Icon name="object group outline"/> {props.t("Contract Groups")}
                            </Menu.Item>
                            <Menu.Item
                                active={props.location.pathname === "/contract-price-changes/"}
                                onClick={() => props.history.push("/contract-price-changes/")}
                                name="contract-price-changes"
                            >
                                <Icon name="edit"/> {props.t("Price Changes")}
                            </Menu.Item>

                            {/* {props.active_customer_id && (
                                <React.Fragment>
                                    <Menu.Item>
                                        <Header as="h4">{props.active_contract_name}</Header>
                                    </Menu.Item>

                                    <Menu.Item
                                        active={props.location.pathname === `/customer/customer-details/${props.active_customer_id}/`}
                                        onClick={() => props.history.push(`/customer/customer-details/${props.active_customer_id}/`)}
                                        name="customer-details"
                                    >
                                        {props.t("Details")}
                                    </Menu.Item>
                                    <Menu.Item
                                        active={props.location.pathname === `/customer/customer-invoicing-details/${props.active_customer_id}/`}
                                        onClick={() => props.history.push(`/customer/customer-invoicing-details/${props.active_customer_id}/`)}
                                        name="customer-invoicing-details"
                                    >
                                        {props.t("Invoicing details")}
                                    </Menu.Item>
                                    <Menu.Item
                                        active={props.location.pathname === `/customer/${props.active_customer_id}/addresses/`}
                                        onClick={() => props.history.push(`/customer/${props.active_customer_id}/addresses/`)}
                                        name="customer-addresses"
                                    >
                                        {props.t("Addresses")}
                                    </Menu.Item>
                                    <Menu.Item
                                        active={props.location.pathname === `/customer/${props.active_customer_id}/contacts/`}
                                        onClick={() => props.history.push(`/customer/${props.active_customer_id}/contacts/`)}
                                        name="customer-addresses"
                                    >
                                        {props.t("Contacts")}
                                    </Menu.Item>
                                    <Menu.Item
                                        active={props.location.pathname === `/customer/${props.active_customer_id}/customer-data-collections/`}
                                        onClick={() => props.history.push(`/customer/${props.active_customer_id}/customer-data-collections/`)}
                                        name="customer-data-collections"
                                    >
                                        {props.t("Data collections")}
                                    </Menu.Item>
                                    <Menu.Item
                                        active={props.location.pathname === `/customer/${props.active_customer_id}/customer-data-collection/items/`}
                                        onClick={() => props.history.push(`/customer/${props.active_customer_id}/customer-data-collection/items/`)}
                                        name="customer-data-collections"
                                    >
                                        {props.t("Collection items")}
                                    </Menu.Item>

                                </React.Fragment>
                            )} */}
                        </Menu>
                    </Grid.Column>
                    <Grid.Column width={13}>{props.children}</Grid.Column>
                </Grid.Row>
            </Grid>
        </Container>
    </Segment >
    </>
);

const mapDispatchToProps = dispatch => {
    return {
        // logout: () => dispatch(logout())
    };
};

export default withRouter(
    connect(
        null,
        mapDispatchToProps
    )(withTranslation('contractshell')(Shell))
);