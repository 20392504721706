// Copyright Caproc Oy
// System name: Aqua-Net 
// System module: Company
// Author: TS
//
// 1.2.2020 TS Initial version
// 11.7.2020 TS Added Company Selection banner

import React from "react";
import { Grid, Container, Segment, Header, Menu, Icon } from "semantic-ui-react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import { CompanySelection } from "../../utils";

const Shell = props => (
    <>
    <Container><CompanySelection/></Container>
    <Segment vertical>
        <Container>
            <Grid container stackable verticalAlign="top" divided columns={2}>
                <Grid.Row>
                    <Grid.Column width={3}>
                        <Header as="h3">{props.t("Company")}</Header>
                        <Menu vertical fluid icon="labeled" compact>
                            <Menu.Item >
                                <Header as="h4">{props.t("Basic details")}</Header>
                            </Menu.Item>
                            <Menu.Item
                                active={props.location.pathname === "/company/company-details"}
                                onClick={() => props.history.push("/company/company-details")}
                                name="company-details"
                            >
                                <Icon name="info"/> {props.t("Details")}
                            </Menu.Item>
                            <Menu.Item
                                active={props.location.pathname === "/company/invoicing-details"}
                                onClick={() => props.history.push("/company/invoicing-details")}
                                name="invoicing-details"
                            >
                                <Icon name="list alternate outline"/> {props.t("Invoicing")}
                            </Menu.Item>
                            <Menu.Item
                                active={props.location.pathname === "/company/net-invoicing-details"}
                                onClick={() => props.history.push("/company/net-invoicing-details")}
                                name="net-invoicing-details"
                            >
                                <Icon name="envelope"/> {props.t("Net invoicing")}
                            </Menu.Item>
                            <Menu.Item
                                active={props.location.pathname === "/company/contacts"}
                                onClick={() => props.history.push("/company/contacts")}
                                name="company-contacts"
                            >
                                <Icon name="address book"/> {props.t("Contacts")}
                            </Menu.Item>
                            {(props.activeRole && (props.activeRole.role === "A" || props.activeRole.role === "S")) && (
                                <Menu.Item
                                    active={props.location.pathname === "/company/users"}
                                    onClick={() => props.history.push("/company/users")}
                                    name="company-users"
                                >
                                    <Icon name="users"/> {props.t("Users")}
                                </Menu.Item>
                            )}
                            <Menu.Item
                                active={props.location.pathname === "/company/files"}
                                onClick={() => props.history.push("/company/files")}
                                name="company-files"
                            >
                                <Icon name="folder"/> {props.t("Files")}
                            </Menu.Item>
                            <Menu.Item>
                                <Header as="h4">{props.t("Products")}</Header>
                            </Menu.Item>

                            <Menu.Item
                                active={props.location.pathname === "/product/products"}
                                onClick={() => props.history.push("/product/products")}
                                name="products"
                            >
                                <Icon name="shopping cart"/> {props.t("Products")}
                            </Menu.Item>
                            <Menu.Item
                                active={props.location.pathname === "/product/product-groups"}
                                onClick={() => props.history.push("/product/product-groups")}
                                name="product-groups"
                            >
                                <Icon name="object group outline"/> {props.t("Product Groups")}
                            </Menu.Item>
                            <Menu.Item>
                                <Header as="h4">{props.t("Finance")}</Header>
                            </Menu.Item>
                            <Menu.Item
                                active={props.location.pathname === "/company/bank-transactions/"}
                                onClick={() => props.history.push("/company/bank-transactions/")}
                                name="bank_transactions"
                            >
                                <Icon name="currency"/> {props.t("Bank transactions")}
                            </Menu.Item>
                            <Menu.Item>
                                <Header as="h4">{props.t("Data collection")}</Header>
                            </Menu.Item>
                            <Menu.Item
                                active={props.location.pathname === `/company/customer-data-collections/`}
                                onClick={() => props.history.push(`/company/customer-data-collections/`)}
                                name="company-data-collections"
                            >
                                <Icon name="info"/> {props.t("Data collections")}
                            </Menu.Item>
                            <Menu.Item
                                active={props.location.pathname === `/company/customer-data-collection/items/`}
                                onClick={() => props.history.push(`/company/customer-data-collection/items/`)}
                                name="company-data-collection-items"
                            >
                                <Icon name="list ol"/> {props.t("Collection items")}
                            </Menu.Item>
                            <Menu.Item>
                                <Header as="h4">{props.t("Tasks")}</Header>
                            </Menu.Item>
                            <Menu.Item
                                active={props.location.pathname === "/company/tasks"}
                                onClick={() => props.history.push("/company/tasks")}
                                name="tasks"
                            >
                                <Icon name="tasks"/> {props.t("Completed tasks")}
                            </Menu.Item>
                        </Menu>
                    </Grid.Column>
                    <Grid.Column width={13}>{props.children}</Grid.Column>
                </Grid.Row>
            </Grid>
        </Container>
    </Segment>
    </>
);

const mapStateToProps = state => {
    return {
        activeRole: state.company.activeRole
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        null
    )(withTranslation('companyshell')(Shell))
);