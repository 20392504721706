// Copyright Caproc Oy
// System name: Aqua-Net 
// System module: Company
// Author: TS
//
// 1.2.2020 TS Initial version
// 11.7.2020 TS Added CompanySelection component
// 14.6.2023 TS Added exportReportToPdf

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setActiveCompany } from "./store/actions/company"
import { Responsive, Icon, Dropdown } from "semantic-ui-react";
import axios from "axios";
import { endpoint, host, exportToCsvURL, fetchReportPdfURL, createReportPdfURL } from "./constants";
import { Redirect } from "react-router-dom";


export const authAxios = axios.create({
    baseURL: endpoint,
    headers: {
        Authorization: {
            toString() {
                return `Token ${localStorage.getItem("token")}`;
            }
        }
    }
});

export const authFileAxios = axios.create({
    baseURL: endpoint,
    headers: {
        Authorization: {
            toString() {
                return `Token ${localStorage.getItem("token")}`;
            }
        },
        'content-type': 'multipart/form-data'
    }
});

export const restAuthAxios = axios.create({
    baseURL: host,
    headers: {
        Authorization: {
            toString() {
                return `Token ${localStorage.getItem("token")}`;
            }
        }
    }
});

export const getWidth = () => {
    const isSSR = typeof window === "undefined";
    return isSSR ? Responsive.onlyTablet.minWidth : window.innerWidth;
};
// Format given date to format defined in the "format input parameter". Possible formats are "Date", "Time", "TimeSeconds", "DateTime", "DateTimeSeconds"
export const getISOFormattedDate = (input_date, format) => {
    if (input_date === null) return null;
    // Format to "YYYY-MM-DD HH:mm:ss". getMonth() January is 0, so add + 1 to get proper calendar month number
    let formatted_date = "";
    if (format.includes("Date")) {
        formatted_date = input_date.getFullYear() + "-";
        // Add 0 if value less than 10
        if ((input_date.getMonth() + 1) < 10) {
            formatted_date = formatted_date + "0" + (input_date.getMonth() + 1) + "-";
        } else formatted_date = formatted_date + (input_date.getMonth() + 1) + "-";
        // Add 0 if value less than 10
        if (input_date.getDate() < 10) {
            formatted_date = formatted_date + "0" + input_date.getDate();
        } else formatted_date = formatted_date + input_date.getDate();
    }
    if (format.includes("Time")) {
        if (formatted_date !== "") {
            formatted_date = formatted_date + " ";
        }
        // Add 0 if value less than 10
        if (input_date.getHours() < 10) {
            formatted_date = formatted_date + "0" + input_date.getHours();
        } else formatted_date = formatted_date + input_date.getHours();
        // Add 0 if value less than 10
        if (input_date.getMinutes() < 10) {
            formatted_date = formatted_date + ":0" + input_date.getMinutes();
        } else formatted_date = formatted_date + ":" + input_date.getMinutes();
        if (format.includes("Seconds")) {
            // Add 0 if value less than 10
            if (input_date.getSeconds() < 10) {
                formatted_date = formatted_date + ":0" + input_date.getSeconds();
            } else formatted_date = formatted_date + ":" + input_date.getSeconds();
        }
    }
    return formatted_date;
};
// Calculate Net price for set of items
export const getTotalNetPrice = items => {
    let i = 0;
    let net = 0;
    for (i in items) {
        net += getItemNetPrice(items[i])
    }
    return net;
}
// Calculate Gross price for set of items
export const getTotalGrossPrice = items => {
    let i = 0;
    let gross = 0;
    // Go through all items in invoice and add them together multiplied with VAT%
    for (i in items) {
        gross += getItemGrossPrice(items[i]);
    }
    return gross;
}
// Calculate Net price for single item
export const getItemNetPrice = item => {
    let discount_multiplier = 1;
    // e.g. 10% discount => 0.90 as multiplier
    if (item.discount > 0) {
        discount_multiplier = (100 - item.discount) / 100;
    }
    return (parseFloat(parseFloat(item.net_price).toFixed(2)) * parseFloat(parseFloat(item.amount).toFixed(2)) * discount_multiplier);
}
// Calculate Gross price for single item
export const getItemGrossPrice = item => {
    let vat_multiplier = 1;
    // If item vat value is 0, use just net_price => vat_multiplier = 1
    // Otherwise change e.g. 24% VAT value into 1.24 and use as a mulplier for gross price
    if (item.vat_value > 0) {
        vat_multiplier = (item.vat_value / 100) + 1;
    }
    return getItemNetPrice(item) * vat_multiplier;
}
// Validate new passwords
export const validatePasswords = (password1, password2) => {
    if (password1 !== password2) {
        return "Passwords do not match";
    } else if (password1.length < 8) {
        return "Please check passwords. Password length needs to be more than 8 characters and include numbers and alphabets"
    } else {
        // Create an array and push all possible values that is needed in password
        let matchedCase = [];
        var ctr = 0;
        var i = 0;
        // matchedCase.push("[$@$!%*#?&]"); // Special Charector
        matchedCase.push("[0-9]");      // Numbers

        // First check numbers
        for (i = 0; i < matchedCase.length; i++) {
            if (new RegExp(matchedCase[i]).test(password1)) {
                ctr++;
            }
        }
        if (ctr > 0) {
            matchedCase = [];
            matchedCase.push("[A-Z]");      // Uppercase Alpabets
            matchedCase.push("[a-z]");     // Lowercase Alpabets    
            // Then Alpabets
            for (i = 0; i < matchedCase.length; i++) {
                if (new RegExp(matchedCase[i]).test(password1)) {
                    ctr++;
                }
            }
        }
        if (ctr < 2) {
            return "Please check passwords. Password needs to include numbers and alphabets"
        } else {
            return "ok";
        }
    }
}

export const exportToCsv = (company, file_name, titles, data) => {
    if (titles === "") {
        titles = null;
    }
    authAxios
        .put(exportToCsvURL(company), { 
            responseType: 'blob',
            file_name: file_name,
            titles: titles,
            data: data
        })
        .then(res => {
            //Create a Blob from the data stream
            const res_file = new Blob(
                ["\uFEFF"+res.data]); // Add extra characters for CSV
            //Build a URL from the file
            const fileURL = URL.createObjectURL(res_file);
            // Get the file name from the URL
            const link = document.createElement('a');
            link.href = fileURL;
            link.setAttribute('download', file_name + ".csv"); //or any other extension
            document.body.appendChild(link);
            link.click();
            return "ok";
        })
        .catch(err => {
            return err;
        });
}

export const exportReportToPdf = async (company, file_name, report_name, titles, data, sub_title=null) => {
    if (titles === "") {
        titles = null;
    }
    try {
        const response_post = await authAxios.post(createReportPdfURL(company), { 
            report_name: report_name,
            titles: titles,
            data: data,
            sub_title: sub_title
        });
        let response_filename = response_post.data;
        try {
            const response_get = await authAxios.get(fetchReportPdfURL(company, response_filename), { 
                responseType: 'blob',
            });
            //Create a Blob from the PDF Stream
            const file = new Blob(
                [response_get.data],
                { type: 'application/pdf' });

            //Build a URL from the file
            const fileURL = URL.createObjectURL(file);

            // //Open the URL on new Window
            // window.open(fileURL);

            const pdf_filename = file_name + ".pdf";
            const link = document.createElement('a');
            link.href = fileURL;
            link.setAttribute('download', pdf_filename); //or any other extension
            document.body.appendChild(link);
            link.click();

        } catch(err) {
            return err;
        }
        
    } catch (error) {
        return error;
    }
    return 0;
}


export const CompanySelection = () => {
    const dispatch = useDispatch();
    const userCompanies = useSelector(state => state.company.userCompanies);
    const isAuthenticated = useSelector(state => state.auth.token !== null);
    const [isredirect, setIsRedirect] = useState(false);

    const SetActiveCompanyDetails = (company_id, company_name, roles) => {
        dispatch(setActiveCompany(company_id, company_name, roles));
        setIsRedirect(true);
    }
    
    return (
        <React.Fragment>
            {isredirect && <Redirect to="/"/> }
            {isAuthenticated && (
                <React.Fragment>
                    {(userCompanies && userCompanies.length > 0) && (
                        <React.Fragment>
                            <div style={{paddingTop: "0.5em"}}>
                                <Dropdown
                                    trigger={<><Icon name="building"/> <strong>{localStorage.getItem("activeCompanyName")}</strong></>}
                                    floating
                                    labeled
                                >
                                    <Dropdown.Menu>
                                        {userCompanies.map((item, key) =>
                                            <Dropdown.Item
                                                key={key}
                                                text={item.name}
                                                onClick={() =>
                                                    SetActiveCompanyDetails(item.id, item.name, item.roles)
                                                }
                                            />
                                        )}
                                    </Dropdown.Menu>
                                </Dropdown>
                            <hr/>
                            </div>
                        </React.Fragment>
                    )}
                </React.Fragment>
            )}
        </React.Fragment>
    );
}

export const roundFloat = (number, decimalPlaces) => {
    const factorOfTen = Math.pow(10, decimalPlaces);
    return Math.round(number * factorOfTen) / factorOfTen;
}