// Copyright Caproc Oy
// System name: Aqua-Net 
// System module: Invoices
// Author: TS
//
// 1.2.2020 TS Initial version
// 26.3.2020 TS Added InvoiceAbnormalPayment 
// 27.3.2020 TS Added InvoiceAbnormalPayment update and create in submit function
// 31.3.2020 TS Changed details section in modal to table for better responsive action
// 28.4.2020 TS Changed handleSaveAndSendInvoicePdf to send list of invoice ids to back end instead of looping through invoices in the front end.
// 28.4.2020 TS Added Invoice comment functionality
// 29.4.2020 TS Added comment icon if Invoice has internal comments
// 29.4.2020 TS Changed button color to yellow is invoicing method is net invoice
// 30.4.2020 TS Added purchase_order and original_order in to Invoice
// 2.5.2020 TS Change Invcoice Comment type from INFO to USER for user comment. OK and ERROR are still system comment types.
// 6.5.2020 TS Changed search input and Invoice date filtering to use filter_invoices instead of invoices. Table totals are also now using filter_invoices.
// 7.5.2020 TS Added icon for error comments (InvoiceComment) in invoice. Added also acknowledge button for error messaages.
// 10.5.2020 TS Changes to invoice reminder/notification which needs to be sent in paper format always. Net invoicing forces notifications to be handled in letters and in net invoicing is not in use, notification invoice will be opened as PDF.
// 10.5.2020 TS Added handling_date in to Abnormal payments
// 12.5.2020 TS Added VAT 0% comment in to invoice details
// 16.5.2020 TS Added copy invoice
// 16.5.2020 TS Added All menu for invoices in all statuses with due date range and statuses filterin search options.
// 19.5.2020 TS Changed color for abnormal payments icon. Blue is balance is 0 and red otherwise
// 28.5.2020 TS Added contract number on invoice info in to invoice details modal
// 6.6.2020 TS Changed remove icon to trash bin
// 7.6.2020 TS Added mobile search and actions
// 8.6.2020 TS Added reference number in to search criterias
// 18.6.2020 TS Added export to CSV
// 2.9.2020 TS Added column sort and multiple edit
// 23.6.2021 TS Added + Invoice (multiple customers) button
// 28.9.2021 TS Added reminder invoice handling for all invoice method types
// 31.10.2021 TS Added create new reminder invoice
// 27.1.2022 TS Added invoice send queue functionalities
// 6.2.2022 TS Use now send queue for all invoice sending. Invoice interface sends now true or false for internal_comments, error_comments and total sum for abnormal payments. No need to loop them through in here.
// 16.2.2022 TS Added Fetch button to "P" and "all" status. 
// 27.4.2022 TS Fixed mandatory field bugs in adding abnormal payments modal.
// 15.6.2022 TS Removed restriction to mark as paid from abnormal payments.

import React from "react";
import _ from 'lodash'
import { Message, Header, Button, Table, Segment, Dimmer, Loader, Image, Modal, Card, Grid, Container, GridColumn, Checkbox, Popup, Confirm, Icon, Input, Form, Select, Responsive, Accordion } from "semantic-ui-react";
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import fiLocale from 'react-semantic-ui-datepickers/dist/locales/fi-FI';
import Shell from "./Shell";
import { authAxios, getISOFormattedDate, getItemGrossPrice, getItemNetPrice, getWidth, exportToCsv, getTotalGrossPrice, getTotalNetPrice } from "../../utils";
import { setActivity } from "../../store/actions/auth";
import { withRouter, Redirect, Link } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import {
    fetchOpenInvoicesURL, updateInvoiceDetailsURL, deleteInvoiceURL, fetchInvoicePdfURL, saveAndSendInvoicePdfURL, sendInvoiceReminderURL,
    customerInvoicingDetailsURL, openInvoicesStatistics, creditInvoiceURL, createInvoiceAbnormalPaymentURL, updateInvoiceAbnormalPaymentURL, invoiceDetailsURL, deleteInvoiceAbnormalPaymentURL,
    createInvoiceCommentURL, updateInvoiceCommentURL, deleteInvoiceCommentURL, fetchOpenInvoicesByDatesURL, saveMultipleEditInvoicesURL, reminderInvoiceNewCopyURL,addInvoicesInToSendQueueURL
} from "../../constants";


class Invoices extends React.Component {
    state = {
        invoices: null,
        invoices_status: null,
        error: null,
        loading: false,
        success_mgs: null,
        modal_open: false,
        active_invoice: false,
        confirm_open: false,
        selected: [],
        select_all: false,
        allowed_statuses: ['C', 'A', 'S', 'O', 'P', 'B','Q', 'month', 'all'], // Allowed statuses defined in Invoice model.
        active_customer_invoicing_details: {
            invoicing_details_id: "",
            customer_id: "",
            payment_term: "",
            default_customer_reference: "",
            default_company_reference: "",
            no_vat: "",
            discount: "",
            invoicing_method: "",
            invoicing_email: ""
        },
        statistics: null,
        search: "",
        search_start_date: null,
        search_end_date: null,
        search_start_due_date: null,
        search_end_due_date: null,
        search_status: null,
        search_status_options: [],
        abnormal_payment_modal_open: false,
        active_abnormal_payment: {
            id: null,
            company: null,
            customer: null,
            invoice: null,
            payment_date: null,
            handling_date: null,
            description: null,
            author: null,
            paid_amount: null
        },
        create_abnormal_payment: false,
        fetchUpdatedInvoice: false,
        send_status_modal_open: false,
        net_invoice_response: {
            sending_failed_count: null,
            sending_ok_count: null,
            response_text: null,
            networked_invoices: null,
            letter_invoices: null,
            letter_pages: null,
            batch_id: null
        },
        invoice_comment_modal_open: false,
        active_invoice_comment: {
            id: null,
            company: null,
            customer: null,
            invoice: null,
            comment_type: null,
            comment: null,
            author: null,
            is_acknowledged: null
        },
        create_invoice_comment: false,
        visible_invoices: [],
        selected_dates: true,
        activeIndexSearch: 0,
        activeIndexActions: 0,
        activeIndexInvoiceActions: 0,
        activeIndexModalActions: 0,
        activeIndexModalDetails: 0,
        activeIndexModalComment: 0,
        activeIndexModalAbnormal: 0,
        multiple_edit: {
            item: null,
            value: null
        },
        multiple_edit_item_options: [],
        multiple_edit_modal_open: false,
        multiple_edit_error: null,
        multiple_edit_confirm_open: false,
        multiple_edit_confirm_text: "",
        multiple_edit_confirm_item: null,
        column: null,
        direction: null,
    };

    componentDidMount() {
        this.props.setActivity();
        if (this.state.allowed_statuses.includes(this.props.match.params.status)) {
            this.handleFetchOpenInvoices(this.props.match.params.status);
            if (this.props.match.params.status === "all") {
                this.handleSearchStatusOptions();
            }
        } else {
            this.setState({
                loading: false,
                error: `Not allowed parameter ${this.props.match.params.status}`
            });
        }
    }

    handleClick = (e, titleProps) => {
        const { index } = titleProps
        const { activeIndexSearch } = this.state
        const newIndex = activeIndexSearch === index ? -1 : index

        this.setState({ activeIndexSearch: newIndex })
    };

    handleClick2 = (e, titleProps) => {
        const { index } = titleProps
        const { activeIndexActions } = this.state
        const newIndex = activeIndexActions === index ? -1 : index

        this.setState({ activeIndexActions: newIndex })
    };

    handleClickInvoiceActions = (e, titleProps) => {
        const { index } = titleProps
        const { activeIndexInvoiceActions } = this.state
        const newIndex = activeIndexInvoiceActions === index ? -1 : index

        this.setState({ activeIndexInvoiceActions: newIndex })
    };

    handleClickModalActions = (e, titleProps) => {
        const { index } = titleProps
        const { activeIndexModalActions } = this.state
        const newIndex = activeIndexModalActions === index ? -1 : index

        this.setState({ activeIndexModalActions: newIndex })
    };

    handleClickModalDetails = (e, titleProps) => {
        const { index } = titleProps
        const { activeIndexModalDetails } = this.state
        const newIndex = activeIndexModalDetails === index ? -1 : index

        this.setState({ activeIndexModalDetails: newIndex })
    };

    handleClickModalComment = (e, titleProps) => {
        const { index } = titleProps
        const { activeIndexModalComment } = this.state
        const newIndex = activeIndexModalComment === index ? -1 : index

        this.setState({ activeIndexModalComment: newIndex })
    };

    handleClickModalAbnormal = (e, titleProps) => {
        const { index } = titleProps
        const { activeIndexModalAbnormal } = this.state
        const newIndex = activeIndexModalAbnormal === index ? -1 : index

        this.setState({ activeIndexModalAbnormal: newIndex })
    };


    handleSearchStatusOptions = () => {
        const { t } = this.props;
        let options = [];
        options[0] = { key: "C", text: t("Created"), value: "C" };
        options[1] = { key: "A", text: t("Approved"), value: "A" };
        options[2] = { key: "Q", text: t("Sending"), value: "Q" };
        options[3] = { key: "S", text: t("Sent"), value: "S" };
        options[4] = { key: "P", text: t("Paid"), value: "P" };
        options[5] = { key: "O", text: t("Overdue"), value: "O" };
        options[6] = { key: "B", text: t("Abnormal"), value: "B" };
        this.setState({
            search_status_options: options
        });
    }

    handleFetchOpenInvoices = async (status) => {
        this.setState({
            loading: true,
            invoices_status: status
        });
        const { activeCompanyId } = this.props;
        if (this.props.match.params.status === "P" || this.props.match.params.status === "all") {
            const { search_start_date, search_end_date } = this.state;
            if (search_start_date !== null && search_end_date !== null) {
                await authAxios
                    .get(fetchOpenInvoicesByDatesURL(activeCompanyId, status, search_start_date, search_end_date))
                    .then(res => {
                        this.setState({
                            invoices: res.data,
                            error: null,
                            loading: false
                        });
                    })
                    .catch(err => {
                        this.setState({
                            loading: false,
                            error: err
                        });
                    });
            }
        } else {
            await authAxios
                .get(fetchOpenInvoicesURL(activeCompanyId, status))
                .then(res => {
                    this.setState({
                        invoices: res.data,
                        error: null,
                        loading: false
                    });
                })
                .catch(err => {
                    this.setState({
                        loading: false,
                        error: err
                    });
                });
        }
        this.handleFetchOpenInvoicesStatistics();
    };

    handleFetchOpenInvoicesStatistics = () => {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;
        authAxios
            .get(openInvoicesStatistics(activeCompanyId))
            .then(res => {
                this.setState({
                    statistics: res.data,
                    error: null,
                    loading: false
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    error: err
                });
            });
    };

    handleFetchInvoicePdf = async (invoice_id) => {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;
        await authAxios
            .get(fetchInvoicePdfURL(activeCompanyId, invoice_id), { responseType: 'blob' })
            .then(res => {
                // console.log(res);
                //Create a Blob from the PDF Stream
                const file = new Blob(
                    [res.data],
                    { type: 'application/pdf' });
                //Build a URL from the file
                const fileURL = URL.createObjectURL(file);
                // // //Open the URL on new Window
                // window.open(fileURL);

                const link = document.createElement('a');
                link.href = fileURL;
                link.setAttribute('download', 'lasku.pdf'); //or any other extension
                document.body.appendChild(link);
                link.click();

                this.setState({
                    error: null,
                    loading: false
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    error: err
                });
            });
    };

    handleSaveAndSendInvoicePdf = async (invoices_list) => {
        this.handleAddInvoicesToSendQueue(invoices_list);
        // if (invoices_list.length > 0) {
        //     this.setState({
        //         loading: true
        //     });
        //     const { activeCompanyId } = this.props;
        //     await authAxios
        //         .post(saveAndSendInvoicePdfURL(activeCompanyId), {
        //             invoices: invoices_list
        //         })
        //         .then(res => {
        //             this.setState({
        //                 error: null,
        //                 loading: false,
        //                 fetchUpdatedInvoice: true,
        //                 net_invoice_response: res.data,
        //                 send_status_modal_open: true
        //             });
        //             // // Update invoice status as "Sent"
        //             // this.updateInvoiceStatus(invoice, "S");
        //         })
        //         .catch(err => {
        //             this.setState({
        //                 loading: false,
        //                 error: err
        //             });
        //         });
        // }
    };

    handleAddInvoicesToSendQueue = async (invoices_list) => {
        if (invoices_list.length > 0) {
            this.setState({
                loading: true
            });
            const { activeCompanyId } = this.props;
            await authAxios
                .post(addInvoicesInToSendQueueURL(activeCompanyId), {
                    invoices: invoices_list
                })
                .then(res => {
                    this.setState({
                        error: null,
                        loading: false,
                        fetchUpdatedInvoice: true
                        // net_invoice_response: res.data,
                        // send_status_modal_open: true
                    });
                    // // Update invoice status as "Sent"
                    // this.updateInvoiceStatus(invoice, "S");
                })
                .catch(err => {
                    this.setState({
                        loading: false,
                        error: err
                    });
                });
        }
    };


    // handleSaveAndSendInvoicePdf = (invoice) => {
    //     this.setState({
    //         loading: true
    //     });
    //     const { activeCompanyId } = this.props;
    //     authAxios
    //         .get(saveAndSendInvoicePdfURL(activeCompanyId, invoice.id))
    //         .then(res => {
    //             this.setState({
    //                 error: null,
    //                 loading: false
    //             });
    //             // Update invoice status as "Sent"
    //             this.updateInvoiceStatus(invoice, "S");
    //         })
    //         .catch(err => {
    //             this.setState({
    //                 loading: false,
    //                 error: err
    //             });
    //         });
    // };

    handleSendInvoiceReminder = async (invoice, open_pdf) => {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;
        if (!open_pdf) {
            await authAxios
                .get(sendInvoiceReminderURL(activeCompanyId, invoice.id, "false"))
                .then(res => {
                    this.setState({
                        error: null,
                        loading: false,
                        fetchUpdatedInvoice: true,
                        net_invoice_response: res.data,
                        send_status_modal_open: true
                    });
                    this.closeModal();
                })
                .catch(err => {
                    this.setState({
                        loading: false,
                        error: err
                    });
                });
        } else {
            await authAxios
                .get(sendInvoiceReminderURL(activeCompanyId, invoice.id, "true"), { responseType: 'blob' })
                .then(res => {
                    //Create a Blob from the PDF Stream
                    const file = new Blob(
                        [res.data],
                        { type: 'application/pdf' });
                    //Build a URL from the file
                    const fileURL = URL.createObjectURL(file);
                    // // //Open the URL on new Window
                    // window.open(fileURL);

                    const link = document.createElement('a');
                    link.href = fileURL;
                    link.setAttribute('download', 'lasku.pdf'); //or any other extension
                    document.body.appendChild(link);
                    link.click();
                    this.setState({
                        error: null,
                        loading: false
                    });
                    this.closeModal();
                })
                .catch(err => {
                    this.setState({
                        loading: false,
                        error: err
                    });
                });
        }
    };

    handleCreateReminderInvoice = async (invoice, orig_invoice_new_status) => {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;
        await authAxios
            .post(reminderInvoiceNewCopyURL(activeCompanyId, invoice.id, orig_invoice_new_status))
            .then(res => {
                this.setState({
                    error: null,
                    loading: false
                });
                this.closeModal();
                this.props.history.push("/invoice/invoices/C/");
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    error: err
                });
            });
    };


    handleCreateCreditInvoice = async (invoice) => {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;
        await authAxios
            .post(creditInvoiceURL(activeCompanyId, invoice.id))
            .then(res => {
                this.setState({
                    error: null,
                    loading: false
                });
                this.closeModal();
                this.props.history.push("/invoice/invoices/C/");
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    error: err
                });
            });
    };

    handleMultipleSaveAndSendInvoicePdf = () => {
        let i = 0;
        let invoices_list = []
        for (i in this.state.selected) {
            // Don't handle paper invoices
            if (this.state.selected[i].invoicing_method !== "P") {
                invoices_list.push(this.state.selected[i].id);
            }
        }
        this.handleSaveAndSendInvoicePdf(invoices_list);
    };

    handleMultipleAddToSendQueue = () => {
        let i = 0;
        let invoices_list = []
        for (i in this.state.selected) {
            // Don't handle paper invoices
            if (this.state.selected[i].invoicing_method !== "P") {
                invoices_list.push(this.state.selected[i].id);
            }
        }
        this.handleAddInvoicesToSendQueue(invoices_list);
    };


    // Fetch invoicing details for selected customer
    handleCustomerInvoicingDetails = async (customer_id) => {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;
        await authAxios
            .get(customerInvoicingDetailsURL(activeCompanyId, customer_id))
            .then(res => {
                this.setState({
                    active_customer_invoicing_details: res.data,
                    error: null,
                    success_mgs: null,
                    loading: false
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    success_mgs: null,
                    error: err
                });
            });
    };

    invoiceDetails = id => {
        this.props.history.push(`/invoice/invoice-details/edit/${id}/`)
    }

    handleCopyInvoice = id => {
        this.props.history.push(`/invoice/invoice-details/copy/${id}/`)
    }

    getTotalNetPriceAllInvoices = (invoices) => {
        // const { invoices } = this.state;
        let i = 0;
        let net = 0;
        for (i in invoices) {
            // net += getTotalNetPrice(invoices[i].invoiceitems);
            net += invoices[i].net_total;
        }
        return net;
    }

    getTotalGrossPriceAllInvoices = (invoices) => {
        // const { invoices } = this.state;
        let i = 0;
        let gross = 0;
        for (i in invoices) {
            // gross += getTotalGrossPrice(invoices[i].invoiceitems);
            gross += invoices[i].gross_total;
        }
        return gross;
    }

    updateInvoice = async (invoice, fetchInvoices) => {
        this.setState({
            loading: true,
        });
        const { activeCompanyId } = this.props;
        if (invoice.status === "Delete") {
            await authAxios
                .delete(deleteInvoiceURL(activeCompanyId, invoice.id), {
                })
                .then(res => {
                    this.setState({
                        loading: false,
                        error: null
                    });
                    // Fetch updated invoices only for single updates or after the last update in batch
                    if (fetchInvoices) {
                        this.handleFetchOpenInvoices(this.props.match.params.status);
                        // Empty selected
                        this.setState({ selected: [], select_all: false });
                    }
                })
                .catch(err => {
                    this.setState({
                        loading: false,
                        success_mgs: null,
                        error: err
                    });
                });
        } else {
            await authAxios
                .put(updateInvoiceDetailsURL(activeCompanyId, invoice.id), {
                    ...invoice
                })
                .then(res => {
                    this.setState({
                        loading: false,
                        error: null
                    });
                    // Fetch updated invoices only for single updates or after the last update in batch
                    if (fetchInvoices) {
                        this.handleFetchOpenInvoices(this.props.match.params.status);
                        // Empty selected
                        this.setState({ selected: [], select_all: false });
                    }
                })
                .catch(err => {
                    this.setState({
                        loading: false,
                        success_mgs: null,
                        error: err
                    });
                });
        }
    }

    updateInvoiceStatus = (invoice, newStatus) => {
        // If multiple invoices 
        if (invoice === "All" || invoice === "Selected") {
            let list = null;
            if (invoice === "All") {
                list = this.state.invoices;
            } else {
                list = this.state.selected;
            }
            let i = 0;
            for (i in list) {
                list[i].status = newStatus;
                // Add invoice sent date if new status is "S" and paid date if status is "P"
                if (newStatus === "S") {
                    list[i].date_sent = getISOFormattedDate(new Date(), "DateTimeSeconds");
                } else if (newStatus === "P") {
                    list[i].date_paid = getISOFormattedDate(new Date(), "DateTimeSeconds");
                }
                // Fetch updated invoices only after last update in batch
                if (i < list.length - 1) {
                    this.updateInvoice(list[i], false);
                } else {
                    this.updateInvoice(list[i], true);
                }
            }
        } else { // If only one invoice
            invoice.status = newStatus;
            // Add invoice sent date if new status is "S" and paid date if status is "P"
            if (newStatus === "S") {
                invoice.date_sent = getISOFormattedDate(new Date(), "DateTimeSeconds");
            } else if (newStatus === "P") {
                invoice.date_paid = getISOFormattedDate(new Date(), "DateTimeSeconds");
            }
            this.updateInvoice(invoice, true);
        }
    }

    openInvoiceDetails = async invoice => {
        // this.handleCustomerInvoicingDetails(invoice.customer.id);
        this.handleCustomerInvoicingDetails(invoice.customer_id);
        await this.handleFetchInvoiceDetails(invoice.id);
        this.setState({
            modal_open: true
            // active_invoice: invoice
        });
    }

    closeModal() {
        this.setState({
            modal_open: false,
            active_invoice: null
        });
    }

    handleCheckboxChange = (e, { value, checked }) => {
        let { selected } = this.state;
        if (checked) {
            if (!selected.includes(value)) {
                selected.push(value);
            }
        } else {
            if (selected.includes(value)) {
                selected.splice(selected.indexOf(value), 1);
            }
        }
        this.setState({ selected });
    };

    handleSelectAll = filtered_invoices => {

        if (!this.state.select_all) {
            let new_list = [];
            let i = 0;
            for (i in filtered_invoices) {
                new_list.push(filtered_invoices[i]);
            }
            // for (i in this.state.invoices) {
            //     new_list.push(this.state.invoices[i]);
            // }
            this.setState({
                select_all: true,
                selected: new_list
            })
        }
        else this.setState({ select_all: false, selected: [] });
    };

    handleConfirm = (action, param) => {
        if (action === "Delete") {
            this.updateInvoiceStatus(param, action);
        } else if (action === "multiple_emails") {
            this.handleMultipleSaveAndSendInvoicePdf();
        } else if (action === "multiple_send_queue") {
            this.handleMultipleAddToSendQueue();
        } else if (action === "email") {
            this.handleSaveAndSendInvoicePdf([param.id]);
        } else if (action === "reminder_email") {
            this.handleSendInvoiceReminder(param, false);
        } else if (action === "create_reminder_invoice") {
            this.handleCreateReminderInvoice(param, "original");
        }

        this.closeConfirm();
    };

    openConfirm = (action, param, text) => {
        if (action === "multiple_emails") {
            // Open confirm only if any invoices are selected
            if (this.state.selected.length > 0) {
                this.setState({
                    confirm_open: true,
                    confirm_action: action,
                    confirm_param: param,
                    confirm_text: text
                });
            }
        } else {
            this.setState({
                confirm_open: true,
                confirm_action: action,
                confirm_param: param,
                confirm_text: text
            });
        }
    };

    closeConfirm = () => {
        this.setState({
            confirm_open: false,
            confirm_action: "",
            confirm_param: "",
            confirm_text: ""
        });
    };


    handleStartDateChange = value => {
        this.setState({
            search_start_date: getISOFormattedDate(value, "Date"),
            selected: [],
            select_all: false,
            refresh: false
            //refresh: (this.props.match.params.status === "P" || this.props.match.params.status === "all") ? true : false
        });
    };

    handleEndDateChange = value => {
        this.setState({
            search_end_date: getISOFormattedDate(value, "Date"),
            selected: [],
            select_all: false,
            refresh: false
            //refresh: (this.props.match.params.status === "P" || this.props.match.params.status === "all") ? true : false
        });
    };

    handleStartDueDateChange = value => {
        this.setState({
            search_start_due_date: getISOFormattedDate(value, "Date"),
            selected: [],
            select_all: false
        });
    };

    handleEndDueDateChange = value => {
        this.setState({
            search_end_due_date: getISOFormattedDate(value, "Date"),
            selected: [],
            select_all: false
        });
    };


    handleGetAbnormalSum = invoice => {
        let i = 0;
        let sum = 0;
        for (i in invoice.invoiceabnormalpayments) {
            sum += invoice.invoiceabnormalpayments[i].paid_amount;
        }
        return sum;
    };

    handleAddAbnormalPayment = () => {
        this.setState({
            create_abnormal_payment: true,
            abnormal_payment_modal_open: true,
            active_abnormal_payment: {
                id: null,
                company: null,
                customer: null,
                invoice: null,
                payment_date: getISOFormattedDate(new Date(), "Date"),
                handling_date: getISOFormattedDate(new Date(), "Date"),
                description: "",
                author: null,
                paid_amount: null
            }
        });
    };

    handleEditAbnormalPayment = abnormal_payment => {
        this.setState({
            create_abnormal_payment: false,
            abnormal_payment_modal_open: true,
            active_abnormal_payment: {
                id: abnormal_payment.id,
                company: abnormal_payment.company,
                customer: abnormal_payment.customer,
                invoice: abnormal_payment.invoice,
                payment_date: abnormal_payment.payment_date,
                handling_date: abnormal_payment.handling_date,
                description: abnormal_payment.description,
                author: abnormal_payment.author.id,
                paid_amount: abnormal_payment.paid_amount
            }
        });

    };

    closeAbnormalPaymentModal = () => {
        this.setState({
            create_abnormal_payment: false,
            abnormal_payment_modal_open: false,
            active_abnormal_payment: {
                id: null,
                company: null,
                customer: null,
                invoice: null,
                payment_date: null,
                handling_date: null,
                description: "",
                author: null,
                paid_amount: null
            }
        });
    };

    handleDeleteAbnormalPayment = abnormal_payment => {
        this.setState({
            loading: true
        });
        const { active_invoice } = this.state;
        const { activeCompanyId } = this.props;
        authAxios
            .delete(deleteInvoiceAbnormalPaymentURL(activeCompanyId, active_invoice.id, abnormal_payment.id))
            .then(res => {
                this.setState({
                    error: null,
                    success_mgs: null,
                    loading: false,
                    fetchUpdatedInvoice: true
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    success_mgs: null,
                    error: err
                });
            });
    };

    handleAbnormalPaymentSubmit = async e => {
        e.preventDefault();
        this.setState({
            loading: true
        });
        const { create_abnormal_payment, active_abnormal_payment, active_invoice } = this.state;
        const { activeCompanyId, t } = this.props;
        if (active_abnormal_payment.description === "" || active_abnormal_payment.description === null) {
            this.setState({
                error: t("Please fill in description"),
                success_mgs: null,
                loading: false
            });
        } else if (active_abnormal_payment.paid_amount === "" || active_abnormal_payment.paid_amount === null) {
                this.setState({
                    error: t("Please fill in paid amount"),
                    success_mgs: null,
                    loading: false
                });
        } else {        
            if (create_abnormal_payment) {
                const updatedPayment = {
                    ...active_abnormal_payment,
                    company: activeCompanyId,
                    customer: active_invoice.customer.id,
                    invoice: active_invoice.id,
                    author: localStorage.getItem("UserID")
                }
                await authAxios
                    .post(createInvoiceAbnormalPaymentURL(activeCompanyId, active_invoice.id), {
                        ...updatedPayment
                    })
                    .then(res => {
                        this.setState({
                            loading: false,
                            error: null,
                            fetchUpdatedInvoice: true
                        });
                        this.closeAbnormalPaymentModal();
                        this.updateInvoiceStatus(active_invoice, "B");
                    })
                    .catch(err => {
                        this.setState({
                            loading: false,
                            success_mgs: null,
                            error: err
                        });
                    });
            } else {
                await authAxios
                    .put(updateInvoiceAbnormalPaymentURL(activeCompanyId, active_invoice.id, active_abnormal_payment.id), {
                        ...active_abnormal_payment
                    })
                    .then(res => {
                        this.setState({
                            loading: false,
                            error: null,
                            fetchUpdatedInvoice: true
                        });
                        this.closeAbnormalPaymentModal();
                    })
                    .catch(err => {
                        this.setState({
                            loading: false,
                            success_mgs: null,
                            error: err
                        });
                    });
            }
        }
    };

    handleAbnormalPaymentDateChange = value => {
        const { active_abnormal_payment } = this.state;
        const updatedDetails = {
            ...active_abnormal_payment,
            payment_date: getISOFormattedDate(value, "Date")
        }
        this.setState({
            active_abnormal_payment: updatedDetails
        });
    };

    handleAbnormalPaymentHandlingDateChange = value => {
        const { active_abnormal_payment } = this.state;
        const updatedDetails = {
            ...active_abnormal_payment,
            handling_date: getISOFormattedDate(value, "Date")
        }
        this.setState({
            active_abnormal_payment: updatedDetails
        });
    };


    handleAbnormalPaymentChange = e => {
        const { t } = this.props;
        if (e.target.name === "paid_amount" && isNaN(e.target.value)) {
            this.setState({
                error: t("Paid amount value is not number")
            });
        } else {
            this.setState({
                error: null
            });
        }
        const { active_abnormal_payment } = this.state;
        const updatedDetails = {
            ...active_abnormal_payment,
            [e.target.name]: e.target.value
        }
        this.setState({
            active_abnormal_payment: updatedDetails
        });
    };

    handleFetchInvoiceDetails = (invoice_id) => {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;
        authAxios
            .get(invoiceDetailsURL(activeCompanyId, invoice_id))
            .then(res => {
                this.setState({
                    active_invoice: res.data,
                    error: null,
                    success_mgs: null,
                    loading: false
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    success_mgs: null,
                    error: err
                });
            });
    };


    handleUpdateActiveInvoiceDetails = () => {
        this.setState({
            loading: true,
            fetchUpdatedInvoice: false,
        });
        const { active_invoice } = this.state;
        const { activeCompanyId } = this.props;
        authAxios
            .get(invoiceDetailsURL(activeCompanyId, active_invoice.id))
            .then(res => {
                this.setState({
                    active_invoice: res.data,
                    error: null,
                    success_mgs: null,
                    loading: false
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    success_mgs: null,
                    error: err
                });
            });
    };

    closeSendStatusModal = () => {
        const updated_net_invoice_response = {
            sending_failed_count: null,
            sending_ok_count: null,
            response_text: null,
            networked_invoices: null,
            letter_invoices: null,
            letter_pages: null,
            batch_id: null
        }
        this.setState({
            net_invoice_response: updated_net_invoice_response,
            send_status_modal_open: false
        });
    };

    handleAddInvoiceComment = () => {
        this.setState({
            create_invoice_comment: true,
            invoice_comment_modal_open: true,
            active_invoice_comment: {
                id: null,
                company: null,
                customer: null,
                invoice: null,
                comment_type: "USER",
                comment: "",
                author: null,
                is_acknowledged: true
            }
        });
    };

    handleEditInvoiceComment = invoice_comment => {
        this.setState({
            create_invoice_comment: false,
            invoice_comment_modal_open: true,
            active_invoice_comment: {
                id: invoice_comment.id,
                company: invoice_comment.company,
                customer: invoice_comment.customer,
                invoice: invoice_comment.invoice,
                comment_type: invoice_comment.comment_type,
                comment: invoice_comment.comment,
                author: invoice_comment.author.id,
                is_acknowledged: invoice_comment.is_acknowledged
            }
        });

    };

    closeInvoiceCommentModal = () => {
        this.setState({
            create_invoice_comment: false,
            invoice_comment_modal_open: false,
            active_invoice_comment: {
                id: null,
                company: null,
                customer: null,
                invoice: null,
                comment_type: "USER",
                comment: "",
                author: null,
                is_acknowledged: true
            }
        });
    };

    handleDeleteInvoiceComment = async invoice_comment => {
        this.setState({
            loading: true
        });
        const { active_invoice } = this.state;
        const { activeCompanyId } = this.props;
        await authAxios
            .delete(deleteInvoiceCommentURL(activeCompanyId, active_invoice.id, invoice_comment.id))
            .then(res => {
                this.setState({
                    error: null,
                    success_mgs: null,
                    loading: false,
                    fetchUpdatedInvoice: true
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    success_mgs: null,
                    error: err
                });
            });
    };

    handleAcknowledgeInvoiceComment = async invoice_comment => {
        this.setState({
            loading: true
        });
        const { active_invoice } = this.state;
        const { activeCompanyId } = this.props;
        await authAxios
            .put(updateInvoiceCommentURL(activeCompanyId, active_invoice.id, invoice_comment.id), {
                ...invoice_comment,
                is_acknowledged: !invoice_comment.is_acknowledged,
                author: invoice_comment.author.id
            })
            .then(res => {
                this.setState({
                    error: null,
                    success_mgs: null,
                    loading: false,
                    fetchUpdatedInvoice: true
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    success_mgs: null,
                    error: err
                });
            });
    };

    handleInvoiceCommentSubmit = async e => {
        e.preventDefault();
        this.setState({
            loading: true
        });
        const { create_invoice_comment, active_invoice_comment, active_invoice } = this.state;
        const { activeCompanyId } = this.props;
        if (create_invoice_comment) {
            const updatedComment = {
                ...active_invoice_comment,
                company: activeCompanyId,
                customer: active_invoice.customer.id,
                invoice: active_invoice.id,
                author: localStorage.getItem("UserID")
            }
            await authAxios
                .post(createInvoiceCommentURL(activeCompanyId, active_invoice.id), {
                    ...updatedComment
                })
                .then(res => {
                    this.setState({
                        loading: false,
                        error: null,
                        fetchUpdatedInvoice: true
                    });
                    this.closeInvoiceCommentModal();
                })
                .catch(err => {
                    this.setState({
                        loading: false,
                        success_mgs: null,
                        error: err
                    });
                });
        } else {
            await authAxios
                .put(updateInvoiceCommentURL(activeCompanyId, active_invoice.id, active_invoice_comment.id), {
                    ...active_invoice_comment
                })
                .then(res => {
                    this.setState({
                        loading: false,
                        error: null,
                        fetchUpdatedInvoice: true
                    });
                    this.closeInvoiceCommentModal();
                })
                .catch(err => {
                    this.setState({
                        loading: false,
                        success_mgs: null,
                        error: err
                    });
                });
        }
    };

    handleInvoiceCommentChange = e => {
        const { active_invoice_comment } = this.state;
        const updatedDetails = {
            ...active_invoice_comment,
            [e.target.name]: e.target.value
        }
        this.setState({
            active_invoice_comment: updatedDetails,
            error: null
        });
    };

    handleCheckInternalComments = invoice => {
        if (invoice.invoicecomments.length > 0) {
            let i = 0;
            for (i in invoice.invoicecomments) {
                if (invoice.invoicecomments[i].comment_type === "USER") {
                    return true;
                }
            }
            return false;
        } else {
            return false;
        }
    }

    handleCheckErrorComments = invoice => {
        if (invoice.invoicecomments.length > 0) {
            let i = 0;
            for (i in invoice.invoicecomments) {
                if (invoice.invoicecomments[i].comment_type === "ERROR" && !invoice.invoicecomments[i].is_acknowledged) {
                    return true;
                }
            }
            return false;
        } else {
            return false;
        }
    }

    handleChange = e => {
        if (e.target.name === 'search') {
            this.setState({
                [e.target.name]: e.target.value,
                selected: [],
                select_all: false
            });
        } else {
            this.setState({
                [e.target.name]: e.target.value
            });
        }
    };

    handleSelectChange = (e, { name, value }) => {
        this.setState({
            [name]: value
        });
    };

    handleExportToCSV = items => {
        this.setState({
            loading: true
        });
        const { t } = this.props;
        if (items !== null) {
            const titles = [
                t("Customer"),
                t("Name"),
                t("Invoice number"),
                t("Original invoice number"),
                t("Status"),
                t("Author"),
                t("Invoice reference"),
                t("Invoice date"),
                t("Due date"),
                t("Payment term"),
                t("Net €"),
                t("Tax €"),
                t("Gross €"),
                t("Company reference"),
                t("Customer reference"),
                t("Purchase order"),
                t("Invoice comment"),
                t("VAT 0% comment"),
                t("Invoicing period"),
                t("Invoice sent"),
                t("Invoice paid"),
                t("Invoicing method"),
                t("Invoicing email"),
                t("Automatic invoice sending"),
                t("Created_detail"),
                t("Last modified")
            ];

            exportToCsv(this.props.activeCompanyId, "invoices", titles, items);
        }
        this.setState({
            loading: false
        });
    };

    // Multiple edit functions
    handleMultiEditSelectChange = (e, { name, value }) => {
        let updatedValue;
        if (name === "item") {
            updatedValue = {
                ...this.state.multiple_edit,
                item: value,
                value: null
            }
        } else if (name === "payment_term") {
            updatedValue = {
                ...this.state.multiple_edit,
                value: value
            }
        }
        this.setState({
            multiple_edit: updatedValue,
            multiple_edit_error: null,
        });
    };

    handleMultiEditChange = e => {
        const updatedValue = {
            ...this.state.multiple_edit,
            value: e.target.value
        }
        this.setState({
            multiple_edit: updatedValue,
            multiple_edit_error: null
        });
    };

    handleMultiEditCheckboxChange = (e, { value, checked }) => {
        const updatedValue = {
            ...this.state.multiple_edit,
            value: checked
        }
        this.setState({
            multiple_edit: updatedValue,
            multiple_edit_error: null,
        });
    };

    handleMultiEditDateChange = value => {
        const updatedValue = {
            ...this.state.multiple_edit,
            value: getISOFormattedDate(value, "Date")
        }
        this.setState({
            multiple_edit: updatedValue,
            multiple_edit_error: null
        });
    };

    handleMultiEditOptions = (state) => {
        const { t } = this.props;
        const options = []
        if (state === "C" || state === "all") {
            options.push(
                {
                    key: "name",
                    text: t("Name"),
                    value: "name"
                }
            );
            options.push(
                {
                    key: "contract_number_on_invoice",
                    text: t("Contract number on invoice"),
                    value: "contract_number_on_invoice"
                }
            );
            options.push(
                {
                    key: "comment",
                    text: t("Invoice comment"),
                    value: "comment"
                }
            );
            options.push(
                {
                    key: "no_vat_comment",
                    text: t("VAT 0% comment"),
                    value: "no_vat_comment"
                }
            );
            options.push(
                {
                    key: "customer_reference",
                    text: t("Customer reference"),
                    value: "customer_reference"
                }
            );
            options.push(
                {
                    key: "company_reference",
                    text: t("Company reference"),
                    value: "company_reference"
                }
            );
            options.push(
                {
                    key: "purchase_order",
                    text: t("Purchase order"),
                    value: "purchase_order"
                }
            );
            options.push(
                {
                    key: "payment_term",
                    text: t("Payment term"),
                    value: "payment_term"
                }
            );
            options.push(
                {
                    key: "date",
                    text: t("Invoice date"),
                    value: "date"
                }
            );
        } 
        options.push(
            {
                key: "booking_date",
                text: t("Booking date"),
                value: "booking_date"
            }
        );
        if (state !== "P" || state === "all") {
            options.push(
                {
                    key: "due_date",
                text: t("Due date"),
                value: "due_date"
                }
            );
        }
        if (state === "S" || state === "P" || state === "O" || state === "all") {
            options.push(
                {
                    key: "date_sent",
                    text: t("Invoice sent"),
                    value: "date_sent"
                }
            );
        }
        if (state === "P" || state === "all") {
            options.push(
                {
                    key: "date_paid",
                    text: t("Invoice paid"),
                    value: "date_paid"
                }
            );
        }
        return options;
    };

    handleGetMultiEditSelectOptions = item => {
        const { t } = this.props;
        let options = [];
        if (item === "payment_term") {
            for (let i = 0; i < 365; i++) {
                options.push({
                    key: i,
                    text: i.toString(),
                    value: i
                });
            }
        }
        return options;
    };

    handleOpenMultiEditModal = () => {
        this.setState({ multiple_edit_modal_open: true });
    };

    handleCloseMultiEditModal = () => {
        this.setState({
            multiple_edit_modal_open: false,
            multiple_edit: {
                item: null,
                value: null
            },
            multiple_edit_confirm_open: false,
            multiple_edit_confirm_text: "",
            multiple_edit_confirm_item: null,
        });
    };

    handleGetMultiEditLabel = item => {
        const options = this.handleMultiEditOptions("all");
        let i = 0;
        for (i in options) {
            if (item === options[i].value) return options[i].text;
        }
    };

    handleGetStatusTranslation = status => {
        const { t } = this.props;
        let translation = "";
        if (status === "C") translation = t("Created")
        else if (status === "S") translation = t("Sent")
        else if (status === "P") translation = t("Paid")
        else if (status === "A") translation = t("Approved")
        else if (status === "O") translation = t("Overdue")
        else if (status === "B") translation = t("Abnormal")
        return translation;
    };

    handleSort = (clickedColumn) => () => {
        const { column, invoices, direction } = this.state

        if (column !== clickedColumn) {
            let sortedArray;
            if (clickedColumn === "status") {
                sortedArray = _.sortBy(invoices, [invoice => this.handleGetStatusTranslation(invoice["status"]).toLowerCase()]);
            } else if (clickedColumn === "customer_name") {
                sortedArray = _.sortBy(invoices, [invoice => invoice["customer"]["name"].toLowerCase()]);
            } else if (clickedColumn === "net") {
                // sortedArray = _.sortBy(invoices, [invoice => getTotalNetPrice(invoice.invoiceitems)]);
                sortedArray = _.sortBy(invoices, [invoice => invoice.net_total]);
            } else if (clickedColumn === "gross") {
                // sortedArray = _.sortBy(invoices, [invoice => getTotalGrossPrice(invoice.invoiceitems)]);
                sortedArray = _.sortBy(invoices, [invoice => invoice.gross_total]);
            } else {
                sortedArray = _.sortBy(invoices, [clickedColumn]);
            }

            this.setState({
                column: clickedColumn,
                invoices: sortedArray,
                direction: 'ascending',
            });
            return;
        }

        this.setState({
            invoices: invoices.reverse(),
            direction: direction === 'ascending' ? 'descending' : 'ascending',
        })
    };

    handleCheckSubmitMultiEditConditions = e => {
        const { t } = this.props;
        const { multiple_edit } = this.state;
        if (multiple_edit.item === null || multiple_edit.item === "") {
            this.setState({
                multiple_edit_error: t("Please select item to edit")
            });
        } else if (multiple_edit.value === null || multiple_edit.value === "") {
            if (multiple_edit.item === "date" || multiple_edit.item === "due_date") {
                this.setState({
                    multiple_edit_error: t("Please select date")
                });
            } else {
                this.setState({
                    multiple_edit_confirm_open: true,
                    multiple_edit_confirm_text: t("Do you want to update item value to 'empty'?"),
                    multiple_edit_confirm_item: e
                });
            }
        } else {
            this.handleSubmitMultiEdit(e);
        }
    }

    handleCloseMultipleEditConfirm = () => {
        this.setState({
            multiple_edit_confirm_open: false,
            multiple_edit_confirm_text: "",
            multiple_edit_confirm_item: null
        });
    };


    handleSubmitMultiEdit = async e => {
        e.preventDefault();
        this.handleCloseMultipleEditConfirm();
        this.setState({
            loading: true
        });
        const { activeCompanyId, t } = this.props;
        const { multiple_edit, selected } = this.state;
        if (multiple_edit.item === null || multiple_edit.item === "") {
            this.setState({
                multiple_edit_error: t("Please select item to edit"),
                loading: false
            });
        } else if (selected.length === 0) {
            this.setState({
                multiple_edit_error: t("Please select invoices to edit"),
                loading: false
            });
        } else {
            // Create invoices array with only id's
            let invoices_to_edit = [];
            let i = 0;
            for (i in selected) {
                invoices_to_edit.push(selected[i].id);
            }

            await authAxios
                .post(saveMultipleEditInvoicesURL(activeCompanyId), {
                    invoices: invoices_to_edit,
                    item: multiple_edit.item,
                    value: multiple_edit.value === null ? "" : multiple_edit.value
                })
                .then(res => {
                    this.handleCloseMultiEditModal();
                    this.setState({
                        refresh: true,
                        select_all: false,
                        selected: []
                    });
                })
                .catch(err => {
                    this.setState({
                        loading: false,
                        success_mgs: null,
                        multiple_edit_error: err.response.data
                    });
                });
        }
    };

    render() {
        const { error, loading, invoices, invoices_status, modal_open, active_invoice, confirm_open, confirm_text,
            confirm_action, confirm_param, statistics, selected, search, search_end_date, search_start_date,
            search_start_due_date, search_end_due_date, search_status, search_status_options,
            create_abnormal_payment, active_abnormal_payment, abnormal_payment_modal_open, success_mgs,
            net_invoice_response, send_status_modal_open, activeIndexSearch, activeIndexActions, activeIndexInvoiceActions, activeIndexModalActions, activeIndexModalDetails, activeIndexModalAbnormal, activeIndexModalComment,
            create_invoice_comment, active_invoice_comment, invoice_comment_modal_open, active_customer_invoicing_details,
            column, direction, multiple_edit_modal_open, multiple_edit, multiple_edit_error } = this.state;
        const { isAuthenticated, t } = this.props;
        if (!isAuthenticated) {
            return <Redirect to="/login" />;
        }
        if (invoices_status !== this.props.match.params.status) {
            if (this.props.match.params.status === "P" || this.props.match.params.status === "all") {
                let init_start_date = new Date();
                if (this.props.match.params.status === "P") {
                    init_start_date = init_start_date.setMonth(init_start_date.getMonth() - 6);
                    this.setState({ search_status: null });
                } else if (this.props.match.params.status === "all") {
                    init_start_date = init_start_date.setMonth(init_start_date.getMonth() - 12);
                    this.handleSearchStatusOptions();
                }
                this.handleStartDateChange(new Date(init_start_date));
                this.handleEndDateChange(new Date());
                this.setState({ selected_dates: true, invoices_status: this.props.match.params.status, invoices: null });
            } else {
                this.setState({
                    search_start_date: null,
                    search_end_date: null,
                    selected_dates: false,
                    search_status: null
                });
                this.handleFetchOpenInvoices(this.props.match.params.status);
            }
            // Empty selected if status changes
            this.setState({
                selected: [],
                select_all: false,
                activeIndexActions: 0,
                activeIndexInvoiceActions: 0,
                activeIndexSearch: 0
            });
        }

        if (this.state.refresh) {
            this.handleFetchOpenInvoices(this.props.match.params.status);
            this.setState({
                refresh: false
            });
        }

        if (this.state.fetchUpdatedInvoice) {
            if (active_invoice) {
                this.handleUpdateActiveInvoiceDetails();
            }
            this.handleFetchOpenInvoices(invoices_status);
            this.setState({ selected: [], select_all: false, fetchUpdatedInvoice: false });
        }
        const is_desktop = getWidth() > Responsive.onlyMobile.maxWidth ? true : false;
        // console.log(this.state);

        let filter_invoices = [];

        // Filter data for search
        if (invoices !== null) {

            filter_invoices = invoices.filter((invoice) => {
                return invoice.name.toLowerCase().indexOf(search.toLocaleLowerCase()) !== -1 ||
                    // invoice.customer.name.toLowerCase().indexOf(search.toLocaleLowerCase()) !== -1 ||
                    invoice.customer_name.toLowerCase().indexOf(search.toLocaleLowerCase()) !== -1 ||
                    invoice.reference_number.toString().toLowerCase().indexOf(search.toLocaleLowerCase()) !== -1 ||
                    invoice.number.toString().toLowerCase().indexOf(search.toLocaleLowerCase()) !== -1;

            })
            if (search_start_date !== null) {
                filter_invoices = filter_invoices.filter((invoice) => {
                    return (new Date(invoice.date) >= new Date(search_start_date))
                })
            }
            if (search_end_date !== null) {
                filter_invoices = filter_invoices.filter((invoice) => {
                    return (new Date(invoice.date) <= new Date(search_end_date))
                })
            }
            if (search_start_due_date !== null) {
                filter_invoices = filter_invoices.filter((invoice) => {
                    return (new Date(invoice.due_date) >= new Date(search_start_due_date))
                })
            }
            if (search_end_due_date !== null) {
                filter_invoices = filter_invoices.filter((invoice) => {
                    return (new Date(invoice.due_date) <= new Date(search_end_due_date))
                })
            }
            if (search_status !== null) {
                if (search_status.length > 0) {
                    filter_invoices = filter_invoices.filter((invoice) => {
                        let i = 0;
                        let return_value = false;
                        for (i in search_status) {
                            if (invoice.status === search_status[i]) {
                                return_value = true;
                            }
                        }
                        return return_value;
                    })
                }
            }
        }

        return (
            <React.Fragment>
                {filter_invoices && (
                    <Shell statistics={statistics} loading={loading}>
                        {is_desktop && (
                            <Button.Group floated="right" basic size='small'>
                                <Button icon="download" content="CSV" onClick={() => this.handleExportToCSV(filter_invoices)} />
                            </Button.Group>
                        )}
                        <Header as="h4">
                            {statistics && (t("Open invoices") + `${statistics.totals.amount} (${statistics.totals.net.toFixed(2)}€) ${statistics.totals.gross.toFixed(2)}€`)} <br />

                            {invoices_status === "C" && (t("Created stats") + `: ${filter_invoices.length} ${t("pcs")} (${this.getTotalNetPriceAllInvoices(filter_invoices).toFixed(2)} €) ${this.getTotalGrossPriceAllInvoices(filter_invoices).toFixed(2)} €`)}
                            {invoices_status === "A" && (t("Approved stats") + `: ${filter_invoices.length} ${t("pcs")} (${this.getTotalNetPriceAllInvoices(filter_invoices).toFixed(2)} €) ${this.getTotalGrossPriceAllInvoices(filter_invoices).toFixed(2)} €`)}
                            {invoices_status === "S" && (t("Sent stats") + `: ${filter_invoices.length} ${t("pcs")} (${this.getTotalNetPriceAllInvoices(filter_invoices).toFixed(2)} €) ${this.getTotalGrossPriceAllInvoices(filter_invoices).toFixed(2)} €`)}
                            {invoices_status === "P" && (t("Paid stats") + `: ${filter_invoices.length} ${t("pcs")} (${this.getTotalNetPriceAllInvoices(filter_invoices).toFixed(2)} €) ${this.getTotalGrossPriceAllInvoices(filter_invoices).toFixed(2)} €`)}
                            {invoices_status === "O" && (t("Overdue stats") + `: ${filter_invoices.length} ${t("pcs")} (${this.getTotalNetPriceAllInvoices(filter_invoices).toFixed(2)} €) ${this.getTotalGrossPriceAllInvoices(filter_invoices).toFixed(2)} €`)}
                            {invoices_status === "B" && (t("Abnormal stats") + `: ${filter_invoices.length} ${t("pcs")} (${this.getTotalNetPriceAllInvoices(filter_invoices).toFixed(2)} €) ${this.getTotalGrossPriceAllInvoices(filter_invoices).toFixed(2)} €`)}
                            {invoices_status === "all" && (t("All stats") + `: ${filter_invoices.length} ${t("pcs")} (${this.getTotalNetPriceAllInvoices(filter_invoices).toFixed(2)} €) ${this.getTotalGrossPriceAllInvoices(filter_invoices).toFixed(2)} €`)}
                        </Header>
                        {is_desktop ? (
                            <React.Fragment>
                                <Grid columns="2">
                                    <Grid.Row>
                                        <Grid.Column width={9}>
                                            {invoices_status === "C" && (
                                                <React.Fragment>
                                                    <Popup content={t("Approve selected invoices")} trigger={
                                                        <Button type="button" size='tiny' positive icon='thumbs up outline' content={t("Approve")} onClick={() => this.updateInvoiceStatus("Selected", "A")} />
                                                    } />
                                                    <Popup content={t("Approve and Send selected invoices")} trigger={
                                                        <Button type="button" size='tiny' primary icon='envelope outline' content={t("+Send")}
                                                            onClick={() => this.openConfirm("multiple_emails", "", t("Do you want to send invoices?", { amount: selected.length }))} />
                                                    } />
                                                    {/* <Popup content={t("Approve and Send selected invoices")} trigger={
                                                        <Button type="button" size='tiny' primary icon='envelope outline' content={t("+Send queue")}
                                                            onClick={() => this.openConfirm("multiple_send_queue", "", t("Do you want to send invoices?", { amount: selected.length }))} />
                                                    } /> */}
                                                    <Popup content={t("Mark selected as Sent")} trigger={
                                                        <Button type="button" size='tiny' positive icon='check circle outline' content={t("Mark as Sent")} onClick={() => this.updateInvoiceStatus("Selected", "S")} />
                                                    } />
                                                    {/* <Popup textAlign='center' content={t("Delete selected invoices")} trigger={
                                                        <Button type="button" size='tiny' negative icon='delete' content={t("Delete selected")} onClick={() => this.openConfirm("Delete", "Selected", t("Are you sure to delete selected invoices?"))} />
                                                    } /> */}
                                                </React.Fragment>
                                            )}
                                            {invoices_status === "A" && (
                                                <React.Fragment>
                                                    <Popup content={t("Send selected invoices")} trigger={
                                                        <Button type="button" size='tiny' primary icon='envelope outline' content={t("+Send")}
                                                            onClick={() => this.openConfirm("multiple_emails", "", t("Do you want to send invoices?", { amount: selected.length }))} />
                                                    } />
                                                    <Popup content={t("Mark selected as Sent")} trigger={
                                                        <Button type="button" size='tiny' positive icon='check circle outline' content={t("Mark as Sent")} onClick={() => this.updateInvoiceStatus("Selected", "S")} />
                                                    } />
                                                    <Popup content={t("Return selected to Created status")} trigger={
                                                        <Button type="button" size='tiny' color='blue' icon='arrow left' content={t("Return selected")} onClick={() => this.updateInvoiceStatus("Selected", "C")} />
                                                    } />
                                                </React.Fragment>
                                            )}
                                            {invoices_status === "S" && (
                                                <React.Fragment>
                                                    <Popup content={t("Mark selected as Paid")} trigger={
                                                        <Button type="button" size='tiny' positive icon='checkmark' content={t("Mark as Paid")} onClick={() => this.updateInvoiceStatus("Selected", "P")} />
                                                    } />
                                                    <Popup content={t("Return selected to Created status")} trigger={
                                                        <Button type="button" size='tiny' color='blue' icon='arrow left' content={t("Return selected")} onClick={() => this.updateInvoiceStatus("Selected", "C")} />
                                                    } />
                                                </React.Fragment>
                                            )}
                                            {invoices_status === "O" && (
                                                <React.Fragment>
                                                    <Popup content={t("Mark selected as Paid")} trigger={
                                                        <Button type="button" size='tiny' positive icon='checkmark' content={t("Mark as Paid")} onClick={() => this.updateInvoiceStatus("Selected", "P")} />
                                                    } />
                                                    <Popup content={t("Return selected to Created status")} trigger={
                                                        <Button type="button" size='tiny' color='blue' icon='arrow left' content={t("Return selected")} onClick={() => this.updateInvoiceStatus("Selected", "C")} />
                                                    } />
                                                </React.Fragment>
                                            )}
                                            {(invoices_status === "B") && (
                                                <React.Fragment>
                                                    <Popup content={t("Mark selected as Paid")} trigger={
                                                        <Button type="button" size='tiny' positive icon='checkmark' content={t("Mark as Paid")} onClick={() => this.updateInvoiceStatus("Selected", "P")} />
                                                    } />
                                                    <Popup content={t("Return selected to Created status")} trigger={
                                                        <Button type="button" size='tiny' color='blue' icon='arrow left' content={t("Return selected")} onClick={() => this.updateInvoiceStatus("Selected", "C")} />
                                                    } />
                                                </React.Fragment>
                                            )}
                                            {invoices_status === "Q" && (
                                                <React.Fragment>
                                                    <Popup content={t("Mark selected as Sent")} trigger={
                                                        <Button type="button" size='tiny' positive icon='check circle outline' content={t("Mark as Sent")} onClick={() => this.updateInvoiceStatus("Selected", "S")} />
                                                    } />
                                                    <Popup content={t("Return selected to Created status")} trigger={
                                                        <Button type="button" size='tiny' color='blue' icon='arrow left' content={t("Return selected")} onClick={() => this.updateInvoiceStatus("Selected", "C")} />
                                                    } />
                                                </React.Fragment>
                                            )}

                                            {(invoices_status === "all" || invoices_status === "P") && (
                                                <React.Fragment>
                                                    <SemanticDatepicker
                                                        firstDayOfWeek="1"
                                                        size="mini"
                                                        disabled={loading}
                                                        label={t("Booking date") + ": "}
                                                        locale={fiLocale}
                                                        placeholder={this.state.search_start_date !== null ? (new Date(this.state.search_start_date).toLocaleString('fi-FI', { dateStyle: 'short' })) : ('DD.MM.YYYY')}
                                                        onDateChange={value => this.handleStartDateChange(value)}
                                                        format='DD.MM.YYYY'
                                                        keepOpenOnClear={true}
                                                        datePickerOnly={true}
                                                        showOutsideDays={true}
                                                    />
                                                    <SemanticDatepicker
                                                        firstDayOfWeek="1"
                                                        size="mini"
                                                        disabled={loading}
                                                        label="->"
                                                        locale={fiLocale}
                                                        placeholder={this.state.search_end_date !== null ? (new Date(this.state.search_end_date).toLocaleString('fi-FI', { dateStyle: 'short' })) : ('DD.MM.YYYY')}
                                                        onDateChange={value => this.handleEndDateChange(value)}
                                                        format='DD.MM.YYYY'
                                                        keepOpenOnClear={true}
                                                        datePickerOnly={true}
                                                    />
                                                </React.Fragment>
                                            )}

                                        </Grid.Column>
                                        <Grid.Column floated="right" width={5}>
                                            {/* {invoices_status === "P" && ( */}
                                            <Input
                                                size="mini"
                                                icon='search'
                                                value={search}
                                                name="search"
                                                onChange={this.handleChange} />
                                            {/* )} */}
                                            {(invoices_status === "all" || invoices_status === "P") ? (
                                                <Button type="button" positive size='tiny' floated="right" content={t("Fetch")} onClick={() => this.setState({refresh: true})} />
                                            ) : (
                                                <Button type="button" positive size='tiny' floated="right" content={t("+ Invoice")} onClick={() => this.props.history.push("/invoice/invoice-details/create/0/")} />
                                            )}
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                                <Grid columns="2">
                                    <Grid.Row>
                                        {(invoices_status !== "all" && invoices_status !== "P") ? (
                                            <React.Fragment>
                                                <Grid.Column>
                                                    <SemanticDatepicker
                                                        firstDayOfWeek="1"
                                                        size="mini"
                                                        disabled={loading}
                                                        label={t("Booking date") + ": "}
                                                        locale={fiLocale}
                                                        placeholder={this.state.search_start_date !== null ? (new Date(this.state.search_start_date).toLocaleString('fi-FI', { dateStyle: 'short' })) : ('DD.MM.YYYY')}
                                                        onDateChange={value => this.handleStartDateChange(value)}
                                                        format='DD.MM.YYYY'
                                                        keepOpenOnClear={true}
                                                        datePickerOnly={true}
                                                        showOutsideDays={true}
                                                    />
                                                    <SemanticDatepicker
                                                        firstDayOfWeek="1"
                                                        size="mini"
                                                        disabled={loading}
                                                        label="->"
                                                        locale={fiLocale}
                                                        placeholder={this.state.search_end_date !== null ? (new Date(this.state.search_end_date).toLocaleString('fi-FI', { dateStyle: 'short' })) : ('DD.MM.YYYY')}
                                                        onDateChange={value => this.handleEndDateChange(value)}
                                                        format='DD.MM.YYYY'
                                                        keepOpenOnClear={true}
                                                        datePickerOnly={true}
                                                    />
                                                </Grid.Column>
                                                <Grid.Column>
                                                    <Button type="button" positive size='tiny' floated="right" content={t("+ Invoice (multiple customers)")} onClick={() => this.props.history.push("/invoice/invoice-details-multiple-customers/create/0/")} />
                                                </Grid.Column>
                                            </React.Fragment>
                                        ) : (
                                            <React.Fragment>
                                                <Grid.Column>
                                                    <SemanticDatepicker
                                                        firstDayOfWeek="1"
                                                        size="mini"
                                                        disabled={loading}
                                                        label={t("Due date") + ": "}
                                                        locale={fiLocale}
                                                        placeholder={this.state.search_start_due_date !== null ? (new Date(this.state.search_start_due_date).toLocaleString('fi-FI', { dateStyle: 'short' })) : ('DD.MM.YYYY')}
                                                        onDateChange={value => this.handleStartDueDateChange(value)}
                                                        format='DD.MM.YYYY'
                                                        keepOpenOnClear={true}
                                                        datePickerOnly={true}
                                                        showOutsideDays={true}
                                                    />
                                                    <SemanticDatepicker
                                                        firstDayOfWeek="1"
                                                        size="mini"
                                                        disabled={loading}
                                                        label="->"
                                                        locale={fiLocale}
                                                        placeholder={this.state.search_end_due_date !== null ? (new Date(this.state.search_end_due_date).toLocaleString('fi-FI', { dateStyle: 'short' })) : ('DD.MM.YYYY')}
                                                        onDateChange={value => this.handleEndDueDateChange(value)}
                                                        format='DD.MM.YYYY'
                                                        keepOpenOnClear={true}
                                                        datePickerOnly={true}
                                                        showOutsideDays={true}
                                                    />
                                                </Grid.Column>
                                                {invoices_status === "all" && (
                                                    <Grid.Column floated="right" textAlign="right">
                                                        <label>{t("Status")}</label>
                                                        <Select
                                                            disabled={loading}
                                                            search
                                                            multiple
                                                            name='search_status'
                                                            options={search_status_options}
                                                            placeholder={t("Select status")}
                                                            onChange={this.handleSelectChange}
                                                            value={search_status}
                                                        />
                                                    </Grid.Column>
                                                )}
                                            </React.Fragment>
                                        )}
                                    </Grid.Row>
                                </Grid>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <Button fluid type="button" positive size='medium' content={t("+ Invoice")} onClick={() => this.props.history.push("/invoice/invoice-details/create/0/")} />
                                <br />
                                <hr />
                                <Accordion>
                                    <Accordion.Title
                                        active={activeIndexSearch === 1}
                                        index={1}
                                        onClick={this.handleClick}>
                                        <Icon color="blue" name='dropdown' />
                                        <font color="light blue"> <b>{t("Search options")}</b></font>
                                    </Accordion.Title>
                                    <Accordion.Content active={activeIndexSearch === 1}>
                                        <label>{t("Invoice date") + ":"}</label><br />
                                        <SemanticDatepicker
                                            firstDayOfWeek="1"
                                            disabled={loading}
                                            locale={fiLocale}
                                            placeholder={this.state.search_start_date !== null ? (new Date(this.state.search_start_date).toLocaleString('fi-FI', { dateStyle: 'short' })) : ('DD.MM.YYYY')}
                                            onDateChange={value => this.handleStartDateChange(value)}
                                            format='DD.MM.YYYY'
                                            keepOpenOnClear={true}
                                            datePickerOnly={true}
                                            showOutsideDays={true}
                                        />
                                        <br />
                                        <SemanticDatepicker
                                            firstDayOfWeek="1"
                                            disabled={loading}
                                            locale={fiLocale}
                                            placeholder={this.state.search_end_date !== null ? (new Date(this.state.search_end_date).toLocaleString('fi-FI', { dateStyle: 'short' })) : ('DD.MM.YYYY')}
                                            onDateChange={value => this.handleEndDateChange(value)}
                                            format='DD.MM.YYYY'
                                            keepOpenOnClear={true}
                                            datePickerOnly={true}
                                        />
                                        <br />
                                        {(invoices_status === "all" || invoices_status === "P") && (
                                            <React.Fragment>
                                                <label>{t("Due date") + ":"}</label><br />
                                                <SemanticDatepicker
                                                    firstDayOfWeek="1"
                                                    disabled={loading}
                                                    locale={fiLocale}
                                                    placeholder={this.state.search_start_due_date !== null ? (new Date(this.state.search_start_due_date).toLocaleString('fi-FI', { dateStyle: 'short' })) : ('DD.MM.YYYY')}
                                                    onDateChange={value => this.handleStartDueDateChange(value)}
                                                    format='DD.MM.YYYY'
                                                    keepOpenOnClear={true}
                                                    datePickerOnly={true}
                                                    showOutsideDays={true}
                                                />
                                                <br />
                                                <SemanticDatepicker
                                                    firstDayOfWeek="1"
                                                    disabled={loading}
                                                    locale={fiLocale}
                                                    placeholder={this.state.search_end_due_date !== null ? (new Date(this.state.search_end_due_date).toLocaleString('fi-FI', { dateStyle: 'short' })) : ('DD.MM.YYYY')}
                                                    onDateChange={value => this.handleEndDueDateChange(value)}
                                                    format='DD.MM.YYYY'
                                                    keepOpenOnClear={true}
                                                    datePickerOnly={true}
                                                    showOutsideDays={true}
                                                />
                                                <br />
                                                {invoices_status === "all" && (
                                                    <React.Fragment>
                                                        <label>{t("Status")}</label><br />
                                                        <Select
                                                            fluid
                                                            disabled={loading}
                                                            search
                                                            multiple
                                                            name='search_status'
                                                            options={search_status_options}
                                                            placeholder={t("Select status")}
                                                            onChange={this.handleSelectChange}
                                                            value={search_status}
                                                        />
                                                        <br />
                                                    </React.Fragment>
                                                )}
                                            </React.Fragment>
                                        )}
                                        <Input
                                            fluid
                                            icon='search'
                                            value={search}
                                            name="search"
                                            onChange={this.handleChange} />
                                    </Accordion.Content>
                                </Accordion>
                                <hr />
                                <Accordion>
                                    <Accordion.Title
                                        active={activeIndexActions === 1}
                                        index={1}
                                        onClick={this.handleClick2}>
                                        <Icon color="blue" name='dropdown' />
                                        <font color="light blue"> <b>{t("Actions to selected invoices")}</b></font>
                                    </Accordion.Title>
                                    <Accordion.Content active={activeIndexActions === 1}>
                                        {invoices_status === "C" && (
                                            <React.Fragment>
                                                <Button fluid type="button" size='medium' positive icon='thumbs up outline' content={t("Approve")} onClick={() => this.updateInvoiceStatus("Selected", "A")} /><br />
                                                <Button fluid type="button" size='medium' primary icon='envelope outline' content={t("+Send")} onClick={() => this.openConfirm("multiple_emails", "", t("Do you want to send invoices?", { amount: selected.length }))} /><br />
                                                <Button fluid type="button" size='medium' positive icon='check circle outline' content={t("Mark as Sent")} onClick={() => this.updateInvoiceStatus("Selected", "S")} /><br />
                                                <Button fluid type="button" size='medium' negative icon='delete' content={t("Delete selected")} onClick={() => this.openConfirm("Delete", "Selected", t("Are you sure to delete selected invoices?"))} />
                                            </React.Fragment>
                                        )}
                                        {invoices_status === "A" && (
                                            <React.Fragment>
                                                <Button fluid type="button" size='medium' primary icon='envelope outline' content={t("+Send")} onClick={() => this.openConfirm("multiple_emails", "", t("Do you want to send invoices?", { amount: selected.length }))} /><br />
                                                <Button fluid type="button" size='medium' positive icon='check circle outline' content={t("Mark as Sent")} onClick={() => this.updateInvoiceStatus("Selected", "S")} /><br />
                                                <Button fluid type="button" size='medium' color='blue' icon='arrow left' content={t("Return")} onClick={() => this.updateInvoiceStatus("Selected", "C")} />
                                            </React.Fragment>
                                        )}
                                        {invoices_status === "S" && (
                                            <React.Fragment>
                                                <Button fluid type="button" size='medium' positive icon='checkmark' content={t("Mark as Paid")} onClick={() => this.updateInvoiceStatus("Selected", "P")} /><br />
                                                <Button fluid type="button" size='medium' color='blue' icon='arrow left' content={t("Return")} onClick={() => this.updateInvoiceStatus("Selected", "C")} />
                                            </React.Fragment>
                                        )}
                                        {invoices_status === "Q" && (
                                            <React.Fragment>
                                                <Button fluid type="button" size='medium' positive icon='check circle outline' content={t("Mark as Sent")} onClick={() => this.updateInvoiceStatus("Selected", "S")} /><br />
                                                <Button fluid type="button" size='medium' color='blue' icon='arrow left' content={t("Return")} onClick={() => this.updateInvoiceStatus("Selected", "C")} />
                                            </React.Fragment>
                                        )}
                                        {invoices_status === "O" && (
                                            <React.Fragment>
                                                <Button fluid type="button" size='medium' positive icon='checkmark' content={t("Mark as Paid")} onClick={() => this.updateInvoiceStatus("Selected", "P")} /><br />
                                                <Button fluid type="button" size='medium' color='blue' icon='arrow left' content={t("Return")} onClick={() => this.updateInvoiceStatus("Selected", "C")} />
                                            </React.Fragment>
                                        )}
                                        {invoices_status === "B" && (
                                            <React.Fragment>
                                                <Button fluid type="button" size='medium' positive icon='checkmark' content={t("Mark as Paid")} onClick={() => this.updateInvoiceStatus("Selected", "P")} /><br />
                                                <Button fluid type="button" size='medium' color='blue' icon='arrow left' content={t("Return")} onClick={() => this.updateInvoiceStatus("Selected", "C")} />
                                            </React.Fragment>
                                        )}
                                        {invoices_status === "Q" && (
                                            <React.Fragment>
                                                <Button fluid type="button" size='medium' color='blue' icon='arrow left' content={t("Return")} onClick={() => this.updateInvoiceStatus("Selected", "C")} />
                                            </React.Fragment>
                                        )}

                                    </Accordion.Content>
                                </Accordion>
                                <hr />
                            </React.Fragment>
                        )}
                        <Confirm
                            content={confirm_text}
                            open={confirm_open}
                            onCancel={() => this.closeConfirm()}
                            onConfirm={() => this.handleConfirm(confirm_action, confirm_param)}
                        />
                        <Confirm
                            content={this.state.multiple_edit_confirm_text}
                            open={this.state.multiple_edit_confirm_open}
                            onCancel={() => this.handleCloseMultipleEditConfirm()}
                            onConfirm={() => this.handleSubmitMultiEdit(this.state.multiple_edit_confirm_item)}
                        />
                        {error && (
                            <Message
                                error
                                header={t("There was an error")}
                                content={error.response ? (error.response.data) : (error)}
                            />
                        )}
                        {loading && (
                            <Segment>
                                <Dimmer active inverted>
                                    <Loader inverted>Loading</Loader>
                                </Dimmer>
                                <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
                            </Segment>
                        )}
                        {filter_invoices && (

                            <Table striped selectable sortable celled >
                                <Table.Header>
                                    <Table.Row>
                                        {(this.props.match.params.status !== "all") && (
                                            <Table.HeaderCell icon='check circle outline' collapsing onClick={() => this.handleSelectAll(filter_invoices)} content={!is_desktop ? (t("Select all")) : ("")} />
                                        )}
                                        {(is_desktop && this.props.match.params.status === "all") && (
                                            <Table.HeaderCell
                                                sorted={column === 'status' ? direction : null}
                                                onClick={this.handleSort('status')}>
                                                {t("Status")}
                                            </Table.HeaderCell>
                                        )}
                                        {is_desktop && (
                                            <React.Fragment>
                                                {((invoices_status !== "all") && selected.length > 0) ? (
                                                    <Button.Group style={{ paddingTop: "5px", paddingLeft: "5px" }}>
                                                        <Button type="button" positive content={t("Edit selected")} icon="edit" onClick={() => this.handleOpenMultiEditModal(invoices_status)} />
                                                        {invoices_status === "C" && (
                                                            <Popup textAlign='center' content={t("Delete selected invoices")} trigger={
                                                                <Button type="button" size='tiny' negative icon='delete' content={t("Delete selected")} onClick={() => this.openConfirm("Delete", "Selected", t("Are you sure to delete selected invoices?"))} />
                                                            } />
                                                        )}
                                                    </Button.Group>
                                                ) : (
                                                    <>
                                                        <Table.HeaderCell
                                                            sorted={column === 'customer_name' ? direction : null}
                                                            onClick={this.handleSort('customer_name')}>
                                                            {t("Name")}
                                                        </Table.HeaderCell>
                                                        <Table.HeaderCell
                                                            sorted={column === 'date' ? direction : null}
                                                            onClick={this.handleSort('date')}>
                                                            {t("Invoice date")}
                                                        </Table.HeaderCell>
                                                        <Table.HeaderCell
                                                            sorted={column === 'due_date' ? direction : null}
                                                            onClick={this.handleSort('due_date')}>
                                                            {t("Due date")}
                                                        </Table.HeaderCell>
                                                        <Table.HeaderCell
                                                            sorted={column === 'net' ? direction : null}
                                                            onClick={this.handleSort('net')}>
                                                            {t("Net €")}
                                                        </Table.HeaderCell>
                                                        <Table.HeaderCell
                                                            sorted={column === 'gross' ? direction : null}
                                                            onClick={this.handleSort('gross')}>
                                                            {t("Gross €")}
                                                        </Table.HeaderCell>
                                                        <Table.HeaderCell textAlign="center">{t("Actions")}</Table.HeaderCell>
                                                    </>
                                                )}
                                            </React.Fragment>
                                        )}
                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                    {filter_invoices.map(invoice => {
                                        return (
                                            <Table.Row key={invoice.id}>
                                                {(this.props.match.params.status !== "all") && (
                                                    <Table.Cell collapsing>
                                                        <Checkbox
                                                            value={invoice}
                                                            checked={selected.includes(invoice) === true}
                                                            onChange={this.handleCheckboxChange} />
                                                    </Table.Cell>
                                                )}
                                                {this.props.match.params.status === "all" && (
                                                    <Table.Cell collapsing>
                                                        {invoice.status === "C" && (t("Created"))}
                                                        {invoice.status === "A" && (<strong>{t("Approved")}</strong>)}
                                                        {invoice.status === "S" && (<font color="blue"><strong>{t("Sent")}</strong></font>)}
                                                        {invoice.status === "Q" && (<font color="blue"><strong>{t("Sending")}</strong></font>)}
                                                        {invoice.status === "P" && (<font color="green"><strong>{t("Paid")}</strong></font>)}
                                                        {invoice.status === "O" && (<font color="red"><strong>{t("Overdue")}</strong></font>)}
                                                        {invoice.status === "B" && (<font color="red"><strong>{t("Abnormal")}</strong></font>)}
                                                    </Table.Cell>
                                                )}
                                                <Table.Cell onClick={() => this.openInvoiceDetails(invoice)}>
                                                    {/* <b>{invoice.customer.name}</b> */}
                                                    <b>{invoice.customer_name}</b>
                                                    <Header sub floated="right">({invoice.number})</Header><br />
                                                    <span>{invoice.name}</span>
                                                    {invoice.contract.number !== null && (

                                                        <Link to={`/contract/contract-details/${invoice.contract.id}/`}>
                                                            <Header sub color="blue" as="h6" floated="right">
                                                                <Popup content={`${t("Contract")} ${invoice.contract.name} (${invoice.contract.number})`} trigger={
                                                                    <Icon fitted link name='sync alternate' size='tiny' />
                                                                } />
                                                            </Header>
                                                        </Link>
                                                    )}
                                                    {invoice.abnormal_total > 0 && (
                                                        // <Header sub color={parseFloat((this.handleGetAbnormalSum(invoice) - getTotalGrossPrice(invoice.invoiceitems)).toFixed(2)) === parseFloat(0) ? "blue" : "red"} as="h6" floated="right">
                                                        //     <Popup content={`${t("Abnormal payments. Current balance")} ${(this.handleGetAbnormalSum(invoice) - getTotalGrossPrice(invoice.invoiceitems)).toFixed(2)} €`} trigger={
                                                        //         <Icon fitted name='info' size='tiny' />
                                                        //     } />
                                                        // </Header>
                                                        <Header sub color={parseFloat((invoice.abnormal_total - invoice.gross_total).toFixed(2)) === parseFloat(0) ? "blue" : "red"} as="h6" floated="right">
                                                            <Popup content={`${t("Abnormal payments. Current balance")} ${(invoice.abnormal_total - invoice.gross_total).toFixed(2)} €`} trigger={
                                                                <Icon fitted name='info' size='tiny' />
                                                            } />
                                                        </Header>

                                                    )}
                                                    {/* {this.handleCheckInternalComments(invoice) && (<Popup content={t("Internal comments added")} trigger={<Header as="h6" floated="right" icon="comments outline" />} />)} */}
                                                    {/* {this.handleCheckErrorComments(invoice) && (<Popup content={t("Error comments in invoice")} trigger={<Header sub color="red" as="h6" floated="right" icon="attention" />} />)} */}
                                                    {invoice.internal_comments && (<Popup content={t("Internal comments added")} trigger={<Header as="h6" floated="right" icon="comments outline" />} />)}
                                                    {invoice.error_comments && (<Popup content={t("Error comments in invoice")} trigger={<Header sub color="red" as="h6" floated="right" icon="attention" />} />)}
                                                </Table.Cell>
                                                <Table.Cell collapsing onClick={() => this.openInvoiceDetails(invoice)}>
                                                    {!is_desktop && (<React.Fragment>{t("Invoice date")}<br /></React.Fragment>)}
                                                    {new Date(invoice.date).toLocaleString('fi-FI', { dateStyle: 'short' })}
                                                </Table.Cell>
                                                <Table.Cell collapsing onClick={() => this.openInvoiceDetails(invoice)}>
                                                    {!is_desktop && (<React.Fragment>{t("Due date")}<br /></React.Fragment>)}
                                                    {new Date(invoice.due_date).toLocaleString('fi-FI', { dateStyle: 'short' })}
                                                </Table.Cell>
                                                <Table.Cell collapsing onClick={() => this.openInvoiceDetails(invoice)}>
                                                    {!is_desktop && (<React.Fragment>{t("Net €")}<br /></React.Fragment>)}
                                                    {invoice.net_total.toFixed(2)} €
                                                </Table.Cell>
                                                <Table.Cell collapsing onClick={() => this.openInvoiceDetails(invoice)}>
                                                    {!is_desktop && (<React.Fragment>{t("Gross €")}<br /></React.Fragment>)}
                                                    {/* <strong>{getTotalGrossPrice(invoice.invoiceitems).toFixed(2)} €</strong> */}
                                                    <strong>{invoice.gross_total.toFixed(2)} €</strong>
                                                </Table.Cell>

                                                {is_desktop ? (
                                                    <React.Fragment>
                                                        {invoice.status === "C" && (
                                                            <Table.Cell collapsing>
                                                                <Popup content={t("Approve")} trigger={
                                                                    <Button type="button" attached='left' size='tiny' positive icon='thumbs up outline' /*content={t("Approve")}*/ onClick={() => this.updateInvoiceStatus(invoice, "A")} />
                                                                } />
                                                                {/* Add email approve + send button if invoicing method is "E"=Email */}
                                                                {invoice.invoicing_method === 'E' && (
                                                                    <Popup content={t("Approve and Send invoice email")} trigger={
                                                                        <Button type="button" attached='right' size='tiny' primary icon='envelope outline' /*content={t("+Send email")}*/ onClick={() => this.openConfirm("email", invoice, t("Do you want to send invoice", { name: invoice.name, customer: invoice.customer_name }))} />
                                                                    } />
                                                                )}
                                                                {/* Add net invoice approve + send button if invoicing method is "N"=Net invoice */}
                                                                {invoice.invoicing_method === 'N' && (
                                                                    <Popup content={t("Approve and Send net invoice")} trigger={
                                                                        <Button type="button" attached='right' size='tiny' color="yellow" icon='envelope outline' /*content={t("+Send email")}*/ onClick={() => this.openConfirm("email", invoice, t("Do you want to send invoice", { name: invoice.name, customer: invoice.customer_name }))} />
                                                                    } />
                                                                )}
                                                                {/* Add get PDF button if invoicing method is "P"=Paper */}
                                                                {invoice.invoicing_method === 'P' && (
                                                                    <Popup content={t("Download PDF invoice")} trigger={
                                                                        <Button type="button" attached='right' size='tiny' color="twitter" icon='file pdf outline' /*content={t("Get PDF")}*/ onClick={() => this.handleFetchInvoicePdf(invoice.id)} />
                                                                    } />
                                                                )}
                                                                <Popup content={t("Mark as Sent")} trigger={
                                                                    <Button type="button" attached='right' size='tiny' positive icon='check circle outline' /*content={t("Mark as Sent")}*/ onClick={() => this.updateInvoiceStatus(invoice, "S")} />
                                                                } />
                                                            </Table.Cell>
                                                        )}
                                                        {invoice.status === "A" && (
                                                            <Table.Cell collapsing>
                                                                {/* Add email approve + send button if invoicing method is "E"=Email */}
                                                                {invoice.invoicing_method === 'E' && (
                                                                    <Popup content={t("Send invoice email")} trigger={
                                                                        <Button type="button" attached='right' size='tiny' primary icon='envelope outline' /*content={t("+Send email")}*/ onClick={() => this.openConfirm("email", invoice, t("Do you want to send invoice", { name: invoice.name, customer: invoice.customer_name }))} />
                                                                    } />
                                                                )}
                                                                {/* Add net invoice approve + send button if invoicing method is "N"=Net invoice */}
                                                                {invoice.invoicing_method === 'N' && (
                                                                    <Popup content={t("Send net invoice")} trigger={
                                                                        <Button type="button" attached='right' size='tiny' color="yellow" icon='envelope outline' /*content={t("+Send email")}*/ onClick={() => this.openConfirm("email", invoice, t("Do you want to send invoice", { name: invoice.name, customer: invoice.customer_name }))} />
                                                                    } />
                                                                )}
                                                                {/* Add get PDF button if invoicing method is "P"=Paper */}
                                                                {invoice.invoicing_method === 'P' && (
                                                                    <Popup content={t("Download PDF invoice")} trigger={
                                                                        <Button type="button" attached='right' size='tiny' color="twitter" icon='file pdf outline' /*content={t("Get PDF")}*/ onClick={() => this.handleFetchInvoicePdf(invoice.id)} />
                                                                    } />
                                                                )}
                                                                <Popup content={t("Mark as Sent")} trigger={
                                                                    <Button type="button" attached='right' size='tiny' positive icon='check circle outline' /*content={t("Mark as Sent")}*/ onClick={() => this.updateInvoiceStatus(invoice, "S")} />
                                                                } />
                                                                <Popup content={t("Return")} trigger={
                                                                    <Button type="button" size='tiny' primary icon='arrow left' /*content={t("Return")}*/ onClick={() => this.updateInvoiceStatus(invoice, "C")} />
                                                                } />
                                                            </Table.Cell>
                                                        )}
                                                        {invoice.status === "S" && (
                                                            <Table.Cell collapsing>
                                                                <Popup content={t("Mark as Paid")} trigger={
                                                                    <Button type="button" size='tiny' positive icon='checkmark' /*content={t("Paid")}*/ onClick={() => this.updateInvoiceStatus(invoice, "P")} />
                                                                } />
                                                                <Popup content={t("Return")} trigger={
                                                                    <Button type="button" size='tiny' primary icon='arrow left' /*content={t("Return")}*/ onClick={() => this.updateInvoiceStatus(invoice, "C")} />
                                                                } />
                                                            </Table.Cell>
                                                        )}
                                                        {invoice.status === "Q" && (
                                                            <Table.Cell collapsing>
                                                                <Popup content={t("Mark as Sent")} trigger={
                                                                    <Button type="button" attached='right' size='tiny' positive icon='check circle outline' /*content={t("Mark as Sent")}*/ onClick={() => this.updateInvoiceStatus(invoice, "S")} />
                                                                } />
                                                                <Popup content={t("Return")} trigger={
                                                                    <Button type="button" size='tiny' primary icon='arrow left' /*content={t("Return")}*/ onClick={() => this.updateInvoiceStatus(invoice, "C")} />
                                                                } />
                                                            </Table.Cell>
                                                        )}
                                                        {invoice.status === "P" && (
                                                            <Table.Cell collapsing>
                                                                <Popup content={t("Return")} trigger={
                                                                    <Button type="button" size='tiny' primary icon='arrow left' /*content={t("Return")}*/ onClick={() => this.updateInvoiceStatus(invoice, "C")} />
                                                                } />
                                                                {/* <Button type="button" disabled size='tiny' positive icon='checkmark' /*content={t("Paid")} /> */}
                                                            </Table.Cell>
                                                        )}
                                                        {invoice.status === "O" && (
                                                            <Table.Cell collapsing>
                                                                <Popup content={t("Mark as Paid")} trigger={
                                                                    <Button type="button" size='tiny' positive icon='checkmark' /*content={t("Paid")}*/ onClick={() => this.updateInvoiceStatus(invoice, "P")} />
                                                                } />
                                                                <Popup content={t("Return")} trigger={
                                                                    <Button type="button" size='tiny' primary icon='arrow left' /*content={t("Return")}*/ onClick={() => this.updateInvoiceStatus(invoice, "C")} />
                                                                } />
                                                            </Table.Cell>
                                                        )}
                                                        {invoice.status === "B" && (
                                                            <Table.Cell collapsing>
                                                                {/* {parseFloat(this.handleGetAbnormalSum(invoice) - getTotalGrossPrice(invoice.invoiceitems)).toFixed(2) == 0.00 && ( */}
                                                                {/* {parseFloat(this.handleGetAbnormalSum(invoice) - invoice.gross_total).toFixed(2) == 0.00 && (
                                                                    <Popup content={t("Mark as Paid")} trigger={
                                                                        <Button type="button" size='tiny' positive icon='checkmark' onClick={() => this.updateInvoiceStatus(invoice, "P")} />} />
                                                                )} */}
                                                                <Popup content={t("Mark as Paid")} trigger={
                                                                        <Button type="button" size='tiny' positive icon='checkmark' onClick={() => this.updateInvoiceStatus(invoice, "P")} />} />
                                                                <Popup content={t("Return")} trigger={
                                                                    <Button type="button" size='tiny' primary icon='arrow left' /*content={t("Return")}*/ onClick={() => this.updateInvoiceStatus(invoice, "C")} />
                                                                } />
                                                            </Table.Cell>
                                                        )}
                                                    </React.Fragment>
                                                ) : (
                                                    <React.Fragment>
                                                        <Accordion>
                                                            <Accordion.Title
                                                                active={activeIndexInvoiceActions === invoice.id}
                                                                index={invoice.id}
                                                                onClick={this.handleClickInvoiceActions}>
                                                                <Icon color="blue" name='dropdown' />
                                                                <font color="light blue"> <b>{t("Actions")}</b></font>
                                                            </Accordion.Title>
                                                            <Accordion.Content active={activeIndexInvoiceActions === invoice.id}>
                                                                {invoice.status === "C" && (
                                                                    <Table.Cell collapsing>
                                                                        <Button fluid type="button" size='medium' positive icon='thumbs up outline' content={t("Approve")} onClick={() => this.updateInvoiceStatus(invoice, "A")} /><br />
                                                                        {/* Add email approve + send button if invoicing method is "E"=Email */}
                                                                        {invoice.invoicing_method === 'E' && (
                                                                            <React.Fragment>
                                                                                <Button fluid type="button" size='medium' primary icon='envelope outline' content={t("+Send email")} onClick={() => this.openConfirm("email", invoice, t("Do you want to send invoice", { name: invoice.name, customer: invoice.customer_name }))} /><br />
                                                                            </React.Fragment>
                                                                        )}
                                                                        {/* Add net invoice approve + send button if invoicing method is "N"=Net invoice */}
                                                                        {invoice.invoicing_method === 'N' && (
                                                                            <React.Fragment>
                                                                                <Button fluid type="button" size='medium' color="yellow" icon='envelope outline' content={t("+Send email")} onClick={() => this.openConfirm("email", invoice, t("Do you want to send invoice", { name: invoice.name, customer: invoice.customer_name }))} /><br />
                                                                            </React.Fragment>
                                                                        )}
                                                                        {/* Add get PDF button if invoicing method is "P"=Paper */}
                                                                        {invoice.invoicing_method === 'P' && (
                                                                            <React.Fragment>
                                                                                <Button fluid type="button" size='medium' color="twitter" icon='file pdf outline' content={t("Get PDF")} onClick={() => this.handleFetchInvoicePdf(invoice.id)} /><br />
                                                                            </React.Fragment>
                                                                        )}
                                                                        <Button fluid type="button" size='medium' positive icon='check circle outline' content={t("Mark as Sent")} onClick={() => this.updateInvoiceStatus(invoice, "S")} />
                                                                    </Table.Cell>
                                                                )}
                                                                {invoice.status === "A" && (
                                                                    <Table.Cell collapsing>
                                                                        {/* Add email approve + send button if invoicing method is "E"=Email */}
                                                                        {invoice.invoicing_method === 'E' && (
                                                                            <React.Fragment>
                                                                                <Button fluid type="button" size='medium' primary icon='envelope outline' content={t("+Send email")} onClick={() => this.openConfirm("email", invoice, t("Do you want to send invoice", { name: invoice.name, customer: invoice.customer_name }))} /><br />
                                                                            </React.Fragment>
                                                                        )}
                                                                        {/* Add net invoice approve + send button if invoicing method is "N"=Net invoice */}
                                                                        {invoice.invoicing_method === 'N' && (
                                                                            <React.Fragment>
                                                                                <Button fluid type="button" size='medium' color="yellow" icon='envelope outline' content={t("+Send email")} onClick={() => this.openConfirm("email", invoice, t("Do you want to send invoice", { name: invoice.name, customer: invoice.customer_name }))} /><br />
                                                                            </React.Fragment>
                                                                        )}
                                                                        {/* Add get PDF button if invoicing method is "P"=Paper */}
                                                                        {invoice.invoicing_method === 'P' && (
                                                                            <React.Fragment>
                                                                                <Button fluid type="button" size='medium' color="twitter" icon='file pdf outline' content={t("Get PDF")} onClick={() => this.handleFetchInvoicePdf(invoice.id)} /><br />
                                                                            </React.Fragment>
                                                                        )}
                                                                        <Button fluid type="button" size='medium' positive icon='check circle outline' content={t("Mark as Sent")} onClick={() => this.updateInvoiceStatus(invoice, "S")} /><br />
                                                                        <Button fluid type="button" size='medium' primary icon='arrow left' content={t("Return")} onClick={() => this.updateInvoiceStatus(invoice, "C")} />
                                                                    </Table.Cell>
                                                                )}
                                                                {invoice.status === "S" && (
                                                                    <Table.Cell collapsing>
                                                                        <Button fluid type="button" size='medium' positive icon='checkmark' content={t("Paid")} onClick={() => this.updateInvoiceStatus(invoice, "P")} /> <br />
                                                                        <Button fluid type="button" size='medium' primary icon='arrow left' content={t("Return")} onClick={() => this.updateInvoiceStatus(invoice, "C")} />
                                                                    </Table.Cell>
                                                                )}
                                                                {invoice.status === "P" && (
                                                                    <Table.Cell collapsing>
                                                                        <Button fluid type="button" size='medium' primary icon='arrow left' content={t("Return")} onClick={() => this.updateInvoiceStatus(invoice, "C")} />
                                                                    </Table.Cell>
                                                                )}
                                                                {invoice.status === "O" && (
                                                                    <Table.Cell collapsing>
                                                                        <Button fluid type="button" size='medium' positive icon='checkmark' content={t("Paid")} onClick={() => this.updateInvoiceStatus(invoice, "P")} /><br />
                                                                        <Button fluid type="button" size='medium' primary icon='arrow left' content={t("Return")} onClick={() => this.updateInvoiceStatus(invoice, "C")} />
                                                                    </Table.Cell>
                                                                )}
                                                                {invoice.status === "B" && (
                                                                    <Table.Cell collapsing>
                                                                        {/* {parseFloat(this.handleGetAbnormalSum(invoice) - getTotalGrossPrice(invoice.invoiceitems)).toFixed(2) == 0.00 && ( */}
                                                                        {/* {parseFloat(this.handleGetAbnormalSum(invoice) - invoice.gross_total).toFixed(2) == 0.00 && (
                                                                            <React.Fragment>
                                                                                <Button fluid type="button" size='medium' positive icon='checkmark' onClick={() => this.updateInvoiceStatus(invoice, "P")} /><br />
                                                                            </React.Fragment>
                                                                        )} */}
                                                                        <React.Fragment>
                                                                                <Button fluid type="button" size='medium' positive icon='checkmark' onClick={() => this.updateInvoiceStatus(invoice, "P")} /><br />
                                                                        </React.Fragment>
                                                                        <Button fluid type="button" size='medium' primary icon='arrow left' content={t("Return")} onClick={() => this.updateInvoiceStatus(invoice, "C")} />
                                                                    </Table.Cell>
                                                                )}
                                                            </Accordion.Content>
                                                        </Accordion>
                                                    </React.Fragment>
                                                )}
                                            </Table.Row>
                                        );
                                    })}
                                    <Table.Row>
                                        {/* {this.props.match.params.status !== "P" && ( */}
                                            <Table.Cell />
                                        {/* )} */}
                                        <Table.Cell />
                                        <Table.Cell />
                                        <Table.Cell collapsing><Header as="h5">{filter_invoices.length} {t("pcs")}</Header></Table.Cell>
                                        <Table.Cell collapsing>
                                            <Header as="h5">{this.getTotalNetPriceAllInvoices(filter_invoices).toFixed(2)} €</Header>
                                        </Table.Cell>
                                        <Table.Cell collapsing>
                                            <Header as="h5">{this.getTotalGrossPriceAllInvoices(filter_invoices).toFixed(2)} €</Header>
                                        </Table.Cell>
                                        <Table.Cell />
                                    </Table.Row>
                                </Table.Body>
                            </Table>
                        )}
                        {active_invoice && (
                            <React.Fragment>
                                < Modal size='large' open={modal_open} dimmer="inverted" scrolling="true">
                                    <Modal.Header>
                                        <Header as="h3">{t("Invoice")} {active_invoice.name}</Header>
                                        {is_desktop ? (
                                            <React.Fragment>
                                                <Button type="button" positive icon='edit' content={t("Edit")} loading={loading} onClick={() => this.invoiceDetails(active_invoice.id)} />
                                                <Button type="button" primary icon='file pdf outline' content={t("Pdf")} loading={loading} onClick={() => this.handleFetchInvoicePdf(active_invoice.id)} />
                                                {active_invoice.invoicing_method === "E" && (
                                                    <React.Fragment>
                                                        <Popup content={t("Sends email to customer with PDF invoice attached")} trigger={
                                                            <Button type="button" primary icon='envelope outline' content={t("Send invoice")} loading={loading} onClick={() => this.openConfirm("email", active_invoice, t("Do you want to send invoice", { name: active_invoice.name, customer: active_invoice.customer.name }))} />
                                                        } />
                                                        {active_invoice.status === "O" && (
                                                            <>
                                                            {/* <Popup content={t("Opens notification invoice PDF")} trigger={
                                                                <Button type="button" icon='file pdf outline' content={t("Open notification PDF")} loading={loading} onClick={() => this.handleSendInvoiceReminder(active_invoice, true)} />
                                                            } /> 
                                                            <Popup content={t("Sends invoice reminder email to customer with PDF invoice attached")} trigger={
                                                                <Button type="button" icon='envelope outline' content={t("Send invoice reminder")} loading={loading} onClick={() => this.openConfirm("reminder_email", active_invoice, t("Do you want to send invoice reminder", { name: active_invoice.name, customer: active_invoice.customer.name }))} />
                                                            } />
                                                            */}
                                                            <Popup content={t("Creates reminder invoice")} trigger={
                                                                <Button type="button" icon='envelope outline' content={t("Create invoice reminder")} loading={loading} onClick={() => this.openConfirm("create_reminder_invoice", active_invoice, t("Do you want to create invoice reminder", { name: active_invoice.name, customer: active_invoice.customer.name }))} />
                                                            } />
                                                            </> 
                                                        )}
                                                    </React.Fragment>
                                                )}
                                                {active_invoice.invoicing_method === "N" && (
                                                    <React.Fragment>
                                                        <Popup content={t("Sends net invoice to customer with PDF invoice attached")} trigger={
                                                            <Button type="button" color="yellow" icon='envelope outline' content={t("Send invoice")} loading={loading} onClick={() => this.openConfirm("email", active_invoice, t("Do you want to send invoice", { name: active_invoice.name, customer: active_invoice.customer.name }))} />
                                                        } />
                                                        {active_invoice.status === "O" && (
                                                            <>
                                                            {/* <Popup content={t("Opens notification invoice PDF")} trigger={
                                                                <Button type="button" icon='file pdf outline' content={t("Open notification PDF")} loading={loading} onClick={() => this.handleSendInvoiceReminder(active_invoice, true)} />
                                                            } /> 
                                                            <Popup content={t("Sends invoice reminder email to customer with PDF invoice attached")} trigger={
                                                                <Button type="button" icon='envelope outline' content={t("Send invoice reminder")} loading={loading} onClick={() => this.openConfirm("reminder_email", active_invoice, t("Do you want to send invoice reminder", { name: active_invoice.name, customer: active_invoice.customer.name }))} />
                                                            } />
                                                            */}
                                                            <Popup content={t("Creates reminder invoice")} trigger={
                                                                <Button type="button" icon='envelope outline' content={t("Create invoice reminder")} loading={loading} onClick={() => this.openConfirm("create_reminder_invoice", active_invoice, t("Do you want to create invoice reminder", { name: active_invoice.name, customer: active_invoice.customer.name }))} />
                                                            } />
                                                            </> 
                                                        )}
                                                    </React.Fragment>
                                                )}
                                                {active_invoice.invoicing_method === "P" && (
                                                    <React.Fragment>
                                                        {active_invoice.status === "O" && (
                                                            <>
                                                            {/* <Popup content={t("Opens notification invoice PDF")} trigger={
                                                                <Button type="button" icon='file pdf outline' content={t("Open notification PDF")} loading={loading} onClick={() => this.handleSendInvoiceReminder(active_invoice, true)} />
                                                            } /> 
                                                            <Popup content={t("Sends invoice reminder email to customer with PDF invoice attached")} trigger={
                                                                <Button type="button" icon='envelope outline' content={t("Send invoice reminder")} loading={loading} onClick={() => this.openConfirm("reminder_email", active_invoice, t("Do you want to send invoice reminder", { name: active_invoice.name, customer: active_invoice.customer.name }))} />
                                                            } />
                                                            */}
                                                            <Popup content={t("Creates reminder invoice")} trigger={
                                                                <Button type="button" icon='envelope outline' content={t("Create invoice reminder")} loading={loading} onClick={() => this.openConfirm("create_reminder_invoice", active_invoice, t("Do you want to create invoice reminder", { name: active_invoice.name, customer: active_invoice.customer.name }))} />
                                                            } />
                                                            </> 
                                                        )}
                                                    </React.Fragment>
                                                )}
                                                {/* Add Credit invoice button if Status is Sent, Overdue or Paid */}
                                                {(active_invoice.status === "S" || active_invoice.status === "O" || active_invoice.status === "P") && (
                                                    <Popup content={t("Creates credit invoice in to Created invoices")} trigger={
                                                        <Button type="button" icon='undo' content={t("Credit invoice")} loading={loading} onClick={() => this.handleCreateCreditInvoice(active_invoice)} />
                                                    } />
                                                )}
                                                <Button type="button" negative floated="right" onClick={() => this.closeModal()} >{t("Close")}</Button>
                                                <Popup content={t("Copy this invoice as a template for new invoice")} trigger={
                                                    <Button type="button" primary icon="copy outline" content={t("Copy invoice")} floated="right" loading={loading} onClick={() => this.handleCopyInvoice(active_invoice.id)} />
                                                } />
                                            </React.Fragment>
                                        ) : (
                                            <React.Fragment>
                                                <Button type="button" negative floated="right" onClick={() => this.closeModal()} >{t("Close")}</Button>
                                                <Accordion>
                                                    <Accordion.Title
                                                        active={activeIndexModalActions === 1}
                                                        index={1}
                                                        onClick={this.handleClickModalActions}>
                                                        <Header as="h4" color="blue"><Icon color="blue" name='dropdown' />{t("Actions")}</Header>
                                                    </Accordion.Title>
                                                    <Accordion.Content active={activeIndexModalActions === 1}>
                                                        <Button fluid type="button" positive icon='edit' content={t("Edit")} loading={loading} onClick={() => this.invoiceDetails(active_invoice.id)} /><br />
                                                        <Button fluid type="button" primary icon='file pdf outline' content={t("Pdf")} loading={loading} onClick={() => this.handleFetchInvoicePdf(active_invoice.id)} /><br />
                                                        {active_invoice.invoicing_method === "E" && (
                                                            <React.Fragment>
                                                                <Button fluid type="button" primary icon='envelope outline' content={t("Send invoice")} loading={loading} onClick={() => this.openConfirm("email", active_invoice, t("Do you want to send invoice", { name: active_invoice.name, customer: active_invoice.customer.name }))} /><br />
                                                                {active_invoice.status === "O" && (
                                                                    <React.Fragment>
                                                                        <Button fluid type="button" icon='file pdf outline' content={t("Open notification PDF")} loading={loading} onClick={() => this.handleSendInvoiceReminder(active_invoice, true)} /><br />
                                                                        <Button fluid type="button" icon='envelope outline' content={t("Send invoice reminder")} loading={loading} onClick={() => this.openConfirm("reminder_email", active_invoice, t("Do you want to send invoice reminder", { name: active_invoice.name, customer: active_invoice.customer.name }))} /><br />
                                                                    </React.Fragment>                                                                
                                                                )}
                                                            </React.Fragment>
                                                        )}
                                                        {active_invoice.invoicing_method === "N" && (
                                                            <React.Fragment>
                                                                <Button fluid type="button" color="yellow" icon='envelope outline' content={t("Send invoice")} loading={loading} onClick={() => this.openConfirm("email", active_invoice, t("Do you want to send invoice", { name: active_invoice.name, customer: active_invoice.customer.name }))} /><br />
                                                                {active_invoice.status === "O" && (
                                                                    <React.Fragment>
                                                                        <Button fluid type="button" icon='envelope outline' content={t("Send invoice reminder")} loading={loading} onClick={() => this.openConfirm("reminder_email", active_invoice, t("Do you want to send invoice reminder", { name: active_invoice.name, customer: active_invoice.customer.name }))} /><br />
                                                                    </React.Fragment>
                                                                )}
                                                            </React.Fragment>
                                                        )}
                                                        {active_invoice.invoicing_method === "P" && (
                                                            <React.Fragment>
                                                                {active_invoice.status === "O" && (
                                                                    <React.Fragment>
                                                                        <Button fluid type="button" icon='file pdf outline' content={t("Open notification PDF")} loading={loading} onClick={() => this.handleSendInvoiceReminder(active_invoice, true)} /><br />
                                                                    </React.Fragment>
                                                                )}
                                                            </React.Fragment>
                                                        )}
                                                        {/* Add Credit invoice button if Status is Sent, Overdue or Paid */}
                                                        {(active_invoice.status === "S" || active_invoice.status === "O" || active_invoice.status === "P") && (
                                                            <React.Fragment>
                                                                <Button fluid type="button" icon='undo' content={t("Credit invoice")} loading={loading} onClick={() => this.handleCreateCreditInvoice(active_invoice)} /><br />
                                                            </React.Fragment>
                                                        )}
                                                        <Button fluid type="button" primary icon="copy outline" content={t("Copy invoice")} floated="right" loading={loading} onClick={() => this.handleCopyInvoice(active_invoice.id)} />
                                                    </Accordion.Content>
                                                </Accordion>
                                            </React.Fragment>
                                        )}
                                    </Modal.Header>
                                    <Modal.Content>
                                        {is_desktop ? (
                                            <React.Fragment>
                                                <Container>
                                                    <Grid columns="2">
                                                        <Grid.Row>
                                                            <Grid.Column>
                                                                <Header as="h4">{t("Basic details")}</Header>
                                                                <Card fluid>
                                                                    <Card.Content>
                                                                        <Card.Description>
                                                                            <Grid>
                                                                                <GridColumn>
                                                                                    <Table basic="very" size="small">
                                                                                        <Table.Body>
                                                                                            <Table.Row>
                                                                                                <Table.Cell><Header as="h5">{t("Invoice number")}:</Header></Table.Cell>
                                                                                                <Table.Cell>{active_invoice.number} </Table.Cell>
                                                                                            </Table.Row>
                                                                                            {active_invoice.original_number > 0 && (
                                                                                                <Table.Row>
                                                                                                    <Table.Cell><Header as="h5">{t("Original invoice number")}:</Header></Table.Cell>
                                                                                                    <Table.Cell>{active_invoice.original_number} </Table.Cell>
                                                                                                </Table.Row>
                                                                                            )}
                                                                                            <Table.Row>
                                                                                                <Table.Cell><Header as="h5">{t("Customer")}:</Header></Table.Cell>
                                                                                                <Table.Cell>
                                                                                                    <Link to={`/customer/customer-details/${active_invoice.customer.id}/`}>
                                                                                                        {active_invoice.customer.name}
                                                                                                    </Link>
                                                                                                </Table.Cell>
                                                                                            </Table.Row>
                                                                                            {active_invoice.contract.number !== null && (
                                                                                                <Table.Row>
                                                                                                    <Table.Cell><Header as="h5">{t("Contract")}:</Header></Table.Cell>
                                                                                                    <Table.Cell>
                                                                                                        <Link to={`/contract/contract-details/${active_invoice.contract.id}/`}>
                                                                                                            {active_invoice.contract.name} ({active_invoice.contract.number})
                                                                                                        </Link>
                                                                                                    </Table.Cell>
                                                                                                </Table.Row>
                                                                                            )}
                                                                                            <Table.Row>
                                                                                                <Table.Cell><Header as="h5">{t("Contract number on invoice")}:</Header></Table.Cell>
                                                                                                <Table.Cell>{active_invoice.contract_number_on_invoice}</Table.Cell>
                                                                                            </Table.Row>
                                                                                            <Table.Row>
                                                                                                <Table.Cell><Header as="h5">{t("Status")}:</Header></Table.Cell>
                                                                                                <Table.Cell>
                                                                                                    {active_invoice.status === "C" && (t("Created"))}
                                                                                                    {active_invoice.status === "A" && (t("Approved"))}
                                                                                                    {active_invoice.status === "S" && (t("Sent"))}
                                                                                                    {active_invoice.status === "Q" && (t("Sending"))}
                                                                                                    {active_invoice.status === "P" && (t("Paid"))}
                                                                                                    {active_invoice.status === "O" && (t("Overdue"))}
                                                                                                    {active_invoice.status === "B" && (t("Abnormal"))}
                                                                                                </Table.Cell>
                                                                                            </Table.Row>
                                                                                            <Table.Row>
                                                                                                <Table.Cell><Header as="h5">{t("Author")}:</Header></Table.Cell>
                                                                                                <Table.Cell>
                                                                                                    {active_invoice.author.username === "contract_manager" ? (
                                                                                                        <Link to={`/contract/contract-details/${active_invoice.contract.id}/`}>
                                                                                                            {active_invoice.contract.name} ({active_invoice.contract.number})
                                                                                                        </Link>
                                                                                                    ) : (
                                                                                                        <React.Fragment>
                                                                                                            {active_invoice.author.first_name} {active_invoice.author.last_name}
                                                                                                        </React.Fragment>
                                                                                                    )}
                                                                                                </Table.Cell>
                                                                                            </Table.Row>
                                                                                            <Table.Row>
                                                                                                <Table.Cell><Header as="h5">{t("Created_detail")}:</Header></Table.Cell>
                                                                                                <Table.Cell>{new Date(active_invoice.date_created).toLocaleString('fi-FI')}</Table.Cell>
                                                                                            </Table.Row>
                                                                                            <Table.Row>
                                                                                                <Table.Cell><Header as="h5">{t("Last modified")}:</Header></Table.Cell>
                                                                                                <Table.Cell>{new Date(active_invoice.last_modified).toLocaleString('fi-FI')}</Table.Cell>
                                                                                            </Table.Row>
                                                                                            <Table.Row>
                                                                                                <Table.Cell><Header as="h5">{t("Invoice sent")}:</Header></Table.Cell>
                                                                                                <Table.Cell>{active_invoice.date_sent && (new Date(active_invoice.date_sent).toLocaleString('fi-FI'))} </Table.Cell>
                                                                                            </Table.Row>
                                                                                            <Table.Row>
                                                                                                <Table.Cell><Header as="h5">{t("Invoice paid")}:</Header></Table.Cell>
                                                                                                <Table.Cell>{active_invoice.date_paid && (new Date(active_invoice.date_paid).toLocaleString('fi-FI'))} </Table.Cell>
                                                                                            </Table.Row>
                                                                                            <Table.Row>
                                                                                                <Table.Cell><Header as="h5">{t("Invoicing method")}:</Header></Table.Cell>
                                                                                                <Table.Cell>
                                                                                                    {active_invoice.invoicing_method === "P" && (t("Paper"))}
                                                                                                    {active_invoice.invoicing_method === "E" && (
                                                                                                        <React.Fragment>
                                                                                                            {t("Email")}
                                                                                                            {active_invoice.invoicing_email}
                                                                                                            {/* {active_customer_invoicing_details && (
                                                                                                                "/" + active_customer_invoicing_details.invoicing_email
                                                                                                            )} */}
                                                                                                        </React.Fragment>
                                                                                                    )}
                                                                                                    {active_invoice.invoicing_method === "N" && (t("Net invoice"))}
                                                                                                </Table.Cell>
                                                                                            </Table.Row>
                                                                                            <Table.Row>
                                                                                                <Table.Cell><Header as="h5">{t("Automatic invoice sending")}:</Header></Table.Cell>
                                                                                                <Table.Cell>{active_invoice.is_automatic_sending ? (
                                                                                                    <React.Fragment>
                                                                                                        {t("Yes")}
                                                                                                        {active_invoice.automatic_sending_mode === "A" && ("/" + t("Approved"))}
                                                                                                        {active_invoice.automatic_sending_mode === "*" && ("/" + t("Created and Approved"))}
                                                                                                    </React.Fragment>
                                                                                                ) : (t("No"))}
                                                                                                </Table.Cell>
                                                                                            </Table.Row>
                                                                                        </Table.Body>
                                                                                    </Table>
                                                                                </GridColumn>
                                                                            </Grid>
                                                                        </Card.Description>
                                                                    </Card.Content>
                                                                </Card>
                                                            </Grid.Column>
                                                            <Grid.Column >
                                                                <Header as="h4">{t("Invoicing details")}</Header>
                                                                <Card fluid>
                                                                    <Card.Content>
                                                                        <Card.Description>
                                                                            <Grid>
                                                                                <GridColumn>
                                                                                    <Table basic="very" size="small">
                                                                                        <Table.Body>
                                                                                            <Table.Row>
                                                                                                <Table.Cell><Header as="h5">{t("Total")}:</Header></Table.Cell>
                                                                                                <Table.Cell>{getTotalGrossPrice(active_invoice.invoiceitems).toFixed(2)} €</Table.Cell>
                                                                                                {/* <Table.Cell>{active_invoice.gross_total.toFixed(2)} €</Table.Cell> */}
                                                                                            </Table.Row>
                                                                                            <Table.Row>
                                                                                                <Table.Cell><Header as="h5">{t("Invoice reference")}:</Header></Table.Cell>
                                                                                                <Table.Cell>{active_invoice.reference_number}</Table.Cell>
                                                                                            </Table.Row>
                                                                                            <Table.Row>
                                                                                                <Table.Cell><Header as="h5">{t("Booking date")}:</Header></Table.Cell>
                                                                                                <Table.Cell>{new Date(active_invoice.booking_date).toLocaleString('fi-FI', { dateStyle: 'short' })}</Table.Cell>
                                                                                            </Table.Row>
                                                                                            <Table.Row>
                                                                                                <Table.Cell><Header as="h5">{t("Invoice date")}:</Header></Table.Cell>
                                                                                                <Table.Cell>{new Date(active_invoice.date).toLocaleString('fi-FI', { dateStyle: 'short' })}</Table.Cell>
                                                                                            </Table.Row>
                                                                                            <Table.Row>
                                                                                                <Table.Cell><Header as="h5">{t("Due date")}:</Header></Table.Cell>
                                                                                                <Table.Cell>{new Date(active_invoice.due_date).toLocaleString('fi-FI', { dateStyle: 'short' })}</Table.Cell>
                                                                                            </Table.Row>
                                                                                            <Table.Row>
                                                                                                <Table.Cell><Header as="h5">{t("Payment term")}:</Header></Table.Cell>
                                                                                                <Table.Cell>{active_invoice.payment_term}</Table.Cell>
                                                                                            </Table.Row>
                                                                                            <Table.Row>
                                                                                                <Table.Cell><Header as="h5">{t("Company reference")}:</Header></Table.Cell>
                                                                                                <Table.Cell>{active_invoice.company_reference}</Table.Cell>
                                                                                            </Table.Row>
                                                                                            <Table.Row>
                                                                                                <Table.Cell><Header as="h5">{t("Customer reference")}:</Header></Table.Cell>
                                                                                                <Table.Cell>{active_invoice.customer_reference} </Table.Cell>
                                                                                            </Table.Row>
                                                                                            <Table.Row>
                                                                                                <Table.Cell><Header as="h5">{t("Purchase order")}:</Header></Table.Cell>
                                                                                                <Table.Cell>{active_invoice.purchase_order} </Table.Cell>
                                                                                            </Table.Row>
                                                                                            <Table.Row>
                                                                                                <Table.Cell><Header as="h5">{t("Invoicing period")}:</Header></Table.Cell>
                                                                                                <Table.Cell>
                                                                                                    {(active_invoice.invoice_period_start_date && active_invoice.invoice_period_end_date) ? (
                                                                                                        <React.Fragment>
                                                                                                            {new Date(active_invoice.invoice_period_start_date).toLocaleString('fi-FI', { dateStyle: 'short' })}-
                                                                                                            {new Date(active_invoice.invoice_period_end_date).toLocaleString('fi-FI', { dateStyle: 'short' })}
                                                                                                        </React.Fragment>
                                                                                                    ) : (
                                                                                                        <React.Fragment />
                                                                                                    )}
                                                                                                </Table.Cell>
                                                                                            </Table.Row>
                                                                                            <Table.Row>
                                                                                                <Table.Cell><Header as="h5">{t("Invoice comment")}:</Header></Table.Cell>
                                                                                                <Table.Cell>{active_invoice.comment} </Table.Cell>
                                                                                            </Table.Row>
                                                                                            {active_invoice.no_vat_comment !== "" && (
                                                                                                <Table.Row>
                                                                                                    <Table.Cell><Header as="h5">{t("VAT 0% comment")}:</Header></Table.Cell>
                                                                                                    <Table.Cell>{active_invoice.no_vat_comment} </Table.Cell>
                                                                                                </Table.Row>
                                                                                            )}
                                                                                        </Table.Body>
                                                                                    </Table>
                                                                                </GridColumn>
                                                                            </Grid>
                                                                            {/* {active_invoice.no_vat_comment === "" && (
                                                                                <React.Fragment>
                                                                                    <br />
                                                                                    <br />
                                                                                </React.Fragment>
                                                                            )} */}
                                                                            {active_invoice.contract.number !== null && (
                                                                                <React.Fragment>
                                                                                    <br />
                                                                                    <br />
                                                                                </React.Fragment>
                                                                            )}
                                                                        </Card.Description>
                                                                    </Card.Content>
                                                                </Card>
                                                            </Grid.Column>
                                                        </Grid.Row>
                                                    </Grid>
                                                </Container>
                                                <br />
                                            </React.Fragment>
                                        ) : (
                                            <React.Fragment>
                                                <Accordion>
                                                    <Accordion.Title
                                                        active={activeIndexModalDetails === 1}
                                                        index={1}
                                                        onClick={this.handleClickModalDetails}>
                                                        <Header as="h4" color="blue"><Icon color="blue" name='dropdown' />{t("Details")}</Header>
                                                    </Accordion.Title>
                                                    <Accordion.Content active={activeIndexModalDetails === 1}>
                                                        <Header as="h4">{t("Basic details")}</Header>
                                                        <Card fluid>
                                                            <Card.Content>
                                                                <Card.Description>
                                                                    <Table basic="very" size="small">
                                                                        <Table.Body>
                                                                            <Table.Row>
                                                                                <Table.Cell><Header as="h5">{t("Invoice number")}</Header></Table.Cell>
                                                                                <Table.Cell>{active_invoice.number} </Table.Cell>
                                                                            </Table.Row>
                                                                            {active_invoice.original_number > 0 && (
                                                                                <Table.Row>
                                                                                    <Table.Cell><Header as="h5">{t("Original invoice number")}</Header></Table.Cell>
                                                                                    <Table.Cell>{active_invoice.original_number} </Table.Cell>
                                                                                </Table.Row>
                                                                            )}
                                                                            <Table.Row>
                                                                                <Table.Cell><Header as="h5">{t("Customer")}</Header></Table.Cell>
                                                                                <Table.Cell>
                                                                                    <Link to={`/customer/customer-details/${active_invoice.customer.id}/`}>
                                                                                        {active_invoice.customer.name}
                                                                                    </Link>
                                                                                </Table.Cell>
                                                                            </Table.Row>
                                                                            {active_invoice.contract.number !== null && (
                                                                                <Table.Row>
                                                                                    <Table.Cell><Header as="h5">{t("Contract")}</Header></Table.Cell>
                                                                                    <Table.Cell>
                                                                                        <Link to={`/contract/contract-details/${active_invoice.contract.id}/`}>
                                                                                            {active_invoice.contract.name} ({active_invoice.contract.number})
                                                                                        </Link>
                                                                                    </Table.Cell>
                                                                                </Table.Row>
                                                                            )}
                                                                            <Table.Row>
                                                                                <Table.Cell><Header as="h5">{t("Contract number on invoice")}</Header></Table.Cell>
                                                                                <Table.Cell>{active_invoice.contract_number_on_invoice}</Table.Cell>
                                                                            </Table.Row>
                                                                            <Table.Row>
                                                                                <Table.Cell><Header as="h5">{t("Status")}</Header></Table.Cell>
                                                                                <Table.Cell>
                                                                                    {active_invoice.status === "C" && (t("Created"))}
                                                                                    {active_invoice.status === "A" && (t("Approved"))}
                                                                                    {active_invoice.status === "S" && (t("Sent"))}
                                                                                    {active_invoice.status === "P" && (t("Paid"))}
                                                                                    {active_invoice.status === "O" && (t("Overdue"))}
                                                                                    {active_invoice.status === "B" && (t("Abnormal"))}
                                                                                </Table.Cell>
                                                                            </Table.Row>
                                                                            <Table.Row>
                                                                                <Table.Cell><Header as="h5">{t("Author")}</Header></Table.Cell>
                                                                                <Table.Cell>
                                                                                    {active_invoice.author.username === "contract_manager" ? (
                                                                                        <Link to={`/contract/contract-details/${active_invoice.contract.id}/`}>
                                                                                            {active_invoice.contract.name} ({active_invoice.contract.number})
                                                                                        </Link>
                                                                                    ) : (
                                                                                        <React.Fragment>
                                                                                            {active_invoice.author.first_name} {active_invoice.author.last_name}
                                                                                        </React.Fragment>
                                                                                    )}
                                                                                </Table.Cell>
                                                                            </Table.Row>
                                                                            <Table.Row>
                                                                                <Table.Cell><Header as="h5">{t("Created_detail")}</Header></Table.Cell>
                                                                                <Table.Cell>{new Date(active_invoice.date_created).toLocaleString('fi-FI')}</Table.Cell>
                                                                            </Table.Row>
                                                                            <Table.Row>
                                                                                <Table.Cell><Header as="h5">{t("Last modified")}</Header></Table.Cell>
                                                                                <Table.Cell>{new Date(active_invoice.last_modified).toLocaleString('fi-FI')}</Table.Cell>
                                                                            </Table.Row>
                                                                            <Table.Row>
                                                                                <Table.Cell><Header as="h5">{t("Invoice sent")}</Header></Table.Cell>
                                                                                <Table.Cell>{active_invoice.date_sent && (new Date(active_invoice.date_sent).toLocaleString('fi-FI'))} </Table.Cell>
                                                                            </Table.Row>
                                                                            <Table.Row>
                                                                                <Table.Cell><Header as="h5">{t("Invoice paid")}</Header></Table.Cell>
                                                                                <Table.Cell>{active_invoice.date_paid && (new Date(active_invoice.date_paid).toLocaleString('fi-FI'))} </Table.Cell>
                                                                            </Table.Row>
                                                                            <Table.Row>
                                                                                <Table.Cell><Header as="h5">{t("Invoicing method")}</Header></Table.Cell>
                                                                                <Table.Cell>
                                                                                    {active_invoice.invoicing_method === "P" && (t("Paper"))}
                                                                                    {active_invoice.invoicing_method === "E" && (
                                                                                        <React.Fragment>
                                                                                            {t("Email")}
                                                                                            {active_customer_invoicing_details && (
                                                                                                "/" + active_customer_invoicing_details.invoicing_email
                                                                                            )}
                                                                                        </React.Fragment>
                                                                                    )}
                                                                                    {active_invoice.invoicing_method === "N" && (t("Net invoice"))}
                                                                                </Table.Cell>
                                                                            </Table.Row>
                                                                            <Table.Row>
                                                                                <Table.Cell><Header as="h5">{t("Automatic invoice sending")}</Header></Table.Cell>
                                                                                <Table.Cell>{active_invoice.is_automatic_sending ? (
                                                                                    <React.Fragment>
                                                                                        {t("Yes")}
                                                                                        {active_invoice.automatic_sending_mode === "A" && ("/" + t("Approved"))}
                                                                                        {active_invoice.automatic_sending_mode === "*" && ("/" + t("Created and Approved"))}
                                                                                    </React.Fragment>
                                                                                ) : (t("No"))}
                                                                                </Table.Cell>
                                                                            </Table.Row>
                                                                        </Table.Body>
                                                                    </Table>
                                                                </Card.Description>
                                                            </Card.Content>
                                                        </Card>
                                                        <Header as="h4">{t("Invoicing details")}</Header>
                                                        <Card fluid>
                                                            <Card.Content>
                                                                <Card.Description>
                                                                    <Table basic="very" size="small">
                                                                        <Table.Body>
                                                                            <Table.Row>
                                                                                <Table.Cell><Header as="h5">{t("Total")}</Header></Table.Cell>
                                                                                <Table.Cell>{getTotalGrossPrice(active_invoice.invoiceitems).toFixed(2)} €</Table.Cell>
                                                                                {/* <Table.Cell>{active_invoice.gross_total.toFixed(2)} €</Table.Cell> */}
                                                                            </Table.Row>
                                                                            <Table.Row>
                                                                                <Table.Cell><Header as="h5">{t("Invoice reference")}</Header></Table.Cell>
                                                                                <Table.Cell>{active_invoice.reference_number}</Table.Cell>
                                                                            </Table.Row>
                                                                            <Table.Row>
                                                                                <Table.Cell><Header as="h5">{t("Invoice date")}</Header></Table.Cell>
                                                                                <Table.Cell>{new Date(active_invoice.date).toLocaleString('fi-FI', { dateStyle: 'short' })}</Table.Cell>
                                                                            </Table.Row>
                                                                            <Table.Row>
                                                                                <Table.Cell><Header as="h5">{t("Due date")}</Header></Table.Cell>
                                                                                <Table.Cell>{new Date(active_invoice.due_date).toLocaleString('fi-FI', { dateStyle: 'short' })}</Table.Cell>
                                                                            </Table.Row>
                                                                            <Table.Row>
                                                                                <Table.Cell><Header as="h5">{t("Payment term")}</Header></Table.Cell>
                                                                                <Table.Cell>{active_invoice.payment_term}</Table.Cell>
                                                                            </Table.Row>
                                                                            <Table.Row>
                                                                                <Table.Cell><Header as="h5">{t("Company reference")}</Header></Table.Cell>
                                                                                <Table.Cell>{active_invoice.company_reference}</Table.Cell>
                                                                            </Table.Row>
                                                                            <Table.Row>
                                                                                <Table.Cell><Header as="h5">{t("Customer reference")}</Header></Table.Cell>
                                                                                <Table.Cell>{active_invoice.customer_reference} </Table.Cell>
                                                                            </Table.Row>
                                                                            <Table.Row>
                                                                                <Table.Cell><Header as="h5">{t("Purchase order")}</Header></Table.Cell>
                                                                                <Table.Cell>{active_invoice.purchase_order} </Table.Cell>
                                                                            </Table.Row>
                                                                            <Table.Row>
                                                                                <Table.Cell><Header as="h5">{t("Invoicing period")}</Header></Table.Cell>
                                                                                <Table.Cell>
                                                                                    {(active_invoice.invoice_period_start_date && active_invoice.invoice_period_end_date) ? (
                                                                                        <React.Fragment>
                                                                                            {new Date(active_invoice.invoice_period_start_date).toLocaleString('fi-FI', { dateStyle: 'short' })}-
                                                                                            {new Date(active_invoice.invoice_period_end_date).toLocaleString('fi-FI', { dateStyle: 'short' })}
                                                                                        </React.Fragment>
                                                                                    ) : (
                                                                                        <React.Fragment />
                                                                                    )}
                                                                                </Table.Cell>
                                                                            </Table.Row>
                                                                            <Table.Row>
                                                                                <Table.Cell><Header as="h5">{t("Invoice comment")}</Header></Table.Cell>
                                                                                <Table.Cell>{active_invoice.comment} </Table.Cell>
                                                                            </Table.Row>
                                                                            {active_invoice.no_vat_comment !== "" && (
                                                                                <Table.Row>
                                                                                    <Table.Cell><Header as="h5">{t("VAT 0% comment")}</Header></Table.Cell>
                                                                                    <Table.Cell>{active_invoice.no_vat_comment} </Table.Cell>
                                                                                </Table.Row>
                                                                            )}
                                                                        </Table.Body>
                                                                    </Table>
                                                                </Card.Description>
                                                            </Card.Content>
                                                        </Card>
                                                    </Accordion.Content>
                                                </Accordion>
                                                <br />
                                                <hr />
                                            </React.Fragment>
                                        )}

                                        {is_desktop ? (
                                            <Button type="button" positive floated="right" loading={loading} onClick={() => this.handleAddInvoiceComment()} >{t("+ Add comment")}</Button>
                                        ) : (
                                            <Button type="button" positive fluid onClick={() => this.handleAddInvoiceComment()} >{t("+ Add comment")}</Button>
                                        )}
                                        <br />
                                        {active_invoice.invoicecomments.length > 0 && (
                                            <React.Fragment>
                                                <Container>
                                                    <strong>{t("Invoice comments")}</strong>
                                                    <Table striped selectable fixed>
                                                        {is_desktop && (
                                                            <Table.Header>
                                                                <Table.Row>
                                                                    <Table.HeaderCell width="3">{t("Comment date")}</Table.HeaderCell>
                                                                    <Table.HeaderCell >{t("Comment type")}</Table.HeaderCell>
                                                                    <Table.HeaderCell width="7">{t("Comment")}</Table.HeaderCell>
                                                                    <Table.HeaderCell>{t("Author")}</Table.HeaderCell>
                                                                    <Table.HeaderCell />
                                                                </Table.Row>
                                                            </Table.Header>
                                                        )}
                                                        <Table.Body>
                                                            {active_invoice.invoicecomments.map(item => {
                                                                return (
                                                                    <Table.Row key={item.id}>

                                                                        <Table.Cell onClick={() => this.handleEditInvoiceComment(item)}>
                                                                            {!is_desktop && (<React.Fragment>{t("Comment date")}<br /></React.Fragment>)}
                                                                            {new Date(item.date_created).toLocaleString('fi-FI')}
                                                                        </Table.Cell>
                                                                        <Table.Cell onClick={() => this.handleEditInvoiceComment(item)}>
                                                                            {!is_desktop && (<React.Fragment>{t("Comment type")}<br /></React.Fragment>)}
                                                                            {item.comment_type === "USER" && (t("USER"))}
                                                                            {item.comment_type === "ERROR" && (
                                                                                <React.Fragment>
                                                                                    {item.is_acknowledged ? (t("ERROR")) : (<Header as="h4" color="red">{t("ERROR")}</Header>)}
                                                                                </React.Fragment>
                                                                            )}
                                                                            {item.comment_type === "OK" && (t("OK"))}
                                                                        </Table.Cell>
                                                                        <Table.Cell onClick={() => this.handleEditInvoiceComment(item)}>
                                                                            {!is_desktop && (<React.Fragment>{t("Comment")}<br /></React.Fragment>)}
                                                                            {item.comment_type === "OK" && (
                                                                                <React.Fragment>
                                                                                    {item.comment.includes("Email invoice sent to") ? (
                                                                                        item.comment.replace("Email invoice sent to", t("Email invoice sent to"))
                                                                                    ) : (
                                                                                        <React.Fragment>
                                                                                            {item.comment.includes("Net invoice sent successfully.") ? (
                                                                                                item.comment.replace("Net invoice sent successfully.", t("Net invoice sent successfully."))
                                                                                            ) : (
                                                                                                <React.Fragment>
                                                                                                    {item.comment.includes("Invoice notification letter sent successfully.") ? (
                                                                                                        item.comment.replace("Invoice notification letter sent successfully.", t("Invoice notification letter sent successfully."))
                                                                                                    ) : (
                                                                                                        item.comment
                                                                                                    )}
                                                                                                </React.Fragment>
                                                                                            )}
                                                                                        </React.Fragment>
                                                                                    )}
                                                                                </React.Fragment>
                                                                            )}
                                                                            {item.comment_type === "ERROR" && (
                                                                                <React.Fragment>
                                                                                    {item.comment.includes("Email invoice sending FAILED to") ? (
                                                                                        item.comment.replace("Email invoice sending FAILED to", t("Email invoice sending FAILED to"))
                                                                                    ) : (
                                                                                        <React.Fragment>
                                                                                            {item.comment.includes("Net invoice sending FAILED. REASON:") ? (
                                                                                                item.comment.replace("Net invoice sending FAILED. REASON:", t("Net invoice sending FAILED. REASON:"))
                                                                                            ) : (
                                                                                                <React.Fragment>
                                                                                                    {item.comment.includes("Invoice notification letter sending FAILED. REASON:") ? (
                                                                                                        item.comment.replace("Invoice notification letter sending FAILED. REASON:", t("Invoice notification letter sending FAILED. REASON:"))
                                                                                                    ) : (
                                                                                                        item.comment
                                                                                                    )}
                                                                                                </React.Fragment>
                                                                                            )}
                                                                                        </React.Fragment>
                                                                                    )}
                                                                                </React.Fragment>
                                                                            )}
                                                                            {(item.comment_type !== "ERROR" && item.comment_type !== "OK") && (item.comment)}

                                                                        </Table.Cell>
                                                                        <Table.Cell onClick={() => this.handleEditInvoiceComment(item)}>
                                                                            {!is_desktop && (<React.Fragment>{t("Author")}<br /></React.Fragment>)}
                                                                            {item.author.first_name} {item.author.last_name}
                                                                        </Table.Cell>
                                                                        {is_desktop ? (
                                                                            <React.Fragment>
                                                                                <Table.Cell textAlign='center' collapsing>
                                                                                    {item.comment_type === "ERROR" && (
                                                                                        <React.Fragment>
                                                                                            {item.is_acknowledged ? (
                                                                                                <React.Fragment>
                                                                                                    <Popup content={t("Mark as unread")} trigger={
                                                                                                        <Button type="button" positive size='tiny' icon='checkmark' onClick={() => this.handleAcknowledgeInvoiceComment(item)} />
                                                                                                    } />
                                                                                                </React.Fragment>
                                                                                            ) : (
                                                                                                <React.Fragment>
                                                                                                    <Popup content={t("Mark as read")} trigger={
                                                                                                        <Button type="button" positive size='tiny' icon='checkmark' onClick={() => this.handleAcknowledgeInvoiceComment(item)} />
                                                                                                    } />
                                                                                                </React.Fragment>
                                                                                            )}
                                                                                        </React.Fragment>
                                                                                    )}
                                                                                    {(item.comment_type === "USER" && item.author.id === parseInt(localStorage.getItem("UserID"))) && (
                                                                                        <React.Fragment>
                                                                                            <Popup content={t("Delete comment")} trigger={
                                                                                                <Icon link color="red" name='trash alternate outline' onClick={() => this.handleDeleteInvoiceComment(item)} />
                                                                                            } />
                                                                                        </React.Fragment>
                                                                                    )}
                                                                                </Table.Cell>
                                                                            </React.Fragment>
                                                                        ) : (
                                                                            <React.Fragment>
                                                                                <Table.Cell collapsing>
                                                                                    <Accordion>
                                                                                        <Accordion.Title
                                                                                            active={activeIndexModalComment === 1}
                                                                                            index={1}
                                                                                            onClick={this.handleClickModalComment}>
                                                                                            <Icon color="blue" name='dropdown' />
                                                                                            <font color="light blue"> <b>{t("Actions")}</b></font>
                                                                                        </Accordion.Title>
                                                                                        <Accordion.Content active={activeIndexModalComment === 1}>
                                                                                            {item.comment_type === "ERROR" && (
                                                                                                <React.Fragment>
                                                                                                    {item.is_acknowledged ? (
                                                                                                        <React.Fragment>
                                                                                                            <Button fluid type="button" positive content={t("Mark as unread")} icon='checkmark' onClick={() => this.handleAcknowledgeInvoiceComment(item)} /><br />
                                                                                                        </React.Fragment>
                                                                                                    ) : (
                                                                                                        <React.Fragment>
                                                                                                            <Button fluid type="button" positive content={t("Mark as read")} icon='checkmark' onClick={() => this.handleAcknowledgeInvoiceComment(item)} /><br />
                                                                                                        </React.Fragment>
                                                                                                    )}
                                                                                                </React.Fragment>
                                                                                            )}
                                                                                            {(item.comment_type === "USER" && item.author.id === parseInt(localStorage.getItem("UserID"))) && (
                                                                                                <React.Fragment>
                                                                                                    <Button fluid type="button" negative icon='trash alternate outline' onClick={() => this.handleDeleteInvoiceComment(item)} />
                                                                                                </React.Fragment>
                                                                                            )}
                                                                                        </Accordion.Content>
                                                                                    </Accordion>
                                                                                </Table.Cell>
                                                                            </React.Fragment>
                                                                        )}
                                                                    </Table.Row>
                                                                );
                                                            })}
                                                        </Table.Body>
                                                    </Table>
                                                </Container>
                                                <br />
                                            </React.Fragment>
                                        )}
                                        {is_desktop ? (
                                            <React.Fragment>
                                                <br />
                                                <Button type="button" positive floated="right" loading={loading} onClick={() => this.handleAddAbnormalPayment()} >{t("+ Abnormal payment")}</Button>
                                            </React.Fragment>
                                        ) : (
                                            <React.Fragment>
                                                <hr />
                                                <Button fluid type="button" positive onClick={() => this.handleAddAbnormalPayment()} >{t("+ Abnormal payment")}</Button>
                                            </React.Fragment>
                                        )}
                                        <br />
                                        {active_invoice.invoiceabnormalpayments.length > 0 && (
                                            <React.Fragment>
                                                <Container>
                                                    <strong>{t("Abnormal payments")}</strong>
                                                    <Table striped selectable >
                                                        {is_desktop && (
                                                            <Table.Header>
                                                                <Table.Row>
                                                                    <Table.HeaderCell>{t("Handling date")}</Table.HeaderCell>
                                                                    <Table.HeaderCell>{t("Payment date")}</Table.HeaderCell>
                                                                    <Table.HeaderCell width="4">{t("Description")}</Table.HeaderCell>
                                                                    <Table.HeaderCell>{t("Author")}</Table.HeaderCell>
                                                                    <Table.HeaderCell textAlign="right">{t("Gross €")}</Table.HeaderCell>
                                                                    <Table.HeaderCell collapsing />
                                                                </Table.Row>
                                                            </Table.Header>
                                                        )}
                                                        <Table.Body>
                                                            {active_invoice.invoiceabnormalpayments.map(item => {
                                                                return (
                                                                    <Table.Row key={item.id}>
                                                                        <Table.Cell collapsing onClick={() => this.handleEditAbnormalPayment(item)}>
                                                                            {!is_desktop && (<React.Fragment>{t("Handling date")}<br /></React.Fragment>)}
                                                                            {new Date(item.handling_date).toLocaleString('fi-FI', { dateStyle: 'short' })}
                                                                        </Table.Cell>
                                                                        <Table.Cell collapsing onClick={() => this.handleEditAbnormalPayment(item)}>
                                                                            {!is_desktop && (<React.Fragment>{t("Payment date")}<br /></React.Fragment>)}
                                                                            {new Date(item.payment_date).toLocaleString('fi-FI', { dateStyle: 'short' })}
                                                                        </Table.Cell>
                                                                        <Table.Cell collapsing onClick={() => this.handleEditAbnormalPayment(item)}>
                                                                            {!is_desktop && (<React.Fragment>{t("Description")}<br /></React.Fragment>)}
                                                                            {item.description}
                                                                        </Table.Cell>
                                                                        <Table.Cell collapsing onClick={() => this.handleEditAbnormalPayment(item)}>
                                                                            {!is_desktop && (<React.Fragment>{t("Author")}<br /></React.Fragment>)}
                                                                            {item.author.first_name} {item.author.last_name}
                                                                        </Table.Cell>
                                                                        <Table.Cell collapsing onClick={() => this.handleEditAbnormalPayment(item)} textAlign="right">
                                                                            {!is_desktop && (<React.Fragment>{t("Gross €")}<br /></React.Fragment>)}
                                                                            <strong>{item.paid_amount.toFixed(2)} €</strong>
                                                                        </Table.Cell>
                                                                        {item.author.id === parseInt(localStorage.getItem("UserID")) ? (
                                                                            <React.Fragment>
                                                                                {is_desktop ? (
                                                                                    <Table.Cell collapsing textAlign='center'>
                                                                                        <Popup content={t("Delete abnormal payment")} trigger={
                                                                                            <Icon link color="red" name='trash alternate outline' onClick={() => this.handleDeleteAbnormalPayment(item)} />
                                                                                        } />
                                                                                    </Table.Cell>
                                                                                ) : (
                                                                                    <Table.Cell collapsing>
                                                                                        <Accordion>
                                                                                            <Accordion.Title
                                                                                                active={activeIndexModalAbnormal === 1}
                                                                                                index={1}
                                                                                                onClick={this.handleClickModalAbnormal}>
                                                                                                <Icon color="blue" name='dropdown' />
                                                                                                <font color="light blue"> <b>{t("Actions")}</b></font>
                                                                                            </Accordion.Title>
                                                                                            <Accordion.Content active={activeIndexModalAbnormal === 1}>
                                                                                                <Button fluid type="button" negative icon='trash alternate outline' onClick={() => this.handleDeleteAbnormalPayment(item)} />
                                                                                            </Accordion.Content>
                                                                                        </Accordion>
                                                                                    </Table.Cell>
                                                                                )}
                                                                            </React.Fragment>
                                                                        ) : (
                                                                            <Table.Cell collapsing />
                                                                        )}
                                                                    </Table.Row>
                                                                );
                                                            })}
                                                            {is_desktop ? (
                                                                <Table.Row>
                                                                    <Table.Cell />
                                                                    <Table.Cell />
                                                                    <Table.Cell textAlign="right"><Header as="h5">{t("Abnormal balance")}</Header></Table.Cell>
                                                                    <Table.Cell >
                                                                        <Header color="red" as="h5">({(this.handleGetAbnormalSum(active_invoice) - getTotalGrossPrice(active_invoice.invoiceitems)).toFixed(2)} €)</Header>
                                                                        {/* <Header color="red" as="h5">({(this.handleGetAbnormalSum(active_invoice) - active_invoice.gross_total).toFixed(2)} €)</Header> */}
                                                                    </Table.Cell>

                                                                    <Table.Cell textAlign="right"><Header as="h5">{this.handleGetAbnormalSum(active_invoice).toFixed(2)} €</Header></Table.Cell>
                                                                    <Table.Cell />
                                                                </Table.Row>
                                                            ) : (
                                                                <Table.Row>
                                                                    <Table.Cell textAlign="right">
                                                                        {t("Abnormal payments total")}
                                                                        <Header as="h5">{this.handleGetAbnormalSum(active_invoice).toFixed(2)} €</Header>
                                                                    </Table.Cell>
                                                                    <Table.Cell textAlign="right">
                                                                        {t("Abnormal balance")}
                                                                        <Header color="red" as="h5">({(this.handleGetAbnormalSum(active_invoice) - getTotalGrossPrice(active_invoice.invoiceitems)).toFixed(2)} €)</Header>
                                                                        {/* <Header color="red" as="h5">({(this.handleGetAbnormalSum(active_invoice) - active_invoice.gross_total).toFixed(2)} €)</Header> */}
                                                                    </Table.Cell>
                                                                </Table.Row>
                                                            )}
                                                        </Table.Body>
                                                    </Table>
                                                </Container>
                                                <br />
                                            </React.Fragment>
                                        )}
                                        <Container>
                                            <Header as="h4">{t("Invoice Items")} {(!is_desktop && active_invoice.invoiceitems.length > 0) && (`${active_invoice.invoiceitems.length} ${t("pcs")} (${getTotalNetPrice(active_invoice.invoiceitems).toFixed(2)} €) ${getTotalGrossPrice(active_invoice.invoiceitems).toFixed(2)} €`)}</Header>
                                            {/* <Header as="h4">{t("Invoice Items")} {(!is_desktop && active_invoice.invoiceitems.length > 0) && (`${active_invoice.invoiceitems.length} ${t("pcs")} (${active_invoice.net_total.toFixed(2)} €) ${active_invoice.gross_total.toFixed(2)} €`)}</Header> */}
                                            <Table striped selectable>
                                                {is_desktop && (
                                                    <Table.Header>
                                                        <Table.Row>
                                                            <Table.HeaderCell>{t("Name")}</Table.HeaderCell>
                                                            <Table.HeaderCell>{t("Amount")}</Table.HeaderCell>
                                                            <Table.HeaderCell>{t("Net €")}</Table.HeaderCell>
                                                            <Table.HeaderCell>{t("Discount %")}</Table.HeaderCell>
                                                            <Table.HeaderCell>{t("Net total €")}</Table.HeaderCell>
                                                            <Table.HeaderCell>{t("VAT %")}</Table.HeaderCell>
                                                            <Table.HeaderCell textAlign="right">{t("Gross €")}</Table.HeaderCell>
                                                        </Table.Row>
                                                    </Table.Header>
                                                )}
                                                {active_invoice.invoiceitems.length > 0 && (
                                                    <Table.Body>
                                                        {active_invoice.invoiceitems.map(item => {
                                                            return (
                                                                <Table.Row key={item.id} >
                                                                    <Table.Cell >
                                                                        {item.item_type === "D" && (<Popup content={t("Data collection product")} trigger={<Icon fitted name="cloud download" />} />)}
                                                                        {item.recurring
                                                                            ? (<Popup content={t("Recurring product/invoiced periodically")} trigger={<Icon fitted name="sync" />} />)
                                                                            : (<Popup content={t("Product invoiced once")} trigger={<Icon fitted name="file alternate outline" />} />)}
                                                                        &nbsp; <b>{item.name}</b><br />{item.description}

                                                                        {/* <Header as="h5" >{item.name}</Header>{item.description} */}
                                                                    </Table.Cell>
                                                                    <Table.Cell >
                                                                        {!is_desktop && (<React.Fragment>{t("Amount")}<br /></React.Fragment>)}
                                                                        {item.amount} {item.measure_unit}
                                                                    </Table.Cell>
                                                                    <Table.Cell  >
                                                                        {!is_desktop && (<React.Fragment>{t("Net €")}<br /></React.Fragment>)}
                                                                        {item.net_price}
                                                                    </Table.Cell>
                                                                    <Table.Cell >
                                                                        {!is_desktop && (<React.Fragment>{t("Discount %")}<br /></React.Fragment>)}
                                                                        {item.discount}
                                                                    </Table.Cell>
                                                                    <Table.Cell  >
                                                                        {!is_desktop && (<React.Fragment>{t("Net total €")}<br /></React.Fragment>)}
                                                                        {getItemNetPrice(item).toFixed(2)} €
                                                                    </Table.Cell>
                                                                    <Table.Cell  >
                                                                        {!is_desktop && (<React.Fragment>{t("VAT %")}<br /></React.Fragment>)}
                                                                        {item.vat_value}
                                                                    </Table.Cell>
                                                                    {is_desktop ? (
                                                                        <Table.Cell textAlign="right">
                                                                            <Header as="h5">{getItemGrossPrice(item).toFixed(2)} €</Header>
                                                                        </Table.Cell>
                                                                    ) : (
                                                                        <Table.Cell >
                                                                            <Header as="h5">
                                                                                {t("Gross €")}<br />
                                                                                {getItemGrossPrice(item).toFixed(2)} €
                                                                            </Header>
                                                                        </Table.Cell>
                                                                    )}
                                                                </Table.Row>
                                                            );
                                                        })}
                                                        {is_desktop && (
                                                            <Table.Row>
                                                                <Table.Cell />
                                                                <Table.Cell />
                                                                <Table.Cell />
                                                                <Table.Cell textAlign="right"><Header as="h5">{t("Total")}</Header></Table.Cell>
                                                                <Table.Cell >
                                                                    <Header as="h5">({getTotalNetPrice(active_invoice.invoiceitems).toFixed(2)} €)</Header>
                                                                    {/* <Header as="h5">({active_invoice.net_total.toFixed(2)} €)</Header> */}
                                                                </Table.Cell>
                                                                <Table.Cell />
                                                                <Table.Cell textAlign="right"><Header as="h5">{getTotalGrossPrice(active_invoice.invoiceitems).toFixed(2)} €</Header></Table.Cell>
                                                                {/* <Table.Cell textAlign="right"><Header as="h5">{active_invoice.gross_total_total.toFixed(2)} €</Header></Table.Cell> */}
                                                            </Table.Row>
                                                        )}
                                                    </Table.Body>
                                                )}
                                            </Table>
                                        </Container>
                                    </Modal.Content>
                                    <Modal.Actions>
                                        <Button type="button" negative onClick={() => this.closeModal()} >{t("Close")}</Button>
                                    </Modal.Actions>
                                </Modal>
                                {/* Invoice comment modal. */}
                                <Modal size='small' open={invoice_comment_modal_open} dimmer="inverted" scrolling="true">
                                    <Modal.Header>
                                        <Header as="h3">{t("Internal invoice comment")}</Header>
                                    </Modal.Header>
                                    <Modal.Content>
                                        {active_invoice_comment && (
                                            <Container>
                                                <Form error={error !== null}>
                                                    <Form.Field>
                                                        <label>{t("Comment")} ({active_invoice_comment.comment ? (`${active_invoice_comment.comment.length}/100`) : ("0/100")})</label>
                                                        <Input
                                                            value={active_invoice_comment.comment}
                                                            name="comment"
                                                            onChange={this.handleInvoiceCommentChange} />
                                                    </Form.Field>

                                                    {error && <Message error header={t("Error")} content={error} />}
                                                    {success_mgs && <Message positive header={success_mgs} />}
                                                    {(create_invoice_comment || (active_invoice_comment.author === parseInt(localStorage.getItem("UserID")) && active_invoice_comment.comment_type === "USER")) && (
                                                        <React.Fragment>
                                                            {!error && (
                                                                <Button primary type="button" loading={loading} disabled={loading} onClick={(e) => this.handleInvoiceCommentSubmit(e)}>
                                                                    {t("Save")}
                                                                </Button>
                                                            )}
                                                        </React.Fragment>
                                                    )}

                                                    <Button type="button" floated="right" negative onClick={() => this.closeInvoiceCommentModal()} >{t("Close")}</Button>
                                                </Form>
                                            </Container>
                                        )}
                                    </Modal.Content>
                                </Modal>

                                {/* Abnormal payment modal. */}
                                <Modal size='small' open={abnormal_payment_modal_open} dimmer="inverted" scrolling="true">
                                    <Modal.Header>
                                        <Header as="h3">{t("Abnormal payment")}</Header>
                                    </Modal.Header>
                                    <Modal.Content>
                                        {active_abnormal_payment && (
                                            <Container>
                                                <Form error={error !== null}>
                                                    <Form.Field>
                                                        {/* <label>{t("Payment date")}</label> */}
                                                        <SemanticDatepicker
                                                            firstDayOfWeek="1"
                                                            size="mini"
                                                            disabled={loading}
                                                            label={t("Handling date")}
                                                            locale={fiLocale}
                                                            placeholder={create_abnormal_payment ? (new Date().toLocaleString('fi-FI', { dateStyle: 'short' })) : (new Date(active_abnormal_payment.handling_date).toLocaleString('fi-FI', { dateStyle: 'short' }))}
                                                            onDateChange={selected => this.handleAbnormalPaymentHandlingDateChange(selected)}
                                                            format='DD.MM.YYYY' />
                                                    </Form.Field>
                                                    <Form.Field>
                                                        {/* <label>{t("Payment date")}</label> */}
                                                        <SemanticDatepicker
                                                            firstDayOfWeek="1"
                                                            size="mini"
                                                            disabled={loading}
                                                            label={t("Payment date")}
                                                            locale={fiLocale}
                                                            placeholder={create_abnormal_payment ? (new Date().toLocaleString('fi-FI', { dateStyle: 'short' })) : (new Date(active_abnormal_payment.payment_date).toLocaleString('fi-FI', { dateStyle: 'short' }))}
                                                            onDateChange={selected => this.handleAbnormalPaymentDateChange(selected)}
                                                            format='DD.MM.YYYY' />
                                                    </Form.Field>
                                                    <Form.Field required>
                                                        <label>{t("Description")} ({active_abnormal_payment.description ? (`${active_abnormal_payment.description.length}/80`) : ("0/80")})</label>
                                                        <Input
                                                            value={active_abnormal_payment.description}
                                                            name="description"
                                                            onChange={this.handleAbnormalPaymentChange} />
                                                    </Form.Field>
                                                    <Form.Field required>
                                                        <label>{t("Amount")}</label>
                                                        <Input
                                                            value={active_abnormal_payment.paid_amount}
                                                            name="paid_amount"
                                                            onChange={this.handleAbnormalPaymentChange} />
                                                    </Form.Field>

                                                    {error && <Message error header={t("Error")} content={error} />}
                                                    {success_mgs && <Message positive header={success_mgs} />}
                                                    {(create_abnormal_payment || active_abnormal_payment.author === parseInt(localStorage.getItem("UserID"))) && (
                                                        <React.Fragment>
                                                            {!error && (
                                                                <Button primary type="button" loading={loading} disabled={loading} onClick={(e) => this.handleAbnormalPaymentSubmit(e)}>
                                                                    {t("Save")}
                                                                </Button>
                                                            )}
                                                        </React.Fragment>
                                                    )}
                                                </Form>
                                            </Container>
                                        )}
                                    </Modal.Content>
                                    <Modal.Actions>
                                        <Button type="button" negative onClick={() => this.closeAbnormalPaymentModal()} >{t("Close")}</Button>
                                    </Modal.Actions>
                                </Modal>
                            </React.Fragment>
                        )
                        }
                        <Modal size='mini' open={send_status_modal_open} dimmer="inverted" scrolling="true">
                            <Modal.Header>
                                <Header as="h4">{t("Sending complete")}</Header>
                            </Modal.Header>
                            <Modal.Content>
                                <Container>
                                    {t("Batch ID")}: {net_invoice_response.batch_id} <br />
                                    <br />
                                    {net_invoice_response.response_text !== "Invoice sending complete." && (
                                        <Header color="red" as="h4">{net_invoice_response.response_text}</Header>
                                    )}
                                    {t("Successfully sent invoices")}: {net_invoice_response.sending_ok_count} <br />
                                    {t("Invoice sending failed")}: {net_invoice_response.sending_failed_count} <br />
                                    <br />
                                    {t("Networked invoices")}: {net_invoice_response.networked_invoices} <br />
                                    {t("Letter invoices")}: {net_invoice_response.letter_invoices} <br />
                                    {t("Letter pages")}: {net_invoice_response.letter_pages} <br />
                                    {t("Email invoices")}: {net_invoice_response.email_invoices}
                                </Container>
                            </Modal.Content>
                            <Modal.Actions>
                                <Button type="button" primary onClick={() => this.closeSendStatusModal()} >{t("OK")}</Button>
                            </Modal.Actions>
                        </Modal>
                        <Modal size="tiny" open={multiple_edit_modal_open} dimmer="inverted" >
                            <Modal.Header>
                                {t("Edit multiple invoices")}
                            </Modal.Header>
                            <Modal.Content>
                                <>
                                    <Form>
                                        <Form.Field>
                                            <Select
                                                search
                                                name='item'
                                                options={this.handleMultiEditOptions(invoices_status)}
                                                placeholder={t("Select item to edit")}
                                                onChange={this.handleMultiEditSelectChange}
                                                value={multiple_edit.item && multiple_edit.item}
                                            />
                                        </Form.Field>
                                        {multiple_edit.item && (
                                            <>
                                                {(multiple_edit.item === "name" || multiple_edit.item === "contract_number_on_invoice") && (
                                                    <Form.Field>
                                                        <label>{this.handleGetMultiEditLabel(multiple_edit.item)} {multiple_edit.value ? (`${multiple_edit.value.length}/35`) : ("0/35")}</label>
                                                        <Input
                                                            value={multiple_edit.value}
                                                            name={multiple_edit.item}
                                                            onChange={this.handleMultiEditChange} />
                                                    </Form.Field>
                                                )}
                                                {(multiple_edit.item === "customer_reference" || multiple_edit.item === "company_reference") && (
                                                    <Form.Field>
                                                        <label>{this.handleGetMultiEditLabel(multiple_edit.item)} {multiple_edit.value ? (`${multiple_edit.value.length}/50`) : ("0/50")}</label>
                                                        <Input
                                                            value={multiple_edit.value}
                                                            name={multiple_edit.item}
                                                            onChange={this.handleMultiEditChange} />
                                                    </Form.Field>
                                                )}
                                                {(multiple_edit.item === "no_vat_comment" || multiple_edit.item === "purchase_order") && (
                                                    <Form.Field>
                                                        <label>{this.handleGetMultiEditLabel(multiple_edit.item)} {multiple_edit.value ? (`${multiple_edit.value.length}/70`) : ("0/70")}</label>
                                                        <Input
                                                            value={multiple_edit.value}
                                                            name={multiple_edit.item}
                                                            onChange={this.handleMultiEditChange} />
                                                    </Form.Field>
                                                )}
                                                {(multiple_edit.item === "comment" || multiple_edit.item === "default_invoice_comment") && (
                                                    <Form.Field>
                                                        <label>{this.handleGetMultiEditLabel(multiple_edit.item)} {multiple_edit.value ? (`${multiple_edit.value.length}/100`) : ("0/100")}</label>
                                                        <Input
                                                            value={multiple_edit.value}
                                                            name={multiple_edit.item}
                                                            onChange={this.handleMultiEditChange} />
                                                    </Form.Field>
                                                )}
                                                {(multiple_edit.item === "payment_term") && (
                                                    <Form.Field>
                                                        <label style={{ paddingRight: "5px" }}>{this.handleGetMultiEditLabel(multiple_edit.item)}</label>
                                                        <Select
                                                            search
                                                            name={multiple_edit.item}
                                                            options={this.handleGetMultiEditSelectOptions(multiple_edit.item)}
                                                            onChange={this.handleMultiEditSelectChange}
                                                            value={multiple_edit.value}
                                                        />
                                                    </Form.Field>
                                                )}
                                                {(multiple_edit.item === "date" || multiple_edit.item === "due_date" || multiple_edit.item === "booking_date" || multiple_edit.item === "date_sent" || multiple_edit.item === "date_paid") && (
                                                    <Form.Field>
                                                        <label style={{ paddingRight: "5px" }}>{this.handleGetMultiEditLabel(multiple_edit.item)}</label>
                                                        <SemanticDatepicker
                                                            firstDayOfWeek="1"
                                                            size="mini"
                                                            disabled={loading}
                                                            locale={fiLocale}
                                                            placeholder='DD.MM.YYYY'
                                                            onDateChange={value => this.handleMultiEditDateChange(value)}
                                                            format='DD.MM.YYYY'
                                                            keepOpenOnClear={true}
                                                            datePickerOnly={true}
                                                            showOutsideDays={true}
                                                        />
                                                        {/* <Select 
                                                                search
                                                                name={multiple_edit.item}
                                                                options={this.handleGetMultiEditSelectOptions(multiple_edit.item)}
                                                                onChange={this.handleMultiEditSelectChange}
                                                                value={multiple_edit.value}
                                                            /> */}
                                                    </Form.Field>
                                                )}
                                            </>
                                        )}
                                    </Form>
                                    {multiple_edit_error && <Message error header={t("Error")} content={multiple_edit_error} />}
                                </>
                            </Modal.Content>
                            <Modal.Actions>
                                {multiple_edit_error === null && (
                                    <Button type="button" primary floated="left" content={t("Save")} onClick={(e) => this.handleCheckSubmitMultiEditConditions(e)} />
                                )}
                                <Button type="button" negative content={t("Cancel")} onClick={() => this.handleCloseMultiEditModal()} />
                            </Modal.Actions>
                        </Modal>

                    </Shell>
                )
                }
            </React.Fragment>
        );
    }
}
const mapStateToProps = state => {
    return {
        activeCompanyId: state.company.activeCompanyId,
        isAuthenticated: state.auth.token !== null
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setActivity: () => dispatch(setActivity())
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(withTranslation('invoices')(Invoices))
);