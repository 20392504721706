// Copyright Caproc Oy
// System name: Aqua-Net 
// System module: Company
// Author: TS
//
// 1.2.2020 TS Initial version

import React from "react";
import { Form, Input, Message, Header, Button, Checkbox } from "semantic-ui-react";
import Shell from "./Shell";
import { authAxios } from "../../utils";
import { setActivity } from "../../store/actions/auth";
import { withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { accountDetailsURL, updateAccountDetailsURL } from "../../constants";
import { withTranslation } from 'react-i18next';


class AccountDetails extends React.Component {
    state = {
        username: "",
        first_name: "",
        last_name: "",
        email: "",
        title: "",
        phone_number: "",
        is_superuser: "",
        send_invoice_sending_email: "",
        error: null,
        success_mgs: null,
        loading: false
    };

    componentDidMount() {
        this.props.setActivity();
        this.handleAccountDetails();
    }

    handleAccountDetails = () => {
        this.setState({
            loading: true
        });
        authAxios
            .get(accountDetailsURL)
            .then(res => {
                this.setState({
                    loading: false,
                    username: res.data.username,
                    first_name: res.data.first_name,
                    last_name: res.data.last_name,
                    email: res.data.email,
                    title: res.data.title,
                    phone_number: res.data.phone_number,
                    is_superuser: res.data.is_superuser,
                    send_invoice_sending_email: res.data.send_invoice_sending_email
                });
            })
            .catch(err => {
                // console.log(err);
                this.setState({
                    loading: false,
                    success_mgs: null,
                    error: err
                });
            });
    };

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value,
            success_mgs: null,
            error: null
        });
    };

    handleCheckboxChange = (e, { name, checked }) => {
        this.setState({
            [name]: checked
        });
    };

    handleSubmit = e => {
        e.preventDefault();
        this.setState({
            loading: true
        });
        const { username,
            first_name,
            last_name,
            email,
            title,
            phone_number,
            is_superuser,
            send_invoice_sending_email
        } = this.state;
        const { t } = this.props;
        if (username === "") {
            this.setState({
                error: t("Please fill in username"),
                success_mgs: null,
                loading: false
            });
        } else if (email === "") {
            this.setState({
                error: t("Please fill in email"),
                success_mgs: null,
                loading: false
            });
        } else if (title.length > 30) {
            this.setState({
                error: t("Max amount of characters is 30 for Title"),
                success_mgs: null,
                loading: false
            });
        } else if (phone_number.length > 30) {
            this.setState({
                error: t("Max amount of characters is 30 for Phone"),
                success_mgs: null,
                loading: false
            });
        } else {
            authAxios
                .post(updateAccountDetailsURL, {
                    username,
                    first_name,
                    last_name,
                    email,
                    title,
                    phone_number,
                    is_superuser,
                    send_invoice_sending_email
                })
                .then(res => {
                    this.setState({
                        loading: false,
                        success_mgs: t("Account updated successfully"),
                        error: null
                    });
                })
                .catch(err => {
                    this.setState({
                        loading: false,
                        success_mgs: null,
                        error: err
                    });
                });
        }
    };

    render() {
        const { username, first_name, last_name, email, title, phone_number, send_invoice_sending_email, error, success_mgs, loading } = this.state;
        const { isAuthenticated, t } = this.props;
        if (!isAuthenticated) {
            return <Redirect to="/login" />;
        }
        // console.log(this.state);
        return (
            <Shell>
                <Header as="h4">{t("Account details")}</Header>
                <Form error={error !== null}>
                    <Form.Field>
                        <label>{t("Username")}</label>
                        <Input
                            value={username}
                            name="username"
                            onChange={this.handleChange} />
                    </Form.Field>
                    <Form.Field>
                        <label>{t("First name")}</label>
                        <Input
                            value={first_name}
                            name="first_name"
                            onChange={this.handleChange} />
                    </Form.Field>
                    <Form.Field>
                        <label>{t("Last name")}</label>
                        <Input
                            value={last_name}
                            name="last_name"
                            onChange={this.handleChange} />
                    </Form.Field>
                    <Form.Field>
                        <label>{t("Email")}</label>
                        <Input
                            value={email}
                            name="email"
                            onChange={this.handleChange} />
                    </Form.Field>
                    <Form.Field>
                        <label>{t("Title")}</label>
                        <Input
                            value={title}
                            name="title"
                            onChange={this.handleChange} />
                    </Form.Field>
                    <Form.Field>
                        <label>{t("Phone number")}</label>
                        <Input
                            value={phone_number}
                            name="phone_number"
                            onChange={this.handleChange} />
                    </Form.Field>
                    <Form.Field>
                        <Checkbox
                            label={<label>{t("Send invoice sending report email")}</label>}
                            name="send_invoice_sending_email"
                            onChange={this.handleCheckboxChange}
                            checked={send_invoice_sending_email}
                        />
                    </Form.Field>
                    {error && <Message error header={t("Error")} content={error} />}
                    {success_mgs && <Message positive header={success_mgs} />}
                    <Button primary type="button" onClick={(e) => this.handleSubmit(e)} loading={loading} disabled={loading}>
                        {t("Save")}
                    </Button>
                </Form>
            </Shell>
        );
    }
}
const mapStateToProps = state => {
    return {
        isAuthenticated: state.auth.token !== null
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setActivity: () => dispatch(setActivity())
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(withTranslation('accountdetails')(AccountDetails))
);
