// Copyright Caproc Oy
// System name: Aqua-Net 
// System module: Customer
// Author: TS
//
// 1.2.2020 TS Initial version 
// 6.6.2020 TS Changed remove icon to trash bin

import React from "react";
import { Message, Header, Button, Table, Segment, Dimmer, Loader, Image, Confirm, Input, Icon } from "semantic-ui-react";
import Shell from "./Shell";
import { authAxios } from "../../utils";
import { setActivity } from "../../store/actions/auth";
import { withRouter, Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import { fetchCustomerGroupsURL, deleteCustomerGroupDetailsURL } from "../../constants";


class CustomerGroups extends React.Component {
    state = {
        data: null,
        delete_confirm_open: false,
        delete_confirm_text: "",
        delete_item: "",
        error: null,
        loading: false,
        search: ""
    };

    componentDidMount() {
        this.props.setActivity();
        this.handleFetchList();
    }

    handleFetchList = () => {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;
        authAxios
            .get(fetchCustomerGroupsURL(activeCompanyId))
            .then(res => {
                this.setState({
                    data: res.data,
                    error: null,
                    loading: false
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    error: err
                });
            });
    };

    customerGroupDetails = id => {
        this.props.history.push(`/customer/customer-group-details/${id}/`)
    };

    openDeleteConfirm = (text, delete_item) => {
        this.setState({
            delete_confirm_open: true,
            delete_confirm_text: text,
            delete_item: delete_item
        });
    };

    closeDeleteConfirm = () => {
        this.setState({
            delete_confirm_open: false,
            delete_confirm_text: "",
            delete_item: ""
        });
    };

    handleDelete = id => {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;
        authAxios
            .delete(deleteCustomerGroupDetailsURL(activeCompanyId, id))
            .then(res => {
                this.setState({
                    error: null,
                    loading: false
                });
                this.handleFetchList();
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    error: err
                });
            });
        this.setState({
            delete_confirm_open: false,
            delete_confirm_text: "",
            delete_item: ""
        });
    };

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    render() {
        const { data, error, loading, delete_confirm_open, delete_confirm_text, delete_item, search } = this.state;
        const { isAuthenticated, t } = this.props;
        if (!isAuthenticated) {
            return <Redirect to="/login" />;
        }
        // console.log(this.state);
        let filter_data = [];

        // Filter data for search
        if (data !== null) {
            filter_data = data.filter((group) => {
                return group.name.toLowerCase().indexOf(search.toLocaleLowerCase()) !== -1 || group.description.toLowerCase().indexOf(search.toLocaleLowerCase()) !== -1;
            })
        }
        return (
            <Shell>
                <Header as="h3">{t("Customer Groups")}</Header>
                <Link to="/customer/customer-group-details/create/">
                    <Button type="button" floated="right" color="green">
                        {t("+ Customer Group")}
                    </Button>
                </Link>

                {error && (
                    <Message
                        error
                        header={t("There was an error")}
                        content={JSON.stringify(error)}
                    />
                )}
                {loading && (
                    <Segment>
                        <Dimmer active inverted>
                            <Loader inverted>Loading</Loader>
                        </Dimmer>
                        <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
                    </Segment>
                )}
                {data && (
                    <React.Fragment>
                        <Input
                            icon='search'
                            value={search}
                            name="search"
                            onChange={this.handleChange} />
                        <Table striped selectable celled>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>{t("Group name")}</Table.HeaderCell>
                                    <Table.HeaderCell>{t("Customers in group (pcs)")}</Table.HeaderCell>
                                    <Table.HeaderCell />
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                {filter_data.map((customer_group, key) => {
                                    return (
                                        <Table.Row key={key}>
                                            <Table.Cell onClick={() => this.customerGroupDetails(customer_group.id)}>
                                                {customer_group.name === "Default group" ? t("Default group") : customer_group.name}
                                            </Table.Cell>
                                            <Table.Cell onClick={() => this.customerGroupDetails(customer_group.id)}>{customer_group.customers.length}</Table.Cell>
                                            {/* Default group should not be deleted */}
                                            {customer_group.name !== "Default group" && (
                                                <Table.Cell collapsing textAlign='center'>
                                                    <Icon link color="red" name='trash alternate outline' onClick={() => this.openDeleteConfirm(t("Are you sure your want to delete", { name: customer_group.name }), customer_group.id)}/>
                                                    {/* <Button type="button" compact negative size='mini' icon='remove' onClick={() => this.openDeleteConfirm(t("Are you sure your want to delete", { name: customer_group.name }), customer_group.id)} /> */}
                                                </Table.Cell>
                                            )}
                                        </Table.Row>
                                    );
                                })}
                            </Table.Body>
                        </Table>
                    </React.Fragment>
                )}
                <Confirm
                    content={delete_confirm_text}
                    open={delete_confirm_open}
                    onCancel={() => this.closeDeleteConfirm()}
                    onConfirm={() => this.handleDelete(delete_item)}
                />
            </Shell>
        );
    }
}
const mapStateToProps = state => {
    return {
        activeCompanyId: state.company.activeCompanyId,
        isAuthenticated: state.auth.token !== null
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setActivity: () => dispatch(setActivity())
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(withTranslation('customergroups')(CustomerGroups))
);