import React from "react";
import {
  Button,
  Form,
  Grid,
  Header,
  Segment,
  Modal,
  Icon
} from "semantic-ui-react";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import { logout, authSendResetPwdEmail } from "../store/actions/auth";

class PasswordChangeEmailForm extends React.Component {
  state = {
    email: "",
    open: false
  };

  componentDidMount() {
    let email;
    if (this.props.match.params.email !== "#") {
      email = this.props.match.params.email;
    } else {
      email = "";
    }
    this.setState({ email: email });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { email } = this.state;
    this.props.sendResetEmail(email);
    const { error } = this.state;
    if (!error) {
      this.setState({ open: true });
    }
  };

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleConfirm = e => {
    this.setState({ open: false })
    if (this.props.token) {
      this.props.logout();
    }
    this.props.history.push('/login/');
  };

  render() {
    const { email, open } = this.state;
    const { error, loading, t } = this.props;

    return (
      <Grid
        textAlign="center"
        style={{ height: "100vh" }}
        verticalAlign="middle"
      >
        <Grid.Column style={{ maxWidth: 450 }}>
          <Header as="h2" textAlign="center">
            {t("Please enter email address where to send password reset link")}
          </Header>
          {error && <p>{error.message}</p>}
          <React.Fragment>
            <Form size="large">
              <Segment stacked>
                <Form.Input
                  onChange={this.handleChange}
                  fluid
                  value={email}
                  name="email"
                  icon="mail"
                  iconPosition="left"
                  placeholder={t("E-mail address")}
                />

                <Button
                  primary
                  type="submit"
                  onClick={(e) => this.handleSubmit(e)}
                  fluid
                  size="large"
                  loading={loading}
                  disabled={loading}
                >
                  {t("Send")}
                </Button>
              </Segment>
            </Form>
            <Modal
              open={open}
              onClose={this.handleConfirm}
              basic
              size='small'
            >
              <Modal.Content>
                <h3>{t("Password reset email has been sent")}</h3>
              </Modal.Content>
              <Modal.Actions>
                <Button color='green' onClick={this.handleConfirm} inverted>
                  <Icon name='checkmark' > OK </Icon>
                </Button>
              </Modal.Actions>
            </Modal>
          </React.Fragment>
        </Grid.Column>
      </Grid>
    );
  }
}

const mapStateToProps = state => {
  return {
    loading: state.auth.loading,
    token: state.auth.token,
    error: state.auth.error
  };
};

const mapDispatchToProps = dispatch => {
  return {
    logout: () => dispatch(logout()),
    sendResetEmail: (email) => dispatch(authSendResetPwdEmail(email))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('passwordresetemail')(PasswordChangeEmailForm));
