// Copyright Caproc Oy
// System name: Aqua-Net 
// System module: Report
// Author: TS
//
// 1.6.2020 TS Initial version
// 2.6.2020 TS Changed to component instead of being part of Reporting shell. Used now from Customer Data collection and in Reports for the whole Company
// 7.6.2020 TS Added mobile version
// 10.6.2020 TS Added use of product report_name if available
// 20.6.2020 TS Added export to CSV

import React from "react";
import { Message, Header, Table, Segment, Dimmer, Loader, Image, Grid, Menu, Select, Modal, Button, Container, Responsive } from "semantic-ui-react";
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import fiLocale from 'react-semantic-ui-datepickers/dist/locales/fi-FI';
import { authAxios, getISOFormattedDate, getWidth, exportToCsv } from "../../utils";
import { setActivity } from "../../store/actions/auth";
import { withRouter, Redirect, Link } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import { reportDataCollectionURL,  reportDataCollectionDetailsURL, fetchFullDetailDataCollectionItemsURL} from "../../constants";
import { Bar} from 'react-chartjs-2';



class ReportDataCollectionItems extends React.Component {
    state = {
        report: [],
        activeMenuItem: this.props.t("Chart"),
        error: null,
        loading: false,
        search_start_date: null,
        search_end_date: null,
        search: "",
        fetch: false,
        chart_data: {},

        dc_product_options: [],
        dc_selected_product: null,
        dc_report_type_options: [],
        report_type: null,
        active_items: null,
        items_modal_open: false,
        total_sum: null,
        customer: null
    };

    componentDidMount() {
        this.props.setActivity();
        if (this.props.customer !== "all") {
            this.handleSetCustomer(this.props.customer);
        }
        this.handleGetDataCollectionProducts();
        this.handleReportTypeOptions();
        let init_start_date = new Date();
        init_start_date.setDate(1);
        init_start_date.setMonth(init_start_date.getMonth() - 1);
        this.handleStartDateChange(init_start_date);
        this.handleEndDateChange(new Date());
    }

    handleSetCustomer = customer => {
        this.setState({ customer: customer });
    };

    handleSelectChange = (e, { name, value }) => {
        this.setState({
            [name]: value,
            fetch: true
        });
    };

    handleReportTypeOptions = () => {
        const { t } = this.props;
        let options = [];
        options[0] = { key: "D", text: t("Daily"), value: "D"};
        options[1] = { key: "W", text: t("Weekly"), value: "W"};
        options[2] = { key: "M", text: t("Monthly"), value: "M"};
        this.setState({
            dc_report_type_options: options,
            report_type: options[0].value
        });
    };

    handleGetDataCollectionProducts = () => {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;
        let customer = null;
        if (this.props.customer !== "all") {
            customer = this.props.customer;
        }
        authAxios
            .get(reportDataCollectionDetailsURL(activeCompanyId, customer))
            .then(res => {
                const options = this.handleFormatDataCollectionDetailProducts(res.data);
                this.setState({
                    loading: false,
                    dc_product_options: options,
                    dc_selected_product: options[0].value,
                    fetch: true
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    error: err
                });
            });
    };

    handleFormatDataCollectionDetailProducts = dc_list => {
        let return_list = [];
        let id_list = [];
        let k = 0;
        for (k in dc_list) {
            const detail = {
                key: dc_list[k].product.id,
                text: dc_list[k].product.report_name !== "" ? dc_list[k].product.report_name : dc_list[k].product.name,
                value: dc_list[k].product
            }
            if (!id_list.includes(dc_list[k].product.id)) {
                return_list.push(detail);
            }
            id_list.push(dc_list[k].product.id);
        }
        return return_list;
    };

    handleMenuItemClick = (e, { name }) => this.setState({ activeMenuItem: name });

    handleFetchReport = () => {
        this.setState({
            loading: true
        });
        const { search_start_date, search_end_date, dc_selected_product, report_type, customer } = this.state;
        const { activeCompanyId } = this.props;

        authAxios
            .get(reportDataCollectionURL(activeCompanyId, report_type, search_start_date, search_end_date, dc_selected_product.id, customer))
            .then(res => {
                this.setState({
                    report: res.data,
                    error: null,
                    loading: false
                });
                this.handleTotals(res.data);
                this.handleChartData(res.data);
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    error: err
                });
            });
    };

    handleFetchFullDetailItems = (items_list) => {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;

        authAxios
            .get(fetchFullDetailDataCollectionItemsURL(activeCompanyId, items_list))
            .then(res => {
                this.setState({
                    loading: false,
                    active_items: res.data,
                    items_modal_open: true
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    error: err
                });
            });
    };

    handleChartData = report => {
        let {
            chart_data,
            report_type,
            total_sum,
            dc_selected_product
        } = this.state;
        const { t } = this.props;
        const default_dataset1 = {
            label: "",
            backgroundColor: 'rgb(78, 150, 220)',
            borderColor: 'rgb(255, 255, 255)',
            data: []
        };
        const default_dataset2 = {
            label: "",
            backgroundColor: 'rgb(100, 140, 180)',
            borderColor: 'rgb(255, 255, 255)',
            data: []
        };
        chart_data.labels = [];
        chart_data.datasets = [];

        let chart_data_dataset = {
            ...default_dataset1,
            label: `${t("Consumption")} (${dc_selected_product.measure_unit})`,
            average: 0,
            total_sum: 0,
            pcs: 0,
            highest: null,
            lowest: null
        };
        let i = 0;
        for (i in report) {
            if (report_type === "D") {
                chart_data.labels.push(new Date(report[i].date).toLocaleString('fi-FI', { dateStyle: 'short' }));
            } else if (report_type === "W") {
                chart_data.labels.push(`${report[i].week}/${report[i].year}`);
            } else if (report_type === "M") {
                chart_data.labels.push(`${report[i].month}/${report[i].year}`);
            }

            chart_data_dataset.data.push(report[i].total_amount);
            if (chart_data_dataset.highest === null || (parseFloat(chart_data_dataset.highest) < parseFloat(report[i].total_amount))) {
                chart_data_dataset.highest = parseFloat(report[i].total_amount);
            }
            if (chart_data_dataset.lowest === null || (parseFloat(chart_data_dataset.lowest) > parseFloat(report[i].total_amount))) {
                chart_data_dataset.lowest = parseFloat(report[i].total_amount);
            }
            chart_data_dataset.total_sum += parseFloat(report[i].total_amount);
            chart_data_dataset.pcs += 1;
        }
        if (chart_data_dataset.pcs > 0) {
            chart_data_dataset.average = parseFloat((chart_data_dataset.total_sum / chart_data_dataset.pcs).toFixed(2));
        }

        chart_data_dataset.label = 
        `${chart_data_dataset.label} (${t("avg")}: ${chart_data_dataset.average.toFixed(2)} ${t("Highest")}: ${chart_data_dataset.highest ? chart_data_dataset.highest.toFixed(2) : 0} ${t("Lowest")}: ${chart_data_dataset.lowest ? chart_data_dataset.lowest.toFixed(2) : 0})`; 

        chart_data.datasets.push(chart_data_dataset);
        this.setState({
            chart_data: chart_data
        });
    };

    handleTotals = report => {
        let { total_sum } = this.state;
        let i = 0;
        total_sum = 0;

        for (i in report) {
            total_sum += report[i].total_amount;
        }
        this.setState({
            total_sum
        });
    };

    handleStartDateChange = value => {
        this.setState({
            search_start_date: getISOFormattedDate(value, "Date"),
            fetch: true
        });

    };

    handleEndDateChange = value => {
        this.setState({
            search_end_date: getISOFormattedDate(value, "Date"),
            fetch: true
        });
    };

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    handleOpenModal = data => {
        if (data.dc_items_list.length > 0) {
            this.handleFetchFullDetailItems(data.dc_items_list);
        }
    };

    handleCloseModal = () => {
        this.setState({
            active_items: null,
            items_modal_open: false,
        });
    };

    handleOpenItemsList = item => {
        this.props.history.push(`/customer/${item.customer}/customer-data-collection/items/`)
    }

    handleModalTotal = items => {
        let i = 0;
        let total = 0;
        for (i in items) {
            total += parseFloat(items[i].data_value)
        }
        return total;
    };

    handleExportToCSV = items => {
        const { t } = this.props;
        const { report_type } = this.state;
        if (items !== null) {
            const titles = [
                t("Period"),
                t("Amount"),
                t("Measure unit")
            ];
            let items_to_csv = [];
            let i = 0;
            let period = "";
            for (i in items) {
                if (report_type === "D") {
                    period = new Date(items[i].date).toLocaleString('fi-FI', { dateStyle: 'short' })
                } else if (report_type === "W") {
                    period = `${items[i].week}/${items[i].year}`
                } else if (report_type === "M") {
                    period = `${new Intl.DateTimeFormat('fi-FI', { month: 'long' }).format(new Date().setMonth(parseInt(items[i].month) - 1))} ${items[i].year}`
                }
                items_to_csv.push({
                    period: period,
                    amount: items[i].total_amount.toFixed(0),
                    measure_unit: items[i].measure_unit 
                });
            }
            exportToCsv(this.props.activeCompanyId, "export", titles, items_to_csv);
        }
    };


    render() {
        const { error, loading, report, search_end_date, search_start_date, fetch, activeMenuItem, dc_report_type_options, report_type, dc_selected_product, dc_product_options, active_items, items_modal_open, total_sum, chart_data } = this.state;
        const { isAuthenticated, t } = this.props;
        const options = {
            scales: {
                yAxes: [
                    {
                        ticks: {
                            min: 0
                        },
                        stacked: false,
                        scaleLabel: {
                            display: true,
                            labelString: dc_selected_product && (dc_selected_product.measure_unit)
                        }  
                    }
                ],
                xAxes: [
                    {
                        stacked: false
                    }
                ]
            }
        }
        if (!isAuthenticated) {
            return <Redirect to="/login" />;
        }
        if (fetch) {
            if (search_start_date !== null && search_end_date !== null && report_type !== null && dc_selected_product !== null) {
                this.handleFetchReport();
            }
            this.setState({ fetch: false });
        }
        const is_desktop =  getWidth() > Responsive.onlyMobile.maxWidth ? true : false;
        // console.log(this.state);
        return (
            // <Shell>
            <React.Fragment>
                {is_desktop && (
                    <Button.Group floated="right" basic size='small'>
                        <Button icon="download" content="CSV" onClick={() => this.handleExportToCSV(report)} />
                    </Button.Group>
                )}
                <Header as="h3">{t("Data collection report")} </Header>
                {is_desktop ? (
                    <Grid columns="2">
                        <Grid.Row>
                            <Grid.Column width={8}>
                                <SemanticDatepicker
                                    firstDayOfWeek="1"
                                    size="mini"
                                    disabled={loading}
                                    locale={fiLocale}
                                    placeholder={search_start_date !== null ? (new Date(search_start_date).toLocaleString('fi-FI', { dateStyle: 'short' })) : ("")}
                                    onDateChange={selected => this.handleStartDateChange(selected)}
                                    format='DD.MM.YYYY' />
                                <SemanticDatepicker
                                    firstDayOfWeek="1"
                                    size="mini"
                                    disabled={loading}
                                    label="->"
                                    locale={fiLocale}
                                    placeholder={search_end_date !== null ? (new Date(search_end_date).toLocaleString('fi-FI', { dateStyle: 'short' })) : ("")}
                                    onDateChange={selected => this.handleEndDateChange(selected)}
                                    format='DD.MM.YYYY' />
                            </Grid.Column>
                            <Grid.Column floated="right" textAlign="right">
                                <label>{t("Report type")}: </label>
                                <Select
                                    search
                                    name='report_type'
                                    options={dc_report_type_options}
                                    placeholder={report_type}
                                    onChange={this.handleSelectChange}
                                    value={report_type}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column floated="right" textAlign="right">
                                <label>{t("Product")}: </label>
                                <Select
                                    search
                                    name='dc_selected_product'
                                    options={dc_product_options}
                                    placeholder={dc_selected_product}
                                    onChange={this.handleSelectChange}
                                    value={dc_selected_product}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                ) : (
                    <React.Fragment>
                        <SemanticDatepicker
                            firstDayOfWeek="1"
                            disabled={loading}
                            locale={fiLocale}
                            placeholder={search_start_date !== null ? (new Date(search_start_date).toLocaleString('fi-FI', { dateStyle: 'short' })) : ("")}
                            onDateChange={selected => this.handleStartDateChange(selected)}
                            format='DD.MM.YYYY' /><br/>
                        <SemanticDatepicker
                            firstDayOfWeek="1"
                            disabled={loading}
                            locale={fiLocale}
                            placeholder={search_end_date !== null ? (new Date(search_end_date).toLocaleString('fi-FI', { dateStyle: 'short' })) : ("")}
                            onDateChange={selected => this.handleEndDateChange(selected)}
                            format='DD.MM.YYYY' /><br/>
                        <label>{t("Report type")}</label><br/>
                        <Select
                            fluid
                            search
                            name='report_type'
                            options={dc_report_type_options}
                            placeholder={report_type}
                            onChange={this.handleSelectChange}
                            value={report_type}
                        />
                        <label>{t("Product")}</label><br/>
                        <Select
                            fluid
                            search
                            name='dc_selected_product'
                            options={dc_product_options}
                            placeholder={dc_selected_product}
                            onChange={this.handleSelectChange}
                            value={dc_selected_product}
                        />
                        <hr/>
                    </React.Fragment>
                )}
                {error && (
                    <Message
                        error
                        header={t("There was an error")}
                        content={JSON.stringify(error)}
                    />
                )}
                {loading && (
                    <Segment>
                        <Dimmer active inverted>
                            <Loader inverted>Loading</Loader>
                        </Dimmer>
                        <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
                    </Segment>
                )}
                {report && (
                    <React.Fragment>
                         {/*
                        <Menu attached="top" tabular>
                            <Menu.Item
                                name={t("Table")}
                                active={activeMenuItem === t("Table")}
                                onClick={this.handleMenuItemClick} />
                            <Menu.Item
                                name={t("Chart")}
                                active={activeMenuItem === t("Chart")}
                                onClick={this.handleMenuItemClick} />
                        </Menu>
                        {(activeMenuItem === t("Table")) ? (
                            <Table striped selectable celled>
                                <Table.Header >
                                    <Table.Row textAlign="right">
                                        <Table.HeaderCell textAlign="left">{t("Period")}</Table.HeaderCell>
                                        <Table.HeaderCell>{t("Amount")}</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {report.map((data, key) => {
                                        return (
                                            <Table.Row key={key} textAlign="right" onClick={() => this.handleOpenModal(data)} >
                                                <Table.Cell textAlign="left">
                                                    {report_type === "D" && (new Date(data.date).toLocaleString('fi-FI', { dateStyle: 'short' }))}
                                                    {report_type === "W" && (`${data.week}/${data.year}`)}
                                                    {report_type === "M" && (`${new Intl.DateTimeFormat('fi-FI', { month: 'long' }).format(new Date().setMonth(parseInt(data.month) - 1))} ${data.year}`)}
                                                    
                                                </Table.Cell>
                                                <Table.Cell><Header as="h5">{data.total_amount.toFixed(0)} {data.measure_unit}</Header></Table.Cell>
                                            </Table.Row>
                                        );
                                    })}
                                    {total_sum && (
                                        <Table.Row textAlign="right" >
                                            <Table.Cell />
                                            <Table.Cell><Header as="h5">{total_sum} {dc_selected_product.measure_unit}</Header></Table.Cell>
                                        </Table.Row>
                                    )}
                                </Table.Body>
                            </Table>
                        ) : ( */}
                                <React.Fragment>
                                    <Header textAlign="center" as="h3">{dc_selected_product && (dc_selected_product.report_name !== "" ? dc_selected_product.report_name : dc_selected_product.name)}</Header>
                                    <Bar data={chart_data} options={options} />
                                    <Header textAlign="center" as="h4">{t("Total")} {total_sum && total_sum.toFixed(2)} {dc_selected_product && (dc_selected_product.measure_unit)}</Header>
                                    {/* <Header textAlign="center" as="h3">{t("Profit and cost")}</Header>
                                    <Bar data={this.state.profit_cost_chart_data} options={options} />
                                    <Header textAlign="center" as="h3">{t("Profit and cost percentage")}</Header>
                                    <Bar data={this.state.profit_cost_percent_chart_data} options={options} />
                                    <Header textAlign="center" as="h3">{t("Net and tax")}</Header>
                                    <Bar data={this.state.net_tax_chart_data} options={options} /> */}
                                </React.Fragment>
                            {/* )} */}
                    </React.Fragment>
                )}
                <React.Fragment>
                    {active_items && (
                        <Modal size='large' open={items_modal_open} dimmer="inverted" scrolling="true">
                            <Modal.Header>
                                <Button type="button" floated="right" negative onClick={() => this.handleCloseModal()} >{t("Close")}</Button>   
                                <Header as="h4">{t("Data collection items")}</Header>
                                <Header as="h4">{active_items.length} {t("pcs")}</Header>
                            </Modal.Header>
                            <Modal.Content>
                                <Container>
                                    <Table striped selectable sortable celled >
                                        {is_desktop && (
                                            <Table.Header>
                                                <Table.Row >
                                                    <Table.HeaderCell>{t("Item added")}</Table.HeaderCell>
                                                    <Table.HeaderCell>{t("Value date")}</Table.HeaderCell>
                                                    <Table.HeaderCell>{t("Source")}</Table.HeaderCell>
                                                    <Table.HeaderCell>{t("Location")}</Table.HeaderCell>
                                                    <Table.HeaderCell>{t("Author")}</Table.HeaderCell>
                                                    <Table.HeaderCell>{t("Value")}</Table.HeaderCell>
                                                </Table.Row>
                                            </Table.Header>
                                        )}      
                                        <Table.Body>
                                            {active_items.map(item => {
                                                return (
                                                    <Table.Row key={item.id} onClick={() => this.handleOpenItemsList(item)}>
                                                        <Table.Cell collapsing>
                                                            {!is_desktop && (<React.Fragment><b>{t("Item added")}</b><br/></React.Fragment>)}
                                                            {new Date(item.date_created).toLocaleString('fi-FI')}
                                                        </Table.Cell>
                                                        <Table.Cell collapsing>
                                                            {!is_desktop && (<React.Fragment><b>{t("Value date")}</b><br/></React.Fragment>)}
                                                            {new Date(item.value_date).toLocaleString('fi-FI', { dateStyle: 'short' })}
                                                        </Table.Cell>
                                                        <Table.Cell collapsing>
                                                            {!is_desktop && (<React.Fragment><b>{t("Source")}</b><br/></React.Fragment>)}
                                                            {item.source_id}
                                                        </Table.Cell>
                                                        <Table.Cell collapsing>
                                                            {!is_desktop && (<React.Fragment><b>{t("Location")}</b><br/></React.Fragment>)}
                                                            {item.location_id}
                                                        </Table.Cell>
                                                        <Table.Cell collapsing>
                                                            {!is_desktop && (<React.Fragment><b>{t("Author")}</b><br/></React.Fragment>)}
                                                            {item.author}
                                                        </Table.Cell>
                                                        <Table.Cell collapsing>
                                                            {!is_desktop && (<React.Fragment><b>{t("Value")}</b><br/></React.Fragment>)}
                                                            {item.data_value.toFixed(2)}
                                                        </Table.Cell>
                                                    </Table.Row>
                                                );
                                            })}
                                        </Table.Body>
                                    </Table>
                                </Container>
                            </Modal.Content>
                            <Modal.Actions>
                            <Button type="button" floated="right" negative onClick={() => this.handleCloseModal()} >{t("Close")}</Button>
                            </Modal.Actions>
                        </Modal>
                    )}
                </React.Fragment>
            </React.Fragment>
            // </Shell>
        );
    }
}
const mapStateToProps = state => {
    return {
        activeCompanyId: state.company.activeCompanyId,
        isAuthenticated: state.auth.token !== null
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setActivity: () => dispatch(setActivity())
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(withTranslation('reportdatacollectionitems')(ReportDataCollectionItems))
);