// Copyright Caproc Oy
// System name: Aqua-Net 
// System module: CustomerPortal
// Author: TS
//
// 10.7.2020 TS Initial version

import React from "react";
import {  Message, Header, Dimmer, Loader, Segment, Image, Card, Table, Grid, GridColumn } from "semantic-ui-react";
import Shell from "./Shell";
import { authAxios } from "../../utils";
import { setActivity } from "../../store/actions/auth";
import { withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import { customerFullDetailsURL, customerAddressesURL} from "../../constants";


class CustomerPortalDetails extends React.Component {
    state = {
        customer_id: "",
        name: "",
        customer: null,
        error: null,
        loading: false,
        invoicing_address: null
    };

    componentDidMount() {
        this.props.setActivity();
        this.handleFetchCustomer();
        this.handleFetchInvoicingAddress();
    }

    handleFetchCustomer = () => {
        this.setState({
            loading: true
        });
        const { activeCompanyId, activeRole } = this.props;
        const { customer_id } = this.props.match.params;

        authAxios
            .get(customerFullDetailsURL(activeCompanyId, customer_id))
            .then(res => {
                this.setState({
                    customer: res.data,
                    name: res.data.name,
                    customer_id: res.data.id,
                    error: null,
                    loading: false
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    error: err
                });
            });
        
    };

    handleFetchInvoicingAddress = () => {
        this.setState({
            loading: true
        });
        const { activeCompanyId, activeRole } = this.props;
        const { customer_id } = this.props.match.params;
        authAxios
            .get(customerAddressesURL(activeCompanyId, customer_id))
            .then(res => {
                this.setState({
                    invoicing_address: this.handleGetInvoicingAddress(res.data),
                    error: null,
                    loading: false
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    error: err
                });
            });
    };

    handleGetInvoicingAddress = data => {
        let i = 0;
        for (i in data) {
            if (data[i].address_type === "I") {
                return data[i];
            }
        }
    };

    render() {
        const {
            customer_id,
            customer,
            name,
            error,
            invoicing_address,
            loading,

        } = this.state;
        const { isAuthenticated, t, activeRole } = this.props;
        if (!isAuthenticated) {
            return <Redirect to="/login" />;
        }
        if (activeRole && activeRole.role !== "C") {
            return <Redirect to="/" />;
        }
        // console.log(this.state);
        // console.log(this.props);
        
        return (
            <Shell active_customer_id={customer_id} active_customer_name={name}>
                <React.Fragment>
                    {error && (
                        <Message
                            error
                            header={t("There was an error")}
                            content={JSON.stringify(error)}
                        />
                    )}
                    {loading && (
                        <Segment>
                            <Dimmer active inverted>
                                <Loader inverted>Loading</Loader>
                            </Dimmer>
                            <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
                        </Segment>
                    )}
                    {customer && (
                        <>
                            <Header as="h4">{t("Basic details")}</Header>
                            <Card fluid>
                                <Card.Content>
                                    <Card.Description>
                                        <Table basic="very" size="small">
                                            <Table.Body>
                                                <Table.Row >
                                                    <Table.Cell width="4"><Header as="h5">{t("Name")}</Header></Table.Cell>
                                                    <Table.Cell>{customer.name}</Table.Cell>
                                                </Table.Row>
                                                <Table.Row>
                                                    <Table.Cell width="4"><Header as="h5">{t("Customer number")}</Header></Table.Cell>
                                                    <Table.Cell>{customer.customer_number} </Table.Cell>
                                                </Table.Row>
                                                {customer.vat && (
                                                    <Table.Row>
                                                        <Table.Cell width="4"><Header as="h5">{t("Vat")}</Header></Table.Cell>
                                                        <Table.Cell>{customer.vat} </Table.Cell>
                                                    </Table.Row>
                                                )}
                                                <Table.Row>
                                                    <Table.Cell width="4"><Header as="h5">{t("Invoicing method")}</Header></Table.Cell>
                                                    <Table.Cell>
                                                        {customer.customerinvoicingdetail.invoicing_method === "P" && t("Paper")}
                                                        {customer.customerinvoicingdetail.invoicing_method === "E" && t("Email")}
                                                        {customer.customerinvoicingdetail.invoicing_method === "N" && t("Net Invoice")}
                                                    </Table.Cell>
                                                </Table.Row>
                                                <Table.Row>
                                                    <Table.Cell width="4"><Header as="h5">{t("Invoicing Email")}</Header></Table.Cell>
                                                    <Table.Cell>{customer.customerinvoicingdetail.invoicing_email}</Table.Cell>
                                                </Table.Row>
                                            </Table.Body>
                                        </Table>
                                    </Card.Description>
                                </Card.Content>
                            </Card>
                            <Header as="h4">{t("Address details")}</Header>
                            <Card fluid>
                                <Card.Content>
                                    <Card.Description>
                                        <Table  basic="very" size="small">
                                            <Table.Body>
                                                <Table.Row>
                                                    <Table.Cell width="4"><Header as="h5">{t("Address")}</Header></Table.Cell>
                                                    <Table.Cell>{customer.address} </Table.Cell>
                                                </Table.Row>
                                                <Table.Row>
                                                    <Table.Cell width="4"><Header as="h5">{t("Postal code")}</Header></Table.Cell>
                                                    <Table.Cell>{customer.postal_code}</Table.Cell>
                                                </Table.Row>
                                                <Table.Row>
                                                    <Table.Cell width="4"><Header as="h5">{t("City")}</Header></Table.Cell>
                                                    <Table.Cell>{customer.city}</Table.Cell>
                                                </Table.Row>
                                                <Table.Row>
                                                    <Table.Cell width="4"><Header as="h5">{t("Country")}</Header></Table.Cell>
                                                    <Table.Cell>{customer.country}</Table.Cell>
                                                </Table.Row>
                                            </Table.Body>
                                        </Table>
                                    </Card.Description>
                                </Card.Content>
                            </Card>
                            {invoicing_address && (
                                <React.Fragment>
                                    <Header as="h4">{t("Invoicing address")}</Header>
                                    <Card fluid>
                                        <Card.Content>
                                            <Card.Description>
                                                <Table  basic="very" size="small">
                                                    <Table.Body>
                                                        <Table.Row>
                                                            <Table.Cell width="4"><Header as="h5">{t("Name")}</Header></Table.Cell>
                                                            <Table.Cell>{invoicing_address.name} </Table.Cell>
                                                        </Table.Row>
                                                        <Table.Row>
                                                            <Table.Cell width="4"><Header as="h5">{t("Address")}</Header></Table.Cell>
                                                            <Table.Cell>{invoicing_address.address} </Table.Cell>
                                                        </Table.Row>
                                                        <Table.Row>
                                                            <Table.Cell width="4"><Header as="h5">{t("Postal code")}</Header></Table.Cell>
                                                            <Table.Cell>{invoicing_address.postal_code}</Table.Cell>
                                                        </Table.Row>
                                                        <Table.Row>
                                                            <Table.Cell width="4"><Header as="h5">{t("City")}</Header></Table.Cell>
                                                            <Table.Cell>{invoicing_address.city}</Table.Cell>
                                                        </Table.Row>
                                                        <Table.Row>
                                                            <Table.Cell width="4"><Header as="h5">{t("Country")}</Header></Table.Cell>
                                                            <Table.Cell>{invoicing_address.country}</Table.Cell>
                                                        </Table.Row>
                                                    </Table.Body>
                                                </Table>
                                            </Card.Description>
                                        </Card.Content>
                                    </Card>
                                </React.Fragment>
                            )}
                        </>
                    )}
                </React.Fragment>
            </Shell>

        );
    }
}
const mapStateToProps = state => {
    return {
        activeCompanyId: state.company.activeCompanyId,
        isAuthenticated: state.auth.token !== null,
        activeRole: state.company.activeRole
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setActivity: () => dispatch(setActivity())
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(withTranslation('customerportaldetails')(CustomerPortalDetails))
);