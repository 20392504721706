// Copyright Caproc Oy
// System name: Aqua-Net 
// System module: Customers
// Author: TS
//
// 1.2.2020 TS Initial version
// 11.4.2020 TS Added Name field as required
// 8.6.2020 TS Added character counters

import React from "react";
import { Form, Input, Message, Header, Button, Select, Checkbox, Dimmer, Loader, Segment, Image } from "semantic-ui-react";
import Shell from "./Shell";
import { authAxios } from "../../utils";
import { setActivity } from "../../store/actions/auth";
import { withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import { createCustomerDetailsURL, customerDetailsURL, updateCustomerDetailsURL, fetchCustomerGroupsURL, countriesURL } from "../../constants";


class customerDetails extends React.Component {
    state = {
        customer_id: null,
        company: "",
        name: "",
        description: "",
        vat: "",
        customer_number: "",
        customer_code: "",
        customer_group: "",
        address: "",
        postal_code: "",
        city: "",
        country: "",
        phone: "",
        email: "",
        homepage: "",
        is_active: false,
        customer_group_options: null,
        country_options: null,
        error: null,
        success_mgs: null,
        loading: false,
        component_loading: false,
        create: false
    };

    componentDidMount() {
        this.props.setActivity();
        this.setState({
            component_loading: true
        });
        this.handleGroups();
        this.fetchCountryOptions();
        if (this.props.match.params.customer_id === "create") {
            this.setState({
                create: true,
                is_active: true,
                country: "FI"
            })
        } else {
            this.handleDetails();
        }
        this.setState({
            component_loading: false
        });
    }

    fetchCountryOptions = () => {
        this.setState({
            loading: true,
        });
        authAxios
            .get(countriesURL)
            .then(res => {
                this.setState({
                    country_options: this.handleFormatCountries(res.data),
                    loading: false
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    success_mgs: null,
                    error: err
                });
            });
    };

    handleFormatCountries = countries => {
        const keys = Object.keys(countries);
        return keys.map(k => {
            return {
                key: k,
                text: countries[k],
                value: k
            };
        });
    };

    handleGroups = () => {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;
        authAxios
            .get(fetchCustomerGroupsURL(activeCompanyId))
            .then(res => {
                this.setState({
                    customer_group_options: this.handleFormatOptions(res.data),
                    error: null,
                    loading: false
                });
            })
            .catch(err => {
                this.setState({
                    customer_group: null,
                    loading: false,
                    error: err
                });
            });
    };

    handleFormatOptions = options => {
        const { t } = this.props;
        const keys = Object.keys(options);
        return keys.map(k => {
            return {
                key: options[k].id,
                text: options[k].name === "Default group" ? t("Default group") : options[k].name,
                value: options[k].id
            };
        });
    };

    handleDetails = () => {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;
        const { customer_id } = this.props.match.params;

        authAxios
            .get(customerDetailsURL(activeCompanyId, customer_id))
            .then(res => {
                this.setState({
                    customer_id: res.data.id,
                    company: res.data.company,
                    name: res.data.name,
                    description: res.data.description,
                    vat: res.data.vat,
                    customer_number: res.data.customer_number,
                    customer_code: res.data.customer_code,
                    customer_group: res.data.customer_group,
                    address: res.data.address,
                    postal_code: res.data.postal_code,
                    city: res.data.city,
                    country: res.data.country,
                    phone: res.data.phone,
                    email: res.data.email,
                    homepage: res.data.homepage,
                    is_active: res.data.is_active,
                    error: null,
                    success_mgs: null,
                    loading: false,
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    success_mgs: null,
                    error: err
                });
            });
    };

    handleSubmit = e => {
        e.preventDefault();
        this.setState({
            loading: true
        });
        const {
            vat,
            customer_number,
            customer_code,
            customer_group,
            address,
            postal_code,
            city,
            country,
            phone,
            email,
            homepage,
            name,
            description,
            is_active,
            customer_id,
            create
        } = this.state;
        const { activeCompanyId, t } = this.props;
        if (name === "") {
            this.setState({
                error: t("Please fill in name"),
                success_mgs: null,
                loading: false
            });
        } else if (customer_group === "") {
            this.setState({
                error: t("Please select Customer group"),
                success_mgs: null,
                loading: false
            });
        } else if (vat.length > 35) {
            this.setState({
                error: t("Max amount of characters is 50 for Vat"),
                success_mgs: null,
                loading: false
            });
        } else if (name.length > 100) {
            this.setState({
                error: t("Max amount of characters is 100 for Name"),
                success_mgs: null,
                loading: false
            });
        } else if (address.length > 105) {
            this.setState({
                error: t("Max amount of characters is 150 for Address"),
                success_mgs: null,
                loading: false
            });
        } else if (postal_code.length > 35) {
            this.setState({
                error: t("Max amount of characters is 50 for Postal code"),
                success_mgs: null,
                loading: false
            });
        } else if (city.length > 35) {
            this.setState({
                error: t("Max amount of characters is 50 for City"),
                success_mgs: null,
                loading: false
            });
        } else if (phone.length > 20) {
            this.setState({
                error: t("Max amount of characters is 20 for Phone"),
                success_mgs: null,
                loading: false
            });
        } else if (homepage.length > 50) {
            this.setState({
                error: t("Max amount of characters is 50 for Homepage"),
                success_mgs: null,
                loading: false
            });
        } else if (customer_code.length > 35) {
            this.setState({
                error: t("Max amount of characters is 20 for Customer code"),
                success_mgs: null,
                loading: false
            });
        } else {
            if (create) {
                authAxios
                    .post(createCustomerDetailsURL(activeCompanyId), {
                        company: activeCompanyId,
                        vat,
                        customer_number,
                        customer_code,
                        customer_group,
                        address,
                        postal_code,
                        city,
                        country,
                        phone,
                        email,
                        homepage,
                        name,
                        description,
                        is_active,
                        author: localStorage.getItem("UserID")
                    })
                    .then(res => {
                        // Goto Customers page 
                        this.props.history.push("/customers/");

                    })
                    .catch(err => {
                        this.setState({
                            loading: false,
                            success_mgs: null,
                            error: err
                        });
                    });

            } else {
                authAxios
                    .put(updateCustomerDetailsURL(activeCompanyId, customer_id), {
                        company: activeCompanyId,
                        vat,
                        customer_number,
                        customer_code,
                        customer_group,
                        address,
                        postal_code,
                        city,
                        country,
                        phone,
                        email,
                        homepage,
                        name,
                        description,
                        is_active
                    })
                    .then(res => {
                        this.setState({
                            loading: false,
                            success_mgs: t("Customer updated successfully"),
                            error: null
                        });
                    })
                    .catch(err => {
                        this.setState({
                            loading: false,
                            success_mgs: null,
                            error: err
                        });
                    });
            }
        }
    };

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value,
            success_mgs: null,
            error: null
        });
    };

    handleSelectChange = (e, { name, value }) => {
        this.setState({
            [name]: value
        });
    };

    handleCheckboxChange = (e, { name, checked }) => {
        this.setState({
            [name]: checked
        });
    };

    render() {
        const {
            customer_id,
            customer_group,
            customer_group_options,
            country_options,
            vat,
            customer_code,
            address,
            postal_code,
            city,
            country,
            phone,
            email,
            homepage,
            name,
            description,
            is_active,
            error,
            success_mgs,
            loading,
            component_loading,
            create

        } = this.state;
        const { isAuthenticated, t } = this.props;
        if (!isAuthenticated) {
            return <Redirect to="/login" />;
        }
        // console.log(this.state);
        return (
            <Shell active_customer_id={customer_id} active_customer_name={name}>

                {component_loading ? (
                    <Segment>
                        <Dimmer active inverted>
                            <Loader inverted>Loading</Loader>
                        </Dimmer>
                        <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
                    </Segment>

                ) : (
                        <React.Fragment>
                            {/* {error && (
                                <Message
                                    error
                                    header="There was an error"
                                    content={JSON.stringify(error)}
                                />
                            )} */}
                            {loading && (
                                <Segment>
                                    <Dimmer active inverted>
                                        <Loader inverted>Loading</Loader>
                                    </Dimmer>
                                    <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
                                </Segment>
                            )}


                            <Header as="h4">{t("Customer details")}</Header>
                            <Form error={error !== null}>
                                <Form.Field required>
                                    <label>{t("Name")} {name ? (`${name.length}/70`) : ("0/70")}</label>
                                    <Input
                                        required
                                        value={name}
                                        name="name"
                                        onChange={this.handleChange} />
                                </Form.Field>
                                <Form.Field>
                                    <label>{t("VAT")} {vat ? (`${vat.length}/35`) : ("0/35")}</label>
                                    <Input
                                        value={vat}
                                        name="vat"
                                        onChange={this.handleChange} />
                                </Form.Field>
                                <Form.Field>
                                    <label>{t("Description")} {description ? (`${description.length}/100`) : ("0/100")}</label>
                                    <Input
                                        value={description}
                                        name="description"
                                        onChange={this.handleChange} />
                                </Form.Field>
                                <Form.Field>
                                    <label>{t("Customer code")} {customer_code ? (`${customer_code.length}/35`) : ("0/35")}</label>
                                    <Input
                                        value={customer_code}
                                        name="customer_code"
                                        onChange={this.handleChange} />
                                </Form.Field>
                                <Form.Field required>
                                    <label>{t("Address")} {address ? (`${address.length}/105`) : ("0/105")}</label>
                                    <Input
                                        required
                                        value={address}
                                        name="address"
                                        onChange={this.handleChange} />
                                </Form.Field>
                                <Form.Field required>
                                    <label>{t("Postal code")} {postal_code ? (`${postal_code.length}/35`) : ("0/35")}</label>
                                    <Input
                                        required
                                        value={postal_code}
                                        name="postal_code"
                                        onChange={this.handleChange} />
                                </Form.Field>
                                <Form.Field required>
                                    <label>{t("City")} {city ? (`${city.length}/35`) : ("0/35")}</label>
                                    <Input
                                        required
                                        value={city}
                                        name="city"
                                        onChange={this.handleChange} />
                                </Form.Field>
                                {country_options && (
                                    <Form.Field required>
                                        <label>{t("Country")}</label>
                                        <Select
                                            required
                                            search
                                            fluid
                                            name='country'
                                            options={country_options}
                                            placeholder={country}
                                            onChange={this.handleSelectChange}
                                            value={country}
                                        />
                                    </Form.Field>
                                )}
                                <Form.Field>
                                    <label>{t("Phone")} {phone ? (`${phone.length}/35`) : ("0/35")}</label>
                                    <Input
                                        value={phone}
                                        name="phone"
                                        onChange={this.handleChange} />
                                </Form.Field>
                                <Form.Field>
                                    <label>{t("Email")}  {email ? (`${email.length}/70`) : ("0/70")}</label>
                                    <Input
                                        value={email}
                                        name="email"
                                        onChange={this.handleChange} />
                                </Form.Field>
                                <Form.Field>
                                    <label>{t("Homepage")} {homepage ? (`${homepage.length}/70`) : ("0/70")}</label>
                                    <Input
                                        value={homepage}
                                        name="homepage"
                                        onChange={this.handleChange} />
                                </Form.Field>
                                {customer_group_options && (
                                    <Form.Field required>
                                        <label>{t("Customer group")}</label>
                                        <Select
                                            required
                                            search
                                            fluid
                                            name='customer_group'
                                            options={customer_group_options}
                                            placeholder={customer_group.toString()}
                                            onChange={this.handleSelectChange}
                                            value={customer_group}
                                        />
                                    </Form.Field>
                                )}
                                <Form.Field>
                                    <Checkbox
                                        label={<label>{t("Is active")}</label>}
                                        name="is_active"
                                        onChange={this.handleCheckboxChange}
                                        checked={is_active}
                                    />
                                </Form.Field>

                                {error && <Message error header={t("Error")} content={error} />}
                                {success_mgs && <Message positive header={success_mgs} />}
                                <Button primary type="button" onClick={(e) => this.handleSubmit(e)} loading={loading} disabled={loading}>
                                    {create ? (t("Create")) : (t("Save"))}
                                </Button>
                            </Form>
                        </React.Fragment>
                    )}
            </Shell>

        );
    }
}
const mapStateToProps = state => {
    return {
        activeCompanyId: state.company.activeCompanyId,
        isAuthenticated: state.auth.token !== null
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setActivity: () => dispatch(setActivity())
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(withTranslation('customerdetails')(customerDetails))
);