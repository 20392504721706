// Copyright Caproc Oy
// System name: Aqua-Net 
// System module: Report
// Author: TS
//
// 2.6.2020 TS Initial version

import React from "react";
import Shell from "./Shell";
import { Radio, Segment, Container }from "semantic-ui-react";
import { authAxios } from "../../utils";
import { withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import ReportDataCollectionItems from "../Report/ReportDataCollectionItems";
import {  customerDetailsURL } from "../../constants";



class CustomerDataCollectionReport extends React.Component {
    
    state = {
        customer_name: null,
        screen_size: "normal"
    };


    componentDidMount() {
        this.fetchCustomerDetails();
    }

    fetchCustomerDetails = () => {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;
        const { customer_id } = this.props.match.params;

        authAxios
            .get(customerDetailsURL(activeCompanyId, customer_id))
            .then(res => {
                this.setState({
                    customer_name: res.data.name,
                });
            })
            .catch(err => {
            });
    };

    handleRadioChange = (e, { name, value }) => {
        this.setState({
            [name]: value
        });
    };

    radioButtons = () => {
        return (
            <React.Fragment>
                <Radio
                    name="screen_size"
                    label={this.props.t("Normal")}
                    onChange={this.handleRadioChange}
                    checked={this.state.screen_size === "normal"}
                    value="normal"
                />
                &nbsp; &nbsp;
                <Radio
                    name="screen_size"
                    label={this.props.t("Wide")}
                    onChange={this.handleRadioChange}
                    checked={this.state.screen_size === "wide"}
                    value="wide"
                />
                &nbsp; &nbsp;
                <Radio
                    name="screen_size"
                    label={this.props.t("Full screen")}
                    onChange={this.handleRadioChange}
                    checked={this.state.screen_size === "full"}
                    value="full"
                />  
            </React.Fragment>
        );
    };

    render() {
        const { isAuthenticated } = this.props;
        if (!isAuthenticated) {
            return <Redirect to="/login" />;
        }
        return (
            <React.Fragment>
                {this.state.screen_size === "normal" && (
                    <Shell active_customer_id={this.props.match.params.customer_id} active_customer_name={this.state.customer_name}>
                        <this.radioButtons/>  
                        <ReportDataCollectionItems customer = {this.props.match.params.customer_id} />
                    </Shell>
                )}
                {this.state.screen_size === "wide" && (
                    <Container>
                        <Segment vertical>
                            <this.radioButtons/>    
                            <ReportDataCollectionItems customer = {this.props.match.params.customer_id} />
                        </Segment>
                    </Container>
                )}
                {this.state.screen_size === "full" && (
                    <React.Fragment>
                        <this.radioButtons/>
                        <ReportDataCollectionItems customer = {this.props.match.params.customer_id} />
                    </React.Fragment>
                )}
            </React.Fragment>
            
        );
    }
}

const mapStateToProps = state => {
    return {
        activeCompanyId: state.company.activeCompanyId,
        isAuthenticated: state.auth.token !== null
    };
};


export default withRouter(
    connect(
        mapStateToProps,
        null
    )(withTranslation('customerdatacollectionreport')(CustomerDataCollectionReport))
);