// Copyright Caproc Oy
// System name: Aqua-Net 
// System module: Contract
// Author: TS
//
// 1.2.2020 TS Initial version
// 28.3.2020 TS Added InvoiceAbnormalPayment icon in contract invoices 
// 31.3.2020 TS Changed Basic and Invoice details to Table. This ensures better responsivity on mobile phones.
// 12.5.2020 TS Added Invoice comment and VAT 0% comment in to contract details
// 19.5.2020 TS Modfied invoices list and fixed link to invoice edit
// 28.5.2020 TS Added contract number on invoice info in to contract details modal
// 4.6.2020 TS Added search. List in mobile mode instead of table
// 5.6.2020 TS Added mobile views
// 18.6.2020 TS Added export to csv. Changed contract group contract to be with minimum information. Full details are fetched if contract is opened.
// 24.8.2020 TS Changed from group categories to one list with group selection in dropdown
// 24.8.2020 TS Added sort by column
// 25.8.2020 TS Added multiple edit
// 3.5.2021 TS Added Contracts amount into Active and Archive tabs
// 5.8.2021 TS Pick only is_active or !is_active in select all depending on Active or Arcive selection.


import React from "react";
import _ from 'lodash'
import { Message, Header, Button, Table, Segment, Dimmer, Loader, Image, Menu, Confirm, Modal, Grid, GridColumn, Card, Container, Icon, Popup, List, Responsive, Input, Select, Checkbox, Form } from "semantic-ui-react";
import Shell from "./Shell";
import { authAxios, getItemGrossPrice, getItemNetPrice, getTotalGrossPrice, getTotalNetPrice, getWidth, exportToCsv } from "../../utils";
import { setActivity } from "../../store/actions/auth";
import { withRouter, Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import {  deleteContractDetailsURL, updateContractDetailsURL, contractFullDetailsURL, fetchContractsInGroupURL, fetchPlainContractGroupsURL, saveMultipleEditContractsURL } from "../../constants";


class Contracts extends React.Component {
    state = {
        contract_groups: null,
        contracts: null,
        selected_contract_group: "all",
        contract_group_options: [],
        activeMenuItem: this.props.t("Active"),
        delete_confirm_open: false,
        delete_confirm_text: "",
        delete_item: "",
        active_contract: null,
        error: null,
        loading: false,
        refresh_contracts: false,
        search: "",
        column: null,
        direction: null,
        select_all: false,
        selected: [],
        multiple_edit: {
            item: null,
            value: null
        },
        multiple_edit_item_options: [],
        multiple_edit_modal_open: false,
        multiple_edit_error: null,
        multiple_edit_confirm_open: false,
        multiple_edit_confirm_text: "",
        multiple_edit_confirm_item: null,
    };

    componentDidMount() {
        this.props.setActivity();
        this.handleFetchContractGroupList();
        this.handleFetchContractsInGroup("all");
        // this.handleFetchGroupList();
    }

    handleFetchFullContractDetails = (id) => {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;
        authAxios
            .get(contractFullDetailsURL(activeCompanyId, id))
            .then(res => {
                this.setState({
                    loading: false,
                    active_contract: res.data,
                    modal_open: true
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    error: err
                });
            });
    };

    handleSelectChange = (e, { name, value }) => {
        this.setState({
            [name]: value,
            refresh_contracts: true
        });
    };

    handleFetchContractGroupList = () => {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;
        authAxios
            .get(fetchPlainContractGroupsURL(activeCompanyId))
            .then(res => {
                this.setState({
                    contract_group_options: this.handleFormatOptions(res.data),
                    error: null,
                    loading: false
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    error: err
                });
            });
    };

    handleFormatOptions = options => {
        const { t } = this.props;
        let i = 0;
        let options_list = [];
        options_list.push({
            key: 0,
            text: t("All"),
            value: "all"
        })
        for (i in options) {
            options_list.push({
                key: options[i].id,
                text: t(options[i].name),
                value: options[i].id
            })
        }
        return options_list;
    };


    handleFetchContractsInGroup = () => {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;
        authAxios
            .get(fetchContractsInGroupURL(activeCompanyId, this.state.selected_contract_group))
            .then(res => {
                this.setState({
                    contracts: res.data,
                    error: null,
                    loading: false
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    error: err
                });
            });
    };

    handleMultiEditSelectChange = (e, { name, value }) => {
        let updatedValue;
        if (name === "item") {
            updatedValue = {
                ...this.state.multiple_edit,
                item: value,
                value: null
            }
        } else if (name === "contract_group") {
            updatedValue = {
                ...this.state.multiple_edit,
                value: value
            }
        } else if (name === "payment_term") {
            updatedValue = {
                ...this.state.multiple_edit,
                value: value
            }
        }
        this.setState({
            multiple_edit: updatedValue,
            multiple_edit_error: null,
        });
    };

    handleMultiEditChange = e => {
        const { t } = this.props;
        if (e.target.name === "discount" && isNaN(e.target.value)) {
            this.setState({
                multiple_edit_error: t("Discount value is not number")
            });
        } else {
            this.setState({
                multiple_edit_error: null
            });
        }
        const updatedValue = {
            ...this.state.multiple_edit,
            value: e.target.value
        }
        this.setState({
            multiple_edit: updatedValue,
        });
    };

    handleMultiEditCheckboxChange = (e, { value, checked }) => {
        const updatedValue = {
            ...this.state.multiple_edit,
            value: checked
        }
        this.setState({
            multiple_edit: updatedValue,
            multiple_edit_error: null,
        });
    };

    handleMultiEditOptions = () => {
        const { t } = this.props;
        const options = [
            {
                key: "name",
                text: t("Name"),
                value: "name"
            },
            {
                key: "contract_number_on_invoice",
                text: t("Contract number on invoice"),
                value: "contract_number_on_invoice"
            },
            {
                key: "comment",
                text: t("Invoice comment"),
                value: "comment"
            },
            {
                key: "no_vat_comment",
                text: t("VAT 0% comment"),
                value: "no_vat_comment"
            },
            {
                key: "payment_term",
                text: t("Payment term"),
                value: "payment_term"
            },
            {
                key: "customer_reference",
                text: t("Customer reference"),
                value: "customer_reference"
            },
            {
                key: "company_reference",
                text: t("Company reference"),
                value: "company_reference"
            },
            {
                key: "contract_group",
                text: t("Contract group"),
                value: "contract_group"
            },
            {
                key: "purchase_order",
                text: t("Purchase order"),
                value: "purchase_order"
            }
        ];
        return options;
    };

    handleGetMultiEditSelectOptions = item => {
        const { t } = this.props;
        let options = [];
        if (item === "contract_group") {
            options = [...this.state.contract_group_options];
            options.shift();
        } else if (item === "payment_term") {
            for (let i = 0; i < 365; i++) {
                options.push({
                    key: i,
                    text: i.toString(),
                    value: i
                });
            }
        }
        return options;
    };

    handleOpenMultiEditModal = () => {
        this.setState({ multiple_edit_modal_open: true });
    };

    handleCloseMultiEditModal = () => {
        this.setState({ 
            multiple_edit_modal_open: false,
            multiple_edit: {
                item: null,
                value: null
            },
            multiple_edit_confirm_open: false,
            multiple_edit_confirm_text: "",
            multiple_edit_confirm_item: null,
        });
    };

    handleGetMultiEditLabel = item => {
        const options = this.handleMultiEditOptions();
        let i = 0;
        for (i in options) {
            if (item === options[i].value) return options[i].text;
        }
    };

    handleSort = (clickedColumn) => () => {
        const { column, contracts, direction } = this.state

        if (column !== clickedColumn) {
            let sortedArray;
            if (clickedColumn === "name" || clickedColumn === "invoicing_type" || clickedColumn === "customer_name") {
                sortedArray = _.sortBy(contracts, [contract => contract[clickedColumn].toLowerCase()]);
            } else {
                sortedArray = _.sortBy(contracts, [clickedColumn]);
            }

            this.setState({
                column: clickedColumn,
                contracts: sortedArray,
                direction: 'ascending',
            });
            return;
        }

        this.setState({
            contracts: contracts.reverse(),
            direction: direction === 'ascending' ? 'descending' : 'ascending',
        })
    };

    handleSelectAll = contracts => {
        const { t } = this.props;
        if (!this.state.select_all) {
            let new_list = [];
            let i = 0;
            for (i in contracts) {
                if ((this.state.activeMenuItem.startsWith(t("Active")) && contracts[i].is_active)) {
                    new_list.push(contracts[i]);
                } else if ((this.state.activeMenuItem.startsWith(t("Archive")) && !contracts[i].is_active)) {
                    new_list.push(contracts[i]);
                }
            }
            this.setState({
                select_all: true,
                selected: new_list
            })
        }
        else this.setState({ select_all: false, selected: [] });
    };

    handleCheckboxChange = (e, { value, checked }) => {
        let { selected } = this.state;
        if (checked) {
            if (!selected.includes(value)) {
                selected.push(value);
            }
        } else {
            if (selected.includes(value)) {
                selected.splice(selected.indexOf(value), 1);
            }
        }
        this.setState({ selected });
    };


    handleDeleteContract = contract_id => {
        this.closeDeleteConfirm();
        if (contract_id === "selected"){
            this.handleChangeOrDeleteMultiple("delete");
        } else {
            this.setState({
                loading: true
            });
            const { activeCompanyId } = this.props;
            authAxios
                .delete(deleteContractDetailsURL(activeCompanyId, contract_id))
                .then(res => {
                    this.setState({
                        error: null,
                        loading: false,
                        refresh_contracts: true
                    });
                })
                .catch(err => {
                    this.setState({
                        loading: false,
                        error: err
                    });
                });
            this.setState({
                delete_confirm_open: false,
                delete_confirm_text: "",
                delete_item: ""
            });
        }
    };

    handleCheckSubmitMultiEditConditions = e => {
        const { t } = this.props;
        const { multiple_edit } = this.state;
        if (multiple_edit.item === null || multiple_edit.item === "") {
            this.setState({
                multiple_edit_error: t("Please select item to edit")
            });
        } else if (multiple_edit.value === null || multiple_edit.value === "") {
            this.setState({
                multiple_edit_confirm_open: true,
                multiple_edit_confirm_text: t("Do you want to update item value to 'empty'?"),
                multiple_edit_confirm_item: e
            });
        } else {
            this.handleSubmitMultiEdit(e);
        }
    }

    handleCloseMultipleEditConfirm = () => {
        this.setState({
            multiple_edit_confirm_open: false,
            multiple_edit_confirm_text: "",
            multiple_edit_confirm_item: null
        });
    };


    handleSubmitMultiEdit = e => {
        e.preventDefault();
        this.handleCloseMultipleEditConfirm();
        this.setState({
            loading: true
        });
        const { activeCompanyId, t } = this.props;
        const { multiple_edit, selected } = this.state;
        if (multiple_edit.item === null || multiple_edit.item === "") {
            this.setState({
                multiple_edit_error: t("Please select item to edit"),
                loading: false
            });
        } else if (selected.length === 0) {
            this.setState({
                multiple_edit_error: t("Please select contracts to edit"),
                loading: false
            });
        } else {
            // Create contracts array with only id's
            let contracts_to_edit = [];
            let i = 0;
            for (i in selected) {
                contracts_to_edit.push(selected[i].id);
            }

            authAxios
                .post(saveMultipleEditContractsURL(activeCompanyId), {
                    contracts: contracts_to_edit,
                    item: multiple_edit.item,
                    value: multiple_edit.value === null ? "" : multiple_edit.value
                })
                .then(res => {
                    this.handleCloseMultiEditModal();
                    this.setState({
                        refresh_contracts: true,
                        select_all: false,
                        selected: []
                    });
                })
                .catch(err => {
                    this.setState({
                        loading: false,
                        success_mgs: null,
                        multiple_edit_error: err.response.data
                    });
                });
        }
    };

    handleChangeOrDeleteMultiple = new_status => {
        const { selected } = this.state;
        let item = "";
        let value = "";
        if (new_status === "archive") {
            item = "is_active";
            value = false;
        } else if (new_status === "activate") {
            item = "is_active";
            value = true;
        } else if (new_status === "delete") {
            item = "delete";
            value = "delete";
        }
        // Create contracts array with only id's
        let contracts_to_edit = [];
        let i = 0;
        for (i in selected) {
            contracts_to_edit.push(selected[i].id);
        }
        const { activeCompanyId } = this.props;
        this.setState({ loading: true });
        authAxios
            .post(saveMultipleEditContractsURL(activeCompanyId), {
                contracts: contracts_to_edit,
                item: item,
                value: value
            })
            .then(res => {
                this.setState({
                    refresh_contracts: true,
                    select_all: false,
                    selected: []
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    success_mgs: null,
                    error: err.response.data
                });
            });
    };


    contractDetails = id => {
        this.props.history.push(`/contract/contract-details/${id}/`)
    };

    handleMenuItemClick = (e, { name }) => this.setState({ activeMenuItem: name, selected: [], select_all: false });

    openDeleteConfirm = (text, delete_item) => {
        this.setState({
            delete_confirm_open: true,
            delete_confirm_text: text,
            delete_item: delete_item
        });
    };

    closeDeleteConfirm = () => {
        this.setState({
            delete_confirm_open: false,
            delete_confirm_text: "",
            delete_item: ""
        });
    };

    openContractDetails = contract => {
        this.handleFetchFullContractDetails(contract.id);
    };

    closeModal() {
        this.setState({
            modal_open: false,
            active_contract: null
        });
    };

    checkIfValid = date => {
        const currentTime = new Date();
        // If end_date is not defined, the contract is permanent -> valid
        if (date !== null) {
            // Contract is valid if end_date is in the future.
            // The end_date is in format YYYY-MM-DD 00:00:00, so we need to reduce one day from current time to ensure the contract is valid
            // to the end of selected date. e.g. if end_date is 24.12.2019 it needs to be valid until the date turns in to 25.12.2019. 
            if (new Date(date) > currentTime.setDate(currentTime.getDate() - 1)) return true;
            else return false;
        } else return true;
    };

    invoiceDetails = id => {
        this.props.history.push(`/invoice/invoice-details/edit/${id}/`)
    };

    handleCheckInternalComments = invoice => {
        if (invoice.invoicecomments.length > 0) {
            let i = 0;
            for (i in invoice.invoicecomments) {
                if (invoice.invoicecomments[i].comment_type === "USER") {
                    return true;
                }
            }
            return false;
        } else {
            return false;
        }
    }

    handleCheckErrorComments = invoice => {
        if (invoice.invoicecomments.length > 0) {
            let i = 0;
            for (i in invoice.invoicecomments) {
                if (invoice.invoicecomments[i].comment_type === "ERROR" && !invoice.invoicecomments[i].is_acknowledged) {
                    return true;
                }
            }
            return false;
        } else {
            return false;
        }
    }

    getTotalNetPriceAllInvoices = invoices => {
        let i = 0;
        let net = 0;
        for (i in invoices) {
            net += getTotalNetPrice(invoices[i].invoiceitems);
        }
        return net;
    };

    getTotalGrossPriceAllInvoices = invoices => {
        let i = 0;
        let gross = 0;
        for (i in invoices) {
            gross += getTotalGrossPrice(invoices[i].invoiceitems);
        }
        return gross;
    };

    changeContractActiveStatus = contract => {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;
        const updatedContract = {
            ...contract,
            is_active: !contract.is_active
        }
        authAxios
            .put(updateContractDetailsURL(activeCompanyId, contract.id), {
                ...updatedContract
            })
            .then(res => {
                this.setState({
                    error: null,
                    loading: false,
                    refresh_contracts: true
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    error: err
                });
            });
    };

    handleGetAbnormalSum = invoice => {
        let i = 0;
        let sum = 0;
        for (i in invoice.invoiceabnormalpayments) {
            sum += invoice.invoiceabnormalpayments[i].paid_amount;
        } 
        return sum;
    };

    handleGetFilterList = items => {
        const { search } = this.state;
        let filtered_list = [];
        filtered_list = items.filter((item) => {
            return item.name.toLowerCase().indexOf(search.toLocaleLowerCase()) !== -1 ||
                item.number.toString().toLowerCase().indexOf(search.toLocaleLowerCase()) !== -1 ||
                item.customer_name.toLowerCase().indexOf(search.toLocaleLowerCase()) !== -1 ||
                item.start_date.toLowerCase().indexOf(search.toLocaleLowerCase()) !== -1 ||
                item.next_invoice_date.toLowerCase().indexOf(search.toLocaleLowerCase()) !== -1;
                
        })
        return filtered_list;
    };

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    handleExportToCSV = items => {
        const { t } = this.props;
        if (items !== null) {
            const titles = [
                t("Contract group"),
                t("Name"),
                t("Number"),
                t("Contract number on invoice"),
                t("Started"),
                t("Ending"),
                t("Automatic invoice sending"),
                `${t("Invoicing period")} (${t("months")})`,
                t("Invoicing type"),
                `${t("Invoice creation")} (${t("days before invoice date")})`,
                t("Next invoice date"),
                t("Next invoice create date"),
                t("Payment term"),
                t("Company reference"),
                t("Customer reference"),
                t("Purchase order"),
                t("Invoice comment"),
                t("VAT 0% comment"),
                t("Created"),
                t("Last modified"),
                t("Is active")
            ];
            // Get only active or archived depending on the selection.
            let exportItems = [];
            let i = 0;
            for (i in items) {
                if (this.state.activeMenuItem.startsWith(this.props.t("Active")) && items[i].is_active) {
                    exportItems.push(items[i]);
                } 
                else if (this.state.activeMenuItem.startsWith(this.props.t("Archive")) && !items[i].is_active) {
                    exportItems.push(items[i]);
                } 
            }

            exportToCsv(this.props.activeCompanyId, "contracts", titles, exportItems);
        }
    };

    handleGetFilterListToExport = groups => {
        let i = 0;
        let x = 0;
        let items = [];
        for (i in groups) {
            for (x in groups[i].contracts) {
                items.push(groups[i].contracts[x]);
            }
        }
        return this.handleGetFilterList(items);
    };

    handleAmountOfContracts = status => {
        const { contracts } = this.state;
        let amount = 0;
        let i = 0;
        for (i in contracts) {
            if (contracts[i].is_active && status === "active") {
                amount += 1;
            } else if (!contracts[i].is_active && status === "archive") {
                amount += 1;
            }
        }
        return amount;
    };

    render() {
        const { error, loading, search, column, direction, contracts, selected_contract_group, contract_group_options, active_contract, activeMenuItem, delete_confirm_open, delete_confirm_text, delete_item, modal_open, selected, multiple_edit_modal_open, multiple_edit, multiple_edit_error } = this.state;
        const { isAuthenticated, t } = this.props;
        if (!isAuthenticated) {
            return <Redirect to="/login" />;
        }
        if (this.state.refresh_contracts) {
            this.handleFetchContractGroupList();
            this.handleFetchContractsInGroup(selected_contract_group);
            this.closeModal();
            // this.handleFetchGroupList()
            this.setState({ refresh_contracts: false });
        }
        const is_desktop =  getWidth() > Responsive.onlyMobile.maxWidth ? true : false;
        // console.log(this.state);
        return (
            <Shell>
                {is_desktop && (
                    <Button.Group floated="right" basic size='small'>
                        <Button icon="download" content="CSV" onClick={() => this.handleExportToCSV(contracts)} />
                    </Button.Group>
                )}

                <Header as="h3">{t("Contracts")}</Header>
                <Link to="/contract/contract-details/create/">
                    <Button type="button" floated="right" color="green">
                        {t("+ Contract")}
                    </Button>
                </Link>
                <Input
                    floated="right"
                    size="mini"
                    icon='search'
                    value={search}
                    name="search"
                    onChange={this.handleChange} />
                {error && (
                    <Message
                        error
                        header={t("There was an error")}
                        content={JSON.stringify(error)}
                    />
                )}
                {loading && (
                    <Segment>
                        <Dimmer active inverted>
                            <Loader inverted>Loading</Loader>
                        </Dimmer>
                        <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
                    </Segment>
                )}

                {contracts && (
                    <React.Fragment>
                        <Menu attached="top" tabular>
                            <Menu.Item
                                name={`${t("Active")} ${this.handleAmountOfContracts("active")}`}
                                active={activeMenuItem.startsWith(t("Active"))}
                                onClick={this.handleMenuItemClick} />
                            <Menu.Item
                                name={`${t("Archive")} ${this.handleAmountOfContracts("archive")}`}
                                active={activeMenuItem.startsWith(t("Archive"))}
                                onClick={this.handleMenuItemClick} />
                        </Menu>
                        {is_desktop ? (
                            <>
                                <div style={{float: "right", paddingTop : "5px", paddingBottom: "5px"}}>
                                    <label style={{paddingRight: "5px"}}>{t("Contract group")}:</label>
                                    <Select 
                                        search
                                        name='selected_contract_group'
                                        options={contract_group_options}
                                        placeholder={t("All")}
                                        onChange={this.handleSelectChange}
                                        value={selected_contract_group}
                                    />
                                </div>
                                <Table striped selectable celled sortable>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell icon='check circle outline' collapsing onClick={() => this.handleSelectAll(this.handleGetFilterList(contracts))} content={!is_desktop ? (t("Select all")) : ("")} />
                                            {selected.length > 0 ? (
                                                <Button.Group style={{paddingTop: "5px", paddingLeft: "5px"}}>
                                                    <Button type="button" positive content={t("Edit selected")} icon="edit" onClick={() => this.handleOpenMultiEditModal()}/>
                                                    {activeMenuItem.startsWith(t("Active")) ? (
                                                        <Popup content={t("Deactive contracts and move in to archive")} trigger={
                                                            <Button type="button" negative icon='pause circle outline' content={t("Deactivate")} onClick={() => this.handleChangeOrDeleteMultiple("archive")} />
                                                        } />
                                                    ) : (
                                                        <React.Fragment>
                                                            <Popup content={t("Activate contracts")} trigger={
                                                                <Button type="button" positive icon='play circle outline' content={t("Activate")} onClick={() => this.handleChangeOrDeleteMultiple("activate")} />
                                                            } />
                                                            <Popup content={t("Deletes selected contracts")} trigger={
                                                                <Button type="button" negative icon='remove circle' content={t("Delete")} onClick={() => this.openDeleteConfirm(t("Are you sure you want to delete selected contracts?"), "selected")} />
                                                            } />
                                                        </React.Fragment>
                                                    )}
                                                </Button.Group>
                                            ) : (
                                                <>
                                                    <Table.HeaderCell 
                                                        sorted={column === 'number' ? direction : null}
                                                        onClick={this.handleSort('number')}>
                                                        {t("Number")}
                                                    </Table.HeaderCell>
                                                    <Table.HeaderCell 
                                                        sorted={column === 'customer_name' ? direction : null}
                                                        onClick={this.handleSort('customer_name')}>
                                                        {t("Customer")}
                                                    </Table.HeaderCell>
                                                    <Table.HeaderCell 
                                                        sorted={column === 'name' ? direction : null}
                                                        onClick={this.handleSort('name')}>
                                                        {t("Name")}
                                                    </Table.HeaderCell>
                                                    <Table.HeaderCell
                                                        sorted={column === 'invoicing_type' ? direction : null}
                                                        onClick={this.handleSort('invoicing_type')}>
                                                        {t("Invoicing type")}
                                                    </Table.HeaderCell>
                                                    <Table.HeaderCell
                                                        sorted={column === 'start_date' ? direction : null}
                                                        onClick={this.handleSort('start_date')}>
                                                        {t("Started")}
                                                    </Table.HeaderCell>
                                                    <Table.HeaderCell
                                                        sorted={column === 'end_date' ? direction : null}
                                                        onClick={this.handleSort('end_date')}>
                                                        {t("Ending")}
                                                    </Table.HeaderCell>
                                                    <Table.HeaderCell
                                                        sorted={column === 'next_invoice_date' ? direction : null}
                                                        onClick={this.handleSort('next_invoice_date')}>
                                                        {t("Next invoice")}
                                                    </Table.HeaderCell>
                                                </>
                                            )}
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {this.handleGetFilterList(contracts).map((contract) => {
                                            if ((activeMenuItem.startsWith(t("Active")) && contract.is_active) || (activeMenuItem.startsWith(t("Archive")) && !contract.is_active)) {
                                                return (
                                                    <Table.Row key={contract.id}>
                                                        <Table.Cell collapsing>
                                                            <Checkbox
                                                                value={contract}
                                                                checked={selected.includes(contract) === true}
                                                                onChange={this.handleCheckboxChange} />
                                                        </Table.Cell>
                                                        <Table.Cell collapsing onClick={() => this.openContractDetails(contract)}>{contract.number}</Table.Cell>
                                                        <Table.Cell collapsing onClick={() => this.openContractDetails(contract)}>{contract.customer_name}</Table.Cell>
                                                        <Table.Cell collapsing onClick={() => this.openContractDetails(contract)}>{contract.name}</Table.Cell>
                                                        <Table.Cell collapsing onClick={() => this.openContractDetails(contract)}>{contract.invoicing_type === "B" ? (t("Before period")) : (t("After period"))}</Table.Cell>
                                                        <Table.Cell collapsing onClick={() => this.openContractDetails(contract)}>{new Date(contract.start_date).toLocaleString('fi-FI', { dateStyle: 'short' })}</Table.Cell>
                                                        <Table.Cell collapsing onClick={() => this.openContractDetails(contract)}>{contract.end_date === null ? "" : new Date(contract.end_date).toLocaleString('fi-FI', { dateStyle: 'short' })}</Table.Cell>
                                                        <Table.Cell collapsing onClick={() => this.openContractDetails(contract)}>{new Date(contract.next_invoice_date).toLocaleString('fi-FI', { dateStyle: 'short' })}</Table.Cell>
                                                        {/* {activeMenuItem === t("Archive") && (
                                                            <Table.Cell collapsing textAlign='center'><Button type="button" compact negative size='mini' icon='remove'
                                                                onClick={() => this.openDeleteConfirm(t("Are you sure your want to delete", { contract_name: contract.name }), contract.id)} /></Table.Cell>
                                                        )} */}
                                                    </Table.Row>
                                                );
                                            }
                                        })}
                                    </Table.Body>
                                </Table>
                            </>
                        ) : (
                            <List  divided verticalAlign='middle'>
                                {this.handleGetFilterList(contracts).map((contract) => {
                                    if ((activeMenuItem.startsWith(t("Active")) && contract.is_active) || (activeMenuItem.startsWith(t("Archive")) && !contract.is_active)) {
                                        return (
                                            <React.Fragment>
                                                <List.Item as="a" onClick={() => this.openContractDetails(contract)}>
                                                    <List.Content>
                                                        <List.Header as="h4">{contract.customer_name} <Header as="h4" floated="right">({contract.number})</Header></List.Header>
                                                        <List.Header as="h5">{contract.name}</List.Header>
                                                        <List horizontal relaxed="very">
                                                            <List.Item>
                                                                <List.Description>{t("Started")}</List.Description>
                                                                <List.Header>{new Date(contract.start_date).toLocaleString('fi-FI', { dateStyle: 'short' })}</List.Header>
                                                            </List.Item>
                                                            <List.Item>
                                                                <List.Description>{t("Next invoice")}</List.Description>
                                                                <List.Header>{new Date(contract.next_invoice_date).toLocaleString('fi-FI', { dateStyle: 'short' })}</List.Header>
                                                            </List.Item>
                                                        </List>
                                                        {contract.end_date && (
                                                            <List.Item>
                                                                <List.Description>{t("Ending")}</List.Description>
                                                                <List.Header>{new Date(contract.end_date).toLocaleString('fi-FI', { dateStyle: 'short' })}</List.Header>
                                                            </List.Item>
                                                        )}
                                                    </List.Content>
                                                </List.Item>
                                            </React.Fragment>
                                        );
                                    }
                                })}
                            </List>
                        )}
                        <Confirm
                            content={delete_confirm_text}
                            open={delete_confirm_open}
                            onCancel={() => this.closeDeleteConfirm()}
                            onConfirm={() => this.handleDeleteContract(delete_item)}
                        />
                        <Confirm
                            content={this.state.multiple_edit_confirm_text}
                            open={this.state.multiple_edit_confirm_open}
                            onCancel={() => this.handleCloseMultipleEditConfirm()}
                            onConfirm={() => this.handleSubmitMultiEdit(this.state.multiple_edit_confirm_item)}
                        />

                    </React.Fragment>
                )}
                {active_contract && (
                    < Modal size='large' open={modal_open} dimmer="inverted" scrolling="true">
                        <Modal.Header>
                            <Header as="h3">{t("Contract")} {active_contract.name}</Header>
                            <Button type="button" positive icon='edit' content={t("Edit")} loading={loading} onClick={() => this.contractDetails(active_contract.id)} />
                            {is_desktop && (
                                <React.Fragment>
                                    {active_contract.is_active ? (
                                        <Popup content={t("Deactive contract and move in to archive. New invoices will not be created when the contract is deactivated")} trigger={
                                            <Button type="button" negative icon='pause circle outline' content={t("Deactivate")} onClick={() => this.changeContractActiveStatus(active_contract)} />
                                        } />
                                    ) : (
                                        <React.Fragment>
                                            <Popup content={t("Activate contract to create invoices again. Please check dates in the contract to make sure the next invoice create date is not in the past")} trigger={
                                                <Button type="button" positive icon='play circle outline' content={t("Activate")} onClick={() => this.changeContractActiveStatus(active_contract)} />
                                            } />
                                            <Popup content={t("Deletes contract and stops creating invoices to the contract.")} trigger={
                                                <Button type="button" negative icon='remove circle' content={t("Delete")} onClick={() => this.openDeleteConfirm(t("Are you sure you want to delete", { contract_name: active_contract.name }), active_contract.id)} />
                                            } />
                                        </React.Fragment>
                                    )}
                                </React.Fragment>
                            )}
                            <Button type="button" negative floated="right" onClick={() => this.closeModal()} >{t("Close")}</Button>
                        </Modal.Header>
                        <Modal.Content>
                            <Container>
                                {is_desktop ? (
                                    <React.Fragment>
                                        <Grid columns="2">
                                            <Grid.Row>
                                                <Grid.Column >
                                                    <Header as="h4">{t("Basic details")}</Header>
                                                    <Card fluid>
                                                        <Card.Content>
                                                            <Card.Description>
                                                                <Grid>
                                                                    <GridColumn>
                                                                        <Table  basic="very" size="small">
                                                                            <Table.Body>
                                                                                <Table.Row>
                                                                                    <Table.Cell><Header as="h5">{t("Number")}</Header></Table.Cell>
                                                                                    <Table.Cell>{active_contract.number} </Table.Cell>
                                                                                </Table.Row>
                                                                                <Table.Row>
                                                                                    <Table.Cell><Header as="h5">{t("Contract number on invoice")}</Header></Table.Cell>
                                                                                    <Table.Cell>{active_contract.contract_number_on_invoice}</Table.Cell>
                                                                                </Table.Row>
                                                                                <Table.Row>
                                                                                    <Table.Cell><Header as="h5">{t("Customer")}</Header></Table.Cell>
                                                                                    <Table.Cell>{active_contract.customer.name} </Table.Cell>
                                                                                </Table.Row>
                                                                                <Table.Row>
                                                                                    <Table.Cell><Header as="h5">{t("Started")}</Header></Table.Cell>
                                                                                    <Table.Cell>{new Date(active_contract.start_date).toLocaleString('fi-FI', { dateStyle: 'short' })}</Table.Cell>
                                                                                </Table.Row>
                                                                                <Table.Row>
                                                                                    <Table.Cell><Header as="h5">{t("Ending")}</Header></Table.Cell>
                                                                                    <Table.Cell>{active_contract.end_date === null ? ("") : (new Date(active_contract.end_date).toLocaleString('fi-FI', { dateStyle: 'short' }))}</Table.Cell>
                                                                                </Table.Row>
                                                                                <Table.Row>
                                                                                    <Table.Cell><Header as="h5">{t("Status")}</Header></Table.Cell>
                                                                                    <Table.Cell>{this.checkIfValid(active_contract.end_date) ? (t("Valid")) : (t("Not valid"))}  </Table.Cell>
                                                                                </Table.Row>
                                                                                <Table.Row>
                                                                                    <Table.Cell><Header as="h5">{t("Created")}</Header></Table.Cell>
                                                                                    <Table.Cell>{new Date(active_contract.date_created).toLocaleString('fi-FI')} </Table.Cell>
                                                                                </Table.Row>
                                                                                <Table.Row>
                                                                                    <Table.Cell><Header as="h5">{t("Last modified")}</Header></Table.Cell>
                                                                                    <Table.Cell>{new Date(active_contract.last_modified).toLocaleString('fi-FI')} </Table.Cell>
                                                                                </Table.Row>
                                                                                <Table.Row>
                                                                                    <Table.Cell><Header as="h5">{t("Is active")}</Header></Table.Cell>
                                                                                    <Table.Cell>{active_contract.is_active ? (t("Yes")) : (t("No"))} </Table.Cell>
                                                                                </Table.Row>
                                                                                <Table.Row>
                                                                                    <Table.Cell><Header as="h5">{t("Automatic invoice sending")}</Header></Table.Cell>
                                                                                    <Table.Cell>{active_contract.is_automatic_sending ? (
                                                                                        <React.Fragment>
                                                                                            {t("Yes")}
                                                                                            {active_contract.automatic_sending_mode === "A" && ("/" + t("Approved"))}
                                                                                            {active_contract.automatic_sending_mode === "*" && ("/" + t("Created and Approved"))}
                                                                                        </React.Fragment>
                                                                                        ) : (t("No"))}
                                                                                    </Table.Cell>
                                                                                </Table.Row>

                                                                            </Table.Body>

                                                                        </Table>
                                                                    </GridColumn>                                                            
                                                                </Grid>
                                                                {active_contract.no_vat_comment !== "" && (
                                                                    <React.Fragment>
                                                                        <br/>
                                                                        <br/>
                                                                    </React.Fragment>
                                                                )}
                                                            </Card.Description>
                                                        </Card.Content>
                                                    </Card>
                                                </Grid.Column>
                                                <Grid.Column >
                                                    <Header as="h4">{t("Invoicing details")}</Header>
                                                    <Card fluid>
                                                        <Card.Content>
                                                            <Card.Description>
                                                                <Grid>
                                                                    <GridColumn>
                                                                        <Table  basic="very" size="small">
                                                                            <Table.Body>
                                                                                <Table.Row>
                                                                                    <Table.Cell><Header as="h5">{t("Invoicing period")}</Header></Table.Cell>
                                                                                    <Table.Cell>{active_contract.invoicing_period_length} {t("months")} </Table.Cell>
                                                                                </Table.Row>
                                                                                <Table.Row>
                                                                                    <Table.Cell><Header as="h5">{t("Invoicing type")}</Header></Table.Cell>
                                                                                    <Table.Cell>{active_contract.invoicing_type === "B" ? (t("Before period")) : (t("After period"))}  </Table.Cell>
                                                                                </Table.Row>
                                                                                <Table.Row>
                                                                                    <Table.Cell><Header as="h5">{t("Invoice creation")}</Header></Table.Cell>
                                                                                    <Table.Cell>{active_contract.advance_create_days} {t("days before invoice date")}</Table.Cell>
                                                                                </Table.Row>
                                                                                <Table.Row>
                                                                                    <Table.Cell><Header as="h5">{t("Next invoice date")}</Header></Table.Cell>
                                                                                    <Table.Cell>{new Date(active_contract.next_invoice_date).toLocaleString('fi-FI', { dateStyle: 'short' })}</Table.Cell>
                                                                                </Table.Row>
                                                                                <Table.Row>
                                                                                    <Table.Cell><Header as="h5">{t("Next invoice create date")}</Header></Table.Cell>
                                                                                    <Table.Cell>{new Date(active_contract.next_invoice_create_date).toLocaleString('fi-FI', { dateStyle: 'short' })}  </Table.Cell>
                                                                                </Table.Row>
                                                                                <Table.Row>
                                                                                    <Table.Cell><Header as="h5">{t("Payment term")}</Header></Table.Cell>
                                                                                    <Table.Cell>{active_contract.payment_term} </Table.Cell>
                                                                                </Table.Row>
                                                                                <Table.Row>
                                                                                    <Table.Cell><Header as="h5">{t("Company reference")}</Header></Table.Cell>
                                                                                    <Table.Cell>{active_contract.company_reference} </Table.Cell>
                                                                                </Table.Row>
                                                                                <Table.Row>
                                                                                    <Table.Cell><Header as="h5">{t("Customer reference")}</Header></Table.Cell>
                                                                                    <Table.Cell>{active_contract.customer_reference} </Table.Cell>
                                                                                </Table.Row>
                                                                                <Table.Row>
                                                                                    <Table.Cell><Header as="h5">{t("Purchase order")}</Header></Table.Cell>
                                                                                    <Table.Cell>{active_contract.purchase_order} </Table.Cell>
                                                                                </Table.Row>
                                                                                <Table.Row>
                                                                                    <Table.Cell><Header as="h5">{t("Invoice comment")}</Header></Table.Cell>
                                                                                    <Table.Cell>{active_contract.comment} </Table.Cell>
                                                                                </Table.Row>
                                                                                {active_contract.no_vat_comment !== "" && (
                                                                                    <Table.Row>
                                                                                        <Table.Cell><Header as="h5">{t("VAT 0% comment")}</Header></Table.Cell>
                                                                                        <Table.Cell>{active_contract.no_vat_comment} </Table.Cell>
                                                                                    </Table.Row>
                                                                                )}

                                                                            </Table.Body>

                                                                        </Table>
                                                                    </GridColumn>
                                                                </Grid>
                                                            </Card.Description>
                                                        </Card.Content>
                                                    </Card>
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                        <br />
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        <Header as="h4">{t("Basic details")}</Header>
                                        <List divided>
                                            <List.Item>
                                                <List.Header as="h5">{t("Number")}</List.Header>
                                                <List.Description>{active_contract.number}</List.Description>
                                            </List.Item>
                                            <List.Item>
                                                <List.Header as="h5">{t("Contract number on invoice")}</List.Header>
                                                <List.Description>{active_contract.contract_number_on_invoice}</List.Description>
                                            </List.Item>
                                            <List.Item>
                                                <List.Header as="h5">{t("Customer")}</List.Header>
                                                <List.Description>{active_contract.customer.name}</List.Description>
                                            </List.Item>
                                            <List.Item>
                                                <List.Header as="h5">{t("Started")}</List.Header>
                                                <List.Description>{new Date(active_contract.start_date).toLocaleString('fi-FI', { dateStyle: 'short' })}</List.Description>
                                            </List.Item>
                                            {active_contract.end_date && (
                                                <List.Item>
                                                    <List.Header as="h5">{t("Ending")}</List.Header>
                                                    <List.Description>{active_contract.end_date === null ? ("") : (new Date(active_contract.end_date).toLocaleString('fi-FI', { dateStyle: 'short' }))}</List.Description>
                                                </List.Item>
                                            )}
                                            <List.Item>
                                                <List.Header as="h5">{t("Status")}</List.Header>
                                                <List.Description>{this.checkIfValid(active_contract.end_date) ? (t("Valid")) : (t("Not valid"))} </List.Description>
                                            </List.Item>
                                            <List.Item>
                                                <List.Header as="h5">{t("Created")}</List.Header>
                                                <List.Description>{new Date(active_contract.date_created).toLocaleString('fi-FI')} </List.Description>
                                            </List.Item>
                                            <List.Item>
                                                <List.Header as="h5">{t("Last modified")}</List.Header>
                                                <List.Description>{new Date(active_contract.last_modified).toLocaleString('fi-FI')}</List.Description>
                                            </List.Item>
                                            <List.Item>
                                                <List.Header as="h5">{t("Is active")}</List.Header>
                                                <List.Description>{active_contract.is_active ? (t("Yes")) : (t("No"))}</List.Description>
                                            </List.Item>
                                            <List.Item>
                                                <List.Header as="h5">{t("Automatic invoice sending")}</List.Header>
                                                <List.Description>
                                                    {active_contract.is_automatic_sending ? (
                                                        <React.Fragment>
                                                            {t("Yes")}
                                                            {active_contract.automatic_sending_mode === "A" && ("/" + t("Approved"))}
                                                            {active_contract.automatic_sending_mode === "*" && ("/" + t("Created and Approved"))}
                                                        </React.Fragment>
                                                    ) : (t("No"))}
                                                </List.Description>
                                            </List.Item>
                                        </List>
                                        <Header as="h4">{t("Invoicing details")}</Header>
                                        <List divided>
                                            <List.Item>
                                                <List.Header as="h5">{t("Invoicing period")}</List.Header>
                                                <List.Description>{active_contract.invoicing_period_length} {t("months")}</List.Description>
                                            </List.Item>
                                            <List.Item>
                                                <List.Header as="h5">{t("Invoicing type")}</List.Header>
                                                <List.Description>{active_contract.invoicing_type === "B" ? (t("Before period")) : (t("After period"))}</List.Description>
                                            </List.Item>
                                            <List.Item>
                                                <List.Header as="h5">{t("Invoice creation")}</List.Header>
                                                <List.Description>{active_contract.advance_create_days} {t("days before invoice date")}</List.Description>
                                            </List.Item>
                                            <List.Item>
                                                <List.Header as="h5">{t("Next invoice date")}</List.Header>
                                                <List.Description>{new Date(active_contract.next_invoice_date).toLocaleString('fi-FI', { dateStyle: 'short' })}</List.Description>
                                            </List.Item>
                                            <List.Item>
                                                <List.Header as="h5">{t("Payment term")}</List.Header>
                                                <List.Description>{active_contract.payment_term}</List.Description>
                                            </List.Item>
                                            <List.Item>
                                                <List.Header as="h5">{t("Company reference")}</List.Header>
                                                <List.Description>{active_contract.company_reference} </List.Description>
                                            </List.Item>
                                            <List.Item>
                                                <List.Header as="h5">{t("Customer reference")}</List.Header>
                                                <List.Description>{active_contract.customer_reference} </List.Description>
                                            </List.Item>
                                            <List.Item>
                                                <List.Header as="h5">{t("Purchase order")}</List.Header>
                                                <List.Description>{active_contract.purchase_order}</List.Description>
                                            </List.Item>
                                            <List.Item>
                                                <List.Header as="h5">{t("Invoice comment")}</List.Header>
                                                <List.Description>{active_contract.comment}</List.Description>
                                            </List.Item>
                                            {active_contract.no_vat_comment !== "" && (
                                                <List.Item>
                                                    <List.Header as="h5">{t("VAT 0% comment")}</List.Header>
                                                    <List.Description>{active_contract.no_vat_comment}</List.Description>
                                                </List.Item>
                                            )}
                                        </List>
                                        <br/>
                                    </React.Fragment>
                                )}
                            </Container>
                            <Container>
                                <Header as="h4">{t("Contract Items")}</Header>
                                {is_desktop ? (
                                    <Table striped selectable celled>
                                        <Table.Header>
                                            <Table.Row>
                                                <Table.HeaderCell>{t("Name")}</Table.HeaderCell>
                                                <Table.HeaderCell>{t("Amount")}</Table.HeaderCell>
                                                <Table.HeaderCell>{t("Net €")}</Table.HeaderCell>
                                                <Table.HeaderCell>{t("Discount %")}</Table.HeaderCell>
                                                <Table.HeaderCell>{t("Net total €")}</Table.HeaderCell>
                                                <Table.HeaderCell>{t("VAT %")}</Table.HeaderCell>
                                                <Table.HeaderCell textAlign="right">{t("Gross €")}</Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>

                                        {active_contract.contractitems.length > 0 && (
                                            <Table.Body>
                                                {active_contract.contractitems.map(item => {
                                                    return (
                                                        <Table.Row key={item.id} >
                                                            <Table.Cell collapsing>
                                                                {item.item_type === "D" && (<Popup content={t("Data collection product")} trigger={<Icon fitted name="cloud download" />} />)}
                                                                {item.recurring
                                                                    ? (<Popup content={t("Recurring product/invoiced periodically")} trigger={<Icon fitted name="sync" />} />)
                                                                    : (<Popup content={t("Product invoiced once")} trigger={<Icon fitted name="file alternate outline" />} />)}
                                                                &nbsp; <b>{item.name}</b><br />{item.description}
                                                            </Table.Cell>
                                                            <Table.Cell collapsing>{item.amount} {item.measure_unit}</Table.Cell>
                                                            <Table.Cell collapsing >{item.net_price}</Table.Cell>
                                                            <Table.Cell collapsing>{item.discount}</Table.Cell>
                                                            <Table.Cell collapsing >{getItemNetPrice(item).toFixed(2)} €</Table.Cell>
                                                            <Table.Cell collapsing >{item.vat_value}</Table.Cell>
                                                            <Table.Cell collapsing textAlign="right"><Header as="h5">{getItemGrossPrice(item).toFixed(2)} €</Header></Table.Cell>
                                                        </Table.Row>
                                                    );
                                                })}
                                                <Table.Row>
                                                    <Table.Cell />
                                                    <Table.Cell />
                                                    <Table.Cell />
                                                    <Table.Cell textAlign="right"><Header as="h5">{t("Total")}</Header></Table.Cell>
                                                    <Table.Cell >
                                                        <Header as="h5">({getTotalNetPrice(active_contract.contractitems).toFixed(2)} €)</Header>
                                                    </Table.Cell>
                                                    <Table.Cell />
                                                    <Table.Cell textAlign="right"><Header as="h5">{getTotalGrossPrice(active_contract.contractitems).toFixed(2)} €</Header></Table.Cell>
                                                </Table.Row>
                                            </Table.Body>
                                        )}
                                    </Table>
                                ) : (
                                    <React.Fragment>
                                        {active_contract.contractitems.length > 0 && (
                                            <List divided>
                                                {active_contract.contractitems.map(item => {
                                                    return (
                                                        <List.Item>
                                                            
                                                                {item.item_type === "D" && (<List.Icon verticalAlign="top" name="cloud download" />)}
                                                                {item.recurring
                                                                    ? (<List.Icon verticalAlign="top" name="sync" />)
                                                                    : (<List.Icon verticalAlign="top" name="file alternate outline" />)}
                                                                <List.Content>
                                                                    <List.Header>{item.name}</List.Header>
                                                                    <List.Description>{item.description}</List.Description>
                                                                </List.Content>
                                                                <List.Description>{t("Amount")}</List.Description>
                                                                <List.Header>{item.amount} {item.measure_unit}</List.Header>
                                                                <List.Description>{t("Net €")}</List.Description>
                                                                <List.Header>{item.net_price}</List.Header>
                                                                <List.Description>{t("Discount %")}</List.Description>
                                                                <List.Header>{item.discount}</List.Header>
                                                                <List.Description>{t("VAT %")}</List.Description>
                                                                <List.Header>{item.vat_value}</List.Header>
                                                                <List.Description>{t("Net total €")}</List.Description>
                                                                <List.Header>{getItemNetPrice(item).toFixed(2)} €</List.Header>
                                                                <List.Description>{t("Gross €")}</List.Description>
                                                                <List.Header>{getItemGrossPrice(item).toFixed(2)} €</List.Header>
                                                            
                                                        </List.Item>
                                                    );
                                                })}
                                            </List>
                                         )}
                                    </React.Fragment>
                                )}
                            </Container>
                            {active_contract.invoices.length > 0 && (
                                <React.Fragment>
                                    <br />
                                    <hr />
                                    <Container>
                                        {is_desktop ? (
                                            <React.Fragment>
                                                <Header as="h4">{t("Contract Invoices")}</Header>
                                                <Table striped selectable sortable celled unstackable>
                                                    <Table.Header>
                                                        <Table.Row>
                                                            <Table.HeaderCell>{t("Name")}</Table.HeaderCell>
                                                            <Table.HeaderCell>{t("Invoice date")}</Table.HeaderCell>
                                                            <Table.HeaderCell>{t("Due date")}</Table.HeaderCell>
                                                            <Table.HeaderCell>{t("Net €")}</Table.HeaderCell>
                                                            <Table.HeaderCell>{t("Gross €")}</Table.HeaderCell>
                                                            <Table.HeaderCell>{t("Status")}</Table.HeaderCell>
                                                        </Table.Row>
                                                    </Table.Header>

                                                    <Table.Body>
                                                        {active_contract.invoices.map(invoice => {
                                                            return (
                                                                <Table.Row key={invoice.id} onClick={() => this.invoiceDetails(invoice.id)}>
                                                                    <Table.Cell >
                                                                        <b>{invoice.customer.name}</b>
                                                                        <Header sub floated="right">({invoice.number})</Header><br />
                                                                        <span>{invoice.name}</span>
                                                                        {invoice.contract.number !== null && (
                                                                            <Link to={`/contract/contract-details/${invoice.contract.id}/`}>
                                                                                <Header sub color="blue" as="h6" floated="right">
                                                                                    <Popup content={`${t("Contract")} ${invoice.contract.name} (${invoice.contract.number})`} trigger={
                                                                                        <Icon fitted link name='sync alternate' size='tiny' />
                                                                                    } />
                                                                                </Header>
                                                                            </Link>
                                                                            )}
                                                                        {invoice.invoiceabnormalpayments.length > 0 && (
                                                                            <Header sub color={parseFloat((this.handleGetAbnormalSum(invoice) - getTotalGrossPrice(invoice.invoiceitems)).toFixed(2)) === parseFloat(0) ? "blue" : "red"} as="h6" floated="right">
                                                                                <Popup content={`${t("Abnormal payments. Current balance")} ${(this.handleGetAbnormalSum(invoice) - getTotalGrossPrice(invoice.invoiceitems)).toFixed(2)} €`} trigger={
                                                                                    <Icon fitted name='info' size='tiny' />
                                                                                } />
                                                                            </Header>
                                                                        )}
                                                                        {this.handleCheckInternalComments(invoice) && (<Popup content={t("Internal comments added")} trigger={<Header as="h6" floated="right" icon="comments outline" />} />)}
                                                                        {this.handleCheckErrorComments(invoice) && (<Popup content={t("Error comments in invoice")} trigger={<Header sub color="red" as="h6" floated="right" icon="attention" />} />)}
                                                                    </Table.Cell>
                                                                    <Table.Cell collapsing >{new Date(invoice.date).toLocaleString('fi-FI', { dateStyle: 'short' })}</Table.Cell>
                                                                    <Table.Cell collapsing >{new Date(invoice.due_date).toLocaleString('fi-FI', { dateStyle: 'short' })}</Table.Cell>
                                                                    <Table.Cell collapsing >{getTotalNetPrice(invoice.invoiceitems).toFixed(2)} €</Table.Cell>
                                                                    <Table.Cell collapsing ><Header as="h5">{getTotalGrossPrice(invoice.invoiceitems).toFixed(2)} €</Header></Table.Cell>
                                                                    <Table.Cell collapsing>
                                                                        <Header as="h5">
                                                                            {invoice.status === "C" && (t("Created status"))}
                                                                            {invoice.status === "A" && (t("Approved status"))}
                                                                            {invoice.status === "S" && (t("Sent status"))}
                                                                            {invoice.status === "P" && (t("Paid status"))}
                                                                            {invoice.status === "O" && (t("Overdue status"))}
                                                                        </Header>
                                                                    </Table.Cell>
                                                                </Table.Row>
                                                            );
                                                        })}
                                                        <Table.Row>
                                                            <Table.Cell />
                                                            <Table.Cell />
                                                            <Table.Cell />
                                                            <Table.Cell collapsing>
                                                                <Header as="h5">{this.getTotalNetPriceAllInvoices(active_contract.invoices).toFixed(2)} €</Header>
                                                            </Table.Cell>
                                                            <Table.Cell collapsing>
                                                                <Header as="h5">{this.getTotalGrossPriceAllInvoices(active_contract.invoices).toFixed(2)} €</Header>
                                                            </Table.Cell>
                                                            <Table.Cell />
                                                        </Table.Row>
                                                    </Table.Body>
                                                </Table>
                                            </React.Fragment>
                                        ) : (
                                            <React.Fragment>
                                                <List>
                                                    <List.Item>
                                                        <List.Header as="h4">{t("Contract Invoices")} </List.Header>
                                                        <List.Header as="h4">
                                                            {active_contract.invoices.length} {t("pcs")} ({this.getTotalNetPriceAllInvoices(active_contract.invoices).toFixed(2)} €) {this.getTotalGrossPriceAllInvoices(active_contract.invoices).toFixed(2)} €
                                                        </List.Header>
                                                    </List.Item>
                                                </List>
                                                <List  divided >
                                                    {active_contract.invoices.map(invoice => {
                                                        return (
                                                            <List.Item >
                                                                <List.Content>
                                                                    <List.Header>
                                                                        <b>{invoice.customer.name}</b>
                                                                        <Header sub floated="right">({invoice.number})</Header><br />
                                                                        <span>{invoice.name}</span>
                                                                        {invoice.contract.number !== null && (
                                                                            <Link to={`/contract/contract-details/${invoice.contract.id}/`}>
                                                                                <Header sub color="blue" as="h6" floated="right">
                                                                                    <Popup content={`${t("Contract")} ${invoice.contract.name} (${invoice.contract.number})`} trigger={
                                                                                        <Icon fitted link name='sync alternate' size='tiny' />
                                                                                    } />
                                                                                </Header>
                                                                            </Link>
                                                                            )}
                                                                        {invoice.invoiceabnormalpayments.length > 0 && (
                                                                            <Header sub color={parseFloat((this.handleGetAbnormalSum(invoice) - getTotalGrossPrice(invoice.invoiceitems)).toFixed(2)) === parseFloat(0) ? "blue" : "red"} as="h6" floated="right">
                                                                                <Popup content={`${t("Abnormal payments. Current balance")} ${(this.handleGetAbnormalSum(invoice) - getTotalGrossPrice(invoice.invoiceitems)).toFixed(2)} €`} trigger={
                                                                                    <Icon fitted name='info' size='tiny' />
                                                                                } />
                                                                            </Header>
                                                                        )}
                                                                        {this.handleCheckInternalComments(invoice) && (<Popup content={t("Internal comments added")} trigger={<Header as="h6" floated="right" icon="comments outline" />} />)}
                                                                        {this.handleCheckErrorComments(invoice) && (<Popup content={t("Error comments in invoice")} trigger={<Header sub color="red" as="h6" floated="right" icon="attention" />} />)}
                                                                    </List.Header>
                                                                </List.Content>
                                                                <List.Item>
                                                                <List horizontal relaxed="very">
                                                                    <List.Item>
                                                                        <List.Description>{t("Invoice date")}</List.Description>
                                                                        <List.Header>{new Date(invoice.date).toLocaleString('fi-FI', { dateStyle: 'short' })}</List.Header>
                                                                    </List.Item>
                                                                    <List.Item>
                                                                        <List.Description>{t("Due date")}</List.Description>
                                                                        <List.Header>{new Date(invoice.due_date).toLocaleString('fi-FI', { dateStyle: 'short' })}</List.Header>
                                                                    </List.Item>
                                                                </List>
                                                                </List.Item>
                                                                <List.Item>
                                                                <List horizontal relaxed="very">
                                                                    <List.Item>
                                                                        <List.Description>{t("Net €")}</List.Description>
                                                                        <List.Header>{getTotalNetPrice(invoice.invoiceitems).toFixed(2)} €</List.Header>
                                                                    </List.Item>
                                                                    <List.Item>
                                                                        <List.Description>{t("Gross €")}</List.Description>
                                                                        <List.Header>{getTotalGrossPrice(invoice.invoiceitems).toFixed(2)} €</List.Header>
                                                                    </List.Item>
                                                                    <List.Description>
                                                                        <Header color={invoice.status === "O" && ("red") || invoice.status === "P" && ("green")} as="h5">
                                                                            {invoice.status === "C" && (t("Created status"))}
                                                                            {invoice.status === "A" && (t("Approved status"))}
                                                                            {invoice.status === "S" && (t("Sent status"))}
                                                                            {invoice.status === "P" && (t("Paid status"))}
                                                                            {invoice.status === "O" && (t("Overdue status"))}
                                                                        </Header>
                                                                    </List.Description>
                                                                </List>
                                                                </List.Item>
                                                            </List.Item>
                                                        );
                                                    })}
                                                </List>
                                            </React.Fragment>
                                        )}
                                    </Container>
                                </React.Fragment>
                            )}
                        </Modal.Content>
                        <Modal.Actions>
                            <Button type="button" negative onClick={() => this.closeModal()} >{t("Close")}</Button>
                        </Modal.Actions>
                    </Modal >
                )
                }
                <Modal size="tiny" open={multiple_edit_modal_open} dimmer="inverted" >
                    <Modal.Header>
                        {t("Edit multiple contracts")}
                    </Modal.Header>
                    <Modal.Content>
                        <>
                            <Form>
                                <Form.Field>
                                    <Select 
                                        search
                                        name='item'
                                        options={this.handleMultiEditOptions()}
                                        placeholder={t("Select item to edit")}
                                        onChange={this.handleMultiEditSelectChange}
                                        value={multiple_edit.item && multiple_edit.item}
                                    />
                                </Form.Field>
                                {multiple_edit.item && (
                                    <>
                                        {(multiple_edit.item === "name" || multiple_edit.item === "contract_number_on_invoice" ) && (
                                            <Form.Field>
                                                <label>{this.handleGetMultiEditLabel(multiple_edit.item)} {multiple_edit.value ? (`${multiple_edit.value.length}/35`) : ("0/35")}</label>
                                                <Input
                                                    value={multiple_edit.value}
                                                    name={multiple_edit.item}
                                                    onChange={this.handleMultiEditChange} />
                                            </Form.Field>
                                        )}
                                        {(multiple_edit.item === "customer_reference" || multiple_edit.item === "company_reference") && (
                                            <Form.Field>
                                                <label>{this.handleGetMultiEditLabel(multiple_edit.item)} {multiple_edit.value ? (`${multiple_edit.value.length}/50`) : ("0/50")}</label>
                                                <Input
                                                    value={multiple_edit.value}
                                                    name={multiple_edit.item}
                                                    onChange={this.handleMultiEditChange} />
                                            </Form.Field>
                                        )}
                                        {( multiple_edit.item === "no_vat_comment" || multiple_edit.item === "purchase_order") && (
                                            <Form.Field>
                                                <label>{this.handleGetMultiEditLabel(multiple_edit.item)} {multiple_edit.value ? (`${multiple_edit.value.length}/70`) : ("0/70")}</label>
                                                <Input
                                                    value={multiple_edit.value}
                                                    name={multiple_edit.item}
                                                    onChange={this.handleMultiEditChange} />
                                            </Form.Field>
                                        )}
                                        {(multiple_edit.item === "comment" || multiple_edit.item === "default_invoice_comment" ) && (
                                            <Form.Field>
                                                <label>{this.handleGetMultiEditLabel(multiple_edit.item)} {multiple_edit.value ? (`${multiple_edit.value.length}/100`) : ("0/100")}</label>
                                                <Input
                                                    value={multiple_edit.value}
                                                    name={multiple_edit.item}
                                                    onChange={this.handleMultiEditChange} />
                                            </Form.Field>
                                        )}
                                        {(multiple_edit.item === "contract_group" || multiple_edit.item === "payment_term" ) && (
                                            <Form.Field>
                                                <label style={{paddingRight: "5px"}}>{this.handleGetMultiEditLabel(multiple_edit.item)}</label>
                                                    <Select 
                                                        search
                                                        name={multiple_edit.item}
                                                        options={this.handleGetMultiEditSelectOptions(multiple_edit.item)}
                                                        onChange={this.handleMultiEditSelectChange}
                                                        value={multiple_edit.value}
                                                    />
                                            </Form.Field>
                                        )}
                                    </>
                                )}
                            </Form>
                            {multiple_edit_error && <Message error header={t("Error")} content={multiple_edit_error} />}
                        </>
                    </Modal.Content>
                    <Modal.Actions>
                        {multiple_edit_error === null && (
                            <Button type="button" primary floated="left" content={t("Save")} onClick={(e) => this.handleCheckSubmitMultiEditConditions(e)}/>
                        )}
                        <Button type="button" negative content={t("Cancel")} onClick={() => this.handleCloseMultiEditModal()}/>
                    </Modal.Actions>
                </Modal>
            </Shell>
        );
    }
}
const mapStateToProps = state => {
    return {
        activeCompanyId: state.company.activeCompanyId,
        isAuthenticated: state.auth.token !== null
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setActivity: () => dispatch(setActivity())
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(withTranslation('contracts')(Contracts))
);