// Copyright Caproc Oy
// System name: Aqua-Net 
// System module: Company
// Author: TS
//
// 1.2.2020 TS Initial version
// 11.7.2020 TS Added Company Selection banner

import React from "react";
import { Grid, Container, Segment, Header, Menu, Icon } from "semantic-ui-react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTranslation } from 'react-i18next';

import { logout } from "../../store/actions/auth";
import { CompanySelection } from "../../utils";

const Shell = props => (
    <>
    <Container><CompanySelection/></Container>
    <Segment vertical>
        <Container>
            <Grid container stackable verticalAlign="top" divided columns={2}>
                <Grid.Row>
                    <Grid.Column width={4}>
                        <Header as="h3">{props.t("Account")}</Header>
                        <Menu vertical fluid icon="labeled" compact>
                            <Menu.Item
                                active={props.location.pathname === "/account/account-details"}
                                onClick={() => props.history.push("/account/account-details")}
                                name="account-details"
                            >
                                <Icon name="info"/> {props.t("Account details")}
                            </Menu.Item>
                            <Menu.Item
                                active={props.location.pathname === "/account/change-password"}
                                onClick={() => props.history.push("/account/change-password")}
                                name="change-password"
                            >
                                <Icon name="shield alternate"/> {props.t("Change password")}
                            </Menu.Item>
                            {(props.activeRoles && props.activeRoles.length > 1) && (
                                <Menu.Item
                                    active={props.location.pathname === "/account/role-select"}
                                    onClick={() => props.history.push("/account/role-select")}
                                    name="role-select"
                                >
                                    <Icon name="user secret"/> {props.t("Change role")} <br/> {props.activeRole.role === "C" ? (`(${props.t(props.activeRole.role_name)} / ${props.activeRole.customer.name})` ) : (`(${props.t(props.activeRole.role_name)})`)}
                                </Menu.Item>
                            )}
                        </Menu>
                    </Grid.Column>
                    <Grid.Column width={12}>{props.children}</Grid.Column>
                </Grid.Row>
            </Grid>
        </Container>
    </Segment>
    </>
);

const mapDispatchToProps = dispatch => {
    return {
        logout: () => dispatch(logout())
    };
};

const mapStateToProps = state => {
    return {
        activeRole: state.company.activeRole,
        activeRoles: state.company.activeRoles
    };
};
  
export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(withTranslation('accountshell')(Shell))
);