// Copyright Caproc Oy
// System name: Aqua-Net 
// System module: Company
// Author: TS
//
// 1.2.2020 TS Initial version

import React from "react";
import { Form, Input, Message, Header, Button, Select } from "semantic-ui-react";
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import fiLocale from 'react-semantic-ui-datepickers/dist/locales/fi-FI';
import Shell from "./Shell";
import { authAxios, getISOFormattedDate } from "../../utils";
import { setActivity } from "../../store/actions/auth";
import { withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import { createCustomerDataCollectionItemURL, customerDataCollectionsURL, fetchCustomersURL } from "../../constants";


class CompanyDataCollectionAddItem extends React.Component {
    state = {
        dc_item: {
            company: "",
            customer: "",
            customer_data_collection_detail: "",
            name: "",
            source_id: "",
            location_id: "",
            data_value: "",
            source_type: "M",
            author: "",
            value_date: "",
            received_message: ""
        },
        data_collection_options: null,
        fields_disabled: true,
        dc_disabled: true,
        error: null,
        success_mgs: null,
        loading: false,
        customer_name: null,
        customer_options: []
    };

    componentDidMount() {
        this.props.setActivity();
        this.handleFetchCustomers();
        // this.handleFetchDataCollectionsList();
        this.handleDateChange(new Date());
    }

    handleFetchCustomers() {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;

        authAxios
            .get(fetchCustomersURL(activeCompanyId))
            .then(res => {
                this.setState({
                    customer_options: this.handleCustomerFormatOptions(res.data),
                    error: null,
                    success_mgs: null,
                    loading: false,
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    success_mgs: null,
                    error: err
                });
            });
    };

    handleCustomerFormatOptions = options => {
        let i = 0;
        let options_list = [];
        for (i in options) {
            // Get only active ones..
            if (options[i].is_active) {
                options_list.push({
                    key: options[i].id,
                    text: options[i].name,
                    value: options[i].id
                })
            }
        }
        return options_list;
    };


    handleFetchDataCollectionsList = customer_id => {
        this.setState({
            loading: true,
        });
        const { activeCompanyId } = this.props;
        authAxios
            .get(customerDataCollectionsURL(activeCompanyId, customer_id))
            .then(res => {
                this.setState({
                    data_collection_options: this.handleFormatOptions(res.data),
                    dc_disabled: false,
                    error: null,
                    loading: false
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    error: err
                });
            });
    };

    handleFormatOptions = options => {
        let i = 0;
        let options_list = [];
        for (i in options) {
            // Get only active ones..
            if (options[i].is_active) {
                options_list.push({
                    key: options[i].id,
                    text: (options[i].product.name + " " + options[i].source_id),
                    value: options[i]
                })
            }
        }
        return options_list;
    };

    handleCustomerSelectChange = (e, { name, value }) => {
        this.handleFetchDataCollectionsList(value);
        const updatedItemDetails = {
            ...this.state.dc_item,
            customer: value
        };
        this.setState({
            dc_item: updatedItemDetails,
            error: null
        });
    };


    handleSelectChange = (e, { name, value }) => {
        const { dc_item } = this.state;
        let updatedItemDetails = {};
        if (name === "customer_data_collection_detail") {
            updatedItemDetails = {
                ...dc_item,
                [name]: value,
                name: value.product.name,
                source_id: value.source_id,
                location_id: value.location_id
            };
        } else {
            updatedItemDetails = {
                ...dc_item,
                [name]: value
            };
        }
        this.setState({
            fields_disabled: false,
            dc_item: updatedItemDetails,
            success_mgs: null,
            error: null
        });
    };


    handleChange = e => {
        const { dc_item } = this.state;
        const updatedItemDetails = {
            ...dc_item,
            [e.target.name]: e.target.value,
        };
        this.setState({
            dc_item: updatedItemDetails,
            success_mgs: null,
            error: null
        });
    };

    handleSubmit = e => {
        e.preventDefault();
        this.setState({
            loading: true
        });
        const { dc_item } = this.state;
        const { activeCompanyId, t } = this.props;
        if (dc_item.data_value === "") {
            this.setState({
                error: t("Please fill in Data value"),
                success_mgs: null,
                loading: false
            });
        } else if (dc_item.author.length > 100) {
            this.setState({
                error: t("Max amount of characters is 100 for Author"),
                success_mgs: null,
                loading: false
            });
        } else if (dc_item.data_value.length > 50) {
            this.setState({
                error: t("Max amount of characters is 50 for Value"),
                success_mgs: null,
                loading: false
            });
        } else {
            let author = dc_item.author;
            if (author === "") {
                author = localStorage.getItem("userFullName");
            }
            const updated_dc_item = {
                ...dc_item,
                company: activeCompanyId,
                customer_data_collection_detail: dc_item.customer_data_collection_detail.id,
                author,
                added_by: localStorage.getItem("UserID")
            }
            authAxios
                .post(createCustomerDataCollectionItemURL(activeCompanyId, dc_item.customer), {
                    ...updated_dc_item
                })
                .then(res => {
                    this.props.history.push(`/company/customer-data-collection/items/`)
                })
                .catch(err => {
                    this.setState({
                        loading: false,
                        success_mgs: null,
                        error: err
                    });
                });

        }
    };

    handleDateChange = value => {
        const { dc_item } = this.state;
        const updatedItemDetails = {
            ...dc_item,
            value_date: getISOFormattedDate(value, "Date"),
        };
        this.setState({
            dc_item: updatedItemDetails
        });
    };

    render() {
        const {
            dc_item,
            data_collection_options,
            fields_disabled,
            dc_disabled,
            customer_options,
            error,
            success_mgs,
            loading,
            customer_name
        } = this.state;
        const { customer_id } = this.props.match.params;
        const { isAuthenticated, t } = this.props;
        if (!isAuthenticated) {
            return <Redirect to="/login" />;
        }
        // console.log(this.state);
        return (
            <Shell active_customer_id={customer_id} active_customer_name={customer_name}>
                <Header as="h4">{t("Add data collection item")}</Header>
                <Form error={error !== null}>
                    {customer_options && (
                        <Form.Field required>
                            <label>{t("Select customer")}</label>
                            <Select
                                disabled={loading }
                                fluid
                                search
                                name='customer'
                                options={customer_options}
                                placeholder={t("Customer")}
                                onChange={this.handleCustomerSelectChange}
                                value={dc_item.customer}
                            />
                        </Form.Field>
                    )}
                    {data_collection_options && (
                        <Form.Field>
                            <label>{t("Data collection product")}</label>
                            <Select
                                search
                                disabled={loading || dc_disabled}
                                fluid
                                name='customer_data_collection_detail'
                                options={data_collection_options}
                                placeholder={t("Data collection product")}
                                onChange={this.handleSelectChange}
                                value={dc_item.customer_data_collection_detail}
                            />
                        </Form.Field>
                    )}
                    {!fields_disabled && (
                        <React.Fragment>
                            <label>{t("Source id")}</label>
                            <Header as="h4">{dc_item.source_id}</Header>
                            <label>{t("Location id")}</label>
                            <Header as="h4">{dc_item.location_id}</Header>
                        </React.Fragment>
                    )}

                    {/* <Form.Field>
                        <label>{t("Source id")}</label>
                        <Input
                            disabled={loading || fields_disabled}
                            name="source_id"
                            value={this.state.source_id}
                            onChange={this.handleChange} />
                    </Form.Field>
                    <Form.Field>
                        <label>{t("Location id")}</label>
                        <Input
                            disabled={loading || fields_disabled}
                            name="location_id"
                            value={this.state.location_id}
                            onChange={this.handleChange} />
                    </Form.Field> */}

                    <Form.Field>
                        <SemanticDatepicker
                            disabled={loading || fields_disabled}
                            label={t("Data read date")}
                            locale={fiLocale}
                            placeholder={new Date(dc_item.value_date).toLocaleString('fi-FI', { dateStyle: 'short' })}
                            onDateChange={selected => this.handleDateChange(selected)}
                            format='DD.MM.YYYY' />
                    </Form.Field>
                    <Form.Field>
                        <label>{t("Value author")}</label>
                        <Input
                            disabled={loading || fields_disabled}
                            value={dc_item.author}
                            name="author"
                            onChange={this.handleChange} />
                    </Form.Field>
                    <Form.Field>
                        <label>{t("Value")}</label>
                        <Input
                            disabled={loading || fields_disabled}
                            value={dc_item.data_value}
                            name="data_value"
                            onChange={this.handleChange} />
                    </Form.Field>

                    {error && <Message error header={t("Error")} content={error} />}
                    {success_mgs && <Message positive header={success_mgs} />}
                    <Button primary type="button" onClick={(e) => this.handleSubmit(e)} loading={loading} disabled={loading}>
                        {t("Save")}
                    </Button>
                </Form>
            </Shell>
        );
    }
}
const mapStateToProps = state => {
    return {
        activeCompanyId: state.company.activeCompanyId,
        isAuthenticated: state.auth.token !== null
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setActivity: () => dispatch(setActivity())
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(withTranslation('companydatacollectionadditem')(CompanyDataCollectionAddItem))
);