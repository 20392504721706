// Copyright Caproc Oy
// System name: Aqua-Net 
// System module: Customer
// Author: TS
//
// 1.2.2020 TS Initial version
// 18.6.2020 TS Added delete option
// 20.6.2020 TS Added export to CSV

import React from "react";
import { Message, Header, Button, Table, Segment, Dimmer, Loader, Image, Modal, Grid, Input, Icon, Popup, Responsive, Confirm } from "semantic-ui-react";
import Shell from "./Shell";
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import fiLocale from 'react-semantic-ui-datepickers/dist/locales/fi-FI';
import { authAxios, getISOFormattedDate, getWidth, exportToCsv } from "../../utils";
import { setActivity } from "../../store/actions/auth";
import { withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import { customerDataCollectionItemsURL, customerDetailsURL, deleteCustomerDataCollectionItemURL } from "../../constants";


class CustomerDataCollectionItems extends React.Component {
    state = {
        data_collection_items: null,
        customer_name: null,
        customer_id: null,
        message_modal_open: false,
        message_modal_text: "",
        error: null,
        loading: false,
        search_start_date: null,
        search_end_date: null,
        search: "",
        refresh: false,
        delete_confirm_open: false,
        delete_confirm_text: "",
        delete_item: ""        

    };

    componentDidMount() {
        this.props.setActivity();
        this.fetchCustomerDetails();
        let init_start_date = new Date();
        init_start_date.setDate(init_start_date.getDate() - 2);
        this.handleStartDateChange(init_start_date);
        this.handleEndDateChange(new Date());
        // this.handleFetchDataCollectionItemsList();
    }


    fetchCustomerDetails = () => {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;
        const { customer_id } = this.props.match.params;
        authAxios
            .get(customerDetailsURL(activeCompanyId, customer_id))
            .then(res => {
                this.setState({
                    customer_name: res.data.name,
                    error: null,
                    success_mgs: null,
                    loading: false,
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    success_mgs: null,
                    error: err
                });
            });
    };

    handleFetchDataCollectionItemsList = () => {
        this.setState({
            loading: true,
        });
        const { activeCompanyId } = this.props;
        const { customer_id } = this.props.match.params;
        const { search_start_date, search_end_date } = this.state;

        if (search_start_date !== null && search_end_date !== null) {

            authAxios
                .get(customerDataCollectionItemsURL(activeCompanyId, customer_id, search_start_date, search_end_date))
                .then(res => {
                    this.setState({
                        data_collection_items: res.data,
                        error: null,
                        loading: false
                    });
                })
                .catch(err => {
                    this.setState({
                        loading: false,
                        error: err
                    });
                });
        }
    };

    openMessageModal = message => {
        this.setState({
            message_modal_open: true,
            message_modal_text: message
        });
    };

    closeModal = () => {
        this.setState({
            message_modal_open: false,
            message_modal_text: ""
        });
    };

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    handleStartDateChange = value => {
        this.setState({
            search_start_date: getISOFormattedDate(value, "Date"),
            refresh: true
        });
    };

    handleEndDateChange = value => {
        this.setState({
            search_end_date: getISOFormattedDate(value, "Date"),
            refresh: true
        });
    };

    handleDelete = id => {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;
        const { customer_id } = this.props.match.params;
        authAxios
            .delete(deleteCustomerDataCollectionItemURL(activeCompanyId, customer_id, id))
            .then(res => {
                this.setState({
                    refresh: true,
                    error: null,
                    loading: false
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    error: err
                });
            });
        this.setState({
            delete_confirm_open: false,
            delete_confirm_text: "",
            delete_item: ""
        });
    };

    openDeleteConfirm = (text, delete_item) => {
        this.setState({
            delete_confirm_open: true,
            delete_confirm_text: text,
            delete_item: delete_item
        });
    };

    closeDeleteConfirm = () => {
        this.setState({
            delete_confirm_open: false,
            delete_confirm_text: "",
            delete_item: ""
        });
    };

    handleExportToCSV = items => {
        const { t } = this.props;
        if (items !== null) {
            const titles = [
                t("Item added"),
                t("Value date"),
                t("Product"),
                t("Source"),
                t("Location"),
                t("Author"),
                t("Value")
            ];
            let items_to_csv = [];
            let i = 0;
            for (i in items) {
                items_to_csv.push({
                    value_date: new Date(items[i].value_date).toLocaleString('fi-FI', { dateStyle: 'short' }),
                    date_created: new Date(items[i].date_created).toLocaleString('fi-FI'),
                    product: items[i].name,
                    source: items[i].source_id,
                    location: items[i].location_id,
                    author: items[i].author,
                    value: items[i].data_value
                });
            }
            exportToCsv(this.props.activeCompanyId, "export", titles, items_to_csv);
        }
    };

    render() {
        const { error, loading, data_collection_items, customer_name, search, search_end_date, search_start_date, refresh, delete_confirm_open, delete_confirm_text, delete_item } = this.state;
        const { customer_id } = this.props.match.params;
        const { isAuthenticated, t } = this.props;
        if (!isAuthenticated) {
            return <Redirect to="/login" />;
        }
        if (refresh) {
            this.handleFetchDataCollectionItemsList();
            this.setState({ refresh: false });
        }
        const is_desktop =  getWidth() > Responsive.onlyMobile.maxWidth ? true : false;
        // console.log(this.state);
        let filtered_dc = [];

        // Filter data for search
        if (data_collection_items !== null) {
            filtered_dc = data_collection_items.filter((item) => {
                return item.name.toLowerCase().indexOf(search.toLocaleLowerCase()) !== -1 ||
                    item.source_id.toString().toLowerCase().indexOf(search.toLocaleLowerCase()) !== -1 ||
                    item.location_id.toString().toLowerCase().indexOf(search.toLocaleLowerCase()) !== -1 ||
                    item.author.toString().toLowerCase().indexOf(search.toLocaleLowerCase()) !== -1 ||
                    item.data_value.toString().toLowerCase().indexOf(search.toLocaleLowerCase()) !== -1 ||
                    item.source_type.toString().toLowerCase().indexOf(search.toLocaleLowerCase()) !== -1;
            })
            if (search_start_date !== null) {
                filtered_dc = filtered_dc.filter((item) => {
                    return (new Date(item.date_created) >= new Date(search_start_date))
                })
            }
            if (search_end_date !== null) {
                filtered_dc = filtered_dc.filter((item) => {
                    return (new Date(item.date_created) <= new Date(search_end_date).setDate(new Date(search_end_date).getDate() + 1))
                })
            }
        }

        return (
            <Shell active_customer_id={customer_id} active_customer_name={customer_name}>
                {is_desktop && (
                    <Button.Group floated="right" basic size='small'>
                        <Button icon="download" content="CSV" onClick={() => this.handleExportToCSV(filtered_dc)} />
                    </Button.Group>
                )}

                <Header as="h3">{customer_name} {t("Data collection items")}</Header>
                {is_desktop ? (
                    <Grid columns="2">
                        <Grid.Column width={9}>
                            <SemanticDatepicker
                                firstDayOfWeek="1"
                                size="mini"
                                disabled={loading}
                                label={t("Item added")}
                                locale={fiLocale}
                                placeholder={search_start_date !== null ? (new Date(search_start_date).toLocaleString('fi-FI', { dateStyle: 'short' })) : ("")}
                                onDateChange={selected => this.handleStartDateChange(selected)}
                                format='DD.MM.YYYY' />
                            <SemanticDatepicker
                                firstDayOfWeek="1"
                                size="mini"
                                disabled={loading}
                                label="->"
                                locale={fiLocale}
                                placeholder={search_end_date !== null ? (new Date(search_end_date).toLocaleString('fi-FI', { dateStyle: 'short' })) : ("")}
                                onDateChange={selected => this.handleEndDateChange(selected)}
                                format='DD.MM.YYYY' />
                        </Grid.Column>
                        <Grid.Column floated="right" width={6}>
                            <Input
                                size="mini"
                                icon='search'
                                value={search}
                                name="search"
                                onChange={this.handleChange} />
                            <Button type="button" positive size='tiny' floated="right" content={t("+ Add item")} onClick={() => this.props.history.push(`/customer/${customer_id}/customer-data-collection/item/create/`)} />


                            {/* <Link to={`/customer/${customer_id}/customer-data-collection/item/create/`}>
                                <Button type="button" floated="right" color="green">
                                    {t("+ Add item")}
                                </Button>
                            </Link> */}
                        </Grid.Column>
                    </Grid>
                ) : (
                    <React.Fragment>
                        <Button type="button" positive fluid content={t("+ Add item")} onClick={() => this.props.history.push(`/customer/${customer_id}/customer-data-collection/item/create/`)} />
                        <hr/>
                        <label>{t("Item added")}</label><br/>
                        <SemanticDatepicker
                            firstDayOfWeek="1"
                            disabled={loading}
                            locale={fiLocale}
                            placeholder={search_start_date !== null ? (new Date(search_start_date).toLocaleString('fi-FI', { dateStyle: 'short' })) : ("")}
                            onDateChange={selected => this.handleStartDateChange(selected)}
                            format='DD.MM.YYYY' />
                        <SemanticDatepicker
                            firstDayOfWeek="1"
                            
                            disabled={loading}
                            locale={fiLocale}
                            placeholder={search_end_date !== null ? (new Date(search_end_date).toLocaleString('fi-FI', { dateStyle: 'short' })) : ("")}
                            onDateChange={selected => this.handleEndDateChange(selected)}
                            format='DD.MM.YYYY' />
                            <Input
                                fluid
                                icon='search'
                                value={search}
                                name="search"
                                onChange={this.handleChange} />
                    </React.Fragment>
                )}
                {error && (
                    <Message
                        error
                        header={t("There was an error")}
                        content={JSON.stringify(error)}
                    />
                )}
                {loading && (
                    <Segment>
                        <Dimmer active inverted>
                            <Loader inverted>Loading</Loader>
                        </Dimmer>
                        <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
                    </Segment>
                )}
                {data_collection_items && (
                    <React.Fragment>
                        <Table striped selectable celled >
                            {is_desktop && (
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell/>
                                        <Table.HeaderCell>{t("Item added")}</Table.HeaderCell>
                                        <Table.HeaderCell>{t("Value date")}</Table.HeaderCell>
                                        <Table.HeaderCell>{t("Author")}</Table.HeaderCell>
                                        <Table.HeaderCell>{t("Product")}</Table.HeaderCell>
                                        <Table.HeaderCell>{t("Source")}</Table.HeaderCell>
                                        <Table.HeaderCell>{t("Location")}</Table.HeaderCell>
                                        <Table.HeaderCell>{t("Value")}</Table.HeaderCell>
                                        <Table.HeaderCell/>
                                        
                                        
                                        {/* <Table.HeaderCell>{t("Message")}</Table.HeaderCell> */}
                                    </Table.Row>
                                </Table.Header>
                            )}
                            <Table.Body>
                                {filtered_dc.map((item) => {
                                    return (
                                        <Table.Row key={item.id} >
                                            <Table.Cell collapsing>{item.source_type === 'M' ? (<Popup content={t("Manual entry/trigger")} trigger={<Icon name='edit' />} />) : (<Popup content={t("Automatic")} trigger={<Icon name='cloud download' />} />)}</Table.Cell>
                                            <Table.Cell collapsing>
                                                {!is_desktop && (<React.Fragment><b>{t("Item added")}</b><br/></React.Fragment>)}
                                                {new Date(item.date_created).toLocaleString('fi-FI')}
                                            </Table.Cell>
                                            <Table.Cell collapsing>
                                                {!is_desktop && (<React.Fragment><b>{t("Value date")}</b><br/></React.Fragment>)}
                                                {new Date(item.value_date).toLocaleString('fi-FI', { dateStyle: 'short' })}
                                            </Table.Cell>
                                            <Table.Cell collapsing>
                                                {!is_desktop && (<React.Fragment><b>{t("Author")}</b><br/></React.Fragment>)}
                                                {item.author}
                                            </Table.Cell>
                                            <Table.Cell collapsing>
                                                {!is_desktop && (<React.Fragment><b>{t("Product")}</b><br/></React.Fragment>)}
                                                {item.name}
                                            </Table.Cell>
                                            <Table.Cell collapsing>
                                                {!is_desktop && (<React.Fragment><b>{t("Source")}</b><br/></React.Fragment>)}
                                                {item.source_id}
                                            </Table.Cell>
                                            <Table.Cell collapsing>
                                                {!is_desktop && (<React.Fragment><b>{t("Location")}</b><br/></React.Fragment>)}
                                                {item.location_id}
                                            </Table.Cell>
                                            <Table.Cell collapsing>
                                                {!is_desktop && (<React.Fragment><b>{t("Value")}</b><br/></React.Fragment>)}
                                                {item.data_value}
                                            </Table.Cell>
                                            {item.source_type === 'A' ? (
                                                <React.Fragment>
                                                    {(this.props.activeRole && (this.props.activeRole.role === "A" || this.props.activeRole.role === "S")) && (
                                                        <Table.Cell collapsing textAlign='center'>
                                                            <Icon link color="red" name='trash alternate outline' onClick={() => this.openDeleteConfirm(t("Are you sure your want to delete", { item: `${new Date(item.value_date).toLocaleString('fi-FI', { dateStyle: 'short' })} ${item.name} ${item.source_id} ${item.data_value}` }), item.id)}/>
                                                        </Table.Cell>
                                                    )}
                                                </React.Fragment>
                                            ) : (
                                                <Table.Cell collapsing textAlign='center'>
                                                    <Icon link color="red" name='trash alternate outline' onClick={() => this.openDeleteConfirm(t("Are you sure your want to delete", { item: `${new Date(item.value_date).toLocaleString('fi-FI', { dateStyle: 'short' })} ${item.name} ${item.source_id} ${item.data_value}` }), item.id)}/>
                                                </Table.Cell>
                                            )}
                                            {/* <Table.Cell collapsing onClick={() => this.openMessageModal(item.received_message)} icon='file alternate outline' ></Table.Cell> */}
                                        </Table.Row>
                                    );
                                })}
                            </Table.Body>
                        </Table>
                        <Modal open={this.state.message_modal_open}>
                            <Modal.Header>
                                <Button type="button" negative onClick={() => this.closeModal()} >{t("Close")}</Button>
                            </Modal.Header>
                            <Modal.Content>
                                {this.state.message_modal_text}
                            </Modal.Content>
                            <Modal.Actions>
                                <Button type="button" negative onClick={() => this.closeModal()} >{t("Close")}</Button>
                            </Modal.Actions>
                        </Modal>
                    </React.Fragment>
                )
                }
                <Confirm
                    content={delete_confirm_text}
                    open={delete_confirm_open}
                    onCancel={() => this.closeDeleteConfirm()}
                    onConfirm={() => this.handleDelete(delete_item)}
                />
            </Shell >
        );
    }
}
const mapStateToProps = state => {
    return {
        activeCompanyId: state.company.activeCompanyId,
        isAuthenticated: state.auth.token !== null,
        activeRole: state.company.activeRole
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setActivity: () => dispatch(setActivity())
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(withTranslation('customerdatacollectionitems')(CustomerDataCollectionItems))
);