// Copyright Caproc Oy
// System name: Aqua-Net 
// System module: Message
// Author: TS
//
// 1.2.2020 TS Initial version
// 7.6.2020 TS Added mobile version
// 20.6.2020 TS Added export to CSV
// 11.7.2020 TS Added Company Selection banner

import React from "react";
import { Message, Header, Button, Table, Segment, Dimmer, Loader, Image, Modal, Grid, Input, Container, TextArea, Form, Icon, Item, List, GridColumn, Responsive } from "semantic-ui-react";
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import fiLocale from 'react-semantic-ui-datepickers/dist/locales/fi-FI';
import { authAxios, getISOFormattedDate, getWidth, exportToCsv, CompanySelection } from "../../utils";
import { setActivity } from "../../store/actions/auth";
import { withRouter, Redirect, Link } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import { messagesListURL, messageAttachmentDownloadURL } from "../../constants";


class Messages extends React.Component {
    state = {
        messages: null,
        message_modal_open: false,
        message_modal_text: "",
        active_message: null,
        error: null,
        loading: false,
        search_start_date: null,
        search_end_date: null,
        search: ""
    };

    componentDidMount() {
        this.props.setActivity();
        this.handleFetchMessagesList();
    }

    handleFetchMessagesList = () => {
        this.setState({
            loading: true,
        });
        const { activeCompanyId } = this.props;
        authAxios
            .get(messagesListURL(activeCompanyId))
            .then(res => {
                this.setState({
                    messages: this.handleFormatMessages(res.data),
                    error: null,
                    loading: false
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    error: err
                });
            });
    };

    handleFormatMessages = messages => {
        let i = 0;
        let new_bcc = "";
        for (i in messages) {
            new_bcc = messages[i].bcc;
            new_bcc = new_bcc.substring(1);
            new_bcc = new_bcc.substring(0, new_bcc.length - 1);
            messages[i].bcc = new_bcc;
        }
        return messages;
    };

    openMessageModal = message => {
        this.setState({
            message_modal_open: true,
            message_modal_text: message.text,
            active_message: message
        });
    };

    closeModal = () => {
        this.setState({
            message_modal_open: false,
            message_modal_text: "",
            active_message: null
        });
    };

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    handleStartDateChange = value => {
        this.setState({
            search_start_date: getISOFormattedDate(value, "Date")
        });
    };

    handleEndDateChange = value => {
        this.setState({
            search_end_date: getISOFormattedDate(value, "Date")
        });
    };

    downloadFile = (file) => {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;
        authAxios
            .get(messageAttachmentDownloadURL(activeCompanyId, file.id), { responseType: 'blob' })
            .then(res => {
                //Create a Blob from the data stream
                const res_file = new Blob(
                    [res.data]);
                //Build a URL from the file
                const fileURL = URL.createObjectURL(res_file);
                // Get the file name from the URL
                const filename = file.file_name.substring(file.file_name.lastIndexOf("/") + 1);
                const link = document.createElement('a');
                link.href = fileURL;
                link.setAttribute('download', filename); //or any other extension
                document.body.appendChild(link);
                link.click();

                this.setState({
                    error: null,
                    loading: false
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    error: err
                });
            });
    };

    handleExportToCSV = items => {
        const { t } = this.props;
        if (items !== null) {
            const titles = [
                t("Subject"),
                t("To"),
                t("Cc"),
                t("Bcc"),
                t("Message"),
                t("Sender"),
                t("Date sent")
            ];
            let items_to_csv = [];
            let i = 0;
            for (i in items) {
                items_to_csv.push({
                    subject: items[i].subject,
                    to: items[i].to,
                    cc: items[i].cc,
                    bcc: items[i].bcc,
                    text: items[i].text,
                    sender: `${items[i].sender.first_name} ${items[i].sender.last_name}`,
                    date_sent: new Date(items[i].date_sent).toLocaleString('fi-FI')
                });
            }
            exportToCsv(this.props.activeCompanyId, "export", titles, items_to_csv);
        }
    };

    render() {
        const { error, loading, messages, search, search_end_date, search_start_date, active_message } = this.state;
        const { isAuthenticated, t } = this.props;
        if (!isAuthenticated) {
            return <Redirect to="/login" />;
        }
        const is_desktop =  getWidth() > Responsive.onlyMobile.maxWidth ? true : false;
        // console.log(this.state);
        let filtered_messages = [];

        // Filter data for search
        if (messages !== null) {
            filtered_messages = messages.filter((message) => {
                return message.subject.toLowerCase().indexOf(search.toLocaleLowerCase()) !== -1 ||
                    message.to.toString().toLowerCase().indexOf(search.toLocaleLowerCase()) !== -1 ||
                    message.cc.toString().toLowerCase().indexOf(search.toLocaleLowerCase()) !== -1 ||
                    message.bcc.toString().toLowerCase().indexOf(search.toLocaleLowerCase()) !== -1 ||
                    message.text.toString().toLowerCase().indexOf(search.toLocaleLowerCase()) !== -1 ||
                    message.sender.toString().toLowerCase().indexOf(search.toLocaleLowerCase()) !== -1 ||
                    message.date_sent.toString().toLowerCase().indexOf(search.toLocaleLowerCase()) !== -1;
            })
            if (search_start_date !== null) {
                filtered_messages = filtered_messages.filter((message) => {
                    return (new Date(message.date_sent) >= new Date(search_start_date))
                })
            }
            if (search_end_date !== null) {
                filtered_messages = filtered_messages.filter((message) => {
                    return (new Date(message.date_sent) <= new Date(search_end_date).setDate(new Date(search_end_date).getDate() + 1))
                })
            }
        }

        return (
            <>
            <Container><CompanySelection/></Container>
            < Segment vertical>
                <Container>
                    {/* {is_desktop && (
                        <Button.Group floated="right" basic size='small'>
                            <Button icon="download" content="CSV" onClick={() => this.handleExportToCSV(filtered_messages)} />
                        </Button.Group>
                    )} */}
                    <Header as="h3">{t("Messages")}</Header>
                    <br/>
                    {is_desktop ? (
                        <React.Fragment>
                            <SemanticDatepicker
                                firstDayOfWeek="1"
                                size="mini"
                                disabled={loading}
                                locale={fiLocale}
                                placeholder={search_start_date !== null ? (new Date(search_start_date).toLocaleString('fi-FI', { dateStyle: 'short' })) : ("")}
                                onDateChange={selected => this.handleStartDateChange(selected)}
                                format='DD.MM.YYYY' />
                            <SemanticDatepicker
                                firstDayOfWeek="1"
                                size="mini"
                                disabled={loading}
                                label="->"
                                locale={fiLocale}
                                placeholder={search_end_date !== null ? (new Date(search_end_date).toLocaleString('fi-FI', { dateStyle: 'short' })) : ("")}
                                onDateChange={selected => this.handleEndDateChange(selected)}
                                format='DD.MM.YYYY' />
                            <Button type="button" floated="right" positive size='tiny' content={t("+ New message")} onClick={() => this.props.history.push(`/message/create/`)} />
                            <div style={{float:'right'}}>
                                <Input
                                    size="mini"
                                    icon='search'
                                    value={search}
                                    name="search"
                                    onChange={this.handleChange} />
                            </div>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <Button fluid type="button" positive content={t("+ New message")} onClick={() => this.props.history.push(`/message/create/`)} />
                            <hr/>
                            <SemanticDatepicker
                                firstDayOfWeek="1"
                                disabled={loading}
                                locale={fiLocale}
                                placeholder={search_start_date !== null ? (new Date(search_start_date).toLocaleString('fi-FI', { dateStyle: 'short' })) : ("")}
                                onDateChange={selected => this.handleStartDateChange(selected)}
                                format='DD.MM.YYYY' />
                            <SemanticDatepicker
                                firstDayOfWeek="1"
                                disabled={loading}
                                locale={fiLocale}
                                placeholder={search_end_date !== null ? (new Date(search_end_date).toLocaleString('fi-FI', { dateStyle: 'short' })) : ("")}
                                onDateChange={selected => this.handleEndDateChange(selected)}
                                format='DD.MM.YYYY' />
                            <Input
                                fluid
                                icon='search'
                                value={search}
                                name="search"
                                onChange={this.handleChange} />
                            <hr/>
                        </React.Fragment>
                    )}
                    {error && (
                        <Message
                            error
                            header={t("There was an error")}
                            content={JSON.stringify(error)}
                        />
                    )}
                    {loading && (
                        <Segment>
                            <Dimmer active inverted>
                                <Loader inverted>Loading</Loader>
                            </Dimmer>
                            <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
                        </Segment>
                    )}
                    {messages && (
                        <React.Fragment>
                                <Table celled striped >
                                    {is_desktop && (
                                        <Table.Header>
                                            <Table.Row>
                                                {/* <Table.HeaderCell width="3">{t("To")}</Table.HeaderCell>
                                                <Table.HeaderCell width="3">{t("Subject")}</Table.HeaderCell> */}
                                                <Table.HeaderCell collapsing>{t("Message")}</Table.HeaderCell>
                                                <Table.HeaderCell collapsing>{t("Sender")}</Table.HeaderCell>
                                                <Table.HeaderCell collapsing>{t("Date sent")}</Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>
                                    )}

                                    <Table.Body>
                                        {filtered_messages.map((message, key) => {
                                            return (
                                                <Table.Row key={key} onClick={() => this.openMessageModal(message)}>
                                                    {/* <Table.Cell collapsing>{message.bcc}</Table.Cell>
                                                    <Table.Cell collapsing>{message.subject}</Table.Cell> */}
                                                    <Table.Cell   >
                                                        <List>
                                                            <List.Item>
                                                                <List.Content>
                                                                    <List.Header>
                                                                        {!is_desktop && (<React.Fragment><b>{t("To")}</b><br/></React.Fragment>)}
                                                                        {message.bcc.replace(/'/g, "")}
                                                                    </List.Header>
                                                                    <List.Description>
                                                                        {!is_desktop && (<React.Fragment><b>{t("Subject")}</b><br/></React.Fragment>)}
                                                                        {message.subject}
                                                                    </List.Description>
                                                                    <br/>
                                                                    <List.Description>
                                                                        {is_desktop ? (
                                                                            <React.Fragment>
                                                                                {message.text.length > 100 ? (`${message.text.substring(0, 100)}...`) : (message.text)}
                                                                            </React.Fragment>
                                                                        ) : (
                                                                            <React.Fragment>
                                                                                <b>{t("Message")}</b><br/>
                                                                                {message.text.length > 70 ? (`${message.text.substring(0, 70)}...`) : (message.text)}
                                                                            </React.Fragment>
                                                                        )}
                                                                        {message.message_attachments.length > 0 && (<Header as="h6" floated="right" icon="attach" />)}
                                                                    </List.Description>
                                                                </List.Content>
                                                            </List.Item>
                                                        </List>
                                                            
            
                                                        {/* {message.text}
                                                        {message.message_attachments.length > 0 && (<Header as="h6" floated="right" icon="attach" />)} */}
                                                    </Table.Cell>
                                                    <Table.Cell collapsing >
                                                        {!is_desktop && (<React.Fragment><b>{t("Sender")}</b><br/></React.Fragment>)}
                                                        {message.sender.first_name} {message.sender.last_name}
                                                    </Table.Cell>
                                                    <Table.Cell collapsing >
                                                        {!is_desktop && (<React.Fragment><b>{t("Date sent")}</b><br/></React.Fragment>)}
                                                        {new Date(message.date_sent).toLocaleString('fi-FI')}
                                                    </Table.Cell>
                                                </Table.Row>
                                            );
                                        })}
                                    </Table.Body>
                                </Table>
                            {active_message && (
                                <Modal size="large" open={this.state.message_modal_open}>
                                    <Modal.Header>
                                        <b>{active_message.subject}</b>
                                        <Button type="button" floated="right" negative onClick={() => this.closeModal()} >{t("Close")}</Button>
                                        <Header as="h5" floated="right">{new Date(active_message.date_sent).toLocaleString('fi-FI')}</Header> <br />

                                    </Modal.Header>
                                    <Modal.Content >
                                        <b>{t("Sender_modal")}</b> {active_message.sender.first_name} {active_message.sender.last_name} ({active_message.sender.username})<br />
                                        <b>{t("Recepients")}</b> {active_message.bcc.replace(/'/g, "")}

                                        <br />
                                        <br />
                                        {active_message.message_attachments.length > 0 && (
                                            <React.Fragment>
                                                <label>{t("Attachments")}</label>
                                                <Icon floated name="attach" />
                                                <Segment>
                                                    <Container>
                                                        {active_message.message_attachments.map(attachment => {
                                                            return (
                                                                <Link onClick={() => this.downloadFile(attachment)} >
                                                                    <Item>
                                                                        <Icon name="file alternate outline" />
                                                                        <Item.Header>
                                                                            <Header as="h5" color="blue"> {attachment.file_name.substring(attachment.file_name.lastIndexOf("/") + 1)}</Header>
                                                                        </Item.Header>
                                                                    </Item>
                                                                </Link>
                                                            );
                                                        })}
                                                    </Container>
                                                </Segment>
                                            </React.Fragment>
                                        )}

                                        <Form>
                                            <TextArea rows={30} value={active_message.text} />
                                        </Form>
                                    </Modal.Content>
                                    <Modal.Actions>
                                        <Button type="button" negative onClick={() => this.closeModal()} >{t("Close")}</Button>
                                    </Modal.Actions>
                                </Modal>
                            )}
                        </React.Fragment>
                    )
                    }
                </Container>
            </ Segment>
            </>
        );
    }
}
const mapStateToProps = state => {
    return {
        activeCompanyId: state.company.activeCompanyId,
        isAuthenticated: state.auth.token !== null
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setActivity: () => dispatch(setActivity())
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(withTranslation('messages')(Messages))
);