// Copyright Caproc Oy
// System name: Aqua-Net 
// System module: Company
// Author: TS
//
// 1.2.2020 TS Initial version
// 11.4.2020 TS Added initial_project_number and current counters
// 28.9.2021 TS Added payment reminder comment and fee

import React from "react";
import { Form, Input, Message, Header, Button, Checkbox, TextArea } from "semantic-ui-react";
import Shell from "./Shell";
import { authAxios } from "../../utils";
import { setActivity } from "../../store/actions/auth";
import { withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import { invoicingDetailsURL, updateInvoicingDetailsURL } from "../../constants";


class InvoicingDetails extends React.Component {
    state = {
        invoicing_details_id: null,
        payment_term: null,
        initial_customer_number: null,
        initial_invoice_number: null,
        initial_contract_number: null,
        initial_project_number: null,
        current_customer_number_counter: null,
        current_invoice_number_counter: null,
        current_contract_number_counter: null,
        current_project_number_counter: null,
        invoice_reference_prefix: null,
        delayed_payments_interest: null,
        invoicing_bcc_email: null,
        invoice_bcc: null,
        invoicing_email_message: null,
        invoice_reminder_email_message: null,
        credit_invoice_comment: null,
        payment_reminder_comment: null,
        payment_reminder_fee: null,
        error: null,
        success_mgs: null,
        loading: false
    };

    componentDidMount() {
        this.props.setActivity();
        this.handleInvoicingDetails();
    }

    handleInvoicingDetails = () => {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;
        authAxios
            .get(invoicingDetailsURL(activeCompanyId))
            .then(res => {
                this.setState({
                    invoicing_details_id: res.data.id,
                    payment_term: res.data.payment_term,
                    initial_customer_number: res.data.initial_customer_number,
                    initial_invoice_number: res.data.initial_invoice_number,
                    initial_contract_number: res.data.initial_contract_number,
                    initial_project_number: res.data.initial_project_number,
                    current_customer_number_counter: res.data.current_customer_number_counter,
                    current_invoice_number_counter: res.data.current_invoice_number_counter,
                    current_contract_number_counter: res.data.current_contract_number_counter,
                    current_project_number_counter: res.data.current_project_number_counter,            
                    invoice_reference_prefix: res.data.invoice_reference_prefix,
                    delayed_payments_interest: res.data.delayed_payments_interest,
                    invoicing_bcc_email: res.data.invoicing_bcc_email,
                    invoice_bcc: res.data.invoice_bcc,
                    invoicing_email_message: res.data.invoicing_email_message,
                    invoice_reminder_email_message: res.data.invoice_reminder_email_message,
                    credit_invoice_comment: res.data.credit_invoice_comment,
                    payment_reminder_comment: res.data.payment_reminder_comment,
                    payment_reminder_fee: res.data.payment_reminder_fee,
                    error: null,
                    success_mgs: null,
                    loading: false
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    success_mgs: null,
                    error: err
                });
            });
    };

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value,
            success_mgs: null,
            error: null
        });
    };

    handleCheckboxChange = (e, { name, checked }) => {
        this.setState({
            [name]: checked
        });
    };

    handleSubmit = e => {
        e.preventDefault();
        this.setState({
            loading: true
        });
        const {
            invoicing_details_id,
            payment_term,
            initial_customer_number,
            initial_invoice_number,
            initial_contract_number,
            initial_project_number,
            current_customer_number_counter,
            current_invoice_number_counter,
            current_contract_number_counter,
            current_project_number_counter,
            invoice_reference_prefix,
            delayed_payments_interest,
            invoicing_bcc_email,
            invoice_bcc,
            invoicing_email_message,
            invoice_reminder_email_message,
            credit_invoice_comment,
            payment_reminder_comment,
            payment_reminder_fee
        } = this.state;
        const { activeCompanyId, t } = this.props;
        if (invoice_bcc && invoicing_bcc_email === "") {
            this.setState({
                loading: false,
                success_mgs: null,
                error: t("Please add also (Invoice copy email) if (Send invoice email copy) is selected")
            });
        } else if (delayed_payments_interest.toString().includes(",")) {
            this.setState({
                error: t("Please use dot as decimal separator in Interest of delayed payment instead of comma"),
                success_mgs: null,
                loading: false
            });
        } else if (invoice_reference_prefix.length > 19) {
            this.setState({
                error: t("Max amount of characters is 19 for Invoice reference prefix"),
                success_mgs: null,
                loading: false
            });
        } else if (payment_reminder_fee < 0) {
            this.setState({
                error: t("Payment reminder fee has to be positive value"),
                success_mgs: null,
                loading: false
            });
        } else if (payment_reminder_fee === null ||payment_reminder_fee === "") {
            this.setState({
                error: t("Payment reminder fee cannot be empty. Please add numerical value."),
                success_mgs: null,
                loading: false
            });
        } else if (payment_reminder_comment.length > 130) {
            this.setState({
                error: t("Max amount of characters is 130 for Payment reminder comment"),
                success_mgs: null,
                loading: false
            });
        } else if (isNaN(payment_reminder_fee)) {
            this.setState({
                error: t("Payment fee has to be numerical value. Use comma as decimal separator if number contains decimals."),
                success_mgs: null,
                loading: false
            });

        } else {
            authAxios
                .put(updateInvoicingDetailsURL(activeCompanyId, invoicing_details_id), {
                    payment_term,
                    initial_customer_number,
                    initial_invoice_number,
                    initial_contract_number,
                    initial_project_number,
                    current_customer_number_counter,
                    current_invoice_number_counter,
                    current_contract_number_counter,
                    current_project_number_counter,
                    invoice_reference_prefix,
                    delayed_payments_interest,
                    invoicing_bcc_email,
                    invoice_bcc,
                    invoicing_email_message,
                    invoice_reminder_email_message,
                    credit_invoice_comment,
                    payment_reminder_comment,
                    payment_reminder_fee
                })
                .then(res => {
                    this.setState({
                        loading: false,
                        success_mgs: t("Invoicing details updated successfully"),
                        error: null
                    });
                })
                .catch(err => {
                    this.setState({
                        loading: false,
                        success_mgs: null,
                        error: err
                    });
                });
        }
    };

    render() {
        const {
            payment_term,
            initial_customer_number,
            initial_invoice_number,
            invoice_reference_prefix,
            initial_contract_number,
            initial_project_number,
            current_customer_number_counter,
            current_invoice_number_counter,
            current_contract_number_counter,
            current_project_number_counter,
            delayed_payments_interest,
            invoicing_bcc_email,
            invoice_bcc,
            invoicing_email_message,
            invoice_reminder_email_message,
            credit_invoice_comment,
            payment_reminder_comment,
            payment_reminder_fee,
            error,
            success_mgs,
            loading } = this.state;
        const { isAuthenticated, t } = this.props;
        if (!isAuthenticated) {
            return <Redirect to="/login" />;
        }
        // console.log(this.state);
        return (
            <Shell>
                <Header as="h4">{t("Invoicing details")}</Header>
                <Form error={error !== null}>
                    <Form.Field>
                        <label>{t("Payment term")}</label>
                        <Input
                            value={payment_term}
                            name="payment_term"
                            onChange={this.handleChange} />
                    </Form.Field>
                    <Form.Field>
                        <label>{t("Initial customer number")} ({t("counter at the moment")} {current_customer_number_counter})</label>
                        <Input
                            value={initial_customer_number}
                            name="initial_customer_number"
                            onChange={this.handleChange} />
                    </Form.Field>
                    <Form.Field>
                        <label>{t("Initial invoice number")}  ({t("counter at the moment")} {current_invoice_number_counter})</label>
                        <Input
                            value={initial_invoice_number}
                            name="initial_invoice_number"
                            onChange={this.handleChange} />
                    </Form.Field>
                    <Form.Field>
                        <label>{t("Initial contract number")}  ({t("counter at the moment")} {current_contract_number_counter})</label>
                        <Input
                            value={initial_contract_number}
                            name="initial_contract_number"
                            onChange={this.handleChange} />
                    </Form.Field>
                    <Form.Field>
                        <label>{t("Initial project number")}  ({t("counter at the moment")} {current_project_number_counter})</label>
                        <Input
                            value={initial_project_number}
                            name="initial_project_number"
                            onChange={this.handleChange} />
                    </Form.Field>
                    <Form.Field>
                        <label>{t("Invoice reference prefix")}</label>
                        <Input
                            value={invoice_reference_prefix}
                            name="invoice_reference_prefix"
                            onChange={this.handleChange} />
                    </Form.Field>
                    <Form.Field>
                        <label>{t("Interest of delayed payment")}</label>
                        <Input
                            value={delayed_payments_interest}
                            name="delayed_payments_interest"
                            onChange={this.handleChange} />
                    </Form.Field>
                    <Form.Field
                        control={TextArea}
                        label={t("Invoice email message")}
                        value={invoicing_email_message}
                        name="invoicing_email_message"
                        onChange={this.handleChange} />
                    {/* <Form.Field
                        control={TextArea}
                        label={t("Invoice reminder email message")}
                        value={invoice_reminder_email_message}
                        name="invoice_reminder_email_message"
                        onChange={this.handleChange} /> */}
                    <Form.Field>
                        <Checkbox
                            label={<label>{t("Send invoice email copy (bcc)")}</label>}
                            name="invoice_bcc"
                            onChange={this.handleCheckboxChange}
                            checked={invoice_bcc}
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>{t("Invoice copy (bcc) email")}</label>
                        <Input
                            value={invoicing_bcc_email}
                            name="invoicing_bcc_email"
                            onChange={this.handleChange} />
                    </Form.Field>
                    <Form.Field>
                        <label>{t("Credit invoice comment")}</label>
                        <Input
                            value={credit_invoice_comment}
                            name="credit_invoice_comment"
                            onChange={this.handleChange} />
                    </Form.Field>
                    <Form.Field>
                        <label>{t("Payment reminder comment")}</label>
                        <Input
                            value={payment_reminder_comment}
                            name="payment_reminder_comment"
                            onChange={this.handleChange} />
                    </Form.Field>
                    <Form.Field>
                        <label>{t("Payment reminder fee (Please use value 0 if reminder fee should not be added in to the payment reminder invoice)")}</label>
                        <Input
                            value={payment_reminder_fee}
                            name="payment_reminder_fee"
                            onChange={this.handleChange} />
                    </Form.Field>

                    {error && <Message error header={t("Error")} content={error} />}
                    {success_mgs && <Message positive header={success_mgs} />}
                    <Button primary type="button" onClick={(e) => this.handleSubmit(e)} loading={loading} disabled={loading}>
                        {t("Save")}
                    </Button>
                </Form>
            </Shell >
        );
    }
}
const mapStateToProps = state => {
    return {
        activeCompanyId: state.company.activeCompanyId,
        isAuthenticated: state.auth.token !== null
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setActivity: () => dispatch(setActivity())
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(withTranslation('companyinvoicingdetails')(InvoicingDetails))
);