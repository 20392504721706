// Copyright Caproc Oy
// System name: Aqua-Net 
// System module: Customer
// Author: TS
//
// 1.2.2020 TS Initial version
// 11.7.2020 TS Added Company Selection banner

import React from "react";
import { Grid, Container, Segment, Header, Menu, Icon } from "semantic-ui-react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import { CompanySelection } from "../../utils";

const Shell = props => (
    <>
    <Container><CompanySelection/></Container>
    < Segment vertical>
        <Container>
            <Grid container stackable verticalAlign="top" divided columns={2}>
                <Grid.Row>
                    <Grid.Column width={3}>
                        <Header as="h3">{props.t("Customers")}</Header>
                        <Menu vertical fluid icon="labeled" compact>
                            <Menu.Item>
                                <Header as="h4">{props.t("Customers")}</Header>
                            </Menu.Item>
                            <Menu.Item
                                active={props.location.pathname === "/customers/"}
                                onClick={() => props.history.push("/customers/")}
                                name="customers"
                            >
                                <Icon name="users"/> {props.t("Customers")}
                            </Menu.Item>
                            <Menu.Item
                                active={props.location.pathname === "/customer/customer-groups/"}
                                onClick={() => props.history.push("/customer/customer-groups/")}
                                name="customer-groups"
                            >
                                <Icon name="object group outline"/> {props.t("Customer Groups")}
                            </Menu.Item>
                            {props.active_customer_id && (
                                <React.Fragment>
                                    <Menu.Item>
                                        <Header as="h4">{props.active_customer_name}</Header>
                                    </Menu.Item>

                                    <Menu.Item
                                        active={props.location.pathname === `/customer/customer-details/${props.active_customer_id}/`}
                                        onClick={() => props.history.push(`/customer/customer-details/${props.active_customer_id}/`)}
                                        name="customer-details"
                                    >
                                        <Icon name="info"/> {props.t("Details")}
                                    </Menu.Item>
                                    <Menu.Item
                                        active={props.location.pathname === `/customer/customer-invoicing-details/${props.active_customer_id}/`}
                                        onClick={() => props.history.push(`/customer/customer-invoicing-details/${props.active_customer_id}/`)}
                                        name="customer-invoicing-details"
                                    >
                                        <Icon name="list alternate outline"/> {props.t("Invoicing details")}
                                    </Menu.Item>
                                    <Menu.Item
                                        active={props.location.pathname === `/customer/${props.active_customer_id}/addresses/`}
                                        onClick={() => props.history.push(`/customer/${props.active_customer_id}/addresses/`)}
                                        name="customer-addresses"
                                    >
                                        <Icon name="address book"/> {props.t("Addresses")}
                                    </Menu.Item>
                                    <Menu.Item
                                        active={props.location.pathname === `/customer/${props.active_customer_id}/contacts/`}
                                        onClick={() => props.history.push(`/customer/${props.active_customer_id}/contacts/`)}
                                        name="customer-contacts"
                                    >
                                        <Icon name="users"/> {props.t("Contacts")}
                                    </Menu.Item>
                                    <Menu.Item
                                        active={props.location.pathname === `/customer/${props.active_customer_id}/files/`}
                                        onClick={() => props.history.push(`/customer/${props.active_customer_id}/files/`)}
                                        name="customer-files"
                                    >
                                        <Icon name="folder"/> {props.t("Files")}
                                    </Menu.Item>
                                    <Menu.Item>
                                        <Header as="h4">{props.t("Data collection")}</Header>
                                    </Menu.Item>
                                    <Menu.Item
                                        active={props.location.pathname === `/customer/${props.active_customer_id}/customer-data-collections/`}
                                        onClick={() => props.history.push(`/customer/${props.active_customer_id}/customer-data-collections/`)}
                                        name="customer-data-collections"
                                    >
                                        <Icon name="info"/> {props.t("Data collections")}
                                    </Menu.Item>
                                    <Menu.Item
                                        active={props.location.pathname === `/customer/${props.active_customer_id}/customer-data-collection/items/`}
                                        onClick={() => props.history.push(`/customer/${props.active_customer_id}/customer-data-collection/items/`)}
                                        name="customer-data-collection-items"
                                    >
                                        <Icon name="list ol"/> {props.t("Collection items")}
                                    </Menu.Item>
                                    <Menu.Item
                                        active={props.location.pathname === `/customer/${props.active_customer_id}/customer-data-collection/report/`}
                                        onClick={() => props.history.push(`/customer/${props.active_customer_id}/customer-data-collection/report/`)}
                                        name="customer-data-collections-report"
                                    >
                                        <Icon name="chart bar"/> {props.t("Item report")}
                                    </Menu.Item>
                                    <Menu.Item>
                                        <Header as="h4">{props.t("Tasks")}</Header>
                                    </Menu.Item>
                                    <Menu.Item
                                        active={props.location.pathname === `/customer/${props.active_customer_id}/tasks/`}
                                        onClick={() => props.history.push(`/customer/${props.active_customer_id}/tasks/`)}
                                        name="customer-tasks"
                                    >
                                        <Icon name="tasks"/> {props.t("Completed tasks")}
                                    </Menu.Item>
                                </React.Fragment>
                            )}
                        </Menu>
                    </Grid.Column>
                    <Grid.Column width={13}>{props.children}</Grid.Column>
                </Grid.Row>
            </Grid>
        </Container>
    </Segment >
    </>
);

const mapDispatchToProps = dispatch => {
    return {
        // logout: () => dispatch(logout())
    };
};

export default withRouter(
    connect(
        null,
        mapDispatchToProps
    )(withTranslation('customershell')(Shell))
);