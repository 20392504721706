// Copyright Caproc Oy
// System name: Aqua-Net 
// System module: Company
// Author: TS
//
// 1.2.2020 TS Initial version
// 6.5.2020 TS Added roles selection
// 8.7.2020 TS Changed roles section to allow more than one Customer instance

import React, { useReducer } from "react";
import { Form, Input, Message, Header, Button, Checkbox, Confirm, Modal, Select, Icon, Grid, Segment } from "semantic-ui-react";
import Shell from "./Shell";
import { authAxios } from "../../utils";
import { setActivity } from "../../store/actions/auth";
import axios from "axios";
import { withRouter, Redirect, Link} from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import { companyUserDetailsURL, companyUserDetailsUpdateURL, signupURL, companyUserRoleOptionsURL, userExistsURL, fetchCustomersURL } from "../../constants";


class CompanyUserDetails extends React.Component {
    state = {
        first_name: "",
        last_name: "",
        title: "",
        email: "",
        phone_number: "",
        is_active: true,
        error: null,
        success_mgs: null,
        loading: false,
        create: null,
        role_options: null,
        userroles: [],
        confirm_open: false,
        confirm_text: "",
        confirm_param: null,
        user_id: null,
        modal_open: false,
        modal_text: "",
        customer_options: null,
        customer: "",
        add_role_active: false,
        user_role: {
            role: "",
            role_name: "",
            customer: null
        }
    };

    componentDidMount() {
        this.props.setActivity();
        if (this.props.match.params.user_id === "create") {
            this.setState({
                create: true,
                is_active: true
            })
            this.fetchRoleOptions();
        } else {
            this.handleDetails();
        }
        this.handleFetchCustomers();
    }

    handleFetchCustomers() {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;

        authAxios
            .get(fetchCustomersURL(activeCompanyId))
            .then(res => {
                this.setState({
                    customer_options: this.handleFormatOptions(res.data),
                    error: null,
                    success_mgs: null,
                    loading: false,
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    success_mgs: null,
                    error: err
                });
            });
    };

    handleFormatOptions = options => {
        let i = 0;
        let options_list = [];
        for (i in options) {
            // Get only active ones..
            if (options[i].is_active) {
                options_list.push({
                    key: options[i].id,
                    text: options[i].name,
                    value: options[i].id
                })
            }
        }
        return options_list;
    };


    fetchRoleOptions() {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;

        authAxios
            .get(companyUserRoleOptionsURL(activeCompanyId))
            .then(res => {
                
                this.setState({
                    role_options: this.handleFormatRoleOptions(res.data),
                    error: null,
                    success_mgs: null,
                    loading: false,
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    success_mgs: null,
                    error: err
                });
            });

    }

    checkIfUserAlreadyExists = e => {
        this.setState({
            loading: true
        });
        const { activeCompanyId, t } = this.props;
        const { email } = this.state;

        authAxios
            .post(userExistsURL(activeCompanyId), {
                user_name: email
            })
            .then(res => {
                this.setState({
                    error: null,
                    success_mgs: null,
                    loading: false
                });
                // Returns 0 if user does not exist
                if (parseInt(res.data) === 0) {
                    this.handleSubmit(e);
                } else if (parseInt(res.data) === -1) { // Returns -1 if user already exists, but is not active
                    this.setState({
                        modal_open: true,
                        modal_text: t("User already exists, but it is NOT active. Please ask support (info@caproc.fi) to activate user and then add roles?", {user: email}),
                    })
                } else { // Returns user id if user already exists and is active
                    this.setState({
                        confirm_open: true,
                        confirm_param: e,
                        confirm_text: t("User already exists. Do you want to give selected roles to existing user?", {user: email}),
                        create: false,
                        user_id: res.data
                    });
                }
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    success_mgs: null,
                    error: err
                });
            });

    }

    handleFormatRoleOptions = options => {
        const { t } = this.props;
        const keys = Object.keys(options);
        return keys.map(k => {
            return {
                key: options[k].value,
                text: t(options[k].name),
                // value: this.checkRoleStatus(options[k])
                value: options[k].value
            };
        });
    };

    checkRoleStatus = role => {
        const { userroles } = this.state;
        const { activeCompanyId } = this.props;
        if (userroles !== null) {
            let i = 0;
            let return_value = false;
            for (i in userroles) {
                if (userroles[i].company === parseInt(activeCompanyId) && userroles[i].role === role.value && userroles[i].is_active) {
                    return_value = true;
                }
            }
            return return_value;
        } else {
            return false;
        }
    };

    handleDetails = () => {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;
        const { user_id } = this.props.match.params;
        authAxios
            .get(companyUserDetailsURL(activeCompanyId, user_id))
            .then(res => {
                this.setState({
                    user_id: user_id,
                    first_name: res.data.first_name,
                    last_name: res.data.last_name,
                    title: res.data.title,
                    email: res.data.email,
                    phone_number: res.data.phone_number,
                    is_active: res.data.is_active,
                    userroles: this.handleFormatUserRoles(res.data.userroles),
                    error: null,
                    success_mgs: null,
                    loading: false
                });
                this.fetchRoleOptions();
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    success_mgs: null,
                    error: err
                });
            });
    };

    handleFormatUserRoles = options => {
        const { t, activeCompanyId } = this.props;
        let k = 0;
        let return_list = [];
        for (k in options) {
            if (options[k].company === activeCompanyId) {
                return_list.push({
                    role: options[k].role,
                    role_name: t(options[k].role_name),
                    customer: options[k].customer.id === undefined ? null : options[k].customer.id
                });
            }
        }
        return return_list;
    };

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value,
            success_mgs: null,
            error: null
        });
    };

    handleCheckboxChange = (e, { name, value }) => {
        this.setState({
            [name]: !value
        });
    };

    handleRolesCheckboxChange = (e, { name, value }) => {
        let { role_options } = this.state;
        let i = 0;
        for (i in role_options) {
            if (name === role_options[i].text) {
                role_options[i].value = !value
                this.setState({
                    role_options: role_options
                });
            }
        }    
    };

    checkRoleChoices = () => {
        const { role_options } = this.state;
        let i = 0;
        let return_value = false;
        for (i in role_options) {
            if (role_options[i].value) {
                return_value = true;
            }
        }
        return return_value;
    };

    handlePreCheck = e => {
        if (this.state.create) {
            this.checkIfUserAlreadyExists(e);
        } else {
            this.handleSubmit(e);
        }
    }

    handleSelectChange = (e, { name, value }) => {
        const { user_role } = this.state;
        const updatedRole = {
            ...user_role,
            [name]: value
        }
        this.setState({
            user_role: updatedRole
        });
    };
    

    handleSubmit = e => {
        e.preventDefault();
        this.setState({
            loading: true
        });
        const {
            first_name,
            last_name,
            title,
            email,
            phone_number,
            is_active,
            userroles,
            create,
            user_id
        } = this.state;
        const { activeCompanyId, t } = this.props;
        if (first_name === "") {
            this.setState({
                error: t("Please fill in first name"),
                success_mgs: null,
                loading: false
            });
        } else if (last_name === "") {
            this.setState({
                error: t("Please fill in last name"),
                success_mgs: null,
                loading: false
            });
        } else if (first_name.length > 20) {
            this.setState({
                error: t("Max amount of characters is 20 for First name"),
                success_mgs: null,
                loading: false
            });
        } else if (last_name.length > 30) {
            this.setState({
                error: t("Max amount of characters is 30 for Last name"),
                success_mgs: null,
                loading: false
            });
        } else if (title.length > 20) {
            this.setState({
                error: t("Max amount of characters is 20 for Title"),
                success_mgs: null,
                loading: false
            });
        } else if (phone_number.length > 20) {
            this.setState({
                error: t("Max amount of characters is 20 for Phone"),
                success_mgs: null,
                loading: false
            });
        } else if (create && (userroles === null || userroles.length === 0)) {
            this.setState({
                error: t("Please add at least one role"),
                success_mgs: null,
                loading: false
            });
        } else {
            if (create) {
                const pswd = 'kjdfs7fds!a'; /* Only temp password. User will reset before first login  */
                axios
                    .post(signupURL, {
                        username: email,
                        email,
                        password1: pswd,
                        password2: pswd
                    })
                    .then(res => {
                        authAxios
                            .post(companyUserDetailsUpdateURL(activeCompanyId, null), {
                                token: res.data.key,
                                username: email,
                                first_name,
                                last_name,
                                title,
                                email,
                                phone_number,
                                is_active,
                                is_created: true,
                                role_options: userroles
                            })
                            .then(res => {
                                this.props.history.push("/company/users/");
                            })
                            .catch(err => {
                                this.setState({
                                    loading: false,
                                    success_mgs: null,
                                    error: err
                                });
                            });
                    })
                    .catch(err => {
                        this.setState({
                            loading: false,
                            success_mgs: null,
                            error: err
                        });
                    });
            } else {
                
                authAxios
                    .post(companyUserDetailsUpdateURL(activeCompanyId, user_id), {
                        username: email,
                        first_name,
                        last_name,
                        title,
                        email,
                        phone_number,
                        is_active,
                        is_created: false,
                        role_options: userroles
                    })
                    .then(res => {
                        this.props.history.push("/company/users/");
                    })
                    .catch(err => {
                        this.setState({
                            loading: false,
                            success_mgs: null,
                            error: err
                        });
                    });
            }
        }
    };

    closeConfirm = () => {
        this.setState({
            confirm_open: false,
            confirm_param: "",
            confirm_text: "",
            create: true,
            user_id: null
        });
    };

    closeModal = () => {
        this.setState({
            modal_open: false,
            modal_text: ""
        });
    };

    handleOpenAddRole = () => {
        this.setState({add_role_active: true });
    }

    handleCancelAddRole = () => {
        this.setState({
            add_role_active: false,
        });
        this.handleResetUserRole();
    }

    handleUpdateUserRole = (action, role) => {
        let { userroles } = this.state;
        if (action === "add") {
            if (!userroles.includes(role)) {
                userroles.push(role);
            }
        } else {
            if (userroles.includes(role)) {
                userroles.splice(userroles.indexOf(role), 1);
            }
        }
        this.setState({ userroles });
    }

    handleSaveAddRole = () => {
        const { user_role } = this.state;
        if (user_role.role !== "") {
            this.handleUpdateUserRole("add", user_role);
        }
        this.setState({
            add_role_active: false,
        });
        this.handleResetUserRole();
    }

    handleResetUserRole = () => {
        this.setState({
            user_role: {
                role: "",
                role_name: "",
                customer: null
            }
        });
    }
 
    getRoleName = role => {
        const { role_options}  = this.state;
        let i = 0;
        for (i in role_options) {
            if (role.role === role_options[i].key) {
                return this.props.t(role_options[i].text);
            }
        }
    }

    getCustomerName = role => {
        const { customer_options}  = this.state;
        let i = 0;
        for (i in customer_options) {
            if (role.customer === customer_options[i].key) {
                return customer_options[i].text;
            }
        }
    }

    render() {
        const {
            first_name,
            last_name,
            title,
            email,
            phone_number,
            error,
            success_mgs,
            loading,
            create,
            role_options,
            confirm_open,
            confirm_param,
            confirm_text,
            modal_open,
            modal_text,
            customer_options,
            userroles,
            add_role_active,
            user_role
        } = this.state;
        const { isAuthenticated, t } = this.props;
        if (!isAuthenticated) {
            return <Redirect to="/login" />;
        }
        // console.log(this.state);
        return (
            <Shell >
                <Header as="h4">{t("Company user details")}</Header>
                <Form error={error !== null}>
                    <Form.Field required>
                        <label>{t("First name")}</label>
                        <Input
                            required
                            value={first_name}
                            name="first_name"
                            onChange={create && (this.handleChange)} />
                    </Form.Field>
                    <Form.Field required>
                        <label>{t("Last name")}</label>
                        <Input
                            required
                            value={last_name}
                            name="last_name"
                            onChange={create && (this.handleChange)} />
                    </Form.Field>
                    <Form.Field>
                        <label>{t("Title")}</label>
                        <Input
                            value={title}
                            name="title"
                            onChange={create && (this.handleChange)} />
                    </Form.Field>
                    <Form.Field required>
                        <label>{t("Email")}</label>
                        <Input
                            required
                            value={email}
                            name="email"
                            onChange={create && (this.handleChange)} />
                    </Form.Field>
                    <Form.Field>
                        <label>{t("Phone")}</label>
                        <Input
                            value={phone_number}
                            name="phone_number"
                            onChange={create && (this.handleChange)} />
                    </Form.Field>
                    <br/>
                    <Button primary  compact type="button" content={t("+ Add role")} onClick={() => this.handleOpenAddRole()} floated="right"/>
                    <label><strong>{t("User roles")}</strong></label>
                    
                    {userroles && (
                        <React.Fragment>
                            <Segment>
                            {userroles.map((role, key) => {
                                return (
                                    <React.Fragment key={key}>
                                        {this.getRoleName(role)}{role.role === "C" ? (` / ${this.getCustomerName(role)}`) : ""} <Link onClick={() => this.handleUpdateUserRole("remove", role)}><Icon color="red" name='remove' /></Link><br/>
                                    </React.Fragment>
                                );
                            })}
                            </Segment>          
                        </React.Fragment>
                    )}
                    <br/>
                    {add_role_active && (
                        <>
                            <Grid columns="2">
                                <Grid.Column>  
                                    <Segment>
                                        <Grid.Row>
                                            <Form.Field>
                                                <Select
                                                    search
                                                    name='role'
                                                    size="mini"
                                                    compact
                                                    options={role_options}
                                                    placeholder={t("Select role")}
                                                    onChange={this.handleSelectChange}
                                                    value={user_role.role}
                                                />
                                            </Form.Field>
                                        </Grid.Row>
                                        {user_role.role === "C" &&  (
                                            <Grid.Row>
                                                <Form.Field>
                                                    <br/>
                                                    <Select
                                                        search
                                                        name='customer'
                                                        compact
                                                        size="mini"
                                                        options={customer_options}
                                                        placeholder={t("Select customer")}
                                                        onChange={this.handleSelectChange}
                                                        value={user_role.customer}
                                                    />
                                                </Form.Field>
                                            </Grid.Row>
                                        )}
                                        <br/>
                                        <Button negative  compact type="button" content={t("Cancel")} onClick={() => this.handleCancelAddRole()} floated="right"/>
                                        <Button positive  compact type="button" content={t("Save")} onClick={() => this.handleSaveAddRole()}/>
                                    </Segment>
                                </Grid.Column>
                            </Grid>
                        </>
                    )}

                    {/* {role_options && (
                        <React.Fragment>
                            <br/>
                            <label><strong>{t("User roles")}</strong></label>
                            <br/>
                            <br/>
                            {role_options.map((role) => {
                                return (
                                    <React.Fragment>
                                        <Form.Field>
                                            <Checkbox
                                                disabled={role.key === "A"}
                                                label={<label>{t(role.text)}</label>}
                                                name={role.text}
                                                onChange={this.handleRolesCheckboxChange}
                                                checked={role.value}
                                                value={role.value}
                                            />
                                        </Form.Field>
                                        {role.role === "C" &&  (
                                            <Form.Field>
                                                <Select
                                                    fluid
                                                    search
                                                    name='customer'
                                                    options={customer_options}
                                                    placeholder={t("Select customer")}
                                                    onChange={this.handleSelectChange}
                                                    value={customer}
                                                />
                                            </Form.Field>
                                        )}
                                    </React.Fragment>
                                );
                            })}
                        </React.Fragment>
                    )} */}
                    <hr/>
                    {error && <Message error header={t("Error")} content={error} />}
                    {success_mgs && <Message positive header={success_mgs} />}
                    <Button primary type="button" loading={loading} disabled={loading} onClick={(e) => this.handlePreCheck(e)}>
                        {create ? (t("Create")) : (t("Save"))}
                    </Button>
                </Form>
                <Confirm
                    cancelButton={t("Cancel")}
                    confirmButton={t("OK")}
                    content={confirm_text}
                    open={confirm_open}
                    onCancel={() => this.closeConfirm()}
                    onConfirm={() => this.handleSubmit(confirm_param)}
                />
                <Modal basic size='tiny' open={modal_open} >
                    
                    <Modal.Content>
                        {modal_text}
                    </Modal.Content>
                    <Modal.Actions>
                        <Button type="button" primary content={t("OK")} onClick={() => this.closeModal()} />
                    </Modal.Actions>
                </Modal>
            </Shell>
        );
    }
}
const mapStateToProps = state => {
    return {
        activeCompanyId: state.company.activeCompanyId,
        isAuthenticated: state.auth.token !== null
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setActivity: () => dispatch(setActivity())
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(withTranslation('companyuserdetails')(CompanyUserDetails))
);