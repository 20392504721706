// Copyright Caproc Oy
// System name: Aqua-Net 
// System module: CustomerPortal
// Author: TS
//
// 11.7.2020 TS Initial version


import React from "react";
import { Message, Header, Button, Table, Segment, Dimmer, Loader, Image, Icon, Responsive, Card } from "semantic-ui-react";
import Shell from "./Shell";
import { authAxios, exportToCsv, getWidth, getTotalGrossPrice } from "../../utils";
import { setActivity } from "../../store/actions/auth";
import { withRouter, Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import { customerInvoicesURL, fetchInvoicePdfURL } from "../../constants";


class CustomerPortalInvoices extends React.Component {
    state = {
        invoices: null,
        customer_id: null,
        error: null,
        loading: false
    };

    componentDidMount() {
        this.props.setActivity();
        this.handleFetchInvoices();
    }

    handleFetchInvoices = () => {
        this.setState({
            loading: true
        });
        const { activeCompanyId, activeRole } = this.props;
        const { customer_id } = this.props.match.params;
        authAxios
            .get(customerInvoicesURL(activeCompanyId, customer_id))
            .then(res => {
                this.setState({
                    invoices: this.handleFilterInvoicesList(res.data),
                    error: null,
                    loading: false
                });
            })
            .catch(err => {
                this.setState({
                    fina_stats: null,
                    loading: false,
                    error: err
                });
            });
        
    };

    handleFilterInvoicesList = data => {
        let new_list = [];
        let i = 0;
        for (i in data) {
            // Status Created, Approved or Deleted
            if (data[i].status === "C" || data[i].status === "A" || data[i].status === "D") {
                continue;
            }
            new_list.push(data[i]);
        }
        new_list.sort(function(a, b){return b.due_date - a.due_date});
        return new_list;
    };

    handleExportToCSV = items => {
        const { t } = this.props;
        if (items !== null) {
            const titles = [
                t("Invoice number"),
                t("Due date"),
                t("Total")
            ];
            let items_to_csv = [];
            let i = 0;
            for (i in items) {
                items_to_csv.push({
                    invoice_number: items[i].number,
                    due_date: new Date(items[i].due_date).toLocaleString('fi-FI', { dateStyle: 'short' }),
                    total: getTotalGrossPrice(items[i].invoiceitems).toFixed(2)
                });
            }
            exportToCsv(this.props.activeCompanyId, "export", titles, items_to_csv);
        }
    };

    handleFetchInvoicePdf = (invoice_id) => {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;
        authAxios
            .get(fetchInvoicePdfURL(activeCompanyId, invoice_id), { responseType: 'blob' })
            .then(res => {
                //Create a Blob from the PDF Stream
                const file = new Blob(
                    [res.data],
                    { type: 'application/pdf' });
                //Build a URL from the file
                const fileURL = URL.createObjectURL(file);
                // // //Open the URL on new Window
                // window.open(fileURL);

                const link = document.createElement('a');
                link.href = fileURL;
                link.setAttribute('download', 'lasku.pdf'); //or any other extension
                document.body.appendChild(link);
                link.click();

                this.setState({
                    error: null,
                    loading: false
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    error: err
                });
            });
    };

    render() {
        const { error, loading, invoices } = this.state;
        const { customer_id } = this.props.match.params;
        const { isAuthenticated, t, activeRole } = this.props;
        if (!isAuthenticated) {
            return <Redirect to="/login" />;
        }
        // console.log(this.state);
        const is_desktop =  getWidth() > Responsive.onlyMobile.maxWidth ? true : false;
        return (
            <>
            {(activeRole && invoices) && (
                <Shell active_customer_id={customer_id} active_customer_name={activeRole.customer.name}>
                    {is_desktop && (
                        <Button.Group floated="right" basic size='small'>
                            <Button icon="download" content="CSV" onClick={() => this.handleExportToCSV(invoices)} />
                        </Button.Group>
                    )}

                    {error && (
                        <Message
                            error
                            header={t("There was an error")}
                            content={JSON.stringify(error)}
                        />
                    )}
                    {loading && (
                        <Segment>
                            <Dimmer active inverted>
                                <Loader inverted>Loading</Loader>
                            </Dimmer>
                            <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
                        </Segment>
                    )}

                    {invoices && (
                        <>
                        <Header textAlign="center">{t("Last invoices")}</Header>
                        <Table basic="very" unstackable textAlign="center">
                            <Table.Header>
                                <Table.Row>
                                <Table.HeaderCell>{t("Invoice number")}</Table.HeaderCell>
                                <Table.HeaderCell>{t("Due date")}</Table.HeaderCell>
                                <Table.HeaderCell>{t("Total")}</Table.HeaderCell>
                                <Table.HeaderCell/>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {invoices.map((invoice, key) => {
                                    return (
                                        <Table.Row key={key} >
                                            <Table.Cell>{invoice.number}</Table.Cell>
                                            <Table.Cell>{new Date(invoice.due_date).toLocaleString('fi-FI', { dateStyle: 'short' })}</Table.Cell>
                                            <Table.Cell><strong>{getTotalGrossPrice(invoice.invoiceitems).toFixed(2)} €</strong></Table.Cell>
                                            <Table.Cell><Link onClick={() => this.handleFetchInvoicePdf(invoice.id)} ><Icon link name="file pdf outline" size="large" /> {t("Open file")}</Link></Table.Cell>
                                        </Table.Row>
                                    );
                                })}
                            </Table.Body>
                        </Table>
                        </>
                    )}
                </Shell>
                )}
            </>
        );
    }
}
const mapStateToProps = state => {
    return {
        activeCompanyId: state.company.activeCompanyId,
        isAuthenticated: state.auth.token !== null,
        activeRole: state.company.activeRole
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setActivity: () => dispatch(setActivity())
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(withTranslation('customerportalinvoices')(CustomerPortalInvoices))
);