// Copyright Caproc Oy
// System name: Aqua-Net 
// System module: Customers
// Author: TS
//
// 1.2.2020 TS Initial version
// 28.3.2020 TS Added InvoiceAbnormalPayment icon in customer invoices 
// 31.3.2020 TS Changed details section in modal to table for better responsive action
// 19.5.2020 TS Modfied invoices list and fixed link to invoice edit
// 28.5.2020 TS Changed customer list to fetch only basic details for each customer to increase performance. Full details of the customer is fetched when customer details modal is triggered.
// 4.6.2020 TS Added search. List in mobile mode instead of table
// 5.6.2020 TS Added mobile views
// 6.6.2020 TS Added "shortcut" icon in to Customers list to edit customer details directly without going through details modal.
// 18.6.2020 TS Added export to CSV
// 17.8.2020 TS Added multiple edit and import from Excel
// 2.5.2021 TS Added initial sensor value and initial sensor invoiced value to customer import modal.
// 3.5.2021 TS Added Contracts amount into Active and Arcive tabs
// 21.6.2021 TS Added "Initial sensor estimate" to Customer import
// 5.8.2021 TS Pick only is_active or !is_active in select all depending on Active or Arcive selection.

import React from "react";
import _ from 'lodash'
import { Message, Header, Button, Table, Segment, Dimmer, Loader, Image, Menu, Confirm, Grid, GridColumn, Card, Modal, Container, Popup, Icon, List, Input, Responsive, Form, Select, Checkbox } from "semantic-ui-react";
import Shell from "./Shell";
import { authAxios, authFileAxios, getTotalGrossPrice, getTotalNetPrice, getWidth, exportToCsv } from "../../utils";
import { setActivity } from "../../store/actions/auth";
import { withRouter, Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import { fetchPlainCustomerGroupsURL, fetchCustomersInGroupURL, deleteCustomerDetailsURL, updateCustomerDetailsURL, customerAddressesURL, customerFullDetailsURL, customerImportUploadURL, saveImportedCustomersURL, fetchExampleCustomersImportFile, saveMultipleEditCustomersURL, customerInvoicingMethodOptionsURL } from "../../constants";


class Customers extends React.Component {
    state = {
        customer_groups: null,
        customer_group_options: [],
        selected_customer_group: "all",
        customers: null,
        activeMenuItem: this.props.t("Active"),
        delete_confirm_open: false,
        delete_confirm_text: "",
        delete_item: "",
        active_customer: null,
        modal_open: false,
        error: null,
        loading: false,
        refresh_customers: false,
        active_customer_addresses: null,
        search: "",
        file: {
            file: "",
            selected_file: null
        },
        import_csv_modal_open: false,
        activeIndex: 0,
        import_file_rows_total: 0,
        received_items_list: null,
        import_disabled: false,
        import_modal_size: "large",
        import_result_modal_open: false,
        imported_customers_count: 0,
        column: null,
        direction: null,
        select_all: false,
        selected: [],
        multiple_edit: {
            item: null,
            value: null
        },
        multiple_edit_item_options: [],
        multiple_edit_modal_open: false,
        multiple_edit_error: null,
        multiple_edit_confirm_open: false,
        multiple_edit_confirm_text: "",
        multiple_edit_confirm_item: null,
        invoicing_method_options: []
    };

    componentDidMount() {
        this.props.setActivity();
        this.handleFetchCustomerGroupList();
        this.handleFetchCustomersInGroup("all");
    }

    handleSelectChange = (e, { name, value }) => {
        this.setState({
            [name]: value,
            refresh_customers: true
        });
    };

    handleMultiEditSelectChange = (e, { name, value }) => {
        let updatedValue;
        if (name === "item") {
            if (value === "no_vat" || value === "add_address_to_invoice") {
                updatedValue = {
                    ...this.state.multiple_edit,
                    item: value,
                    value: false
                }
            } else {
                updatedValue = {
                    ...this.state.multiple_edit,
                    item: value,
                    value: null
                }
            }
        } else if (name === "customer_group") {
            updatedValue = {
                ...this.state.multiple_edit,
                value: value
            }
        } else if (name === "payment_term") {
            updatedValue = {
                ...this.state.multiple_edit,
                value: value
            }
        } else if (name === "invoicing_method") {
            updatedValue = {
                ...this.state.multiple_edit,
                value: value
            }
        }
        this.setState({
            multiple_edit: updatedValue,
            multiple_edit_error: null,
        });
    };

    handleMultiEditChange = e => {
        const { t } = this.props;
        if (e.target.name === "discount" && isNaN(e.target.value)) {
            this.setState({
                multiple_edit_error: t("Discount value is not number")
            });
        } else {
            this.setState({
                multiple_edit_error: null
            });
        }
        const updatedValue = {
            ...this.state.multiple_edit,
            value: e.target.value
        }
        this.setState({
            multiple_edit: updatedValue,
        });
    };

    handleMultiEditCheckboxChange = (e, { value, checked }) => {
        const updatedValue = {
            ...this.state.multiple_edit,
            value: checked
        }
        this.setState({
            multiple_edit: updatedValue,
            multiple_edit_error: null,
        });
    };

    handleMultiEditOptions = () => {
        const { t } = this.props;
        const options = [
            {
                key: "vat",
                text: t("VAT"),
                value: "vat"
            },
            {
                key: "description",
                text: t("Description"),
                value: "description"
            },
            {
                key: "customer_code",
                text: t("Customer code"),
                value: "customer_code"
            },
            {
                key: "phone",
                text: t("Phone"),
                value: "phone"
            },
            {
                key: "email",
                text: t("Email"),
                value: "email"
            },
            {
                key: "homepage",
                text: t("Homepage"),
                value: "homepage"
            },
            {
                key: "customer_group",
                text: t("Customer group"),
                value: "customer_group"
            },
            {
                key: "discount",
                text: t("Discount %"),
                value: "discount"
            },
            // {
            //     key: "no_vat",
            //     text: t("Vat 0% customer"),
            //     value: "no_vat"
            // },
            // {
            //     key: "no_vat_comment",
            //     text: t("Vat 0% comment"),
            //     value: "no_vat_comment"
            // },
            {
                key: "invoicing_method",
                text: t("Invoicing method"),
                value: "invoicing_method"
            },
            // {
            //     key: "invoicing_email",
            //     text: t("Invoicing Email"),
            //     value: "invoicing_email"
            // },
            {
                key: "payment_term",
                text: t("Payment term"),
                value: "payment_term"
            },
            {
                key: "default_company_reference",
                text: t("Company reference"),
                value: "default_company_reference"
            },
            {
                key: "default_customer_reference",
                text: t("Customer reference"),
                value: "default_customer_reference"
            },
            {
                key: "default_invoice_comment",
                text: t("Default invoice comment"),
                value: "default_invoice_comment"
            },
            {
                key: "add_address_to_invoice",
                text: t("Add customer delivery address to invoice"),
                value: "add_address_to_invoice"
            }
        ];
        return options;
    };

    handleGetMultiEditSelectOptions = item => {
        const { t } = this.props;
        let options = [];
        if (item === "customer_group") {
            options = [...this.state.customer_group_options];
            options.shift();
        } else if (item === "payment_term") {
            for (let i = 0; i < 365; i++) {
                options.push({
                    key: i,
                    text: i.toString(),
                    value: i
                });
            }
        } else if (item === "invoicing_method") {
            options = this.state.invoicing_method_options;
        }
        return options;
    };

    fetchInvoicingMethodOptions() {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;
        const customer_id  = this.state.selected[0].id;

        authAxios
            .get(customerInvoicingMethodOptionsURL(activeCompanyId, customer_id))
            .then(res => {
                this.setState({
                    invoicing_method_options: this.handleFormatInvoicingMethodOptions(res.data),
                    multiple_edit_error: null,
                    success_mgs: null,
                    loading: false,
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    success_mgs: null,
                    multiple_edit_error: err
                });
            });

    }

    handleFormatInvoicingMethodOptions = options => {
        const { t } = this.props;
        let return_options = [];
        let k = 0;
        for (k in options) {
            // Email option is not enabled for multiple edit as it requires mandatory invoicing email address. 
            if (options[k].value !== "E") {
                return_options.push({
                    key: options[k].value,
                    text: t(options[k].name),
                    value: options[k].value
                });
            }
        }

        return return_options;
    };

    handleOpenMultiEditModal = () => {
        this.fetchInvoicingMethodOptions();
        this.setState({ multiple_edit_modal_open: true });
    };

    handleCloseMultiEditModal = () => {
        this.setState({ 
            multiple_edit_modal_open: false,
            multiple_edit: {
                item: null,
                value: null
            },
            multiple_edit_confirm_open: false,
            multiple_edit_confirm_text: "",
            multiple_edit_confirm_item: null,
        });
    };

    handleGetMultiEditLabel = item => {
        const options = this.handleMultiEditOptions();
        let i = 0;
        for (i in options) {
            if (item === options[i].value) return options[i].text;
        }
    }

    handleSort = (clickedColumn) => () => {
        const { column, customers, direction } = this.state

        if (column !== clickedColumn) {
            let sortedArray;
            if (clickedColumn === "name" || clickedColumn === "address") {
                sortedArray = _.sortBy(customers, [customer => customer[clickedColumn].toLowerCase()]);
            } else {
                sortedArray = _.sortBy(customers, [clickedColumn]);
            }
            this.setState({
                column: clickedColumn,
                customers: sortedArray,
                direction: 'ascending',
            });
            return;
        }

        this.setState({
            customers: customers.reverse(),
            direction: direction === 'ascending' ? 'descending' : 'ascending',
        })
    };

    handleCheckSubmitMultiEditConditions = e => {
        const { t } = this.props;
        const { multiple_edit } = this.state;
        if (multiple_edit.item === null || multiple_edit.item === "") {
            this.setState({
                multiple_edit_error: t("Please select item to edit")
            });
        } else if (multiple_edit.value === null || multiple_edit.value === "") {
            this.setState({
                multiple_edit_confirm_open: true,
                multiple_edit_confirm_text: t("Do you want to update item value to 'empty'?"),
                multiple_edit_confirm_item: e
            });
        } else {
            this.handleSubmitMultiEdit(e);
        }
    }

    handleCloseMultipleEditConfirm = () => {
        this.setState({
            multiple_edit_confirm_open: false,
            multiple_edit_confirm_text: "",
            multiple_edit_confirm_item: null
        });
    };


    handleSubmitMultiEdit = e => {
        e.preventDefault();
        this.handleCloseMultipleEditConfirm();
        this.setState({
            loading: true
        });
        const { activeCompanyId, t } = this.props;
        const { multiple_edit, selected } = this.state;
        if (multiple_edit.item === null || multiple_edit.item === "") {
            this.setState({
                multiple_edit_error: t("Please select item to edit"),
                loading: false
            });
        } else if (selected.length === 0) {
            this.setState({
                multiple_edit_error: t("Please select customers to edit"),
                loading: false
            });
        } else {
            // Create customers array with only id's
            let customers_to_edit = [];
            let i = 0;
            for (i in selected) {
                customers_to_edit.push(selected[i].id);
            }

            authAxios
                .post(saveMultipleEditCustomersURL(activeCompanyId), {
                    customers: customers_to_edit,
                    item: multiple_edit.item,
                    value: multiple_edit.value === null ? "" : multiple_edit.value
                })
                .then(res => {
                    this.handleCloseMultiEditModal();
                    this.setState({
                        refresh_customers: true,
                        select_all: false,
                        selected: []
                    });
                })
                .catch(err => {
                    this.setState({
                        loading: false,
                        success_mgs: null,
                        multiple_edit_error: err.response.data
                    });
                });
        }
    };

    handleChangeOrDeleteMultipleCustomers = new_status => {
        const { selected } = this.state;
        let item = "";
        let value = "";
        if (new_status === "archive") {
            item = "is_active";
            value = false;
        } else if (new_status === "activate") {
            item = "is_active";
            value = true;
        } else if (new_status === "delete") {
            item = "delete";
            value = "delete";
        }
        // Create customers array with only id's
        let customers_to_edit = [];
        let i = 0;
        for (i in selected) {
            customers_to_edit.push(selected[i].id);
        }
        const { activeCompanyId } = this.props;
        this.setState({ loading: true });
        authAxios
            .post(saveMultipleEditCustomersURL(activeCompanyId), {
                customers: customers_to_edit,
                item: item,
                value: value
            })
            .then(res => {
                this.setState({
                    refresh_customers: true,
                    select_all: false,
                    selected: []
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    success_mgs: null,
                    error: err.response.data
                });
            });
    };


    handleFetchFullCustomerDetails = (customer_id) => {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;
        authAxios
            .get(customerFullDetailsURL(activeCompanyId, customer_id))
            .then(res => {
                this.setState({
                    loading: false,
                    active_customer: res.data,
                    modal_open: true
                });
                this.handleFetchCustomerAddresses(customer_id);
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    error: err
                });
            });
    };

    handleFetchCustomerAddresses = (customer_id) => {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;
        authAxios
            .get(customerAddressesURL(activeCompanyId, customer_id))
            .then(res => {
                this.setState({
                    active_customer_addresses: res.data,
                    error: null,
                    loading: false
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    error: err
                });
            });
    };

    handleFetchCustomerGroupList = () => {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;
        authAxios
            .get(fetchPlainCustomerGroupsURL(activeCompanyId))
            .then(res => {
                this.setState({
                    customer_group_options: this.handleFormatOptions(res.data),
                    error: null,
                    loading: false
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    error: err
                });
            });
    };

    handleFormatOptions = options => {
        const { t } = this.props;
        let i = 0;
        let options_list = [];
        options_list.push({
            key: 0,
            text: t("All"),
            value: "all"
        })
        for (i in options) {
            options_list.push({
                key: options[i].id,
                text: t(options[i].name),
                value: options[i].id
            })
        }
        return options_list;
    };


    handleFetchCustomersInGroup = () => {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;
        authAxios
            .get(fetchCustomersInGroupURL(activeCompanyId, this.state.selected_customer_group))
            .then(res => {
                this.setState({
                    customers: res.data,
                    error: null,
                    loading: false
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    error: err
                });
            });
    };


    handleDeleteCustomer = customer_id => {
        this.closeDeleteConfirm();
        if (customer_id === "selected") {
            this.handleChangeOrDeleteMultipleCustomers("delete");
        } else {
            this.setState({
                loading: true
            });
            const { activeCompanyId } = this.props;
            authAxios
                .delete(deleteCustomerDetailsURL(activeCompanyId, customer_id))
                .then(res => {
                    this.setState({
                        error: null,
                        loading: false,
                        refresh_customers: true
                    });
                })
                .catch(err => {
                    this.setState({
                        loading: false,
                        error: err
                    });
                });
            this.setState({
                delete_confirm_open: false,
                delete_confirm_text: "",
                delete_item: ""
            });
        }
    };

    customerDetails = id => {
        this.props.history.push(`/customer/customer-details/${id}/`)
    };

    handleMenuItemClick = (e, { name }) => this.setState({ activeMenuItem: name, selected: [], select_all: false });

    openDeleteConfirm = (text, delete_item) => {
        this.setState({
            delete_confirm_open: true,
            delete_confirm_text: text,
            delete_item: delete_item
        });
    };

    closeDeleteConfirm = () => {
        this.setState({
            delete_confirm_open: false,
            delete_confirm_text: "",
            delete_item: ""
        });
    };

    openDetails = customer => {
        this.setState({
            loading: true
        });
        this.handleFetchFullCustomerDetails(customer.id);
    };

    closeModal() {
        this.setState({
            modal_open: false,
            active_customer: null,
            active_customer_addresses: null
        });
    };

    changeActiveStatus = customer => {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;
        const updatedCustomer = {
            ...customer,
            customer_group: customer.customer_group.id,
            is_active: !customer.is_active
        }
        authAxios
            .put(updateCustomerDetailsURL(activeCompanyId, customer.id), {
                ...updatedCustomer
            })
            .then(res => {
                this.setState({
                    error: null,
                    loading: false,
                    refresh_customers: true
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    error: err
                });
            });
    };

    getTotalNetPriceAllInvoices = invoices => {
        let i = 0;
        let net = 0;
        for (i in invoices) {
            net += getTotalNetPrice(invoices[i].invoiceitems);
        }
        return net;
    };

    getTotalGrossPriceAllInvoices = invoices => {
        let i = 0;
        let gross = 0;
        for (i in invoices) {
            gross += getTotalGrossPrice(invoices[i].invoiceitems);
        }
        return gross;
    };

    contractDetails = id => {
        this.props.history.push(`/contract/contract-details/${id}/`)
    };

    invoiceDetails = id => {
        this.props.history.push(`/invoice/invoice-details/edit/${id}/`)
    };

    handleCheckInternalComments = invoice => {
        if (invoice.invoicecomments.length > 0) {
            let i = 0;
            for (i in invoice.invoicecomments) {
                if (invoice.invoicecomments[i].comment_type === "USER") {
                    return true;
                }
            }
            return false;
        } else {
            return false;
        }
    }

    handleCheckErrorComments = invoice => {
        if (invoice.invoicecomments.length > 0) {
            let i = 0;
            for (i in invoice.invoicecomments) {
                if (invoice.invoicecomments[i].comment_type === "ERROR" && !invoice.invoicecomments[i].is_acknowledged) {
                    return true;
                }
            }
            return false;
        } else {
            return false;
        }
    }

    handleGetAbnormalSum = invoice => {
        let i = 0;
        let sum = 0;
        for (i in invoice.invoiceabnormalpayments) {
            sum += invoice.invoiceabnormalpayments[i].paid_amount;
        } 
        return sum;
    };

    handleGetFilterList = customers => {
        const { search } = this.state;
        let filtered_list = [];
        filtered_list = customers.filter((customer) => {
            return customer.name.toLowerCase().indexOf(search.toLocaleLowerCase()) !== -1 ||
                customer.vat.toLowerCase().indexOf(search.toLocaleLowerCase()) !== -1 ||
                customer.address.toLowerCase().indexOf(search.toLocaleLowerCase()) !== -1 ||
                customer.postal_code.toLowerCase().indexOf(search.toLocaleLowerCase()) !== -1 ||
                customer.city.toLowerCase().indexOf(search.toLocaleLowerCase()) !== -1 ||
                customer.customer_number.toString().toLowerCase().indexOf(search.toLocaleLowerCase()) !== -1;
                
        })
        return filtered_list;
    };

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    handleExportToCSV = items => {
        const { t } = this.props;
        if (items !== null) {
            const titles = [
                t("Customer group"),
                t("Name"),
                t("Number"),
                t("VAT"),
                t("Description"),
                t("Customer code"),
                t("Address"),
                t("Postal code"),
                t("City"),
                t("Country"),
                t("Phone"),
                t("Email"),
                t("Homepage"),
                `${t("Invoicing")} ${t("Name")}`,
                `${t("Invoicing")} ${t("Address")}`,
                `${t("Invoicing")} ${t("Postal code")}`,
                `${t("Invoicing")} ${t("City")}`,
                `${t("Invoicing")} ${t("Country")}`,
                `${t("Delivery")} ${t("Name")}`,
                `${t("Delivery")} ${t("Address")}`,
                `${t("Delivery")} ${t("Postal code")}`,
                `${t("Delivery")} ${t("City")}`,
                `${t("Delivery")} ${t("Country")}`,
                t("Discount %"),
                t("Vat 0%"),
                t("Vat 0% comment"),
                t("Invoicing method"),
                t("Invoicing Email"),
                t("Payment term"),
                t("Company reference"),
                t("Customer reference"),
                t("Net invoice indentifier"),
                t("Net invoice intermediator"),
                t("Default contract number"),
                t("Default invoice comment"),
                t("Default sensor number"),
                t("Default location number"),
                t("Add customer delivery address to invoice"),
                t("Last modified"),
                t("Is active")
            ];
            // Get only active or archived depending on the selection.
            let exportItems = [];
            let i = 0;
            for (i in items) {
                if (this.state.activeMenuItem.startsWith(this.props.t("Active")) && items[i].is_active) {
                    exportItems.push(items[i]);
                } 
                else if (this.state.activeMenuItem.startsWith(this.props.t("Archive")) && !items[i].is_active) {
                    exportItems.push(items[i]);
                } 
            }
            exportToCsv(this.props.activeCompanyId, "customers", titles, exportItems);
        }
    };

    handleGetFilterListToExport = groups => {
        const {t} = this.props;
        let i = 0;
        let x = 0;
        let items = [];
        for (i in groups) {
            for (x in groups[i].customers) {
                if ((this.state.activeMenuItem.startsWith(t("Active")) && groups[i].customers[x].is_active)) {
                    items.push(groups[i].customers[x]);
                } else if ((this.state.activeMenuItem.startsWith(t("Archive")) && !groups[i].customers[x].is_active)) {
                    items.push(groups[i].customers[x]);
                }
            }
        }
        return this.handleGetFilterList(items);
    };

    // Customer import 
    handleImportItems = () => {
        this.setState({
            import_csv_modal_open: true
        });
    };

    handleCloseModal = () => {
        const updatedFile = {
            ...this.state.file,
            first_row_header: false
        }
        this.setState({
            import_csv_modal_open: false,
            received_items_list: null,
            import_file_rows_total: 0,
            import_disabled: false,
            file: updatedFile,
            import_error: null,
            loading: false
        });
    };

    handleSaveImportItems = () => {
        this.setState({
            loading: true
        });
        const { received_items_list } = this.state;
        const { activeCompanyId } = this.props;
        authAxios
            .post(saveImportedCustomersURL(activeCompanyId), {
                customer_import_list: received_items_list
            })
            .then(res => {
                this.handleCloseModal();
                this.handleShowImportResults(res.data);
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    success_mgs: null,
                    import_error: err.response.data
                });
            });
    };

    handleShowImportResults = result => {
        this.setState({
            imported_customers_count: result.customers_imported,
            import_result_modal_open: true
        });
    };

    handleCloseImportResults = result => {
        this.setState({
            imported_customers_count: 0,
            import_result_modal_open: false,
            refresh_customers: true
        });
    };

    handleFileSelect = (e) => {
        // e.preventDefault();
        // this.fileSelector.click();
        const { file } = this.state;
        const updatedFile = {
            ...file,
            selected_file: e.target.files[0]
        }
        this.setState({
            file: updatedFile,
            success_mgs: null,
            import_error: null
        });
    };

    handleUploadImportFile = e => {
        e.preventDefault();
        this.setState({
            loading: true
        });
        const { file } = this.state;
        const { activeCompanyId, t } = this.props;
        if (file.selected_file === null) {
            this.setState({
                import_error: t("Please select file to upload"),
                success_mgs: null,
                loading: false
            });
        } else {
            let form_data = new FormData();
            form_data.append('file', file.selected_file, file.selected_file.name);

            authFileAxios
                .post(customerImportUploadURL(activeCompanyId), form_data)
                .then(res => {
                    this.setState({
                        received_items_list: res.data.new_items_list,
                        import_file_rows_total: res.data.rows_in_file,
                        loading: false,
                        success_mgs: null,
                        import_error: null,
                        import_disabled: true
                    });
                })
                .catch(err => {
                    this.setState({
                        loading: false,
                        success_mgs: null,
                        import_error: t(err.response.data.ERROR[0], {data: err.response.data.ERROR[1]}),
                        inputKey: Math.random().toString(36)
                    });
                });
        }
    };

    handleChangeImportModalSize = () => {
        this.setState({
            import_modal_size: this.state.import_modal_size === "fullscreen" ? "large" : "fullscreen"
        });
    };

    handleGetExampleFile = () => {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;
        authAxios
            .get(fetchExampleCustomersImportFile(activeCompanyId), { responseType: 'blob' })
            .then(res => {
                //Create a Blob from the PDF Stream
                const file = new Blob(
                    [res.data]);
                //Build a URL from the file
                const fileURL = URL.createObjectURL(file);
                //Open the URL on new Window
                // window.open(fileURL);

                const link = document.createElement('a');
                link.href = fileURL;
                link.setAttribute('download', 'customers-import-example.xlsx'); //or any other extension
                document.body.appendChild(link);
                link.click();
                this.setState({
                    error: null,
                    loading: false
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    error: err
                });
            });
    };

    handleCheckboxChange = (e, { value, checked }) => {
        let { selected } = this.state;
        if (checked) {
            if (!selected.includes(value)) {
                selected.push(value);
            }
        } else {
            if (selected.includes(value)) {
                selected.splice(selected.indexOf(value), 1);
            }
        }
        this.setState({ selected });
    };

    handleSelectAll = customers => {
        const { t } = this.props;
        if (!this.state.select_all) {
            let new_list = [];
            let i = 0;
            for (i in customers) {
                if ((this.state.activeMenuItem.startsWith(t("Active")) && customers[i].is_active)) {
                    new_list.push(customers[i]);
                } else if ((this.state.activeMenuItem.startsWith(t("Archive")) && !customers[i].is_active)) {
                    new_list.push(customers[i]);
                }
            }
            this.setState({
                select_all: true,
                selected: new_list
            })
        }
        else this.setState({ select_all: false, selected: [] });
    };

    handleAmountOfCustomers = status => {
        const { customers } = this.state;
        let amount = 0;
        let i = 0;
        for (i in customers) {
            if (customers[i].is_active && status === "active") {
                amount += 1;
            } else if (!customers[i].is_active && status === "archive") {
                amount += 1;
            }
        }
        return amount;
    };

    render() {
        const { error, loading, customers, customer_group_options, selected_customer_group, search, activeMenuItem, active_customer, modal_open, delete_confirm_open, delete_confirm_text, delete_item, active_customer_addresses, 
            import_csv_modal_open, received_items_list, import_error, import_disabled, import_modal_size, column, direction, selected, multiple_edit, multiple_edit_error, multiple_edit_modal_open} = this.state;
        const { isAuthenticated, t } = this.props;
        if (!isAuthenticated) {
            return <Redirect to="/login" />;
        }
        if (this.state.refresh_customers) {
            this.handleFetchCustomerGroupList();
            this.handleFetchCustomersInGroup(selected_customer_group);
            this.closeModal();
            this.setState({ refresh_customers: false });
        }
        const is_desktop =  getWidth() > Responsive.onlyMobile.maxWidth ? true : false;
        //console.log(this.state);
        return (
            <Shell>
                {is_desktop && (
                    <Button.Group floated="right" basic size='small'>
                        <Button icon="download" content={t("Export (CSV)")} onClick={() => this.handleExportToCSV(customers)} />
                        <Button icon="upload" content={t("Import (Excel)")} onClick={() => this.handleImportItems()}/>
                    </Button.Group>
                )}
                <Header as="h3">{t("Customers")}</Header>
                <Link to="/customer/customer-details/create/">
                    <Button type="button" floated="right" color="green">
                        {t("+ Customer")}
                    </Button>
                </Link>
                <Input
                    floated="right"
                    size="mini"
                    icon='search'
                    value={search}
                    name="search"
                    onChange={this.handleChange} />
                {error && (
                    <Message
                        error
                        header={t("There was an error")}
                        content={JSON.stringify(error)}
                    />
                )}
                {loading && (
                    <Segment>
                        <Dimmer active inverted>
                            <Loader inverted>Loading</Loader>
                        </Dimmer>
                        <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
                    </Segment>
                )}

                {customers && (
                    <React.Fragment>
                        <Menu attached="top" tabular>
                            <Menu.Item
                                name={`${t("Active")} ${this.handleAmountOfCustomers("active")}`}
                                active={activeMenuItem.startsWith(t("Active"))}
                                onClick={this.handleMenuItemClick} />
                            <Menu.Item
                                name={`${t("Archive")} ${this.handleAmountOfCustomers("archive")}`}
                                active={activeMenuItem.startsWith(t("Archive"))}
                                onClick={this.handleMenuItemClick} />
                        </Menu>

                        {is_desktop ? (
                            <>
                                <div style={{float: "right", paddingTop : "5px", paddingBottom: "5px"}}>
                                    <label style={{paddingRight: "5px"}}>{t("Customer group")}:</label>
                                    <Select 
                                        search
                                        name='selected_customer_group'
                                        options={customer_group_options}
                                        placeholder={t("All")}
                                        onChange={this.handleSelectChange}
                                        value={selected_customer_group}
                                    />
                                </div>
                                <Table striped selectable celled sortable>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell icon='check circle outline' collapsing onClick={() => this.handleSelectAll(this.handleGetFilterList(customers))} content={!is_desktop ? (t("Select all")) : ("")} />
                                            
                                            {selected.length > 0 ? (
                                                <Button.Group style={{paddingTop: "5px", paddingLeft: "5px"}}>
                                                    <Button type="button" positive content={t("Edit selected")} icon="edit" onClick={() => this.handleOpenMultiEditModal()}/>
                                                    {activeMenuItem.startsWith(t("Active")) ? (
                                                        <Popup content={t("Deactive customers and move in to archive")} trigger={
                                                            <Button type="button" negative icon='pause circle outline' content={t("Deactivate")} onClick={() => this.handleChangeOrDeleteMultipleCustomers("archive")} />
                                                        } />
                                                    ) : (
                                                        <React.Fragment>
                                                            <Popup content={t("Activate customers")} trigger={
                                                                <Button type="button" positive icon='play circle outline' content={t("Activate")} onClick={() => this.handleChangeOrDeleteMultipleCustomers("activate")} />
                                                            } />
                                                            <Popup content={t("Deletes Customers and related Contracts, Projects and Invoices")} trigger={
                                                                <Button type="button" negative icon='remove circle' content={t("Delete")} onClick={() => this.openDeleteConfirm(t("Are you sure you want to delete selected customers?"), "selected")} />
                                                            } />
                                                        </React.Fragment>
                                                    )}
                                                </Button.Group>
                                            ) : (
                                                <>
                                                    <Table.HeaderCell
                                                        sorted={column === 'customer_number' ? direction : null}
                                                        onClick={this.handleSort('customer_number')}>
                                                            {t("Number")}
                                                    </Table.HeaderCell>
                                                    <Table.HeaderCell
                                                        sorted={column === 'name' ? direction : null}
                                                        onClick={this.handleSort('name')}>
                                                        {t("Name")}
                                                    </Table.HeaderCell>
                                                    <Table.HeaderCell
                                                        sorted={column === 'address' ? direction : null}
                                                        onClick={this.handleSort('address')}>
                                                        {t("Address")}
                                                    </Table.HeaderCell>
                                                    <Table.HeaderCell
                                                        sorted={column === 'vat' ? direction : null}
                                                        onClick={this.handleSort('vat')}>
                                                        {t("VAT")}
                                                    </Table.HeaderCell>
                                                    <Table.HeaderCell collapsing />
                                                </>
                                            )}
                                        </Table.Row>
                                    </Table.Header>

                                    <Table.Body>
                                        
                                    {/* <List> */}
                                        {this.handleGetFilterList(customers).map((customer) => {
                                            if ((activeMenuItem.startsWith(t("Active")) && customer.is_active) || (activeMenuItem.startsWith(t("Archive")) && !customer.is_active)) {
                                                return (
                                                    <Table.Row key={customer.id}>
                                                        <Table.Cell collapsing>
                                                            <Checkbox
                                                                value={customer}
                                                                checked={selected.includes(customer) === true}
                                                                onChange={this.handleCheckboxChange} />
                                                        </Table.Cell>
                                                        <Table.Cell onClick={() => this.openDetails(customer)}>{customer.customer_number}</Table.Cell>
                                                        <Table.Cell onClick={() => this.openDetails(customer)}>
                                                            {customer.name}
                                                        </Table.Cell>
                                                        <Table.Cell onClick={() => this.openDetails(customer)}>{customer.address}, {customer.postal_code} {customer.city}</Table.Cell>
                                                        <Table.Cell onClick={() => this.openDetails(customer)}>{customer.vat}</Table.Cell>
                                                        <Table.Cell onClick={() => this.customerDetails(customer.id)}><Icon link name="edit"/></Table.Cell>
                                                    </Table.Row>
                                                );
                                            }
                                        })}
                                        {/* </List> */}
                                    </Table.Body>
                                </Table>
                            </>
                        ) : (
                            <List>
                                {this.handleGetFilterList(customers).map((customer) => {
                                    if ((activeMenuItem.startsWith(t("Active")) && customer.is_active) || (activeMenuItem.startsWith(t("Archive")) && !customer.is_active)) {
                                        return (
                                            <React.Fragment>
                                                <List.Item as="a" onClick={() => this.openDetails(customer)}>
                                                    <List >
                                                        <List.Item>
                                                            <List.Content>
                                                                <List.Header as="h4">{customer.name} <Header as="h4" floated="right">({customer.customer_number})</Header></List.Header>
                                                                </List.Content>
                                                        </List.Item>
                                                        <List.Item>
                                                            <List.Content>
                                                                <List.Description>{t("Address")}</List.Description>
                                                                <List.Header>{customer.address}, {customer.postal_code} {customer.city}</List.Header>
                                                            </List.Content>
                                                        </List.Item>
                                                        {customer.vat !== "" && (
                                                            <List.Item>
                                                                <List.Content>
                                                                    <List.Description>{t("VAT")}</List.Description>
                                                                    <List.Header>{customer.vat}</List.Header>
                                                                </List.Content>
                                                            </List.Item>
                                                        )}
                                                    </List>
                                                </List.Item>
                                                <hr/>
                                            </React.Fragment>
                                        );
                                    }
                                })}
                            </List>
                        )}
                        <Confirm
                            content={delete_confirm_text}
                            open={delete_confirm_open}
                            onCancel={() => this.closeDeleteConfirm()}
                            onConfirm={() => this.handleDeleteCustomer(delete_item)}
                        />
                        <Confirm
                            content={this.state.multiple_edit_confirm_text}
                            open={this.state.multiple_edit_confirm_open}
                            onCancel={() => this.handleCloseMultipleEditConfirm()}
                            onConfirm={() => this.handleSubmitMultiEdit(this.state.multiple_edit_confirm_item)}
                        />
                    </React.Fragment>
                )
                }
                {active_customer && (
                    < Modal size='large' open={modal_open} dimmer="inverted" scrolling="true">
                        <Modal.Header>
                            <Header as="h3">{t("Customer")} {active_customer.name}</Header>
                            <Button type="button" positive icon='edit' content={t("Edit")} loading={loading} onClick={() => this.customerDetails(active_customer.id)} />
                            {is_desktop && (
                                <React.Fragment>
                                    {active_customer.is_active ? (
                                        <Popup content={t("Deactive customer and move in to archive")} trigger={
                                            <Button type="button" negative icon='pause circle outline' content={t("Deactivate")} onClick={() => this.changeActiveStatus(active_customer)} />
                                        } />
                                    ) : (
                                        <React.Fragment>
                                            <Popup content={t("Activate customer")} trigger={
                                                <Button type="button" positive icon='play circle outline' content={t("Activate")} onClick={() => this.changeActiveStatus(active_customer)} />
                                            } />
                                            <Popup content={t("Deletes Customer and related Contracts, Projects and Invoices")} trigger={
                                                <Button type="button" negative icon='remove circle' content={t("Delete")} onClick={() => this.openDeleteConfirm(t("Are you sure you want to delete", { customer_name: active_customer.name }), active_customer.id)} />
                                            } />
                                        </React.Fragment>
                                    )}
                                </React.Fragment>
                            )}
                            <Button type="button" negative floated="right" onClick={() => this.closeModal()} >{t("Close")}</Button>
                        </Modal.Header>
                        <Modal.Content>
                            <Container>
                                {is_desktop ? (
                                    <React.Fragment>
                                    <Grid columns="2">
                                        <Grid.Row>
                                            <Grid.Column >
                                                <Header as="h4">{t("Basic details")}</Header>
                                                <Card fluid>
                                                    <Card.Content>
                                                        <Card.Description>
                                                            <Grid>
                                                                <GridColumn>
                                                                    <Table  basic="very" size="small">
                                                                        <Table.Body>
                                                                            <Table.Row>
                                                                                <Table.Cell><Header as="h5">{t("Customer group")}</Header></Table.Cell>
                                                                                <Table.Cell>{active_customer.customer_group.name} </Table.Cell>
                                                                            </Table.Row>
                                                                            <Table.Row>
                                                                                <Table.Cell><Header as="h5">{t("Vat")}</Header></Table.Cell>
                                                                                <Table.Cell>{active_customer.vat} </Table.Cell>
                                                                            </Table.Row>
                                                                            <Table.Row>
                                                                                <Table.Cell><Header as="h5">{t("Name")}</Header></Table.Cell>
                                                                                <Table.Cell>{active_customer.name}</Table.Cell>
                                                                            </Table.Row>
                                                                            <Table.Row>
                                                                                <Table.Cell><Header as="h5">{t("Description")}</Header></Table.Cell>
                                                                                <Table.Cell>{active_customer.description}</Table.Cell>
                                                                            </Table.Row>
                                                                            <Table.Row>
                                                                                <Table.Cell><Header as="h5">{t("Customer number")}</Header></Table.Cell>
                                                                                <Table.Cell>{active_customer.customer_number} </Table.Cell>
                                                                            </Table.Row>
                                                                            <Table.Row>
                                                                                <Table.Cell><Header as="h5">{t("Customer code")}</Header></Table.Cell>
                                                                                <Table.Cell>{active_customer.customer_code} </Table.Cell>
                                                                            </Table.Row>
                                                                            <Table.Row>
                                                                                <Table.Cell><Header as="h5">{t("Last modified")}</Header></Table.Cell>
                                                                                <Table.Cell>{new Date(active_customer.last_modified).toLocaleString('fi-FI')} </Table.Cell>
                                                                            </Table.Row>
                                                                            <Table.Row>
                                                                                <Table.Cell><Header as="h5">{t("Is active")}</Header></Table.Cell>
                                                                                <Table.Cell>{active_customer.is_active ? (t("Yes")) : (t("No"))} </Table.Cell>
                                                                            </Table.Row>
                                                                        </Table.Body>
                                                                    </Table>
                                                                </GridColumn>
                                                            </Grid>
                                                        </Card.Description>
                                                    </Card.Content>
                                                </Card>
                                            </Grid.Column>
                                            <Grid.Column >
                                                {/* <Header as="h4">{t("Address details")}</Header> */}
                                                <br/>
                                                <Card fluid>
                                                    <Card.Content>
                                                        <Card.Description>
                                                            <Grid>
                                                                <GridColumn>
                                                                    <Table  basic="very" size="small">
                                                                        <Table.Body>
                                                                            <Table.Row>
                                                                                <Table.Cell><Header as="h5">{t("Address")}</Header></Table.Cell>
                                                                                <Table.Cell>{active_customer.address} </Table.Cell>
                                                                            </Table.Row>
                                                                            <Table.Row>
                                                                                <Table.Cell><Header as="h5">{t("Postal code")}</Header></Table.Cell>
                                                                                <Table.Cell>{active_customer.postal_code}</Table.Cell>
                                                                            </Table.Row>
                                                                            <Table.Row>
                                                                                <Table.Cell><Header as="h5">{t("City")}</Header></Table.Cell>
                                                                                <Table.Cell>{active_customer.city}</Table.Cell>
                                                                            </Table.Row>
                                                                            <Table.Row>
                                                                                <Table.Cell><Header as="h5">{t("Country")}</Header></Table.Cell>
                                                                                <Table.Cell>{active_customer.country}</Table.Cell>
                                                                            </Table.Row>
                                                                            <Table.Row>
                                                                                <Table.Cell><Header as="h5">{t("Phone")}</Header></Table.Cell>
                                                                                <Table.Cell>{active_customer.phone}</Table.Cell>
                                                                            </Table.Row>
                                                                            <Table.Row>
                                                                                <Table.Cell><Header as="h5">{t("Email")}</Header></Table.Cell>
                                                                                <Table.Cell><a href={`mailto:${active_customer.email}`}>{active_customer.email}</a></Table.Cell>
                                                                            </Table.Row>
                                                                            <Table.Row>
                                                                                <Table.Cell><Header as="h5">{t("Homepage")}</Header></Table.Cell>
                                                                                <Table.Cell><a href={`http://${active_customer.homepage}`}>{active_customer.homepage}</a></Table.Cell>
                                                                            </Table.Row>
                                                                        </Table.Body>
                                                                    </Table>
                                                                </GridColumn>
                                                            </Grid>
                                                            <br />
                                                            <br/>
                                                        </Card.Description>
                                                    </Card.Content>
                                                </Card>
                                            </Grid.Column>
                                        </Grid.Row>
                                        {active_customer_addresses && (
                                            <Grid.Row>
                                                {active_customer_addresses.map((address, key) => {
                                                    return (
                                                        <Grid.Column key={key}>
                                                            {address.address_type === "I" ? (<Header as="h4">{t("Invoicing address")}</Header>) : (<Header as="h4">{t("Delivery address")}</Header>)}
                                                            <Card fluid>
                                                                <Card.Content>
                                                                    <Card.Description>
                                                                        <Grid>
                                                                            <GridColumn>
                                                                                <Table  basic="very" size="small">
                                                                                    <Table.Body>
                                                                                        <Table.Row>
                                                                                            <Table.Cell><Header as="h5">{t("Name")}</Header></Table.Cell>
                                                                                            <Table.Cell>{address.name} </Table.Cell>
                                                                                        </Table.Row>
                                                                                        <Table.Row>
                                                                                            <Table.Cell><Header as="h5">{t("Address")}</Header></Table.Cell>
                                                                                            <Table.Cell>{address.address} </Table.Cell>
                                                                                        </Table.Row>
                                                                                        <Table.Row>
                                                                                            <Table.Cell><Header as="h5">{t("Postal code")}</Header></Table.Cell>
                                                                                            <Table.Cell>{address.postal_code}</Table.Cell>
                                                                                        </Table.Row>
                                                                                        <Table.Row>
                                                                                            <Table.Cell><Header as="h5">{t("City")}</Header></Table.Cell>
                                                                                            <Table.Cell>{address.city}</Table.Cell>
                                                                                        </Table.Row>
                                                                                        <Table.Row>
                                                                                            <Table.Cell><Header as="h5">{t("Country")}</Header></Table.Cell>
                                                                                            <Table.Cell>{address.country}</Table.Cell>
                                                                                        </Table.Row>
                                                                                    </Table.Body>
                                                                                </Table>
                                                                            </GridColumn>
                                                                        </Grid>
                                                                    </Card.Description>
                                                                </Card.Content>
                                                            </Card>
                                                        </Grid.Column>
                                                    );
                                                })}
                                            </Grid.Row>
                                        )}
                                        <Grid.Row>
                                            <Grid.Column >
                                                <Header as="h4">{t("Invoicing details")}</Header>
                                                <Card fluid>
                                                    <Card.Content>
                                                        <Card.Description>
                                                            <Grid>
                                                                <GridColumn>
                                                                    <Table  basic="very" size="small">
                                                                        <Table.Body>
                                                                            <Table.Row>
                                                                                <Table.Cell><Header as="h5">{t("Discount %")}</Header></Table.Cell>
                                                                                <Table.Cell>{active_customer.customerinvoicingdetail.discount}%</Table.Cell>
                                                                            </Table.Row>
                                                                            <Table.Row>
                                                                                <Table.Cell><Header as="h5">{t("Vat 0%")}</Header></Table.Cell>
                                                                                <Table.Cell>{active_customer.customerinvoicingdetail.no_vat ? (t("Yes")) : (t("No"))}</Table.Cell>
                                                                            </Table.Row>
                                                                            <Table.Row>
                                                                                <Table.Cell><Header as="h5">{t("Vat 0% comment")}</Header></Table.Cell>
                                                                                <Table.Cell>{active_customer.customerinvoicingdetail.no_vat_comment}</Table.Cell>
                                                                            </Table.Row>
                                                                            <Table.Row>
                                                                                <Table.Cell><Header as="h5">{t("Invoicing method")}</Header></Table.Cell>
                                                                                <Table.Cell>
                                                                                    {active_customer.customerinvoicingdetail.invoicing_method === "P" && t("Paper")}
                                                                                    {active_customer.customerinvoicingdetail.invoicing_method === "E" && t("Email")}
                                                                                    {active_customer.customerinvoicingdetail.invoicing_method === "N" && t("Net Invoice")}
                                                                                </Table.Cell>
                                                                            </Table.Row>
                                                                            <Table.Row>
                                                                                <Table.Cell><Header as="h5">{t("Invoicing Email")}</Header></Table.Cell>
                                                                                <Table.Cell>{active_customer.customerinvoicingdetail.invoicing_email}</Table.Cell>
                                                                            </Table.Row>
                                                                        </Table.Body>
                                                                    </Table>
                                                                </GridColumn>
                                                            </Grid>
                                                        </Card.Description>
                                                    </Card.Content>
                                                </Card>
                                            </Grid.Column>
                                            <Grid.Column >
                                                <Header as="h4">&nbsp; </Header>
                                                <Card fluid>
                                                    <Card.Content>
                                                        <Card.Description>
                                                            <Grid>
                                                                <GridColumn>
                                                                    <Table  basic="very" size="small">
                                                                        <Table.Body>
                                                                            <Table.Row>
                                                                                <Table.Cell><Header as="h5">{t("Payment term")}</Header></Table.Cell>
                                                                                <Table.Cell>{active_customer.customerinvoicingdetail.payment_term}</Table.Cell>
                                                                            </Table.Row>
                                                                            <Table.Row>
                                                                                <Table.Cell><Header as="h5">{t("Company reference")}</Header></Table.Cell>
                                                                                <Table.Cell>{active_customer.customerinvoicingdetail.default_company_reference}</Table.Cell>
                                                                            </Table.Row>
                                                                            <Table.Row>
                                                                                <Table.Cell><Header as="h5">{t("Customer reference")}</Header></Table.Cell>
                                                                                <Table.Cell>{active_customer.customerinvoicingdetail.default_customer_reference}</Table.Cell>
                                                                            </Table.Row>
                                                                            <Table.Row>
                                                                                <Table.Cell><Header as="h5">{t("Net invoice indentifier")}</Header></Table.Cell>
                                                                                <Table.Cell>{active_customer.customerinvoicingdetail.net_invoice_indentifier}</Table.Cell>
                                                                            </Table.Row>
                                                                            <Table.Row>
                                                                                <Table.Cell><Header as="h5">{t("Net invoice intermediator")}</Header></Table.Cell>
                                                                                <Table.Cell>{active_customer.customerinvoicingdetail.net_invoice_intermediator}</Table.Cell>
                                                                            </Table.Row>
                                                                        </Table.Body>
                                                                    </Table>
                                                                </GridColumn>
                                                            </Grid>
                                                        </Card.Description>
                                                    </Card.Content>
                                                </Card>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                    <br />
                                    <hr />
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        <Header as="h4">{t("Basic details")}</Header>
                                        <List divided>
                                            <List.Item>
                                                <List.Header as="h5">{t("Customer group")}</List.Header>
                                                <List.Description>{active_customer.customer_group.name}</List.Description>
                                            </List.Item>
                                            <List.Item>
                                                <List.Header as="h5">{t("Vat")}</List.Header>
                                                <List.Description>{active_customer.vat}</List.Description>
                                            </List.Item>
                                            <List.Item>
                                                <List.Header as="h5">{t("Name")}</List.Header>
                                                <List.Description>{active_customer.name}</List.Description>
                                            </List.Item>
                                            <List.Item>
                                                <List.Header as="h5">{t("Description")}</List.Header>
                                                <List.Description>{active_customer.description}</List.Description>
                                            </List.Item>
                                            <List.Item>
                                                <List.Header as="h5">{t("Customer number")}</List.Header>
                                                <List.Description>{active_customer.customer_number}</List.Description>
                                            </List.Item>
                                            <List.Item>
                                                <List.Header as="h5">{t("Customer code")}</List.Header>
                                                <List.Description>{active_customer.customer_code}</List.Description>
                                            </List.Item>
                                            <List.Item>
                                                <List.Header as="h5">{t("Last modified")}</List.Header>
                                                <List.Description>{new Date(active_customer.last_modified).toLocaleString('fi-FI')}</List.Description>
                                            </List.Item>
                                            <List.Item>
                                                <List.Header as="h5">{t("Is active")}</List.Header>
                                                <List.Description>{active_customer.is_active ? (t("Yes")) : (t("No"))}</List.Description>
                                            </List.Item>
                                        </List>
                                        <List divided>
                                            <List.Item>
                                                <List.Header as="h5">{t("Address")}</List.Header>
                                                <List.Description>{active_customer.address}</List.Description>
                                            </List.Item>
                                            <List.Item>
                                                <List.Header as="h5">{t("Postal code")}</List.Header>
                                                <List.Description>{active_customer.postal_code}</List.Description>
                                            </List.Item>
                                            <List.Item>
                                                <List.Header as="h5">{t("City")}</List.Header>
                                                <List.Description>{active_customer.city}</List.Description>
                                            </List.Item>
                                            <List.Item>
                                                <List.Header as="h5">{t("Country")}</List.Header>
                                                <List.Description>{active_customer.country.name}</List.Description>
                                            </List.Item>
                                            <List.Item>
                                                <List.Header as="h5">{t("Phone")}</List.Header>
                                                <List.Description>{active_customer.phone}</List.Description>
                                            </List.Item>
                                            <List.Item>
                                                <List.Header as="h5">{t("Email")}</List.Header>
                                                <List.Description><a href={`mailto:${active_customer.email}`}>{active_customer.email}</a></List.Description>
                                            </List.Item>
                                            <List.Item>
                                                <List.Header as="h5">{t("Homepage")}</List.Header>
                                                <List.Description><a href={`http://${active_customer.homepage}`}>{active_customer.homepage}</a></List.Description>
                                            </List.Item>
                                        </List>
                                        {active_customer_addresses && (
                                            <React.Fragment>
                                                {active_customer_addresses.map((address, key) => {
                                                    return (
                                                        <React.Fragment>
                                                            {address.address_type === "I" ? (<Header as="h4">{t("Invoicing address")}</Header>) : (<Header as="h4">{t("Delivery address")}</Header>)}
                                                            <List divided>
                                                                <List.Item>
                                                                    <List.Header as="h5">{t("Name")}</List.Header>
                                                                    <List.Description>{address.name}</List.Description>
                                                                </List.Item>
                                                                <List.Item>
                                                                    <List.Header as="h5">{t("Address")}</List.Header>
                                                                    <List.Description>{address.address}</List.Description>
                                                                </List.Item>
                                                                <List.Item>
                                                                    <List.Header as="h5">{t("Postal code")}</List.Header>
                                                                    <List.Description>{address.postal_code}</List.Description>
                                                                </List.Item>
                                                                <List.Item>
                                                                    <List.Header as="h5">{t("City")}</List.Header>
                                                                    <List.Description>{address.city}</List.Description>
                                                                </List.Item>
                                                                <List.Item>
                                                                    <List.Header as="h5">{t("Country")}</List.Header>
                                                                    <List.Description>{address.country}</List.Description>
                                                                </List.Item>
                                                            </List>
                                                        </React.Fragment>
                                                    );
                                                })}
                                            </React.Fragment>
                                        )}
                                        <Header as="h4">{t("Invoicing details")}</Header>
                                        <List divided>
                                            <List.Item>
                                                <List.Header as="h5">{t("Discount %")}</List.Header>
                                                <List.Description>{active_customer.customerinvoicingdetail.discount}%</List.Description>
                                            </List.Item>
                                            <List.Item>
                                                <List.Header as="h5">{t("Vat 0%")}</List.Header>
                                                <List.Description>{active_customer.customerinvoicingdetail.no_vat ? (t("Yes")) : (t("No"))}</List.Description>
                                            </List.Item>
                                            <List.Item>
                                                <List.Header as="h5">{t("Vat 0% comment")}</List.Header>
                                                <List.Description>{active_customer.customerinvoicingdetail.no_vat_comment}</List.Description>
                                            </List.Item>
                                            <List.Item>
                                                <List.Header as="h5">{t("Invoicing method")}</List.Header>
                                                <List.Description>
                                                    {active_customer.customerinvoicingdetail.invoicing_method === "P" && t("Paper")}
                                                    {active_customer.customerinvoicingdetail.invoicing_method === "E" && t("Email")}
                                                    {active_customer.customerinvoicingdetail.invoicing_method === "N" && t("Net Invoice")}
                                                </List.Description>
                                            </List.Item>
                                            <List.Item>
                                                <List.Header as="h5">{t("Invoicing Email")}</List.Header>
                                                <List.Description>{active_customer.customerinvoicingdetail.invoicing_email}</List.Description>
                                            </List.Item>
                                            <List.Item>
                                                <List.Header as="h5">{t("Payment term")}</List.Header>
                                                <List.Description>{active_customer.customerinvoicingdetail.payment_term}</List.Description>
                                            </List.Item>
                                            <List.Item>
                                                <List.Header as="h5">{t("Company reference")}</List.Header>
                                                <List.Description>{active_customer.customerinvoicingdetail.default_company_reference}</List.Description>
                                            </List.Item>
                                            <List.Item>
                                                <List.Header as="h5">{t("Customer reference")}</List.Header>
                                                <List.Description>{active_customer.customerinvoicingdetail.default_customer_reference}</List.Description>
                                            </List.Item>
                                            <List.Item>
                                                <List.Header as="h5">{t("Net invoice indentifier")}</List.Header>
                                                <List.Description>{active_customer.customerinvoicingdetail.net_invoice_indentifier}</List.Description>
                                            </List.Item>
                                            <List.Item>
                                                <List.Header as="h5">{t("Net invoice intermediator")}</List.Header>
                                                <List.Description>{active_customer.customerinvoicingdetail.net_invoice_intermediator}</List.Description>
                                            </List.Item>
                                        </List>
                                        <br/>
                                    </React.Fragment>
                                )}
                            </Container>
                            <Container>
                                <Header as="h4">{t("Contracts")}</Header>
                                {is_desktop ? (
                                    <Table striped selectable celled>
                                        <Table.Header>
                                            <Table.Row>
                                                <Table.HeaderCell collapsing>{t("Number")}</Table.HeaderCell>
                                                <Table.HeaderCell collapsing>{t("Customer")}</Table.HeaderCell>
                                                <Table.HeaderCell collapsing>{t("Name")}</Table.HeaderCell>
                                                <Table.HeaderCell collapsing>{t("Invoicing type")}</Table.HeaderCell>
                                                <Table.HeaderCell collapsing>{t("Started")}</Table.HeaderCell>
                                                <Table.HeaderCell collapsing>{t("Ending")}</Table.HeaderCell>
                                                <Table.HeaderCell collapsing>{t("Next invoice")}</Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>

                                        {active_customer.contracts.length > 0 && (
                                            <Table.Body>
                                                {active_customer.contracts.map((contract, key) => {
                                                    return (
                                                        <Table.Row key={key} onClick={() => this.contractDetails(contract.id)}>
                                                            <Table.Cell collapsing >{contract.number}</Table.Cell>
                                                            <Table.Cell collapsing >{contract.customer.name}</Table.Cell>
                                                            <Table.Cell collapsing >{contract.name}</Table.Cell>
                                                            <Table.Cell collapsing >{contract.invoicing_type === "B" ? (t("Before period")) : (t("After period"))}</Table.Cell>
                                                            <Table.Cell collapsing >{new Date(contract.start_date).toLocaleString('fi-FI', { dateStyle: 'short' })}</Table.Cell>
                                                            <Table.Cell collapsing >{contract.end_date === null ? "" : new Date(contract.end_date).toLocaleString('fi-FI', { dateStyle: 'short' })}</Table.Cell>
                                                            <Table.Cell collapsing >{new Date(contract.next_invoice_date).toLocaleString('fi-FI', { dateStyle: 'short' })}</Table.Cell>
                                                        </Table.Row>
                                                    );
                                                })}
                                            </Table.Body>
                                        )}
                                    </Table>
                                ) : (
                                    <React.Fragment>
                                        {active_customer.contracts.length > 0 && (
                                            <List divided>
                                                {active_customer.contracts.map((contract, key) => {
                                                    return (
                                                        // <List.Item as="a" onClick={() => this.contractDetails(contract.id)}>
                                                        <List.Item >
                                                            <List.Content>
                                                                <List.Header as="h4">{contract.customer.name} <Header as="h4" floated="right">({contract.number})</Header></List.Header>
                                                                <List.Header as="h5">{contract.name}</List.Header>
                                                                <List horizontal relaxed="very">
                                                                    <List.Item>
                                                                        <List.Description>{t("Started")}</List.Description>
                                                                        <List.Header>{new Date(contract.start_date).toLocaleString('fi-FI', { dateStyle: 'short' })}</List.Header>
                                                                    </List.Item>
                                                                    <List.Item>
                                                                        <List.Description>{t("Next invoice")}</List.Description>
                                                                        <List.Header>{new Date(contract.next_invoice_date).toLocaleString('fi-FI', { dateStyle: 'short' })}</List.Header>
                                                                    </List.Item>
                                                                </List>
                                                                {contract.end_date && (
                                                                    <List.Item>
                                                                        <List.Description>{t("Ending")}</List.Description>
                                                                        <List.Header>{new Date(contract.end_date).toLocaleString('fi-FI', { dateStyle: 'short' })}</List.Header>
                                                                    </List.Item>
                                                                )}
                                                            </List.Content>
                                                        </List.Item>
                                                    );
                                                })}
                                            </List>
                                        )}
                                    </React.Fragment>
                                )}
                            </Container>
                            {active_customer.invoices.length > 0 && (
                                <React.Fragment>
                                    <br />
                                    <hr />
                                    <Container>
                                        {is_desktop ? (
                                            <React.Fragment>
                                                <Header as="h4">{t("Customer Invoices")}</Header>
                                                <Table striped selectable sortable celled >
                                                    <Table.Header>
                                                        <Table.Row>
                                                            <Table.HeaderCell>{t("Name")}</Table.HeaderCell>
                                                            <Table.HeaderCell>{t("Invoice date")}</Table.HeaderCell>
                                                            <Table.HeaderCell>{t("Due date")}</Table.HeaderCell>
                                                            <Table.HeaderCell>{t("Net €")}</Table.HeaderCell>
                                                            <Table.HeaderCell>{t("Gross €")}</Table.HeaderCell>
                                                            <Table.HeaderCell>{t("Status")}</Table.HeaderCell>
                                                        </Table.Row>
                                                    </Table.Header>

                                                    <Table.Body>
                                                        {active_customer.invoices.map(invoice => {
                                                            return (
                                                                <Table.Row key={invoice.id} onClick={() => this.invoiceDetails(invoice.id)}>
                                                                    <Table.Cell >
                                                                        <b>{invoice.customer.name}</b>
                                                                        <Header sub floated="right">({invoice.number})</Header><br />
                                                                        <span>{invoice.name}</span>
                                                                        {invoice.contract.number !== null && (
                                                                            <Link to={`/contract/contract-details/${invoice.contract.id}/`}>
                                                                                <Header sub color="blue" as="h6" floated="right">
                                                                                    <Popup content={`${t("Contract")} ${invoice.contract.name} (${invoice.contract.number})`} trigger={
                                                                                        <Icon fitted link name='sync alternate' size='tiny' />
                                                                                    } />
                                                                                </Header>
                                                                            </Link>
                                                                            )}
                                                                        {invoice.invoiceabnormalpayments.length > 0 && (
                                                                            <Header sub color={parseFloat((this.handleGetAbnormalSum(invoice) - getTotalGrossPrice(invoice.invoiceitems)).toFixed(2)) === parseFloat(0) ? "blue" : "red"} as="h6" floated="right">
                                                                                <Popup content={`${t("Abnormal payments. Current balance")} ${(this.handleGetAbnormalSum(invoice) - getTotalGrossPrice(invoice.invoiceitems)).toFixed(2)} €`} trigger={
                                                                                    <Icon fitted name='info' size='tiny' />
                                                                                } />
                                                                            </Header>
                                                                        )}
                                                                        {this.handleCheckInternalComments(invoice) && (<Popup content={t("Internal comments added")} trigger={<Header as="h6" floated="right" icon="comments outline" />} />)}
                                                                        {this.handleCheckErrorComments(invoice) && (<Popup content={t("Error comments in invoice")} trigger={<Header sub color="red" as="h6" floated="right" icon="attention" />} />)}
                                                                    </Table.Cell>
                                                                    <Table.Cell collapsing >{new Date(invoice.date).toLocaleString('fi-FI', { dateStyle: 'short' })}</Table.Cell>
                                                                    <Table.Cell collapsing >{new Date(invoice.due_date).toLocaleString('fi-FI', { dateStyle: 'short' })}</Table.Cell>
                                                                    <Table.Cell collapsing >{getTotalNetPrice(invoice.invoiceitems).toFixed(2)} €</Table.Cell>
                                                                    <Table.Cell collapsing ><Header as="h5">{getTotalGrossPrice(invoice.invoiceitems).toFixed(2)} €</Header></Table.Cell>
                                                                    <Table.Cell collapsing>
                                                                        <Header color={invoice.status === "O" && ("red") || invoice.status === "P" && ("green")} as="h5">
                                                                            {invoice.status === "C" && (t("Created status"))}
                                                                            {invoice.status === "A" && (t("Approved status"))}
                                                                            {invoice.status === "S" && (t("Sent status"))}
                                                                            {invoice.status === "P" && (t("Paid status"))}
                                                                            {invoice.status === "O" && (t("Overdue status"))}
                                                                        </Header>
                                                                    </Table.Cell>
                                                                </Table.Row>
                                                            );
                                                        })}
                                                        <Table.Row>
                                                            <Table.Cell />
                                                            <Table.Cell />
                                                            <Table.Cell />
                                                            <Table.Cell collapsing>
                                                                <Header as="h5">{this.getTotalNetPriceAllInvoices(active_customer.invoices).toFixed(2)} €</Header>
                                                            </Table.Cell>
                                                            <Table.Cell collapsing>
                                                                <Header as="h5">{this.getTotalGrossPriceAllInvoices(active_customer.invoices).toFixed(2)} €</Header>
                                                            </Table.Cell>
                                                            <Table.Cell />
                                                        </Table.Row>
                                                    </Table.Body>
                                                </Table>
                                            </React.Fragment>
                                        ) : (
                                            <React.Fragment>
                                                <List>
                                                    <List.Item>
                                                        <List.Header as="h4">{t("Customer Invoices")} </List.Header>
                                                        <List.Header as="h4">
                                                            {active_customer.invoices.length} {t("pcs")} ({this.getTotalNetPriceAllInvoices(active_customer.invoices).toFixed(2)} €) {this.getTotalGrossPriceAllInvoices(active_customer.invoices).toFixed(2)} €
                                                        </List.Header>
                                                    </List.Item>
                                                </List>
                                                <List  divided >
                                                    {active_customer.invoices.map(invoice => {
                                                        return (
                                                            <List.Item >
                                                                <List.Content>
                                                                    <List.Header>
                                                                        <b>{invoice.customer.name}</b>
                                                                        <Header sub floated="right">({invoice.number})</Header><br />
                                                                        <span>{invoice.name}</span>
                                                                        {invoice.contract.number !== null && (
                                                                            <Link to={`/contract/contract-details/${invoice.contract.id}/`}>
                                                                                <Header sub color="blue" as="h6" floated="right">
                                                                                    <Popup content={`${t("Contract")} ${invoice.contract.name} (${invoice.contract.number})`} trigger={
                                                                                        <Icon fitted link name='sync alternate' size='tiny' />
                                                                                    } />
                                                                                </Header>
                                                                            </Link>
                                                                            )}
                                                                        {invoice.invoiceabnormalpayments.length > 0 && (
                                                                            <Header sub color={parseFloat((this.handleGetAbnormalSum(invoice) - getTotalGrossPrice(invoice.invoiceitems)).toFixed(2)) === parseFloat(0) ? "blue" : "red"} as="h6" floated="right">
                                                                                <Popup content={`${t("Abnormal payments. Current balance")} ${(this.handleGetAbnormalSum(invoice) - getTotalGrossPrice(invoice.invoiceitems)).toFixed(2)} €`} trigger={
                                                                                    <Icon fitted name='info' size='tiny' />
                                                                                } />
                                                                            </Header>
                                                                        )}
                                                                        {this.handleCheckInternalComments(invoice) && (<Popup content={t("Internal comments added")} trigger={<Header as="h6" floated="right" icon="comments outline" />} />)}
                                                                        {this.handleCheckErrorComments(invoice) && (<Popup content={t("Error comments in invoice")} trigger={<Header sub color="red" as="h6" floated="right" icon="attention" />} />)}
                                                                    </List.Header>
                                                                </List.Content>
                                                                <List.Item>
                                                                <List horizontal relaxed="very">
                                                                    <List.Item>
                                                                        <List.Description>{t("Invoice date")}</List.Description>
                                                                        <List.Header>{new Date(invoice.date).toLocaleString('fi-FI', { dateStyle: 'short' })}</List.Header>
                                                                    </List.Item>
                                                                    <List.Item>
                                                                        <List.Description>{t("Due date")}</List.Description>
                                                                        <List.Header>{new Date(invoice.due_date).toLocaleString('fi-FI', { dateStyle: 'short' })}</List.Header>
                                                                    </List.Item>
                                                                </List>
                                                                </List.Item>
                                                                <List.Item>
                                                                <List horizontal relaxed="very">
                                                                    <List.Item>
                                                                        <List.Description>{t("Net €")}</List.Description>
                                                                        <List.Header>{getTotalNetPrice(invoice.invoiceitems).toFixed(2)} €</List.Header>
                                                                    </List.Item>
                                                                    <List.Item>
                                                                        <List.Description>{t("Gross €")}</List.Description>
                                                                        <List.Header>{getTotalGrossPrice(invoice.invoiceitems).toFixed(2)} €</List.Header>
                                                                    </List.Item>
                                                                    <List.Description>
                                                                        <Header color={invoice.status === "O" && ("red") || invoice.status === "P" && ("green")} as="h5">
                                                                            {invoice.status === "C" && (t("Created status"))}
                                                                            {invoice.status === "A" && (t("Approved status"))}
                                                                            {invoice.status === "S" && (t("Sent status"))}
                                                                            {invoice.status === "P" && (t("Paid status"))}
                                                                            {invoice.status === "O" && (t("Overdue status"))}
                                                                        </Header>
                                                                    </List.Description>
                                                                </List>
                                                                </List.Item>
                                                            </List.Item>
                                                        );
                                                    })}
                                                </List>
                                            </React.Fragment>
                                        )}
                                    </Container>
                                </React.Fragment>
                            )}
                        </Modal.Content>
                        <Modal.Actions>
                            <Button type="button" negative onClick={() => this.closeModal()} >{t("Close")}</Button>
                        </Modal.Actions>
                    </Modal >
                )
                }
                <Modal size={import_modal_size} open={import_csv_modal_open} dimmer="inverted" >
                    {loading && (
                        <Segment>
                            <Dimmer active inverted>
                                <Loader inverted>Loading</Loader>
                            </Dimmer>
                            <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
                        </Segment>
                    )}

                    <Modal.Header>
                        <div style={{float: "right"}}>
                                <Icon link name={import_modal_size === "fullscreen" ? "compress" : "expand"} onClick={() => this.handleChangeImportModalSize()}/>
                                <Icon link name="x" onClick={() => this.handleCloseModal()}/>
                        </div>
                        <Header as="h3">{t("Import customers")}</Header>
                        
                    </Modal.Header>
                    <Modal.Content scrolling>
                            <Header as="h4">{t("Select file to import. Supported format is (.xlsx) Excel")}</Header>
                            <Form error={import_error !== null}>
                                <Form.Field>
                                    <Input disabled={import_disabled} type="file" id="file" accept=".xlsx" key={this.state.inputKey} onChange={this.handleFileSelect} />
                                </Form.Field>
                                <br/>
                                <i>{t("Please use Import customers template file to import multiple customers. Mandatory fields are marked in the file title row.")}</i><br/>
                                <Link onClick={this.handleGetExampleFile}>{t("Import customers template")}</Link><br/>
                                <i>{t("The content of the file is validated first and based on the result you can choose to complete the import or cancel.")}</i><br/><br/>
                                {import_error && <Message error header={t("Error")} content={import_error} />}
                                <Button primary type="button" content={t("Upload file")} onClick={(e) => this.handleUploadImportFile(e)} loading={loading} disabled={loading || import_disabled || import_error}/>
                            </Form>
                            {received_items_list && (
                                <React.Fragment>
                                    <Header as="h4">{t("Rows in import file")}: {received_items_list.length}</Header>
                                    <Table striped selectable sortable celled >
                                        {is_desktop && (
                                            <Table.Header>
                                                <Table.Row >
                                                    <Table.HeaderCell>{t("Name")}</Table.HeaderCell>
                                                    <Table.HeaderCell>{t("VAT")}</Table.HeaderCell>
                                                    <Table.HeaderCell>{t("Description")}</Table.HeaderCell>
                                                    <Table.HeaderCell>{t("Customer code")}</Table.HeaderCell>
                                                    <Table.HeaderCell>{t("Address")}</Table.HeaderCell>
                                                    <Table.HeaderCell>{t("Postal code")}</Table.HeaderCell>
                                                    <Table.HeaderCell>{t("City")}</Table.HeaderCell>
                                                    <Table.HeaderCell>{t("Country")}</Table.HeaderCell>
                                                    <Table.HeaderCell>{t("Phone")}</Table.HeaderCell>
                                                    <Table.HeaderCell>{t("Email")}</Table.HeaderCell>
                                                    <Table.HeaderCell>{t("Homepage")}</Table.HeaderCell>
                                                    <Table.HeaderCell>{`${t("Invoicing")} ${t("Name")}`}</Table.HeaderCell>
                                                    <Table.HeaderCell>{`${t("Invoicing")} ${t("Address")}`}</Table.HeaderCell>
                                                    <Table.HeaderCell>{`${t("Invoicing")} ${t("Postal code")}`}</Table.HeaderCell>
                                                    <Table.HeaderCell>{`${t("Invoicing")} ${t("City")}`}</Table.HeaderCell>
                                                    <Table.HeaderCell>{`${t("Invoicing")} ${t("Country")}`}</Table.HeaderCell>
                                                    <Table.HeaderCell>{`${t("Delivery")} ${t("Name")}`}</Table.HeaderCell>
                                                    <Table.HeaderCell>{`${t("Delivery")} ${t("Address")}`}</Table.HeaderCell>
                                                    <Table.HeaderCell>{`${t("Delivery")} ${t("Postal code")}`}</Table.HeaderCell>
                                                    <Table.HeaderCell>{`${t("Delivery")} ${t("City")}`}</Table.HeaderCell>
                                                    <Table.HeaderCell>{`${t("Delivery")} ${t("Country")}`}</Table.HeaderCell>
                                                    <Table.HeaderCell>{t("Discount %")}</Table.HeaderCell>
                                                    <Table.HeaderCell>{t("Vat 0%")}</Table.HeaderCell>
                                                    <Table.HeaderCell>{t("Vat 0% comment")}</Table.HeaderCell>
                                                    <Table.HeaderCell>{t("Invoicing method")}</Table.HeaderCell>
                                                    <Table.HeaderCell>{t("Invoicing Email")}</Table.HeaderCell>
                                                    <Table.HeaderCell>{t("Payment term")}</Table.HeaderCell>
                                                    <Table.HeaderCell>{t("Company reference")}</Table.HeaderCell>
                                                    <Table.HeaderCell>{t("Customer reference")}</Table.HeaderCell>
                                                    <Table.HeaderCell>{t("Net invoice indentifier")}</Table.HeaderCell>
                                                    <Table.HeaderCell>{t("Net invoice intermediator")}</Table.HeaderCell>
                                                    <Table.HeaderCell>{t("Default contract number")}</Table.HeaderCell>
                                                    <Table.HeaderCell>{t("Default invoice comment")}</Table.HeaderCell>
                                                    <Table.HeaderCell>{t("Add customer delivery address to invoice")}</Table.HeaderCell>
                                                    <Table.HeaderCell>{t("Default location number")}</Table.HeaderCell>
                                                    <Table.HeaderCell>{t("Default sensor number")}</Table.HeaderCell>
                                                    <Table.HeaderCell>{t("Initial sensor value")}</Table.HeaderCell>
                                                    <Table.HeaderCell>{t("Initial sensor last invoiced value")}</Table.HeaderCell>
                                                    <Table.HeaderCell>{t("Initial sensor estimate")}</Table.HeaderCell>
                                                </Table.Row>
                                            </Table.Header>
                                        )}      
                                        <Table.Body>
                                            {received_items_list.map((item, key) => {
                                                return (
                                                    <Table.Row key={key} textAlign="center">
                                                        <Table.Cell >
                                                            {item.name}
                                                        </Table.Cell>
                                                        <Table.Cell >
                                                            {item.vat}
                                                        </Table.Cell>
                                                        <Table.Cell >
                                                            {item.description}
                                                        </Table.Cell>
                                                        <Table.Cell >
                                                            {item.customer_code}
                                                        </Table.Cell>
                                                        <Table.Cell >
                                                            {item.address}
                                                        </Table.Cell>
                                                        <Table.Cell >
                                                            {item.postal_code}
                                                        </Table.Cell>
                                                        <Table.Cell >
                                                            {item.city}
                                                        </Table.Cell>
                                                        <Table.Cell >
                                                            {item.country}
                                                        </Table.Cell>
                                                        <Table.Cell >
                                                            {item.phone}
                                                        </Table.Cell>
                                                        <Table.Cell >
                                                            {item.email}
                                                        </Table.Cell>
                                                        <Table.Cell >
                                                            {item.homepage}
                                                        </Table.Cell>
                                                        <Table.Cell >
                                                            {item.i_name}
                                                        </Table.Cell>
                                                        <Table.Cell >
                                                            {item.i_address}
                                                        </Table.Cell>
                                                        <Table.Cell >
                                                            {item.i_postal_code}
                                                        </Table.Cell>
                                                        <Table.Cell >
                                                            {item.i_city}
                                                        </Table.Cell>
                                                        <Table.Cell >
                                                            {item.i_country}
                                                        </Table.Cell>
                                                        <Table.Cell >
                                                            {item.d_name}
                                                        </Table.Cell>
                                                        <Table.Cell >
                                                            {item.d_address}
                                                        </Table.Cell>
                                                        <Table.Cell >
                                                            {item.d_postal_code}
                                                        </Table.Cell>
                                                        <Table.Cell >
                                                            {item.d_city}
                                                        </Table.Cell>
                                                        <Table.Cell >
                                                            {item.d_country}
                                                        </Table.Cell>
                                                        <Table.Cell >
                                                            {item.inv_discount}
                                                        </Table.Cell>
                                                        <Table.Cell >
                                                            {item.inv_no_vat === true ? t("Yes") : t("No")}
                                                        </Table.Cell>
                                                        <Table.Cell >
                                                            {item.inv_no_vat_comment}
                                                        </Table.Cell>
                                                        <Table.Cell >
                                                            {item.inv_invoicing_method === "P" && t("Paper")}
                                                            {item.inv_invoicing_method === "E" && t("Email")}
                                                            {item.inv_invoicing_method === "N" && t("Net Invoice")}
                                                        </Table.Cell>
                                                        <Table.Cell >
                                                            {item.inv_invoicing_email}
                                                        </Table.Cell>
                                                        <Table.Cell >
                                                            {item.inv_payment_term}
                                                        </Table.Cell>
                                                        <Table.Cell >
                                                            {item.inv_default_company_reference}
                                                        </Table.Cell>
                                                        <Table.Cell >
                                                            {item.inv_default_customer_reference}
                                                        </Table.Cell>
                                                        <Table.Cell >
                                                            {item.inv_net_invoice_indentifier}
                                                        </Table.Cell>
                                                        <Table.Cell >
                                                            {item.inv_net_invoice_intermediator}
                                                        </Table.Cell>
                                                        <Table.Cell >
                                                            {item.inv_default_contract_number}
                                                        </Table.Cell>
                                                        <Table.Cell >
                                                            {item.inv_default_invoice_comment}
                                                        </Table.Cell>
                                                        <Table.Cell >
                                                            {item.inv_add_address_to_invoice === true ? t("Yes") : t("No")}
                                                        </Table.Cell>
                                                        <Table.Cell >
                                                            {item.inv_default_location_number}
                                                        </Table.Cell>
                                                        <Table.Cell >
                                                            {item.inv_default_sensor_number}
                                                        </Table.Cell>
                                                        <Table.Cell >
                                                            {item.inv_default_sensor_initial_value}
                                                        </Table.Cell>
                                                        <Table.Cell >
                                                            {item.inv_default_sensor_initial_invoiced_value}
                                                        </Table.Cell>
                                                        <Table.Cell >
                                                            {item.inv_default_sensor_initial_estimate}
                                                        </Table.Cell>
                                                    </Table.Row>
                                                );
                                            })}
                                        </Table.Body>
                                    </Table>
                                </React.Fragment>
                            )}
                    </Modal.Content>
                    <Modal.Actions>
                        {received_items_list && (
                            <Button type="button" positive disabled={import_error} loading={loading} floated="left" onClick={() => this.handleSaveImportItems()} >{t("Save new items")}</Button>
                        )}
                        <Button type="button" negative loading={loading} onClick={() => this.handleCloseModal()} >{t("Cancel")}</Button>
                    </Modal.Actions>
                </Modal>
                <Modal size="tiny" open={this.state.import_result_modal_open} dimmer="inverted" >
                    <Modal.Content>
                        {t("New customers imported")}: {this.state.imported_customers_count}
                    </Modal.Content>
                    <Modal.Actions>
                        <Button type="button" positive content={t("OK")} onClick={() => this.handleCloseImportResults()}/>
                    </Modal.Actions>
                </Modal>

                <Modal size="tiny" open={multiple_edit_modal_open} dimmer="inverted" >
                    <Modal.Header>
                        {t("Edit multiple customers")}
                    </Modal.Header>
                    <Modal.Content>
                        <>
                            <Form>
                                <Form.Field>
                                    <Select 
                                        search
                                        name='item'
                                        options={this.handleMultiEditOptions()}
                                        placeholder={t("Select item to edit")}
                                        onChange={this.handleMultiEditSelectChange}
                                        value={multiple_edit.item && multiple_edit.item}
                                    />
                                </Form.Field>
                                {multiple_edit.item && (
                                    <>
                                        {(multiple_edit.item === "vat" || multiple_edit.item === "customer_code" || multiple_edit.item === "phone" || multiple_edit.item === "invoicing_email") && (
                                            <Form.Field>
                                                <label>{this.handleGetMultiEditLabel(multiple_edit.item)} {multiple_edit.value ? (`${multiple_edit.value.length}/35`) : ("0/35")}</label>
                                                <Input
                                                    value={multiple_edit.value}
                                                    name={multiple_edit.item}
                                                    onChange={this.handleMultiEditChange} />
                                            </Form.Field>
                                        )}
                                        {(multiple_edit.item === "default_customer_reference" || multiple_edit.item === "default_company_reference") && (
                                            <Form.Field>
                                                <label>{this.handleGetMultiEditLabel(multiple_edit.item)} {multiple_edit.value ? (`${multiple_edit.value.length}/50`) : ("0/50")}</label>
                                                <Input
                                                    value={multiple_edit.value}
                                                    name={multiple_edit.item}
                                                    onChange={this.handleMultiEditChange} />
                                            </Form.Field>
                                        )}
                                        {(multiple_edit.item === "email" || multiple_edit.item === "homepage" || multiple_edit.item === "no_vat_comment" ) && (
                                            <Form.Field>
                                                <label>{this.handleGetMultiEditLabel(multiple_edit.item)} {multiple_edit.value ? (`${multiple_edit.value.length}/70`) : ("0/70")}</label>
                                                <Input
                                                    value={multiple_edit.value}
                                                    name={multiple_edit.item}
                                                    onChange={this.handleMultiEditChange} />
                                            </Form.Field>
                                        )}
                                        {(multiple_edit.item === "description" || multiple_edit.item === "default_invoice_comment" ) && (
                                            <Form.Field>
                                                <label>{this.handleGetMultiEditLabel(multiple_edit.item)} {multiple_edit.value ? (`${multiple_edit.value.length}/100`) : ("0/100")}</label>
                                                <Input
                                                    value={multiple_edit.value}
                                                    name={multiple_edit.item}
                                                    onChange={this.handleMultiEditChange} />
                                            </Form.Field>
                                        )}
                                        {(multiple_edit.item === "discount" ) && (
                                            <Form.Field>
                                                <label>{this.handleGetMultiEditLabel(multiple_edit.item)}</label>
                                                <Input
                                                    value={multiple_edit.value}
                                                    name={multiple_edit.item}
                                                    onChange={this.handleMultiEditChange} />
                                            </Form.Field>
                                        )}
                                        {(multiple_edit.item === "customer_group" || multiple_edit.item === "payment_term" || multiple_edit.item === "invoicing_method") && (
                                            <Form.Field>
                                                <label style={{paddingRight: "5px"}}>{this.handleGetMultiEditLabel(multiple_edit.item)}</label>
                                                    <Select 
                                                        search
                                                        name={multiple_edit.item}
                                                        options={this.handleGetMultiEditSelectOptions(multiple_edit.item)}
                                                        onChange={this.handleMultiEditSelectChange}
                                                        value={multiple_edit.value}
                                                    />
                                            </Form.Field>
                                        )}
                                        {(multiple_edit.item === "no_vat" || multiple_edit.item === "add_address_to_invoice") && (
                                            <Form.Field>
                                                {/* <label>{this.handleGetMultiEditLabel(multiple_edit.item)}</label> */}
                                                <Checkbox
                                                    label={this.handleGetMultiEditLabel(multiple_edit.item)}
                                                    value={multiple_edit.value}
                                                    checked={multiple_edit.value}
                                                    onChange={this.handleMultiEditCheckboxChange} />
                                            </Form.Field>
                                        )}
                                    </>
                                )}
                            </Form>
                            {multiple_edit_error && <Message error header={t("Error")} content={multiple_edit_error} />}
                        </>
                    </Modal.Content>
                    <Modal.Actions>
                        {multiple_edit_error === null && (
                            <Button type="button" primary floated="left" content={t("Save")} onClick={(e) => this.handleCheckSubmitMultiEditConditions(e)}/>
                        )}
                        <Button type="button" negative content={t("Cancel")} onClick={() => this.handleCloseMultiEditModal()}/>
                    </Modal.Actions>
                </Modal>
            </Shell>
        );
    }
}
const mapStateToProps = state => {
    return {
        activeCompanyId: state.company.activeCompanyId,
        isAuthenticated: state.auth.token !== null
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setActivity: () => dispatch(setActivity())
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(withTranslation('customers')(Customers))
);
