// Copyright Caproc Oy
// System name: Aqua-Net 
// System module: Message
// Author: TS
//
// 1.2.2020 TS Initial version
// 14.5.2020 TS Added search to Recepient groups modal. Changed also selecting of groups and adding to the list.
// 11.7.2020 TS Added Company Selection banner

import React from "react";
import { Form, Input, Message, Header, Button, TextArea, Segment, Container, Icon, Accordion, Modal, Table, Checkbox } from "semantic-ui-react";
import { authAxios, authFileAxios, CompanySelection } from "../../utils";
import { setActivity } from "../../store/actions/auth";
import { withRouter, Redirect, Link } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import { messageCreateURL, messageAttachmentUploadURL, recepientGroupsURL, messageSendURL } from "../../constants";


class MessageCreate extends React.Component {
    state = {
        message: {
            subject: "",
            to: "",
            cc: "",
            bcc: [],
            text: ""
        },
        groups: [],
        attachments: null,
        attachment_names: [],
        activeIndex: 0,
        addressBookModalOpen: false,
        recepient_groups: null,
        message_id: null,
        error: null,
        loading: false,
        sendingOK: false,
        search: "",
        selected: []
    };

    componentDidMount() {
        this.props.setActivity();
        this.fetctRecepientGroups();

    }

    fetctRecepientGroups() {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;
        authAxios
            .get(recepientGroupsURL(activeCompanyId))
            .then(res => {
                this.setState({
                    recepient_groups: res.data,
                    error: null,
                    loading: false,
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    error: err
                });
            });
    }

    handleChange = e => {
        const { message } = this.state;
        const updatedMessage = {
            ...message,
            [e.target.name]: e.target.value
        }
        this.setState({
            message: updatedMessage,
            error: null
        });
    };

    handleSearchChange = e => {
        this.setState({
            [e.target.name]: e.target.value,
            error: null
        });
    };


    handleFileSelect = (e) => {
        let { attachment_names } = this.state;
        let i = 0;
        for (i in e.target.files) {
            attachment_names[i] = e.target.files[i];
        }

        this.setState({
            attachments: e.target.files,
            attachment_names: attachment_names,
            error: null
        });
    };

    handleClick = (e, titleProps) => {
        const { index } = titleProps
        const { activeIndex } = this.state
        const newIndex = activeIndex === index ? -1 : index

        this.setState({ activeIndex: newIndex })
    };


    handleSubmit = e => {
        e.preventDefault();
        this.setState({
            loading: true
        });
        const {
            message,
            attachments,
            groups
        } = this.state;
        const { activeCompanyId, t } = this.props;
        if (message.subject === "") {
            this.setState({
                error: t("Please fill in Subject"),
                success_mgs: null,
                loading: false
            });
        } else if (groups.length === 0) {
            this.setState({
                error: t("Please select Recepients"),
                success_mgs: null,
                loading: false
            });
        } else if (message.text === "") {
            this.setState({
                error: t("Please fill in Message"),
                success_mgs: null,
                loading: false
            });
        } else if (message.subject.length > 128) {
            this.setState({
                error: t("Max amount of characters is 128 for Subject"),
                success_mgs: null,
                loading: false
            });
        } else {
            authAxios
                .post(messageCreateURL(activeCompanyId), {
                    ...message,
                    groups
                })
                .then(res => {
                    const message_id = res.data.message_id;
                    if (attachments) {
                        let i = 0;
                        for (i = 0; i < attachments.length; i++) {
                            let form_data = new FormData();
                            form_data.append('file_name', attachments[i], attachments[i].name);
                            form_data.append('message', message_id);
                            form_data.append('company', activeCompanyId);

                            authFileAxios
                                .post(messageAttachmentUploadURL(activeCompanyId, message_id), form_data)
                                .then(res => {
                                    if (i === attachments.length) {
                                        this.setState({
                                            sendingOK: true,
                                            message_id
                                        });
                                    }
                                })
                                .catch(err => {
                                    this.setState({
                                        loading: false,
                                        sendingOK: false,
                                        error: err
                                    });
                                    return;
                                });
                        }
                    } else {
                        this.setState({
                            sendingOK: true,
                            message_id
                        });
                    }
                })
                .catch(err => {
                    this.setState({
                        loading: false,
                        error: err
                    });
                });
        }
    };

    openAddressBookModal = () => {
        this.setState({
            addressBookModalOpen: true,
            selected: this.state.groups
        });
    };

    closeAddressBookModal = () => {
        this.setState({
            addressBookModalOpen: false,
            selected: [],
            search: ""
        });
    };

    handleAddGroupCheckboxChange = (e, { value, checked }) => {
        let { selected } = this.state;
        if (checked) {
            if (!selected.includes(value)) {
                selected.push(value);
            }
        } else {
            if (selected.includes(value)) {
                selected.splice(selected.indexOf(value), 1);
            }
        }
        this.setState({ selected });
    };

    removeGroupFromRecepients = group => {
        const { message, groups } = this.state;
        let recepients = message.bcc;
        let updated_groups = groups;
        if (recepients.includes(group.name)) {
            recepients.splice(recepients.indexOf(group.name), 1);
        }
        if (updated_groups.includes(group)) {
            updated_groups.splice(updated_groups.indexOf(group), 1);
        }
        const updatedMessage = {
            ...message,
            bcc: recepients
        }
        this.setState({
            message: updatedMessage,
            groups: updated_groups
        });
    };

    handleAddSelectedGroups = () => {
        const { message, selected, groups } = this.state;
        let recepients = message.bcc;
        let updated_groups = groups;

        let i = 0;
        for (i in selected) {
            if (!recepients.includes(selected[i].name)) {
                recepients.push(selected[i].name);
            }
            if (!updated_groups.includes(selected[i])) {
                updated_groups.push(selected[i]);
            }
        }
        const updatedMessage = {
            ...message,
            bcc: recepients
        }
        this.setState({
            message: updatedMessage,
            groups: updated_groups
        });
        this.closeAddressBookModal();
    };



    // handleAddGroupCheckboxChange = (e, { value, checked }) => {
    //     const { message } = this.state;
    //     let recepients = message.bcc;
    //     let { groups } = this.state;
    //     if (checked) {
    //         if (!recepients.includes(value.name)) {
    //             recepients.push(value.name);
    //         }
    //         if (!groups.includes(value)) {
    //             groups.push(value);
    //         }
    //     } else {
    //         if (recepients.includes(value.name)) {
    //             recepients.splice(recepients.indexOf(value.name), 1);
    //         }
    //         if (groups.includes(value)) {
    //             groups.splice(groups.indexOf(value), 1);
    //         }
    //     }
    //     const updatedMessage = {
    //         ...message,
    //         bcc: recepients
    //     }
    //     this.setState({
    //         message: updatedMessage,
    //         groups
    //     });
    // };

    // removeGroupFromRecepients = group => {
    //     this.handleAddGroupCheckboxChange(null, { value: group, checked: false });
    // };

    sendMessage = () => {
        const { activeCompanyId } = this.props;
        const { message_id } = this.state;
        authAxios
            .get(messageSendURL(activeCompanyId, message_id))
            .then(res => {
                this.setState({
                    loading: false
                });
                this.props.history.push("/messages/");
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    sendingOK: false,
                    error: err
                });
            });
    };

    render() {
        const {
            message,
            attachment_names,
            activeIndex,
            error,
            recepient_groups,
            groups,
            sendingOK,
            loading,
            search,
            selected
        } = this.state;
        const { isAuthenticated, t } = this.props;
        if (!isAuthenticated) {
            return <Redirect to="/login" />;
        }
        // console.log(this.state);
        if (sendingOK) {
            this.setState({
                sendingOK: false
            });
            this.sendMessage();
        }

        let filter_groups = [];

        // Filter data for search
        if (recepient_groups !== null) {
            filter_groups = recepient_groups.filter((group) => {
                return group.name.toLowerCase().indexOf(search.toLocaleLowerCase()) !== -1;
            })
        }
        return (
            <>
            <Container><CompanySelection/></Container>
            < Segment vertical>
                <Container>

                    <Header as="h3">{t("New message")}</Header>
                    <Button type="button" primary onClick={() => this.openAddressBookModal()} content={t("Recepients")} />

                    <Form error={error !== null}>
                        <br />
                        <label><b>{t("Recepients")}:</b></label>&nbsp;
                        {groups.length > 0 && (
                            groups.map((group, key) => {
                                return (
                                    <React.Fragment key={key}>
                                        <b>{group.name}</b><Link onClick={() => this.removeGroupFromRecepients(group)}><Icon name='remove' /></Link>
                                    </React.Fragment>
                                );
                            })
                        )}
                        {/* <Form.Field >

                            <Input
                                required
                                value={message.bcc}
                                name="bcc"
                                onChange={this.handleChange}
                            />
                        </Form.Field> */}
                        <br />
                        <br />
                        <Accordion>
                            <Accordion.Title
                                active={activeIndex === 1}
                                index={1}
                                onClick={this.handleClick}>
                                <Icon name='dropdown' />
                                <b>{t("Attachment")}</b> <Icon name='attach' />
                            </Accordion.Title>
                            <Accordion.Content active={activeIndex === 1}>
                                <Form.Field>
                                    <Input icon='attach' multiple type="file" id="file_name" onChange={this.handleFileSelect} />
                                </Form.Field>
                                {attachment_names.length > 0 && (
                                    attachment_names.map((file, key) => {
                                        return (
                                            <React.Fragment>
                                                <Icon key={key} name='attach' /> <b>{file.name} </b>
                                            </React.Fragment>
                                        );
                                    })
                                )}
                            </Accordion.Content>
                        </Accordion>

                        <Form.Field required>
                            <label>{t("Subject")}</label>
                            <Input
                                required
                                value={message.subject}
                                name="subject"
                                onChange={this.handleChange} />
                        </Form.Field>
                        <Form.Field
                            required
                            control={TextArea}
                            rows={15}
                            label={t("Message")}
                            value={message.text}
                            name="text"
                            onChange={this.handleChange} />


                        {error && <Message error header={t("Error")} content={error} />}
                        <Button primary type="button" loading={loading} disabled={loading} onClick={(e) => this.handleSubmit(e)}>
                            {t("Send")}
                        </Button>
                    </Form>
                </Container>
                <Modal size="tiny" open={this.state.addressBookModalOpen}>
                    <Modal.Header>
                        <b>{t("Address Book")}</b> <br />
                        <Button positive type="button" icon='add circle' content={t("Select addresses")} onClick={() => this.handleAddSelectedGroups()} />
                        <Button type="button" floated="right" negative onClick={() => this.closeAddressBookModal()} >{t("Close")}</Button>
                    </Modal.Header>
                    <Modal.Content >
                    <Input
                            size="small"
                            icon='search'
                            value={search}
                            name="search"
                            onChange={this.handleSearchChange} />

                        <Table striped selectable size='small' unstackable>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell />
                                    <Table.HeaderCell content={t("Group name")} />
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {(filter_groups && filter_groups.length > 0) && (
                                    filter_groups.map((group, key) => {
                                        return (
                                            <Table.Row key={key}>

                                                <Table.Cell collapsing>
                                                    <Checkbox
                                                        value={group}
                                                        checked={selected.includes(group) === true}
                                                        onChange={this.handleAddGroupCheckboxChange} />
                                                </Table.Cell>
                                                <Table.Cell collapsing>{group.name}</Table.Cell>

                                            </Table.Row>
                                        );
                                    })
                                )}
                            </Table.Body>
                        </Table>
                    </Modal.Content>
                    {/* <Modal.Actions>
                        <Button type="button" negative onClick={() => this.closeAddressBookModal()} >{t("Close")}</Button>
                    </Modal.Actions> */}
                </Modal>

            </ Segment>
            </>
        );
    }
}
const mapStateToProps = state => {
    return {
        activeCompanyId: state.company.activeCompanyId,
        isAuthenticated: state.auth.token !== null
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setActivity: () => dispatch(setActivity())
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(withTranslation('messagecreate')(MessageCreate))
);