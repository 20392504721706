// Copyright Caproc Oy
// System name: Aqua-Net 
// System module: i18n
// Author: TS
//
// 1.2.2020 TS Initial version
// 30.3.2020 TS Added contractpricechanges and contractpricechangedetails
// 1.4.2020 TS Added Project module
// 30.5.2020 TS Added trade receivables report
// 1.6.2020 TS Added data collection items report
// 24.6.2021 TS Added InvoiceDetailsMultipleCustomers


import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import landingpage_fi from "./translations/fi/landingpage.json";
import landingpage_en from "./translations/en/landingpage.json";
import desktopcontainer_fi from "./translations/fi/Layout/desktopcontainer.json";
import desktopcontainer_en from "./translations/en/Layout/desktopcontainer.json";
import mobilecontainer_en from "./translations/en/Layout/mobilecontainer.json";
import mobilecontainer_fi from "./translations/fi/Layout/mobilecontainer.json";
import accountdetails_fi from "./translations/fi/Account/accountdetails.json";
import accountdetails_en from "./translations/en/Account/accountdetails.json";
import changepassword_fi from "./translations/fi/Account/changepassword.json";
import changepassword_en from "./translations/en/Account/changepassword.json";
import roleselect_fi from "./translations/fi/Account/roleselect.json";
import roleselect_en from "./translations/en/Account/roleselect.json";
import accountshell_fi from "./translations/fi/Account/shell.json";
import accountshell_en from "./translations/en/Account/shell.json";
import companydetails_fi from "./translations/fi/Company/companydetails.json";
import companydetails_en from "./translations/en/Company/companydetails.json";
import companyinvoicingdetails_fi from "./translations/fi/Company/companyinvoicingdetails.json";
import companyinvoicingdetails_en from "./translations/en/Company/companyinvoicingdetails.json";
import companynetinvoicing_fi from "./translations/fi/Company/companynetinvoicing.json";
import companynetinvoicing_en from "./translations/en/Company/companynetinvoicing.json";
import companyusers_fi from "./translations/fi/Company/companyusers.json";
import companyusers_en from "./translations/en/Company/companyusers.json";
import companyuserdetails_fi from "./translations/fi/Company/companyuserdetails.json";
import companyuserdetails_en from "./translations/en/Company/companyuserdetails.json";
import companyfiles_fi from "./translations/fi/Company/companyfiles.json";
import companyfiles_en from "./translations/en/Company/companyfiles.json";
import companyfiledetails_fi from "./translations/fi/Company/companyfiledetails.json";
import companyfiledetails_en from "./translations/en/Company/companyfiledetails.json";
import companycontacts_fi from "./translations/fi/Company/companycontacts.json";
import companycontacts_en from "./translations/en/Company/companycontacts.json";
import companycontactdetails_fi from "./translations/fi/Company/companycontactdetails.json";
import companycontactdetails_en from "./translations/en/Company/companycontactdetails.json";
import companytasks_fi from "./translations/fi/Company/companytasks.json";
import companytasks_en from "./translations/en/Company/companytasks.json";
import companytaskdetails_fi from "./translations/fi/Company/companytaskdetails.json";
import companytaskdetails_en from "./translations/en/Company/companytaskdetails.json";
import companydatacollectionadditem_fi from "./translations/fi/Company/companydatacollectionadditem.json";
import companydatacollectionadditem_en from "./translations/en/Company/companydatacollectionadditem.json";
import companydatacollectiondetails_fi from "./translations/fi/Company/companydatacollectiondetails.json";
import companydatacollectiondetails_en from "./translations/en/Company/companydatacollectiondetails.json";
import companydatacollectionitems_fi from "./translations/fi/Company/companydatacollectionitems.json";
import companydatacollectionitems_en from "./translations/en/Company/companydatacollectionitems.json";
import companydatacollections_fi from "./translations/fi/Company/companydatacollections.json";
import companydatacollections_en from "./translations/en/Company/companydatacollections.json";
import companydatacollectionreport_fi from "./translations/fi/Company/companydatacollectionreport.json";
import companydatacollectionreport_en from "./translations/en/Company/companydatacollectionreport.json";
import companybanktransactions_fi from "./translations/fi/Company/companybanktransactions.json";
import companybanktransactions_en from "./translations/en/Company/companybanktransactions.json";
import productdetails_fi from "./translations/fi/Company/productdetails.json";
import productdetails_en from "./translations/en/Company/productdetails.json";
import productgroupdetails_fi from "./translations/fi/Company/productgroupdetails.json";
import productgroupdetails_en from "./translations/en/Company/productgroupdetails.json";
import productgroups_fi from "./translations/fi/Company/productgroups.json";
import productgroups_en from "./translations/en/Company/productgroups.json";
import products_fi from "./translations/fi/Company/products.json";
import products_en from "./translations/en/Company/products.json";
import companyshell_fi from "./translations/fi/Company/shell.json";
import companyshell_en from "./translations/en/Company/shell.json";
import customeraddresses_fi from "./translations/fi/Customer/customeraddresses.json";
import customeraddresses_en from "./translations/en/Customer/customeraddresses.json";
import customercontactdetails_fi from "./translations/fi/Customer/customercontactdetails.json";
import customercontactdetails_en from "./translations/en/Customer/customercontactdetails.json";
import customercontacts_fi from "./translations/fi/Customer/customercontacts.json";
import customercontacts_en from "./translations/en/Customer/customercontacts.json";
import customerdatacollections_fi from "./translations/fi/Customer/customerdatacollections.json";
import customerdatacollections_en from "./translations/en/Customer/customerdatacollections.json";
import customerdatacollectiondetails_fi from "./translations/fi/Customer/customerdatacollectiondetails.json";
import customerdatacollectiondetails_en from "./translations/en/Customer/customerdatacollectiondetails.json";
import customerdatacollectionitems_fi from "./translations/fi/Customer/customerdatacollectionitems.json";
import customerdatacollectionitems_en from "./translations/en/Customer/customerdatacollectionitems.json";
import customerdatacollectionadditem_fi from "./translations/fi/Customer/customerdatacollectionadditem.json";
import customerdatacollectionadditem_en from "./translations/en/Customer/customerdatacollectionadditem.json";
import customerdatacollectionreport_fi from "./translations/fi/Customer/customerdatacollectionreport.json";
import customerdatacollectionreport_en from "./translations/en/Customer/customerdatacollectionreport.json";
import customerdetails_fi from "./translations/fi/Customer/customerdetails.json";
import customerdetails_en from "./translations/en/Customer/customerdetails.json";
import customergroupdetails_fi from "./translations/fi/Customer/customergroupdetails.json";
import customergroupdetails_en from "./translations/en/Customer/customergroupdetails.json";
import customergroups_fi from "./translations/fi/Customer/customergroups.json";
import customergroups_en from "./translations/en/Customer/customergroups.json";
import customerinvoicingdetails_fi from "./translations/fi/Customer/customerinvoicingdetails.json";
import customerinvoicingdetails_en from "./translations/en/Customer/customerinvoicingdetails.json";
import customers_fi from "./translations/fi/Customer/customers.json";
import customers_en from "./translations/en/Customer/customers.json";
import customerfiles_fi from "./translations/fi/Customer/customerfiles.json";
import customerfiles_en from "./translations/en/Customer/customerfiles.json";
import customerfiledetails_fi from "./translations/fi/Customer/customerfiledetails.json";
import customerfiledetails_en from "./translations/en/Customer/customerfiledetails.json";
import customertasks_fi from "./translations/fi/Customer/customertasks.json";
import customertasks_en from "./translations/en/Customer/customertasks.json";
import customertaskdetails_fi from "./translations/fi/Customer/customertaskdetails.json";
import customertaskdetails_en from "./translations/en/Customer/customertaskdetails.json";
import customershell_fi from "./translations/fi/Customer/shell.json";
import customershell_en from "./translations/en/Customer/shell.json";
import invoicedetails_fi from "./translations/fi/Invoice/invoicedetails.json";
import invoicedetails_en from "./translations/en/Invoice/invoicedetails.json";
import invoicedetailsmultiplecustomers_fi from "./translations/fi/Invoice/invoicedetailsmultiplecustomers.json";
import invoicedetailsmultiplecustomers_en from "./translations/en/Invoice/invoicedetailsmultiplecustomers.json";
import invoices_fi from "./translations/fi/Invoice/invoices.json";
import invoices_en from "./translations/en/Invoice/invoices.json";
import invoiceshell_fi from "./translations/fi/Invoice/shell.json";
import invoiceshell_en from "./translations/en/Invoice/shell.json";
import login_fi from "./translations/fi/login.json";
import login_en from "./translations/en/login.json";
import passwordreset_fi from "./translations/fi/passwordreset.json";
import passwordreset_en from "./translations/en/passwordreset.json";
import passwordresetemail_fi from "./translations/fi/passwordresetemail.json";
import passwordresetemail_en from "./translations/en/passwordresetemail.json";
import contracts_fi from "./translations/fi/Contract/contracts.json";
import contracts_en from "./translations/en/Contract/contracts.json";
import contractdetails_fi from "./translations/fi/Contract/contractdetails.json";
import contractdetails_en from "./translations/en/Contract/contractdetails.json";
import contractgroups_fi from "./translations/fi/Contract/contractgroups.json";
import contractgroups_en from "./translations/en/Contract/contractgroups.json";
import contractgroupdetails_fi from "./translations/fi/Contract/contractgroupdetails.json";
import contractgroupdetails_en from "./translations/en/Contract/contractgroupdetails.json";
import contractshell_fi from "./translations/fi/Contract/contractshell.json";
import contractshell_en from "./translations/en/Contract/contractshell.json";
import contractpricechanges_fi from "./translations/fi/Contract/contractpricechanges.json";
import contractpricechanges_en from "./translations/en/Contract/contractpricechanges.json";
import contractpricechangedetails_fi from "./translations/fi/Contract/contractpricechangedetails.json";
import contractpricechangedetails_en from "./translations/en/Contract/contractpricechangedetails.json";
import messagecreate_fi from "./translations/fi/Message/messagecreate.json";
import messagecreate_en from "./translations/en/Message/messagecreate.json";
import messages_fi from "./translations/fi/Message/messages.json";
import messages_en from "./translations/en/Message/messages.json";
import reportinvoices_fi from "./translations/fi/Report/reportinvoices.json";
import reportinvoices_en from "./translations/en/Report/reportinvoices.json";
import reportinvoicescustomers_fi from "./translations/fi/Report/reportinvoicescustomers.json";
import reportinvoicescustomers_en from "./translations/en/Report/reportinvoicescustomers.json";
import reportinvoicesproducts_fi from "./translations/fi/Report/reportinvoicesproducts.json";
import reportinvoicesproducts_en from "./translations/en/Report/reportinvoicesproducts.json";
import reporttradereceivables_fi from "./translations/fi/Report/reporttradereceivables.json";
import reporttradereceivables_en from "./translations/en/Report/reporttradereceivables.json";
import reportpaymenttransactions_fi from "./translations/fi/Report/reportpaymenttransactions.json";
import reportpaymenttransactions_en from "./translations/en/Report/reportpaymenttransactions.json";
import reportdatacollectionitems_fi from "./translations/fi/Report/reportdatacollectionitems.json";
import reportdatacollectionitems_en from "./translations/en/Report/reportdatacollectionitems.json";
import reportdatacollection_fi from "./translations/fi/Report/reportdatacollection.json";
import reportdatacollection_en from "./translations/en/Report/reportdatacollection.json";
import reportshell_fi from "./translations/fi/Report/reportshell.json";
import reportshell_en from "./translations/en/Report/reportshell.json";
import projectdetails_fi from "./translations/fi/Project/projectdetails.json";
import projectdetails_en from "./translations/en/Project/projectdetails.json";
import projectgroupdetails_fi from "./translations/fi/Project/projectgroupdetails.json";
import projectgroupdetails_en from "./translations/en/Project/projectgroupdetails.json";
import projectgroups_fi from "./translations/fi/Project/projectgroups.json";
import projectgroups_en from "./translations/en/Project/projectgroups.json";
import projects_fi from "./translations/fi/Project/projects.json";
import projects_en from "./translations/en/Project/projects.json";
import projectshell_fi from "./translations/fi/Project/projectshell.json";
import projectshell_en from "./translations/en/Project/projectshell.json";
import projecttaskdetails_fi from "./translations/fi/Project/projecttaskdetails.json";
import projecttaskdetails_en from "./translations/en/Project/projecttaskdetails.json";
import projecttasks_fi from "./translations/fi/Project/projecttasks.json";
import projecttasks_en from "./translations/en/Project/projecttasks.json";

// Customer portal
import customerlandingpage_fi from "./translations/fi/customerlandingpage.json";
import customerlandingpage_en from "./translations/en/customerlandingpage.json";
import customerportalcontactdetails_fi from "./translations/fi/CustomerPortal/customerportalcontactdetails.json";
import customerportalcontactdetails_en from "./translations/en/CustomerPortal/customerportalcontactdetails.json";
import customerportalcontacts_fi from "./translations/fi/CustomerPortal/customerportalcontacts.json";
import customerportalcontacts_en from "./translations/en/CustomerPortal/customerportalcontacts.json";
import customerportaldatacollectionadditem_fi from "./translations/fi/CustomerPortal/customerportaldatacollectionadditem.json";
import customerportaldatacollectionadditem_en from "./translations/en/CustomerPortal/customerportaldatacollectionadditem.json";
import customerportaldatacollectionitems_fi from "./translations/fi/CustomerPortal/customerportaldatacollectionitems.json";
import customerportaldatacollectionitems_en from "./translations/en/CustomerPortal/customerportaldatacollectionitems.json";
import customerportaldatacollectionreport_fi from "./translations/fi/CustomerPortal/customerportaldatacollectionreport.json";
import customerportaldatacollectionreport_en from "./translations/en/CustomerPortal/customerportaldatacollectionreport.json";
import customerportaldetails_fi from "./translations/fi/CustomerPortal/customerportaldetails.json";
import customerportaldetails_en from "./translations/en/CustomerPortal/customerportaldetails.json";
import customerportalfiles_fi from "./translations/fi/CustomerPortal/customerportalfiles.json";
import customerportalfiles_en from "./translations/en/CustomerPortal/customerportalfiles.json";
import customerportalinvoices_fi from "./translations/fi/CustomerPortal/customerportalinvoices.json";
import customerportalinvoices_en from "./translations/en/CustomerPortal/customerportalinvoices.json";
import customerportalshell_fi from "./translations/fi/CustomerPortal/shell.json";
import customerportalshell_en from "./translations/en/CustomerPortal/shell.json";

// the translations
const resources = {
    en: {
        landingpage: landingpage_en,
        desktopcontainer: desktopcontainer_en,
        mobilecontainer: mobilecontainer_en,
        accountdetails: accountdetails_en,
        changepassword: changepassword_en,
        roleselect: roleselect_en,
        accountshell: accountshell_en,
        companydetails: companydetails_en,
        companyinvoicingdetails: companyinvoicingdetails_en,
        companynetinvoicing: companynetinvoicing_en,
        companyusers: companyusers_en,
        companyuserdetails: companyuserdetails_en,
        companyfiles: companyfiles_en,
        companyfiledetails: companyfiledetails_en,
        companycontacts: companycontacts_en,
        companycontactdetails: companycontactdetails_en,
        companytasks: companytasks_en,
        companytaskdetails: companytaskdetails_en,
        companydatacollectionadditem: companydatacollectionadditem_en,
        companydatacollectiondetails: companydatacollectiondetails_en,
        companydatacollectionitems: companydatacollectionitems_en,
        companydatacollections: companydatacollections_en,
        companydatacollectionreport: companydatacollectionreport_en,
        companybanktransactions: companybanktransactions_en,
        productdetails: productdetails_en,
        productgroupdetails: productgroupdetails_en,
        productgroups: productgroups_en,
        products: products_en,
        companyshell: companyshell_en,
        customeraddresses: customeraddresses_en,
        customercontactdetails: customercontactdetails_en,
        customercontacs: customercontacts_en,
        customerdatacollections: customerdatacollections_en,
        customerdatacollectiondetails: customerdatacollectiondetails_en,
        customerdatacollectionitems: customerdatacollectionitems_en,
        customerdatacollectionadditem: customerdatacollectionadditem_en,
        customerdatacollectionreport: customerdatacollectionreport_en,
        customerdetails: customerdetails_en,
        customergroupdetails: customergroupdetails_en,
        customergroups: customergroups_en,
        customerinvoicingdetails: customerinvoicingdetails_en,
        customers: customers_en,
        customerfiles: customerfiles_en,
        customerfiledetails: customerfiledetails_en,
        customertasks: customertasks_en,
        customertaskdetails: customertaskdetails_en,
        customershell: customershell_en,
        invoicedetails: invoicedetails_en,
        invoicedetailsmultiplecustomers: invoicedetailsmultiplecustomers_en,
        invoices: invoices_en,
        invoiceshell: invoiceshell_en,
        login: login_en,
        passwordreset: passwordreset_en,
        passwordresetemail: passwordresetemail_en,
        contracts: contracts_en,
        contractdetails: contractdetails_en,
        contractgroups: contractgroups_en,
        contractgroupdetails: contractgroupdetails_en,
        contractshell: contractshell_en,
        contractpricechanges: contractpricechanges_en,
        contractpricechangedetails: contractpricechangedetails_en,
        messages: messages_en,
        messagecreate: messagecreate_en,
        reportinvoices: reportinvoices_en,
        reportinvoicescustomers: reportinvoicescustomers_en,
        reportinvoicesproducts: reportinvoicesproducts_en,
        reporttradereceivables: reporttradereceivables_en,
        reportpaymenttransactions: reportpaymenttransactions_en,
        reportdatacollectionitems: reportdatacollectionitems_en,
        reportdatacollection: reportdatacollection_en,
        reportshell: reportshell_en,
        projectdetails: projectdetails_en,
        projectgroupdetails: projectgroupdetails_en,
        projectgroups: projectgroups_en,
        projects: projects_en,
        projectshell: projectshell_en,
        projecttaskdetails: projecttaskdetails_en,
        projecttasks: projecttasks_en,

        // Customer portal
        customerlandingpage: customerlandingpage_en,
        customerportalcontactdetails: customerportalcontactdetails_en,
        customerportalcontacts: customerportalcontacts_en,
        customerportaldatacollectionadditem: customerportaldatacollectionadditem_en,
        customerportaldatacollectionitems: customerportaldatacollectionitems_en,
        customerportaldatacollectionreport: customerportaldatacollectionreport_en,
        customerportaldetails: customerportaldetails_en,
        customerportalfiles: customerportalfiles_en,
        customerportalinvoices: customerportalinvoices_en,
        customerportalshell: customerportalshell_en
    },
    fi: {
        landingpage: landingpage_fi,
        desktopcontainer: desktopcontainer_fi,
        mobilecontainer: mobilecontainer_fi,
        accountdetails: accountdetails_fi,
        changepassword: changepassword_fi,
        roleselect: roleselect_fi,
        accountshell: accountshell_fi,
        companydetails: companydetails_fi,
        companyinvoicingdetails: companyinvoicingdetails_fi,
        companynetinvoicing: companynetinvoicing_fi,
        companyusers: companyusers_fi,
        companyuserdetails: companyuserdetails_fi,
        companyfiles: companyfiles_fi,
        companyfiledetails: companyfiledetails_fi,
        companycontacts: companycontacts_fi,
        companycontactdetails: companycontactdetails_fi,
        companytasks: companytasks_fi,
        companytaskdetails: companytaskdetails_fi,
        companydatacollectionadditem: companydatacollectionadditem_fi,
        companydatacollectiondetails: companydatacollectiondetails_fi,
        companydatacollectionitems: companydatacollectionitems_fi,
        companydatacollections: companydatacollections_fi,
        companydatacollectionreport: companydatacollectionreport_fi,
        companybanktransactions: companybanktransactions_fi,
        productdetails: productdetails_fi,
        productgroupdetails: productgroupdetails_fi,
        productgroups: productgroups_fi,
        products: products_fi,
        companyshell: companyshell_fi,
        customeraddresses: customeraddresses_fi,
        customercontactdetails: customercontactdetails_fi,
        customercontacts: customercontacts_fi,
        customerdatacollections: customerdatacollections_fi,
        customerdatacollectiondetails: customerdatacollectiondetails_fi,
        customerdatacollectionitems: customerdatacollectionitems_fi,
        customerdatacollectionadditem: customerdatacollectionadditem_fi,
        customerdatacollectionreport: customerdatacollectionreport_fi,
        customerdetails: customerdetails_fi,
        customergroupdetails: customergroupdetails_fi,
        customergroups: customergroups_fi,
        customerinvoicingdetails: customerinvoicingdetails_fi,
        customers: customers_fi,
        customerfiles: customerfiles_fi,
        customerfiledetails: customerfiledetails_fi,
        customertasks: customertasks_fi,
        customertaskdetails: customertaskdetails_fi,
        customershell: customershell_fi,
        invoicedetails: invoicedetails_fi,
        invoicedetailsmultiplecustomers: invoicedetailsmultiplecustomers_fi,
        invoices: invoices_fi,
        invoiceshell: invoiceshell_fi,
        login: login_fi,
        passwordreset: passwordreset_fi,
        passwordresetemail: passwordresetemail_fi,
        contracts: contracts_fi,
        contractdetails: contractdetails_fi,
        contractgroups: contractgroups_fi,
        contractgroupdetails: contractgroupdetails_fi,
        contractshell: contractshell_fi,
        contractpricechanges: contractpricechanges_fi,
        contractpricechangedetails: contractpricechangedetails_fi,
        messages: messages_fi,
        messagecreate: messagecreate_fi,
        reportinvoices: reportinvoices_fi,
        reportinvoicescustomers: reportinvoicescustomers_fi,
        reportinvoicesproducts: reportinvoicesproducts_fi,
        reporttradereceivables: reporttradereceivables_fi,
        reportpaymenttransactions: reportpaymenttransactions_fi,
        reportdatacollectionitems: reportdatacollectionitems_fi,
        reportdatacollection: reportdatacollection_fi,
        reportshell: reportshell_fi,
        projectdetails: projectdetails_fi,
        projectgroupdetails: projectgroupdetails_fi,
        projectgroups: projectgroups_fi,
        projects: projects_fi,
        projectshell: projectshell_fi,
        projecttaskdetails: projecttaskdetails_fi,
        projecttasks: projecttasks_fi,

        // Customer portal
        customerlandingpage: customerlandingpage_fi,
        customerportalcontactdetails: customerportalcontactdetails_fi,
        customerportalcontacts: customerportalcontacts_fi,
        customerportaldatacollectionadditem: customerportaldatacollectionadditem_fi,
        customerportaldatacollectionitems: customerportaldatacollectionitems_fi,
        customerportaldatacollectionreport: customerportaldatacollectionreport_fi,
        customerportaldetails: customerportaldetails_fi,
        customerportalfiles: customerportalfiles_fi,
        customerportalinvoices: customerportalinvoices_fi,
        customerportalshell: customerportalshell_fi
    }
};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: "fi",

        keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;