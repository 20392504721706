// Copyright Caproc Oy
// System name: Aqua-Net 
// System module: Layout
// Author: TS
//
// 1.2.2020 TS Initial version
// 30.3.2020 TS Changed use or roles. Projects module only for Admins for now
// 11.5.2020 TS Projects now available for all
// 5.6.2020 TS Added Caproc logo
// 19.6.2020 TS Changed login to "sign-in" icon
// 29.6.2020 TS Changed size of sidebar menu to "huge" for better usability on mobile

import PropTypes from "prop-types";
import React, { Component } from "react";
import {
    Button,
    Container,
    Dropdown,
    Icon,
    Menu,
    Responsive,
    Segment,
    Sidebar,
    Flag,
    DropdownMenu,
    Image
} from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { logout } from "../../store/actions/auth";
import { setActiveCompany } from "../../store/actions/company";
import { getWidth } from "../../utils";
import { withTranslation } from 'react-i18next';
import Logo from "../../assets/images/Caproc-logo-small-70.png";


class MobileContainer extends Component {
    state = {
        company_name: null
    };

    handleSidebarHide = () => this.setState({ sidebarOpened: false });

    handleToggle = () => this.setState({ sidebarOpened: true });

    setActiveCompanyId = (company_id, company_name, roles) => {
        this.props.setCompany(company_id, company_name, roles);
        this.setState({ company_name });
        this.props.history.push("/")
    }

    handleLogout = () => {
        this.setState({ company_name: null });
        this.props.logout();
    }


    render() {
        const { children, isAuthenticated, userCompanies, activeCompanyId, t, i18n, activeRole } = this.props;
        const { sidebarOpened, fixed, company_name } = this.state;
        return (
            <Responsive
                as={Sidebar.Pushable}
                getWidth={getWidth}
                maxWidth={Responsive.onlyMobile.maxWidth}
            >
                <Sidebar
                    as={Menu}
                    animation="push"
                    inverted
                    onHide={this.handleSidebarHide}
                    vertical
                    visible={sidebarOpened}
                    onClick={this.handleSidebarHide}
                    size="huge"
                    icon="labeled"
                    compact
                >
                    {/* <Menu.Item
                        active={this.props.location.pathname === "/"}
                        onClick={() => this.props.history.push("/")}
                    >
                        {t('Home')}
                    </Menu.Item> */}
                    <Menu.Item
                        active={this.props.location.pathname === "/"}
                        onClick={() => this.props.history.push("/")}
                        content={<Image src={Logo} size='small' verticalAlign="bottom" />}
                        fitted="vertically"
                        position="left"
                    />

                    {(userCompanies && activeCompanyId) && (
                        <React.Fragment>
                            {activeRole && (
                                <React.Fragment>
                                    {activeRole.role !== "C" ? (
                                        <React.Fragment>
                                            <Menu.Item
                                                active={this.props.location.pathname === "/customers/"}
                                                onClick={() => this.props.history.push("/customers/")}
                                            >
                                                <Icon name="group"/> {t('Customers')}
                                            </Menu.Item>
                                            <Menu.Item
                                                active={this.props.location.pathname === "/contracts/"}
                                                onClick={() => this.props.history.push("/contracts/")}
                                            >
                                                <Icon name="handshake"/> {t('Contracts')}
                                            </Menu.Item>
                                            {/* {(activeRole && activeRole.role === "A") && ( */}
                                                <Menu.Item
                                                    active={this.props.location.pathname === "/projects/"}
                                                    onClick={() => this.props.history.push("/projects/")}
                                                >
                                                    <Icon name="suitcase"/> {t('Projects')}
                                                </Menu.Item>
                                            {/* )} */}
                                            <Menu.Item
                                                active={this.props.location.pathname === "/invoice/invoices/C/"}
                                                onClick={() => this.props.history.push("/invoice/invoices/C/")}
                                            >
                                                <Icon name="list alternate"/> {t('Invoices')}
                                            </Menu.Item>
                                            <Menu.Item
                                                active={this.props.location.pathname === "/messages/"}
                                                onClick={() => this.props.history.push("/messages/")}
                                            >
                                                <Icon name="envelope"/> {t('Messages')}
                                            </Menu.Item>
                                            <Menu.Item
                                                active={this.props.location.pathname === "/reports/invoices/invoices/"}
                                                onClick={() => this.props.history.push("/reports/invoices/invoices/")}
                                            >
                                                <Icon name="chart bar"/> {t('Reports')}
                                            </Menu.Item>
                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment>
                                            <Menu.Item
                                                active={this.props.location.pathname === `/customer-portal/customer-details/${activeRole.customer.id}`}
                                                onClick={() => this.props.history.push(`/customer-portal/customer-details/${activeRole.customer.id}`)}
                                            >
                                                <Icon name="home"/> {t('Customer details')}
                                            </Menu.Item>
                                        </React.Fragment>
                                    )}
                                </React.Fragment>
                            )}
                        </React.Fragment>
                    )}      
                    <Menu.Item position="right">
                        {isAuthenticated ? (
                            <React.Fragment>
                                {(userCompanies !== null && userCompanies.length > 0) &&
                                    <Dropdown direction="left" item text={company_name ? (company_name) : (localStorage.getItem("activeCompanyName"))}>
                                        <Dropdown.Menu onClick={this.handleSidebarHide}>
                                            {userCompanies.map((item, key) =>
                                                <Dropdown.Item
                                                    key={key}
                                                    text={item.name}
                                                    onClick={() =>
                                                        this.setActiveCompanyId(item.id, item.name, item.roles)
                                                    }
                                                    icon="building"
                                                />
                                            )}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                }
                                <Dropdown item direction="left" text={localStorage.getItem("userFullName") !== "" ? (localStorage.getItem("userFullName")) : (localStorage.getItem("userName"))}>
                                    <Dropdown.Menu onClick={this.handleSidebarHide}>
                                        <Dropdown.Item
                                            text={t('Account')}
                                            onClick={() =>
                                                this.props.history.push("/account/account-details")
                                            }
                                            icon="user"
                                        />
                                        {(userCompanies && activeRole && activeRole.role !== "C") && (
                                            <Dropdown.Item
                                                text={t('Company')}
                                                onClick={() =>
                                                    this.props.history.push("/company/company-details")
                                                }
                                                icon="building"
                                            />
                                        )}
                                        <Dropdown.Item
                                            text={t('Logout')}
                                            onClick={() =>
                                                this.handleLogout()
                                            }
                                            icon="power"
                                        />
                                    </Dropdown.Menu>
                                </Dropdown>
                                <Dropdown item direction="left" button trigger={<Flag name={i18n.language === "en" ? "gb" : i18n.language} />} >
                                    <DropdownMenu onClick={this.handleSidebarHide}>
                                        <Dropdown.Item flag="fi" onClick={() => i18n.changeLanguage('fi')} />
                                        <Dropdown.Item flag="gb" onClick={() => i18n.changeLanguage('en')} />
                                    </DropdownMenu>
                                </Dropdown>

                            </React.Fragment>
                        ) : (
                                <React.Fragment>
                                    <Button
                                        type="button"
                                        inverted={!fixed}
                                        onClick={() => this.props.history.push("/login")}
                                        icon="sign-in"
                                    >
                                        {t('Login')}
                                    </Button>
                                    <Dropdown item direction="left" trigger={<Flag name={i18n.language === "en" ? "gb" : i18n.language} />} >
                                        <DropdownMenu onClick={this.handleSidebarHide}>
                                            <Dropdown.Item flag="fi" onClick={() => i18n.changeLanguage('fi')} />
                                            <Dropdown.Item flag="gb" onClick={() => i18n.changeLanguage('en')} />
                                        </DropdownMenu>
                                    </Dropdown>

                                </React.Fragment>
                            )}
                    </Menu.Item>
                </Sidebar>

                <Sidebar.Pusher dimmed={sidebarOpened}>
                    <Segment
                        inverted
                        textAlign="center"
                        style={{ padding: "1em 0em" }}
                        vertical
                    >
                        <Container>
                            <Menu inverted pointing secondary size="large">
                                <Menu.Item onClick={this.handleToggle}>
                                    <Icon name="sidebar" />
                                </Menu.Item>
                                <Menu.Item
                                    //active={this.props.location.pathname === "/"}
                                    onClick={() => this.props.history.push("/")}
                                    content={<Image src={Logo} size='small' verticalAlign="bottom" />}
                                    fitted="vertically"
                                    position="left"
                                ></Menu.Item>
                                <Menu.Item position="left" fitted="vertically">
                                    {isAuthenticated ? (
                                        <React.Fragment>
                                            {/* {userCompanies &&
                                                <Dropdown item text={company_name ? (company_name) : (localStorage.getItem("activeCompanyName"))}>
                                                    <Dropdown.Menu>
                                                        {userCompanies.map((item, key) =>
                                                            <Dropdown.Item
                                                                key={key}
                                                                text={item.name}
                                                                onClick={() =>
                                                                    this.setActiveCompanyId(item.id, item.name)
                                                                }
                                                            />
                                                        )}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            } */}
                                            <Dropdown item icon="user outline" direction="left">
                                                <Dropdown.Menu>
                                                    <Dropdown.Item
                                                        text={localStorage.getItem("userFullName") !== "" ? (localStorage.getItem("userFullName")) : (localStorage.getItem("userName"))}
                                                        onClick={() =>
                                                            this.props.history.push("/account/account-details")
                                                        }
                                                        icon="user"
                                                    />
                                                    {(userCompanies && activeRole && activeRole.role !== "C") && (
                                                        <Dropdown.Item
                                                            text={t('Company')}
                                                            onClick={() =>
                                                                this.props.history.push("/company/company-details")
                                                            }
                                                            icon="building"
                                                        />
                                                    )}
                                                    <Dropdown.Item
                                                        text={t('Logout')}
                                                        onClick={() =>
                                                            this.handleLogout()
                                                        }
                                                        icon="power"
                                                    />
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </React.Fragment>
                                    ) : (
                                            <React.Fragment>
                                                <Button
                                                    type="button"
                                                    inverted
                                                    onClick={() => this.props.history.push("/login")}
                                                    icon="sign-in"
                                                    size="large"
                                                />
                                            </React.Fragment>
                                        )}
                                </Menu.Item>
                            </Menu>
                        </Container>
                    </Segment>

                    {children}
                </Sidebar.Pusher>
            </Responsive>
        );
    }
}

MobileContainer.propTypes = {
    children: PropTypes.node
};

const mapStateToProps = state => {
    return {
        isAuthenticated: state.auth.token !== null,
        activeCompanyId: state.company.activeCompanyId,
        userCompanies: state.company.userCompanies,
        activeRole: state.company.activeRole
    };
};

const mapDispatchToProps = dispatch => {
    return {
        logout: () => dispatch(logout()),
        setCompany: (id, name, roles) => dispatch(setActiveCompany(id, name, roles))
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(withTranslation('desktopcontainer')(MobileContainer))
);