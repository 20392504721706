// Copyright Caproc Oy
// System name: Aqua-Net 
// System module: store
// Author: TS
//
// 1.2.2020 TS Initial version
// 30.3.2020 TS Added COMPANY_SET_ACTIVE_ROLES
// 8.7.2020 TS Added COMPANY_SET_ACTIVE_ROLE

export const AUTH_START = "AUTH_START";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAIL = "AUTH_FAIL";
export const AUTH_LOGOUT = "AUTH_LOGOUT";

export const COMPANY_FETCHUSERCOMPANIES_START = "COMPANY_FETCHUSERCOMPANIES_START";
export const COMPANY_FETCHUSERCOMPANIES_SUCCESS = "COMPANY_FETCHUSERCOMPANIES_SUCCESS";
export const COMPANY_FETCHUSERCOMPANIES_FAIL = "COMPANY_FETCHUSERCOMPANIES_FAIL";

export const COMPANY_SET_ACTIVE_COMPANY = "COMPANY_SET_ACTIVE_COMPANY";
export const COMPANY_SET_ACTIVE_ROLES = "COMPANY_SET_ACTIVE_ROLES";
export const COMPANY_RESET_COMPANIES = "COMPANY_RESET_COMPANIES";
export const COMPANY_SET_ACTIVE_ROLE = "COMPANY_SET_ACTIVE_ROLE";

