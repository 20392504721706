// Copyright Caproc Oy
// System name: Aqua-Net 
// System module: Project
// Author: TS
//
// 1.2.2020 TS Initial version
// 8.3.2020 TS Project URLs
// 14.3.2020 TS Project task URLs
// 26.3.2020 TS InvoiceAbnormalPayment URLs
// 29.3.2020 TS Added Contract price change URLs
// 11.5.2020 TS Added vat_value_options
// 29.5.2020 TS Added search_status options in to invoice report urls
// 3.9.2020 TS Added saveMultipleEditInvoicesURL
// 31.10.2021 TS Added reminderInvoiceNewCopyURL
// 14.6.2023 TS Added fetchReportPdfURL and createReportPdfURL

let DEBUG = false;
export let host = "http://127.0.0.1:8000";
if (DEBUG === false) {
    host = "https://www.sales.caproc.fi";
}
const apiURL = "/api";

export const endpoint = `${host}${apiURL}`;
// User
export const accountDetailsURL = `${endpoint}/account-details/`;
export const updateAccountDetailsURL = `${endpoint}/update-account/`;
export const changePasswordURL = `${host}/rest-auth/password/change/`;
export const logoutURL = `${host}/rest-auth/logout/`;
export const loginURL = `${host}/rest-auth/login/`;
export const signupURL = `${host}/rest-auth/registration/`;
export const resetPasswordURL = `${host}/rest-auth/password/reset/`;
export const resetPasswordConfirmURL = `${host}/rest-auth/password/reset/confirm/`;
export const userExistsURL = company_id => `${endpoint}/${company_id}/user-exists/`;
// Company
export const companyDetailsURL = (company_id, id) => `${endpoint}/${company_id}/companies/${id}/`;
export const updateCompanyDetailsURL = (company_id, id) => `${endpoint}/${company_id}/companies/${id}/update/`;
export const companyListURL = `${endpoint}/companies/`;
// Company users
export const companyUsersListURL = company_id => `${endpoint}/${company_id}/users/`;
export const companyActiveUsersListURL = company_id => `${endpoint}/${company_id}/active-users/`;
export const companyUserDetailsURL = (company_id, id) => `${endpoint}/${company_id}/user-details/${id}/`;
export const companyUserDetailsUpdateURL = (company_id, id) => `${endpoint}/${company_id}/user-details/${id}/update/`;
export const companyUserRoleOptionsURL = (company_id) => `${endpoint}/${company_id}/company/user-role-options/`;
// Company vat value options
export const vatValueOptionsURL = company_id => `${endpoint}/${company_id}/company/vat-value-options/`;
// Company files
export const companyFilesListURL = company_id => `${endpoint}/${company_id}/files/`;
export const companyFileDetailsURL = (company_id, id) => `${endpoint}/${company_id}/file/${id}/`;
export const companyFileDetailsUpdateURL = (company_id, id) => `${endpoint}/${company_id}/file/${id}/update/`;
export const companyFileUploadURL = (company_id) => `${endpoint}/${company_id}/file/upload/`;
export const companyFileDeleteURL = (company_id, id) => `${endpoint}/${company_id}/file/${id}/delete/`;
export const companyFileDownloadURL = (company_id, id) => `${endpoint}/${company_id}/file/${id}/download/`;
// Company contacts
export const updateCompanyContactURL = (company_id, id) => `${endpoint}/${company_id}/contact/${id}/update/`;
export const companyContactsURL = (company_id) => `${endpoint}/${company_id}/contacts/`;
export const createCompanyContactURL = (company_id) => `${endpoint}/${company_id}/contact/create/`;
export const deleteCompanyContactURL = (company_id, id) => `${endpoint}/${company_id}/contact/${id}/delete/`;
export const companyContactDetailsURL = (company_id, id) => `${endpoint}/${company_id}/contact/${id}/`;
export const companyContactRoleOptionsURL = (company_id) => `${endpoint}/${company_id}/contact-role-options/`;
// Company user roles
export const companyAccountRolesURL = company_id => `${endpoint}/${company_id}/account-roles/`;
// Countries
export const countriesURL = `${endpoint}/countries/`;
// Company invoicing details
export const invoicingDetailsURL = company_id => `${endpoint}/${company_id}/companies/invoicing/`;
export const updateInvoicingDetailsURL = (company_id, id) => `${endpoint}/${company_id}/companies/invoicing/${id}/update/`;
//Company data collection
export const companyDataCollectionsURL = (company_id) => `${endpoint}/${company_id}/customer-data-collections/`;
export const createMultipleCustomerDataCollectionURL = (company_id) => `${endpoint}/${company_id}/multiple-customer-data-collection/create/`;
export const companyDataCollectionItemsURL = (company_id, start_date, end_date) => `${endpoint}/${company_id}/customer-data-collection-items/${start_date}/${end_date}/`;
export const companyDataCollectionItemsImportUploadURL = (company_id) => `${endpoint}/${company_id}/customer-data-collection-items/import/file/upload/`;
export const saveImportedDataCollectionItemsURL = (company_id) => `${endpoint}/${company_id}/customer-data-collection-items/save-imported-items/`;
// Company bank transactions
export const companyBankTransactionsURL = (company_id, start_date, end_date) => `${endpoint}/${company_id}/company-bank-transactions/${start_date}/${end_date}/`;
export const deleteBankTransactionURL = (company_id, id) => `${endpoint}/${company_id}/company-bank-transactions/${id}/delete/`;
export const companyBankTransactionsImportUploadURL = (company_id) => `${endpoint}/${company_id}/company-bank-transactions/import/file/upload/`;
// Product
export const fetchProductsURL = company_id => `${endpoint}/${company_id}/products/`;
export const fetchProductGroupsURL = company_id => `${endpoint}/${company_id}/products/groups/`;
export const productDetailsURL = (company_id, id) => `${endpoint}/${company_id}/products/${id}/`;
export const updateProductDetailsURL = (company_id, id) => `${endpoint}/${company_id}/products/${id}/update/`;
export const createProductDetailsURL = (company_id) => `${endpoint}/${company_id}/products/create/`;
export const deleteProductDetailsURL = (company_id, id) => `${endpoint}/${company_id}/products/${id}/delete/`;
// Product Group
export const productGroupDetailsURL = (company_id, id) => `${endpoint}/${company_id}/products/groups/${id}/`;
export const updateProductGroupDetailsURL = (company_id, id) => `${endpoint}/${company_id}/products/groups/${id}/update/`;
export const createProductGroupDetailsURL = (company_id) => `${endpoint}/${company_id}/products/groups/create/`;
export const deleteProductGroupDetailsURL = (company_id, id) => `${endpoint}/${company_id}/products/groups/${id}/delete/`;

export const fetchMeasureUnitsURL = (company_id, language_id) => `${endpoint}/${company_id}/product_measure_units/${language_id}/`;
export const fetchCustomerGroupsURL = company_id => `${endpoint}/${company_id}/customers/groups/`;
export const fetchPlainCustomerGroupsURL = company_id => `${endpoint}/${company_id}/customers/plain-groups/`;

// Customer
export const customerDetailsURL = (company_id, id) => `${endpoint}/${company_id}/customers/${id}/`;
export const customerFullDetailsURL = (company_id, id) => `${endpoint}/${company_id}/customer-full-details/${id}/`;
export const updateCustomerDetailsURL = (company_id, id) => `${endpoint}/${company_id}/customers/${id}/update/`;
export const createCustomerDetailsURL = (company_id) => `${endpoint}/${company_id}/customers/create/`;
export const deleteCustomerDetailsURL = (company_id, id) => `${endpoint}/${company_id}/customers/${id}/delete/`;
export const fetchCustomersURL = company_id => `${endpoint}/${company_id}/customers/`;
export const fetchCustomersInGroupURL = (company_id, id) => `${endpoint}/${company_id}/customers-in-group/${id}/`;
export const customerImportUploadURL = (company_id) => `${endpoint}/${company_id}/customers-import/file/upload/`;
export const saveImportedCustomersURL = (company_id) => `${endpoint}/${company_id}/customers-import/save-imported/`;
export const saveMultipleEditCustomersURL = (company_id) => `${endpoint}/${company_id}/customers-multiple-edit/save/`;
// Example Customers import file
export const fetchExampleCustomersImportFile = company_id => `${endpoint}/${company_id}/customers-import-example/`;
// Customer Group
export const customerGroupDetailsURL = (company_id, id) => `${endpoint}/${company_id}/customers/groups/${id}/`;
export const updateCustomerGroupDetailsURL = (company_id, id) => `${endpoint}/${company_id}/customers/groups/${id}/update/`;
export const createCustomerGroupDetailsURL = (company_id) => `${endpoint}/${company_id}/customers/groups/create/`;
export const deleteCustomerGroupDetailsURL = (company_id, id) => `${endpoint}/${company_id}/customers/groups/${id}/delete/`;
// Customer invoicing details
export const updateCustomerInvoicingDetailsURL = (company_id, customer_id, id) => `${endpoint}/${company_id}/customers/${customer_id}/invoicing/${id}/update/`;
export const customerInvoicingDetailsURL = (company_id, customer_id) => `${endpoint}/${company_id}/customers/${customer_id}/invoicing/`;
export const customerInvoicingMethodOptionsURL = (company_id, customer_id) => `${endpoint}/${company_id}/customers/${customer_id}/invoicing/invoicing-method-options/`;
// Customer addresses
export const updateCustomerAddressURL = (company_id, customer_id, id) => `${endpoint}/${company_id}/customers/${customer_id}/addresses/${id}/update/`;
export const customerAddressesURL = (company_id, customer_id) => `${endpoint}/${company_id}/customers/${customer_id}/addresses/`;
// Customer contacts
export const updateCustomerContactURL = (company_id, customer_id, id) => `${endpoint}/${company_id}/customers/${customer_id}/contact/${id}/update/`;
export const customerContactsURL = (company_id, customer_id) => `${endpoint}/${company_id}/customers/${customer_id}/contacts/`;
export const createCustomerContactURL = (company_id, customer_id) => `${endpoint}/${company_id}/customers/${customer_id}/contact/create/`;
export const deleteCustomerContactURL = (company_id, customer_id, id) => `${endpoint}/${company_id}/customers/${customer_id}/contact/${id}/delete/`;
export const customerContactDetailsURL = (company_id, customer_id, id) => `${endpoint}/${company_id}/customers/${customer_id}/contact/${id}/`;
export const customerContactRoleOptionsURL = (company_id, customer_id) => `${endpoint}/${company_id}/customers/${customer_id}/contact-role-options/`;
// Customer data collections
export const updateCustomerDataCollectionURL = (company_id, customer_id, id) => `${endpoint}/${company_id}/customers/${customer_id}/customer-data-collection/${id}/update/`;
export const customerDataCollectionsURL = (company_id, customer_id) => `${endpoint}/${company_id}/customers/${customer_id}/customer-data-collections/`;
export const createCustomerDataCollectionURL = (company_id, customer_id) => `${endpoint}/${company_id}/customers/${customer_id}/customer-data-collection/create/`;
export const deleteCustomerDataCollectionURL = (company_id, customer_id, id) => `${endpoint}/${company_id}/customers/${customer_id}/customer-data-collection/${id}/delete/`;
export const customerDataCollectionDetailsURL = (company_id, customer_id, id) => `${endpoint}/${company_id}/customers/${customer_id}/customer-data-collection/${id}/`;
// Data collection items
export const customerDataCollectionItemsURL = (company_id, customer_id, start_date, end_date) => `${endpoint}/${company_id}/customers/${customer_id}/customer-data-collection-items/${start_date}/${end_date}/`;
export const customerDataCollectionItemDetailsURL = (company_id, customer_id, id) => `${endpoint}/${company_id}/customers/${customer_id}/customer-data-collection-item/${id}/`;
export const createCustomerDataCollectionItemURL = (company_id, customer_id) => `${endpoint}/${company_id}/customers/${customer_id}/customer-data-collection-item/create/`;
export const deleteCustomerDataCollectionItemURL = (company_id, customer_id, id) => `${endpoint}/${company_id}/customers/${customer_id}/customer-data-collection-item/${id}/delete/`;
export const fetchFullDetailDataCollectionItemsURL = (company_id, ids_list) => `${endpoint}/${company_id}/data-collection-items/full-details/${ids_list}/`;
// Customer files
export const customerFilesListURL = (company_id, customer_id) => `${endpoint}/${company_id}/customers/${customer_id}/files/`;
export const customerFileDetailsURL = (company_id, customer_id, id) => `${endpoint}/${company_id}/customers/${customer_id}/file/${id}/`;
export const customerFileDetailsUpdateURL = (company_id, customer_id, id) => `${endpoint}/${company_id}/customers/${customer_id}/file/${id}/update/`;
export const customerFileUploadURL = (company_id, customer_id) => `${endpoint}/${company_id}/customers/${customer_id}/file/upload/`;
export const customerFileDeleteURL = (company_id, customer_id, id) => `${endpoint}/${company_id}/customers/${customer_id}/file/${id}/delete/`;
export const customerFileDownloadURL = (company_id, customer_id, id) => `${endpoint}/${company_id}/customers/${customer_id}/file/${id}/download/`;
// Customer contracts
export const customerActiveContractsURL = (company_id, customer_id) => `${endpoint}/${company_id}/customers/${customer_id}/active-contracts/`;
// Invoices
export const fetchOpenInvoicesURL = (company_id, status) => `${endpoint}/${company_id}/invoices/${status}/`;
export const customerInvoicesURL = (company_id, customer_id) => `${endpoint}/${company_id}/customer-invoices/${customer_id}/`;
export const fetchOpenInvoicesByDatesURL = (company_id, status, start_date, end_date) => `${endpoint}/${company_id}/invoices/${status}/${start_date}/${end_date}/`;
export const fetchCustomerInvoicesByStatusURL = (company_id, customer_id, status) => `${endpoint}/${company_id}/customer/${customer_id}/invoices/${status}/`;
export const fetchFullDetailInvoicesURL = (company_id, invoice_ids_list) => `${endpoint}/${company_id}/invoices/full-details/${invoice_ids_list}/`;
export const fetchReportDetailInvoicesURL = (company_id, invoice_ids_list) => `${endpoint}/${company_id}/invoices/report-details/${invoice_ids_list}/`;
export const updateInvoiceDetailsURL = (company_id, id) => `${endpoint}/${company_id}/invoice/${id}/update/`;
export const modifyInvoiceDetailsURL = (company_id, id) => `${endpoint}/${company_id}/invoice-modify/${id}/update/`;
export const deleteInvoiceURL = (company_id, id) => `${endpoint}/${company_id}/invoice/${id}/delete/`;
export const creditInvoiceURL = (company_id, id) => `${endpoint}/${company_id}/invoice/${id}/credit/`;
export const invoiceDetailsURL = (company_id, id) => `${endpoint}/${company_id}/invoice/${id}/`;
export const createInvoiceURL = (company_id) => `${endpoint}/${company_id}/invoice/create/`;
export const createInvoiceForMultipleCustomersURL = (company_id) => `${endpoint}/${company_id}/invoice/multiple-customers/create/`;
export const saveMultipleEditInvoicesURL = (company_id) => `${endpoint}/${company_id}/invoices-multiple-edit/save/`;
export const reminderInvoiceNewCopyURL = (company_id, id, orig_invoice_new_status) => `${endpoint}/${company_id}/invoice/${id}/new-reminder-copy/${orig_invoice_new_status}/`;

// Invoice items
export const updateInvoiceItemURL = (company_id, invoice_id, id) => `${endpoint}/${company_id}/invoice/${invoice_id}/item/${id}/update/`;
export const createInvoiceItemURL = (company_id, id) => `${endpoint}/${company_id}/invoice/${id}/item/create/`;
export const deleteInvoiceItemURL = (company_id, invoice_id, id) => `${endpoint}/${company_id}/invoice/${invoice_id}/item/${id}/delete/`;
// Invoice item from Project task
export const createInvoiceItemFromProjectTaskURL = (company_id, id) => `${endpoint}/${company_id}/invoice/${id}/item-from-project-task/create/`;
// Invoice abnormal payments
export const updateInvoiceAbnormalPaymentURL = (company_id, invoice_id, id) => `${endpoint}/${company_id}/invoice/${invoice_id}/abnormalpayment/${id}/update/`;
export const createInvoiceAbnormalPaymentURL = (company_id, id) => `${endpoint}/${company_id}/invoice/${id}/abnormalpayment/create/`;
export const deleteInvoiceAbnormalPaymentURL = (company_id, invoice_id, id) => `${endpoint}/${company_id}/invoice/${invoice_id}/abnormalpayment/${id}/delete/`;
// Invoice comments
export const updateInvoiceCommentURL = (company_id, invoice_id, id) => `${endpoint}/${company_id}/invoice/${invoice_id}/comment/${id}/update/`;
export const createInvoiceCommentURL = (company_id, id) => `${endpoint}/${company_id}/invoice/${id}/comment/create/`;
export const deleteInvoiceCommentURL = (company_id, invoice_id, id) => `${endpoint}/${company_id}/invoice/${invoice_id}/comment/${id}/delete/`;
// Invoice PDF
export const fetchInvoicePdfURL = (company_id, invoice_id) => `${endpoint}/${company_id}/invoice-pdf/${invoice_id}/`;
// export const saveAndSendInvoicePdfURL = (company_id, invoice_id) => `${endpoint}/${company_id}/invoice-pdf/${invoice_id}/save-and-send/`;
export const saveAndSendInvoicePdfURL = (company_id) => `${endpoint}/${company_id}/invoice-pdf/save-and-send/`;
export const sendInvoiceReminderURL = (company_id, invoice_id, print_only) => `${endpoint}/${company_id}/invoice-pdf/${invoice_id}/send-reminder/${print_only}/`;
export const addInvoicesInToSendQueueURL = (company_id) => `${endpoint}/${company_id}/invoice-list/add-to-send-queue/`;

// Open invoices statistics
export const openInvoicesStatistics = (company_id) => `${endpoint}/${company_id}/open-invoices-statistics/`;
// Contract group
export const fetchContractGroupsURL = company_id => `${endpoint}/${company_id}/contract/groups/`;
export const contractGroupDetailsURL = (company_id, id) => `${endpoint}/${company_id}/contract/group/${id}/`;
export const updateContractGroupDetailsURL = (company_id, id) => `${endpoint}/${company_id}/contract/group/${id}/update/`;
export const createContractGroupDetailsURL = (company_id) => `${endpoint}/${company_id}/contract/group/create/`;
export const deleteContractGroupDetailsURL = (company_id, id) => `${endpoint}/${company_id}/contract/group/${id}/delete/`;
export const fetchPlainContractGroupsURL = company_id => `${endpoint}/${company_id}/contracts/plain-groups/`;
// Contract
export const deleteContractDetailsURL = (company_id, id) => `${endpoint}/${company_id}/contract/${id}/delete/`;
export const contractDetailsURL = (company_id, id) => `${endpoint}/${company_id}/contract/${id}/`;
export const contractFullDetailsURL = (company_id, id) => `${endpoint}/${company_id}/contract-full-details/${id}/`;
export const updateContractDetailsURL = (company_id, id) => `${endpoint}/${company_id}/contract/${id}/update/`;
export const createContractURL = (company_id) => `${endpoint}/${company_id}/contract/create/`;
export const fetchContractsURL = (company_id, id) => `${endpoint}/${company_id}/contracts/`;
export const fetchContractsInGroupURL = (company_id, id) => `${endpoint}/${company_id}/contracts-in-group/${id}/`;
export const saveMultipleEditContractsURL = (company_id) => `${endpoint}/${company_id}/contracts-multiple-edit/save/`;
// Contract items
export const updateContractItemURL = (company_id, contract_id, id) => `${endpoint}/${company_id}/contract/${contract_id}/item/${id}/update/`;
export const createContractItemURL = (company_id, id) => `${endpoint}/${company_id}/contract/${id}/item/create/`;
export const deleteContractItemURL = (company_id, contract_id, id) => `${endpoint}/${company_id}/contract/${contract_id}/item/${id}/delete/`;
// Contract price change type options
export const contractPriceChangeTypeOptionsURL = company_id => `${endpoint}/${company_id}/contract-price-change-type-options/`;
// Contract price change run
export const contractPriceChangeRunURL = (company_id, id) => `${endpoint}/${company_id}/contract-price-change/run/${id}/`;
// Contract price change
export const fetchContractPriceChangesURL = company_id => `${endpoint}/${company_id}/contract-price-changes/`;
export const deleteContractPriceChangeURL = (company_id, id) => `${endpoint}/${company_id}/contract-price-change/${id}/delete/`;
export const contractPriceChangeDetailsURL = (company_id, id) => `${endpoint}/${company_id}/contract-price-change/${id}/`;
export const updateContractPriceChangeURL = (company_id, id) => `${endpoint}/${company_id}/contract-price-change/${id}/update/`;
export const createContractPriceChangeURL = (company_id) => `${endpoint}/${company_id}/contract-price-change/create/`;
// Contract price change product
export const createPriceChangeProductURL = (company_id, id) => `${endpoint}/${company_id}/contract-price-change/${id}/product/create/`;
export const deletePriceChangeProductURL = (company_id, contract_pc_id, id) => `${endpoint}/${company_id}/contract-price-change/${contract_pc_id}/product/${id}/delete/`;
// Contract price change contract
export const createPriceChangeContractURL = (company_id, id) => `${endpoint}/${company_id}/contract-price-change/${id}/contract/create/`;
export const deletePriceChangeContractURL = (company_id, contract_pc_id, id) => `${endpoint}/${company_id}/contract-price-change/${contract_pc_id}/contract/${id}/delete/`;
// Messages
export const messagesListURL = company_id => `${endpoint}/${company_id}/messages/`;
export const messageAttachmentDownloadURL = (company_id, id) => `${endpoint}/${company_id}/message/attachment/${id}/download/`;
export const messageAttachmentUploadURL = (company_id, id) => `${endpoint}/${company_id}/message/${id}/attachment/upload/`;
export const messageCreateURL = company_id => `${endpoint}/${company_id}/message/create/`;
export const messageSendURL = (company_id, id) => `${endpoint}/${company_id}/message/${id}/send/`;
// Recepient groups
export const recepientGroupsURL = company_id => `${endpoint}/${company_id}/recepient-groups/`;
// Tasks/Company
export const companyTaskListURL = company_id => `${endpoint}/${company_id}/tasks/`;
export const companyTaskDetailsURL = (company_id, id) => `${endpoint}/${company_id}/task/${id}/`;
export const companyTaskFileDownloadURL = (company_id, id) => `${endpoint}/${company_id}/task/file/${id}/download/`;
export const companyTaskFileUploadURL = (company_id, id) => `${endpoint}/${company_id}/task/${id}/file/upload/`;
export const companyTaskCreateURL = company_id => `${endpoint}/${company_id}/task/create/`;
export const companyTaskUpdateURL = (company_id, id) => `${endpoint}/${company_id}/task/${id}/update/`;
export const companyTaskDeleteURL = (company_id, id) => `${endpoint}/${company_id}/task/${id}/delete/`;
export const companyTaskFileDeleteURL = (company_id, id) => `${endpoint}/${company_id}/task/file/${id}/delete/`;
// Tasks/Customer
export const customerTaskListURL = (company_id, customer_id) => `${endpoint}/${company_id}/customers/${customer_id}/tasks/`;
export const customerTaskDetailsURL = (company_id, customer_id, id) => `${endpoint}/${company_id}/customers/${customer_id}/task/${id}/`;
export const customerTaskFileDownloadURL = (company_id, customer_id, id) => `${endpoint}/${company_id}/customers/${customer_id}/task/file/${id}/download/`;
export const customerTaskFileUploadURL = (company_id, customer_id, id) => `${endpoint}/${company_id}/customers/${customer_id}/task/${id}/file/upload/`;
export const customerTaskCreateURL = (company_id, customer_id) => `${endpoint}/${company_id}/customers/${customer_id}/task/create/`;
export const customerTaskUpdateURL = (company_id, customer_id, id) => `${endpoint}/${company_id}/customers/${customer_id}/task/${id}/update/`;
export const customerTaskDeleteURL = (company_id, customer_id, id) => `${endpoint}/${company_id}/customers/${customer_id}/task/${id}/delete/`;
export const customerTaskFileDeleteURL = (company_id, customer_id, id) => `${endpoint}/${company_id}/customers/${customer_id}/task/file/${id}/delete/`;
// Reports
export const reportInvoicesByMonthURL = (company_id, start_date, end_date, search_status) => `${endpoint}/${company_id}/report/invoices/month/${start_date}/${end_date}/${search_status}/`;
export const reportCustomerInvoicesURL = (company_id, start_date, end_date, search_status) => `${endpoint}/${company_id}/report/invoices/customers/${start_date}/${end_date}/${search_status}/`;
export const reportProductInvoicesURL = (company_id, start_date, end_date, search_status) => `${endpoint}/${company_id}/report/invoices/products/${start_date}/${end_date}/${search_status}/`;
export const reportTradeReceivablesURL = (company_id) => `${endpoint}/${company_id}/report/trade-receivables/`;
export const reportTradeReceivablesWithTimeframeURL = (company_id, start_date, end_date) => `${endpoint}/${company_id}/report/trade-receivables-timeframe/${start_date}/${end_date}/`;
export const reportDataCollectionDetailsURL = (company_id, customer_id) => `${endpoint}/${company_id}/report/data-collection-details/${customer_id}/`;
export const reportDataCollectionURL = (company_id, report_type, start_date, end_date, product_id, customer_id) => `${endpoint}/${company_id}/report/data-collection/${report_type}/${start_date}/${end_date}/${product_id}/${customer_id}/`;
export const reportPaymentTransactionsURL = (company_id, start_date, end_date) => `${endpoint}/${company_id}/report/payment-transactions/${start_date}/${end_date}/`;
// Dashboard
export const fetchDashboardFinaStatisticsURL = (company_id, year, month) => `${endpoint}/${company_id}/dashboard-statistics/fina/${year}/${month}/`;
// Project group
export const fetchProjectGroupsURL = company_id => `${endpoint}/${company_id}/project/groups/`;
export const projectGroupDetailsURL = (company_id, id) => `${endpoint}/${company_id}/project/group/${id}/`;
export const updateProjectGroupDetailsURL = (company_id, id) => `${endpoint}/${company_id}/project/group/${id}/update/`;
export const createProjectGroupDetailsURL = (company_id) => `${endpoint}/${company_id}/project/group/create/`;
export const deleteProjectGroupDetailsURL = (company_id, id) => `${endpoint}/${company_id}/project/group/${id}/delete/`;
// Project
export const deleteProjectDetailsURL = (company_id, id) => `${endpoint}/${company_id}/project/${id}/delete/`;
export const projectDetailsURL = (company_id, id) => `${endpoint}/${company_id}/project/${id}/`;
export const updateProjectDetailsURL = (company_id, id) => `${endpoint}/${company_id}/project/${id}/update/`;
export const updateProjectActivityURL = (company_id, id) => `${endpoint}/${company_id}/project/${id}/update/activity/`;
export const createProjectURL = (company_id) => `${endpoint}/${company_id}/project/create/`;
export const fetchProjectListURL = company_id => `${endpoint}/${company_id}/projects/`;
// Project member
export const updateProjectMemberURL = (company_id, project_id, id) => `${endpoint}/${company_id}/project/${project_id}/member/${id}/update/`;
export const createProjectMemberURL = (company_id, project_id) => `${endpoint}/${company_id}/project/${project_id}/member/create/`;
export const deleteProjectMemberURL = (company_id, project_id, id) => `${endpoint}/${company_id}/project/${project_id}/member/${id}/delete/`;
// Project product
export const updateProjectProductURL = (company_id, project_id, id) => `${endpoint}/${company_id}/project/${project_id}/product/${id}/update/`;
export const createProjectProductURL = (company_id, project_id) => `${endpoint}/${company_id}/project/${project_id}/product/create/`;
export const deleteProjectProductURL = (company_id, project_id, id) => `${endpoint}/${company_id}/project/${project_id}/product/${id}/delete/`;
// Project tasks
export const fetchProjectTasksURL = (company_id, status) => `${endpoint}/${company_id}/project-tasks/${status}/`;
export const fetchProjectTasksWithDatesURL = (company_id, status, start_date, end_date) => `${endpoint}/${company_id}/project-tasks/dates/${status}/${start_date}/${end_date}/`;
export const fetchProjectTasksForProjectURL = (company_id, project_id) => `${endpoint}/${company_id}/project/${project_id}/tasks/`;
export const fetchProjectTasksWithConditionsURL = (company_id, customer_id, project_id, author_id, status_id, start_date, end_date) => `${endpoint}/${company_id}/project/tasks/${customer_id}/${project_id}/${author_id}/${status_id}/${start_date}/${end_date}/`;
export const projectTaskDetailsURL = (company_id, id) => `${endpoint}/${company_id}/project/task/${id}/`;
export const updateProjectTaskURL = (company_id, id) => `${endpoint}/${company_id}/project/task/${id}/update/`;
export const createProjectTaskURL = (company_id) => `${endpoint}/${company_id}/project/task/create/`;
export const deleteProjectTaskURL = (company_id, id) => `${endpoint}/${company_id}/project/task/${id}/delete/`;
// Project task statistics
export const projectTaskStatistics = (company_id) => `${endpoint}/${company_id}/project-task-statistics/`;
// Project type options
export const projectTypeOptionsURL = company_id => `${endpoint}/${company_id}/project-type-options/`;
export const projectTaskTypeOptionsURL = company_id => `${endpoint}/${company_id}/project-task-type-options/`;
// Sensors
export const fetchActiveSensorsURL = company_id => `${endpoint}/${company_id}/sensors/active/`;
// Automatic sending mode options
export const automaticSendingModeOptionsURL = company_id => `${endpoint}/${company_id}/automatic-sending-mode-options/`;
// Net invoicing details
export const searchNetInvoicingDetailsURL = company_id => `${endpoint}/${company_id}/search-net-invoicing-details/`;
export const searchNetInvoiceDeliveryMethodURL = (company_id, id) => `${endpoint}/${company_id}/customers/${id}/net-invoicing-delivery-method/`;
// Net invoicing settings
export const netInvoicingDetailsURL = company_id => `${endpoint}/${company_id}/net-invoicing-details/`;
export const updateNetInvoicingContractsURL = company_id => `${endpoint}/${company_id}/net-invoicing-details/update/`;
export const updateNetInvoicingStatusURL = company_id => `${endpoint}/${company_id}/net-invoicing-status/update/`;
// GDPR definitions
export const fetchGdprDefinitionsURL = company_id => `${endpoint}/${company_id}/gdpr-definitions/`;
// Export to CSV
export const exportToCsvURL = company_id => `${endpoint}/${company_id}/export-csv/`;
// Example Data Collection Items import file
export const fetchExampleDcItemsImportFile = company_id => `${endpoint}/${company_id}/data-collection-items-import-example/`;
// Customer portal 
export const createSensorReadingURL = company_id => `${endpoint}/${company_id}/add-sensor-reading/`;
// Report PDF
export const fetchReportPdfURL = (company_id, file_name) => `${endpoint}/${company_id}/fetch-report-pdf/${file_name}/`;
export const createReportPdfURL = company_id => `${endpoint}/${company_id}/create-report-pdf/`;









