// Copyright Caproc Oy
// System name: Aqua-Net 
// System module: Contract
// Author: TS
//
// 1.2.2020 TS Initial version

import React from "react";
import { Form, Input, Message, Header, Button } from "semantic-ui-react";
import Shell from "./Shell";
import { authAxios } from "../../utils";
import { setActivity } from "../../store/actions/auth";
import { withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import { contractGroupDetailsURL, updateContractGroupDetailsURL, createContractGroupDetailsURL } from "../../constants";


class ContractGroupDetails extends React.Component {
    state = {
        company: "",
        name: "",
        description: "",
        contract_group_id: null,
        error: null,
        success_mgs: null,
        loading: false,
        create: null,
        default_group: false
    };

    componentDidMount() {
        this.props.setActivity();
        if (this.props.match.params.contract_group_id === "create") {
            this.setState({
                create: true,
                is_active: true
            })
        } else {
            this.handleDetails();
        }
    }

    handleSelectChange = (e, { name, value }) => {
        this.setState({
            [name]: value
        });
    };

    handleDetails = () => {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;
        const { contract_group_id } = this.props.match.params;
        authAxios
            .get(contractGroupDetailsURL(activeCompanyId, contract_group_id))
            .then(res => {
                this.setState({
                    contract_group_id: res.data.id,
                    company: res.data.company,
                    name: res.data.name,
                    description: res.data.description,
                    error: null,
                    success_mgs: null,
                    loading: false,
                    default_group: res.data.name === "Default group" ? true : false
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    success_mgs: null,
                    error: err
                });
            });
    };

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value,
            success_mgs: null,
            error: null
        });
    };

    handleSubmit = e => {
        e.preventDefault();
        this.setState({
            loading: true
        });
        const {
            company,
            name,
            description,
            contract_group_id,
            create
        } = this.state;
        const { activeCompanyId, t } = this.props;
        if (name === "") {
            this.setState({
                error: t("Please fill in name"),
                success_mgs: null,
                loading: false
            });
        } else if (name.length > 100) {
            this.setState({
                error: t("Max amount of characters is 100 for Name"),
                success_mgs: null,
                loading: false
            });
        } else if (create && (name === "Default group" || name === t("Default group"))) {
            this.setState({
                error: t("Please check Name. Name cannot be Default group"),
                success_mgs: null,
                loading: false
            });
        } else {
            if (create) {
                authAxios
                    .post(createContractGroupDetailsURL(activeCompanyId), {
                        company: activeCompanyId,
                        name,
                        description,
                        author: localStorage.getItem("UserID")
                    })
                    .then(res => {
                        this.props.history.push(`/contract/contract-groups`)
                    })
                    .catch(err => {
                        this.setState({
                            loading: false,
                            success_mgs: null,
                            error: err
                        });
                    });
            } else {
                authAxios
                    .put(updateContractGroupDetailsURL(activeCompanyId, contract_group_id), {
                        company,
                        name,
                        description
                    })
                    .then(res => {
                        this.props.history.push(`/contract/contract-groups`)
                    })
                    .catch(err => {
                        this.setState({
                            loading: false,
                            success_mgs: null,
                            error: err
                        });
                    });
            }
        }
    };

    render() {
        const {
            name,
            description,
            error,
            success_mgs,
            loading,
            create
        } = this.state;
        const { isAuthenticated, t } = this.props;
        if (!isAuthenticated) {
            return <Redirect to="/login" />;
        }

        return (
            <Shell>
                <Header as="h4">{t("Contract group details")}</Header>
                <Form error={error !== null}>
                    <Form.Field>
                        <label>{t("Name")}</label>
                        <Input
                            disabled={this.state.default_group}
                            value={name === "Default group" ? t("Default group") : name}
                            name="name"
                            onChange={this.handleChange} />
                    </Form.Field>
                    <Form.Field>
                        <label>{t("Description")}</label>
                        <Input
                            value={description}
                            name="description"
                            onChange={this.handleChange} />
                    </Form.Field>

                    {error && <Message error header={t("Error")} content={error} />}
                    {success_mgs && <Message positive header={success_mgs} />}
                    <Button primary type="button" onClick={(e) => this.handleSubmit(e)} loading={loading} disabled={loading}>
                        {create ? (t("Create")) : (t("Save"))}
                    </Button>
                </Form>
            </Shell>
        );
    }
}
const mapStateToProps = state => {
    return {
        activeCompanyId: state.company.activeCompanyId,
        isAuthenticated: state.auth.token !== null
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setActivity: () => dispatch(setActivity())
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(withTranslation('contractgroupdetails')(ContractGroupDetails))
);