// Copyright Caproc Oy
// System name: Aqua-Net 
// System module: CustomerPortal
// Author: TS
//
// 10.7.2020 TS Initial version
// 11.7.2020 TS Added Company Selection banner

import React from "react";
import { Grid, Container, Segment, Header, Menu, Icon } from "semantic-ui-react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import { CompanySelection } from "../../utils";

const Shell = props => (
    <>
    <Container><CompanySelection/></Container>
    < Segment vertical>
        <Container>
            <Grid container stackable verticalAlign="top" divided columns={2}>
                <Grid.Row>
                    <Grid.Column width={3}>
                        <Menu vertical fluid icon="labeled" compact> 
                            {props.active_customer_id && (
                                <React.Fragment>
                                    <Menu.Item>
                                        <Header as="h4">{props.active_customer_name}</Header>
                                    </Menu.Item>

                                    <Menu.Item
                                        active={props.location.pathname === `/customer-portal/customer-details/${props.active_customer_id}/`}
                                        onClick={() => props.history.push(`/customer-portal/customer-details/${props.active_customer_id}/`)}
                                        name="customer-details"
                                        icon="home"
                                        position="left"
                                    >
                                         <Icon name="home"/> {props.t("Details")}
                                    </Menu.Item>
                                    <Menu.Item
                                        active={props.location.pathname === `/customer-portal/contacts/${props.active_customer_id}/`}
                                        onClick={() => props.history.push(`/customer-portal/contacts/${props.active_customer_id}/`)}
                                        name="customer-contacts"
                                    >
                                        <Icon name="address book outline"/> {props.t("Contacts")}
                                    </Menu.Item>
                                    <Menu.Item
                                        active={props.location.pathname === `/customer-portal/invoices/${props.active_customer_id}/`}
                                        onClick={() => props.history.push(`/customer-portal/invoices/${props.active_customer_id}/`)}
                                        name="customer-invoicing-details"
                                    >
                                        <Icon name="file alternate outline"/> {props.t("Invoices")}
                                    </Menu.Item>
                                    <Menu.Item
                                        active={props.location.pathname === `/customer-portal/${props.active_customer_id}/files/`}
                                        onClick={() => props.history.push(`/customer-portal/${props.active_customer_id}/files/`)}
                                        name="customer-files"
                                    >
                                        <Icon name="briefcase"/> {props.t("Files")}
                                    </Menu.Item>
                                    <Menu.Item>
                                        <Header as="h4">{props.t("Data collection")}</Header>
                                    </Menu.Item>
                                    <Menu.Item
                                        active={props.location.pathname === `/customer-portal/${props.active_customer_id}/customer-data-collection/items/`}
                                        onClick={() => props.history.push(`/customer-portal/${props.active_customer_id}/customer-data-collection/items/`)}
                                        name="customer-data-collection-items"
                                    >
                                        <Icon name="list ol"/> {props.t("Collection items")}
                                    </Menu.Item>
                                    <Menu.Item
                                        active={props.location.pathname === `/customer-portal/${props.active_customer_id}/customer-data-collection/report/`}
                                        onClick={() => props.history.push(`/customer-portal/${props.active_customer_id}/customer-data-collection/report/`)}
                                        name="customer-data-collections-report"
                                    >
                                        <Icon name="chart bar outline"/> {props.t("Item report")}
                                    </Menu.Item>
                                    <Menu.Item
                                        active={props.location.pathname === `/customer-portal/${props.active_customer_id}/customer-data-collection/items/add/`}
                                        onClick={() => props.history.push(`/customer-portal/${props.active_customer_id}/customer-data-collection/items/add/`)}
                                        name="customer-data-collection-items-add"
                                    >
                                        <Icon name="edit"/> {props.t("Add meter reading")}
                                    </Menu.Item>
                                    {/* <Menu.Item>
                                        <Header as="h4">{props.t("Tasks")}</Header>
                                    </Menu.Item>
                                    <Menu.Item
                                        active={props.location.pathname === `/customer/${props.active_customer_id}/tasks/`}
                                        onClick={() => props.history.push(`/customer/${props.active_customer_id}/tasks/`)}
                                        name="customer-tasks"
                                    >
                                        {props.t("Completed tasks")}
                                    </Menu.Item> */}
                                </React.Fragment>
                            )}
                        </Menu>
                    </Grid.Column>
                    <Grid.Column width={13}>{props.children}</Grid.Column>
                </Grid.Row>
            </Grid>
        </Container>
    </Segment >
    </>
);

const mapDispatchToProps = dispatch => {
    return {
        // logout: () => dispatch(logout())
    };
};

export default withRouter(
    connect(
        null,
        mapDispatchToProps
    )(withTranslation('customerportalshell')(Shell))
);