// Copyright Caproc Oy
// System name: Aqua-Net 
// System module: Customer
// Author: TS
//
// 1.2.2020 TS Initial version
// 6.6.2020 TS Changed remove icon to trash bin
// 20.6.2020 TS Added export to CSV
// 21.6.2021 TS Added "Last estimate"

import React from "react";
import { Message, Header, Button, Table, Segment, Dimmer, Loader, Image, Icon, Popup, Menu, Confirm, Input, Responsive } from "semantic-ui-react";
import Shell from "./Shell";
import { authAxios, exportToCsv, getWidth } from "../../utils";
import { setActivity } from "../../store/actions/auth";
import { withRouter, Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import { customerDataCollectionsURL, customerDetailsURL, deleteCustomerDataCollectionURL } from "../../constants";


class CustomerDataCollections extends React.Component {
    state = {
        data_collections: null,
        activeMenuItem: this.props.t("Active"),
        delete_confirm_open: false,
        delete_confirm_text: "",
        delete_item: "",
        customer_name: null,
        customer_id: null,
        error: null,
        loading: false,
        search: ""
    };

    componentDidMount() {
        this.props.setActivity();
        this.fetchCustomerDetails();
        this.handleFetchDataCollectionsList();
    }


    fetchCustomerDetails = () => {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;
        const { customer_id } = this.props.match.params;

        authAxios
            .get(customerDetailsURL(activeCompanyId, customer_id))
            .then(res => {
                this.setState({
                    customer_name: res.data.name,
                    error: null,
                    success_mgs: null,
                    loading: false,
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    success_mgs: null,
                    error: err
                });
            });
    };

    handleFetchDataCollectionsList = () => {
        this.setState({
            loading: true,
        });
        const { activeCompanyId } = this.props;
        const { customer_id } = this.props.match.params;
        authAxios
            .get(customerDataCollectionsURL(activeCompanyId, customer_id))
            .then(res => {
                this.setState({
                    data_collections: res.data,
                    error: null,
                    loading: false
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    error: err
                });
            });
    };

    handleDelete = item_id => {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;
        const { customer_id } = this.props.match.params;
        authAxios
            .delete(deleteCustomerDataCollectionURL(activeCompanyId, customer_id, item_id))
            .then(res => {
                this.setState({
                    error: null,
                    loading: false
                });
                this.handleFetchDataCollectionsList();
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    error: err
                });
            });
        this.setState({
            delete_confirm_open: false,
            delete_confirm_text: "",
            delete_item: ""
        });
    };

    openDeleteConfirm = (text, delete_item) => {
        this.setState({
            delete_confirm_open: true,
            delete_confirm_text: text,
            delete_item: delete_item
        });
    };

    closeDeleteConfirm = () => {
        this.setState({
            delete_confirm_open: false,
            delete_confirm_text: "",
            delete_item: ""
        });
    };

    customerDataCollectionDetails = (customer_id, id) => {
        this.props.history.push(`/customer/${customer_id}/customer-data-collection-details/${id}/`)
    };

    handleMenuItemClick = (e, { name }) => this.setState({ activeMenuItem: name });

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    handleExportToCSV = items => {
        const { t } = this.props;
        if (items !== null) {
            const titles = [
                t("Start time"),
                t("Product"),
                t("Source"),
                t("Location"),
                t("Last value"),
                t("Last invoiced value"),
                t("Last montly estimate")
            ];
            let items_to_csv = [];
            let i = 0;
            for (i in items) {
                items_to_csv.push({
                    date_created: new Date(items[i].date_created).toLocaleString('fi-FI'),
                    product: items[i].name,
                    source_id: items[i].source_id,
                    location_id: items[i].location_id,
                    last_value: items[i].last_value,
                    last_invoiced_value: items[i].last_invoiced_value,
                    last_estimate: items[i].last_estimate
                });
            }
            exportToCsv(this.props.activeCompanyId, "export", titles, items_to_csv);
        }
    };

    render() {
        const { error, loading, data_collections, customer_name, activeMenuItem, delete_confirm_open, delete_confirm_text, delete_item, search } = this.state;
        const { customer_id } = this.props.match.params;
        const { isAuthenticated, t } = this.props;
        if (!isAuthenticated) {
            return <Redirect to="/login" />;
        }
        const is_desktop =  getWidth() > Responsive.onlyMobile.maxWidth ? true : false;
        // console.log(this.state);

        let filtered_items = [];

        // Filter data for search
        if (data_collections !== null) {
            filtered_items = data_collections.filter((item) => {
                return item.name.toLowerCase().indexOf(search.toLocaleLowerCase()) !== -1 ||
                    item.source_id.toString().toLowerCase().indexOf(search.toLocaleLowerCase()) !== -1 ||
                    item.location_id.toString().toLowerCase().indexOf(search.toLocaleLowerCase()) !== -1 ;
            })
        }

        return (
            <Shell active_customer_id={customer_id} active_customer_name={customer_name}>
                {is_desktop && (
                    <Button.Group floated="right" basic size='small'>
                        <Button icon="download" content="CSV" onClick={() => this.handleExportToCSV(filtered_items)} />
                    </Button.Group>
                )}

                <Header as="h3">{customer_name} {t("Data collections")}</Header>
                <Link to={`/customer/${customer_id}/customer-data-collection-details/create/`}>
                    <Button type="button" floated="right" color="green">
                        {t("+ Data collection")}
                    </Button>
                </Link>
                <Input
                    size="mini"
                    icon='search'
                    value={search}
                    name="search"
                    onChange={this.handleChange} />
                {error && (
                    <Message
                        error
                        header={t("There was an error")}
                        content={JSON.stringify(error)}
                    />
                )}
                {loading && (
                    <Segment>
                        <Dimmer active inverted>
                            <Loader inverted>Loading</Loader>
                        </Dimmer>
                        <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
                    </Segment>
                )}

                {data_collections && (
                    <React.Fragment>
                        <Menu attached="top" tabular>
                            <Menu.Item
                                name={t("Active")}
                                active={activeMenuItem === t("Active")}
                                onClick={this.handleMenuItemClick} />
                            <Menu.Item
                                name={t("Archive")}
                                active={activeMenuItem === t("Archive")}
                                onClick={this.handleMenuItemClick} />
                        </Menu>

                        <Table striped selectable celled>
                            {is_desktop && (
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell></Table.HeaderCell>
                                        <Table.HeaderCell>{t("Start time")}</Table.HeaderCell>
                                        <Table.HeaderCell>{t("Product")}</Table.HeaderCell>
                                        <Table.HeaderCell>{t("Source")}</Table.HeaderCell>
                                        <Table.HeaderCell>{t("Last value")}</Table.HeaderCell>
                                        <Table.HeaderCell>{t("Last invoiced value")}</Table.HeaderCell>
                                        <Table.HeaderCell>{t("Last estimate")}</Table.HeaderCell>
                                        {activeMenuItem === t("Archive") && (
                                            <Table.HeaderCell />
                                        )}
                                    </Table.Row>
                                </Table.Header>
                            )}
                            <Table.Body>
                                {filtered_items.map((data_collection) => {
                                    if ((activeMenuItem === t("Active") && data_collection.is_active) || (activeMenuItem === t("Archive") && !data_collection.is_active)) {
                                        return (
                                            <Table.Row key={data_collection.id} >
                                                <Table.Cell onClick={() => this.customerDataCollectionDetails(customer_id, data_collection.id)}>{data_collection.sensor
                                                    ? (<Popup content={t("Automatic")} trigger={<Icon name='cloud download' />} />)
                                                    : (<Popup content={t("Manual entry/trigger")} trigger={<Icon name='edit' />} />)} </Table.Cell>
                                                <Table.Cell onClick={() => this.customerDataCollectionDetails(customer_id, data_collection.id)}>
                                                    {!is_desktop && (<React.Fragment><b>{t("Start time")}</b><br/></React.Fragment>)}
                                                    {new Date(data_collection.date_created).toLocaleString('fi-FI')}
                                                </Table.Cell>
                                                <Table.Cell onClick={() => this.customerDataCollectionDetails(customer_id, data_collection.id)}>
                                                    {!is_desktop && (<React.Fragment><b>{t("Product")}</b><br/></React.Fragment>)}
                                                    {data_collection.name}
                                                </Table.Cell>
                                                <Table.Cell onClick={() => this.customerDataCollectionDetails(customer_id, data_collection.id)}>
                                                    {!is_desktop && (<React.Fragment><b>{t("Source")}</b><br/></React.Fragment>)}
                                                    {data_collection.source_id}
                                                </Table.Cell>
                                                <Table.Cell onClick={() => this.customerDataCollectionDetails(customer_id, data_collection.id)}>
                                                    {!is_desktop && (<React.Fragment><b>{t("Last value")}</b><br/></React.Fragment>)}
                                                    {data_collection.last_value}
                                                </Table.Cell>
                                                <Table.Cell onClick={() => this.customerDataCollectionDetails(customer_id, data_collection.id)}>
                                                    {!is_desktop && (<React.Fragment><b>{t("Last invoiced value")}</b><br/></React.Fragment>)}
                                                    {data_collection.last_invoiced_value}
                                                </Table.Cell>
                                                <Table.Cell onClick={() => this.customerDataCollectionDetails(customer_id, data_collection.id)}>
                                                    {!is_desktop && (<React.Fragment><b>{t("Last estimate")}</b><br/></React.Fragment>)}
                                                    {data_collection.last_estimate}
                                                </Table.Cell>
                                                {activeMenuItem === t("Archive") && (
                                                    <Table.Cell collapsing textAlign='center'>
                                                        <Icon link color="red" name='trash alternate outline' onClick={() => this.openDeleteConfirm(t("Are you sure your want to delete", { data_collection: data_collection.product.name }), data_collection.id)}/>
                                                        {/* <Button type="button" compact negative size='mini' icon='remove' onClick={() => this.openDeleteConfirm(t("Are you sure your want to delete", { data_collection: data_collection.product.name }), data_collection.id)} /> */}
                                                    </Table.Cell>
                                                )}
                                            </Table.Row>
                                        );
                                    }
                                })}
                            </Table.Body>
                        </Table>
                        <Confirm
                            content={delete_confirm_text}
                            open={delete_confirm_open}
                            onCancel={() => this.closeDeleteConfirm()}
                            onConfirm={() => this.handleDelete(delete_item)}
                        />
                    </React.Fragment>
                )}
            </Shell>
        );
    }
}
const mapStateToProps = state => {
    return {
        activeCompanyId: state.company.activeCompanyId,
        isAuthenticated: state.auth.token !== null
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setActivity: () => dispatch(setActivity())
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(withTranslation('customerdatacollections')(CustomerDataCollections))
);