// Copyright Caproc Oy
// System name: Aqua-Net 
// System module: Project
// Author: TS
//
// 8.3.2020 TS Initial version
// 11.7.2020 TS Added Company Selection banner

import React from "react";
import { Grid, Container, Segment, Header, Menu, Icon, Statistic } from "semantic-ui-react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import { CompanySelection } from "../../utils";

const Shell = props => (
    <>
    <Container><CompanySelection/></Container>
    < Segment vertical>
        <Container>
            <Grid container stackable verticalAlign="top" divided columns={2}>
                <Grid.Row>
                    <Grid.Column width={3}>
                        <Header as="h3">{props.t("Projects")}</Header>
                        <Menu vertical fluid icon="labeled" compact>
                            <Menu.Item>
                                <Header as="h4">{props.t("Projects")}</Header>
                            </Menu.Item>
                            <Menu.Item
                                disabled = {props.loading}
                                active={props.location.pathname === "/projects/"}
                                onClick={() => props.history.push("/projects/")}
                                name="projects"
                            >
                                <Icon name="suitcase"/> {props.t("Projects")}
                            </Menu.Item>
                            <Menu.Item
                                disabled = {props.loading}
                                active={props.location.pathname === "/project/project-groups/"}
                                onClick={() => props.history.push("/project/project-groups/")}
                                name="project-groups"
                            >
                                <Icon name="object group outline"/> {props.t("Project Groups")}
                            </Menu.Item>
                            <Menu.Item
                                disabled = {props.loading}
                                // active={props.location.pathname === "/project/tasks/C/"}
                                onClick={() => props.history.push("/project/tasks/C/")}
                                name="project-tasks"
                            >
                                <Icon name="tasks"/> {props.t("Project Tasks")}
                            </Menu.Item>
                            {props.location.pathname.startsWith("/project/tasks/") && (
                                <React.Fragment>
                                    <Menu.Item
                                        disabled = {props.loading}
                                        active={props.location.pathname === "/project/tasks/C/"}
                                        onClick={() => props.history.push("/project/tasks/C/")}
                                        name="project-tasks-created"
                                        position='left'
                                    >
                                        <Statistic size='mini' >
                                            <Statistic.Label>{props.t("Created")}</Statistic.Label>
                                            <Statistic.Value>{props.statistics && (`${props.statistics.C.amount}`)}</Statistic.Value>
                                        </Statistic>
                                        {/* {props.t("Created")} {props.statistics && (`(${props.statistics.C.amount})`)} */}
                                    </Menu.Item>
                                    <Menu.Item
                                        disabled = {props.loading}
                                        active={props.location.pathname === "/project/tasks/V/"}
                                        onClick={() => props.history.push("/project/tasks/V/")}
                                        name="project-tasks-verified"
                                    >
                                        <Statistic size='mini' >
                                            <Statistic.Label>{props.t("Verified")}</Statistic.Label>
                                            <Statistic.Value>{props.statistics && (`${props.statistics.V.amount}`)}</Statistic.Value>
                                        </Statistic>
                                        {/* {props.t("Verified")} {props.statistics && (`(${props.statistics.V.amount})`)} */}
                                    </Menu.Item>
                                    <Menu.Item
                                        disabled = {props.loading}
                                        active={props.location.pathname === "/project/tasks/A/"}
                                        onClick={() => props.history.push("/project/tasks/A/")}
                                        name="project-tasks-approved"
                                    >
                                        <Statistic size='mini' >
                                            <Statistic.Label>{props.t("Approved")}</Statistic.Label>
                                            <Statistic.Value>{props.statistics && (`${props.statistics.A.amount}`)}</Statistic.Value>
                                        </Statistic>
                                        {/* {props.t("Approved")} {props.statistics && (`(${props.statistics.A.amount})`)} */}
                                    </Menu.Item>
                                    <Menu.Item
                                        disabled = {props.loading}
                                        active={props.location.pathname === "/project/tasks/I/"}
                                        onClick={() => props.history.push("/project/tasks/I/")}
                                        name="project-tasks-invoiced"
                                    >
                                        <Statistic size='mini' >
                                            <Statistic.Label>{props.t("Invoiced")}</Statistic.Label>
                                            <Statistic.Value>{props.statistics && (`${props.statistics.I.amount}`)}</Statistic.Value>
                                        </Statistic>
                                        {/* {props.t("Invoiced")} {props.statistics && (`(${props.statistics.I.amount})`)} */}
                                    </Menu.Item>
                                    <Menu.Item
                                        disabled = {props.loading}
                                        active={props.location.pathname === "/project/tasks/all/"}
                                        onClick={() => props.history.push("/project/tasks/all/")}
                                        name="project-tasks-invoiced"
                                    >
                                        <Statistic size='mini' >
                                            <Statistic.Label>{props.t("All")}</Statistic.Label>
                                        </Statistic>
                                        {/* {props.t("All")}  */}
                                    </Menu.Item>
                                </React.Fragment>
                            )} 
                        </Menu>
                    </Grid.Column>
                    <Grid.Column width={13}>{props.children}</Grid.Column>
                </Grid.Row>
            </Grid>
        </Container>
    </Segment >
    </>
);

const mapDispatchToProps = dispatch => {
    return {
        // logout: () => dispatch(logout())
    };
};

export default withRouter(
    connect(
        null,
        mapDispatchToProps
    )(withTranslation('projectshell')(Shell))
);