// Copyright Caproc Oy
// System name: Aqua-Net 
// System module: Company
// Author: TS
//
// 8.5.2020 TS Initial version

import React from "react";
import { Form, Input, Message, Header, Button, Checkbox, Modal, Container } from "semantic-ui-react";
import Shell from "./Shell";
import { authAxios } from "../../utils";
import { setActivity } from "../../store/actions/auth";
import { withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import { netInvoicingDetailsURL, updateNetInvoicingContractsURL, updateNetInvoicingStatusURL } from "../../constants";


class CompanyNetInvoicing extends React.Component {
    state = {
        net_invoicing_active: false, 
        net_invoicing_contract_send: false, 
        net_invoicing_contract_duplicate_check: false, 
        net_invoicing_contract_priority: false, 
        net_invoicing_contract_accounting_copy: false,
        net_invoicing_contract_accounting_email: null,
        net_invoicing_contract_archive: false,
        net_invoicing_contract_receive: false,
        net_invoicing_contract_scanning: false,
        response: null,
        error: null,
        success_mgs: null,
        loading: false
    };

    componentDidMount() {
        this.props.setActivity();
        this.handleNetInvoicingDetails();
    }

    handleNetInvoicingDetails = () => {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;
        authAxios
            .get(netInvoicingDetailsURL(activeCompanyId))
            .then(res => {
                this.setState({
                    net_invoicing_active: res.data.net_invoicing_active,
                    net_invoicing_contract_send: res.data.net_invoicing_contract_send,
                    net_invoicing_contract_duplicate_check: res.data.net_invoicing_contract_duplicate_check,
                    net_invoicing_contract_priority: res.data.net_invoicing_contract_priority,
                    net_invoicing_contract_accounting_copy: res.data.net_invoicing_contract_accounting_copy,
                    net_invoicing_contract_accounting_email: res.data.net_invoicing_contract_accounting_email,
                    net_invoicing_contract_archive: res.data.net_invoicing_contract_archive,
                    net_invoicing_contract_receive: res.data.net_invoicing_contract_receive,
                    net_invoicing_contract_scanning: res.data.net_invoicing_contract_scanning,            
                    error: null,
                    success_mgs: null,
                    loading: false
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    success_mgs: null,
                    error: err
                });
            });
    };

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value,
            success_mgs: null,
            error: null
        });
    };

    handleCheckboxChange = (e, { name, checked }) => {
        this.setState({
            [name]: checked
        });
    };

    handleSubmitContracts = e => {
        e.preventDefault();
        this.setState({
            loading: true,
            error: null
        });
        const {
            net_invoicing_contract_send,
            net_invoicing_contract_duplicate_check,
            net_invoicing_contract_priority, 
            net_invoicing_contract_accounting_copy,
            net_invoicing_contract_accounting_email,
            net_invoicing_contract_archive,
            net_invoicing_contract_receive,
            net_invoicing_contract_scanning
        } = this.state;
        const { activeCompanyId, t } = this.props;
        if (net_invoicing_contract_accounting_copy && net_invoicing_contract_accounting_email === "") {
            this.setState({
                loading: false,
                success_mgs: null,
                error: t("Please add also (Accounting email) if (Accounting copy) is selected")
            });
        } else {
            authAxios
                .post(updateNetInvoicingContractsURL(activeCompanyId), {
                    net_invoicing_contract_send,
                    net_invoicing_contract_duplicate_check,
                    net_invoicing_contract_priority, 
                    net_invoicing_contract_accounting_copy,
                    net_invoicing_contract_accounting_email,
                    net_invoicing_contract_archive,
                    // net_invoicing_contract_receive,
                    // net_invoicing_contract_scanning
                })
                .then(res => {
                    this.setState({
                        response: res.data,
                        loading: false,
                        success_mgs: null,
                        error: null,
                        modal_open: true
                    });
                })
                .catch(err => {
                    this.setState({
                        loading: false,
                        success_mgs: null,
                        error: err
                    });
                });
        }
    };

    handleSubmitNetInvoicing = e => {
        e.preventDefault();
        this.setState({
            loading: true,
            error: null
        });
        const {
            net_invoicing_active
        } = this.state;
        const { activeCompanyId, t } = this.props;
        authAxios
            .post(updateNetInvoicingStatusURL(activeCompanyId), {
                net_invoicing_active: !net_invoicing_active
            })
            .then(res => {
                this.setState({
                    response: res.data,
                    loading: false,
                    success_mgs: null,
                    error: null,
                    modal_open: true
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    success_mgs: null,
                    error: err
                });
            });
        
    };

    closeModal = () => {
        this.setState({
            modal_open: false,
            response: null
        });
        this.handleNetInvoicingDetails();
    };

    render() {
        const {
            net_invoicing_active,
            net_invoicing_contract_send,
            net_invoicing_contract_duplicate_check,
            net_invoicing_contract_priority, 
            net_invoicing_contract_accounting_copy,
            net_invoicing_contract_accounting_email,
            net_invoicing_contract_archive,
            net_invoicing_contract_receive,
            net_invoicing_contract_scanning,
            response,
            modal_open,
            error,
            success_mgs,
            loading } = this.state;
        const { isAuthenticated, t } = this.props;
        if (!isAuthenticated) {
            return <Redirect to="/login" />;
        }
        // console.log(this.state);
        return (
            <Shell>
                <Header as="h3">{t("Net invoicing details")}</Header>
                <strong>{t("Net invoicing status")}:</strong>&nbsp; {net_invoicing_active ? (<font color="green"><strong >{t("ACTIVE")}</strong></font>) : (<font color="red"><strong >{t("INACTIVE")}</strong></font>)} <br/><br/>
                <Form error={error !== null}>
                    <Form.Field>
                        <Button primary type="button" onClick={(e) => this.handleSubmitNetInvoicing(e)} loading={loading} disabled={loading}>{net_invoicing_active ? (t("Deactivate net invoicing")) : (t("Activate net invoicing"))}</Button>  
                    </Form.Field>
                </Form>
                <br/>
                <i>({t("Net invoicing service is provided in co-operation with intermediator Apix Messaging Oy. Caproc Oy will invoice the user of service according to the price list in the service contract.")})</i>
                {net_invoicing_active && (
                    <React.Fragment>
                        <hr/>
                        <Header as="h4">{t("Net invoicing services")}</Header>
                        <Form error={error !== null}>
                            <Form.Field>
                                <Checkbox
                                    disabled = {loading}
                                    label={<label>{t("Net invoice sending")}</label>}
                                    name="net_invoicing_contract_send"
                                    onChange={this.handleCheckboxChange}
                                    checked={net_invoicing_contract_send}
                                />
                            </Form.Field>
                            <Form.Field>
                                <Checkbox
                                    disabled = {loading}
                                    label={<label>{t("Prevent duplicate invoice sending")}</label>}
                                    name="net_invoicing_contract_duplicate_check"
                                    onChange={this.handleCheckboxChange}
                                    checked={net_invoicing_contract_duplicate_check}
                                />
                            </Form.Field>
                            <Form.Field>
                                <Checkbox
                                    disabled = {loading}
                                    label={<label>{t("Send paper invoices in 1st class instead of 2nd class")}</label>}
                                    name="net_invoicing_contract_priority"
                                    onChange={this.handleCheckboxChange}
                                    checked={net_invoicing_contract_priority}
                                />
                            </Form.Field>
                            <Form.Field>
                                <Checkbox
                                    disabled = {loading}
                                    label={<label>{t("Send accounting copy to accounting email")}</label>}
                                    name="net_invoicing_contract_accounting_copy"
                                    onChange={this.handleCheckboxChange}
                                    checked={net_invoicing_contract_accounting_copy}
                                />
                            </Form.Field>
                            <Form.Field width={6}>
                                <label>{t("Accounting copy email")}</label>
                                <Input
                                    disabled = {loading}
                                    value={net_invoicing_contract_accounting_email}
                                    name="net_invoicing_contract_accounting_email"
                                    onChange={this.handleChange} />
                            </Form.Field>
                            <Form.Field>
                                <Checkbox
                                    disabled = {loading}
                                    label={<label>{t("Archive all net invoices")}</label>}
                                    name="net_invoicing_contract_archive"
                                    onChange={this.handleCheckboxChange}
                                    checked={net_invoicing_contract_archive}
                                />
                            </Form.Field>

                            {/* <Form.Field>
                                <Checkbox
                                    disabled = {loading}
                                    label={<label>{t("Net invoice receiving")}</label>}
                                    name="net_invoicing_contract_receive"
                                    onChange={this.handleCheckboxChange}
                                    checked={net_invoicing_contract_receive}
                                />
                            </Form.Field>
                            <Form.Field>
                                <Checkbox
                                    disabled = {loading}
                                    label={<label>{t("Scanning of received paper invoices")}</label>}
                                    name="net_invoicing_contract_scanning"
                                    onChange={this.handleCheckboxChange}
                                    checked={net_invoicing_contract_scanning}
                                />
                            </Form.Field> */}
                            {error && <Message error header={t("Error")} content={error} />}
                            {success_mgs && <Message positive header={success_mgs} />}
                            <Button type="button" primary onClick={(e) => this.handleSubmitContracts(e)} loading={loading} disabled={loading}>
                                {t("Save")}
                            </Button>
                        </Form>
                    </React.Fragment>
                )}
                <Modal size='mini' open={(modal_open)} dimmer="inverted" scrolling="true">
                    {/* <Modal.Header>
                        <Header as="h4">{t("Sending complete")}</Header>
                    </Modal.Header> */}
                    <Modal.Content>
                        <Container>
                            {response && (
                                <React.Fragment>
                                    {response.type === "status" && (
                                        <React.Fragment>
                                            {response.status === "error" ? (<Header color="red" as="h4">{response.text.fi}</Header>) : (t(response.text))}
                                        </React.Fragment>
                                    )}
                                    {response.type === "contract" && (
                                        <React.Fragment>
                                            {response.status === "error" ? (<Header color="red" as="h4">{t(response.text.fi)}</Header>) : 
                                            (
                                                <React.Fragment>
                                                    {t("Services updated")} <br/>
                                                    <br/>
                                                    {response.text !== "Services updated" && (response.text.fi)}
                                                </React.Fragment>
                                            )}
                                        </React.Fragment>
                                    )}
                                </React.Fragment>
                            )}
                        </Container>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button type="button" primary onClick={() => this.closeModal()} >{t("OK")}</Button>
                    </Modal.Actions>
                </Modal>
            </Shell >
        );
    }
}
const mapStateToProps = state => {
    return {
        activeCompanyId: state.company.activeCompanyId,
        isAuthenticated: state.auth.token !== null
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setActivity: () => dispatch(setActivity())
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(withTranslation('companynetinvoicing')(CompanyNetInvoicing))
);