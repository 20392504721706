// Copyright Caproc Oy
// System name: Aqua-Net 
// System module: Contract
// Author: TS
//
// 28.2.2020 TS Initial version
// 29.3.2020 TS Create and Update procedures
// 30.3.2020 TS Added current day check to handleRunChanges to run only price change scheduled for today
// 6.6.2020 TS Changed remove icon to trash bin
// 21.6.2020 TS Added mobile version

import React from "react";
import { Form, Input, Message, Header, Button, Select, Grid, Table, Modal, Container, Checkbox, Loader, GridRow, GridColumn, Popup, Icon, Responsive } from "semantic-ui-react";
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import fiLocale from 'react-semantic-ui-datepickers/dist/locales/fi-FI';
import { authAxios, getISOFormattedDate, getWidth } from "../../utils";
import { setActivity } from "../../store/actions/auth";
import { withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import {
    contractPriceChangeTypeOptionsURL,
    fetchContractGroupsURL,
    fetchProductGroupsURL,
    contractPriceChangeDetailsURL,
    createContractPriceChangeURL,
    createPriceChangeProductURL,
    createPriceChangeContractURL,
    updateContractPriceChangeURL,
    deletePriceChangeProductURL,
    deletePriceChangeContractURL,
    contractPriceChangeRunURL
} from "../../constants";
import Shell from "./Shell";


class ContractPriceChangeDetails extends React.Component {
    state = {
        contract_pc_id: null,
        contract_pc_details: {
            id: "",
            company: "",
            name: "",
            description: "",
            change_type: "%",
            change_amount: "",
            change_date: "",
            is_completed: false,
            author: ""
        },
        contract_pc_products_list: [],
        selected_products_list: [],
        contract_pc_product_select_modal_open: false,
        contract_pc_contracts_list: [],
        selected_contracts_list: [],
        contract_pc_contract_select_modal_open: false,
        product_groups: null,
        contract_groups: null,
        fields_disabled: true,
        pc_type_options: [],
        select_all_contracts: false,
        select_all_products: false,
        error: null,
        success_mgs: null,
        loading: false,
        create: false,
        run_changes: false,
        leave_page: false
    };

    componentDidMount() {
        this.props.setActivity();
        this.handleFetchProductGroupList();
        this.handleFetchContractGroupList();
        this.handleFetchPriceChangeTypeOptions();
        if (this.props.match.params.contract_price_change_id === "create") {
            // Set initial date to start date picker
            this.handleDateChange(new Date());
            this.setState({
                create: true
            })
        } else {
            this.handleDetails();
        }
    }

    handleFetchPriceChangeTypeOptions() {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;

        authAxios
            .get(contractPriceChangeTypeOptionsURL(activeCompanyId))
            .then(res => {
                this.setState({
                    pc_type_options: this.handleFormatTypeOptions(res.data),
                    error: null,
                    success_mgs: null,
                    loading: false,
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    success_mgs: null,
                    error: err
                });
            });

    };

    handleFormatTypeOptions = options => {
        const keys = Object.keys(options);
        return keys.map(k => {
            return {
                key: options[k].value,
                text: options[k].name,
                value: options[k].value
            };
        });
    };

    handleFetchContractGroupList() {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;

        authAxios
            .get(fetchContractGroupsURL(activeCompanyId))
            .then(res => {
                this.setState({
                    contract_groups: res.data,
                    error: null,
                    success_mgs: null,
                    loading: false,
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    success_mgs: null,
                    error: err
                });
            });
    };


    handleFetchProductGroupList = () => {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;
        authAxios
            .get(fetchProductGroupsURL(activeCompanyId))
            .then(res => {
                this.setState({
                    product_groups: res.data,
                    error: null,
                    loading: false
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    error: err
                });
            });
    };

    handleDetails = () => {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;
        const { contract_price_change_id } = this.props.match.params;
        authAxios
            .get(contractPriceChangeDetailsURL(activeCompanyId, contract_price_change_id))
            .then(res => {
                this.setState({
                    contract_pc_details: res.data,
                    contract_pc_products_list: res.data.contractpricechangeproducts,
                    contract_pc_contracts_list: res.data.contractpricechangecontracts,
                    error: null,
                    success_mgs: null,
                    loading: false
                });
            })
            .catch(err => {
                this.setState({
                    loading: false,
                    success_mgs: null,
                    error: err
                });
            });

    };

    handleChange = e => {
        const { t } = this.props;
        if (e.target.name === "change_amount" && isNaN(e.target.value)) {
            this.setState({
                error: t("Change amount value is not number")
            });
        } else {
            this.setState({
                error: null
            });
        }
        const { contract_pc_details } = this.state;
        const updatedDetails = {
            ...contract_pc_details,
            [e.target.name]: e.target.value,
        };
        this.setState({
            contract_pc_details: updatedDetails,
            success_mgs: null,
        });
    };

    handleSelectChange = (e, { name, value }) => {
        const { contract_pc_details } = this.state;
        const updatedDetails = {
            ...contract_pc_details,
            [name]: value
        };
        this.setState({
            contract_pc_details: updatedDetails,
            error: null
        });
    };

    handleDateChange = value => {
        const { contract_pc_details } = this.state;
        const updatedDetails = {
            ...contract_pc_details,
            change_date: getISOFormattedDate(value, "Date")
        };
        this.setState({
            contract_pc_details: updatedDetails,
            error: null
        });
    };

    handleCheckboxChange = (e, { name, value }) => {
        const { contract_pc_details } = this.state;
        const updatedDetails = {
            ...contract_pc_details,
            [name]: !value
        };
        this.setState({
            contract_pc_details: updatedDetails
        });
    };

    handleSubmit = e => {
        e.preventDefault();
        this.setState({
            loading: true,
            error: null
        });
        const { create } = this.state;
        let { contract_pc_details } = this.state;
        const { activeCompanyId, t } = this.props;
        if (contract_pc_details.name === "") {
            this.setState({
                error: t("Please fill in Name"),
                success_mgs: null,
                loading: false
            });
        } else if (contract_pc_details.name.length > 100) {
            this.setState({
                error: t("Max amount of characters is 100 in Name field"),
                success_mgs: null,
                loading: false
            });
        } else if (contract_pc_details.description.length > 100) {
            this.setState({
                error: t("Max amount of characters is 100 in Comment field"),
                success_mgs: null,
                loading: false
            });
        } else if (contract_pc_details.change_date === "") {
            this.setState({
                error: t("Please select Change date"),
                success_mgs: null,
                loading: false
            });
        } else if (contract_pc_details.change_amount === "") {
            this.setState({
                error: t("Please fill in Change amount"),
                success_mgs: null,
                loading: false
            });
        } else {
            if (create) {
                authAxios
                    .post(createContractPriceChangeURL(activeCompanyId), {
                        ...contract_pc_details
                    })
                    .then(res => {
                        contract_pc_details.id = res.data.contract_price_change_id;
                        // Create price change products
                        let { contract_pc_products_list } = this.state;
                        let i = 0;
                        for (i in contract_pc_products_list) {
                            // Add invoice id from just created invoice and add to all items.
                            contract_pc_products_list[i]['contract_price_change'] = res.data.contract_price_change_id;
                            contract_pc_products_list[i]['author'] = localStorage.getItem("UserID");
                            authAxios
                                .post(createPriceChangeProductURL(activeCompanyId, res.data.contract_price_change_id), {
                                    ...contract_pc_products_list[i],
                                    product: contract_pc_products_list[i].product.id
                                })
                                .then(res => {
                                })
                                .catch(err => {
                                    this.setState({
                                        loading: false,
                                        success_mgs: null,
                                        error: err
                                    });
                                    return;
                                });
                        }
                        // Create price change contracts
                        let { contract_pc_contracts_list } = this.state;
                        i = 0;
                        for (i in contract_pc_contracts_list) {
                            // Add invoice id from just created invoice and add to all items.
                            contract_pc_contracts_list[i]['contract_price_change'] = res.data.contract_price_change_id;
                            contract_pc_contracts_list[i]['author'] = localStorage.getItem("UserID");
                            authAxios
                                .post(createPriceChangeContractURL(activeCompanyId, res.data.contract_price_change_id), {
                                    ...contract_pc_contracts_list[i],
                                    contract: contract_pc_contracts_list[i].contract.id
                                })
                                .then(res => {
                                })
                                .catch(err => {
                                    this.setState({
                                        loading: false,
                                        success_mgs: null,
                                        error: err
                                    });
                                    return;
                                });
                        }
                        this.setState({
                            run_changes: true
                        });
                    })
                    .catch(err => {
                        this.setState({
                            loading: false,
                            success_mgs: null,
                            error: err
                        });
                    });

            } else {
                authAxios
                    .put(updateContractPriceChangeURL(activeCompanyId, contract_pc_details.id), {
                        ...contract_pc_details
                    })
                    .then(res => {
                        // Add new products
                        let { contract_pc_products_list } = this.state;
                        let i = 0;
                        for (i in contract_pc_products_list) {
                            // id is empty if this is new item added to the list
                            if (!contract_pc_products_list[i].id) {
                                contract_pc_products_list[i]['contract_price_change'] = contract_pc_details.id;
                                contract_pc_products_list[i]['author'] = localStorage.getItem("UserID");
                                authAxios
                                    .post(createPriceChangeProductURL(activeCompanyId, contract_pc_details.id), {
                                        ...contract_pc_products_list[i],
                                        product: contract_pc_products_list[i].product.id
                                    })
                                    .then(res => {
                                    })
                                    .catch(err => {
                                        this.setState({
                                            loading: false,
                                            success_mgs: null,
                                            error: err
                                        });
                                        return;
                                    });
                            } 
                        }
                        // Add new contracts
                        let { contract_pc_contracts_list } = this.state;
                        i = 0;
                        for (i in contract_pc_contracts_list) {
                            // id is empty if this is new item added to the list
                            if (!contract_pc_contracts_list[i].id) {
                                contract_pc_contracts_list[i]['contract_price_change'] = contract_pc_details.id;
                                contract_pc_contracts_list[i]['author'] = localStorage.getItem("UserID");
                                authAxios
                                    .post(createPriceChangeContractURL(activeCompanyId, contract_pc_details.id), {
                                        ...contract_pc_contracts_list[i],
                                        contract: contract_pc_contracts_list[i].contract.id
                                    })
                                    .then(res => {
                                    })
                                    .catch(err => {
                                        this.setState({
                                            loading: false,
                                            success_mgs: null,
                                            error: err
                                        });
                                        return;
                                    });
                            } 
                        }
                        this.setState({
                            run_changes: true
                        });
                    })
                    .catch(err => {
                        this.setState({
                            loading: false,
                            success_mgs: null,
                            error: err
                        });
                    });
            }
        }
    };

    handleRunChanges = () => {
        this.setState({
            loading: true
        });
        const { activeCompanyId } = this.props;
        const { contract_pc_details } = this.state;
        // Run changes only once per price change task. is_completed status is modified in back end. Also run changes only for one having change date today. Not scheduled ones.
        if (!contract_pc_details.is_completed && contract_pc_details.change_date === getISOFormattedDate(new Date(), "Date")) {
            authAxios
                .get(contractPriceChangeRunURL(activeCompanyId, contract_pc_details.id))
                .then(res => {
                    this.setState({
                        loading: false,
                        leave_page: true
                    });
                })
                .catch(err => {
                    this.setState({
                        loading: false,
                        error: err
                    });
                });
        } else {
            this.setState({
                loading: false,
                leave_page: true
            });
        }
    };

    openProductsAddModal = () => {
        this.setState({
            contract_pc_product_select_modal_open: true
        })
    };

    closeProductsAddModal = () => {
        this.setState({
            contract_pc_product_select_modal_open: false,
            selected_products_list: [],
        })
    };

    openContractsAddModal = () => {
        this.setState({
            contract_pc_contract_select_modal_open: true
        })
    };

    closeContractsAddModal = () => {
        this.setState({
            contract_pc_contract_select_modal_open: false,
            selected_contracts_list: [],
        })
    };

    handleProductsListChange = (item, updated_item, action) => {
        let { contract_pc_products_list } = this.state;
        const { activeCompanyId } = this.props;
        if (action === "add") {
            if (!contract_pc_products_list.includes(item)) {
                let already_exists = false;
                let i = 0;
                for (i in contract_pc_products_list) {
                    if (contract_pc_products_list[i].product.id === item.product.id) {
                        already_exists = true;
                    }
                }
                if (!already_exists) {
                    contract_pc_products_list.push(item);
                }
            }
        } else if (action === "remove") {
            if (contract_pc_products_list.includes(item)) {
                contract_pc_products_list.splice(contract_pc_products_list.indexOf(item), 1);
                // If this product is already saved in the db (has id defined), Delete it.
                if (item.id) {
                    authAxios
                        .delete(deletePriceChangeProductURL(activeCompanyId, item.contract_price_change, item.id), {
                        })
                        .then(res => {
                        })
                        .catch(err => {
                            this.setState({
                                loading: false,
                                success_mgs: null,
                                error: err
                            });
                            return;
                        });
                }
            }
        } else if (action === "update") {
            if (contract_pc_products_list.includes(item)) {
                contract_pc_products_list.splice(contract_pc_products_list.indexOf(item), 1, updated_item);
            }
        }
        this.setState({ contract_pc_products_list });
    };

    handleContractsListChange = (item, updated_item, action) => {
        let { contract_pc_contracts_list } = this.state;
        const { activeCompanyId } = this.props;
        if (action === "add") {
            if (!contract_pc_contracts_list.includes(item)) {
                let already_exists = false;
                let i = 0;
                for (i in contract_pc_contracts_list) {
                    if (contract_pc_contracts_list[i].contract.id === item.contract.id) {
                        already_exists = true;
                    }
                }
                if (!already_exists) {
                    contract_pc_contracts_list.push(item);
                }
            }
        } else if (action === "remove") {
            if (contract_pc_contracts_list.includes(item)) {
                contract_pc_contracts_list.splice(contract_pc_contracts_list.indexOf(item), 1);
                // If this product is already saved in the db (has id defined), Delete it.
                if (item.id) {
                    authAxios
                        .delete(deletePriceChangeContractURL(activeCompanyId, item.contract_price_change, item.id), {
                        })
                        .then(res => {
                        })
                        .catch(err => {
                            this.setState({
                                loading: false,
                                success_mgs: null,
                                error: err
                            });
                            return;
                        });
                }
            }
        } else if (action === "update") {
            if (contract_pc_contracts_list.includes(item)) {
                contract_pc_contracts_list.splice(contract_pc_contracts_list.indexOf(item), 1, updated_item);
            }
        }
        this.setState({ contract_pc_contracts_list });
    };


    handleAddProductsCheckboxChange = (e, { value, checked }) => {
        let { selected_products_list } = this.state;
        if (checked) {
            if (!selected_products_list.includes(value)) {
                selected_products_list.push(value);
            }
        } else {
            if (selected_products_list.includes(value)) {
                selected_products_list.splice(selected_products_list.indexOf(value), 1);
            }
        }
        this.setState({ selected_products_list });
    };

    handleAddContractsCheckboxChange = (e, { value, checked }) => {
        let { selected_contracts_list } = this.state;
        if (checked) {
            if (!selected_contracts_list.includes(value)) {
                selected_contracts_list.push(value);
            }
        } else {
            if (selected_contracts_list.includes(value)) {
                selected_contracts_list.splice(selected_contracts_list.indexOf(value), 1);
            }
        }
        this.setState({ selected_contracts_list });
    };

    handleSelectAllProducts = () => {
        if (!this.state.select_all_products) {
            let new_list = [];
            let i = 0;
            let x = 0;
            for (x in this.state.product_groups) {
                i = 0;
                for (i in this.state.product_groups[x].products) {
                    new_list.push(this.state.product_groups[x].products[i]);
                }
            }
            this.setState({
                select_all_products: true,
                selected_products_list: new_list
            })
        }
        else this.setState({ select_all_products: false, selected_products_list: [] });
    };

    handleSelectAllContracts = () => {
        if (!this.state.select_all_contracts) {
            let new_list = [];
            let i = 0;
            let x = 0;
            for (x in this.state.contract_groups) {
                i = 0;
                for (i in this.state.contract_groups[x].contracts) {
                    new_list.push(this.state.contract_groups[x].contracts[i]);
                }
            }
            this.setState({
                select_all_contracts: true,
                selected_contracts_list: new_list
            })
        }
        else this.setState({ select_all_contracts: false, selected_contracts_list: [] });
    };


    handleAddProductsToPriceChange = () => {
        const { selected_products_list } = this.state;
        const { activeCompanyId } = this.props;
        let i = 0;
        for (i in selected_products_list) {
            let new_item = {};
            new_item['company'] = activeCompanyId;
            // Pick contract price change id in handleSubmit after the it has been created.

            new_item['product'] = selected_products_list[i];
            this.handleProductsListChange(new_item, "", "add");
        }
        this.closeProductsAddModal();
    };

    handleAddContractsToPriceChange = () => {
        const { selected_contracts_list } = this.state;
        const { activeCompanyId } = this.props;
        let i = 0;
        for (i in selected_contracts_list) {
            let new_item = {};
            new_item['company'] = activeCompanyId;
            // Pick contract price change id in handleSubmit after the it has been created.

            new_item['contract'] = selected_contracts_list[i];
            this.handleContractsListChange(new_item, "", "add");
        }
        this.closeContractsAddModal();
    };

    render() {
        const {
            contract_pc_details,
            contract_pc_products_list,
            contract_pc_contracts_list,
            selected_contracts_list,
            selected_products_list,
            product_groups,
            contract_groups,
            contract_pc_product_select_modal_open,
            contract_pc_contract_select_modal_open,
            pc_type_options,
            error,
            success_mgs,
            loading,
            create
        } = this.state;
        const { isAuthenticated, t } = this.props;
        if (!isAuthenticated) {
            return <Redirect to="/login" />;
        }
        if (this.state.run_changes && error === null) {
            this.handleRunChanges();
            this.setState({
                run_changes: false
            });
        }
        if (this.state.leave_page && error === null) {
            return <Redirect to="/contract-price-changes/" />;
        }
        const is_desktop =  getWidth() > Responsive.onlyMobile.maxWidth ? true : false;
        // console.log(this.state);
        return (
            <Shell>
                {(create || contract_pc_details.id !== "") && (
                    <div>
                        {loading && (
                            <Loader inverted>Loading</Loader>
                        )}
                        <Header as="h3">{create ? (t("New price change")) : (contract_pc_details.name)}</Header>
                        <Form error={error !== null}>
                            <Form.Field required>
                                <label>{t("Name")}</label>
                                <Input
                                    value={contract_pc_details.name}
                                    name="name"
                                    onChange={this.handleChange} />
                            </Form.Field>
                            <Form.Field >
                                <label>{t("Description")}</label>
                                <Input
                                    value={contract_pc_details.description}
                                    name="description"
                                    onChange={this.handleChange} />
                            </Form.Field>
                            {is_desktop ? (
                                <Grid>
                                    <GridRow>
                                    <GridColumn width="2">
                                        <Form.Field required>
                                            <Popup content={t("Add change amount in percent or in currency. Pick corresponding type from the Type menu.")} trigger={<label>{t("Amount")}</label>} />
                                            
                                            <Input
                                                value={contract_pc_details.change_amount}
                                                name="change_amount"
                                                onChange={this.handleChange} />
                                        </Form.Field>
                                        </GridColumn>
                                        <GridColumn width="2">
                                        {pc_type_options && (
                                            <Form.Field>
                                                <label>{t("Type")}</label>
                                                <Select
                                                    fluid
                                                    search
                                                    name='change_type'
                                                    options={pc_type_options}
                                                    placeholder={contract_pc_details.change_type}
                                                    onChange={this.handleSelectChange}
                                                    value={contract_pc_details.change_type}
                                                />
                                            </Form.Field>
                                        )}
                                        </GridColumn>
                                        <GridColumn width="3">
                                            <Form.Field>
                                                <label>{t("Change date")}</label>
                                                <SemanticDatepicker
                                                    firstDayOfWeek={1}
                                                    locale={fiLocale}
                                                    name="change_date"
                                                    pointing="top left"
                                                    size="small"
                                                    placeholder={contract_pc_details.change_date !== null ? (new Date(contract_pc_details.change_date).toLocaleString('fi-FI', { dateStyle: 'short' })) : ("")}
                                                    onDateChange={selected => this.handleDateChange(selected)}
                                                    format='DD.MM.YYYY'
                                                />
                                            </Form.Field>
                                        </GridColumn>
                                    </GridRow>
                                </Grid>
                            ) : ( 
                                <React.Fragment>
                                    <Form.Field required>
                                        <label>{t("Amount")}</label>
                                        <Input
                                            value={contract_pc_details.change_amount}
                                            name="change_amount"
                                            onChange={this.handleChange} />
                                    </Form.Field>
                                    {pc_type_options && (
                                        <Form.Field>
                                            <label>{t("Type")}</label>
                                            <Select
                                                fluid
                                                search
                                                name='change_type'
                                                options={pc_type_options}
                                                placeholder={contract_pc_details.change_type}
                                                onChange={this.handleSelectChange}
                                                value={contract_pc_details.change_type}
                                            />
                                        </Form.Field>
                                    )}
                                    <Form.Field>
                                        <label>{t("Change date")}</label>
                                        <SemanticDatepicker
                                            firstDayOfWeek={1}
                                            locale={fiLocale}
                                            name="change_date"
                                            pointing="top left"
                                            placeholder={contract_pc_details.change_date !== null ? (new Date(contract_pc_details.change_date).toLocaleString('fi-FI', { dateStyle: 'short' })) : ("")}
                                            onDateChange={selected => this.handleDateChange(selected)}
                                            format='DD.MM.YYYY'
                                        />
                                    </Form.Field>
                                </React.Fragment>
                            )}
                            <strong>{t("Please note!")}</strong> {t("If Change Date is current date (today), all changes will be executed straight after pressing Save. Adjust Change Date if you want to schedule changes later.")}
                            <br />

                            <Header as="h3">{t("Products")}</Header>
                            <Button type="button" disabled={loading } floated="right" color="green" content={t("+ Product")} onClick={() => this.openProductsAddModal()} />

                            <Table celled striped selectable sortable>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>{t("Name")}</Table.HeaderCell>
                                        <Table.HeaderCell>{t("Code")}</Table.HeaderCell>
                                        <Table.HeaderCell>{t("Description")}</Table.HeaderCell>
                                        <Table.HeaderCell collapsing/>
                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                    {contract_pc_products_list.map((item, i) => {
                                        return (
                                            <Table.Row key={i + 1}>
                                                <Table.Cell collapsing >{item.product.name}</Table.Cell>
                                                <Table.Cell collapsing >{item.product.code}</Table.Cell>
                                                <Table.Cell collapsing >{item.product.description}</Table.Cell>
                                                <Table.Cell collapsing textAlign='center'>
                                                    <Icon link color="red" name='trash alternate outline' onClick={() => this.handleProductsListChange(item, "", "remove")}/>
                                                    {/* <Button type="button" negative size='mini' icon='remove' onClick={() => this.handleProductsListChange(item, "", "remove")} /> */}
                                                </Table.Cell>
                                            </Table.Row>
                                        );
                                    })}
                                </Table.Body>
                            </Table>
                            <br />
                            <Header as="h3">{t("Contracts")}</Header>
                            <Button type="button" disabled={loading } floated="right" color="green" content={t("+ Contract")} onClick={() => this.openContractsAddModal()} />

                            <Table celled striped selectable sortable>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>{t("Customer")}</Table.HeaderCell>
                                        <Table.HeaderCell>{t("Contract")}</Table.HeaderCell>
                                        <Table.HeaderCell>{t("Number")}</Table.HeaderCell>
                                        <Table.HeaderCell>{t("Description")}</Table.HeaderCell> 
                                        <Table.HeaderCell collapsing/>
                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                    {contract_pc_contracts_list.map((item, i) => {
                                        return (
                                            <Table.Row key={i + 1}>
                                                <Table.Cell collapsing >{item.contract.customer.name}</Table.Cell>
                                                <Table.Cell collapsing >{item.contract.name}</Table.Cell>
                                                <Table.Cell collapsing >{item.contract.number}</Table.Cell>
                                                <Table.Cell collapsing >{item.contract.comment}</Table.Cell>
                                                <Table.Cell collapsing textAlign='center'>
                                                    <Icon link color="red" name='trash alternate outline' onClick={() => this.handleContractsListChange(item, "", "remove")}/>
                                                    {/* <Button type="button" negative size='mini' icon='remove' onClick={() => this.handleContractsListChange(item, "", "remove")} /> */}
                                                </Table.Cell>
                                            </Table.Row>
                                        );
                                    })}
                                </Table.Body>
                            </Table>

                            {error && <Message error header={t("Error")} content={error} />}
                            {success_mgs && <Message positive header={success_mgs} />}
                            <Button primary type="button" onClick={(e) => this.handleSubmit(e)} loading={loading} disabled={loading}>
                                {create ? (t("Create")) : (t("Save"))}
                            </Button>
                        </Form>
                    </div>
                )}

                {/* Products select modal. Lists available products and adds selected ones to the selected products list */}
                <Modal size='small' open={contract_pc_product_select_modal_open} dimmer="inverted" scrolling="true">
                    <Modal.Header>
                        <Header as="h3">{t("Pick products and services")}</Header>
                        <Button type="button" positive icon='add circle' content={t("Add products to price change")} onClick={() => this.handleAddProductsToPriceChange()} />
                        <Button type="button" floated='right' negative onClick={() => this.closeProductsAddModal()} >{t("Close")}</Button>
                    </Modal.Header>
                    <Modal.Content>
                        <Container>
                            <Button type="button" size='tiny' primary icon='add circle' content={t("Select all")} onClick={() => this.handleSelectAllProducts()} />  
                            {product_groups && (
                                <React.Fragment>
                                    {product_groups.map(group => {
                                        return (
                                            <React.Fragment key={group.id}>
                                                {group.products.length > 0 && (
                                                    <React.Fragment>
                                                        <Header as="h4">{group.name}</Header>
                                                        <Table striped selectable  size='small'>
                                                            <Table.Header>
                                                                <Table.Row>
                                                                    <Table.HeaderCell collapsing/>
                                                                    <Table.HeaderCell content={t("Product")}/>
                                                                    <Table.HeaderCell content={t("Code")} />
                                                                    <Table.HeaderCell content={t("Description")} />
                                                                </Table.Row>
                                                            </Table.Header>
                                                            <Table.Body>
                                                                {group.products.map((product, key) => {
                                                                    return (
                                                                        <React.Fragment key={key}>
                                                                            {product.is_active && (
                                                                                <Table.Row key={key} >
                                                                                    <Table.Cell collapsing>
                                                                                        <Checkbox
                                                                                            value={product}
                                                                                            checked={selected_products_list.includes(product) === true}
                                                                                            onChange={this.handleAddProductsCheckboxChange} />
                                                                                    </Table.Cell>
                                                                                    <Table.Cell>{product.name}</Table.Cell>
                                                                                    <Table.Cell>{product.code}</Table.Cell>
                                                                                    <Table.Cell>{product.description}</Table.Cell>
                                                                                </Table.Row>
                                                                            )}
                                                                        </React.Fragment>
                                                                    );
                                                                })}
                                                            </Table.Body>
                                                        </Table>
                                                    </React.Fragment>
                                                )}
                                            </React.Fragment>
                                        );
                                    })}
                                </React.Fragment>
                            )}
                        </Container>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button type="button" negative onClick={() => this.closeProductsAddModal()} >{t("Close")}</Button>
                    </Modal.Actions>
                </Modal>
                {/* Contracts select modal. Lists available contracts and adds selected ones to the selected contracts list */}
                <Modal size='small' open={contract_pc_contract_select_modal_open} dimmer="inverted" scrolling="true">
                    <Modal.Header>
                        <Header as="h3">{t("Pick contracts")}</Header>
                        <Button type="button" positive icon='add circle' content={t("Add contracts to price change")} onClick={() => this.handleAddContractsToPriceChange()} />
                        <Button type="button" floated='right' negative onClick={() => this.closeContractsAddModal()} >{t("Close")}</Button>
                    </Modal.Header>
                    <Modal.Content>
                        <Container>
                            <Button type="button" size='tiny' primary icon='add circle' content={t("Select all")} onClick={() => this.handleSelectAllContracts()} />
                            {contract_groups && (
                                <React.Fragment>
                                    {contract_groups.map(group => {
                                        return (
                                            <React.Fragment key={group.id}>
                                                {group.contracts.length > 0 && (
                                                    <React.Fragment>
                                                        <Header as="h4">{group.name}</Header>
                                                        <Table striped selectable  size='small'>
                                                            <Table.Header>
                                                                <Table.Row>
                                                                    <Table.HeaderCell collapsing/>
                                                                    <Table.HeaderCell content={t("Contract")} />
                                                                    <Table.HeaderCell content={t("Customer")} />
                                                                    <Table.HeaderCell content={t("Number")} />
                                                                    <Table.HeaderCell content={t("Description")} />
                                                                </Table.Row>
                                                            </Table.Header>
                                                            <Table.Body>
                                                                {group.contracts.map((contract, key) => {
                                                                    return (
                                                                        <React.Fragment key={key}>
                                                                            {contract.is_active && (
                                                                                <Table.Row key={key} >
                                                                                    <Table.Cell collapsing>
                                                                                        <Checkbox
                                                                                            value={contract}
                                                                                            checked={selected_contracts_list.includes(contract) === true}
                                                                                            onChange={this.handleAddContractsCheckboxChange} />
                                                                                    </Table.Cell>
                                                                                    <Table.Cell>{contract.name}</Table.Cell>
                                                                                    <Table.Cell>{contract.customer.name}</Table.Cell>
                                                                                    <Table.Cell>{contract.number}</Table.Cell>
                                                                                    <Table.Cell>{contract.comment}</Table.Cell>
                                                                                </Table.Row>
                                                                            )}
                                                                        </React.Fragment>
                                                                    );
                                                                })}
                                                            </Table.Body>
                                                        </Table>
                                                    </React.Fragment>
                                                )}
                                            </React.Fragment>
                                        );
                                    })}
                                </React.Fragment>
                            )}
                        </Container>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button type="button" negative onClick={() => this.closeContractsAddModal()} >{t("Close")}</Button>
                    </Modal.Actions>
                </Modal>
            </Shell>
        );
    }
}
const mapStateToProps = state => {
    return {
        activeCompanyId: state.company.activeCompanyId,
        isAuthenticated: state.auth.token !== null
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setActivity: () => dispatch(setActivity())
    };
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(withTranslation('contractpricechangedetails')(ContractPriceChangeDetails))
);